import { Action } from "@reduxjs/toolkit";
import { all, put, call, takeLatest, select } from "typed-redux-saga";
import { beachChairApi, pricesApi } from "../../api/api";
import { dateDiffInDays } from "../../utils/convertions/getDateDiffInDays";
import i18next from "../../utils/i18n/i18n";
import {
  getPriceRequest,
  getPriceSuccess,
  priceFailure,
} from "../reducers/priceReducer";
import { getPublicReferenceSelector } from "../selectors/bookingSelectors";
import { IPrice } from "../actionPayload/pricePayloads";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";
import tz from "dayjs/plugin/timezone";
import { GERMANY_TIME_ZONE } from "../../utils/convertions/constants";
dayjs.extend(utc);
dayjs.extend(tz);

function* getPriceSaga(action: Action) {
  try {
    if (getPriceRequest.match(action)) {
      const { start, end } = action.payload;
      const publicReference = yield* select(getPublicReferenceSelector);
      const params = window.location.pathname.split("/");
      const { data: beachChair } = yield* call(
        [beachChairApi, beachChairApi.beachChairsControllerFindOne],
        {
          id: +params[params.length - 1],
        }
      );
      if (publicReference) {
        const startFormat = dayjs(start).tz(GERMANY_TIME_ZONE).utc().format();
        const endFromat = dayjs(end).tz(GERMANY_TIME_ZONE).utc().format();
        const ratesPrices = yield* call(
          [pricesApi, pricesApi.pricesControllerGetRates],
          {
            start: startFormat,
            end: endFromat,
            publicReference,
            rowId: beachChair.rowId,
            sectionId: beachChair?.sectionId,
            model: beachChair?.model,
          }
        );
        let selectedDatesPrice: IPrice[] = [{ price: 0 }];
        if (dateDiffInDays(new Date(start), new Date(end)) > 0) {
          const { data } = yield* call(
            [pricesApi, pricesApi.pricesControllerCalculatePrice],
            {
              start: startFormat,
              end: endFromat,
              appVersion: "9900009",
              publicReference,
              rowId: beachChair.rowId,
              sectionId: beachChair?.sectionId,
              model: beachChair?.model,
            }
          );
          selectedDatesPrice = data as unknown as IPrice[];
        }
        yield* put(
          getPriceSuccess({
            ratePrices: ratesPrices.data,
            selectedDatesPrice: selectedDatesPrice,
          })
        );
      }
    }
  } catch (e: any) {
    i18next.setDefaultNamespace("error");
    if (e.response?.data) {
      const { payload } = e.response.data;
      const translatedErrorText = i18next.t(`${e.response.data.errorType}`, {
        payload,
      });
      yield* put(
        priceFailure({
          error: translatedErrorText,
        })
      );
    } else {
      yield* put(
        priceFailure({
          error: `${e}`,
        })
      );
    }
  }
}

function* priceSaga() {
  yield* all([takeLatest(getPriceRequest.type, getPriceSaga)]);
}

export default priceSaga;
