import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { ModalStyles } from "../../../utils/hooks/styles/modalStyle";
import { useAppDispatch } from "../../../store";
import { useTranslation } from "react-i18next";
import { selectionModalRequest } from "../../../store/reducers/mainAppReducer";
import {
  addBeachChairBookingRequest,
  flushCurrentBookingRequest,
} from "../../../store/reducers/bookingReducer";
import { addPreBookingExtrasToBookingRequest } from "../../../store/reducers/extrasReducer";

const SelectionModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <Box
      style={{
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
      }}
      sx={ModalStyles.paper}
    >
      <Box sx={ModalStyles.selectionModal}>
        <Typography sx={ModalStyles.typographyBoldBlue}>
          {t("common:selection.message")}
        </Typography>
        <Box sx={ModalStyles.selectionModalButtons}>
          <Button
            sx={{ ...ModalStyles.button, ...ModalStyles.secondaryButton }}
            onClick={() => {
              dispatch(flushCurrentBookingRequest({}));
              dispatch(addBeachChairBookingRequest({}));
              dispatch(addPreBookingExtrasToBookingRequest({}));
              dispatch(selectionModalRequest({ toggle: false }));
            }}
          >
            {t("common:selection.beginNew")}
          </Button>
          <Button
            sx={ModalStyles.button}
            onClick={() => {
              dispatch(selectionModalRequest({ toggle: false }));
            }}
          >
            {t("common:selection.continue")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default React.forwardRef(SelectionModal);
