import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { BankDetails } from "../../../api-client/generated";
import * as ibantools from "ibantools";
import { useTranslation } from "react-i18next";

export function useSEPAForm() {
  const { t } = useTranslation(["booking"]);
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        accountHolder: yup
          .string()
          .nullable(true)
          .transform((v) => (v === "" ? null : v)),
        bankName: yup
          .string()
          .nullable(true)
          .transform((v) => (v === "" ? null : v)),
        bic: yup
          .string()
          .transform((v) => (v === "" ? undefined : v))
          .test("isBic", t("shouldBeIBAN"), (bic) => {
            return bic ? ibantools.isValidBIC(bic || "") : true;
          }),
        iban: yup
          .string()
          .test("isIban", t("shouldBeIBAN"), (iban) => {
            return iban ? ibantools.isValidIBAN(iban || "") : true;
          })
          .required(),
      }),
    [t]
  );

  const {
    control,
    formState: { isValid },
    formState: { errors },
    getValues,
  } = useForm<BankDetails>({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  return {
    sepaControl: control,
    sepaIsValid: isValid,
    sepaErrors: errors,
    sepaGetValues: getValues,
  };
}
