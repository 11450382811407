import { stylesConfig } from "../../styles/styles";
import { keyframes } from "@mui/system";

const boxShadowAnimation = keyframes`
  0% {
    box-shadow: ${stylesConfig.orangeBoxShadow};
  }
  100%  {
    box-shadow: ${stylesConfig.boxShadow};
  }
`;

const globalWidth = {
  xs: "auto",
  md: `calc(${stylesConfig.leftMinComponentWidth} - 40px)`,
  xl: `calc(${stylesConfig.leftComponentWidth} - 2*${stylesConfig.marginLeft})`,
};

export const BeachChairStylesRoot = {
  display: "grid",
  gap: {
    xs: "10px",
    sm: "10px",
    md: `calc(3*${stylesConfig.marginTop})`,
  },
  paddingLeft: {
    xs: "10px",
    sm: "10px",
    md: "20px",
  },
  paddingRight: {
    xs: "10px",
    sm: "10px",
    md: "20px",
  },
  paddingTop: {
    xs: "10px",
    sm: "10px",
    md: `calc(3*${stylesConfig.marginTop})`,
  },
  paddingBottom: {
    xs: "10px",
    sm: "10px",
    md: `calc(3*${stylesConfig.marginTop})`,
  },
  maxHeight: {
    xs: `${stylesConfig.beachChairMobileViewHeight}`,
    sm: `${stylesConfig.beachChairMobileViewHeight}`,
    md: `calc(100vh - ${stylesConfig.appBarHeight} - ${stylesConfig.marginTop} - ${stylesConfig.marginBottom})`,
  },
  backgroundColor: stylesConfig.whiteColor,
  borderRadius: {
    xs: `20px 20px 0 0`,
    sm: `20px 20px 0 0`,
    md: stylesConfig.sxBorderRadius,
  },
  boxShadow: {
    xs: stylesConfig.boxShadow,
    sm: stylesConfig.boxShadow,
    md: stylesConfig.boxShadow,
  },
  marginRight: 0,
  overflowY: "scroll",
  boxSizing: "content-box",
  "&::-webkit-scrollbar": {
    width: "3px",
    height: 1,
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: stylesConfig.blueColor,
    borderRadius: stylesConfig.sxBorderRadius,
  },
} as const;

export const BeachChairSelectAnimation = {
  animation: `${boxShadowAnimation} 0.5s`,
} as const;

export const BeachChairStyles = {
  beachChairImage: {
    height: 200,
    borderRadius: stylesConfig.sxBorderRadius,
    boxShadow: stylesConfig.boxShadow,
  } as const,
  twoColumnsGrid: {
    width: globalWidth,
    display: "grid",
    gridTemplateColumns: "70% 30%",
  } as const,
  twoRowsGrid: {
    width: globalWidth,
    display: "grid",
    gridTemplateRows: "auto auto",
  } as const,
  pricesGrid: {
    display: "grid",
    marginBottom: "10px",
  } as const,
  twoColumnsGrid34: {
    width: globalWidth,
    display: "grid",
    gap: {
      xs: "10px",
      sm: "10px",
      md: 0,
    },
    gridTemplateColumns: {
      xs: "auto",
      sm: "auto",
      md: "75% 25%",
    },
  } as const,
  twoColumnsGridSelectedPrice: {
    width: globalWidth,
    display: "grid",
    gap: {
      xs: "10px",
      sm: "10px",
      md: 0,
    },
    gridTemplateColumns: {
      xs: "auto auto",
      sm: "auto auto",
      md: "75% 25%",
    },
    marginBottom: "10px",
  } as const,
  typographyBoldBlue: {
    fontSize: {
      xs: 13,
      sm: 13,
      md: 16,
    },
    fontWeight: "bold",
    color: stylesConfig.blueColor,
  },
  empty: {
    backgroundColor: stylesConfig.lightGrayColor,
    borderRadius: stylesConfig.sxBorderRadius,
    height: "20px",
  },
  typographyGray: {
    fontSize: {
      xs: 12,
      sm: 12,
      md: 15,
    },
    alignSelf: "center",
    color: stylesConfig.grayColor,
  },
  typographyGrid: {
    display: "grid",
    gridTemplateRows: "auto auto auto",
  },
  button: {
    color: `${stylesConfig.whiteColor}`,
    backgroundColor: `${stylesConfig.blueColor}`,
    borderRadius: stylesConfig.sxBorderRadius,
    height: `calc(${stylesConfig.defaultControlHeight}*0.5)`,
    boxShadow: stylesConfig.blueBoxShadow,
    width: "77px",
    "&:hover": {
      backgroundColor: stylesConfig.blueColor,
    },
  },
  divider: {
    display: {
      xs: "none",
      sm: "none",
      md: "inline-block",
    },
    width: globalWidth,
  },
  typographyBoldGray: {
    display: "grid",
    gap: "2px",
    fontSize: {
      xs: 13,
      sm: 13,
      md: 16,
    },
    gridTemplateColumns: "30px 200px",
    color: stylesConfig.grayColor,
  },
  typographyBoldGrayCity: {
    alignContent: "center",
    display: "grid",
    fontSize: {
      xs: 13,
      sm: 13,
      md: 16,
    },
    color: stylesConfig.grayColor,
  } as const,
  infoBox: {
    display: "grid",
    gap: "10px",
  },
  noTextDecoration: {
    textDecoration: "none",
    justifySelf: "right",
  },
  buttonMin: {
    position: "absolute",
    height: 30,
    width: 30,
    alignSelf: "right",
    justifySelf: "right",
    top: 10,
    backgroundColor: stylesConfig.whiteColor,
    color: stylesConfig.blueColor,
    boxShadow: stylesConfig.boxShadow,
    "&:hover": {
      backgroundColor: stylesConfig.whiteColor,
      color: stylesConfig.blueColor,
    },
  } as const,
  lock: {
    height: 37,
    width: 25,
  } as const,
  lockGrid: {
    display: "grid",
    gridTemplateColumns: "40px auto",
    alignItems: "center",
    justifyItems: { xs: "left", sm: "left", md: "center" },
  } as const,
  seasonGrid: {
    display: "grid",
    gridTemplateColumns: "40px auto",
    alignItems: "center",
    justifyItems: "left",
  } as const,
  calendarMonthIcon: {
    height: "37px",
    width: "32px",
    color: stylesConfig.blueColor,
  },
};
