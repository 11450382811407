import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ContentStyles } from "../../utils/hooks/styles/contentStyles";
import ReactMarkdown from "react-markdown";
import {
  TextField,
  Stack,
  Button,
  Box,
  Typography,
  Link,
  FormControl,
  InputLabel,
  Input,
} from "@mui/material";
import MarkdownDeFile from "../../assets/markdown/contact.de.md";
import MarkdownEnFile from "../../assets/markdown/contact.en.md";
import { useLocation } from "react-router";
import queryString from "query-string";
import CloseButton from "./closeButton";

const ContactForm = () => {
  const { t } = useTranslation(["common"]);
  return (
    <FormControl fullWidth={true}>
      <form
        method="POST"
        name="fa-form-1"
        action="https://webhook.frontapp.com/forms/68165134e46d2dbf75fd/IlEEZKyhu3cP5Oifbs4XAvV9m72IjLPXA2Yx8eNhp1kDep5ZzusFM92sCI2ggX4zyhX36Kbmvl57qfLYCdVJ5hPn-CxKrl1X-uvqefHMYI6FTGauiCT1DKeondqxGA"
        encType="multipart/form-data"
        acceptCharset="utf-8"
      >
        <Box
          sx={{
            display: "grid",
            gap: "10px",
            paddingTop: "10px",
            paddingBottom: "10px",
            paddingRight: "40px",
            paddingLeft: "40px",
          }}
        >
          <TextField
            sx={{ width: "full" }}
            id="name"
            label={`${t("firstName")} ${t("lastName")}`}
            type="text"
            name="name"
            variant="outlined"
            fullWidth={true}
          />
          <TextField
            sx={{ width: "full" }}
            id="email"
            label="E-Mail"
            type="email"
            name="email"
            variant="outlined"
            fullWidth={true}
          />
          <TextField
            sx={{ width: "full" }}
            id="body"
            label={t("message")}
            multiline
            rows={2}
            name="body"
            defaultValue=""
            variant="outlined"
          />
        </Box>
        <FormControl variant="standard">
          <InputLabel htmlFor="attachment-field">{t("attachment")}</InputLabel>
          <Input id="attachment-field" type="file" name="attachment" />
        </FormControl>
        <br />
        <Button variant="contained" color="primary" type="submit">
          {t("send")}
        </Button>
      </form>
    </FormControl>
  );
};

const ContactContent = () => {
  const { search } = useLocation();
  const values = queryString.parse(search);
  const { i18n, t } = useTranslation(["common"]);
  const [text, setText] = useState("");

  useEffect(() => {
    const userLanguage = i18n.language;
    fetch(
      !userLanguage || userLanguage === "de" || userLanguage === "de-DE"
        ? MarkdownDeFile
        : MarkdownEnFile
    )
      .then((res) => res.text())
      .then((text) => setText(text));
  }, [i18n.language, i18n.languages]);

  const emailSuccess = values?.success !== undefined;
  const emailError = values?.error !== undefined;
  const displayMessages = emailSuccess || emailError;

  return (
    <Box sx={ContentStyles.root}>
      <Box sx={ContentStyles.content}>
        <Box sx={ContentStyles.helperText}>
          <Box sx={ContentStyles.background}>
            <CloseButton />
            {displayMessages ? (
              <Stack spacing={2} alignItems="center">
                {emailSuccess && (
                  <Box sx={ContentStyles.markDown}>
                    <Typography sx={ContentStyles.errorCode}>
                      {t("Success")} 😁
                    </Typography>
                    <Typography sx={ContentStyles.helperText}>
                      {t("sendEmailSuccess")}
                    </Typography>
                  </Box>
                )}
                {emailError && (
                  <Box sx={ContentStyles.markDown}>
                    <Typography sx={ContentStyles.errorCode}>
                      {t((values?.code as string) || "")}
                    </Typography>
                    <Typography sx={ContentStyles.helperText}>
                      {t((values?.message as string) || "")}
                    </Typography>
                  </Box>
                )}
              </Stack>
            ) : (
              <Stack spacing={2} alignItems="center" paddingBottom="20">
                <Box sx={ContentStyles.markDown}>
                  <ReactMarkdown>{text}</ReactMarkdown>
                </Box>
                <ContactForm />
                <Box sx={{ textAlign: "left", maxWidth: "500px", mx: "auto" }}>
                  <Typography>{t("reachUsAlso")}</Typography>
                  <Typography>
                    {t("telephone")}:{" "}
                    <Link href="tel:04022857860">040 22857860</Link>
                    <br />
                    {t("email")}:{" "}
                    <Link href="mailto:moin@strandbutler.de">
                      moin@strandbutler.de
                    </Link>
                  </Typography>
                </Box>
              </Stack>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ContactContent;
