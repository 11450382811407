import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RatesQueryResponse } from "../../api-client/generated";
import {
  GetPriceRequestPayload,
  GetPriceSuccessPayload,
  IPrice,
  PriceFailurePayload,
} from "../actionPayload/pricePayloads";

interface IPriceState {
  pending: boolean;
  ratePrices? : RatesQueryResponse[];
  selectedDatesPrice?: IPrice[];
  error: string;
}

const initialState: IPriceState = {
  pending: false,
  ratePrices: undefined,
  selectedDatesPrice: undefined,
  error: "",
};

const priceSlice = createSlice({
  name: "price",
  initialState,
  reducers: {
    getPriceRequest(state, action: PayloadAction<GetPriceRequestPayload>) {
      state.error = "";
    },
    getPriceSuccess(state, action: PayloadAction<GetPriceSuccessPayload>) {
      state.error = "";
      state.ratePrices = action.payload.ratePrices;
      state.selectedDatesPrice = action.payload.selectedDatesPrice;
    },
    priceFailure(state, action: PayloadAction<PriceFailurePayload>) {
      state.error = action.payload.error;
      state.ratePrices = undefined;
      state.selectedDatesPrice = undefined;
    },
  },
});

export const { getPriceRequest, getPriceSuccess, priceFailure } =
  priceSlice.actions;

export default priceSlice.reducer;
