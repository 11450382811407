import { stylesConfig } from "../../styles/styles";

export const LocationAvailabilitiesBeachChairsStyle = {
  root: {
    display: "grid",
    gap: "30px",
    paddingBottom: `calc(3*${stylesConfig.marginTop})`,
    gridAutoRows: `60px minmax(25px, 10px)`,
    height: {
      xs: `calc(100vh - 3*${stylesConfig.appBarHeight} - *${stylesConfig.footerHeight})`,
      sm: `calc(100vh - 3*${stylesConfig.appBarHeight} - *${stylesConfig.footerHeight})`,
      md: `calc(100vh - ${stylesConfig.appBarHeight} - 3*${stylesConfig.marginTop} - 2*${stylesConfig.marginBottom})`,
    },
    minHeight: {
      xs: `70vh`,
      sm: `70vh`,
      md: `calc(100vh - ${stylesConfig.appBarHeight} - 3*${stylesConfig.marginTop} - 2*${stylesConfig.marginBottom})`,
    },
    padding: {
      xs: "10px",
      sm: "10px",
    },
    margin: {
      xs: "10px",
      sm: "10px",
      md: 0,
    },
    backgroundColor: stylesConfig.whiteColor,
    borderRadius: stylesConfig.sxBorderRadius,
    boxShadow: {
      xs: stylesConfig.blueBoxShadow,
      sm: stylesConfig.blueBoxShadow,
      md: stylesConfig.boxShadow,
    },
    overflowY: "scroll",
    boxSizing: "content-box",
    "&::-webkit-scrollbar": {
      width: 3,
      height: 5,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: stylesConfig.blueColor,
      borderRadius: stylesConfig.borderRadius,
    },
  } as const,
  buttonClose: {
    background: "white",
    height: 40,
    width: 40,
    color: stylesConfig.blueColor,
    backgroundColor: stylesConfig.whiteColor,
  } as const,
  buttonMin: {
    background: "white",
    position: "fixed",
    height: 40,
    width: 40,
    alignSelf: "right",
    justifySelf: "right",
    top: `calc(${stylesConfig.appBarHeight} + 20px)`,
    right: "30px",
    color: stylesConfig.blueColor,
    backgroundColor: stylesConfig.whiteColor,
  } as const,
  switchMin: {
    display: "grid",
    alignItems: "right",
    justifyItems: "right",
    background: "white",
    height: 40,
    width: "100%",
    right: "140px",
    color: stylesConfig.blueColor,
    fontWeight: "bold",
  } as const,
  switchMinSec: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "right",
    justifySelf: "fight",
    background: "white",
    height: 40,
    width: 100,
    paddingTop: "-10px",
    color: stylesConfig.blueColor,
    fontWeight: "bold",
  } as const,

  waterTypography: {
    position: "absolute",
    height: 40,
    width: "inherit",
    textAlign: "center",
    top: `20px`,
    color: stylesConfig.blueColor,
    fontWeight: "bold",
  } as const,
  buttonBack: {
    position: "fixed",
    height: 40,
    width: 40,
    left: "10px",
    color: stylesConfig.blueColor,
  } as const,
  mobileViewExpandMenu: { top: `calc(4*${stylesConfig.appBarHeight})` },
  normalMenu: { top: `calc(2*${stylesConfig.appBarHeight} + 20px)` },
  sticky: {
    position: "sticky",
    width: {
      xs: `calc(100vw - 42px)`,
      sm: `calc(100vw - 42px)`,
      md: `calc(100vw - ${stylesConfig.leftMinComponentWidth} - 6*${stylesConfig.marginLeft})`,
      lg: `calc(100vw - ${stylesConfig.leftMinComponentWidth} - 5*${stylesConfig.marginLeft})`,
      xl: `calc(100vw - ${stylesConfig.leftComponentWidth} - 5*${stylesConfig.marginLeft})`,
    },
    left: 0,
    flexGrow: 0,
    flexShrink: 0,
  } as const,
  rowContent: {
    marginRight: stylesConfig.marginRight,
    marginLeft: stylesConfig.marginLeft,
    alignItems: "center",
    justifyContent: "left",
    display: "flex",
    gridTemplateColumns: "repeat(auto-fit, minmax(70px, 70px));",
  } as const,
  noData: {
    fontSize: 25,
    color: stylesConfig.blueColor,
    fontWeight: "bold",
    display: "grid",
    alignItems: "center",
    justifyItems: "center",
  },
  noDataText: {
    color: stylesConfig.blueColor,
    fontSize: 20,
    lineHeight: 1.5,
    display: "grid",
    alignItems: "center",
    justifyItems: "center",
  },
};
