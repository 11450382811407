import { addHours, isBefore } from "date-fns";
import { createSelector } from "reselect";
import { dateToProperDateCartFormat } from "../../utils/convertions/dataConversion";
import { ITreeSelect, SectionKindEnum } from "../../utils/models/ITreeSelect";
import { getAllBeaches } from "../reducers/beachReducer";
import { getAllCities } from "../reducers/cityReducer";
import { getAllRegions } from "../reducers/regionReducer";
import { AppState } from "../reducers/rootReducer";
import { getAllSections, getSectionById } from "../reducers/sectionReducer";
import { getDateFromSelector } from "./mainAppSelectors";

const getAppState = (state: AppState) => state;
const getSections = (state: AppState) => state.section.sections;
const getSelectedSection = (state: AppState) => state.section.section;
const getError = (state: AppState) => state.section.error;
const getPending = (state: AppState) => state.section.pending;

export const getSectionsSelector = createSelector(
  getSections,
  (sections) => sections
);

export const getAllSectionsSelector = createSelector(
  getAllSections,
  (sections) => sections
);

export const getSectionByIdSelector = (id: number) =>
  createSelector(getAppState, (state) => getSectionById(state, id)!);

export const getSelectedSectionSelector = createSelector(
  getSelectedSection,
  (section) => section
);
export const getErrorSelector = createSelector(getError, (error) => error);
export const getPendingSelector = createSelector(
  getPending,
  (pending) => pending
);

// Create a function that generates a tree of regions, cities, beaches and sections
// This function is used in autocomplete dropdown
export const getLocationsAutocompleteOptions = createSelector(
  getAllRegions,
  getAllCities,
  getAllBeaches,
  getAllSections,
  (regions, cities, beaches, sections) => {
    const treeSelect: ITreeSelect[] = [];

    // We have the test section with the country ID 34 available on PROD environment,
    // so we need to filter that out
    const TEST_SECTION_COUNTRY = 34;

    regions
      .filter(({ countryId }) => countryId !== TEST_SECTION_COUNTRY)
      .forEach((region, regionIndex) => {
        const regionId = `r${regionIndex}`;

        treeSelect.push({
          id: regionId,
          name: region.name,
          obj: region,
          regionId: region.id,
          kind: SectionKindEnum.REGION,
        });

        // get all related cities
        cities
          .filter(({ regionId }) => regionId === region.id)
          .forEach((city, cityIndex) => {
            const cityId = `${regionId}c${cityIndex}`;
            /*treeSelect.push({
              id: cityId,
              name: city.name,
              obj: city,
              regionId: region.id,
              kind: SectionKindEnum.CITY,
              parentId: regionId,
            });*/

            // get all related beaches
            beaches
              .filter(({ cityId }) => cityId === city.id)
              .forEach((beach, beachIndex) => {
                const beachId = `${regionId}${cityId}b${beachIndex}`;
                treeSelect.push({
                  id: beachId,
                  name: beach.name,
                  obj: beach,
                  regionId: region.id,
                  kind: SectionKindEnum.BEACH,
                  parentId: regionId,
                });

                // get all related sections
                sections
                  .filter(({ beachId }) => beachId === beach.id)
                  .forEach((section, sectionIndex) => {
                    const sectionId = `${regionId}${cityId}${beachId}s${sectionIndex}`;
                    treeSelect.push({
                      id: sectionId,
                      name: section.name,
                      obj: section,
                      regionId: region.id,
                      kind: SectionKindEnum.BEACH_SECTION,
                      parentId: beachId,
                    });
                  });
              });
          });
      });

    return treeSelect;
  }
);

export const getSelectedSectionLeadTimeHoursSelector = createSelector(
  getSelectedSection,
  getDateFromSelector,
  (section, start) => {
    const leadTimeHours = section?.location.leadTimeHours;
    if (section && leadTimeHours) {
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);
      tomorrow.setHours(0, 0, 0, 0);
      const addedHours = addHours(tomorrow, leadTimeHours);
      const startDate = new Date(start);
      return isBefore(startDate, addedHours)
        ? {
            leadTimeHours,
            addedHours: dateToProperDateCartFormat(addedHours.toISOString()),
          }
        : undefined;
    } else {
      return undefined;
    }
  }
);
