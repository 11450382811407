import React from "react";
import { useLocation } from "react-router";
import qs from "qs";
import { useAppDispatch } from "../../../store";
import { getBookingDetailsRequest } from "../../../store/reducers/bookingReducer";

const Bookings = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const bookingRef =
    typeof query?.bookingRef === "string" ? query.bookingRef : null;
  const email = typeof query?.email === "string" ? query.email : null;

  React.useLayoutEffect(() => {
    if (bookingRef && email) {
      dispatch(getBookingDetailsRequest({ bookingRef, email }));
    }
    // eslint-disable-next-line
  }, [bookingRef, email]);
  return <></>;
};

export default Bookings;
