import { FilterOptionsState } from "@mui/base/AutocompleteUnstyled/useAutocomplete";
import { useEffect, useMemo, useRef } from "react";
import { Popper, TextField, Autocomplete, Typography, Box } from "@mui/material";
import { useWidget } from "../../../contexts";
import { getSelectedRegionSelector } from "../../../store/selectors/regionSelectors";
import { ITreeSelect, SectionKindEnum } from "../../../utils/models/ITreeSelect";
import {
  setGlobalBeachRequest,
  setGlobalCityRequest,
  setGlobalRegionRequest,
  setGlobalSectionRequest
} from "../../../store/reducers/locationReducer";
import {
  SearchStyle,
  useSearchStyles
} from "../../../utils/hooks/styles/searchStyle";
import { clearCityRequest } from "../../../store/reducers/cityReducer";
import {
  showStartToggle,
  toggleIgnoreCloseExpandMenuRequest
} from "../../../store/reducers/mainAppReducer";
import { getLocationsAutocompleteOptions } from "../../../store/selectors/sectionSelectors";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../store";
import { setFocusRequest } from "../../../store/reducers/mainAppReducer";
import { getFocusSelector } from "../../../store/selectors/mainAppSelectors";
import { useTranslation } from "react-i18next";

const getIndent = ({ kind }: ITreeSelect) => {
  switch (kind) {
    case SectionKindEnum.CITY:
      return 20;
    case SectionKindEnum.BEACH:
      return 32;
    case SectionKindEnum.BEACH_SECTION:
      return 48;
    case SectionKindEnum.REGION:
    default:
      return 0;
  }
};

const TreeItemEmoji: React.FC<{ treeItem: ITreeSelect }> = ({ treeItem: { kind } }) => {
  let emoji: string;

  switch (kind) {
    case SectionKindEnum.CITY:
      emoji = "🏘";
      break;
    case SectionKindEnum.BEACH:
      emoji = "🏖";
      break;
    case SectionKindEnum.BEACH_SECTION:
      emoji = "☀️";
      break;
    case SectionKindEnum.REGION:
    default:
      emoji = "▸";
      break;
  }

  return <Typography sx={{ display: "inline-block", pr: 1 }}>{emoji}</Typography>;
};

const getChildPlaces = (option: ITreeSelect, options: ITreeSelect[]) => {
  return options.filter(({ id }) => id.includes(option.id)).map(({ id }) => id);
}

const getParentPlaces = (option: ITreeSelect, options: ITreeSelect[]): string[] => {
  let parentItem = options.find(({ id }) => id === option.parentId);
  return parentItem
    ? [...new Set([parentItem.id, ...getParentPlaces(parentItem, options)] )]
    : [];
}

const onFilter = (options: ITreeSelect[], state: FilterOptionsState<ITreeSelect>) => {
  const searchTerm = (state.inputValue ?? "").toLowerCase();
  const matchingIds = new Set<string>();

  const foundOptions = options.filter(option => option.name.toLowerCase().includes(searchTerm));

  foundOptions.forEach(option => {
    matchingIds.add(option.id);
    getParentPlaces(option, options).forEach(id => matchingIds.add(id));
    getChildPlaces(option, options).forEach(id => matchingIds.add(id));
  });

  return options.filter(({ id }) => matchingIds.has(id));
};

const SearchAutocomplete = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const autocompleteOptions = useSelector(getLocationsAutocompleteOptions);
  const region = useSelector(getSelectedRegionSelector);
  const classes = useSearchStyles();

  const inputRef = useRef<HTMLInputElement | null>(null)
  const focus = useSelector(getFocusSelector);
  const { isWidgetActive } = useWidget();

  useEffect(() => {
    if (focus) {
      inputRef.current?.focus();
      dispatch(setFocusRequest({ toggle: false }));
    }
  }, [dispatch, focus, inputRef]);

  const onChange = (event: React.SyntheticEvent, value: ITreeSelect | null) => {
    dispatch(showStartToggle({ toggle: false }));
    dispatch(toggleIgnoreCloseExpandMenuRequest({ toggle: false }));

    if (value) {
      const id = value.obj.id;
      const kind = value.kind;

      switch (kind) {
        case SectionKindEnum.CITY:
          dispatch(setGlobalCityRequest({ id }));
          break;
        case SectionKindEnum.BEACH:
          dispatch(setGlobalBeachRequest({ id }));
          break;
        case SectionKindEnum.BEACH_SECTION:
          dispatch(setGlobalSectionRequest({ id }));
          break;
        case SectionKindEnum.REGION:
          dispatch(setGlobalRegionRequest({ id }));
          break;
        default:
          break;
      }
    } else {
      clearCityRequest();
    }
  };

  const validOptions = useMemo(() => {
    return isWidgetActive && region?.id
      ? autocompleteOptions.filter(option => option.regionId === region.id)
      : autocompleteOptions;
  }, [autocompleteOptions, isWidgetActive, region]);

  return (
    <Autocomplete
      sx={{
        whiteSpace: "pre",
        maxWidth: 300,
        minWidth: 300,
        mx: 2
      }}
      PopperComponent={(props) => (
        <Popper {...props} className={classes.popper} />
      )}
      onChange={onChange}
      openOnFocus
      id="combo-box"
      options={validOptions}
      noOptionsText={t("common:noOptions")}
      renderOption={(props, option) => (
        <Box
          {...props}
          component="li"
          key={option.id}
          sx={{ ml: `${getIndent(option)}px` }}
        >
          <TreeItemEmoji treeItem={option} />
          <Typography>{option.name}</Typography>
        </Box>
      )}
      renderInput={(params) =>
        <TextField
          {...params}
          classes={{ root: `notchedOutline: classes.notchedOutline` }}
          label={t("common:searchPlaceholder")}
          variant="outlined"
          sx={SearchStyle.textRoundedField}
          inputRef={(input) => inputRef.current = input}
        />
      }
      filterOptions={onFilter}
      getOptionLabel={({ name }) => name}
    />
  );
};

export default SearchAutocomplete;
