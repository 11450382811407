import { stylesConfig } from "../../../utils/styles/styles";
import { Polygon, Marker, OverlayView } from "@react-google-maps/api";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../store";
import { getAllSectionsSelector } from "../../../store/selectors/sectionSelectors";
import { IGeoPolygon } from "../../../utils/models/IGeoPoint";
import { setGlobalSectionRequest } from "../../../store/reducers/locationReducer";
import { getSelectedBeachSelector } from "../../../store/selectors/beachSelectors";
import logo from "./../../../assets/SBLMarker.png";
import { getCentroid } from "../../../utils/convertions/getCentroid";
import { Fragment } from "react";
import React from "react";
import { IMapStateProps } from "../../../utils/models/IMapStateProps";
import { geoJsonGuard } from "../../../utils/functions/geoJsonGuard";
import { setRowIdSubSectionRequest } from "../../../store/reducers/availabilityReducer";
import { subSectionRequest } from "../../../store/reducers/mainAppReducer";
import { Box, Typography } from "@mui/material";
import { SBColor } from "../../locationAvailabilitiesBeachChairs/availabilitiesBeachChair/availabilitiesBeachChairTimeline";
import { useWidgetZustand } from "../../../contexts/zustand/widgetZustand";

const BeachMapState = ({ setMapBounds }: IMapStateProps) => {
  const dispatch = useAppDispatch();
  const sections = useSelector(getAllSectionsSelector);
  const beach = useSelector(getSelectedBeachSelector);
  const { publicRef } = useWidgetZustand();
  const beachSections = React.useMemo(
    () =>
      sections
        .filter((section) => geoJsonGuard(section.geoJson))
        .filter((section) => section.beachId === (beach?.id || 0)).filter(section => {
          if(publicRef) {return (section as any).publicReference === publicRef} else { return true}}),
    [sections, beach, publicRef]
  );

  React.useEffect(() => {
    const bounds = new window.google.maps.LatLngBounds();
    if (beach && beachSections.length === 0) {
      const path =
        Object.keys(beach.geoJson).length !== 0
          ? (beach.geoJson as IGeoPolygon).coordinates[0].map((coord) => {
            return { lat: coord[1], lng: coord[0] };
          })
          : [{ lat: 0, lng: 0 }];
      path.forEach((point) => {
        bounds.extend({
          lat: point.lat,
          lng: point.lng,
        });
      });
    }
    if (beachSections.length > 1) {
      beachSections.forEach((section) => {
        let geoJson = section.geoJson;
        let centroid = getCentroid(geoJson);
        bounds.extend({
          lat: centroid.geometry.coordinates[1],
          lng: centroid.geometry.coordinates[0],
        });
      });
    } else {
      beachSections.forEach((section) => {
        const path =
          Object.keys(section.geoJson).length !== 0
            ? (section.geoJson as IGeoPolygon).coordinates[0].map((coord) => {
              return { lat: coord[1], lng: coord[0] };
            })
            : [{ lat: 0, lng: 0 }];
        path.forEach((point) => {
          bounds.extend({
            lat: point.lat,
            lng: point.lng,
          });
        });
      });
    }
    setMapBounds(bounds);
  }, [beachSections, setMapBounds, beach]);

  return (
    <>
      {beachSections.map((section) => {
        let geoJson = section.geoJson;
        let centroid = getCentroid(geoJson);
        if (
          geoJson &&
          Object.keys(geoJson).length === 0 &&
          geoJson.constructor === Object
        ) {
          return <></>;
        } else {
          const path = (geoJson as IGeoPolygon).coordinates[0].map((coord) => {
            return { lat: coord[1], lng: coord[0] };
          });
          return (
            <Fragment key={`fragment-${section.id}-${section.beachId}`}>
              <Polygon
                path={path}
                key={`polygon-${section.id}-${section.beachId}`}
                onClick={() => {
                  setTimeout(() => {
                    dispatch(setRowIdSubSectionRequest({}));
                    const hasSubsections =
                      (section as any)?.hasSubsections || false;
                    dispatch(subSectionRequest({ toggle: hasSubsections }));
                    dispatch(
                      setGlobalSectionRequest({
                        id: section.id,
                        hasSubsections,
                      })
                    );
                  }, 200);
                }}
                options={{
                  strokeColor: stylesConfig.blueColor,
                  fillColor: stylesConfig.orangeColor,
                  strokeOpacity: 0.78,
                  strokeWeight: 1,
                  fillOpacity: 0.5,
                }}
              />
              <Marker
                onClick={() => {
                  dispatch(setRowIdSubSectionRequest({}));
                  const hasSubsections =
                    (section as any)?.hasSubsections || false;
                  dispatch(subSectionRequest({ toggle: hasSubsections }));
                  dispatch(
                    setGlobalSectionRequest({
                      id: section.id,
                      hasSubsections,
                    })
                  );
                }}
                key={`marker-${section.id}-${section.beachId}`}
                position={{
                  lat: centroid.geometry.coordinates[1],
                  lng: centroid.geometry.coordinates[0],
                }}
                clickable
                icon={{
                  url: logo,
                  scaledSize: new google.maps.Size(50, 50),
                }}
              />
              <OverlayView
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                position={{
                  lat: centroid.geometry.coordinates[1],
                  lng: centroid.geometry.coordinates[0],
                }}
              >
                <Box
                  onClick={() => {
                    dispatch(setRowIdSubSectionRequest({}));
                    const hasSubsections =
                      (section as any)?.hasSubsections || false;
                    dispatch(subSectionRequest({ toggle: hasSubsections }));
                    dispatch(
                      setGlobalSectionRequest({
                        id: section.id,
                        hasSubsections,
                      })
                    );
                  }}
                  sx={{
                    width: "fit-content",
                    cursor: "pointer",
                    background: SBColor.orange,
                    borderRadius: "25px",
                    padding: "5px",
                    justifyContent: "center",
                    marginLeft: "-50%",
                    marginRight: "50%",
                  }}
                >
                  <Typography
                    sx={{
                      whiteSpace: "nowrap",
                      color: "white",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    {section.name}
                  </Typography>
                </Box>
              </OverlayView>
            </Fragment>
          );
        }
      })}
    </>
  );
};

export default BeachMapState;
