import { OverlayView, OverlayViewF, Polygon } from "@react-google-maps/api";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../store";
import { getSelectedSectionSelector } from "../../../store/selectors/sectionSelectors";
import { IGeoPolygon } from "../../../utils/models/IGeoPoint";
import { stylesConfig } from "../../../utils/styles/styles";
import { IMapStateProps } from "../../../utils/models/IMapStateProps";
import React, { useEffect, useState } from "react";
import { getPublicReferenceSelector } from "../../../store/selectors/bookingSelectors";
import {
  getDateFromSelector,
  getDateToSelector,
} from "../../../store/selectors/mainAppSelectors";
import {
  getPublicSectionsBeachChairsAvailabilityRequest,
  selectBeachChairRequest,
  setRowRequest,
} from "../../../store/reducers/availabilityReducer";
import { getSectionsSelector } from "../../../store/selectors/availabilitySelectors";
import { Box, CardMedia, Typography } from "@mui/material";
import { SBColor } from "../../locationAvailabilitiesBeachChairs/availabilitiesBeachChair/availabilitiesBeachChairTimeline";
import { EventBusy } from "@mui/icons-material";
import lock from "../../../assets/lock.png";
import {
  getAllBookedBeachChairs,
  selectBeachChairToBookingRequest,
} from "../../../store/reducers/bookingReducer";
import { redirectRequest } from "../../../store/reducers/mainAppReducer";
import { getSelectedCitySelector } from "../../../store/selectors/citySelectors";

enum shortModels {
  "2_SEAT_RECLINER" = "2LK",
  "2.5_SEAT_RECLINER" = "2.5LK",
  "BALTIC_BEACH_CHAIR" = "OSK",
  "2_SEAT_CHAIR" = "2K",
  "3_SEAT_CHAIR" = "3K",
  "FULL_RECLINER" = "GL",
  "BEACH_CHAIR_BED" = "SSK",
  "DAYBED" = "DB",
  "XL_BEACH_CHAIR" = "XLSK",
  "SAUNA" = "SAUNA",
  "FAMILY_BEACH_CHAIR" = "FAM",
  "XL_BEACH_CHAIR_TURNABLE" = "XLSK-DT",
  "2_SEAT_CHAIR_TURNABLE" = "2K-DT",
  "COMFORT" = "COM",
  "WHEELCHAIR" = "BFREI",
  "XXL_BEACH_CHAIR" = "XXLSK",
  "CHILDBEACHCHAIR" = "KIND",
  "DOG_BEACH_CHAIR" = "HUND",
  "FKK_BUDJE" = "FKK",
  "NORDORT" = "NORD",
  "PREMIUM" = "PREM",
  "RETRO" = "RETRO",
}

export enum Reason {
  OUTSIDE_RENTAL_SEASON = "OUTSIDE_RENTAL_SEASON",
  STOP_SALE = "STOP_SALE",
  BOOKED = "BOOKED",
}

export enum EColor {
  "silver" = "#C0C0C0",
  "darkgreen" = "#006400",
  "green" = "#00FF00",
  "lightgreen" = "#90EE90",
  "darkblue" = "#00008B",
  "blue" = "#0000FF",
  "lightblue" = "#ADD8E6",
  "darkred" = "#8B0000",
  "red" = "#ff0000",
  "lightred" = "#FFCCCB",
  "white" = "#eeeeee",
  "brightyellow" = "#FFE900",
  "yellow" = "#FFEF00",
  "darkyellow" = "#E0A526",
  "black" = "#000000",
  "brightbrown" = "#E2BB7B",
  "brown" = "#B3632B",
  "darkbrown" = "#4D2B12",
  "beige" = "#f5f5dc",
  "orange" = "#ffaa00",
  "grey" = "#909090",
  "purple" = "#5a005a",
  "pink" = "#c09da4",
}

const setZoomLevel = 18;

const getTimeStyle = (timeEnum: string) => {
  switch (timeEnum) {
    case "boxBackgroundFree":
      return {
        backgroundColor: SBColor.white,
      };
    case "boxBackgroundNoon":
      return {
        background: `linear-gradient(180deg, ${SBColor.white} 50%, ${SBColor.lightGray} 50%)`,
      };
    case "boxBackgroundAfterNoon":
      return {
        background: `linear-gradient(180deg, ${SBColor.white} 50%, ${SBColor.white} 50%)`,
      };
    case "boxBackgroundAllDay":
      return {
        backgroundColor: SBColor.gray,
        color: "white",
      };
  }
};

const SectionBeachChairsMapState = ({
  setMapBounds,
  zoom,
  setZoom,
}: IMapStateProps) => {
  const dispatch = useAppDispatch();
  const [markers, setMarkers] = useState<object[]>();
  const section = useSelector(getSelectedSectionSelector);
  const start = useSelector(getDateFromSelector);
  const end = useSelector(getDateToSelector);
  const startRef = React.useRef<string>(start);
  const endRef = React.useRef<string>(end);
  const publicReference = useSelector(getPublicReferenceSelector);
  const geoJson = React.useMemo(() => section?.geoJson || {}, [section]);
  const city = useSelector(getSelectedCitySelector);
  const inBookingSelectedIds = useSelector(getAllBookedBeachChairs);
  const path = React.useMemo(
    () =>
      Object.keys(geoJson).length !== 0
        ? (geoJson as IGeoPolygon).coordinates[0].map((coord) => {
            return { lat: coord[1], lng: coord[0] };
          })
        : [{ lat: 0, lng: 0 }],
    [geoJson]
  );
  const rows = useSelector(getSectionsSelector);
  useEffect(() => {
    let beachChairMarkers: object[] = [];
    rows?.forEach((row) => {
      (row.rowData as any).forEach((beachChairs: any) => {
        if (
          Object.keys(beachChairs.geoJson).length &&
          beachChairs.geoJson.coordinates[0] !== 0 &&
          beachChairs.geoJson.coordinates[1] !== 0
        ) {
          beachChairMarkers.push(beachChairs);
        }
      });
    });
    setMarkers(beachChairMarkers);
  }, [rows]);
  React.useEffect(() => {
    if (
      section &&
      publicReference
    ) {
      dispatch(
        getPublicSectionsBeachChairsAvailabilityRequest({
          publicReference,
          sectionId: section!.id,
          start,
          end,
          hasSubsections: false,
        })
      );
      startRef.current = start;
      endRef.current = end;
    }
    // eslint-disable-next-line
  }, [start, end]);

  React.useEffect(() => {
    const bounds = new window.google.maps.LatLngBounds();
    path.forEach((point) => {
      bounds.extend({
        lat: point.lat,
        lng: point.lng,
      });
    });
    setMapBounds(bounds);
  }, [path, setMapBounds, setZoom]);

  return geoJson &&
    Object.keys(geoJson).length === 0 &&
    geoJson.constructor === Object ? (
    <></>
  ) : (
    <>
      {markers?.map((marker, index) => {
        const beachChair = marker as any;
        const lat = parseFloat(beachChair.geoJson.coordinates[0]);
        const lng = parseFloat(beachChair.geoJson.coordinates[1]);
        let box = "FREE";
        if ((marker as any).timeEnum === "boxBackgroundAllDay") {
          box = "BOOKING";
        }
        if ((marker as any).reserved) {
          box = "RESERVED";
        }

        const isInBooking =
          inBookingSelectedIds.filter(
            (item) => item.beachChairId === beachChair.id
          ).length > 0;

        return (
          <OverlayViewF
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            key={`${index}-${marker}`}
            position={{
              lat,
              lng,
            }}
          >
            <Box
              sx={{
                transform: "translate(-50%,-200%)",
                ...((zoom ?? 0) > setZoomLevel && {
                  ":hover": {
                    animation: "pulse 2s infinite",
                    zIndex: 999999999,
                  },
                }),
                display: "grid",
                gridTemplateRows: "1px auto auto",
                justifyContent: "center",
                textAlign: "center",
                alignItems: "center",
                ...(box === "FREE"
                  ? {
                      color: SBColor.blue,
                    }
                  : { color: SBColor.white }),
                ...(box === "RESERVED" && {
                  color: "green",
                }),
              }}
              onClick={() => {
                dispatch(setRowRequest({ selectedRow: beachChair.row }));
                dispatch(
                  selectBeachChairRequest({
                    selectedBeachChair: beachChair,
                  })
                );
                dispatch(
                  selectBeachChairToBookingRequest({
                    beachChairId: beachChair.id,
                  })
                );
                dispatch(
                  redirectRequest({
                    path: `/s/strandkorb/${city?.name}/${section?.name}/${section?.id}/${beachChair.id}`,
                  })
                );
              }}
            >
              <Box
                className="pin1"
                sx={{
                  transform: `rotate(-45deg) ${
                    (zoom ?? 0) > setZoomLevel
                      ? "translate(-10%, -60%);"
                      : "translate(40%, -110%);"
                  }`,
                  width: (zoom ?? 0) > setZoomLevel ? "30px" : "10px",
                  height: (zoom ?? 0) > setZoomLevel ? "30px" : "10px",
                  display: "grid",
                  background: "white",
                  cursor: "pointer",
                  border: `2px solid ${SBColor.blue}`,
                  boxShadow: `0 0 15px ${SBColor.grayTransparent}`,

                  ...(isInBooking
                    ? {
                        backgroundColor: SBColor.orange,
                        color: SBColor.white,
                      }
                    : getTimeStyle(beachChair.timeEnum)),
                  ...(box === "RESERVED" && {
                    backgroundColor: SBColor.white,
                    color: "green",
                    border: `2px dashed green`,
                  }),
                  ...(beachChair.reason === Reason.STOP_SALE && {
                    border: `2px dashed ${SBColor.blue}`,
                  }),
                  ...(beachChair.attributes?.color && {
                    borderTop: `2px dashed ${
                      EColor[beachChair.attributes.color as keyof typeof EColor]
                    } !important`,
                    borderRight: `2px dashed ${
                      EColor[beachChair.attributes.color as keyof typeof EColor]
                    } !important`,
                  }),
                }}
              />
              <Box
                sx={{
                  display: "grid",
                  width: "70px",
                  height: "30px",
                  gridTemplateColumns: "50% 50%",
                }}
              >
                {(zoom ?? 0) > setZoomLevel ? (
                  <Typography
                    sx={{
                      /*border: `2px solid ${SBColor.blue}`,
                padding: "2px",
                borderRadius: "5px",
                background: SBColor.white,*/
                      maxWidth: "100px",
                      minWidth: "100px",
                      textAlign: "center",
                      transform: "translate(-15%, -35%)",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      fontSize: "15px",
                      fontWeight: "bolder",
                      color: SBColor.blue,
                    }}
                    data-testid="id"
                  >
                    {beachChair.publicNumber}
                  </Typography>
                ) : null}
                {(zoom ?? 0) > setZoomLevel && beachChair.lockId !== null ? (
                  <CardMedia
                    sx={{
                      transform: "translate(-200%, 25%)",
                      height: "30px",
                      width: "20px",
                    }}
                    data-testid="hasLock"
                    component="img"
                    src={lock}
                  />
                ) : (
                  <Box />
                )}
                {(zoom ?? 0) > setZoomLevel && beachChair.seasonBeachChair ? (
                  <EventBusy
                    data-testid="isSeasonBeachChair"
                    sx={{
                      gridColumnStart: "2px",
                      gridColumnEnd: "2px",
                      color: SBColor.blue,
                      transform: "translate(115%, -65%)",
                      height: "30px !important",
                      width: "40px !important",
                    }}
                  />
                ) : (
                  <Box />
                )}
              </Box>
              {(zoom ?? 0) > setZoomLevel ? (
                <Typography
                  sx={{
                    transform: "translate(0, 20%)",
                    fontSize: "11px",
                    fontWeight: "bold",
                    zIndex: 999,
                  }}
                  data-testid="model"
                >
                  {shortModels[beachChair.model as keyof typeof shortModels]}
                </Typography>
              ) : null}
            </Box>
          </OverlayViewF>
        );
      })}
      <Polygon
        path={path}
        options={{
          strokeColor: stylesConfig.blueColor,
          fillColor: stylesConfig.orangeColor,
          strokeOpacity: 0.78,
          strokeWeight: 1,
          fillOpacity: 0.5,
        }}
      />
    </>
  );
};

export default SectionBeachChairsMapState;
