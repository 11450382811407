import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../store";
import {
  getBeachChairDetailedInformationRequest,
  selectBeachChairTriggerRequest,
} from "../../../store/reducers/availabilityReducer";
import { selectedBeachChairDetailedInformationSelector } from "../../../store/selectors/availabilitySelectors";

export function useSelectedBeachChair() {
  const params = window.location.pathname.split("/");
  const beachChairId = useMemo(() => {
    const arrayParams = params as any;
    return +arrayParams[arrayParams.length - 1]
  }, [params])
  const dispatch = useAppDispatch();
  const selectedBeachChair = useSelector(
    selectedBeachChairDetailedInformationSelector(Number(beachChairId))
  );

  React.useEffect(() => {
    dispatch(
      getBeachChairDetailedInformationRequest({
        beachChairId: Number(beachChairId),
      })
    );
    // eslint-disable-next-line
  }, [beachChairId]);

  React.useEffect(() => {
    selectedBeachChair &&
      setTimeout(() => {
        dispatch(selectBeachChairTriggerRequest({ trigger: false }));
      }, 1000);
    // eslint-disable-next-line
  }, [selectedBeachChair]);

  return {
    selectedBeachChair,
  };
}
