import { create } from "zustand";

interface WidgetZustandStore {
  widgetUrlZustand: string;
  setwidgetUrlZustand: (url: string) => void;
  publicRef: string;
  setPublicRef: (publicRef: string) => void;
}

export const useWidgetZustand = create<WidgetZustandStore>((set) => {
  return {
    widgetUrlZustand: "",
    setwidgetUrlZustand: (widgetUrlZustand: string) =>
      set((state) => ({
        ...state,
        widgetUrlZustand,
      })),
      publicRef: "",
      setPublicRef: (publicRef: string) =>
        set((state) => ({
          ...state,
          publicRef,
        })),
  };
});
