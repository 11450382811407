import {
  CardMedia,
  Typography,
  Divider,
  Button,
  LinearProgress,
  Box,
  useMediaQuery,
  useTheme,
  IconButton,
  Tooltip,
} from "@mui/material";
import React, { useMemo } from "react";
import {
  BeachChairSelectAnimation,
  BeachChairStyles,
  BeachChairStylesRoot,
} from "../../utils/hooks/styles/beachChairStyle";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useSelector } from "react-redux";
import { dateToProperDateCartFormat } from "../../utils/convertions/dataConversion";
import {
  getErrorSelector,
  getRatePricesSelector,
  selectedDatesPriceSelector,
} from "../../store/selectors/priceSelectors";
import { useAppDispatch } from "../../store";
import { getPriceRequest } from "../../store/reducers/priceReducer";
import {
  getDateFromSelector,
  getDateToSelector,
} from "../../store/selectors/mainAppSelectors";
import {
  addBeachChairBookingRequest,
  getAllBookedBeachChairs,
  getBeachChairAvailableCheckRequest,
} from "../../store/reducers/bookingReducer";
import { useTranslation } from "react-i18next";
import { getSelectedCitySelector } from "../../store/selectors/citySelectors";
import { getSelectedSectionSelector } from "../../store/selectors/sectionSelectors";
import {
  changeBeachChairDates,
  changeBeachChairDatesSelected,
  redirectRequest,
  selectionModalRequest,
} from "../../store/reducers/mainAppReducer";
import { dateDiffInDays } from "../../utils/convertions/getDateDiffInDays";
import CloseIcon from "@mui/icons-material/Close";
import {
  allowBookSelector,
  getBeachChairAvailableSelector,
  getPublicReferenceSelector,
} from "../../store/selectors/bookingSelectors";
import { selectBeachChairModelRateRequest } from "../../store/reducers/availabilityReducer";
import { getSelectedRegionSelector } from "../../store/selectors/regionSelectors";
import { useSelectedBeachChair } from "../../utils/hooks/customHooks/useSelectedBeachChair";
import {
  getSectionsSelector,
} from "../../store/selectors/availabilitySelectors";
import ReactMarkdown from "react-markdown";
import { EURO } from "../../utils/convertions/euroFormater";
import lock from "./../../assets/lock.png";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import InfoIcon from "@mui/icons-material/Info";
import { isDisabled } from "../../utils/checkers/isDisabled";
import { EAvailabilityTime } from "../../components/locationAvailabilitiesBeachChairs/availabilitiesBeachChair/availabilitiesBeachChair";
import { isAfter, isBefore } from "date-fns";
const changeRange = (range: string): string => {
  const rangeArray = range.split("-");
  return `${rangeArray[0]}:00 - ${rangeArray[1]}:00`;
};

const BeachChair = () => {
  const { t } = useTranslation(["beachchairs"]);
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useAppDispatch();
  const city = useSelector(getSelectedCitySelector);
  const section = useSelector(getSelectedSectionSelector);
  const region = useSelector(getSelectedRegionSelector);
  const { selectedBeachChair } = useSelectedBeachChair();
  const allowBook = useSelector(allowBookSelector);
  const start = useSelector(getDateFromSelector);
  const end = useSelector(getDateToSelector);
  const available = useSelector(getBeachChairAvailableSelector);
  const ratePrices = useSelector(getRatePricesSelector);
  const selectedDatesPrice = useSelector(selectedDatesPriceSelector);
  const errorText = useSelector(getErrorSelector);
  const inBookingSelectedIds = useSelector(getAllBookedBeachChairs);
  const publicReference = useSelector(getPublicReferenceSelector);
  const rows = useSelector(getSectionsSelector);
  /*const { editedChannelUuid, clearCreatedBooking } =
    useCreatedBookingIndicator();*/
  React.useEffect(() => {
    selectedBeachChair &&
      dispatch(
        getBeachChairAvailableCheckRequest({
          start,
          end,
          id: selectedBeachChair.id,
          publicReference,
        })
      );
    dispatch(getPriceRequest({ start, end }));
    // eslint-disable-next-line
  }, [start, end, selectedBeachChair, publicReference]);

  const disabledButton =
    isDisabled(
      inBookingSelectedIds.filter(
        (item) => item.beachChairId === selectedBeachChair?.id
      ),
      start,
      end
    ) || !available;

  const inStopSales = useMemo(() => {
    let inStopSale = false;
    const startDate = new Date(start);
    const endDate = new Date(end);
    selectedBeachChair?.stopSaleDates?.forEach((stopSale) => {
      const stopSaleObj = JSON.parse(JSON.stringify(stopSale));
      const stopSaleStart = new Date((stopSaleObj as any).start);
      const stopSaleEnd = new Date((stopSaleObj as any).end);
      if (isAfter(startDate, stopSaleStart) && isBefore(endDate, stopSaleEnd)) {
        inStopSale = true;
      }
    });
    return inStopSale;
  }, [selectedBeachChair, start, end]);

  const isAvailableToBook = useMemo(() => {
    let isAvailable = true;
    selectedBeachChair &&
      rows.forEach((row) =>
        row.rowData.forEach((beachChair) => {
          if (
            beachChair.id === selectedBeachChair.id &&
            beachChair.timeEnum === EAvailabilityTime.Allday
          ) {
            isAvailable = false;
          }
        })
      );
    return isAvailable;
  }, [selectedBeachChair, rows]);

  return (
    <Box
      sx={{
        ...BeachChairStylesRoot,
        ...BeachChairSelectAnimation,
      }}
    >
      <IconButton
        sx={BeachChairStyles.buttonMin}
        aria-label="minmax"
        onClick={() => {
          dispatch(
            redirectRequest({
              path: `/s/${region?.name}/${city?.name}/${section?.id}`,
            })
          );
        }}
      >
        <CloseIcon />
      </IconButton>

      <CardMedia
        sx={BeachChairStyles.beachChairImage}
        image={
          selectedBeachChair?.images && selectedBeachChair?.images.length > 0
            ? selectedBeachChair.images[0]
            : `/images/beachChair_${selectedBeachChair?.model}.jpg`
        }
        title="Strandkorb"
      />
      <Box sx={BeachChairStyles.infoBox}>
        <Box>
          <Typography sx={BeachChairStyles.typographyBoldBlue}>
            {t("publicNumber")}: {selectedBeachChair?.publicNumber}
          </Typography>
        </Box>
        <Box>
          <Typography sx={BeachChairStyles.typographyBoldGrayCity}>
            {selectedBeachChair?.seasonBeachChair && (
              <Box sx={{ ...BeachChairStyles.seasonGrid }}>
                <CalendarMonthIcon
                  sx={{ ...BeachChairStyles.calendarMonthIcon }}
                />
                {t("seasonBeachChairInfo")}
              </Box>
            )}
          </Typography>
        </Box>
        {selectedBeachChair?.attributes?.color ? (
          selectedBeachChair?.attributes?.color !== "0" ? (
            <Box>
              <Typography sx={BeachChairStyles.typographyBoldBlue}>
                {`${t("color")} : ${t(
                  `colors.${selectedBeachChair.attributes.color}`
                )}`}
              </Typography>
            </Box>
          ) : null
        ) : null}
        <Box sx={BeachChairStyles.infoBox}>
          {selectedBeachChair?.model ? (
            <Typography sx={BeachChairStyles.typographyBoldBlue}>
              {t(`common:models.${selectedBeachChair?.model}`)}
            </Typography>
          ) : null}
          <Box sx={BeachChairStyles.typographyBoldGray}>
            <LocationOnIcon />
            <Typography sx={BeachChairStyles.typographyBoldGrayCity}>
              {city?.name}, {section?.name}
            </Typography>
          </Box>
          <Divider sx={BeachChairStyles.divider} />
          {inStopSales ? (
            <Box sx={BeachChairStyles.twoRowsGrid}>
              <Typography sx={BeachChairStyles.typographyBoldBlue}>
                {t("unavailableReasons.STOP_SALE")}
              </Typography>
            </Box>
          ) : isAvailableToBook ? (
            <Box sx={BeachChairStyles.twoRowsGrid}>
              {selectedBeachChair &&
                (selectedBeachChair as any)?.timeEnum !==
                EAvailabilityTime.Allday ? (
                dateDiffInDays(new Date(start), new Date(end)) < 1 ? (
                  ratePrices ? (
                    ratePrices.length > 0 ? (
                      ratePrices.map((rate, index) => (
                        <Box
                          key={`${rate.type}-${rate.price}-${index}`}
                          sx={{
                            ...BeachChairStyles.twoColumnsGrid,
                            ...BeachChairStyles.pricesGrid,
                          }}
                        >
                          <Typography
                            sx={{
                              ...BeachChairStyles.typographyGray,
                              ...BeachChairStyles.typographyGrid,
                            }}
                          >{rate.type ? <Box>
                            {t(`durations.${rate.type.toLowerCase()}`)}
                          </Box> : null}

                            {rate.discount}
                            {rate.discount && (
                              <Box style={{ marginTop: "-10px" }}>
                                {t("discount")}{" "}
                                <Tooltip title={`${t("discountTooltip")}`}>
                                  <InfoIcon
                                    style={{
                                      position: "relative",
                                      top: "4px",
                                    }}
                                  />
                                </Tooltip>
                              </Box>
                            )}
                            <Box>
                              {`${dateToProperDateCartFormat(start)}`}{" "}
                              {rate.range && (
                                <strong>{changeRange(rate.range)}</strong>
                              )}
                            </Box>
                            <Box>
                              <strong>{EURO(rate.price || 0).format()}</strong>
                            </Box>
                          </Typography>
                          <Box sx={BeachChairStyles.noTextDecoration}>
                            <Button
                              disabled={disabledButton}
                              sx={BeachChairStyles.button}
                              onClick={() => {
                                dispatch(
                                  changeBeachChairDates({ rate: rate.type })
                                );
                                dispatch(
                                  selectBeachChairModelRateRequest({
                                    model: selectedBeachChair?.model || "",
                                    rate: rate.type,
                                    discount: rate.discount,
                                  })
                                );
                                if (allowBook) {
                                  dispatch(
                                    selectionModalRequest({ toggle: true })
                                  );
                                } else {
                                  dispatch(
                                    addBeachChairBookingRequest({
                                      discount: rate.discount,
                                    })
                                  );
                                }
                              }}
                            >
                              {t("addToCart")}
                            </Button>
                          </Box>
                        </Box>
                      ))
                    ) : (
                      <Typography sx={BeachChairStyles.typographyBoldBlue}>
                        {t("moreDaysInfo")}
                      </Typography>
                    )
                  ) : (
                    <LinearProgress />
                  )
                ) : selectedDatesPrice && selectedDatesPrice?.length > 0 ? (
                  selectedDatesPrice.map((rate, index) => (
                    <Box
                      sx={{
                        ...BeachChairStyles.twoColumnsGrid,
                        ...BeachChairStyles.pricesGrid,
                      }}
                    >
                      <Typography
                        sx={{
                          ...BeachChairStyles.typographyGray,
                          ...BeachChairStyles.typographyGrid,
                        }}
                      >
                        <Box>
                          {dateDiffInDays(new Date(start), new Date(end)) + 1}{" "}
                          {t("days")}
                        </Box>
                        {rate.discount && (
                          <Box style={{ marginTop: "-10px" }}>
                            {t("discount")}{" "}
                            <Tooltip title={`${t("discountTooltip")}`}>
                              <InfoIcon
                                style={{ position: "relative", top: "4px" }}
                              />
                            </Tooltip>
                          </Box>
                        )}
                        <Box>{`${dateToProperDateCartFormat(
                          start
                        )} - ${dateToProperDateCartFormat(end)}`}</Box>
                        <Box>
                          <strong>{EURO(rate.price || 0).format()}</strong>
                        </Box>
                      </Typography>
                      <Box sx={BeachChairStyles.noTextDecoration}>
                        <Button
                          disabled={disabledButton}
                          sx={BeachChairStyles.button}
                          onClick={() => {
                            dispatch(changeBeachChairDatesSelected({}));
                            dispatch(
                              selectBeachChairModelRateRequest({
                                model: selectedBeachChair?.model || "",
                                discount: rate.discount || false,
                                rate: "none",
                              })
                            );
                            if (allowBook) {
                              dispatch(selectionModalRequest({ toggle: true }));
                            } else {
                              dispatch(
                                addBeachChairBookingRequest({
                                  discount: rate.discount || false,
                                })
                              );
                            }
                          }}
                        >
                          {t("addToCart")}
                        </Button>
                      </Box>
                    </Box>
                  ))
                ) : errorText !== "" ? (
                  errorText !== "undefined" ? (
                    <Typography sx={BeachChairStyles.typographyBoldBlue}>
                      {errorText}
                    </Typography>
                  ) : (
                    <Box sx={BeachChairStyles.empty} />
                  )
                ) : selectedDatesPrice?.length === 0 ? (
                  <LinearProgress />
                ) : (
                  <Typography sx={BeachChairStyles.typographyBoldBlue}>
                    {t("moreDaysInfo")}
                  </Typography>
                )
              ) : (
                <Box sx={BeachChairStyles.typographyBoldBlue}>
                  {t(
                    `unavailableReasons.${(selectedBeachChair as any)?.reason || "BOOKED"
                    }`
                  )}
                </Box>
              )}
            </Box>
          ) : (
            <Box sx={BeachChairStyles.typographyBoldBlue}>
              {t(`unavailableReasons.BOOKED`)}
            </Box>
          )}
        </Box>
        {selectedBeachChair?.lockId && (
          <>
            <Divider sx={BeachChairStyles.divider} />
            <Typography sx={BeachChairStyles.typographyBoldBlue}>
              {t("lockInformation")}
            </Typography>
            <Box sx={BeachChairStyles.lockGrid}>
              <CardMedia
                sx={BeachChairStyles.lock}
                data-testid="hasLock"
                component="img"
                src={lock}
              />
              <Typography sx={BeachChairStyles.typographyBoldGrayCity}>
                <ReactMarkdown>{t("lockInformationDescription")}</ReactMarkdown>
              </Typography>
            </Box>
          </>
        )}
        {selectedBeachChair && selectedBeachChair.description !== "" && (
          <Divider sx={BeachChairStyles.divider} />
        )}
        {!matchesMd &&
          selectedBeachChair &&
          selectedBeachChair.description !== "" && (
            <Box sx={{ maxWidth: "310px !important" }}>
              <Typography sx={BeachChairStyles.typographyBoldBlue}>
                {t("additionalInformation")}
              </Typography>
              <Typography sx={BeachChairStyles.typographyBoldGrayCity}>
                <ReactMarkdown>{`${selectedBeachChair?.description}`}</ReactMarkdown>
              </Typography>
            </Box>
          )}
      </Box>
    </Box>
  );
};

export default BeachChair;
