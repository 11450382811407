import { Action } from "@reduxjs/toolkit";
import { all, put, call, takeLatest, select, take } from "typed-redux-saga";
import { beachApi, cityApi, featuresApi, regionApi } from "../../api/api";
import {
  beachFailure,
  clearBeachRequest,
  getBeachRequest,
  getBeachSuccess,
  setBeachRequest,
} from "../reducers/beachReducer";
import {
  selectBeachChairToBookingRequest,
  setPublicReference,
} from "../reducers/bookingReducer";
import {
  regionFailure,
  getRegionRequest,
  getRegionSuccess,
  setRegionRequest,
} from "../reducers/regionReducer";
import {
  cityFailure,
  clearCityRequest,
  getCityRequest,
  getCitySuccess,
  setCityRequest,
} from "../reducers/cityReducer";
import {
  setGlobalBeachChairRequest,
  setGlobalBeachRequest,
  setGlobalCityRequest,
  setGlobalRegionRequest,
  setGlobalSectionRequest,
} from "../reducers/locationReducer";
import {
  clearSectionRequest,
  getSectionByIdRequest,
  getSectionByIdSuccess,
  getSectionRequest,
  getSectionSuccess,
  sectionFailure,
  setSectionRequest,
} from "../reducers/sectionReducer";
import { getVendorRequest } from "../reducers/vendorReducer";
import {
  getCitiesSelector,
  getCityByIdSelector,
} from "../selectors/citySelectors";
import {
  getAllBeachesSelector,
  getBeachByIdSelector,
} from "../selectors/beachSelectors";
import {
  setMapLocationRequest,
  setMapStateRequest,
} from "../reducers/mapReducer";
import { EMapState } from "../../utils/models/EMapState";
import {
  getRegionsByIdSelector,
  getRegionsSelector,
} from "../selectors/regionSelectors";
import { getCentroid } from "../../utils/convertions/getCentroid";
import { getGeoJson } from "../../utils/convertions/getGeoJson";
import {
  getPublicSectionsBeachChairsAvailabilityRequest,
  selectBeachChairRequest,
  setRowIdSubSectionRequest,
} from "../reducers/availabilityReducer";
import {
  getDateFromSelector,
  getDateToSelector,
  getIgnoreCloseExpandMenuSelector,
} from "../selectors/mainAppSelectors";
import {
  leftDrawerChangeRequest,
  mainComponentShowChangeRequest,
  redirectRequest,
  subSectionRequest,
  toggleMapRequest,
  toggleMobileViewExpandMenuRequest,
} from "../reducers/mainAppReducer";
import { getSectionByIdSelector } from "../selectors/sectionSelectors";
import { URLParser } from "../../utils/hooks/customHooks/useUrlParams";
import { getBeachChairByIdSelector } from "../selectors/availabilitySelectors";
import {
  getExtrasRequest,
  resetPreBookingExtrasStateRequest,
} from "../reducers/extrasReducer";
import { getAllExtrasSelector } from "../selectors/extraSelectors";
import { getVendorSelector } from "../selectors/vendorSelectors";

function* getRegionSaga(action: Action) {
  try {
    if (getRegionRequest.match(action)) {
      const { searchPhrase } = action.payload;
      const filterObj = {
        active: true,
      };
      const filters = new URLSearchParams(
        Object.keys(filterObj)
          .filter((obj) => (filterObj as any)[obj] !== undefined)
          .map((key) => `filters%5B${key}%5D=${(filterObj as any)[key]}`)
          .join("&")
      );
      const { data } = yield* call(
        [regionApi, regionApi.regionsControllerFindMany],
        {
          search: searchPhrase,
          orderBy: "name",
        },
        {
          params: filters,
        }
      );
      yield* put(getRegionSuccess({ regions: data.items }));
    }
  } catch (e: any) {
    yield* put(
      regionFailure({
        error: e.error,
      })
    );
  }
}

function* getCitySaga(action: Action) {
  try {
    if (getCityRequest.match(action)) {
      const { searchPhrase } = action.payload;
      const filterObj = {
        active: true,
      };
      const filters = new URLSearchParams(
        Object.keys(filterObj)
          .filter((obj) => (filterObj as any)[obj] !== undefined)
          .map((key) => `filters%5B${key}%5D=${(filterObj as any)[key]}`)
          .join("&")
      );
      const { data } = yield* call(
        [cityApi, cityApi.citiesControllerFindMany],
        {
          search: searchPhrase,
        },
        {
          params: filters,
        }
      );
      yield* put(getCitySuccess({ cities: data.items }));
    }
  } catch (e: any) {
    yield* put(
      cityFailure({
        error: e.error,
      })
    );
  }
}

function* getBeachSaga(action: Action) {
  try {
    if (getBeachRequest.match(action)) {
      const { searchPhrase, cityId } = action.payload;
      const filterObj = {
        active: true,
      };
      const filters = new URLSearchParams(
        Object.keys(filterObj)
          .filter((obj) => (filterObj as any)[obj] !== undefined)
          .map((key) => `filters%5B${key}%5D=${(filterObj as any)[key]}`)
          .join("&")
      );
      const { data } = cityId
        ? yield* call(
            [beachApi, beachApi.beachesControllerFindMany],
            {
              search: searchPhrase,
              filterBy: "cityId",
              filter: `${cityId}`,
            },
            {
              params: filters,
            }
          )
        : yield* call(
            [beachApi, beachApi.beachesControllerFindMany],
            {
              search: searchPhrase,
            },
            {
              params: filters,
            }
          );
      yield* put(getBeachSuccess({ beaches: data.items }));
    }
  } catch (e: any) {
    yield* put(
      beachFailure({
        error: e.error,
      })
    );
  }
}

function* getSectionSaga(action: Action) {
  try {
    if (getSectionRequest.match(action)) {
      const { searchPhrase, beachId, onlineBooking } = action.payload;
      const filterObj = {
        beachId,
        onlineBooking: onlineBooking ? onlineBooking : undefined,
      };
      const filters = new URLSearchParams(
        Object.keys(filterObj)
          .filter((obj) => (filterObj as any)[obj] !== undefined)
          .map((key) => `filters%5B${key}%5D=${(filterObj as any)[key]}`)
          .join("&")
      );
      const { data } = yield* call(
        [featuresApi, featuresApi.sectionsControllerFindMany],
        {
          search: searchPhrase,
        },
        {
          params: filters,
        }
      );
      if (onlineBooking) {
        const cities = yield* select(getCitiesSelector);
        const regions = yield* select(getRegionsSelector);
        const beaches = yield* select(getAllBeachesSelector);
        const uniqueCityIds = [
          ...new Set(data.items.map((section) => section.cityId)),
        ];
        const uniqueBeachIds = [
          ...new Set(data.items.map((section) => section.beachId)),
        ];
        const remaningCities = cities.filter((el) =>
          uniqueCityIds.includes(el.id)
        );
        const uniqueRegionsIds = [
          ...new Set(remaningCities.map((city) => city.regionId)),
        ];
        const remaningRegions = regions.filter((el) =>
          uniqueRegionsIds.includes(el.id)
        );
        const remaningBeaches = beaches.filter((el) =>
          uniqueBeachIds.includes(el.id)
        );

        yield* put(setMapStateRequest({ mapState: EMapState.ALL_CITIES }));
        yield* put(getRegionSuccess({ regions: remaningRegions }));
        yield* put(getCitySuccess({ cities: remaningCities }));
        yield* put(getBeachSuccess({ beaches: remaningBeaches }));
      }
      yield* put(getSectionSuccess({ sections: data.items }));
      const { prefix, suffix, suffixId, id } = URLParser(
        window.location.pathname
      );
      if (prefix.includes("r")) {
        if (prefix.includes("result")) {
          yield* put(setMapStateRequest({ mapState: EMapState.ALL_CITIES }));
        } else {
          yield* put(setGlobalRegionRequest({ id: +id }));
        }
      }
      if (prefix.includes("c")) {
        if (prefix.includes("content")) {
          yield* put(toggleMapRequest({ toggle: false }));
        } else {
          yield* put(setGlobalCityRequest({ id: +id }));
        }
      }
      if (prefix.includes("b")) {
        if (!prefix.includes("booking")) {
          yield* put(setGlobalBeachRequest({ id: +id }));
        } else {
          if (suffix.includes("result_bank_transfer")) {
            yield* put(toggleMapRequest({ toggle: false }));
          } else {
            yield* put(toggleMapRequest({ toggle: true }));
          }
        }
      }
      if (prefix.includes("s")) {
        if (suffix.includes("strandkorb")) {
          yield* put(
            setGlobalBeachChairRequest({ suffixId: +suffixId, id: +id })
          );
          const publicReference =
            ((data.items.find((item) => item.id === +suffixId) as any)
              ?.publicReference as never as string) ?? "";
          yield* put(setPublicReference({ publicReference }));
          yield* put(getVendorRequest({ publicReference }));
          yield* take("vendor/getVendorSuccess");
          const cfg = yield* select(getVendorSelector);
          const configuration = cfg?.configuration;
          if (configuration?.beachChairView === "map") {
            yield* put(toggleMapRequest({ toggle: true }));
          }
        } else {
          const publicReference =
            ((data.items.find((item) => item.id === +id) as any)
              ?.publicReference as never as string) ?? "";
          yield* put(setPublicReference({ publicReference }));
          if (publicReference) {
            yield* put(getVendorRequest({ publicReference }));
            yield* take("vendor/getVendorSuccess");
            const cfg = yield* select(getVendorSelector);
            const configuration = cfg?.configuration;
            if (configuration?.beachChairView === "map") {
              yield* put(toggleMapRequest({ toggle: true }));
            }
          }
          if (id.includes("extras")) {
            yield* put(subSectionRequest({ toggle: false }));
            yield* put(
              setGlobalSectionRequest({ id: +suffixId, isExtras: true })
            );
          } else {
            if (!isNaN(+suffixId) && !isNaN(+id)) {
              yield* put(subSectionRequest({ toggle: true }));
              yield* put(
                setGlobalSectionRequest({
                  id: +suffixId,
                  hasSubsections: true,
                  rowId: +id,
                })
              );
            } else {
              yield* put(subSectionRequest({ toggle: false }));
              yield* put(setGlobalSectionRequest({ id: +id }));
            }
          }
        }
      }
      if (prefix.includes("404") || prefix.includes("500")) {
        yield* put(setMapStateRequest({ mapState: EMapState.ALL_CITIES }));
      }
    }
  } catch (e: any) {
    yield* put(
      sectionFailure({
        error: e.error,
      })
    );
  }
}

function* getSectionByIdSaga(action: Action) {
  try {
    if (getSectionByIdRequest.match(action)) {
      const { id } = action.payload;
      const { data } = yield* call(
        [featuresApi, featuresApi.sectionsControllerFindMany],
        {
          search: "searchPhrase",
          filterBy: "id",
          filter: `${id}`,
        }
      );
      yield* put(
        setPublicReference({
          publicReference: (data.items[0] as any).publicReference,
        })
      );
      yield* put(
        getVendorRequest({
          publicReference: (data.items[0] as any).publicReference,
        })
      );
      const city = yield* call([cityApi, cityApi.citiesControllerFindOne], {
        id: (data.items[0] as any).cityId,
      });
      yield* put(setCityRequest({ city: city.data }));
      yield* put(getSectionByIdSuccess({ section: data.items[0] }));
    }
  } catch (e: any) {
    yield* put(
      sectionFailure({
        error: e.error,
      })
    );
  }
}

function* setSectionSaga(action: Action) {
  try {
    if (setSectionRequest.match(action)) {
      const { publicReference } = action.payload;
      yield* put(setPublicReference({ publicReference }));
      yield* put(getVendorRequest({ publicReference }));
      yield* put(resetPreBookingExtrasStateRequest());
    }
  } catch (e: any) {
    yield* put(
      sectionFailure({
        error: e.error,
      })
    );
  }
}

function* setGlobalRegionSaga(action: Action) {
  try {
    if (setGlobalRegionRequest.match(action)) {
      const { id } = action.payload;
      yield* put(setMapStateRequest({ mapState: EMapState.REGION }));
      yield* put(toggleMapRequest({ toggle: true }));
      yield* put(
        toggleMobileViewExpandMenuRequest({
          toggle: false,
        })
      );
      const region = yield* select(getRegionsByIdSelector(id));
      yield* put(clearSectionRequest({}));
      yield* put(clearBeachRequest({}));
      yield* put(clearCityRequest());
      yield* put(setRegionRequest({ region }));
      const centroid = getCentroid(region.geoJson);
      yield* put(
        setMapLocationRequest({
          lat: centroid.geometry.coordinates[0],
          lng: centroid.geometry.coordinates[1],
        })
      );
      yield* put(
        redirectRequest({
          path: `/r/${region.name}/${region.id}`,
        })
      );
    }
  } catch (e: any) {
    yield* put(
      regionFailure({
        error: e.error,
      })
    );
  }
}

function* setGlobalCitySaga(action: Action) {
  try {
    if (setGlobalCityRequest.match(action)) {
      const { id } = action.payload;
      yield* put(setMapStateRequest({ mapState: EMapState.CITY }));
      yield* put(toggleMapRequest({ toggle: true }));
      yield* put(
        toggleMobileViewExpandMenuRequest({
          toggle: false,
        })
      );
      const city = yield* select(getCityByIdSelector(id));
      yield* put(clearSectionRequest({}));
      yield* put(clearBeachRequest({}));
      const regionToSelect = yield* select(
        getRegionsByIdSelector(city.regionId)
      );
      yield* put(setRegionRequest({ region: regionToSelect! }));
      yield* put(setCityRequest({ city }));
      const geoJson = getGeoJson(city.geoJson);
      yield* put(
        setMapLocationRequest({
          lat: geoJson.coordinates[0],
          lng: geoJson.coordinates[1],
        })
      );
      yield* put(
        redirectRequest({
          path: `/c/${regionToSelect?.name}/${city.name}/${city.id}`,
        })
      );
    }
  } catch (e: any) {
    yield* put(
      cityFailure({
        error: e.error,
      })
    );
  }
}
function* setGlobalBeachSaga(action: Action) {
  try {
    if (setGlobalBeachRequest.match(action)) {
      const { id } = action.payload;
      yield* put(setMapStateRequest({ mapState: EMapState.BEACH }));
      yield* put(toggleMapRequest({ toggle: true }));
      yield* put(
        toggleMobileViewExpandMenuRequest({
          toggle: false,
        })
      );
      const beach = yield* select(getBeachByIdSelector(id));
      yield* put(clearSectionRequest({}));
      const cityToSelect = yield* select(
        getCityByIdSelector(beach.cityId || 0)
      );
      yield* put(setCityRequest({ city: cityToSelect! }));
      const regionToSelect = yield* select(
        getRegionsByIdSelector(cityToSelect?.regionId!)
      );
      yield* put(setRegionRequest({ region: regionToSelect! }));
      yield* put(setBeachRequest({ beach }));
      const centroid = getCentroid(beach.geoJson);
      yield* put(
        setMapLocationRequest({
          lat: centroid.geometry.coordinates[0],
          lng: centroid.geometry.coordinates[1],
        })
      );
      yield* put(
        redirectRequest({
          path: `/b/${regionToSelect?.name}/${cityToSelect?.name}/${beach.name}/${beach.id}`,
        })
      );
    }
  } catch (e: any) {
    yield* put(
      beachFailure({
        error: e.error,
      })
    );
  }
}

function* setGlobalSectionSaga(action: Action) {
  try {
    if (setGlobalSectionRequest.match(action)) {
      const { id, isExtras, hasSubsections, rowId } = action.payload;
      yield* put(subSectionRequest({ toggle: hasSubsections || false }));
      if (rowId) {
        yield* put(setRowIdSubSectionRequest({ rowId: rowId }));
      }
      const ignoreCloseExpandMenu = yield* select(
        getIgnoreCloseExpandMenuSelector
      );
      if (!ignoreCloseExpandMenu) {
        yield* put(
          toggleMobileViewExpandMenuRequest({
            toggle: false,
          })
        );
      }
      const section = yield* select(getSectionByIdSelector(id));
      yield* put(
        setSectionRequest({
          section,
          publicReference: (section as any).publicReference,
        })
      );
      if (hasSubsections === false) {
        yield* put(setMapStateRequest({ mapState: EMapState.SECTION }));
      }
      if (hasSubsections && !rowId) {
        yield* put(toggleMapRequest({ toggle: true }));
      } else {
        const cfg = yield* select(getVendorSelector);
        const configuration = cfg?.configuration;
        if (configuration?.beachChairView === "map") {
          yield* put(toggleMapRequest({ toggle: true }));
        } else {
          yield* put(toggleMapRequest({ toggle: false }));
        }
      }
      const start = yield* select(getDateFromSelector);
      const end = yield* select(getDateToSelector);
      const beachToSelect = yield* select(
        getBeachByIdSelector(section.beachId)
      );
      yield* put(setBeachRequest({ beach: beachToSelect! }));
      const cityToSelect = yield* select(getCityByIdSelector(section.cityId));
      yield* put(setCityRequest({ city: cityToSelect! }));
      const regionToSelect = yield* select(
        getRegionsByIdSelector(cityToSelect?.regionId!)
      );
      yield* put(setRegionRequest({ region: regionToSelect! }));
      yield* put(
        getPublicSectionsBeachChairsAvailabilityRequest({
          publicReference: (section as any).publicReference,
          sectionId: section.id,
          start,
          end,
          hasSubsections: hasSubsections || false,
          rowId,
        })
      );
      if (isExtras) {
        yield* put(getExtrasRequest({}));
        yield* take("extras/getExtrasSuccess");
        const extras = yield* select(getAllExtrasSelector);
        if (extras.length > 0)
          yield* put(leftDrawerChangeRequest({ toggle: true }));
        yield* put(
          redirectRequest({
            path: `/s/${regionToSelect?.name}/${cityToSelect?.name}/${section.name}/${section.id}/extras`,
          })
        );
      } else {
        if (rowId) {
          yield* put(
            redirectRequest({
              path: `/s/${regionToSelect?.name}/${cityToSelect?.name}/${section.name}/${section.id}/${rowId}`,
            })
          );
        } else {
          const cfg = yield* select(getVendorSelector);
          const configuration = cfg?.configuration;
          if (configuration?.beachChairView === "map") {
            yield* put(mainComponentShowChangeRequest({ toggle: false }));
          }
          yield* put(
            redirectRequest({
              path: `/s/${regionToSelect?.name}/${cityToSelect?.name}/${section.name}/${section.id}`,
            })
          );
        }
      }
      yield take(
        "availability/getPublicSectionsBeachChairsAvailabilitySuccess"
      );
      if (hasSubsections === true) {
        yield* put(mainComponentShowChangeRequest({ toggle: false }));
        yield* put(setMapStateRequest({ mapState: EMapState.ROWS }));
      } else {
        const centroid = getCentroid(section.geoJson);
        yield* put(
          setMapLocationRequest({
            lat: centroid.geometry.coordinates[0],
            lng: centroid.geometry.coordinates[1],
          })
        );
      }
      if (!hasSubsections) {
        const cfg = yield* select(getVendorSelector);
        const configuration = cfg?.configuration;
        yield* put(setMapStateRequest({ mapState: EMapState.SECTION }));
        if (configuration?.beachChairView === "map") {
          yield* put(mainComponentShowChangeRequest({ toggle: false }));
        } else {
          yield* put(mainComponentShowChangeRequest({ toggle: true }));
        }
      } else if (rowId) {
        yield* put(mainComponentShowChangeRequest({ toggle: true }));
      }
    }
  } catch (e: any) {
    yield* put(
      sectionFailure({
        error: e.error,
      })
    );
  }
}

function* setGlobalBeachChairSaga(action: Action) {
  try {
    if (setGlobalBeachChairRequest.match(action)) {
      const { suffixId, id } = action.payload;
      yield* put(setMapStateRequest({ mapState: EMapState.SECTION }));
      const cfg = yield* select(getVendorSelector);
      const configuration = cfg?.configuration;
      if (configuration?.beachChairView === "map") {
        yield* put(toggleMapRequest({ toggle: true }));
      } else {
        yield* put(toggleMapRequest({ toggle: false }));
      }

      yield* put(
        toggleMobileViewExpandMenuRequest({
          toggle: false,
        })
      );
      const section = yield* select(getSectionByIdSelector(suffixId!));
      const start = yield* select(getDateFromSelector);
      const end = yield* select(getDateToSelector);
      const beachToSelect = yield* select(
        getBeachByIdSelector(section!.beachId)
      );
      yield* put(setBeachRequest({ beach: beachToSelect! }));
      const cityToSelect = yield* select(getCityByIdSelector(section!.cityId));
      yield* put(setCityRequest({ city: cityToSelect! }));
      yield* put(
        setSectionRequest({
          section,
          publicReference: (section as any).publicReference,
        })
      );
      yield* put(
        getPublicSectionsBeachChairsAvailabilityRequest({
          publicReference: (section as any).publicReference,
          sectionId: section.id,
          start,
          end,
          hasSubsections: false,
        })
      );
      yield take(
        "availability/getPublicSectionsBeachChairsAvailabilitySuccess"
      );
      const centroid = getCentroid(section.geoJson);
      yield* put(
        setMapLocationRequest({
          lat: centroid.geometry.coordinates[0],
          lng: centroid.geometry.coordinates[1],
        })
      );
      const beachChair = yield* select(getBeachChairByIdSelector(id));
      yield* put(
        selectBeachChairRequest({
          selectedBeachChair: beachChair,
        })
      );
      yield* put(
        selectBeachChairToBookingRequest({
          beachChairId: beachChair.id,
        })
      );
      yield* put(
        redirectRequest({
          path: `/s/strandkorb/${cityToSelect?.name}/${section?.name}/${section?.id}/${beachChair.id}`,
        })
      );
    }
  } catch (e: any) {
    yield* put(
      sectionFailure({
        error: e.error,
      })
    );
  }
}

function* locationSaga() {
  yield* all([
    takeLatest(getCityRequest.type, getCitySaga),
    takeLatest(getBeachRequest.type, getBeachSaga),
    takeLatest(getSectionByIdRequest.type, getSectionByIdSaga),
    takeLatest(setSectionRequest.type, setSectionSaga),
    takeLatest(getSectionRequest.type, getSectionSaga),
    takeLatest(getRegionRequest.type, getRegionSaga),
    takeLatest(setGlobalRegionRequest.type, setGlobalRegionSaga),
    takeLatest(setGlobalCityRequest.type, setGlobalCitySaga),
    takeLatest(setGlobalBeachRequest.type, setGlobalBeachSaga),
    takeLatest(setGlobalSectionRequest.type, setGlobalSectionSaga),
    takeLatest(setGlobalBeachChairRequest.type, setGlobalBeachChairSaga),
  ]);
}

export default locationSaga;
