import { createSelector } from "reselect";
import { getAllBeaches, getBeachById } from "../reducers/beachReducer";
import { AppState } from "../reducers/rootReducer";

const getAppState = (state: AppState) => state;
const getSelectedBeach = (state: AppState) => state.beach.beach;
const getError = (state: AppState) => state.beach.error;
const getPending = (state: AppState) => state.beach.pending;

export const getAllBeachesSelector = createSelector(
  getAllBeaches,
  (beaches) => beaches
);

export const getBeachByIdSelector = (id: number) =>
  createSelector(getAppState, (state) => getBeachById(state, id)!);

export const getSelectedBeachSelector = createSelector(
  getSelectedBeach,
  (beach) => beach
);
export const getErrorSelector = createSelector(getError, (error) => error);
export const getPendingSelector = createSelector(
  getPending,
  (pending) => pending
);
