import React from "react";

import { Box, Chip } from "@mui/material/";

// ICONS
import ShowerIcon from "@mui/icons-material/Shower";
import AccessibilityIcon from "@mui/icons-material/Accessibility";
import PetsSharpIcon from "@mui/icons-material/PetsSharp";
import InfoIcon from "@mui/icons-material/Info";
import KayakingIcon from "@mui/icons-material/Kayaking";
import CheckroomIcon from "@mui/icons-material/Checkroom";
import LocalDrinkIcon from "@mui/icons-material/LocalDrink";
import FoodBankIcon from "@mui/icons-material/FoodBank";
import WifiIcon from "@mui/icons-material/Wifi";
import WcIcon from "@mui/icons-material/Wc";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import ToysIcon from "@mui/icons-material/Toys";
import IcecreamIcon from "@mui/icons-material/Icecream";
import AccessibleIcon from "@mui/icons-material/Accessible";
import PedalBikeIcon from "@mui/icons-material/PedalBike";
import LocalParkingIcon from "@mui/icons-material/LocalParking";
import SurfingIcon from "@mui/icons-material/Surfing";
import TagFacesIcon from "@mui/icons-material/TagFaces";
import EmojiFlagsIcon from "@mui/icons-material/EmojiFlags";
import CreditCardIcon from "@mui/icons-material/CreditCard";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getSelectedSectionSelector } from "../../store/selectors/sectionSelectors";
import { SectionStyles } from "../../utils/hooks/styles/sectionStyles";

const Attributes = () => {
  const { t } = useTranslation(["common"]);
  const section = useSelector(getSelectedSectionSelector);

  const icons = {
    showers: ShowerIcon,
    nude: AccessibilityIcon,
    dogs: PetsSharpIcon,
    pedalBoat: KayakingIcon,
    dressingRoom: CheckroomIcon,
    rentingToy: ToysIcon,
    drinks: LocalDrinkIcon,
    wlan: WifiIcon,
    freeMagazin: ImportContactsIcon,
    wc: WcIcon,
    wheelChair: AccessibleIcon,
    ice: IcecreamIcon,
    playground: TagFacesIcon,
    food: FoodBankIcon,
    carparking: LocalParkingIcon,
    rentalbikes: PedalBikeIcon,
    sup: SurfingIcon,
    baywatch: EmojiFlagsIcon,
    ccPayment: CreditCardIcon,
  };

  const attributes = section?.attributes
    ? Object.entries(section?.attributes).filter(([key, val]) => val === true)
    : [];
  React.useEffect(() => {
    // dispatch(getLocationRequest({searchPhrase:''}))
  }, []);

  return (
    <Box>
      {attributes.map((key) => {
        let Icon = (icons as any)[key[0]] ? (icons as any)[key[0]] : InfoIcon;
        return (
          <Chip
            key={`key-${key[0]}`}
            label={t(`attributes.${key[0]}`)}
            sx={SectionStyles.chip}
            size="small"
            icon={<Icon />}
          />
        );
      })}
    </Box>
  );
};

export default Attributes;
