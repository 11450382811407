import { createSelector } from "reselect";
import {
  getAllExtras,
  getAllPreBooking,
  getPreBookingExtraById,
} from "../reducers/extrasReducer";
import { AppState } from "../reducers/rootReducer";

const getAppState = (state: AppState) => state;
const getError = (state: AppState) => state.extras.error;
const getPending = (state: AppState) => state.extras.pending;
const getExtraTypeOption = (state: AppState) => state.extras.selectedType;
const getExtraCategoryOption = (state: AppState) =>
  state.extras.selectedCategory;

export const getAllExtrasSelector = createSelector(
  getAllExtras,
  (extras) => extras
);

export const getAllPreBookingSelector = createSelector(
  getAllPreBooking,
  (extras) => extras
);

export const getExtraTypeOptionSelector = createSelector(
  getExtraTypeOption,
  (option) => option
);
export const getExtraCategoryOptionSelector = createSelector(
  getExtraCategoryOption,
  (option) => option
);

export const getPreBookingExtraByIdSelector = (id: number) =>
  createSelector(getAppState, (state) => getPreBookingExtraById(state, id));

export const getErrorSelector = createSelector(getError, (error) => error);
export const getPendingSelector = createSelector(
  getPending,
  (pending) => pending
);
