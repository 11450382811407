import { create } from "zustand";
import { persist } from "zustand/middleware";

interface AffiliateReferenceStore {
  affiliateReference?: string;
  setAffiliateReference: (affiliateReference: string) => void;
}

export const useAffiliateReferenceStore = create<AffiliateReferenceStore>()(
  persist(
    (set) => {
      return {
        affiliateReference: undefined,
        setAffiliateReference: (affiliateReference: string) =>
          set((state) => ({
            ...state,
            affiliateReference: affiliateReference,
          })),
      };
    },
    {
      name: "affiliateReference",
    }
  )
);
