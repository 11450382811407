import React, { ReactChild, ReactChildren, ReactNode } from "react";
import { Drawer as MUIDrawer } from "@mui/material";
import { useRightDrawerStyles } from "../../utils/hooks/styles/rightDrawerStyle";
import { useAppDispatch } from "../../store";
import {
  leftDrawerChangeRequest,
  rightDrawerChangeRequest,
} from "../../store/reducers/mainAppReducer";

export interface ISBWrapperProps {
  state: boolean;
  children: ReactNode | ReactChild | ReactChildren;
  anchor: "left" | "right";
}

const SBDrawer = ({ state, children, anchor }: ISBWrapperProps) => {
  const classes = useRightDrawerStyles();
  const dispatch = useAppDispatch();

  return (
    <MUIDrawer
      style={{ zIndex: 999999999 }}
      classes={{
        paper: anchor === "right" ? classes.drawerRightPaper : classes.drawerLeftPaper ,
        root: classes.root,
      }}
      PaperProps={{ elevation: 0 }}
      anchor={anchor}
      data-testid="sbDrawer"
      open={state}
      onClose={() => {
        dispatch(
          anchor === "right"
            ? rightDrawerChangeRequest({ toggle: false })
            : leftDrawerChangeRequest({ toggle: false })
        );
      }}
    >
      {children}
    </MUIDrawer>
  );
};

export default SBDrawer;
