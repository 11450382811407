import { makeStyles } from "@mui/styles";
import { stylesConfig } from "../../styles/styles";

const globalWidth = { xs: "100vw", sm: "350px", md: "350px" };

export const BookingStyles = {
  root: {
    marginTop: stylesConfig.marginTop,
    alignItems: "center",
    justifyContent: "center",
    maxHeight: `calc(100vh - ${stylesConfig.appBarHeight} - 2*${stylesConfig.marginTop} - 4*${stylesConfig.marginBottom})`,
    borderRadius: 8,
    width: globalWidth,
    backgroundColor: stylesConfig.whiteColor,
  },
  cardHeader: {
    display: "grid",
    height: "70px",
    gap: stylesConfig.marginRight,
    alignItems: "center",
    justifyContent: "center",
    gridTemplateColumns: "auto auto",
    color: stylesConfig.whiteColor,
    width: globalWidth,
    borderRadius: `20px 0 0 20px`,
    borderBlockColor: stylesConfig.whiteColor,
    backgroundColor: stylesConfig.blueColor,
  },
  cardFooter: {
    display: "grid",
    height: "140px",
    alignItems: "center",
    justifyContent: "center",
    justifyItems: "center",
    borderRadius: `20px 0 0 20px`,
    borderBlockColor: stylesConfig.whiteColor,
    backgroundColor: stylesConfig.lightGrayColor,
  },
  customBadge: {
    width: stylesConfig.buttonIconSize,
    height: stylesConfig.buttonIconSize,
    alignItems: "center",
    justifyContent: "center",
    border: `2px solid ${stylesConfig.lightGrayColor}`,
    borderRadius: "35%",
  },
  icon: {
    width: stylesConfig.iconSize,
    height: stylesConfig.iconSize,
  } as const,
  iconButton: {
    color: stylesConfig.blueColor,
    boxShadow: stylesConfig.grayBoxShadow,
    borderRadius: stylesConfig.borderRadius,
    width: stylesConfig.buttonIconSize,
    height: stylesConfig.buttonIconSize,
    justifySelf: "right",
  },
  button: {
    width: "120px",
    color: `${stylesConfig.whiteColor} !important`,
    backgroundColor: `${stylesConfig.orangeColor} !important`,
    borderRadius: stylesConfig.borderRadius,
    boxShadow: stylesConfig.orangeBoxShadow,
    "&:hover": {
      backgroundColor: `${stylesConfig.orangeColor} !important`,
    },
    fontSize: {
      xs: "10px",
      sm: "10px",
      md: "12px",
    },
  },
  twoColumns: {
    display: "grid",
    gap: stylesConfig.marginRight,
    gridTemplateColumns: "auto auto",
    alignItems: "center",
  },
  linkTypographyBoldBlue: {
    fontWeight: "bold",
    color: stylesConfig.blueColor,
    fontSize: "1rem",
    cursor: "pointer",
  } as const,
  typographyBoldBlue: {
    fontSize: 16,
    fontWeight: "bold",
    color: stylesConfig.blueColor,
  },
  typographyBlue: {
    fontSize: 16,
    color: stylesConfig.blueColor,
  },
  typographyBlueRight: {
    fontSize: 16,
    color: stylesConfig.blueColor,
    textAlign: "right",
  } as const,
  typographyBoldGray: {
    fontSize: 14,
    fontWeight: "bold",
    color: stylesConfig.grayColor,
  },
  typographyGray: {
    fontSize: 14,
    color: stylesConfig.grayColor,
    justifySelf: "right",
  },
  cartItem: {
    height: 130,
    paddingLeft: stylesConfig.marginLeft,
    paddingRight: stylesConfig.marginRight,
    display: "grid",
    gridAutoRows: "auto",
    gap: stylesConfig.marginTop,
  },
  autoRows: {
    paddingTop: stylesConfig.marginTop,
    display: "grid",
    gridAutoRows: "auto",
    gap: stylesConfig.marginTop,
    maxHeight: "55vh",
    overflowY: "scroll",
    boxSizing: "content-box",
    "&::-webkit-scrollbar": {
      width: 1,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: stylesConfig.lightGrayColor,
    },
  } as const,
  divider: {
    width: `inherit`,
  },
};

export const useBookingStyles = makeStyles((theme) => ({
  customBadge: {
    backgroundColor: `${stylesConfig.orangeColor} !important`,
    color: `${stylesConfig.whiteColor} !important`,
  },
}));
