import { createSelector } from "reselect";
import { AppState } from "../reducers/rootReducer";

const getVendor = (state: AppState) => state.vendor.vendor;
const getError = (state: AppState) => state.vendor.error;
const getPending = (state: AppState) => state.vendor.pending;

export const getVendorSelector = createSelector(
  getVendor,
  (vendor) => vendor
);

export const getErrorSelector = createSelector(getError, (error) => error);
export const getPendingSelector = createSelector(
  getPending,
  (pending) => pending
);
