import { ResponseExtrasBookingDto } from "../../api-client/generated";

export const extrasCount = (extras?: ResponseExtrasBookingDto[]): number => {
  let count = 0;
  if (extras && extras.length > 0) {
    count = extras
      .map((item) => item.amount)
      .reduce((accumulator, current) => accumulator + current);
  }
  return count;
};
