import { createSelector } from "reselect";
import { getAllRegions, getRegionById } from "../reducers/regionReducer";
import { AppState } from "../reducers/rootReducer";

const getAppState = (state: AppState) => state;
const getSelectedRegion = (state: AppState) => state.region.region;
const getError = (state: AppState) => state.region.error;
const getPending = (state: AppState) => state.region.pending;

export const getRegionsSelector = createSelector(
  getAllRegions,
  (regions) => regions
);

export const getRegionsByIdSelector = (id: number) =>
  createSelector(getAppState, (state) => getRegionById(state, id)!);

export const getSelectedRegionSelector = createSelector(
  getSelectedRegion,
  (region) => region
);
export const getErrorSelector = createSelector(getError, (error) => error);
export const getPendingSelector = createSelector(
  getPending,
  (pending) => pending
);
