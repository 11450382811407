import { Action } from "@reduxjs/toolkit";
import { all, put, call, takeLatest, select } from "typed-redux-saga";
import {
  BookingResponseDto,
  PaymentFlow,
  PublicCreateBeachChairBookingBodyDto,
  PublicUpdatePaymentMethod,
} from "../../api-client/generated";
import { bookingsApi, paymentsApi, publicApi, vendorApi } from "../../api/api";
import {
  addBeachChairBookingRequest,
  addBeachChairBookingSuccess,
  addBookedBeachChairRequest,
  addCustomerBookingRequest,
  addCustomerBookingSuccess,
  bookingFailure,
  confirmBankTransferBookingRequest,
  confirmBankTransferBookingSuccess,
  createBookingRequest,
  createBookingSuccess,
  deleteBeachChairFromBookingRequest,
  deleteBeachChairFromBookingSuccess,
  flushBookedBeachChairsRequest,
  deleteExtrasFromBookingRequest,
  deleteExtrasFromBookingSuccess,
  flushCurrentBookingRequest,
  flushCurrentBookingSuccess,
  getBookingRequest,
  getBookingSuccess,
  removeBookedBeachChairRequest,
  setBookingRef,
  updateBookingPaymentMethodRequest,
  getBookingDetailsRequest,
  getBookingDetailsSuccess,
  redirectToPdfRequest,
  getBeachChairAvailableCheckRequest,
  getBeachChairAvailableCheckSuccess,
  putBookingAbortPaymentRequest,
  putBookingAbortPaymentSuccess,
  setPublicReference,
} from "../reducers/bookingReducer";
import {
  redirectRequest,
  rightDrawerBookingDetailsChangeRequest,
  rightDrawerChangeRequest,
} from "../reducers/mainAppReducer";
import { getSelectedBeachChairModelRateSelector } from "../selectors/availabilitySelectors";
import {
  getBookingRefSelector,
  getCustomerCommentSelector,
  getPublicReferenceSelector,
  getSelectedBeachChairSelector,
} from "../selectors/bookingSelectors";
import {
  getDateFromSelector,
  getDateToSelector,
} from "../selectors/mainAppSelectors";
import { GERMANY_TIME_ZONE } from "../../utils/convertions/constants";
import {
  setMapLocationDefaultRequest,
  setMapStateRequest,
} from "../reducers/mapReducer";
import { EMapState } from "../../utils/models/EMapState";
import { format } from "date-fns-tz";
import { useCreatedBookingIndicator } from "../../components/locationAvailabilitiesBeachChairs/availabilitiesBeachChair/hooks/useCreatedBookingIndicator";
import {
  getPublicSectionsBeachChairsAvailabilitySuccess,
  getPublicSectionsBeachChairsAvailabilityTimelineSuccess,
} from "../reducers/availabilityReducer";
import { ISection } from "../../utils/models/ISection";
import { getSelectedSectionSelector } from "../selectors/sectionSelectors";
import { getExtrasRequest } from "../reducers/extrasReducer";
import { getVendorRequest } from "../reducers/vendorReducer";
import { useAffiliateReferenceStore } from "../../utils/hooks/customHooks/useAffiliateReferenceStore";
import { useWidgetZustand } from "../../contexts/zustand/widgetZustand";
import { MetaDataSource } from "../../utils/models/MeraDataSource";

function* createBookingSaga(action: Action) {
  try {
    if (createBookingRequest.match(action)) {
      const { widgetUrlZustand } = useWidgetZustand.getState();
      const { publicReference } = action.payload;
      const { affiliateReference } = useAffiliateReferenceStore.getState();
      const { data } = yield* call(
        [publicApi, publicApi.publicControllerPublicCreateBooking],
        {
          publicReference,
          affiliateReference,
          source:
            widgetUrlZustand !== ""
              ? MetaDataSource.WIDGET
              : MetaDataSource.WEBSITE,
        }
      );
      yield* put(createBookingSuccess({ booking: data }));
      yield* put(
        setBookingRef({ bookingRef: data.bookingRef, publicReference })
      );
    }
  } catch (e: any) {
    yield* put(
      bookingFailure({
        error: e.error,
      })
    );
  }
}

function* addBeachChairBookingSaga(action: Action) {
  try {
    if (addBeachChairBookingRequest.match(action)) {
      const { widgetUrlZustand } = useWidgetZustand.getState();
      const { discount } = action.payload;
      const selectedBeachChairModelRate = yield* select(
        getSelectedBeachChairModelRateSelector
      );
      const publicReference = yield* select(getPublicReferenceSelector);
      const bookingReference = yield* select(getBookingRefSelector);
      if (bookingReference === "") {
        const { affiliateReference } = useAffiliateReferenceStore.getState();
        const { data } = yield* call(
          [publicApi, publicApi.publicControllerPublicCreateBooking],
          {
            publicReference,
            affiliateReference,
            source:
              widgetUrlZustand !== ""
                ? MetaDataSource.WIDGET
                : MetaDataSource.WEBSITE,
          }
        );
        yield* put(createBookingSuccess({ booking: data }));
        yield* put(
          setBookingRef({ bookingRef: data.bookingRef, publicReference })
        );
      }
      const bookingRef = yield* select(getBookingRefSelector);
      const selectedBeachChairId = yield* select(getSelectedBeachChairSelector);
      const section = yield* select(getSelectedSectionSelector);
      const start = yield* select(getDateFromSelector);
      const end = yield* select(getDateToSelector);
      if (selectedBeachChairId) {
        yield* call(
          [publicApi, publicApi.publicControllerCheckBeachChairAvailability],
          {
            beachChairId: selectedBeachChairId,
            publicReference,
            start: format(new Date(start), "yyyy-MM-dd"),
            end: format(new Date(end), "yyyy-MM-dd"),
          }
        );
        const responseData = yield* call(
          [publicApi, publicApi.publicControllerGetPublicSectionsBeachChairs],
          {
            id: section?.id ?? 0,
            publicReference,
            start: format(new Date(start), "yyyy-MM-dd"),
            end: format(new Date(end), "yyyy-MM-dd"),
            timeZone: "Europe/Berlin",
          }
        );
        const response = responseData.data as unknown as ISection[];
        yield* put(
          getPublicSectionsBeachChairsAvailabilitySuccess({
            sections: response,
          })
        );
        const { data: timeline } = yield* call(
          [publicApi, publicApi.publicControllerGetPublicSectionsCalendar],
          {
            id: section?.id ?? 0,
            publicReference,
            start: format(new Date(start), "yyyy-MM-dd"),
            end: format(new Date(end), "yyyy-MM-dd"),
            timeZone: "Europe/Berlin",
          }
        );
        yield* put(
          getPublicSectionsBeachChairsAvailabilityTimelineSuccess({
            timeline: timeline,
          })
        );
        useCreatedBookingIndicator.getState().setCreatedToBooking();
        const publicCreateBeachChairBookingBodyDto: PublicCreateBeachChairBookingBodyDto =
          {
            beachChairId: selectedBeachChairId,
            end,
            start,
            model: selectedBeachChairModelRate!.model,
            rate:
              selectedBeachChairModelRate!.rate === "none"
                ? undefined
                : selectedBeachChairModelRate!.rate,
            discount,
          };
        const { data } = yield* call(
          [publicApi, publicApi.publicControllerAddBeachChairBookingToBooking],
          {
            bookingRef,
            publicReference,
            publicCreateBeachChairBookingBodyDto,
          }
        );
        const booking = data as unknown as BookingResponseDto;
        yield* put(addBeachChairBookingSuccess({ booking }));
        yield* put(
          addBookedBeachChairRequest({
            beachChairId: selectedBeachChairId,
            start: booking.beachChairBookings.filter(
              (beachChair) => beachChair.beachChairId === selectedBeachChairId
            )[0].start,
            end: booking.beachChairBookings.filter(
              (beachChair) => beachChair.beachChairId === selectedBeachChairId
            )[0].end,
          })
        );
        yield* put(rightDrawerChangeRequest({ toggle: true }));
      }
    }
  } catch (e: any) {
    yield* put(
      bookingFailure({
        error: e.error,
      })
    );
  }
}

function* getBookingSaga(action: Action) {
  try {
    if (getBookingRequest.match(action)) {
      const { bookingRef } = action.payload;
      /*const { data } = */ yield* call(
        [bookingsApi, bookingsApi.bookingsControllerCreate],
        {
          bookingRef,
        }
      );
      yield* put(getBookingSuccess({}));
    }
  } catch (e: any) {
    yield* put(
      bookingFailure({
        error: e.error,
      })
    );
  }
}

function* addCustomerBookingSaga(action: Action) {
  try {
    if (addCustomerBookingRequest.match(action)) {
      const { publicCreateCustomerBodyDto } = action.payload;
      const publicReference = yield* select(getPublicReferenceSelector);
      const bookingRef = yield* select(getBookingRefSelector);
      yield* call(
        [publicApi, publicApi.publicControllerPublicAddCustomerToBooking],
        {
          bookingRef,
          publicReference,
          publicCreateCustomerBodyDto,
        }
      );
      yield* put(addCustomerBookingSuccess({}));
      yield* put(
        updateBookingPaymentMethodRequest({
          paymentMethod: "CASH",
          email: publicCreateCustomerBodyDto.email,
        })
      );
    }
  } catch (e: any) {
    yield* put(
      bookingFailure({
        error: e.error,
      })
    );
  }
}

function* updateBookingPaymentMethodSaga(action: Action) {
  try {
    if (updateBookingPaymentMethodRequest.match(action)) {
      const { paymentMethod, email, bankDetails } = action.payload;
      const bookingRef = yield* select(getBookingRefSelector);
      const customerComment = yield* select(getCustomerCommentSelector);
      yield* call([publicApi, publicApi.publicControllerChangePaymentMethod], {
        bookingRef,
        email,
        publicUpdatePaymentMethod: {
          paymentMethod,
          bankDetails,
          customerComment,
        } as PublicUpdatePaymentMethod,
      });
      yield* put(addCustomerBookingSuccess({}));
    }
  } catch (e: any) {
    yield* put(
      bookingFailure({
        error: e.error,
      })
    );
  }
}

function* confirmBankTransferBookingSaga(action: Action) {
  try {
    if (confirmBankTransferBookingRequest.match(action)) {
      const { email, bookingRef } = action.payload;
      yield* call([publicApi, publicApi.publicControllerPublicConfirmBooking], {
        email,
        bookingRef,
        flow: PaymentFlow.Website,
        timeZone: GERMANY_TIME_ZONE,
      });
      const { data } = yield* call(
        [publicApi, publicApi.publicControllerPublicBookingDetails],
        {
          email,
          bookingRef,
        }
      );
      yield* put(setMapStateRequest({ mapState: EMapState.ALL_CITIES }));
      yield* put(setMapLocationDefaultRequest({}));
      yield* put(
        redirectRequest({
          path: `/booking/result_bank_transfer?bookingReference=${bookingRef}&email=${email}`,
        })
      );
      yield* put(
        confirmBankTransferBookingSuccess({ bookingDetailsResult: data })
      );
    }
  } catch (e: any) {
    yield* put(
      bookingFailure({
        error: e.error,
      })
    );
  }
}

function* deleteBeachChairFromBookingSaga(action: Action) {
  try {
    if (deleteBeachChairFromBookingRequest.match(action)) {
      const { beachChairBookingId, beachChairId, start, end } = action.payload;
      const bookingRef = yield* select(getBookingRefSelector);
      const publicReference = yield* select(getPublicReferenceSelector);
      const { data } = yield* call(
        [publicApi, publicApi.publicControllerRemoveFromBooking],
        {
          bookingRef,
          beachChairBookingId,
          publicReference,
        }
      );
      yield* put(deleteBeachChairFromBookingSuccess({ booking: data }));
      yield* put(removeBookedBeachChairRequest({ beachChairId, start, end }));
      if (data.beachChairBookings.length === 0 && data.extras.length === 0)
        yield* put(flushCurrentBookingRequest({}));
    }
  } catch (e: any) {
    yield* put(
      bookingFailure({
        error: e.error,
      })
    );
  }
}

function* deleteExtrasFromBookingSaga(action: Action) {
  try {
    if (deleteExtrasFromBookingRequest.match(action)) {
      const { bookingExtrasId } = action.payload;
      const bookingReference = yield* select(getBookingRefSelector);
      const publicReference = yield* select(getPublicReferenceSelector);
      const { data } = yield* call(
        [publicApi, publicApi.publicControllerRemoveExtrasFromBooking],
        {
          bookingReference,
          bookingExtrasId,
          publicReference,
        }
      );
      yield* put(deleteExtrasFromBookingSuccess({ booking: data }));
      if (data.beachChairBookings.length === 0 && data.extras.length === 0)
        yield* put(flushCurrentBookingRequest({}));
    }
  } catch (e: any) {
    yield* put(
      bookingFailure({
        error: e.error,
      })
    );
  }
}

function* flushCurrentBookingSaga(action: Action) {
  try {
    if (flushCurrentBookingRequest.match(action)) {
      yield* put(flushCurrentBookingSuccess({}));
      yield* put(flushBookedBeachChairsRequest());
    }
  } catch (e: any) {
    yield* put(
      bookingFailure({
        error: e.error,
      })
    );
  }
}

function* bookingDetailsSaga(action: Action) {
  try {
    if (getBookingDetailsRequest.match(action)) {
      const { bookingRef, email, showDetails } = action.payload;
      const { data } = yield* call(
        [publicApi, publicApi.publicControllerPublicBookingDetails],
        {
          bookingRef,
          email,
        }
      );
      yield* put(getBookingDetailsSuccess({ booking: data }));
      yield* put(
        rightDrawerBookingDetailsChangeRequest({
          toggle: showDetails === undefined ? true : showDetails,
        })
      );
    }
  } catch (e: any) {
    yield* put(
      bookingFailure({
        error: e.error,
      })
    );
  }
}

function* getBeachChairAvailableCheckSaga(action: Action) {
  try {
    if (getBeachChairAvailableCheckRequest.match(action)) {
      const { id, start, end, publicReference } = action.payload;
      const { data } = yield* call(
        [publicApi, publicApi.publicControllerCheckBeachChairAvailability],
        {
          beachChairId: id,
          publicReference,
          start: format(new Date(start), "yyyy-MM-dd"),
          end: format(new Date(end), "yyyy-MM-dd"),
        }
      );
      yield* put(
        getBeachChairAvailableCheckSuccess({ available: data.available })
      );
    }
  } catch (e: any) {
    yield* put(getBeachChairAvailableCheckSuccess({ available: false }));
  }
}

function* redirectToPdfSaga(action: Action) {
  try {
    if (redirectToPdfRequest.match(action)) {
      const { vendorId, vendorName } = action.payload;
      if (vendorId && vendorName) {
        const response = yield* call(
          [vendorApi, vendorApi.vendorsControllerTermsPdf],
          {
            id: vendorId,
          },
          {
            Accept: "application/pdf",
            responseType: "blob",
          }
        );
        const url = window.URL.createObjectURL(
          new Blob([response.data as unknown as Blob], {
            type: "application/pdf",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${vendorName}_terms.pdf`);
        document.body.appendChild(link);
        link.click();
      }
    }
  } catch (e: any) {
    yield* put(
      bookingFailure({
        error: e.error,
      })
    );
  }
}

function* putBookingAbortPaymentSaga(action: Action) {
  try {
    if (putBookingAbortPaymentRequest.match(action)) {
      const { bookingRef, email } = action.payload;
      const { data } = yield* call(
        [paymentsApi, paymentsApi.paymentsControllerPublicAbortPayment],
        {
          bookingRef,
          email,
        }
      );
      yield* put(
        setPublicReference({
          publicReference: data.vendor.publicReference,
        })
      );
      yield* put(
        getVendorRequest({
          publicReference: data.vendor.publicReference,
        })
      );
      yield* put(getExtrasRequest({}));
      yield* put(
        setBookingRef({
          bookingRef: data.bookingRef,
          publicReference: data.vendor.publicReference,
        })
      );
      yield* put(putBookingAbortPaymentSuccess({ booking: data }));
      yield* put(rightDrawerChangeRequest({ toggle: true }));
    }
  } catch (e: any) {
    yield* put(
      bookingFailure({
        error: e.error,
      })
    );
  }
}

function* bookingSaga() {
  yield* all([
    takeLatest(createBookingRequest.type, createBookingSaga),
    takeLatest(addBeachChairBookingRequest.type, addBeachChairBookingSaga),
    takeLatest(addCustomerBookingRequest.type, addCustomerBookingSaga),
    takeLatest(
      updateBookingPaymentMethodRequest.type,
      updateBookingPaymentMethodSaga
    ),
    takeLatest(
      deleteBeachChairFromBookingRequest.type,
      deleteBeachChairFromBookingSaga
    ),
    takeLatest(
      deleteExtrasFromBookingRequest.type,
      deleteExtrasFromBookingSaga
    ),
    takeLatest(
      confirmBankTransferBookingRequest.type,
      confirmBankTransferBookingSaga
    ),
    takeLatest(flushCurrentBookingRequest.type, flushCurrentBookingSaga),
    takeLatest(getBookingRequest.type, getBookingSaga),
    takeLatest(getBookingDetailsRequest.type, bookingDetailsSaga),
    takeLatest(putBookingAbortPaymentRequest.type, putBookingAbortPaymentSaga),
    takeLatest(
      getBeachChairAvailableCheckRequest.type,
      getBeachChairAvailableCheckSaga
    ),
    takeLatest(redirectToPdfRequest.type, redirectToPdfSaga),
  ]);
}

export default bookingSaga;
