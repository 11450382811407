import { stylesConfig } from "../../styles/styles";

export const ResultStyles = {
  root: {
    // display: "grid",
    // gap: `calc(3*${stylesConfig.marginTop})`,
    paddingBottom: stylesConfig.marginBottom,
    gridTemplateRows: "auto auto",
    alignItems: "center",
    justifyContent: "center",
    justifyItems: "center",
    maxHeight: {
      md: `calc(90vh - ${stylesConfig.appBarHeight} - 2*${stylesConfig.marginTop} - 2*${stylesConfig.marginBottom})`,
    },
    backgroundColor: stylesConfig.blueBackground,
    borderRadius: stylesConfig.sxBorderRadius,
    boxShadow: {
      xs: stylesConfig.blueBoxShadow,
      sm: stylesConfig.blueBoxShadow,
      md: stylesConfig.boxShadow,
    },
    padding: {
      xs: "10px",
      sm: "10px",
      // md: `calc(3*${stylesConfig.marginTop}) ${stylesConfig.marginRight} calc(3*${stylesConfig.marginBottom}) ${stylesConfig.marginLeft}`
    },
    margin: {
      xs: "10px",
      sm: "10px",
      md: "10px 0 0 0"
    },
    overflowY: "scroll",
    boxSizing: "content-box",
    "&::-webkit-scrollbar": {
      width: 3,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: stylesConfig.blueColor,
    },
  } as const,
  content: {
    marginTop: {
      xs: 4,
      sm: 4,
      md: 8
    },
    marginBottom: {
      xs: 4,
      sm: 4,
      md: 8
    },
    display: "grid",
    gap: `calc(3*${stylesConfig.marginTop})`,
    gridTemplateRows: "auto auto",
    alignItems: "center",
    justifyContent: "center",
    justifyItems: "center",
  } as const,
  icon: {
    width: {
      xs: 40,
      sm: 40,
      md: 80
    },
    height:  {
      xs: 40,
      sm: 40,
      md: 80
    },
    borderRadius: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: stylesConfig.blueColor,
    backgroundColor: "white",
    fontSize: 32,
  },
  markDown : {
    "& img": {
      width: {
        xs: "30%"
      }
    }
  },
  errorCode: {
    textAlign: "center",
    color: stylesConfig.blueColor,
    fontSize: "4em",
    fontWeight: 700,
    marginBottom: "26px",
  } as const,
  helperText: {
    textAlign: "center",
    color: stylesConfig.blueColor,
    fontSize: 20,
    marginBottom: 1,
  } as const,
  termsText: {
    textAlign: "justify",
    color: stylesConfig.blueColor,
    fontSize: 16,
    marginBottom: 10,
  } as const,
  button: {
    backgroundColor: `${stylesConfig.orangeColor} !important`,
    borderRadius: 20,
    boxShadow: "0px 10px 25px -7px rgba(237, 107, 22, 0.7)",
    width: 200,
    height: 55,
    fontSize: 14,
  } as const
}