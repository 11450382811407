import { createSelector } from "reselect";
import { AppState } from "../reducers/rootReducer";

const getBookingRef = (state: AppState) => state.booking.bookingRef;
const getBookingPublicReference = (state: AppState) =>
  state.booking.bookingPublicReference;
const getPublicReference = (state: AppState) => state.booking.publicReference;
const getBooking = (state: AppState) => state.booking.booking;
const getPublicBookingDetails = (state: AppState) =>
  state.booking.bookingDetails;
const getBookingDetails = (state: AppState) =>
  state.booking.bookingDetailsResult;
const getError = (state: AppState) => state.booking.error;
const getPending = (state: AppState) => state.booking.pending;
const getCustomerComment = (state: AppState) => state.booking.customerComment;
const getSelectedBeachChair = (state: AppState) =>
  state.booking.selectedBeachChair;
const getBeachChairAvailable = (state: AppState) =>
  state.booking.beachChairAvailable;

export const getErrorSelector = createSelector(getError, (error) => error);
export const getPendingSelector = createSelector(
  getPending,
  (pending) => pending
);

export const getCustomerCommentSelector = createSelector(
  getCustomerComment,
  (customerComment) => customerComment
);

export const getBookingRefSelector = createSelector(
  getBookingRef,
  (bookingRef) => bookingRef
);

export const getBookingPublicReferenceSelector = createSelector(
  getBookingPublicReference,
  (bookingPublicReference) => bookingPublicReference
);

export const allowBookSelector = createSelector(
  getPublicReference,
  getBookingPublicReference,
  (publicReference, bookingPublicReference) =>
    bookingPublicReference === ""
      ? false
      : publicReference !== bookingPublicReference
);

export const getBookingDetailsSelector = createSelector(
  getBookingDetails,
  (bookingDetails) => bookingDetails
);

export const getPublicReferenceSelector = createSelector(
  getPublicReference,
  (publicReference) => publicReference
);

export const getBookingSelector = createSelector(
  getBooking,
  (booking) => booking
);

export const getSelectedBeachChairSelector = createSelector(
  getSelectedBeachChair,
  (id) => id
);

export const getPublicBookingDetailsSelector = createSelector(
  getPublicBookingDetails,
  (bookingDetails) => bookingDetails
);

export const getBeachChairAvailableSelector = createSelector(
  getBeachChairAvailable,
  (beachChairAvailable) => beachChairAvailable
);
