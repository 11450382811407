import React from "react";
import {
  Table,
  TableBody,
  Typography,
  CardMedia,
  TableContainer,
  TableRow,
  TableCell,
  AccordionSummary,
  AccordionDetails,
  MobileStepper,
  Accordion,
  Box,
  useTheme,
  IconButton,
  Button,
  useMediaQuery,
  Link,
} from "@mui/material/";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SwipeableViews from "react-swipeable-views";
import Attributes from "./attributes";
import { SectionStyles } from "../../utils/hooks/styles/sectionStyles";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getSelectedSectionSelector } from "../../store/selectors/sectionSelectors";
import { getSelectedCitySelector } from "../../store/selectors/citySelectors";
import { getVendorSelector } from "../../store/selectors/vendorSelectors";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { useAppDispatch } from "../../store";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import {
  leftDrawerChangeRequest,
  toggleMapRequest,
} from "../../store/reducers/mainAppReducer";
import { getExtrasRequest } from "../../store/reducers/extrasReducer";
import { getAllExtrasSelector } from "../../store/selectors/extraSelectors";
import { getPublicReferenceSelector } from "../../store/selectors/bookingSelectors";
import {
  getRowIdSubSectionSelector,
  getSectionsSelector,
} from "../../store/selectors/availabilitySelectors";
import { apiConfig } from "../../utils/oidc/oidcConfig";

export interface ISectionProps {
  onClick: () => void;
}

const Section = ({ onClick }: ISectionProps) => {
  const { t } = useTranslation(["common"]);
  const section = useSelector(getSelectedSectionSelector);
  const city = useSelector(getSelectedCitySelector);
  const vendor = useSelector(getVendorSelector);
  const dispatch = useAppDispatch();
  const items = useSelector(getAllExtrasSelector);
  const publicReference = useSelector(getPublicReferenceSelector);

  const images = [...(section?.images || [])];
  if (!!vendor?.details.logo) {
    images.splice(1, 0, vendor?.details?.logo);
  }
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const matchesMd = useMediaQuery(theme.breakpoints.down("md"));
  const maxSteps = images.length;
  const rowId = useSelector(getRowIdSubSectionSelector);
  const rows = useSelector(getSectionsSelector);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  React.useEffect(() => {
    publicReference && dispatch(getExtrasRequest({}));
    // eslint-disable-next-line
  }, [publicReference]);

  return (
    <Box sx={SectionStyles.root}>
      <Box style={{ maxWidth: "100%" }}>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {images.map((step, index) => (
            <span key={`step-${index}-${step}`}>
              {Math.abs(activeStep - index) <= 2 ? (
                <CardMedia
                  key={`step-${index}-${step}`}
                  sx={SectionStyles.sectionImage}
                  image={step}
                  title={section?.name}
                />
              ) : (
                <></>
              )}
            </span>
          ))}
        </SwipeableViews>

        <Box>
          <MobileStepper
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            nextButton={
              <IconButton
                size="small"
                onClick={handleNext}
                disabled={activeStep === maxSteps - 1}
              >
                {theme.direction === "rtl" ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </IconButton>
            }
            backButton={
              <IconButton
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                {theme.direction === "rtl" ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft />
                )}
              </IconButton>
            }
          />
        </Box>
        {matchesMd && (
          <Box sx={SectionStyles.buttonsSection}>
            <Button
              sx={SectionStyles.buttonBack}
              onClick={() => {
                dispatch(toggleMapRequest({ toggle: true }));
              }}
            >
              {t("backToMap")}
            </Button>
            <Button
              sx={SectionStyles.buttonGo}
              onClick={() => {
                onClick();
              }}
            >
              {t("goToSections")}
            </Button>
          </Box>
        )}
        <Box>
          <Typography sx={SectionStyles.typographyBoldBlue}>
            {vendor?.name}
          </Typography>
        </Box>
        {rowId && (
          <Box>
            <Typography sx={SectionStyles.typographyBoldBlue}>
              {rows.find((row) => row.id === rowId)?.name}
            </Typography>
          </Box>
        )}
        <Box sx={SectionStyles.columnsGrid}>
          <LocationOnIcon />
          <Typography>
            {city?.name}, {section?.location?.entranceName}
          </Typography>
        </Box>

        {items && vendor && vendor.activeAvailability && items.length > 0 && (
          <Box>
            <Typography sx={SectionStyles.typographyBoldBlue}>
              {t("additionalItems")}
            </Typography>
            <Box sx={{ ...SectionStyles.additionalItemsBtnCenter }}>
              <Button
                sx={{ ...SectionStyles.additionalItemsBtn }}
                onClick={() => {
                  dispatch(leftDrawerChangeRequest({ toggle: true }));
                }}
              >
                {t("toAdditionalItems")}
              </Button>
            </Box>
          </Box>
        )}
        <Accordion
          expanded={expanded === "panel0" || !expanded}
          onChange={handleChange("panel0")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel0a-content"
            id="panel0a-header"
          >
            <Typography sx={SectionStyles.typographyBoldBlue}>
              {t("sectionDescription")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>{section?.description}</AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography sx={SectionStyles.typographyBoldBlue}>
              {t("attributesHeadline")}:
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Attributes />
          </AccordionDetails>
        </Accordion>
        {!vendor?.configuration?.hideOpeningHours && (
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography sx={SectionStyles.typographyBoldBlue}>
                {t("openingHours")}:
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer>
                <Table sx={{ minWidth: 250 }} size="small">
                  <TableBody>
                    {section?.location?.details.openingHours
                      .slice()
                      .sort((a, b) => (a as any).weekday - (b as any).weekday)
                      .map((opening, index) => {
                        return (
                          <TableRow
                            key={`weekday-${(opening as any).weekday}-${index}`}
                          >
                            <TableCell
                              key={`weekday-opening-${
                                (opening as any).weekday
                              }`}
                              align="left"
                            >
                              {t(`weekdays.${(opening as any).weekday}`)}
                            </TableCell>
                            <TableCell
                              key={`weekday-closing-${
                                (opening as any).closing
                              }`}
                              align="right"
                            >
                              {(opening as any).opening}-
                              {(opening as any).closing}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        )}
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography sx={SectionStyles.typographyBoldBlue}>
              {t("vendor")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              <Typography sx={SectionStyles.typographyBoldBlue}>
                {vendor?.companyName}
              </Typography>
              {`${vendor?.address.street}`}
              <br />
              {`${vendor?.address.zip} ${vendor?.address.city}`}
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4a-content"
            id="panel4a-header"
          >
            <Typography sx={SectionStyles.typographyBoldBlue}>
              {t("moreInformation")}:
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ ...SectionStyles.centered }}>
            {section?.location && (
              <>
                {section?.location?.description && (
                  <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    className={`priceText`}
                  >
                    {section?.location?.description}
                  </ReactMarkdown>
                )}
                <Link
                  sx={{
                    ...SectionStyles.typographyBoldBlue,
                    ...{ justifyContent: "center" },
                  }}
                  href={`${apiConfig.apiPrefix}/api/v1/vendor/locations/terms/${section?.locationId}/pdf`}
                  target="_blank"
                >
                  {t("rentalTerms")}
                </Link>
              </>
            )}
            {section?.beachPlan && (
              <Link
                sx={{
                  ...SectionStyles.typographyBoldBlue,
                  ...{ justifyContent: "center" },
                }}
                href={`${section?.beachPlan}`}
                target="_blank"
              >
                {t("beachPlan")}
              </Link>
            )}
            {(section?.location as any)?.priceText && (
              <>
                <Typography sx={SectionStyles.typographyBoldBlue}>
                  {t("priceText")}:
                </Typography>
                <ReactMarkdown
                  remarkPlugins={[remarkGfm]}
                  className={`priceText`}
                >
                  {(section?.location as any)?.priceText?.de || ""}
                </ReactMarkdown>
              </>
            )}
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
};

export default Section;
