import { Action } from "@reduxjs/toolkit";
import { all, call, put, takeLatest } from "typed-redux-saga";
import { publicApi } from "../../api/api";
import { getVendorRequest, getVendorSuccess, vendorFailure } from "../reducers/vendorReducer";

function* getVendorSaga(action: Action) {
    try {
      if (getVendorRequest.match(action)) {
        const { publicReference } = action.payload;
        const { data } = yield* call(
            [publicApi, publicApi.publicControllerPublicVendor],
            {
              publicReference,
            }
          );

          yield* put(
            getVendorSuccess({
              vendor: data
            })
          );
      }
    } catch (e:any) {
        yield* put(
            vendorFailure({
              error: e.error,
            })
          );
    }
  }
  
  function* vendorSaga() {
    yield* all([
      takeLatest(getVendorRequest.type, getVendorSaga)
    ]);
  }
  
  export default vendorSaga;