import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ToysIcon from "@mui/icons-material/Toys";
import LocalBarIcon from "@mui/icons-material/LocalBar";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useTranslation } from "react-i18next";
import { AdditionalItemsStyles } from "../../utils/hooks/styles/additionaltemsStyle";
import { Box, Divider } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import { useSelector } from "react-redux";
import {
  getAllExtrasSelector,
  getExtraTypeOptionSelector,
} from "../../store/selectors/extraSelectors";
import { useAppDispatch } from "../../store";
import { setSelectedExtraTypeRequest } from "../../store/reducers/extrasReducer";
import ItemsToChoose from "./itemsToChoose";
import { stylesConfig } from "../../utils/styles/styles";
import { makeStyles } from "@mui/styles";
import CoffeeIcon from "@mui/icons-material/Coffee";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
const useStyles = makeStyles(() => ({
  customTabsStyle: {
    "&.MuiTabs-root": {
      backgroundColor: `${stylesConfig.whiteColor} !important`,
    },
    "& .MuiTabScrollButton-root:first-of-type": {
      borderRadius: `8px`,
      marginTop: "15px",
      height: "35px",
      width: "35px",
      backgroundColor: stylesConfig.lightBlueColor,
      color: stylesConfig.blueColor,
    },
    "& .MuiTabScrollButton-root:last-of-type": {
      borderRadius: `8px`,
      marginTop: "15px",
      height: "35px",
      width: "35px",
      backgroundColor: stylesConfig.lightBlueColor,
      color: stylesConfig.blueColor,
    },
  },
}));

export default function IconTabs() {
  const { t } = useTranslation(["additionalItems"]);
  const classes = useStyles();
  const [uniqueTypes, setUniqueTypes] = React.useState<number>(1);
  const type = useSelector(getExtraTypeOptionSelector);
  const items = useSelector(getAllExtrasSelector);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (items.length > 0) {
      dispatch(setSelectedExtraTypeRequest({ option: `${items[0].type}` }));
      setUniqueTypes(
        items.map((item) => item.type).filter((x, i, a) => a.indexOf(x) === i)
          .length
      );
    }
    // eslint-disable-next-line
  }, [items]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(setSelectedExtraTypeRequest({ option: `${newValue}` }));
  };

  return (
    <Box sx={{ display: "grid", gap: "10px" }}>
      <Tabs
        value={type}
        centered={uniqueTypes <= 3}
        onChange={handleChange}
        aria-label="additionalItems"
        variant={uniqueTypes > 3 ? "scrollable" : "fullWidth"}
        allowScrollButtonsMobile
        scrollButtons
        TabIndicatorProps={{
          style: {
            display: "none",
          },
        }}
        classes={{ root: classes.customTabsStyle }}
      >
        {items.filter((item) => item.type === "FOOD").length > 0 && (
          <Tab
            icon={
              <Box
                sx={{
                  ...AdditionalItemsStyles.boxBorder,
                  ...(type === "FOOD" &&
                    AdditionalItemsStyles.selectedBoxBorder),
                }}
              >
                <FastfoodIcon sx={{ ...AdditionalItemsStyles.tabIcon }} />
              </Box>
            }
            label={t("FOOD")}
            sx={{ ...AdditionalItemsStyles.tab }}
            value="FOOD"
          />
        )}
        {items.filter((item) => item.type === "ACCESSOIRES").length > 0 && (
          <Tab
            icon={
              <Box
                sx={{
                  ...AdditionalItemsStyles.boxBorder,
                  ...(type === "ACCESSOIRES" &&
                    AdditionalItemsStyles.selectedBoxBorder),
                }}
              >
                <BusinessCenterIcon sx={{ ...AdditionalItemsStyles.tabIcon }} />
              </Box>
            }
            label={t("ACCESSOIRES")}
            sx={{ ...AdditionalItemsStyles.tab }}
            value="ACCESSOIRES"
          />
        )}
        {items.filter((item) => item.type === "TOYS").length > 0 && (
          <Tab
            icon={
              <Box
                sx={{
                  ...AdditionalItemsStyles.boxBorder,
                  ...(type === "TOYS" &&
                    AdditionalItemsStyles.selectedBoxBorder),
                }}
              >
                <ToysIcon sx={{ ...AdditionalItemsStyles.tabIcon }} />
              </Box>
            }
            label={t("TOYS")}
            sx={{ ...AdditionalItemsStyles.tab }}
            value="TOYS"
          />
        )}
        {items.filter((item) => item.type === "BEVERAGES").length > 0 && (
          <Tab
            icon={
              <Box
                sx={{
                  ...AdditionalItemsStyles.boxBorder,
                  ...(type === "BEVERAGES" &&
                    AdditionalItemsStyles.selectedBoxBorder),
                }}
              >
                <CoffeeIcon sx={{ ...AdditionalItemsStyles.tabIcon }} />
              </Box>
            }
            label={t("BEVERAGES")}
            sx={{ ...AdditionalItemsStyles.tab }}
            value="BEVERAGES"
          />
        )}
        {items.filter((item) => item.type === "ALCOHOLIC_BEVERAGES").length >
          0 && (
          <Tab
            icon={
              <Box
                sx={{
                  ...AdditionalItemsStyles.boxBorder,
                  ...(type === "ALCOHOLIC_BEVERAGES" &&
                    AdditionalItemsStyles.selectedBoxBorder),
                }}
              >
                <LocalBarIcon sx={{ ...AdditionalItemsStyles.tabIcon }} />
              </Box>
            }
            label={t("ALCOHOLIC_BEVERAGES")}
            sx={{ ...AdditionalItemsStyles.tab }}
            value="ALCOHOLIC_BEVERAGES"
          />
        )}
        {items.filter((item) => item.type === "OTHER").length > 0 && (
          <Tab
            icon={
              <Box
                sx={{
                  ...AdditionalItemsStyles.boxBorder,
                  ...(type === "OTHER" &&
                    AdditionalItemsStyles.selectedBoxBorder),
                }}
              >
                <MoreHorizIcon sx={{ ...AdditionalItemsStyles.tabIcon }} />
              </Box>
            }
            label={t("OTHER")}
            sx={{ ...AdditionalItemsStyles.tab }}
            value="OTHER"
          />
        )}
      </Tabs>
      <Divider />
      <TabContext value={type}>
        <TabPanel value="FOOD" sx={{ p: 0 }}>
          <ItemsToChoose type="FOOD" />
        </TabPanel>
        <TabPanel value="ACCESSOIRES" sx={{ p: 0 }}>
          <ItemsToChoose type="ACCESSOIRES" />
        </TabPanel>
        <TabPanel value="TOYS" sx={{ p: 0 }}>
          <ItemsToChoose type="TOYS" />
        </TabPanel>
        <TabPanel value="BEVERAGES" sx={{ p: 0 }}>
          <ItemsToChoose type="BEVERAGES" />
        </TabPanel>
        <TabPanel value="ALCOHOLIC_BEVERAGES" sx={{ p: 0 }}>
          <ItemsToChoose type="ALCOHOLIC_BEVERAGES" />
        </TabPanel>
        <TabPanel value="OTHER" sx={{ p: 0 }}>
          <ItemsToChoose type="OTHER" />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
