import { stylesConfig } from "../../styles/styles";

export const ContentStyles = {
  root: {
    paddingBottom: stylesConfig.marginBottom,
    gridTemplateRows: "auto auto",
    alignItems: "center",
    justifyContent: "center",
    justifyItems: "center",
    maxHeight: {
      md: `calc(100vh - ${stylesConfig.appBarHeight} - ${stylesConfig.footerHeight} - 1vh)`,
    },
    padding: {
      xs: "10px",
      sm: "10px",
    },
    overflowY: "scroll",
    boxSizing: "content-box",
    "&::-webkit-scrollbar": {
      width: 3,
      height: 1,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: stylesConfig.blueColor,
      borderRadius: stylesConfig.sxBorderRadius,
    },
  } as const,
  content: {
    display: "grid",
    gap: `calc(3*${stylesConfig.marginTop})`,
    gridTemplateRows: "auto auto",
    alignItems: "center",
    justifyContent: "center",
    justifyItems: "center",
  } as const,
  icon: {
    width: {
      xs: 40,
      sm: 40,
      md: 80,
    },
    height: {
      xs: 40,
      sm: 40,
      md: 80,
    },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: stylesConfig.blueColor,
    backgroundColor: "white",
    fontSize: 32,
  },
  markDown: {
    color: stylesConfig.blueColor,
    padding: "20px",
    "& img": {
      borderRadius: stylesConfig.sxBorderRadius,
      width: {
        xs: "100%",
      },
    },
  },
  markDownAbout: {
    color: stylesConfig.blueColor,
    padding: "20px",
    "& img": {
      borderRadius: stylesConfig.sxBorderRadius,
      width: {
        xs: "30%",
      },
    },
  },
  errorCode: {
    textAlign: "center",
    color: stylesConfig.blueColor,
    fontSize: "2em",
    fontWeight: 700,
    marginBottom: 10,
  } as const,
  helperText: {
    maxWidth: "800px",
    textAlign: "center",
    color: stylesConfig.blueColor,
    fontSize: 20,
    marginBottom: 1,
  } as const,
  background: {
    backgroundColor: stylesConfig.whiteColor,
    borderRadius: stylesConfig.sxBorderRadius,
    paddingBottom: "20px",
  },
  termsText: {
    maxWidth: "800px",
    textAlign: "justify",
    color: stylesConfig.blueColor,
    fontSize: 16,
    marginBottom: 10,
  } as const,
  button: {
    backgroundColor: `${stylesConfig.orangeColor} !important`,
    borderRadius: 20,
    boxShadow: "0px 10px 25px -7px rgba(237, 107, 22, 0.7)",
    width: 200,
    height: 55,
    fontSize: 14,
  },
  secondaryButton: {
    color: `${stylesConfig.whiteColor}`,
    borderRadius: 20,
    width: 200,
    height: 55,
    fontSize: 14,
    backgroundColor: `${stylesConfig.blueColor}`,
    boxShadow: stylesConfig.blueBoxShadow,
    "&:hover": {
      backgroundColor: `${stylesConfig.whiteColor}`,
      color: `${stylesConfig.blueColor}`,
    },
  } as const,
  buttonsGrid: {
    display: "grid",
    justifyContent: "center",
    gridTemplateColumns: {
      xs: "auto",
      sm: "auto",
      md: "auto 100px auto",
    },
  },
  contact: {
    "& .MuiInputBase-fullWidth": {
      marginBottom: 5,
    },
  },
};
