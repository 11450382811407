import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store";
import { addPreBookingExtrasToBookingRequest } from "../../store/reducers/extrasReducer";
import { selectionModalRequest } from "../../store/reducers/mainAppReducer";
import { allowBookSelector } from "../../store/selectors/bookingSelectors";
import { getAllPreBookingSelector } from "../../store/selectors/extraSelectors";
import { EURO } from "../../utils/convertions/euroFormater";
import { AdditionalItemsStyles } from "../../utils/hooks/styles/additionaltemsStyle";

function TotalPrice() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["additionalItems"]);
  const items = useSelector(getAllPreBookingSelector);
  const allowBook = useSelector(allowBookSelector);

  const totalPrice = React.useMemo(
    () =>
      items.length > 0
        ? items
            .map((item) => item.price)
            .reduce((accumulator, current) => accumulator + current)
        : 0,
    [items]
  );
  return (
    <Box sx={{ ...AdditionalItemsStyles.cardFooter }}>
      <Box sx={AdditionalItemsStyles.twoColumns}>
        <Typography sx={AdditionalItemsStyles.typographyBlueRight}>
          {t("totalPrice")}:
        </Typography>
        <Typography sx={AdditionalItemsStyles.typographyBoldBlue}>
          {EURO(totalPrice).format()}
        </Typography>
      </Box>
      <Button
        disabled={totalPrice === 0}
        sx={{ ...AdditionalItemsStyles.buttonAddToBooking }}
        onClick={() => {
          if (allowBook) {
            dispatch(selectionModalRequest({ toggle: true }));
          } else {
            dispatch(addPreBookingExtrasToBookingRequest({}));
          }
        }}
      >
        {t("confirmBooking")}
      </Button>
    </Box>
  );
}

export default TotalPrice;
