import { createSelector } from "reselect";
import { dateToProperDateFormat } from "../../utils/convertions/dataConversion";
import { AppState } from "../reducers/rootReducer";

const getShowStart = (state: AppState) => state.main.showStart;
const getDateFrom = (state: AppState) => state.main.dateFrom;
const getDateTo = (state: AppState) => state.main.dateTo;
const getSeasonStartDate = (state: AppState) => state.main.seasonStartDate;
const getSeasonEndDate = (state: AppState) => state.main.seasonEndDate;
const getRightDrawerState = (state: AppState) => state.main.rightDrawer;
const getRightBookingDetailsDrawerState = (state: AppState) =>
  state.main.rightBookingDetailsDrawer;
const getLeftDrawerState = (state: AppState) => state.main.leftDrawer;
const getMainComponentShowState = (state: AppState) => state.main.mainComponent;
const getSelectionModalModalState = (state: AppState) =>
  state.main.selectionModal;
const getFocusState = (state: AppState) => state.main.focus;
const getSwitchState = (state: AppState) => state.main.searchSwitch;
const getMobileViewExpandMenu = (state: AppState) =>
  state.main.mobileViewExpandMenu;
const getIgnoreCloseExpandMenu = (state: AppState) =>
  state.main.ignoreCloseExpandMenu;
const getShowMap = (state: AppState) => state.main.showMap;
const getIsSubSections = (state: AppState) => state.main.isSubSections;
const getIsChangeDateByUser = (state: AppState) =>
  state.main.isChangeDateByUser;

export const getShowStartSelector = createSelector(
  getShowStart,
  (showStart) => showStart
);
export const getDateFromSelector = createSelector(getDateFrom, (dateFrom) =>
  dateToProperDateFormat(dateFrom)
);
export const getDateToSelector = createSelector(getDateTo, (dateTo) =>
  dateToProperDateFormat(dateTo)
);

export const getSeasonStartDateSelector = createSelector(
  getSeasonStartDate,
  (seasonStart) => dateToProperDateFormat(seasonStart)
);
export const getSeasonEndDateSelector = createSelector(
  getSeasonEndDate,
  (seasonEnd) => dateToProperDateFormat(seasonEnd)
);

export const rightDrawerSelector = createSelector(
  getRightDrawerState,
  (rightDrawer) => rightDrawer
);

export const getRightBookingDetailsDrawerSelector = createSelector(
  getRightBookingDetailsDrawerState,
  (rightBookingDetailsDrawerState) => rightBookingDetailsDrawerState
);

export const leftDrawerSelector = createSelector(
  getLeftDrawerState,
  (leftDrawer) => leftDrawer
);

export const mainComponentShowSelector = createSelector(
  getMainComponentShowState,
  (mainComponent) => mainComponent
);
export const getSelectionModalSelector = createSelector(
  getSelectionModalModalState,
  (selectionModal) => selectionModal
);
export const getFocusSelector = createSelector(getFocusState, (focus) => focus);
export const getSwitchStateSelector = createSelector(
  getSwitchState,
  (searchSwitch) => searchSwitch
);
export const getMobileViewExpandMenuSelector = createSelector(
  getMobileViewExpandMenu,
  (mobileViewExpandMenu) => mobileViewExpandMenu
);
export const getIgnoreCloseExpandMenuSelector = createSelector(
  getIgnoreCloseExpandMenu,
  (ignoreCloseExpandMenu) => ignoreCloseExpandMenu
);
export const getShowMapSelector = createSelector(
  getShowMap,
  (showMap) => showMap
);
export const getIsSubSectionsSelector = createSelector(
  getIsSubSections,
  (isSubSections) => isSubSections
);
export const getIschangeDateByUserSelector = createSelector(
  getIsChangeDateByUser,
  (isChangeDateByUser) => isChangeDateByUser
);
