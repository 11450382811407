import {
  Box,
  Button,
  CardMedia,
  IconButton,
  InputLabel,
  Modal,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useMemo } from "react";
import {
  ProgramBox,
  ProgramContent,
  useProgram,
  ProgramItem,
  Epg,
  Layout,
  useEpg,
  ChannelBox,
  Channel,
  Theme,
  TimelineWrapper,
  useTimeline,
  Timeline,
  TimelineWeekMonthBox,
  TimelineWeekMonthDate,
  CurrentTime,
} from "@nessprim/planby";
import { stylesConfig } from "../../../utils/styles/styles";
import lock from "./../../../assets/lock.png";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { EColor } from "../../../utils/models/EColor";
import { RowDividerStyles } from "../../../utils/hooks/styles/rowDividerStyle";
import dayjs from "dayjs";
import "dayjs/locale/de";
import {
  Area,
  ChannelWithOmittedUuid,
} from "@nessprim/planby/dist/Epg/helpers";
import { useAppDispatch } from "../../../store";
import { selectBeachChairToBookingRequest } from "../../../store/reducers/bookingReducer";
import {
  changeDateFromRequest,
  changeDateToRequest,
  isChangeDateByUserRequest,
  redirectRequest,
} from "../../../store/reducers/mainAppReducer";
import { useSelector } from "react-redux";
import {
  getDateFromSelector,
  getDateToSelector,
} from "../../../store/selectors/mainAppSelectors";
import { Add, DateRange as DateRangeIcon, Remove } from "@mui/icons-material";
import { getSelectedSectionSelector } from "../../../store/selectors/sectionSelectors";
import { getSelectedCitySelector } from "../../../store/selectors/citySelectors";
import { ModalStyles } from "../../../utils/hooks/styles/modalStyle";
import { useTranslation } from "react-i18next";
import {
  getRowIdSubSectionSelector,
  getTimelineSelector,
} from "../../../store/selectors/availabilitySelectors";
import { getPublicSectionsBeachChairsAvailabilityTimelineRequest } from "../../../store/reducers/availabilityReducer";
import { AvailabilitiesBeachChairStyle } from "./availabilitiesBeachChair";
import {
  DateRange,
  LocalizationProvider,
  MobileDateRangePicker,
} from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import deLocale from "date-fns/locale/de";
import { isValidDate } from "../../../utils/checkers/isValidDate";
import { SearchStyle } from "../../../utils/hooks/styles/searchStyle";
import { useCreatedBookingIndicator } from "./hooks/useCreatedBookingIndicator";
import { useBeachChairTimelineModal } from "./hooks/useBeachChairTimelineModal";
import { getPublicReferenceSelector } from "../../../store/selectors/bookingSelectors";

dayjs.locale("de");

export const SBColor = {
  /**HEX*/
  blue: "#233F91",
  orange: "#ED6B17",
  darkWhite: "#FAFAFA",
  white: "#FFFFFF",
  lightGray: "#EDEDED",
  gray: "#808080",
  scroll: "#233F91",
  red: "#ff0033",
  /**RGBA*/
  opacity: "rgba(255,255,255,0)",
  blueOpacity: "rgba(35,63,145,0.2)",
  grayOpacity: "rgba(128,128,128,0.1)",
  grayTransparent: "rgba(0, 0, 0, 0.54)",
};

export const SBDatePickerStyle = {
  borderColor: "white",
  "& .MuiInputBase-root": {
    color: SBColor.blue,
    backgroundColor: SBColor.white,
  },
  "& .MuiFilledInput-input.Mui-disabled": {
    color: `${SBColor.blue}!important`,
    WebkitTextFillColor: `${SBColor.blue}!important`,
  },

  "& .MuiFilledInput-root.Mui-disabled": {
    backgroundColor: SBColor.white,
    color: `${SBColor.blue}!important`,
    WebkitTextFillColor: `${SBColor.blue}!important`,
  },

  "& .Mui-disabled": {
    backgroundColor: SBColor.white,
    color: `${SBColor.blue}!important`,
    WebkitTextFillColor: `${SBColor.blue}!important`,
  },

  "& .MuiOutlinedInput-notchedOutline ": {
    borderColor: `${SBColor.blue}!important`,
  },

  "& .MuiInputLabel-root": {
    color: `${SBColor.blue}!important`,
  },

  "& .MuiOutlinedInput-input": {
    padding: "7px 10px",
  },
} as const;

export const SBDatePickerHeightStyle = {
  height: "30px",
};

export const SBComponentHeightStyle = {
  height: "40px!important",
  padding: "7px 10px",
};

export const SBTextFieldIconStyle = {
  display: "grid",
  alignContent: "center",
  color: SBColor.blue,
} as const;

export const SBTextFieldGridStyle = {
  display: "grid",
  gridTemplateColumns: "30px auto",
  minHeight: "80px",
  maxHeight: "200px",
} as const;

export const SBLabelStyle = {
  color: SBColor.blue,
  fontWeight: "bold",
} as const;

const theme: Theme = {
  primary: {
    600: "#fff",
    900: "#fff",
  },
  grey: { 300: SBColor.blue },
  white: SBColor.blue,
  green: {
    200: SBColor.opacity,
    300: SBColor.opacity,
  },
  teal: {
    100: SBColor.blue,
  },
  loader: {
    teal: SBColor.blue,
    purple: SBColor.blue,
    pink: SBColor.blue,
    bg: SBColor.blue,
  },
  scrollbar: {
    border: "#d1d1d1",
    thumb: {
      bg: SBColor.blue,
    },
  },
  gradient: {
    blue: {
      300: "#002eb3",
      600: "#002360",
      900: "#051937",
    },
  },
  text: {
    grey: {
      300: SBColor.blue,
      500: SBColor.blue,
    },
  },
  timeline: {
    divider: {
      bg: SBColor.blue,
    },
  },
  grid: {
    item: SBColor.lightGray,
    divider: SBColor.lightGray,
    highlight: SBColor.blue,
  },
};

const createWeekends = (start: string, end: string) => {
  let area: Area[] = [];
  let current = dayjs(start);
  while (current.isBefore(end)) {
    if (current.get("day") === 6 || current.get("day") === 0) {
      const index = current.get("day") === 0 ? 1 : 0;
      const weekendStart = current.format("YYYY-MM-DD");
      const weekend = current.add(2 - index, "day").format("YYYY-MM-DD");
      area.push({
        startDate: `${weekendStart}T01:00:00`,
        endDate: `${weekend}T01:00:00`,
        styles: {
          background: SBColor.grayOpacity,
          borderLeft: `1px dotted ${SBColor.blue}`,
          borderRight: `1px dotted ${SBColor.blue}`,
        },
      });
      current = current.add(6 - index, "day");
    } else {
      current = current.add(1, "day");
    }
  }
  return area;
};

const Item = ({ program, ...rest }: ProgramItem) => {
  const { styles, isLive } = useProgram({
    program,
    ...rest,
  });
  const { setModal } = useBeachChairTimelineModal();

  const { data } = program;
  const { type } = data;

  const bgColor = useMemo(() => {
    switch (type) {
      case "createdBooking":
        return SBColor.orange;
      case "newBooking":
        return SBColor.blue;
      case "row":
        return SBColor.blue;
      default:
        return SBColor.lightGray;
    }
  }, [type]);

  return (
    <ProgramBox
      onClick={() => {
        if (type.includes("createdBooking")) {
          setModal(true);
        }
      }}
      width={styles.width}
      style={{
        ...styles.position,
        ...(type === "row" && {
          display: "grid",
          marginTop: "auto",
          marginBottom: "auto",
          alignItems: "center",
        }),
      }}
    >
      <ProgramContent
        width={styles.width}
        isLive={isLive}
        style={{
          background: bgColor,
          ...(type === "row" && { height: "1px" }),
          borderRadius: type === "row" ? "0" : "10px",
          border: "none",
          padding: "2px 2px",
        }}
      ></ProgramContent>
    </ProgramBox>
  );
};

interface ChannelItemProps {
  channel: Channel;
}

const ChannelItem = ({ channel }: ChannelItemProps) => {
  const { position, type, publicNumber, seasonBeachChair, lockId, attributes } =
    channel;

  return (
    <ChannelBox
      {...position}
      style={{
        backgroundColor: stylesConfig.whiteColor,
      }}
    >
      {type === "row" ? (
        <Box
          sx={{
            display: "grid",
            backgroundColor: stylesConfig.whiteColor,
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Typography
            sx={RowDividerStyles.boldTypography}
            data-testid="row-name"
          >
            {publicNumber}
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            backgroundColor: stylesConfig.whiteColor,
            ...AvailabilitiesBeachChairStyle.root,
            height: "70px",
          }}
        >
          <Box
            sx={{
              ...AvailabilitiesBeachChairStyle.boxBackgroundFree,
              ...(attributes &&
                attributes?.color && {
                  borderTop: `5px dashed ${
                    EColor[attributes.color as any as keyof typeof EColor]
                  } !important`,
                }),
            }}
          >
            <Box sx={{ ...AvailabilitiesBeachChairStyle.iconsGrid }}>
              {lockId !== null ? (
                <CardMedia
                  sx={AvailabilitiesBeachChairStyle.lock}
                  data-testid="hasLock"
                  component="img"
                  src={lock}
                />
              ) : (
                <Box />
              )}
              {seasonBeachChair && (
                <CalendarMonthIcon
                  sx={AvailabilitiesBeachChairStyle.seasonBeachChair}
                  data-testid="isSeasonBeachChair"
                />
              )}
            </Box>
            <Typography sx={{ ...AvailabilitiesBeachChairStyle.gridRowSecond }}>
              {publicNumber}
            </Typography>
          </Box>
        </Box>
      )}
    </ChannelBox>
  );
};

export function CustomTimeline(props: Timeline) {
  const { isWeekMonthMode, isMonthMode, time, ...rest } = useTimeline(props);
  const { timelineHeight, weekDayWidth, monthsWidth } = rest;
  const { getTimelineProps, getCurrentTimeProps } = rest;
  const { isToday, isCurrentTime, isRTL, isTimelineVisible, isVerticalMode } =
    props;
  const { mode } = props;

  const renderWeekMonth = (item: string, index: number) => {
    const width = isMonthMode ? monthsWidth[index].width : weekDayWidth;
    const left = isMonthMode ? monthsWidth[index].left : width * index;
    const position = {
      left,
      width,
    };
    const isVisible = isTimelineVisible(position);
    if (!isVisible) return null;

    const date = dayjs(item);

    return (
      <TimelineWeekMonthBox
        className="planby-timeline-box"
        data-testid="timeline-item"
        key={index}
        isToday={isToday}
        isWeekMonthMode={isWeekMonthMode}
        isCurrentTime={isCurrentTime}
        isVerticalMode={isVerticalMode}
        timelineHeight={timelineHeight}
        styleType={mode.style}
        {...position}
      >
        <TimelineWeekMonthDate
          className="planby-timeline-week-month-date"
          isRTL={isRTL}
          isVerticalMode={isVerticalMode}
          styleType={mode.style}
        >
          <Typography
            sx={{
              fontSize: "10px",
              color: stylesConfig.blueColor,
              fontWeight: "bold",
            }}
          >
            {date.format("ddd")}
          </Typography>
          <Typography sx={{ fontSize: "12px", color: stylesConfig.blueColor }}>
            {date.format("DD.MM")}
          </Typography>
        </TimelineWeekMonthDate>
      </TimelineWeekMonthBox>
    );
  };

  return (
    <TimelineWrapper
      className="planby-timeline-wrapper"
      data-testid="timeline"
      {...getTimelineProps()}
    >
      {isCurrentTime && isToday && <CurrentTime {...getCurrentTimeProps()} />}
      {time.map((item, index) => renderWeekMonth(item as string, index))}
    </TimelineWrapper>
  );
}

const AvailabilitiesBeachChairTimeline = () => {
  const muiTheme = useTheme();
  const dispatch = useAppDispatch();
  const matchesMd = useMediaQuery(muiTheme.breakpoints.down("md"));
  const { isBeachChairTimelineModal, setModal } = useBeachChairTimelineModal();
  const start = useSelector(getDateFromSelector);
  const end = useSelector(getDateToSelector);
  const section = useSelector(getSelectedSectionSelector);
  const city = useSelector(getSelectedCitySelector);
  const data = useSelector(getTimelineSelector);
  const rowId = useSelector(getRowIdSubSectionSelector);
  const publicReference = useSelector(getPublicReferenceSelector);
  const {
    setCreatedBooking,
    editedChannelUuid,
    createBooking,
    createdBookings,
    clearCreatedBooking,
  } = useCreatedBookingIndicator();

  const startTimeline = useMemo(() => {
    if (section && section.location && section.location.seasonStart) {
      const seasonStartArray = section?.location?.seasonStart.split("-");
      const seasonStart = `${dayjs(start, "YYYY-MM-DD").get("year")}-${
        seasonStartArray[1]
      }-${seasonStartArray[0]}`;
      return dayjs(seasonStart, "DD-MM-YYYY").isBefore(dayjs(), "year")
        ? dayjs().format("YYYY-MM-DD")
        : dayjs(seasonStart, "DD-MM-YYYY").format("YYYY-MM-DD");
    } else {
      return dayjs().format("YYYY-MM-DD");
    }
  }, [section, start]);

  const endTimeline = useMemo(() => {
    if (section && section.location && section.location.seasonEnd) {
      const seasonEndArray = section?.location?.seasonEnd.split("-");
      const seasonEnd = `${dayjs(end, "YYYY-MM-DD").get("year")}-${
        seasonEndArray[1]
      }-${seasonEndArray[0]}`;
      return dayjs(seasonEnd).add(1, "day").format("YYYY-MM-DD");
    } else {
      return dayjs().format("YYYY-MM-DD");
    }
  }, [section, end]);

  React.useEffect(() => {
    dispatch(
      getPublicSectionsBeachChairsAvailabilityTimelineRequest({
        publicReference,
        sectionId: section?.id ?? 0,
        start: startTimeline,
        end: endTimeline,
        hasSubsections: true,
      })
    );
    // eslint-disable-next-line
  }, [startTimeline, endTimeline]);

  const channels = React.useMemo(() => {
    if (rowId) {
      let startIndex = 0;
      let endIndex = data?.channels.length ?? 0;
      let start = false;
      for (let index = 0; index < (data?.channels?.length ?? 0); index++) {
        if ((data?.channels[index] as any).type.includes("row")) {
          if (start) {
            endIndex = index;
            break;
          }
          if ((data?.channels[index] as any).uuid.includes(`${rowId}`)) {
            start = true;
            startIndex = index;
            endIndex = data?.channels.length ?? 0;
          }
        }
      }
      return (
        (data?.channels as any as ChannelWithOmittedUuid[])?.slice(
          startIndex,
          endIndex
        ) ?? []
      );
    } else {
      return (data?.channels as any as ChannelWithOmittedUuid[]) ?? [];
    }
  }, [data, rowId]);

  const [open, setOpen] = React.useState<boolean>(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [value, setValue] = React.useState<DateRange<Date>>([
    new Date(start),
    new Date(end),
  ]);

  const { getEpgProps, getLayoutProps } = useEpg({
    epg: createdBookings,
    channels,
    startDate: startTimeline,
    endDate: endTimeline,
    dayWidth: dayjs(startTimeline).diff(dayjs(endTimeline), "day") * 100,
    sidebarWidth: 100,
    itemHeight: 80,
    isSidebar: true,
    isResize: true,
    isTimeline: true,
    isBaseTimeFormat: false,
    isVerticalMode: false,
    isCurrentTime: false,
    mode: { type: "week", style: "modern" },
    grid: {
      enabled: true,
      onGridItemClick: (props) => {
        const { channelUuid, date } = props;
        if (channelUuid !== editedChannelUuid) {
          dispatch(
            redirectRequest({
              path: `/s/strandkorb/${city?.name}/${section?.name}/${section?.id}`,
            })
          );
        }
        dispatch(
          changeDateFromRequest({
            dateFrom: dayjs(date).toISOString(),
          })
        );
        dispatch(changeDateToRequest({ dateTo: dayjs(date).toISOString() }));
        setValue([new Date(date), new Date(date)]);
        dispatch(isChangeDateByUserRequest({ toggle: true }));
        clearCreatedBooking(editedChannelUuid);
        createBooking({
          since: `${date}T01:00:00`,
          till: `${date}T23:59:59`,
          channelUuid,
          type: "createdBooking",
        });
        setModal(true);
      },
      hoverHighlight: true,
    },
    areas: createWeekends(startTimeline, endTimeline),
    theme,
  });
  const dayDiff = useMemo(() => {
    return (dayjs(end)?.diff(dayjs(start), "day") ?? 0) + 1;
  }, [start, end]);
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        height: "82%",
        minHeight: "82%",
        maxHeight: "82%",
        width: "100%",
        minWidth: "100%",
        maxWidth: "100%",
      }}
    >
      <Box
        sx={{
          height: {
            xs: `calc(100vh - 3*${stylesConfig.appBarHeight} - *${stylesConfig.footerHeight} - 130px)`,
            sm: `calc(100vh - 3*${stylesConfig.appBarHeight} - *${stylesConfig.footerHeight} - 130px)`,
            md: `calc(100vh - ${stylesConfig.appBarHeight} - 3*${stylesConfig.marginTop} - 2*${stylesConfig.marginBottom} - 130px)`,
          },
          width: "100%",
          minWidth: "100%",
          maxWidth: "100%",
        }}
      >
        <Epg {...getEpgProps()}>
          <Layout
            {...getLayoutProps()}
            renderProgram={({ program, ...rest }) => (
              <Item key={program.data.id} program={program} {...rest} />
            )}
            renderChannel={({ channel }) => (
              <ChannelItem key={channel.uuid} channel={channel} />
            )}
            renderTimeline={(props) => <CustomTimeline {...props} />}
          />
        </Epg>
      </Box>
      {isBeachChairTimelineModal && (
        <Modal
          hideBackdrop={true}
          open={isBeachChairTimelineModal}
          onClose={() => {
            setModal(false);
          }}
          style={{ zIndex: 999999999 }}
        >
          <Box
            style={{
              top: `50%`,
              left: `50%`,
              transform: `translate(-50%, -50%)`,
            }}
            sx={ModalStyles.paper}
          >
            <Box sx={ModalStyles.beachChairDateRangeModal}>
              <Typography
                sx={{
                  width: "100%",
                  fontWeight: "bold",
                  color: SBColor.blue,
                  textAlign: "center",
                }}
              >
                {"Bitte wählen Sie ihren Buchungszeitraum"}
              </Typography>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                locale={deLocale}
              >
                <MobileDateRangePicker
                  open={open}
                  onOpen={handleOpen}
                  onClose={handleClose}
                  InputProps={{ readOnly: true }}
                  startText={t("common:pickup")}
                  endText={t("common:dropoff")}
                  toolbarTitle={t("common:timerange")}
                  cancelText={t("common:cancel")}
                  inputFormat="dd.MM.yyyy"
                  mask="__.__.____"
                  disablePast
                  value={value}
                  onChange={(newValue) => {
                    if (
                      newValue &&
                      newValue[0] &&
                      newValue[1] &&
                      isValidDate(newValue[0]) &&
                      isValidDate(newValue[1])
                    ) {
                      dispatch(
                        changeDateFromRequest({
                          dateFrom: newValue[0].toISOString(),
                        })
                      );
                      dispatch(
                        changeDateToRequest({
                          dateTo: newValue[1].toISOString(),
                        })
                      );
                      setValue(newValue);
                      setCreatedBooking(editedChannelUuid, {
                        since: `${dayjs(newValue[0].toISOString()).format(
                          "YYYY-MM-DD"
                        )}T01:00:00`,
                        till: `${dayjs(newValue[1].toISOString())
                          .add(-1, "day")
                          .add(-1, "day")
                          .format("YYYY-MM-DD")}T23:59:59`,
                        editedChannelUuid,
                        type: "createdBooking",
                      });
                      dispatch(isChangeDateByUserRequest({ toggle: true }));
                    }
                  }}
                  renderInput={(startProps, endProps) => (
                    <Box sx={SearchStyle.datePickersTimeline}>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <Typography sx={{ ...SBLabelStyle, width: "120px" }}>
                          {t("common:start")}
                        </Typography>
                        <TextField
                          {...startProps}
                          inputRef={(input) => input && input.blur()}
                          fullWidth
                          variant="outlined"
                          sx={SearchStyle.textRoundedField}
                          InputProps={{
                            readOnly: true,
                            style: { fontSize: matchesMd ? 11 : 12 },
                            endAdornment: (
                              <DateRangeIcon
                                sx={SearchStyle.datePickerIconColor}
                                onClick={handleOpen}
                              />
                            ),
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <Typography sx={{ ...SBLabelStyle, width: "120px" }}>
                          {t("common:end")}
                        </Typography>
                        <TextField
                          {...endProps}
                          inputRef={(input) => input && input.blur()}
                          fullWidth
                          variant="outlined"
                          sx={SearchStyle.textRoundedField}
                          InputProps={{
                            readOnly: true,
                            style: { fontSize: matchesMd ? 11 : 12 },
                            endAdornment: (
                              <DateRangeIcon
                                sx={SearchStyle.datePickerIconColor}
                                onClick={handleOpen}
                              />
                            ),
                          }}
                        />
                      </Box>
                    </Box>
                  )}
                />
              </LocalizationProvider>

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  gap: "50px",
                  alignItems: "center",
                }}
              >
                <InputLabel sx={{ ...SBLabelStyle }}>
                  {t("common:daysLabel")}
                </InputLabel>

                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "auto auto auto",
                    justifyContent: "center",
                    border: `1px solid ${SBColor.lightGray}`,
                    borderRadius: "25px",
                  }}
                >
                  <IconButton
                    aria-label="deduct"
                    sx={{ ...SBTextFieldIconStyle, width: "40px" }}
                    onClick={() => {
                      setValue([
                        new Date(start),
                        new Date(dayjs(end).add(-1, "day").toISOString()),
                      ]);
                      dispatch(
                        changeDateToRequest({
                          dateTo: dayjs(end).add(-1, "day").toISOString(),
                        })
                      );
                      setCreatedBooking(editedChannelUuid, {
                        till: `${dayjs(end)
                          .add(-1, "day")
                          .add(-1, "day")
                          .format("YYYY-MM-DD")}T23:59:59`,
                        editedChannelUuid,
                        type: "createdBooking",
                      });
                    }}
                    disabled={dayDiff <= 1}
                  >
                    <Remove />
                  </IconButton>
                  <Typography
                    sx={{
                      display: "flex",
                      width: "40px",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "center",
                      fontWeight: "bold",
                    }}
                  >
                    {dayDiff}
                  </Typography>
                  <IconButton
                    aria-label="add"
                    sx={{ ...SBTextFieldIconStyle, width: "40px" }}
                    onClick={() => {
                      setValue([
                        new Date(start),
                        new Date(dayjs(end).add(1, "day").toISOString()),
                      ]);
                      dispatch(
                        changeDateToRequest({
                          dateTo: dayjs(end).add(1, "day").toISOString(),
                        })
                      );
                      setCreatedBooking(editedChannelUuid, {
                        till: `${dayjs(end)
                          .add(1, "day")
                          .format("YYYY-MM-DD")}T23:59:59`,
                        editedChannelUuid,
                        type: "createdBooking",
                      });
                    }}
                  >
                    <Add />
                  </IconButton>
                </Box>
                <InputLabel sx={{ ...SBLabelStyle }}>
                  {dayDiff === 1 ? t("common:day") : t("common:days")}
                </InputLabel>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <Button
                  sx={{
                    backgroundColor: SBColor.blue,
                    color: SBColor.white,
                    width: "100px",
                    fontWeight: "bold",
                  }}
                  onClick={() => {
                    const beachChairId = +editedChannelUuid.split("-")[1];
                    dispatch(
                      selectBeachChairToBookingRequest({
                        beachChairId: beachChairId,
                      })
                    );
                    dispatch(
                      redirectRequest({
                        path: `/s/strandkorb/${city?.name}/${section?.name}/${section?.id}/${beachChairId}`,
                      })
                    );
                    setModal(false);
                  }}
                >
                  {t("common:accept")}
                </Button>
                <Button
                  sx={{
                    backgroundColor: SBColor.orange,
                    color: SBColor.white,
                    width: "100px",
                    fontWeight: "bold",
                  }}
                  onClick={() => {
                    clearCreatedBooking(editedChannelUuid);
                    dispatch(
                      redirectRequest({
                        path: `/s/strandkorb/${city?.name}/${section?.name}/${section?.id}`,
                      })
                    );
                    setModal(false);
                  }}
                >
                  {t("cancel")}
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      )}
    </Box>
  );
};

export default AvailabilitiesBeachChairTimeline;
