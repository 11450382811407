import {
  Box,
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  LinearProgress,
} from "@mui/material";
import React, { Fragment, useMemo, useState } from "react";
import { LocationAvailabilitiesBeachChairsStyle } from "../../../utils/hooks/styles/locationAvailabilitiesBeachChairsStyle";

import {
  selectBeachChairRequest,
  setRowRequest,
} from "../../../store/reducers/availabilityReducer";
import AvailabilitiesBeachChair from "./availabilitiesBeachChair";
import { useTranslation } from "react-i18next";
import { redirectRequest } from "../../../store/reducers/mainAppReducer";
import { getVendorSelector } from "../../../store/selectors/vendorSelectors";
import { getSelectedCitySelector } from "../../../store/selectors/citySelectors";
import { getSelectedBeachChairSelector } from "../../../store/selectors/bookingSelectors";
import {
  getAllBookedBeachChairs,
  selectBeachChairToBookingRequest,
} from "../../../store/reducers/bookingReducer";
import { isDisabled } from "../../../utils/checkers/isDisabled";
import { useAppDispatch } from "../../../store";
import { useSelector } from "react-redux";
import {
  getPendingAvailabilitiesSelector,
  getRowIdSubSectionSelector,
  getSectionsSelector,
} from "../../../store/selectors/availabilitySelectors";
import {
  getDateFromSelector,
  getDateToSelector,
} from "../../../store/selectors/mainAppSelectors";
import { getSelectedSectionSelector } from "../../../store/selectors/sectionSelectors";
import RowDivider from "../rowDivider/rowDivider";

export const formatDate = (year: Number, season: String) => {
  const seasonMonthDay = season.split("-");
  return new Date(+year, +seasonMonthDay[1] - 1, +seasonMonthDay[0]);
};

const AvailabilitiesBeachChairs = () => {
  const [show, setShow] = useState(false);
  const showColor = true;
  const { t } = useTranslation("availabilities");
  const dispatch = useAppDispatch();
  const selectedId = useSelector(getSelectedBeachChairSelector);
  const rowId = useSelector(getRowIdSubSectionSelector);
  const inBookingSelectedIds = useSelector(getAllBookedBeachChairs);
  const section = useSelector(getSelectedSectionSelector);
  const city = useSelector(getSelectedCitySelector);
  const rows = useSelector(getSectionsSelector);
  const pending = useSelector(getPendingAvailabilitiesSelector);
  const vendor = useSelector(getVendorSelector);
  const start = useSelector(getDateFromSelector);
  const end = useSelector(getDateToSelector);

  const displayRows = useMemo(() => {
    return (
      rows.length > 0 &&
      rows.every((row) => (row?.rowData?.length ? true : false))
    );
  }, [rows]);

  const [showBookingStart, setShowBookingStart] = useState(false);

  React.useEffect(() => {
    setShowBookingStart(
      vendor?.configuration.bookingStartdate &&
        vendor?.configuration.bookingStartdate !== ""
        ? true
        : false
    );
  }, [vendor]);

  return (
    <>
      {displayRows && vendor?.configuration.gridLineBreak && (
        <Box sx={LocationAvailabilitiesBeachChairsStyle.noDataText}>
          {t("availabilities:beachChairPosition")}
        </Box>
      )}
      {pending ? (
        <LinearProgress />
      ) : displayRows ? (
        rows
          ?.filter((row) => rowId === undefined || row.id === rowId)
          .map(
            (row) =>
              row.rowData.length > 0 && (
                <Fragment key={`${row.id}-${row.name}`}>
                  <Box
                    sx={LocationAvailabilitiesBeachChairsStyle.sticky}
                    style={
                      vendor?.configuration.gridLineBreak
                        ? { marginBottom: "20px" }
                        : {}
                    }
                  >
                    <RowDivider name={`${row.name}`} />
                  </Box>
                  <Box
                    sx={LocationAvailabilitiesBeachChairsStyle.rowContent}
                    style={
                      vendor?.configuration.gridLineBreak
                        ? { display: "grid", gridGap: "20px" }
                        : {}
                    }
                  >
                    {row.rowData.map((beachChair) => (
                      <Box
                        onClick={() => {
                          dispatch(setRowRequest({ selectedRow: row }));
                          dispatch(
                            selectBeachChairRequest({
                              selectedBeachChair: beachChair,
                            })
                          );
                          dispatch(
                            selectBeachChairToBookingRequest({
                              beachChairId: beachChair.id,
                            })
                          );
                          dispatch(
                            redirectRequest({
                              path: `/s/strandkorb/${city?.name}/${section?.name}/${section?.id}/${beachChair.id}`,
                            })
                          );
                        }}
                        key={`${row.name}-${beachChair.id}`}
                      >
                        <AvailabilitiesBeachChair
                          id={beachChair.publicNumber}
                          timeEnum={beachChair.timeEnum}
                          hasLock={beachChair.lockId !== null}
                          isSeasonBeachChair={beachChair.seasonBeachChair}
                          selected={selectedId === beachChair.id}
                          attributes={beachChair.attributes}
                          showColor={showColor}
                          inBookingSelected={isDisabled(
                            inBookingSelectedIds.filter(
                              (item) => item.beachChairId === beachChair.id
                            ),
                            start,
                            end
                          )}
                        />
                      </Box>
                    ))}
                  </Box>
                </Fragment>
              )
          )
      ) : (
        <Box sx={LocationAvailabilitiesBeachChairsStyle.noData}>
          <Typography
            variant={"h2"}
            sx={LocationAvailabilitiesBeachChairsStyle.noData}
          >
            {showBookingStart
              ? t("bookingStartNotStarted", { city: vendor?.address.city })
              : t("noData")}
          </Typography>
          <Typography
            variant={"h3"}
            sx={{
              ...LocationAvailabilitiesBeachChairsStyle.noDataText,
              padding: 5,
            }}
          >
            {showBookingStart
              ? t("bookingStartNotStartedText", {
                  bookingStartDate: vendor?.configuration.bookingStartdate,
                })
              : t("contactDirectly")}
          </Typography>
          <Card
            sx={{ maxWidth: 375, minHeight: 350, marginLeft: 1 }}
            elevation={6}
          >
            <CardContent>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                {t("contact")}
              </Typography>
              <Typography variant="h5" component="div">
                {vendor?.name}
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                {t("address")}
              </Typography>
              <Typography variant="body2">
                {vendor?.address.street}
                <br />
                {vendor?.address.zip} {vendor?.address.city}
                <br />
                {vendor?.address.country}
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                variant="outlined"
                size="small"
                onClick={() => setShow((prev) => !prev)}
                target="_blank"
                href={`mailto:${
                  vendor?.details.email
                }?bcc=info@strandbutler.de&subject=${t(
                  "onlineBooking"
                )}&body=${t("emailContent")}`}
              >
                {t("email")}
              </Button>
              &nbsp;
              <Button
                variant="outlined"
                size="small"
                onClick={() => setShow((prev) => !prev)}
              >
                {t("telephone")}
              </Button>
              <Button
                variant="outlined"
                size="small"
                onClick={() => setShow((prev) => !prev)}
                target="_blank"
                href={`${vendor?.details.website}`}
              >
                {t("website")}
              </Button>
            </CardActions>
            <CardContent>
              {show && (
                <Typography variant="body2">
                  <Box>{vendor?.details.email}</Box>
                  <Button href={`tel:${vendor?.details.telephone}`}>
                    {vendor?.details.telephone}
                  </Button>
                </Typography>
              )}
            </CardContent>
          </Card>
        </Box>
      )}
    </>
  );
};

export default AvailabilitiesBeachChairs;
