import { makeStyles } from "@mui/styles";
export const useRightDrawerStyles = makeStyles((theme) => ({
  root: {
    zIndex: 99999999999999999999,
  },
  drawerRightPaper: {
    top: `80px !important`,
    bottom: `80px !important`,
    background: "none !important",
    overflowY: "clip",
    height: "inherit",
  },
  drawerLeftPaper: {
    top: `80px !important`,
    bottom: `80px !important`,
    background: "none !important",
    overflowY: "clip",
    height: "inherit",
    [theme.breakpoints.between("lg", "md")]: {
      width: "100vw",
    },
    [theme.breakpoints.down(900)]: {
      width: "100vw",
    },
  },
}));
