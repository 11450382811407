import { all, fork } from "redux-saga/effects";
import availabilitySaga from "./availabilitiesSaga";
import bookingSaga from "./bookingsSaga";
import extrasSaga from "./extrasSaga";
import locationSaga from "./locationsSaga";
import mainAppSaga from "./mainAppSaga";
import priceSaga from "./priceSaga";
import vendorSaga from "./vendorSaga";

export function* rootSaga() {
  yield all([
    fork(mainAppSaga),
    fork(locationSaga),
    fork(availabilitySaga),
    fork(priceSaga),
    fork(bookingSaga),
    fork(vendorSaga),
    fork(extrasSaga),
  ]);
}
