import { Box, Divider, IconButton, Typography } from "@mui/material";
import React from "react";
import { EURO } from "../../utils/convertions/euroFormater";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { AdditionalItemsStyles } from "../../utils/hooks/styles/additionaltemsStyle";
import { useAppDispatch } from "../../store";
import {
  addExtraToPreBookingRequest,
  removeExtraFromPreBookingRequest,
} from "../../store/reducers/extrasReducer";
import { useSelector } from "react-redux";
import {
  getAllExtrasSelector,
  getPreBookingExtraByIdSelector,
} from "../../store/selectors/extraSelectors";

interface IItemToChoose {
  name: string;
  price: number;
  extraId: number;
}

const ItemToChoose = ({ name, price, extraId }: IItemToChoose) => {
  const dispatch = useAppDispatch();
  const item = useSelector(getPreBookingExtraByIdSelector(extraId));
  const count = item?.count || 0;
  return (
    <Box sx={{ ...AdditionalItemsStyles.itemToChooseBox }}>
      <Box>
        <Typography sx={{ ...AdditionalItemsStyles.typographyLabel }}>
          {name}
        </Typography>
        <Typography sx={{ ...AdditionalItemsStyles.typographyPrice }}>
          {EURO(price || 0).format()}
        </Typography>
      </Box>
      <Box sx={{ ...AdditionalItemsStyles.itemAddRemoveBox }}>
        <IconButton
          onClick={() => {
            dispatch(
              removeExtraFromPreBookingRequest({
                extraId,
                count,
                price,
              })
            );
          }}
          disabled={count === 0}
        >
          <RemoveIcon sx={{ ...AdditionalItemsStyles.addRemoveIcon }} />
        </IconButton>
        <Typography
          sx={{
            ...(count > 0
              ? AdditionalItemsStyles.countIndicatorPositive
              : AdditionalItemsStyles.countIndicatorZero),
          }}
        >
          {count}
        </Typography>
        <IconButton
          onClick={() => {
            dispatch(addExtraToPreBookingRequest({ extraId, count, price }));
          }}
        >
          <AddIcon sx={{ ...AdditionalItemsStyles.addRemoveIcon }} />
        </IconButton>
      </Box>
    </Box>
  );
};

export interface IItemsToChooseProps {
  type: string;
}

const ItemsToChoose = ({ type }: IItemsToChooseProps) => {
  const items = useSelector(getAllExtrasSelector);

  const filteredItems = React.useMemo(
    () => items.filter((item) => item.type === type),
    [items, type]
  );

  return (
    <Box sx={{ ...AdditionalItemsStyles.itemsBoxHeight }}>
      {filteredItems.map((item) => (
        <Box key={`${item.name}-${item.price}`}>
          <ItemToChoose name={item.name} price={item.price} extraId={item.id} />
          <Divider />
        </Box>
      ))}
    </Box>
  );
};

export default ItemsToChoose;
