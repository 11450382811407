import { isWithinInterval, isEqual } from "date-fns";
import { IIsBookedBeachChair } from "../models/IIsBookedBeachChair";

export const isDisabled = (
  inBookingSelectedIds: IIsBookedBeachChair[],
  start: string,
  end: string
): boolean => {
  let disabled = false;
  inBookingSelectedIds.forEach((selected) => {
    disabled = isWithinInterval(new Date(selected.start), {
      start: new Date(start),
      end: new Date(end),
    });
    if (!disabled)
      disabled = isWithinInterval(new Date(selected.end), {
        start: new Date(start),
        end: new Date(end),
      });
    if (!disabled)
      disabled =
        isEqual(new Date(start), new Date(selected.start)) &&
        isEqual(new Date(end), new Date(selected.end));
  });
  return disabled;
};
