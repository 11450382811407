import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EMapState } from "../../utils/models/EMapState";
import {
  SetMapLocationDefaultRequestPayload,
  SetMapLocationRequestPayload,
  SetMapStateLocationRequestPayload,
} from "../actionPayload/mapPayloads";

export interface ICoordinates {
  lat: number;
  lng: number;
}

const mapSlice = createSlice({
  name: "map",
  initialState: {
    coordinates: { lat: 10.1201516, lng: 54.333523 } as ICoordinates,
    initialCoordinates: { lat: 10.1201516, lng: 54.333523 } as ICoordinates,
    mapState: EMapState.ALL_CITIES,
  },
  reducers: {
    setMapLocationRequest(
      state,
      action: PayloadAction<SetMapLocationRequestPayload>
    ) {
      state.coordinates = { lat: action.payload.lat, lng: action.payload.lng };
    },
    setMapLocationDefaultRequest(
      state,
      action: PayloadAction<SetMapLocationDefaultRequestPayload>
    ) {
      state.coordinates = state.initialCoordinates;
    },
    setMapStateRequest(
      state,
      action: PayloadAction<SetMapStateLocationRequestPayload>
    ) {
      state.mapState = action.payload.mapState;
    },
  },
});

export const {
  setMapLocationRequest,
  setMapStateRequest,
  setMapLocationDefaultRequest,
} = mapSlice.actions;

export default mapSlice.reducer;
