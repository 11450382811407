export default (function getBrowser() {
  const isOpera = (!!window.opr) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
  const isFirefox = typeof InstallTrigger !== 'undefined';
  const isSafari = (navigator.vendor !== null && navigator.vendor.match(/Apple Computer, Inc./) && navigator.userAgent.indexOf('Safari') !== -1);
  const isIE = /*@cc_on!@*/false || !!document.documentMode;
  const isEdge = !isIE && !!window.StyleMedia;
  const isChrome = (navigator.vendor.toLowerCase().indexOf('google') !== -1);
  const isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") !== -1);
  const isBlink = (isChrome || isOpera) && !!window.CSS;

  return {
    isOpera, isFirefox, isSafari, isEdge, isChrome, isEdgeChromium, isBlink, isIE
  }
})()
