import { makeStyles } from "@mui/styles";
import { stylesConfig } from "../../styles/styles";

/** COMPONENT Visual STRUCTURE

ssssssssssss

dpdpdpdpdpdp

blblblblblbl

s = search
dp = datepicker
bl = beach list
*/
export const SearchStyle = {
  root: {
    display: "grid",
    gap: {
      xs: 1,
      md: `calc(3*${stylesConfig.marginTop})`,
    },
    paddingTop: `calc(3*${stylesConfig.marginTop})`,
    paddingBottom: `calc(3*${stylesConfig.marginTop})`,
    gridTemplateRows: "auto auto auto",
    alignItems: "center",
    justifyItems: "center",
    justifyContent: "center",
    padding: {
      xs: "10px",
      sm: "10px",
    },
    margin: {
      xs: "10px",
      sm: "10px",
      md: "10px 0 0 0",
    },
    maxHeight: {
      md: `calc(70vh - ${stylesConfig.appBarHeight} - 3*${stylesConfig.marginTop} - 3*${stylesConfig.marginBottom})`,
    },
    backgroundColor: stylesConfig.whiteColor,
    borderRadius: stylesConfig.sxBorderRadius,
    boxShadow: stylesConfig.boxShadow,
  } as const,
  textRoundedField: {
    "&:hover": {
      borderColor: stylesConfig.blueColor,
    },
    [`& fieldset`]: {
      borderRadius: stylesConfig.sxBorderRadius,
      borderColor: stylesConfig.blackTransparentColor,
    },
  },
  datePickers: {
    maxWidth: 300,
    minWidth: 300,
    display: "grid",
    gridTemplateColumns: "auto auto",
    justifyContent: "center",
    gap: 1,
  },
  datePickersTimeline: {
    maxWidth: 500,
    minWidth: 300,
    display: "grid",
    gridTemplateColumns: "auto",
    gap: "30px",
  },
  typographyBoldBlue: {
    fontSize: {
      xs: 13,
      sm: 13,
      md: 16,
    },
    color: stylesConfig.blueColor,
    fontWeight: "bold",
  },
  typographyBoldGray: {
    fontSize: {
      xs: 13,
      sm: 13,
      md: 16,
    },
    color: stylesConfig.grayColor,
    fontWeight: "bold",
    display: "grid",
    alignContent: "center",
    marginRight: "10px",
  },
  colorBlack: {
    color: stylesConfig.blueColor,
    fontWeight: "bold",
  },
  datePickerIconColor: {
    color: stylesConfig.blackDarkTransparentColor,
    cursor: "pointer",
  },
  switchContent: {
    display: "grid",
    gridTemplateColumns: "auto auto",
    justifyContent: "center",
  },
};

export const useSearchStyles = makeStyles((theme) => ({
  textRoundedField: {
    "&:hover": {
      borderColor: stylesConfig.blueColor,
    },
    [`& fieldset`]: {
      borderRadius: stylesConfig.borderRadius,
      borderColor: stylesConfig.blackTransparentColor,
    },
  },
  datePicker: {
    marginTop: 0,
    marginBottom: 0,
  },
  popper: {
    zIndex: 99999999,
    whiteSpace: "pre-wrap",
    overflowY: "scroll",
    boxSizing: "content-box",
    "&::-webkit-scrollbar": {
      width: 1,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: stylesConfig.lightGrayColor,
    },
  },
}));
