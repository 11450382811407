import { createStyles, makeStyles } from "@mui/styles";
import { stylesConfig } from "../../styles/styles";

export const ModalStyles = {
  paper: {
    position: "absolute",
    width: {
      xs: 360,
      sm: 550,
    },
    justifyItems: "center",
    alignItems: "center",
    backgroundColor: stylesConfig.whiteColor,
    borderRadius: stylesConfig.sxBorderRadius,
    paddingTop: stylesConfig.marginTop,
    paddingBottom: stylesConfig.marginBottom,
    paddingLeft: stylesConfig.marginLeft,
    paddingRight: stylesConfig.marginRight,
    maxHeight: {
      xs: "70vh",
      sm: "70vh",
    },
    boxShadow: {
      xs: stylesConfig.blueBoxShadow,
      sm: stylesConfig.blueBoxShadow,
      md: stylesConfig.blueBoxShadow,
    },
    overflowY: "scroll",
    boxSizing: "content-box",
    "&::-webkit-scrollbar": {
      width: 3,
      height: 3,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: stylesConfig.blueColor,
      borderRadius: stylesConfig.sxBorderRadius,
    },
  } as const,
  button: {
    width: 200,
    color: `${stylesConfig.whiteColor}`,
    backgroundColor: `${stylesConfig.orangeColor}`,
    borderRadius: stylesConfig.borderRadius,
    height: stylesConfig.defaultControlHeight,
    boxShadow: stylesConfig.orangeBoxShadow,
    "&:hover": {
      backgroundColor: `${stylesConfig.orangeColor}`,
    },
    "&:disabled, &.Mui-disabled": {
      backgroundColor: stylesConfig.blueColor,
      boxShadow: stylesConfig.blueBoxShadow,
      color: "rgba(255,255,255,0.5)",
    },
  },
  secondaryButton: {
    color: `${stylesConfig.whiteColor}`,
    backgroundColor: `${stylesConfig.blueColor}`,
    boxShadow: stylesConfig.blueBoxShadow,
    "&:hover": {
      backgroundColor: `${stylesConfig.whiteColor}`,
      color: `${stylesConfig.blueColor}`,
    },
  },
  box: {
    width: "100%",
    height: "100%",
    display: "grid",
    gridTemplateColumns: {
      xs: "auto",
      sm: "auto",
      md: "50% 50%",
    },
    gridTemplateRows: {
      xs: "auto auto",
      sm: "auto auto",
      md: "auto",
    },
  },
  termsWidth: {
    width: {
      xs: 300,
      sm: "100%",
      md: "100%",
    },
  },
  checkBox: {
    marginLeft: {
      xs: "3px",
      sm: "32px",
      md: "20px",
    },
    paddingLeft: {
      xs: "0",
      sm: "",
      md: "9px",
    },
  },
  selectionModal: {
    display: "grid",
    gap: "10px",
    gridTemplateRows: {
      xs: "200px 200px",
      sm: "200px 70px",
      md: "200px 70px",
    },
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
  },
  beachChairDateRangeModal: {
    height: "300px",
    display: "grid",
    gap: "5px",
    padding: "20px",
    justifyContent: "center",
  },
  typographyBlue: {
    fontSize: {
      xs: 12,
      sm: 12,
      md: 12,
    },
    fontWeight: "normal",
    color: stylesConfig.blueColor,
  },
  typographyBoldDarkBlueSize: {
    fontSize: {
      xs: 10,
      sm: 10,
      md: 12,
    },
    fontWeight: "bold",
    color: stylesConfig.darkBlue,
  },
  typographyBoldGrayLabelColorSize: {
    fontSize: {
      xs: 10,
      sm: 10,
      md: 12,
    },
    color: stylesConfig.grayLabelColor,
  },
  center: {
    alignContent: "center",
    justifyContent: "center",
    textAlign: "center",
  } as const,
  left: {
    alignContent: "center",
    justifyContent: "center",
    textAlign: "left",
  } as const,
  typographyBoldBlue: {
    fontSize: {
      xs: 12,
      sm: 12,
      md: 14,
    },
    fontWeight: "bolder",
    color: stylesConfig.blueColor,
  },
  typographyBoldDarkBlue: {
    fontSize: {
      xs: 12,
      sm: 12,
      md: 14,
    },
    fontWeight: "bolder",
    color: stylesConfig.darkBlue,
  },
  iconBlueColor: {
    color: stylesConfig.whiteColor,
    boxShadow: stylesConfig.blueBoxShadow,
    borderRadius: stylesConfig.sxBorderRadius,
    backgroundColor: stylesConfig.blueColor,
  },
  typographyBoldOrangeSelected: {
    fontSize: {
      xs: 13,
      sm: 13,
      md: 16,
    },
    fontWeight: "bolder",
    color: stylesConfig.orangeColor,
  },
  selectionModalButtons: {
    display: "grid",
    gap: "10px",
    justifyContent: "center",
    alignItems: "center",
    gridTemplateColumns: {
      xs: "auto",
      sm: "auto auto",
      md: "auto auto",
    },
  },
  paymentMethods: {
    maxHeight: "500px",
    display: "grid",
    gap: 1,
  },
  paymentMethod: {
    display: "grid",
    gap: 1,
    alignItems: "center",
    gridTemplateColumns: "64px 200px",
    cursor: "pointer",
  },
  customSepa: {
    dispaly: "grid",
    gap: 1,
    alignItems: "center",
  } as const,
  selectedPaymentMethod: {
    display: "grid",
    gap: 1,
    alignItems: "center",
    gridTemplateColumns: "64px 200px",
    cursor: "pointer",
  },
  cardIcon: {
    height: "40px",
    width: "70px",
    justifySelf: "right",
    filter: "grayscale(100%) ",
  },
  cardIconColor: {
    height: "40px",
    width: "70px",
    justifySelf: "right",
  },
  radioButtonLabel: {
    display: "grid",
    width: "200px",
    gridTemplateColumns: "auto auto",
    alignItems: "center",
  },
  radioGroupGrid: {
    display: "grid",
    gap: 1,
    gridTemplateRows: "auto auto auto",
    gridTemplateColumns: {
      xs: "auto",
      sm: "auto auto",
      md: "auto auto",
    },
  },
  sepaTitleGrid: {
    width: "100%",
    display: "grid",
    gap: 1,
    justifyItems: "center",
    alignContent: "center",
  },
  sepaGrid: {
    display: "grid",
    gap: 1,
    gridTemplateRows: "auto auto",
    gridTemplateColumns: {
      xs: "auto",
      sm: "auto auto",
      md: "auto auto",
    },
  },
  textRoundedSepaField: {
    color: stylesConfig.blueColor,
    width: "260px",
    [`& fieldset`]: {
      borderRadius: stylesConfig.borderRadius,
      borderColor: stylesConfig.grayLabelColor,
    },
  },
};

export const useModalStyles = makeStyles((theme) =>
  createStyles({
    firsStateGrid: {
      display: "grid",
      justifyItems: "center",
      alignItems: "center",
      gap: stylesConfig.marginTop,
    },
    textField: {
      color: stylesConfig.blueColor,
      borderRadius: stylesConfig.borderRadius,
      boxShadow: stylesConfig.boxShadow,
    },
    blueIcon: {
      color: stylesConfig.blueColor,
    },
    notchedOutline: {
      borderWidth: "1px",
      borderColor: `${stylesConfig.blueColor} !important`,
      borderRadius: stylesConfig.borderRadius,
      boxShadow: stylesConfig.boxShadow,
    },
    formPaymentMethod: {
      display: "grid",
      gridTemplateRows: "15% auto 15%",
      justifyItems: "center",
      alignItems: "center",
      gap: "20px",
    },
    radio: {
      "&$checked": {
        color: stylesConfig.blueColor,
      },
    },
    checked: {},
    insideCard: {
      height: "calc(5*56px + 4*0.5vh)",
      padding: 20,
      paddingTop: 5,
      display: "grid",
      gridTemplateRows: "20% 20% 20% 20% 20% auto",
      gap: "0.5vh",
    },
    insideCardSecond: {
      padding: 20,
      paddingTop: 5,
      display: "grid",
      gridTemplateRows: "20% 20% 20% 20%",
      gap: "0.5vh",
    },
    insideCardSecondRow: {
      height: "calc(4*56px + 4*0.5vh)",
      padding: 20,
      paddingTop: 5,
      display: "grid",
      gap: "0.5vh",
    },
    propGrid: {
      display: "grid",
      gridTemplateColumns: "100%",
      alignItems: "center",
    },
    mainColor: {
      color: stylesConfig.blueColor,
    },
    inputRoot: {
      "& .MuiInputBase-root.Mui-disabled": {
        color: stylesConfig.blueColor,
      },
    },
    textArea: {
      alignItems: "flex-start !important",
      gridRowStart: "4",
      gridRowEnd: "6",
    },
    textRoundedField: {
      "&:hover": {
        borderColor: stylesConfig.blueColor,
      },
      [`& fieldset`]: {
        borderRadius: stylesConfig.borderRadius,
        borderColor: stylesConfig.blackTransparentColor,
      },
    },
    textRoundedSepaField: {
      color: stylesConfig.blueColor,
      width: "260px",
      cssOutlinedInput: {
        "&$cssFocused $notchedOutline": {
          borderColor: `${theme.palette.primary.main} !important`,
        },
      },

      cssFocused: {},

      notchedOutline: {
        borderWidth: "1px",
        borderColor: "green !important",
      },
      "&:hover": {
        borderColor: stylesConfig.blueColor,
      },
      [`& fieldset`]: {
        borderRadius: stylesConfig.borderRadius,
        borderColor: stylesConfig.blackTransparentColor,
      },
    },
  })
);
