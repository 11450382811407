import { createSelector } from "reselect";
import { AppState } from "../reducers/rootReducer";

const getCoordinates = (state: AppState) => state.map.coordinates;
const getInitialCoordinates = (state: AppState) => state.map.initialCoordinates;
const getMapState = (state: AppState) => state.map.mapState;

export const getCoordinatesSelector = createSelector(
  getCoordinates,
  (coordinates) => coordinates
);

export const getInitialCoordinatesSelector = createSelector(
  getInitialCoordinates,
  (initialCoordinates) => initialCoordinates
);

export const getMapStateSelector = createSelector(
  getMapState,
  (mapState) => mapState
);
