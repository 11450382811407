import { Action } from "@reduxjs/toolkit";
import { all, put, takeLatest, select, call } from "typed-redux-saga";
import {
  BookingResponseDto,
  CreateExtrasBookingBulkDto,
} from "../../api-client/generated";
import { publicApi } from "../../api/api";
import {
  addExtrasBookingSuccess,
  createBookingSuccess,
  setBookingRef,
} from "../reducers/bookingReducer";
import {
  addPreBookingExtrasToBookingRequest,
  addPreBookingExtrasToBookingSuccess,
  extraFailure,
  getExtrasRequest,
  getExtrasSuccess,
} from "../reducers/extrasReducer";
import { rightDrawerChangeRequest } from "../reducers/mainAppReducer";
import {
  getBookingRefSelector,
  getPublicReferenceSelector,
} from "../selectors/bookingSelectors";
import { getAllPreBookingSelector } from "../selectors/extraSelectors";
import { useWidgetZustand } from "../../contexts/zustand/widgetZustand";
import { MetaDataSource } from "../../utils/models/MeraDataSource";

function* getExtrasSaga(action: Action) {
  try {
    if (getExtrasRequest.match(action)) {
      const publicReference = yield* select(getPublicReferenceSelector);
      const { data } = yield* call(
        [publicApi, publicApi.publicControllerGetExtras],
        {
          publicReference,
          page: 0,
          itemsPerPage: 0,
        }
      );

      yield* put(getExtrasSuccess({ extras: data.items }));
    }
  } catch (e: any) {
    yield* put(
      extraFailure({
        error: e.error,
      })
    );
  }
}

function* addPreBookingExtrasToBookingSaga(action: Action) {
  try {
    if (addPreBookingExtrasToBookingRequest.match(action)) {
      const { widgetUrlZustand } = useWidgetZustand.getState();
      const publicReference = yield* select(getPublicReferenceSelector);
      const bookingReference = yield* select(getBookingRefSelector);
      if (bookingReference === "") {
        const { data } = yield* call(
          [publicApi, publicApi.publicControllerPublicCreateBooking],
          {
            publicReference,
            source:
              widgetUrlZustand !== ""
                ? MetaDataSource.WIDGET
                : MetaDataSource.WEBSITE,
          }
        );
        yield* put(createBookingSuccess({ booking: data }));
        yield* put(
          setBookingRef({ bookingRef: data.bookingRef, publicReference })
        );
      }
      const bookingRef = yield* select(getBookingRefSelector);
      const preBooking = yield* select(getAllPreBookingSelector);
      const createExtrasBookingBulkDto = {
        items: preBooking.map((item) => {
          return { extraId: item.id, amount: item.count };
        }),
      } as unknown as CreateExtrasBookingBulkDto;
      const { data } = yield* call(
        [publicApi, publicApi.publicControllerAddExtras],
        {
          bookingReference: bookingRef,
          publicReference,
          createExtrasBookingBulkDto,
        }
      );
      yield* put(addPreBookingExtrasToBookingSuccess());
      const booking = data as unknown as BookingResponseDto;
      yield* put(addExtrasBookingSuccess({ booking }));
      yield* put(rightDrawerChangeRequest({ toggle: true }));
    }
  } catch (e: any) {
    yield* put(
      extraFailure({
        error: e.error,
      })
    );
  }
}

function* extrasSaga() {
  yield* all([
    takeLatest(getExtrasRequest.type, getExtrasSaga),
    takeLatest(
      addPreBookingExtrasToBookingRequest.type,
      addPreBookingExtrasToBookingSaga
    ),
  ]);
}

export default extrasSaga;
