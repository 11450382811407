import { stylesConfig } from "../../styles/styles";

export const DisplayerStylesRoot = {
  display: "grid",
  gap: {
    xs: "10px",
    sm: "10px",
    md: `calc(3*${stylesConfig.marginTop})`,
  },
  paddingLeft: {
    xs: "10px",
    sm: "10px",
    md: "20px",
  },
  paddingRight: {
    xs: "10px",
    sm: "10px",
    md: "20px",
  },
  paddingTop: {
    xs: "20px",
    sm: "10px",
    md: `calc(3*${stylesConfig.marginTop})`,
  },
  paddingBottom: {
    xs: "10px",
    sm: "10px",
    md: `calc(3*${stylesConfig.marginTop})`,
  },
  maxHeight: {
    xs: `${stylesConfig.beachChairMobileViewHeight}`,
    sm: `${stylesConfig.beachChairMobileViewHeight}`,
    md: `calc(100vh - ${stylesConfig.appBarHeight} - 2*${stylesConfig.marginTop} - 2*${stylesConfig.marginBottom})`,
  },
  backgroundColor: stylesConfig.whiteColor,
  borderRadius: {
    xs: `20px 20px 0 0`,
    sm: `20px 20px 0 0`,
    md: stylesConfig.sxBorderRadius,
  },
  boxShadow: {
    xs: stylesConfig.boxShadow,
    sm: stylesConfig.boxShadow,
    md: stylesConfig.boxShadow,
  },
  marginRight: 0,
  overflowY: "scroll",
  boxSizing: "content-box",
  "&::-webkit-scrollbar": {
    width: 3,
    height: 1,
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: stylesConfig.blueColor,
  },
} as const;

export const DisplayerStyles = {
  modal: {
    zIndex: 9999999999,
    tabIndex: -1
  } as const,

  mainBox: {
    position: 'absolute',
    margin: 'auto',
    bottom: 0,
    width: '100%'
  } as const,

  tableBox: {
    display: "flex",
    maxWidth: "100%"
  } as const,

  tableColumn: {
    flex: "auto",
    padding: "10px",
    paddingTop: 0
  } as const,

  typographyBoldTitle: {
    fontWeight: "bolder",
    fontSize: {
      xs: "20px",
      md: "25px",
    },
    color: `${stylesConfig.darkBlue}`,
    alignItems: "center"
  } as const,

  imageLogo: {
    imageRendering: "crisp-edges",
    width: "60px",
    height: "60px",
    margin: "10px 0"
  } as const,

  buttonApp: {
    color: `${stylesConfig.whiteColor}`,
    backgroundColor: `${stylesConfig.orangeColor}`,
    borderRadius: stylesConfig.sxBorderRadius,
    height: `calc(${stylesConfig.defaultControlHeight}*0.5)`,
    width: "100%",
    "&:hover": {
      backgroundColor: stylesConfig.orangeColor,
    },
  },

  buttonBrowser: {
    color: `${stylesConfig.darkBlue}`,
    backgroundColor: `${stylesConfig.whiteColor}`,
    borderColor: `${stylesConfig.grayColor}`,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: stylesConfig.sxBorderRadius,
    height: `calc(${stylesConfig.defaultControlHeight}*0.5)`,
    width: "100%",
    "&:hover": {
      backgroundColor: stylesConfig.whiteColor,
    },
  }
} as const;
