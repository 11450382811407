import { BeachChairsApi, PaymentsApi } from "./../api-client/generated/api";
import { Configuration } from "./../api-client/generated/configuration";
import axios from "axios";
import { apiConfig } from "../utils/oidc/oidcConfig";
import {
  FeaturesApi,
  LocationApi,
  PublicApi,
  BeachApi,
  RegionApi,
  CityApi,
  SectionApi,
  VendorApi,
  PricesApi,
  BookingsApi,
} from "../api-client/generated";
import { toast } from "react-toastify";
import i18next from "../utils/i18n/i18n";

const { publicUrl } = apiConfig;

const config = new Configuration();

const axiosInstance = axios.create({
  baseURL: publicUrl,
  withCredentials: true,
  headers: {
    appVersion: "Customer-ui",
  },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    i18next.setDefaultNamespace("error");
    if (error.response.data.errorType && error.response.data.payload) {
      const { payload } = error.response.data;
      toast.error(i18next.t(`${error.response.data.errorType}`, { payload }));
    }
    if (error.response.data.message && error.response.data.error) {
      toast.error(i18next.t(`${error.response.data.message}`));
    }
    if (error.response.status === 400) {
      // IN DEBUG
      //console.debug(error.response.data.message);
    }
    if (error.response.status === 401) {
      // IN DEBUG
      //console.debug(error.response.data.message);
    }
    return Promise.reject(error);
  }
);

const locationApi = new LocationApi(config, `${publicUrl}`, axiosInstance);
const featuresApi = new FeaturesApi(config, `${publicUrl}`, axiosInstance);
const cityApi = new CityApi(config, `${publicUrl}`, axiosInstance);
const regionApi = new RegionApi(config, `${publicUrl}`, axiosInstance);
const beachApi = new BeachApi(config, `${publicUrl}`, axiosInstance);
const sectionApi = new SectionApi(config, `${publicUrl}`, axiosInstance);
const publicApi = new PublicApi(config, `${publicUrl}`, axiosInstance);
const vendorApi = new VendorApi(config, `${publicUrl}`, axiosInstance);
const pricesApi = new PricesApi(config, `${publicUrl}`, axiosInstance);
const bookingsApi = new BookingsApi(config, `${publicUrl}`, axiosInstance);
const beachChairApi = new BeachChairsApi(config, `${publicUrl}`, axiosInstance);
const paymentsApi = new PaymentsApi(config, `${publicUrl}`, axiosInstance);

export {
  locationApi,
  featuresApi,
  publicApi,
  regionApi,
  cityApi,
  beachApi,
  sectionApi,
  vendorApi,
  pricesApi,
  bookingsApi,
  beachChairApi,
  paymentsApi,
};
