import {
  Box,
  CardMedia,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import BANK_TRANSFER from "../../../../assets/paymentMethods/bankTransfer.svg";
import SEPA from "../../../../assets/paymentMethods/sepa.svg";
import PAYPAL from "../../../../assets/paymentMethods/paypal.svg";
import INVOICE from "../../../../assets/paymentMethods/sepa.svg";
import { useSelector } from "react-redux";
import { getVendorSelector } from "../../../../store/selectors/vendorSelectors";
import { ModalStyles } from "../../../../utils/hooks/styles/modalStyle";
import { EBookingPaymentMethod } from "../../../../utils/models/EBookingPaymentMethod";
import { useTranslation } from "react-i18next";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import React, { useRef } from "react";
import OTHER_STRIPE from "./../../../../assets/paymentMethods/sofort.svg";
import STRIPE from "../../../../assets/paymentMethods/cc.svg";
import { getBookingSelector } from "../../../../store/selectors/bookingSelectors";
import { dateDiffInDays } from "../../../../utils/convertions/getDateDiffInDays";
interface IPaymentMethodsRadioButtonsProps {
  handlePaymentChange(paymentMethod: EBookingPaymentMethod): void;
  selectedCountry: string;
}

const PaymentMethodsRadioButtons = ({
  handlePaymentChange,
  selectedCountry,
}: IPaymentMethodsRadioButtonsProps) => {
  const vendor = useSelector(getVendorSelector);
  const paymentMethod = React.useRef<EBookingPaymentMethod>(
    EBookingPaymentMethod.STRIPE_CREDIT_CARD
  );
  const { t } = useTranslation();
  const booking = useSelector(getBookingSelector);
  const checkIfMoreThan14Days = useRef<boolean>(true);

  React.useEffect(() => {
    if (
      booking?.beachChairBookings !== undefined &&
      booking?.beachChairBookings.length > 0
    ) {
      booking.beachChairBookings.forEach((beachChairBooking) => {
        if (
          dateDiffInDays(new Date(), new Date(beachChairBooking.start)) >= 14
        ) {
          checkIfMoreThan14Days.current = false;
          return;
        }
      });
    }
  }, [booking]);

  return (
    <RadioGroup
      aria-label="position"
      name="position"
      onChange={(_, value) => {
        const pMethod = value as EBookingPaymentMethod;
        paymentMethod.current = pMethod;
        handlePaymentChange(pMethod);
      }}
      sx={ModalStyles.radioGroupGrid}
    >
      {!checkIfMoreThan14Days.current &&
        vendor?.paymentMethods.find(
          (method) => method === EBookingPaymentMethod.STRIPE_BANK_TRANSFER
        ) && (
          <FormControlLabel
            value={EBookingPaymentMethod.STRIPE_BANK_TRANSFER}
            control={
              <Radio
                style={{ color: "#c6ccd4" }}
                checkedIcon={
                  <CheckRoundedIcon sx={ModalStyles.iconBlueColor} />
                }
              />
            }
            label={
              <Box sx={ModalStyles.radioButtonLabel}>
                <Typography
                  sx={
                    paymentMethod.current ===
                      EBookingPaymentMethod.STRIPE_BANK_TRANSFER
                      ? ModalStyles.typographyBoldBlue
                      : ModalStyles.typographyBoldDarkBlue
                  }
                >
                  {t(`bookings:paymentMethod.STRIPE_BANK_TRANSFER`)}
                </Typography>
                <CardMedia
                  image={BANK_TRANSFER}
                  sx={
                    paymentMethod.current ===
                      EBookingPaymentMethod.STRIPE_BANK_TRANSFER
                      ? ModalStyles.cardIconColor
                      : ModalStyles.cardIcon
                  }
                />
              </Box>
            }
            labelPlacement="end"
          />
        )}
      {vendor?.paymentMethods.find(
        (method) => method === EBookingPaymentMethod.STRIPE_SOFORT
      ) && (
          <FormControlLabel
            value={EBookingPaymentMethod.STRIPE_SOFORT}
            control={
              <Radio
                style={{ color: "#c6ccd4" }}
                checkedIcon={<CheckRoundedIcon sx={ModalStyles.iconBlueColor} />}
              />
            }
            label={
              <Box sx={ModalStyles.radioButtonLabel}>
                <Typography
                  sx={
                    paymentMethod.current === EBookingPaymentMethod.STRIPE_SOFORT
                      ? ModalStyles.typographyBoldBlue
                      : ModalStyles.typographyBoldDarkBlue
                  }
                >
                  {t(`bookings:paymentMethod.STRIPE_SOFORT`)}
                </Typography>
                <CardMedia
                  image={OTHER_STRIPE}
                  sx={
                    paymentMethod.current === EBookingPaymentMethod.STRIPE_SOFORT
                      ? ModalStyles.cardIconColor
                      : ModalStyles.cardIcon
                  }
                />
              </Box>
            }
            labelPlacement="end"
          />
        )}
      {vendor?.paymentMethods.find(
        (method) => method === EBookingPaymentMethod.STRIPE_DIRECT_DEBIT
      ) &&
        selectedCountry !== "Schweiz" && (
          <FormControlLabel
            value={EBookingPaymentMethod.STRIPE_DIRECT_DEBIT}
            control={
              <Radio
                style={{ color: "#c6ccd4" }}
                checkedIcon={
                  <CheckRoundedIcon sx={ModalStyles.iconBlueColor} />
                }
              />
            }
            label={
              <Box sx={ModalStyles.radioButtonLabel}>
                <Typography
                  sx={
                    paymentMethod.current ===
                      EBookingPaymentMethod.STRIPE_DIRECT_DEBIT
                      ? ModalStyles.typographyBoldBlue
                      : ModalStyles.typographyBoldDarkBlue
                  }
                >
                  {t(`bookings:paymentMethod.STRIPE_DIRECT_DEBIT`)}
                </Typography>
                <CardMedia
                  image={SEPA}
                  sx={
                    paymentMethod.current ===
                      EBookingPaymentMethod.STRIPE_DIRECT_DEBIT
                      ? ModalStyles.cardIconColor
                      : ModalStyles.cardIcon
                  }
                />
              </Box>
            }
            labelPlacement="end"
          />
        )}
      {/*vendor?.paymentMethods.find(
        (method) => method === EBookingPaymentMethod.CUSTOM_SEPA
      ) && (
          <FormControlLabel
            value={EBookingPaymentMethod.CUSTOM_SEPA}
            control={
              <Radio
                style={{ color: "#c6ccd4" }}
                checkedIcon={<CheckRoundedIcon sx={ModalStyles.iconBlueColor} />}
              />
            }
            label={
              <Box sx={ModalStyles.radioButtonLabel}>
                <Typography
                  sx={
                    paymentMethod.current === EBookingPaymentMethod.CUSTOM_SEPA
                      ? ModalStyles.typographyBoldBlue
                      : ModalStyles.typographyBoldDarkBlue
                  }
                >
                  {t(`bookings:paymentMethod.CUSTOM_SEPA`)}
                </Typography>
                <CardMedia
                  image={SEPA}
                  sx={
                    paymentMethod.current === EBookingPaymentMethod.CUSTOM_SEPA
                      ? ModalStyles.cardIconColor
                      : ModalStyles.cardIcon
                  }
                />
              </Box>
            }
            labelPlacement="end"
          />
          )*/}
      {vendor?.paymentMethods.find(
        (method) => method === EBookingPaymentMethod.STRIPE_PAYPAL
      ) && (
          <FormControlLabel
            value={EBookingPaymentMethod.STRIPE_PAYPAL}
            control={
              <Radio
                style={{ color: "#c6ccd4" }}
                checkedIcon={<CheckRoundedIcon sx={ModalStyles.iconBlueColor} />}
              />
            }
            label={
              <Box sx={ModalStyles.radioButtonLabel}>
                <Typography
                  sx={
                    paymentMethod.current === EBookingPaymentMethod.STRIPE_PAYPAL
                      ? ModalStyles.typographyBoldBlue
                      : ModalStyles.typographyBoldDarkBlue
                  }
                >
                  {t(`bookings:paymentMethod.STRIPE_PAYPAL`)}
                </Typography>
                <CardMedia
                  image={PAYPAL}
                  sx={
                    paymentMethod.current === EBookingPaymentMethod.STRIPE_PAYPAL
                      ? ModalStyles.cardIconColor
                      : ModalStyles.cardIcon
                  }
                />
              </Box>
            }
            labelPlacement="end"
          />
        )}
      {vendor?.paymentMethods.find(
        (method) => method === EBookingPaymentMethod.STRIPE_CREDIT_CARD
      ) && (
          <FormControlLabel
            value={EBookingPaymentMethod.STRIPE_CREDIT_CARD}
            control={
              <Radio
                style={{ color: "#c6ccd4" }}
                checkedIcon={<CheckRoundedIcon sx={ModalStyles.iconBlueColor} />}
              />
            }
            label={
              <Box sx={ModalStyles.radioButtonLabel}>
                <Typography
                  sx={
                    paymentMethod.current ===
                      EBookingPaymentMethod.STRIPE_CREDIT_CARD
                      ? ModalStyles.typographyBoldBlue
                      : ModalStyles.typographyBoldDarkBlue
                  }
                >
                  {t(`bookings:paymentMethod.STRIPE_CREDIT_CARD`)}
                </Typography>
                <CardMedia
                  image={STRIPE}
                  sx={
                    paymentMethod.current ===
                      EBookingPaymentMethod.STRIPE_CREDIT_CARD
                      ? ModalStyles.cardIconColor
                      : ModalStyles.cardIcon
                  }
                />
              </Box>
            }
            labelPlacement="end"
          />
        )}
      {vendor?.paymentMethods.find(
        (method) => method === EBookingPaymentMethod.INVOICE
      ) && (
          <FormControlLabel
            value={EBookingPaymentMethod.INVOICE}
            control={
              <Radio
                style={{ color: "#c6ccd4" }}
                checkedIcon={<CheckRoundedIcon sx={ModalStyles.iconBlueColor} />}
              />
            }
            label={
              <Box sx={ModalStyles.radioButtonLabel}>
                <Typography
                  sx={
                    paymentMethod.current === EBookingPaymentMethod.INVOICE
                      ? ModalStyles.typographyBoldBlue
                      : ModalStyles.typographyBoldDarkBlue
                  }
                >
                  {t(`bookings:paymentMethod.INVOICE`)}
                </Typography>
                <CardMedia
                  image={INVOICE}
                  sx={
                    paymentMethod.current === EBookingPaymentMethod.INVOICE
                      ? ModalStyles.cardIconColor
                      : ModalStyles.cardIcon
                  }
                />
              </Box>
            }
            labelPlacement="end"
          />
        )}
    </RadioGroup>
  );
};

export default PaymentMethodsRadioButtons;
