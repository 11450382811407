import { Typography, Divider, Box, IconButton } from "@mui/material";
import React from "react";
import { useAppDispatch } from "../../store";
import { useTranslation } from "react-i18next";
import {
  AdditionalItemsStylesRoot,
  AdditionalItemsStyles,
} from "../../utils/hooks/styles/additionaltemsStyle";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { leftDrawerChangeRequest } from "../../store/reducers/mainAppReducer";
import AutocompleteSearchAdditionalItems from "./autocompleteSearchAdditionalItems";
import IconTabs from "./iconTabs";
import TotalPrice from "./totalPrice";

const AdditionalItems = () => {
  const { t } = useTranslation(["additionalItems"]);
  const dispatch = useAppDispatch();

  return (
    <Box>
      <Box sx={{ ...AdditionalItemsStylesRoot }}>
        <Box sx={{ ...AdditionalItemsStyles.titleAdditionalItems }}>
          <IconButton
            sx={{ ...AdditionalItemsStyles.iconButton }}
            onClick={() => {
              dispatch(leftDrawerChangeRequest({ toggle: false }));
            }}
          >
            <KeyboardBackspaceIcon />
          </IconButton>
          <Typography
            sx={{
              ...AdditionalItemsStyles.typographyBoldBlue,
              ...AdditionalItemsStyles.typographyBoldBlueCenter,
            }}
          >
            {t("additionalItems")}
          </Typography>
        </Box>
        <Box sx={{ ...AdditionalItemsStyles.selectInfo }}>
          <Typography sx={{ ...AdditionalItemsStyles.textAlignCenter }}>
            {t("selectInfo")}
          </Typography>
        </Box>
        <AutocompleteSearchAdditionalItems />
        <Divider />
        <IconTabs />
      </Box>
      <TotalPrice />
    </Box>
  );
};

export default AdditionalItems;
