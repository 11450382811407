import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ContentStyles } from "../../../utils/hooks/styles/contentStyles";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useAppDispatch } from "../../../store";
import {
  redirectRequest,
  toggleMapRequest,
} from "../../../store/reducers/mainAppReducer";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { getBookingDetailsSelector } from "../../../store/selectors/bookingSelectors";
import ReactMarkdown from "react-markdown";
import { setMapStateRequest } from "../../../store/reducers/mapReducer";
import { EMapState } from "../../../utils/models/EMapState";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { getBookingDetailsRequest } from "../../../store/reducers/bookingReducer";
import { dateToProperDateCartFormat } from "../../../utils/convertions/dataConversion";
import CloseButton from "../../static/closeButton";

const BookingResult = () => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();
  const { params } = useParams<{ params: string }>();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const bookingDetails = useSelector(getBookingDetailsSelector);
  useEffect(() => {
    if (!bookingDetails) {
      dispatch(
        getBookingDetailsRequest({
          bookingRef: `${values.sbl_bookingRef}`,
          email: `${values.sbl_email}`,
          showDetails: false,
        })
      );
    }
    // eslint-disable-next-line
  }, [bookingDetails]);
  return params === "success" ? (
    <Box sx={ContentStyles.root}>
      <Box sx={ContentStyles.content}>
        <Box sx={ContentStyles.helperText}>
          <Box sx={ContentStyles.background}>
            <CloseButton />
            <Box sx={ContentStyles.markDown}>
              <Typography variant="h2">
                {params === "success" ? t("congratulations") : t("sorry")}
              </Typography>
              {bookingDetails ? (
                <ReactMarkdown>
                  {params === "success"
                    ? `${t("congratulationsInfo", {
                        startdate: dateToProperDateCartFormat(
                          `${bookingDetails?.beachChairBookings[0].start}`
                        ),
                        section:
                          bookingDetails?.beachChairBookings[0].beachChair
                            .section.value,
                        city: bookingDetails?.beachChairBookings[0].beachChair
                          .location.address.city,
                      })}
                  \n\n
                  ${t("bookingNumber")}: ${values.sbl_bookingRef}`
                    : t("sorryInfo")}
                </ReactMarkdown>
              ) : (
                <Box sx={{ padding: "5em 0" }}>
                  <CircularProgress />
                </Box>
              )}
              {params === "success" && (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ ...ContentStyles.button, ...{ marginRight: 2 } }}
                  onClick={() => {
                    dispatch(
                      redirectRequest({
                        path: `/bookings?bookingRef=${values.sbl_bookingRef}&email=${values.sbl_email}`,
                      })
                    );
                    dispatch(toggleMapRequest({ toggle: true }));
                    dispatch(
                      setMapStateRequest({ mapState: EMapState.ALL_CITIES })
                    );
                  }}
                >
                  {t("showBooking")}
                </Button>
              )}
              <Button
                variant="contained"
                color="secondary"
                sx={ContentStyles.secondaryButton}
                onClick={() => {
                  dispatch(redirectRequest({ path: "/" }));
                  dispatch(toggleMapRequest({ toggle: true }));
                  dispatch(
                    setMapStateRequest({
                      mapState: EMapState.ALL_CITIES,
                    })
                  );
                }}
              >
                {t("backToStart")}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  ) : (
    <Box></Box>
  );
};

export default BookingResult;
