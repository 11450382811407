import { IGeo, IGeoPoint, IGeoPolygon } from "./../models/IGeoPoint";
export const geoJsonGuard = (geoJson: object): boolean => {
  if (!geoJson.hasOwnProperty("type") && !geoJson.hasOwnProperty("coordinates"))
    return false;
  const geo = geoJson as IGeo;
  if (!(geo.type.includes("Point") || geo.type.includes("Polygon")))
    return false;
  if (geo.type.includes("Point")) {
    const geoPoint = geoJson as IGeoPoint;
    if (geoPoint.coordinates.length !== 2) return false;
    geoPoint.coordinates.forEach((coord) => {
      if (isNaN(coord)) return false;
    });
  }
  if (geo.type.includes("Polygon")) {
    const geoPoint = geoJson as IGeoPolygon;
    geoPoint.coordinates[0].forEach((coord) => {
      if (coord.length !== 2) return false;
      coord.forEach((coord) => {
        if (isNaN(coord)) return false;
      });
    });
  }
  return true;
};
