import React from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { useAppDispatch } from "../../../store";
import {
  redirectRequest,
  toggleMapRequest,
} from "../../../store/reducers/mainAppReducer";
import { useSelector } from "react-redux";
import { getBookingDetailsSelector } from "../../../store/selectors/bookingSelectors";
import { GERMANY_TIME_ZONE } from "../../../utils/convertions/constants";
import { ContentStyles } from "../../../utils/hooks/styles/contentStyles";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import currency from "currency.js";
import { EURO } from "../../../utils/convertions/euroFormater";
import { setMapStateRequest } from "../../../store/reducers/mapReducer";
import { EMapState } from "../../../utils/models/EMapState";
import { getBookingDetailsRequest } from "../../../store/reducers/bookingReducer";
import { useLocation } from "react-router";
import queryString from "query-string";
import CloseButton from "../../static/closeButton";

const BookingBankTransferResult = () => {
  const { t } = useTranslation(["bookings"]);
  const dispatch = useAppDispatch();
  const bookingDetails = useSelector(getBookingDetailsSelector);
  const { search } = useLocation();
  const values = queryString.parse(search);

  React.useEffect(() => {
    dispatch(toggleMapRequest({ toggle: false }));
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (!bookingDetails) {
      dispatch(
        getBookingDetailsRequest({
          bookingRef: `${values.bookingReference}`,
          email: `${values.email}`,
          showDetails: false,
        })
      );
    }
    // eslint-disable-next-line
  }, [bookingDetails]);

  return (
    <Box sx={ContentStyles.root}>
      <Box sx={ContentStyles.content}>
        <Box sx={ContentStyles.helperText}>
          <Box sx={ContentStyles.background}>
            <CloseButton />
            {bookingDetails ? (
              <Box sx={ContentStyles.markDown}>
                <Card>
                  <CardContent>
                    <Typography
                      variant="h4"
                      color="text.secondary"
                      component="div"
                      align={"center"}
                    >
                      {t("bookingConfirmation")}
                    </Typography>
                    <Typography component="div">
                      <br />
                      {t("bookingResult.emailNotification")}
                    </Typography>
                    <Typography>
                      {t("bookingResult.reservationNumber")}:
                    </Typography>
                    <Typography variant="h6" component="div" align="center">
                      {bookingDetails?.bookingRef}
                    </Typography>
                    <Grid
                      paddingLeft={2}
                      paddingTop={2}
                      container
                      direction="row"
                      justifyContent="center"
                      // justifyContent="flex-end"
                      alignItems="center"
                      spacing={2}
                      columns={12}
                    >
                      {bookingDetails?.beachChairBookings.map(
                        (beachChairBookings) => (
                          <Grid
                            key={`${beachChairBookings.id}-${beachChairBookings.beachChair.id}`}
                            container
                            direction="row"
                            justifyContent="center"
                            // justifyContent="flex-end"
                            alignItems="center"
                            spacing={2}
                            columns={12}
                          >
                            <Grid item xs={12} md={6}>
                              <Table>
                                <TableBody>
                                  <TableRow>
                                    <TableCell>{t("section")}:</TableCell>
                                    <TableCell>
                                      {
                                        beachChairBookings?.beachChair?.section
                                          ?.value
                                      }
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>{t("row")}:</TableCell>
                                    <TableCell>
                                      {
                                        beachChairBookings?.beachChair?.row
                                          ?.value
                                      }
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>{t("model")}:</TableCell>
                                    <TableCell>
                                      {t(
                                        "common:models." +
                                          beachChairBookings.beachChair?.model
                                      )}
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Table>
                                <TableBody>
                                  <TableRow>
                                    <TableCell>{t("pickup")}:</TableCell>
                                    <TableCell>
                                      {beachChairBookings.start
                                        ? format(
                                            utcToZonedTime(
                                              new Date(
                                                beachChairBookings.start
                                              ),
                                              GERMANY_TIME_ZONE
                                            ),
                                            "dd.MM.yyyy"
                                          )
                                        : ""}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>{t("dropoff")}:</TableCell>
                                    <TableCell>
                                      {beachChairBookings.end
                                        ? format(
                                            utcToZonedTime(
                                              new Date(beachChairBookings.end),
                                              GERMANY_TIME_ZONE
                                            ),
                                            "dd.MM.yyyy"
                                          )
                                        : ""}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>
                                      {t("beachChairNumber")}:
                                    </TableCell>
                                    <TableCell>
                                      {
                                        beachChairBookings.beachChair
                                          ?.publicNumber
                                      }
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </Grid>
                          </Grid>
                        )
                      )}
                      <Grid item xs={12} md={6}></Grid>
                      <Grid item xs={12} md={6}>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell>{t("priceTerms.vat19")}:</TableCell>
                              <TableCell align="right">
                                {bookingDetails?.totalPrice &&
                                  EURO(bookingDetails?.totalPrice)
                                    .subtract(
                                      currency(
                                        bookingDetails?.totalPrice
                                      ).divide(1.19)
                                    )
                                    .format()}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>{t("priceTerms.price")}:</TableCell>
                              <TableCell align="right">
                                {bookingDetails?.totalPrice &&
                                  EURO(bookingDetails?.totalPrice).format()}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Grid>
                    </Grid>
                    <br />
                    {bookingDetails?.metaData?.bankData ? (
                      <Typography>
                        {t("bookingResult.bankNotification")}: <br />
                        <br />
                        <strong>{t("accountholder")}: </strong>
                        {bookingDetails?.metaData?.bankData?.accountHolder ||
                          "Strand & Mehr GmbH"}
                        <br />
                        <strong>BIC: </strong>
                        {bookingDetails?.metaData?.bankData?.bic} -{" "}
                        <strong>IBAN:</strong>{" "}
                        {bookingDetails?.metaData?.bankData?.iban}
                        <br />
                        <strong>{t("subject")}: </strong>
                        {bookingDetails?.metaData?.bankData?.reference}
                      </Typography>
                    ) : null}
                  </CardContent>
                </Card>

                <br />
                <br />
                <Button
                  variant="contained"
                  color="primary"
                  sx={ContentStyles.button}
                  onClick={() => {
                    dispatch(redirectRequest({ path: "/" }));
                    dispatch(toggleMapRequest({ toggle: true }));
                    dispatch(
                      setMapStateRequest({
                        mapState: EMapState.ALL_CITIES,
                      })
                    );
                  }}
                >
                  {t("backToStart")}
                </Button>
              </Box>
            ) : (
              <CircularProgress />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BookingBankTransferResult;
