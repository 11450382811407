import { createSelector } from "reselect";
import { AppState } from "../reducers/rootReducer";

const getPrice = (state: AppState) => state.price;
const getRatePrices = (state: AppState) => state.price.ratePrices;
const getError = (state: AppState) => state.price.error;
const getPending = (state: AppState) => state.price.pending;

export const getRatePricesSelector = createSelector(
  getRatePrices,
  (ratePrices) => ratePrices
);

export const selectedDatesPriceSelector = createSelector(
  getPrice,
  (price) => price.selectedDatesPrice?.length === 0 ? undefined : price.selectedDatesPrice
);

export const getErrorSelector = createSelector(getError, (error) => error);

export const getPendingSelector = createSelector(
  getPending,
  (pending) => pending
);
