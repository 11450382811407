export enum EBookingPaymentMethod {
    BANK_TRANSFER = 'BANK_TRANSFER',
    CASH = 'CASH',
    EC = 'EC',
    CC = 'CC',
    INVOICE = 'INVOICE',
    PAYPAL = 'PAYPAL',
    DIRECT_DEBIT = 'DIRECT_DEBIT',
    OTHER_STRIPE = 'OTHER_STRIPE',
    CUSTOM_SEPA = 'CUSTOM_SEPA',
    STRIPE_BANK_TRANSFER = 'STRIPE_BANK_TRANSFER',
    STRIPE_DIRECT_DEBIT = 'STRIPE_DIRECT_DEBIT',
    STRIPE_SOFORT = 'STRIPE_SOFORT',
    STRIPE_CREDIT_CARD = 'STRIPE_CREDIT_CARD',
    STRIPE_PAYPAL = 'STRIPE_PAYPAL'
}