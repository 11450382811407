import { createSelector } from "reselect";
import { getAllCities, getCityById } from "../reducers/cityReducer";
import { AppState } from "../reducers/rootReducer";

const getAppState = (state: AppState) => state;
const getSelectedCity = (state: AppState) => state.city.city;
const getError = (state: AppState) => state.city.error;
const getPending = (state: AppState) => state.city.pending;

export const getCitiesSelector = createSelector(
  getAllCities,
  (cities) => cities
);

export const getCityByIdSelector = (id: number) =>
  createSelector(getAppState, (state) => getCityById(state, id)!);

export const getSelectedCitySelector = createSelector(
  getSelectedCity,
  (city) => city
);
export const getErrorSelector = createSelector(getError, (error) => error);
export const getPendingSelector = createSelector(
  getPending,
  (pending) => pending
);
