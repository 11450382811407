import { Box, CardMedia, Typography } from "@mui/material";

import { stylesConfig } from "../../../utils/styles/styles";
import lock from "./../../../assets/lock.png";
import { EColor } from "../../../utils/models/EColor";
import EventBusyIcon from "@mui/icons-material/EventBusy";

export const AvailabilitiesBeachChairStyle = {
  root: {
    height: 70,
    width: 70,
    marginLeft: 2,
  } as const,
  gridRowSecond: {
    fontSize: 12,
    fontWeight: "bold",
    gridRow: 2,
  } as const,
  selected: {
    boxShadow: `0 0 30px ${stylesConfig.orangeColor}`,
  } as const,
  inBookingSelected: {
    backgroundColor: `${stylesConfig.orangeColor}`,
    color: `${stylesConfig.whiteColor}`,
    border: `1px solid ${stylesConfig.orangeColor} `,
  } as const,
  boxBackgroundFree: {
    height: "100%",
    width: "100%",
    display: "grid",
    gridTemplateRows: "1px auto auto",
    borderRadius: "10%",
    border: "1px solid",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      boxShadow: `0 0 11px ${stylesConfig.orangeColor}`,
      cursor: "pointer",
    },
    backgroundColor: stylesConfig.whiteColor,
  } as const,
  boxBackgroundNoon: {
    height: "100%",
    width: "100%",
    display: "grid",
    gridTemplateRows: "1px auto auto",
    borderRadius: "10%",
    border: "1px solid",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      boxShadow: `0 0 11px ${stylesConfig.orangeColor}`,
      cursor: "pointer",
    },
    background: `linear-gradient(180deg, ${stylesConfig.whiteColor} 50%, ${stylesConfig.grayColor} 50%)`,
  } as const,
  boxBackgroundAfterNoon: {
    height: "100%",
    width: "100%",
    display: "grid",
    gridTemplateRows: "1px auto auto",
    borderRadius: "10%",
    border: "1px solid",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      boxShadow: `0 0 11px ${stylesConfig.orangeColor}`,
      cursor: "pointer",
    },
    background: `linear-gradient(180deg, ${stylesConfig.grayColor} 50%, ${stylesConfig.whiteColor} 50%)`,
  } as const,
  boxDisabled: {
    height: "100%",
    width: "100%",
    display: "grid",
    gridTemplateRows: "1px auto auto",
    borderRadius: "10%",
    border: "1px solid",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
    cursor: "pointer",
    color: "white",
    "&:hover": {
      boxShadow: `0 0 11px ${stylesConfig.blueColor}`,
      cursor: "pointer",
    },
    backgroundColor: stylesConfig.grayColor,
  } as const,
  iconsGrid: {
    width: "72px",
    display: "grid",
    gridTemplateColumns: "50% 50%",
  },
  lock: {
    height: 37,
    width: 25,
    transform: "translate(-20%)",
  } as const,
  seasonBeachChair: {
    transform: "translate(20%)",
    height: 37,
    width: 37,
    color: stylesConfig.blueColor,
    clipPath: "polygon(11% 10%, 93% 10%, 89% 93%, 11% 91%)",
    background: stylesConfig.whiteColor,
  } as const,
};

function getProperty<T, K extends keyof T>(o: T, propertyName: K): T[K] {
  return o[propertyName];
}

export interface IAttributes {
  color?: string;
}

export interface IAvailabilitiesBeachChairProps {
  id: string;
  timeEnum: EAvailabilityTime;
  hasLock: boolean;
  isSeasonBeachChair: boolean;
  selected: boolean;
  inBookingSelected: boolean;
  attributes: IAttributes;
  showColor: boolean;
}

export enum EAvailabilityTime {
  Free = "boxBackgroundFree",
  Noon = "boxBackgroundNoon",
  Afternoon = "boxBackgroundAfterNoon",
  Allday = "boxBackgroundAllDay",
}

const AvailabilitiesBeachChair = ({
  id,
  timeEnum,
  hasLock,
  isSeasonBeachChair,
  selected,
  inBookingSelected,
  attributes,
  showColor,
}: IAvailabilitiesBeachChairProps) => {
  return (
    <Box sx={AvailabilitiesBeachChairStyle.root}>
      <Box
        sx={{
          ...(timeEnum !== EAvailabilityTime.Allday
            ? getProperty(AvailabilitiesBeachChairStyle, timeEnum)
            : AvailabilitiesBeachChairStyle.boxDisabled),
          ...(selected && AvailabilitiesBeachChairStyle.selected),
          ...(inBookingSelected &&
            AvailabilitiesBeachChairStyle.inBookingSelected),
          ...(showColor &&
            attributes?.color && {
              borderTop: `5px dashed ${
                EColor[attributes.color as keyof typeof EColor]
              } !important`,
              height: "66px !important",
            }),
        }}
        data-testid="box"
      >
        <Box sx={{ ...AvailabilitiesBeachChairStyle.iconsGrid }}>
          {hasLock ? (
            <CardMedia
              sx={AvailabilitiesBeachChairStyle.lock}
              data-testid="hasLock"
              component="img"
              src={lock}
            />
          ) : (
            <Box sx={AvailabilitiesBeachChairStyle.lock} />
          )}
          {isSeasonBeachChair && (
            <EventBusyIcon
              sx={AvailabilitiesBeachChairStyle.seasonBeachChair}
              data-testid="isSeasonBeachChair"
            />
          )}
        </Box>
        <Typography
          sx={AvailabilitiesBeachChairStyle.gridRowSecond}
          data-testid="id"
        >
          {id}
        </Typography>
      </Box>
    </Box>
  );
};

export default AvailabilitiesBeachChair;
