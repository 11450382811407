import {
  useState,
  useRef,
  useEffect,
  useCallback,
  SyntheticEvent,
  MouseEvent,
  KeyboardEvent,
  memo,
  Fragment,
} from "react";

import {
  AppBar,
  Badge,
  Box,
  CardMedia,
  ClickAwayListener,
  Grow,
  MenuList,
  Modal,
  Popper,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Switch, Route, Link, useLocation } from "react-router-dom";
import Maps from "./components/map/map";
import Search from "./components/search/search";
import LocationAvailabilitiesBeachChairs from "./components/locationAvailabilitiesBeachChairs/locationAvailabilitiesBeachChairs";
import BeachChair from "./components/beachChair/beachChair";
import logo from "./assets/StrandButlerLogoBanner.png";
import Booking from "./components/booking/booking";
import { useWidget } from "./contexts";
import { useAppDispatch } from "./store";
import {
  selectionModalRequest,
  redirectRequest,
  rightDrawerChangeRequest,
  toggleMobileViewExpandMenuRequest,
  toggleMapRequest,
} from "./store/reducers/mainAppReducer";
import { useSelector } from "react-redux";
import {
  getMobileViewExpandMenuSelector,
  getRightBookingDetailsDrawerSelector,
  getSelectionModalSelector,
  getShowMapSelector,
  getSwitchStateSelector,
  leftDrawerSelector,
  rightDrawerSelector,
} from "./store/selectors/mainAppSelectors";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BookingResult from "./components/booking/bookingResult/bookingResult";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import Page404 from "./components/static/page404";
import Page500 from "./components/static/page500";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import ImprintContent from "./components/static/imprint";
import DataPrivacyContent from "./components/static/dataprivacy";
import TermsContent from "./components/static/terms";
import ContactContent from "./components/static/contact";
import MenuIcon from "@mui/icons-material/Menu";
import About from "./components/static/about";
import Vendor from "./components/static/vendor";
import {
  setMapLocationDefaultRequest,
  setMapStateRequest,
} from "./store/reducers/mapReducer";
import { EMapState } from "./utils/models/EMapState";
import HelpIcon from "@mui/icons-material/Help";
import BusinessIcon from "@mui/icons-material/Business";
import MapIcon from "@mui/icons-material/Map";
import StartContent from "./components/static/start";
import SBDrawer from "./components/drawer/sbDrawer";
import { getBookingSelector } from "./store/selectors/bookingSelectors";
import Section from "./components/section/section";
import SelectionModal from "./components/section/sectionModal/selectionModal";
import BookingBankTransferResult from "./components/booking/bookingResult/bookingBankTransferResult";
import { AppStyles, useAppStyles } from "./utils/hooks/styles/appStyle";
import { getRegionRequest } from "./store/reducers/regionReducer";
import { getCityRequest } from "./store/reducers/cityReducer";
import { getBeachRequest } from "./store/reducers/beachReducer";
import { getSectionRequest } from "./store/reducers/sectionReducer";
import SearchDateRange from "./components/search/searchDateRange/searchDateRange";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import SearchAutocomplete from "./components/search/searchAutocomplete/searchAutocomplete";
import SearchSwitch from "./components/search/searchSwitch/searchSwitch";
import Displayer from "./components/displayer/displayer";
import AdditionalItems from "./components/additionalItems/additionalItems";
import { badgeCount } from "./utils/functions/badgeCount";
import Bookings from "./components/booking/bookings/bookings";
import BookingDetails from "./components/booking/bookings/bookingDetails";
import queryString from "query-string";
import { putBookingAbortPaymentRequest } from "./store/reducers/bookingReducer";
import { useWidgetZustand } from "./contexts/zustand/widgetZustand";
import { getVendorSelector } from "./store/selectors/vendorSelectors";

function App() {
  const { t } = useTranslation();
  const classes = useAppStyles();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const selectionModal = useSelector(getSelectionModalSelector);
  const mobileViewExpandMenu = useSelector(getMobileViewExpandMenuSelector);
  const booking = useSelector(getBookingSelector);
  const rightDrawer = useSelector(rightDrawerSelector);
  const rightBookingDetailsDrawer = useSelector(
    getRightBookingDetailsDrawerSelector
  );
  const { widgetUrlZustand } = useWidgetZustand();
  const leftDrawer = useSelector(leftDrawerSelector);
  const showMap = useSelector(getShowMapSelector);
  const theme = useTheme();
  const matchesLg = useMediaQuery(theme.breakpoints.down("lg"));
  const matchesMd = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSm = useMediaQuery(theme.breakpoints.between("xs", "md"));
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLSpanElement>(null);
  const onlineBooking = useSelector(getSwitchStateSelector);
  const ref = useRef();
  const scrollRef = useRef<HTMLInputElement>(null);
  const { isWidgetActive } = useWidget();
  const configuration = useSelector(getVendorSelector)?.configuration;
  useEffect(() => {
    if (!onlineBooking) {
      dispatch(getRegionRequest({ searchPhrase: "" }));
      dispatch(getCityRequest({ searchPhrase: "" }));
      dispatch(getBeachRequest({ searchPhrase: "" }));
    }
    dispatch(getSectionRequest({ searchPhrase: "", onlineBooking }));
    // eslint-disable-next-line
  }, [onlineBooking]);

  useEffect(() => {
    if (
      location.pathname.includes("/result/error") &&
      location.search.includes("sbl_bookingRef") &&
      location.search.includes("sbl_email")
    ) {
      const query = queryString.parse(location.search);
      dispatch(
        putBookingAbortPaymentRequest({
          bookingRef: (query as any).sbl_bookingRef,
          email: (query as any).sbl_email,
        })
      );
    }
  }, [location, dispatch]);

  const handleScroll = useCallback(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const handleModalClose = () => {
    dispatch(selectionModalRequest({ toggle: false }));
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (
    event: Event | SyntheticEvent | MouseEvent<Document, MouseEvent>
  ) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event: KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }
  const hideMainShowMap = configuration?.beachChairView === "map" && matchesMd && !showMap;

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current!.focus();
    }
    prevOpen.current = open;
  }, [open]);
  const datePickers = <SearchDateRange />;

  return (
    <>
      <ToastContainer
        className={classes.toaster}
        style={{ zIndex: 999999 }}
        autoClose={5000}
        position="top-right"
      />
      <Modal
        open={selectionModal}
        onClose={handleModalClose}
        style={{ zIndex: 999999999 }}
      >
        <SelectionModal ref={ref} />
      </Modal>
      <SBDrawer state={rightDrawer} anchor="right">
        <Booking />
      </SBDrawer>
      <SBDrawer state={rightBookingDetailsDrawer} anchor="right">
        <BookingDetails />
      </SBDrawer>
      <SBDrawer state={leftDrawer} anchor="left">
        <AdditionalItems />
      </SBDrawer>

      <AppBar position="absolute" sx={AppStyles.appBar}>
        <Toolbar
          sx={{
            ...AppStyles.toolbar,
            ...(isWidgetActive ? AppStyles.widgetMode.toolbar : {}),
          }}
        >
          <CardMedia
            onClick={() => {
              dispatch(toggleMapRequest({ toggle: true }));
              if (widgetUrlZustand !== "") {
                dispatch(redirectRequest({ path: widgetUrlZustand }));
                dispatch(setMapStateRequest({ mapState: EMapState.REGION }));
              } else {
                dispatch(redirectRequest({ path: "/" }));
                dispatch(setMapLocationDefaultRequest({}));
                dispatch(
                  setMapStateRequest({ mapState: EMapState.ALL_CITIES })
                );
              }
            }}
            component="img"
            sx={AppStyles.logoIcon}
            image={logo}
            title="Strandbutler"
          />

          {!matchesMd && datePickers}

          {matchesLg ? (
            <Box sx={AppStyles.navigationRight}>
              {matchesSm && (
                <Box
                  sx={AppStyles.marginTop}
                  onClick={() => {
                    dispatch(toggleMapRequest({ toggle: true }));
                  }}
                  ref={anchorRef}
                >
                  <MapIcon sx={AppStyles.navIcon} />
                </Box>
              )}

              {/* Mobile navigation component */}
              {!isWidgetActive && (
                <Box>
                  <Box
                    sx={AppStyles.marginTop}
                    onClick={handleToggle}
                    ref={anchorRef}
                  >
                    <MenuIcon sx={AppStyles.navIcon} />
                  </Box>
                  <Popper
                    style={{ zIndex: 10000 }}
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    placement="bottom-start"
                    transition
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === "bottom-start"
                              ? "left top"
                              : "left bottom",
                        }}
                      >
                        <Box sx={AppStyles.background}>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                              autoFocusItem={open}
                              id="composition-menu"
                              aria-labelledby="composition-button"
                              onKeyDown={handleListKeyDown}
                            >
                              <Link
                                className={[
                                  classes.navHamburgerItem,
                                  classes.noTextDecoration,
                                ].join(" ")}
                                to="/booking"
                                onClick={() => {
                                  dispatch(toggleMapRequest({ toggle: true }));
                                  dispatch(
                                    setMapStateRequest({
                                      mapState: EMapState.ALL_CITIES,
                                    })
                                  );
                                }}
                              >
                                <div
                                  className={
                                    location.pathname.includes("/booking")
                                      ? classes.select
                                      : ""
                                  }
                                ></div>
                                <span
                                  className={[
                                    classes.hamburgerMenuItem,
                                    location.pathname.includes("/booking")
                                      ? classes.selected
                                      : "",
                                  ].join(" ")}
                                >
                                  {t("common:bookings")}
                                  <EventAvailableIcon />
                                </span>
                              </Link>

                              <Link
                                className={[
                                  classes.navHamburgerItem,
                                  classes.noTextDecoration,
                                ].join(" ")}
                                to="/content/about"
                                onClick={() => {
                                  dispatch(toggleMapRequest({ toggle: false }));
                                }}
                              >
                                <div
                                  className={
                                    location.pathname.includes("/content/about")
                                      ? classes.select
                                      : ""
                                  }
                                ></div>
                                <span
                                  className={[
                                    classes.hamburgerMenuItem,
                                    location.pathname.includes("/content/about")
                                      ? classes.selected
                                      : "",
                                  ].join(" ")}
                                >
                                  {t("common:howitworks")}
                                  <HelpIcon />
                                </span>
                              </Link>

                              <Link
                                className={[
                                  classes.navHamburgerItem,
                                  classes.noTextDecoration,
                                ].join(" ")}
                                to="/content/vendor"
                                onClick={() => {
                                  dispatch(toggleMapRequest({ toggle: false }));
                                }}
                              >
                                <div
                                  className={
                                    location.pathname.includes(
                                      "/content/vendor"
                                    )
                                      ? classes.select
                                      : ""
                                  }
                                ></div>
                                <span
                                  className={[
                                    classes.hamburgerMenuItem,
                                    location.pathname.includes(
                                      "/content/vendor"
                                    )
                                      ? classes.selected
                                      : "",
                                  ].join(" ")}
                                >
                                  {t("common:vendor")}
                                  <BusinessIcon />
                                </span>
                              </Link>
                            </MenuList>
                          </ClickAwayListener>
                        </Box>
                      </Grow>
                    )}
                  </Popper>
                </Box>
              )}

              <Box
                onClick={() => {
                  if (badgeCount(booking) > 0) {
                    dispatch(rightDrawerChangeRequest({ toggle: true }));
                  }
                }}
              >
                <Badge
                  badgeContent={badgeCount(booking)}
                  color="primary"
                  sx={AppStyles.badge}
                  classes={{ badge: classes.customBadge }}
                >
                  <ShoppingBasketIcon sx={AppStyles.navIcon} />
                </Badge>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                ...AppStyles.navigationRight,
                ...(isWidgetActive ? AppStyles.widgetMode.navigationRight : {}),
              }}
            >
              {/* Desktop navigation */}
              {!isWidgetActive && (
                <Fragment>
                  <Link
                    className={[classes.navItem, classes.noTextDecoration].join(
                      " "
                    )}
                    to="/booking"
                    onClick={() => {
                      dispatch(toggleMapRequest({ toggle: true }));
                      dispatch(
                        setMapStateRequest({ mapState: EMapState.ALL_CITIES })
                      );
                    }}
                  >
                    <div
                      className={
                        location.pathname.includes("/booking")
                          ? classes.select
                          : ""
                      }
                    ></div>
                    <Box sx={AppStyles.marginTop}>
                      {matchesLg ? (
                        <EventAvailableIcon sx={AppStyles.navIcon} />
                      ) : (
                        t("common:bookings")
                      )}
                    </Box>
                  </Link>
                  <Link
                    className={[classes.navItem, classes.noTextDecoration].join(
                      " "
                    )}
                    to="/content/about"
                    onClick={() => {
                      dispatch(toggleMapRequest({ toggle: false }));
                    }}
                  >
                    <div
                      className={
                        location.pathname.includes("/content/about")
                          ? classes.select
                          : ""
                      }
                    ></div>
                    <Box sx={AppStyles.marginTop}>
                      {matchesLg ? (
                        <HelpIcon sx={AppStyles.navIcon} />
                      ) : (
                        t("common:howitworks")
                      )}
                    </Box>
                  </Link>
                  <Link
                    className={[classes.navItem, classes.noTextDecoration].join(
                      " "
                    )}
                    to="/content/vendor"
                    onClick={() => {
                      dispatch(toggleMapRequest({ toggle: false }));
                    }}
                  >
                    <div
                      className={
                        location.pathname.includes("/content/vendor")
                          ? classes.select
                          : ""
                      }
                    ></div>
                    <Box sx={AppStyles.marginTop}>
                      {matchesLg ? (
                        <BusinessIcon sx={AppStyles.navIcon} />
                      ) : (
                        t("common:vendor")
                      )}
                    </Box>
                  </Link>
                </Fragment>
              )}
              <Box
                onClick={() => {
                  if (badgeCount(booking) > 0) {
                    dispatch(rightDrawerChangeRequest({ toggle: true }));
                  }
                }}
              >
                <Badge
                  badgeContent={badgeCount(booking)}
                  color="primary"
                  sx={AppStyles.badge}
                  classes={{ badge: classes.customBadge }}
                >
                  <ShoppingBasketIcon sx={AppStyles.navIcon} />
                </Badge>
              </Box>
            </Box>
          )}
          {matchesMd && (
            <Box sx={AppStyles.datePickersCenter}>{datePickers}</Box>
          )}
          {matchesMd && mobileViewExpandMenu && (
            <Box sx={AppStyles.datePickersCenter}>
              <SearchAutocomplete />
              {!isWidgetActive && <SearchSwitch />}
            </Box>
          )}
          {matchesMd && (
            <Box sx={AppStyles.datePickersCenter}>
              {
                <Box
                  onClick={() => {
                    dispatch(
                      toggleMobileViewExpandMenuRequest({
                        toggle: !mobileViewExpandMenu,
                      })
                    );
                  }}
                >
                  {mobileViewExpandMenu ? (
                    <ExpandLessIcon sx={AppStyles.navIcon} />
                  ) : (
                    <ExpandMoreIcon sx={AppStyles.navIcon} />
                  )}
                </Box>
              }
            </Box>
          )}
        </Toolbar>
      </AppBar>

      <Box
        sx={
          mobileViewExpandMenu
            ? {
              ...AppStyles.mobileViewExpanded,
              ...(isWidgetActive
                ? AppStyles.widgetMode.mobileViewExpanded
                : {}),
            }
            : isWidgetActive
              ? AppStyles.widgetMode.mobileView
              : AppStyles.mobileView
        }
      >
        {(!matchesMd || !showMap) && (
          <Box
            sx={{
              ...(matchesMd
                ? location.pathname.includes("/s/strandkorb/")
                  ? AppStyles.leftAbsoluteComponent
                  : AppStyles.leftComponent
                : AppStyles.leftComponent),
              ...(matchesMd &&
                mobileViewExpandMenu &&
                AppStyles.marginTopExpanded),
            }}
          >
            <Switch>
              <Route path="/s/strandkorb/:cityName/:sectionName/:sectionId/:beachChairId">
                <BeachChair />
              </Route>
              {!hideMainShowMap && <Route path="/s/:regionName/:cityName/:sectionId">
                <Section onClick={handleScroll} />
              </Route>}
              {!hideMainShowMap && <Route path="/s/:regionName/:cityName/:sectionId/:rowId">
                <Section onClick={handleScroll} />
              </Route>}
              {!matchesMd && <Search />}
            </Switch>
          </Box>
        )}
        {configuration?.beachChairView === "map" && matchesMd && (
          <Box
            sx={{
              ...(matchesMd
                ? location.pathname.includes("/s/strandkorb/")
                  ? AppStyles.leftAbsoluteComponent
                  : AppStyles.leftComponent
                : AppStyles.leftComponent),
              ...(matchesMd &&
                mobileViewExpandMenu &&
                AppStyles.marginTopExpanded),
            }}
          >
            <Switch>
              <Route path="/s/strandkorb/:cityName/:sectionName/:sectionId/:beachChairId">
                <BeachChair />
              </Route>
            </Switch>
          </Box>
        )}

        {(!matchesMd || !showMap) && (
          <Box ref={scrollRef} sx={AppStyles.mainComponent}>
            <Switch>
              <Route path="/s/">
                <LocationAvailabilitiesBeachChairs />
              </Route>
              <Route path="/result/:params">
                <BookingResult />
              </Route>
              <Route path="/booking/result_bank_transfer">
                <BookingBankTransferResult />
              </Route>
              <Route path="/bookings">
                <Bookings />
              </Route>
              <Route path="/404">
                <Page404 />
              </Route>
              <Route path="/500">
                <Page500 />
              </Route>
              <Route path="/content/imprint">
                <ImprintContent />
              </Route>
              <Route path="/content/dataprivacy">
                <DataPrivacyContent />
              </Route>
              <Route path="/content/terms">
                <TermsContent />
              </Route>
              <Route path="/content/contact">
                <ContactContent />
              </Route>
              <Route path="/content/about">
                <About />
              </Route>
              <Route path="/content/vendor">
                <Vendor />
              </Route>
              <Route exact path="/">
                <StartContent />
              </Route>
            </Switch>
          </Box>
        )}
        {(!matchesMd || showMap) && <Maps />}
      </Box>

      {matchesMd && <Displayer />}

      {!isWidgetActive && (
        <AppBar sx={AppStyles.bottomNavigation}>
          <Toolbar sx={AppStyles.bottomToolbar}>
            <Link
              onClick={() => {
                dispatch(toggleMapRequest({ toggle: false }));
              }}
              to="/content/imprint"
            >
              {t("common:bottomMenu.imprint")}
            </Link>
            <Link
              onClick={() => {
                dispatch(toggleMapRequest({ toggle: false }));
              }}
              to="/content/dataprivacy"
            >
              {t("common:bottomMenu.dataprivacy")}
            </Link>
            <Link
              onClick={() => {
                dispatch(toggleMapRequest({ toggle: false }));
              }}
              to="/content/terms"
            >
              {t("common:bottomMenu.terms")}
            </Link>
            <Link
              onClick={() => {
                dispatch(toggleMapRequest({ toggle: false }));
              }}
              to="/content/contact"
            >
              {t("common:bottomMenu.contact")}
            </Link>
          </Toolbar>
        </AppBar>
      )}
    </>
  );
}

export default memo(App);
