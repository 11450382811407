import React, { useEffect, useMemo, useState } from "react";
import { Box, Button, CardMedia, Modal, Typography } from "@mui/material";
import { DisplayerStyles, DisplayerStylesRoot } from "../../utils/hooks/styles/displayerStyles";
import { useTranslation } from "react-i18next";
import logo from "../../assets/SBLMarker.png";
import safari from "../../assets/safari.png";
import chrome from "../../assets/chrome.png";
import getBrowser from "../../utils/helpers/getBrowser";
import getOS from "../../utils/helpers/getOS";
import { DisplayerKeys, DisplayerServiceKeys } from "./displayerKeys";

const Displayer = () => {
  const { t } = useTranslation(["common"]);
  const displayer = localStorage.getItem(DisplayerServiceKeys.STORAGE);
  const urlParams = useMemo(() => new URLSearchParams(window.location.search.toLocaleLowerCase()), []);
  const qCode = urlParams.get(DisplayerServiceKeys.QUERY_CODE);
  const qrKeyParam = qCode === 'true';
  let [displayPopup, setDisplayPopup] = useState(!displayer && qrKeyParam)
  let browserIcon = getBrowser.isSafari ? safari : chrome;
  const protocol = window.location.protocol.startsWith('https') ? 'https' : 'http';

  const makeDecision = (decision: DisplayerKeys): void => {
    localStorage.setItem(DisplayerServiceKeys.STORAGE, decision);
    setDisplayPopup(false)
  }

  const androidData: { href: string, appLink: string } = useMemo(() => {
    return {
      href: `${window.location.href}`.replace(protocol, 'strandbutler'),
      appLink: 'market://details?id=app.strandbutler.de'
    }
  }, [protocol])

  useEffect(() => {
    let newUrl: string = `${window.location.href}`.replace(
      new RegExp(`(qrcode=${qCode})`, 'gi'), ''
    )

    if (newUrl.endsWith('?')) {
      newUrl = window.location.origin;
    }

    if (!newUrl.endsWith('/')) {
      newUrl += '/'
    }

    if (displayer === DisplayerKeys.BROWSER && qrKeyParam) {
      window.location.assign(newUrl)
    } else if (displayer === DisplayerKeys.MOBILE) {
      if (getOS.isIPhone) {
        const { pathname, search } = window.location;
        const path = (pathname !== '') ? pathname : 'launch';
        window.location.href = `strandbutler://${path}${search}`;
        setTimeout(() => {
          window.location.href = 'https://apps.apple.com/de/app/strandbutler/id1612132981'
        }, 500);
      }
    }
  }, [displayer, urlParams, qrKeyParam, qCode, protocol])

  const btnElement = (
    <Button
      sx={{ ...DisplayerStyles.buttonApp }}
      variant="contained"
      color="inherit"
      onClick={() => makeDecision(DisplayerKeys.MOBILE)}
    >{ t('strandbutlerApp') }</Button>
  );

  return displayPopup ? (
    <Modal
      open
      onClose={() => null}
      style={{ ...DisplayerStyles.modal }}
    >
      <Box
        sx={{ ...DisplayerStyles.mainBox }}
      >
        <Box sx={{ ...DisplayerStylesRoot }}>
          <Typography align="center" sx={DisplayerStyles.typographyBoldTitle}>{t("openIn")}:</Typography>
          <Box sx={{ ...DisplayerStyles.tableBox }}>
            <Box sx={{ ...DisplayerStyles.tableColumn }}>
              <>
                <CardMedia
                  component="img"
                  sx={{ ...DisplayerStyles.imageLogo }}
                  image={logo}
                  title="Strandbutler"
                />
                <CardMedia
                  component="img"
                  sx={{ ...DisplayerStyles.imageLogo }}
                  image={browserIcon}
                  title={ getBrowser.isSafari ? 'Safari' : 'Chrome' }
                />
              </>
            </Box>
            <Box sx={{ ...DisplayerStyles.tableColumn }}>
              <Box sx={{ marginTop: "20px" }}>
                {
                  getOS.isAndroid ? (
                    <a
                      href={androidData.href}
                      data-applink={androidData.appLink}
                      style={{
                        textDecoration: 'none'
                      }}
                    >{btnElement}</a>
                  ) : btnElement
                }
              </Box>
              <Box sx={{ marginTop: "35px" }}>
                <Button
                  sx={{ ...DisplayerStyles.buttonBrowser }}
                  variant="contained"
                  color="warning"
                  onClick={() => makeDecision(DisplayerKeys.BROWSER)}
                >{ t('mobileWebsite') }</Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  ) : null;
}

export default Displayer
