import React, { useEffect } from "react";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { DateRange, MobileDateRangePicker } from "@mui/lab";
import deLocale from "date-fns/locale/de";
import { useSelector } from "react-redux";
import {
  getDateFromSelector,
  getDateToSelector,
  getIschangeDateByUserSelector,
  getSeasonEndDateSelector,
} from "../../../store/selectors/mainAppSelectors";
import { isValidDate } from "../../../utils/checkers/isValidDate";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../store";
import {
  changeDateFromRequest,
  changeDateToRequest,
  isChangeDateByUserRequest,
} from "../../../store/reducers/mainAppReducer";
import { SearchStyle } from "../../../utils/hooks/styles/searchStyle";
import { Box, TextField, useMediaQuery, useTheme } from "@mui/material";
import { getSelectedSectionSelector } from "../../../store/selectors/sectionSelectors";
import { isAfter, isBefore, getYear, parse, addHours, addDays } from "date-fns";
import { useCreatedBookingIndicator } from "../../locationAvailabilitiesBeachChairs/availabilitiesBeachChair/hooks/useCreatedBookingIndicator";
import dayjs from "dayjs";
import { useLocation } from "react-router";
import queryString from "query-string";
import { useAffiliateReferenceStore } from "../../../utils/hooks/customHooks/useAffiliateReferenceStore";

const SearchDateRange = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.down("md"));
  const start = useSelector(getDateFromSelector);
  const end = useSelector(getDateToSelector);
  const seasonEnd = useSelector(getSeasonEndDateSelector);
  const section = useSelector(getSelectedSectionSelector);
  const [value, setValue] = React.useState<DateRange<Date>>([
    new Date(start),
    new Date(end),
  ]);
  const [open, setOpen] = React.useState<boolean>(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const isChangeDateByUser = useSelector(getIschangeDateByUserSelector);
  const location = useLocation();
  const { setAffiliateReference } = useAffiliateReferenceStore();

  useEffect(() => {
    const query = queryString.parse(location.search);
    if ((query as any)?.afp) {
      setAffiliateReference((query as any).afp);
    }
  }, [location, setAffiliateReference]);

  React.useEffect(() => {
    if (isChangeDateByUser === false) {
      if (section && section.location) {
        if (section.location.seasonStart && section.location.seasonEnd) {
          const today = new Date();
          const seasonStart = parse(
            `${section.location.seasonStart}-${getYear(today)}`,
            "dd-MM-yyyy",
            new Date()
          );
          const seasonEnd = parse(
            `${section.location.seasonEnd}-${getYear(today)}`,
            "dd-MM-yyyy",
            new Date()
          );
          if (isAfter(today, seasonEnd)) {
            const seasonStartNextYear = parse(
              `${section.location.seasonStart}-${getYear(today) + 1}`,
              "dd-MM-yyyy",
              new Date()
            );
            setValue([seasonStartNextYear, seasonStartNextYear]);
            dispatch(
              changeDateFromRequest({
                dateFrom: seasonStartNextYear.toISOString(),
              })
            );
            dispatch(
              changeDateToRequest({ dateTo: seasonStartNextYear.toISOString() })
            );
          }
          if (isBefore(today, seasonStart)) {
            setValue([seasonStart, seasonStart]);
            dispatch(
              changeDateFromRequest({
                dateFrom: seasonStart.toISOString(),
              })
            );
            dispatch(
              changeDateToRequest({ dateTo: seasonStart.toISOString() })
            );
          }
          if (isAfter(today, seasonStart) && isBefore(today, seasonEnd)) {
            const nextDay = addDays(today, 0);
            const todayAndLeadTime = addHours(
              nextDay,
              section.location.leadTimeHours || 0
            );
            dispatch(
              changeDateFromRequest({
                dateFrom: todayAndLeadTime.toISOString(),
              })
            );
            dispatch(
              changeDateToRequest({ dateTo: todayAndLeadTime.toISOString() })
            );
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [section, isChangeDateByUser]);

  React.useEffect(() => {
    setValue([new Date(start), new Date(end)]);
    // eslint-disable-next-line
  }, [start, end]);

  const { setCreatedBooking, editedChannelUuid } = useCreatedBookingIndicator();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
      <MobileDateRangePicker
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        InputProps={{ readOnly: true }}
        startText={t("common:pickup")}
        endText={t("common:dropoff")}
        toolbarTitle={t("common:timerange")}
        cancelText={t("common:cancel")}
        inputFormat="dd.MM.yyyy"
        mask="__.__.____"
        disablePast
        maxDate={new Date(seasonEnd)}
        value={value}
        onChange={(newValue) => {
          if (
            newValue &&
            newValue[0] &&
            newValue[1] &&
            isValidDate(newValue[0]) &&
            isValidDate(newValue[1])
          ) {
            dispatch(
              changeDateFromRequest({
                dateFrom: newValue[0].toISOString(),
              })
            );
            dispatch(
              changeDateToRequest({ dateTo: newValue[1].toISOString() })
            );
            setValue(newValue);
            dispatch(isChangeDateByUserRequest({ toggle: true }));
            if (editedChannelUuid) {
              setCreatedBooking(editedChannelUuid, {
                since: `${dayjs(newValue[0]).format("YYYY-MM-DD")}T01:00:00`,
                till: `${dayjs(newValue[1]).format("YYYY-MM-DD")}T23:59:59`,
                editedChannelUuid,
                type: "createdBooking",
              });
            }
          }
        }}
        renderInput={(startProps, endProps) => (
          <Box sx={SearchStyle.datePickers}>
            <TextField
              {...startProps}
              inputRef={(input) => input && input.blur()}
              fullWidth
              variant="outlined"
              sx={SearchStyle.textRoundedField}
              InputProps={{
                readOnly: true,
                style: { fontSize: matchesMd ? 11 : 12 },
                endAdornment: (
                  <DateRangeIcon
                    sx={SearchStyle.datePickerIconColor}
                    onClick={handleOpen}
                  />
                ),
              }}
            />
            <TextField
              {...endProps}
              inputRef={(input) => input && input.blur()}
              fullWidth
              variant="outlined"
              sx={SearchStyle.textRoundedField}
              InputProps={{
                readOnly: true,
                style: { fontSize: matchesMd ? 11 : 12 },
                endAdornment: (
                  <DateRangeIcon
                    sx={SearchStyle.datePickerIconColor}
                    onClick={handleOpen}
                  />
                ),
              }}
            />
          </Box>
        )}
      />
    </LocalizationProvider>
  );
};

export default SearchDateRange;
