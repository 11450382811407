import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  BookingDetailsResponseDto,
  BookingResponseDto,
} from "../../api-client/generated";
import { IIsBookedBeachChair } from "../../utils/models/IIsBookedBeachChair";
import {
  AddBeachChairBookingRequestPayload,
  AddBeachChairBookingSuccessPayload,
  AddCommentToBookingRequestPayload,
  AddCustomerBookingRequestPayload,
  AddCustomerBookingSuccessPayload,
  BookedBeachChaiPayload,
  BookingFailurePayload,
  ConfirmBankTransferBookingRequestPayload,
  ConfirmBankTransferBookingSuccessPayload,
  CreateBookingRequestPayload,
  CreateBookingSuccessPayload,
  DeleteBeachChairFromBookingRequestPayload,
  DeleteBeachChairFromBookingSuccess,
  DeleteExtrasFromBookingRequestPayload,
  DeleteExtrasFromBookingSuccess,
  FlushBookingRequestPayload,
  FlushBookingSuccessPayload,
  GetBeachChairAvailableCheckRequestPayload,
  GetBeachChairAvailableCheckSuccessPayload,
  GetBookingDetailsRequestPayload,
  GetBookingDetailsSuccessPayload,
  GetBookingRequestPayload,
  GetBookingSuccessPayload,
  PutBookingAbortPaymentRequestPayload,
  PutBookingAbortPaymentSuccessPayload,
  RedirectToPdfRequestPayload,
  SelectBookedBeachChaiPayload,
  SetBookingRequestPayload,
  SetPublicReferenceRequestPayload,
  UpdateBookingPaymentMethodRequestPayload,
} from "../actionPayload/bookingPayloads";
import { AppState } from "./rootReducer";

interface IBeachChairModelRate {
  model: string;
  rate: string;
}

const bookedBeachChairAdapter = createEntityAdapter<IIsBookedBeachChair>({
  selectId: (beachchair) =>
    `${beachchair.beachChairId}.${beachchair.start}.${beachchair.end}`,
});

interface IBooking {
  pending: boolean;
  error: string;
  bookingRef: string;
  bookingPublicReference: string;
  publicReference: string;
  customerComment?: string;
  booking?: BookingResponseDto | BookingDetailsResponseDto;
  bookingDetails?: BookingDetailsResponseDto;
  beachChairModelRate?: IBeachChairModelRate;
  bookingDetailsResult?: BookingDetailsResponseDto;
  bookedBeachChairAdapter: EntityState<IIsBookedBeachChair>;
  selectedBeachChair?: number;
  beachChairAvailable: boolean;
}

const initialState: IBooking = {
  pending: false,
  error: "",
  bookingRef: "",
  bookingPublicReference: "",
  publicReference: "",
  bookedBeachChairAdapter: bookedBeachChairAdapter.getInitialState({}),
  beachChairAvailable: false,
};

const bookingSlice = createSlice({
  name: "map",
  initialState,
  reducers: {
    createBookingRequest(
      state,
      action: PayloadAction<CreateBookingRequestPayload>
    ) {},
    createBookingSuccess(
      state,
      action: PayloadAction<CreateBookingSuccessPayload>
    ) {
      state.booking = action.payload.booking;
    },
    addBeachChairBookingRequest(
      state,
      action: PayloadAction<AddBeachChairBookingRequestPayload>
    ) {},
    addBeachChairBookingSuccess(
      state,
      action: PayloadAction<AddBeachChairBookingSuccessPayload>
    ) {
      state.booking = action.payload.booking;
    },
    addExtrasBookingSuccess(
      state,
      action: PayloadAction<AddBeachChairBookingSuccessPayload>
    ) {
      state.booking = action.payload.booking;
    },
    addCustomerBookingRequest(
      state,
      action: PayloadAction<AddCustomerBookingRequestPayload>
    ) {},
    addCustomerBookingSuccess(
      state,
      action: PayloadAction<AddCustomerBookingSuccessPayload>
    ) {},
    addCommentToBookingRequest(
      state,
      action: PayloadAction<AddCommentToBookingRequestPayload>
    ) {
      state.customerComment = action.payload.customerComment;
    },
    updateBookingPaymentMethodRequest(
      state,
      action: PayloadAction<UpdateBookingPaymentMethodRequestPayload>
    ) {},
    getBookingRequest(state, action: PayloadAction<GetBookingRequestPayload>) {
      state.bookingRef = action.payload.bookingRef;
    },
    getBookingSuccess(
      state,
      action: PayloadAction<GetBookingSuccessPayload>
    ) {},
    setBookingRef(state, action: PayloadAction<SetBookingRequestPayload>) {
      state.bookingRef = action.payload.bookingRef;
      state.bookingPublicReference = action.payload.publicReference;
    },
    setPublicReference(
      state,
      action: PayloadAction<SetPublicReferenceRequestPayload>
    ) {
      state.publicReference = action.payload.publicReference;
    },
    confirmBankTransferBookingRequest(
      state,
      action: PayloadAction<ConfirmBankTransferBookingRequestPayload>
    ) {},
    confirmBankTransferBookingSuccess(
      state,
      action: PayloadAction<ConfirmBankTransferBookingSuccessPayload>
    ) {
      state.booking = undefined;
      state.bookingRef = "";
      state.bookingPublicReference = "";
      state.publicReference = "";
      state.bookingDetailsResult = action.payload.bookingDetailsResult;
    },
    flushCurrentBookingRequest(
      state,
      action: PayloadAction<FlushBookingRequestPayload>
    ) {},
    flushCurrentBookingSuccess(
      state,
      action: PayloadAction<FlushBookingSuccessPayload>
    ) {
      state.booking = undefined;
      state.bookingDetailsResult = undefined;
      state.bookingRef = "";
      state.bookingPublicReference = "";
      state.customerComment = undefined;
    },
    deleteBeachChairFromBookingRequest(
      state,
      action: PayloadAction<DeleteBeachChairFromBookingRequestPayload>
    ) {},
    deleteBeachChairFromBookingSuccess(
      state,
      action: PayloadAction<DeleteBeachChairFromBookingSuccess>
    ) {
      state.booking = action.payload.booking;
    },
    selectBeachChairToBookingRequest(
      state,
      action: PayloadAction<SelectBookedBeachChaiPayload>
    ) {
      state.selectedBeachChair = action.payload.beachChairId;
    },
    addBookedBeachChairRequest(
      state,
      action: PayloadAction<BookedBeachChaiPayload>
    ) {
      bookedBeachChairAdapter.upsertOne(state.bookedBeachChairAdapter, {
        beachChairId: action.payload.beachChairId,
        start: action.payload.start,
        end: action.payload.end,
      });
    },
    removeBookedBeachChairRequest(
      state,
      action: PayloadAction<BookedBeachChaiPayload>
    ) {
      bookedBeachChairAdapter.removeOne(
        state.bookedBeachChairAdapter,
        `${action.payload.beachChairId}.${action.payload.start}.${action.payload.end}`
      );
    },
    flushBookedBeachChairsRequest(state) {
      bookedBeachChairAdapter.removeAll(state.bookedBeachChairAdapter);
    },
    deleteExtrasFromBookingRequest(
      state,
      action: PayloadAction<DeleteExtrasFromBookingRequestPayload>
    ) {},
    deleteExtrasFromBookingSuccess(
      state,
      action: PayloadAction<DeleteExtrasFromBookingSuccess>
    ) {
      state.booking = action.payload.booking;
    },
    getBookingDetailsRequest(
      state,
      action: PayloadAction<GetBookingDetailsRequestPayload>
    ) {},
    getBookingDetailsSuccess(
      state,
      action: PayloadAction<GetBookingDetailsSuccessPayload>
    ) {
      state.bookingDetails = action.payload.booking;
      state.bookingDetailsResult = action.payload.booking;
    },
    getBeachChairAvailableCheckRequest(
      state,
      action: PayloadAction<GetBeachChairAvailableCheckRequestPayload>
    ) {},
    getBeachChairAvailableCheckSuccess(
      state,
      action: PayloadAction<GetBeachChairAvailableCheckSuccessPayload>
    ) {
      state.beachChairAvailable = action.payload.available;
    },
    putBookingAbortPaymentRequest(
      state,
      action: PayloadAction<PutBookingAbortPaymentRequestPayload>
    ) {},
    putBookingAbortPaymentSuccess(
      state,
      action: PayloadAction<PutBookingAbortPaymentSuccessPayload>
    ) {
      state.booking = action.payload.booking;
    },
    redirectToPdfRequest(
      state,
      action: PayloadAction<RedirectToPdfRequestPayload>
    ) {},
    bookingFailure(state, action: PayloadAction<BookingFailurePayload>) {
      state.error = action.payload.error;
    },
  },
});

export const { selectAll: getAllBookedBeachChairs } =
  bookedBeachChairAdapter.getSelectors<AppState>(
    (state) => state.booking.bookedBeachChairAdapter
  );

export const {
  createBookingRequest,
  createBookingSuccess,
  addBeachChairBookingRequest,
  addBeachChairBookingSuccess,
  addExtrasBookingSuccess,
  addCustomerBookingRequest,
  addCustomerBookingSuccess,
  addCommentToBookingRequest,
  updateBookingPaymentMethodRequest,
  getBookingRequest,
  getBookingSuccess,
  setBookingRef,
  setPublicReference,
  confirmBankTransferBookingRequest,
  confirmBankTransferBookingSuccess,
  flushCurrentBookingRequest,
  flushCurrentBookingSuccess,
  deleteBeachChairFromBookingRequest,
  deleteBeachChairFromBookingSuccess,
  selectBeachChairToBookingRequest,
  addBookedBeachChairRequest,
  removeBookedBeachChairRequest,
  flushBookedBeachChairsRequest,
  deleteExtrasFromBookingRequest,
  deleteExtrasFromBookingSuccess,
  getBookingDetailsRequest,
  getBookingDetailsSuccess,
  getBeachChairAvailableCheckRequest,
  getBeachChairAvailableCheckSuccess,
  putBookingAbortPaymentRequest,
  putBookingAbortPaymentSuccess,
  redirectToPdfRequest,
  bookingFailure,
} = bookingSlice.actions;

export default bookingSlice.reducer;
