import { useLocation } from "react-router";

export function useUrlParams() {
  const location = useLocation();
  return URLParser(location.pathname);
}

export const URLParser = (pathname: string) => {
  const locationArray = pathname.split("/");
  locationArray.shift();
  const prefix = locationArray.shift()!;
  const suffix = locationArray.shift()!;
  const id = locationArray.pop()!;
  const suffixId = locationArray.pop() || "0";
  return { prefix, suffix, suffixId, id };
};
