import { FormControlLabel, FormGroup, Switch, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../store";
import {
  toggleIgnoreCloseExpandMenuRequest,
  toggleSearchSwitchRequest,
} from "../../../store/reducers/mainAppReducer";
import { getSwitchStateSelector } from "../../../store/selectors/mainAppSelectors";
import { SearchStyle } from "../../../utils/hooks/styles/searchStyle";

const SearchSwitch = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const checked = useSelector(getSwitchStateSelector);
  return (
    <FormGroup sx={SearchStyle.switchContent}>
      <Typography component="span" sx={SearchStyle.typographyBoldGray}>
        {t("common:all")}
      </Typography>
      <FormControlLabel
        labelPlacement={"end"}
        control={
          <Switch
            checked={checked}
            onChange={() => {
              dispatch(toggleIgnoreCloseExpandMenuRequest({ toggle: true }));
              dispatch(toggleSearchSwitchRequest({ toggle: !checked }));
            }}
          />
        }
        label={
          <Typography sx={SearchStyle.typographyBoldBlue}>
            {t("common:searchSwitchLabel")}
          </Typography>
        }
      />
    </FormGroup>
  );
};

export default SearchSwitch;
