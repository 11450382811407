import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store";
import {
  setFocusRequest,
  showStartToggle,
  toggleMapRequest,
} from "../../store/reducers/mainAppReducer";
import { ContentStyles } from "../../utils/hooks/styles/contentStyles";
import { getShowStartSelector } from "../../store/selectors/mainAppSelectors";
import StrandbutlerWindsurfing from "./../../assets/windsurf.jpg";
import StrandbutlerSurfer from "./../../assets/surf.jpg";
import StrandbutlerMitTablet from "./../../assets/tablet.jpg";

const typographyBoldTitle = {
  fontWeight: "bolder",
  fontSize: {
    xs: "20px",
    md: "25px",
  },
};

const typographyText = {
  fontSize: {
    xs: "18px",
    md: "20px",
  },
};

const imageGrid = {
  padding: {
    xs: "10px",
    md: "20px",
  },
  display: "grid",
  gridTemplateColumns: "33% 33% 33%",
  gap: {
    xs: "5px",
    md: "10px",
  },
};

const StartContent = () => {
  const { t } = useTranslation(["common"]);
  const showStart = useSelector(getShowStartSelector);
  const dispatch = useAppDispatch();

  return showStart ? (
    <Box sx={ContentStyles.root}>
      <Box sx={ContentStyles.content}>
        <Box sx={ContentStyles.helperText}>
          <Box sx={ContentStyles.background}>
            <Box sx={ContentStyles.markDown}>
              <Typography sx={{ ...typographyBoldTitle }}>
                {t("startTitle")}
              </Typography>
              <Typography sx={{ ...typographyText }}>
                {t("startText")}
              </Typography>
              <Box sx={{ ...imageGrid }}>
                <img
                  src={StrandbutlerWindsurfing}
                  alt="Strandbutler Windsurfing"
                  loading="lazy"
                />
                <img
                  src={StrandbutlerSurfer}
                  alt="Strandbutler Windsurfing"
                  loading="lazy"
                />
                <img
                  src={StrandbutlerMitTablet}
                  alt="Strandbutler Windsurfing"
                  loading="lazy"
                />
              </Box>
            </Box>
            <Box sx={ContentStyles.buttonsGrid}>
              <Button
                variant="contained"
                sx={ContentStyles.button}
                onClick={() => {
                  dispatch(setFocusRequest({ toggle: true }));
                  dispatch(showStartToggle({ toggle: false }));
                  dispatch(toggleMapRequest({ toggle: true }));
                }}
              >
                {t("findBeachChairWithSearch")}
              </Button>
              <Typography
                sx={{ padding: "1em", color: ContentStyles.markDown.color }}
              >
                {t("or")}
              </Typography>
              <Button
                variant="contained"
                sx={ContentStyles.button}
                onClick={() => {
                  dispatch(showStartToggle({ toggle: false }));
                  dispatch(toggleMapRequest({ toggle: true }));
                }}
              >
                {t("findBeachChairWithMap")}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  ) : (
    <></>
  );
};

export default StartContent;
