import { create } from "zustand";
import { ProgramWithOmittedUuid } from "@nessprim/planby/dist/Epg/helpers";

interface CreatedBookingIndicatorStore {
  editedChannelUuid: string;
  createdBookings: ProgramWithOmittedUuid[];
  setCreatedBookings: (createdBookings: ProgramWithOmittedUuid[]) => void;
  setCreatedToBooking: () => void;
  createRowSeparator: (createdBooking: ProgramWithOmittedUuid) => void;
  createBooking: (createdBooking: ProgramWithOmittedUuid) => void;
  setCreatedBooking: (
    _channelUuid: string,
    createdBooking: ProgramWithOmittedUuid
  ) => void;
  clearCreatedBooking: (_channelUuid: string) => void;
  removeBooking: (_channelUuid: string) => void;
}

export const useCreatedBookingIndicator = create<CreatedBookingIndicatorStore>(
  (set, get) => {
    return {
      editedChannelUuid: "",
      createdBookings: [],
      setCreatedBookings: (createdBookings: ProgramWithOmittedUuid[]) =>
        set((state) => ({
          ...state,
          createdBookings: createdBookings.map((epg, index) => {
            return { ...epg, id: index };
          }),
        })),
      createRowSeparator: (createdBooking: ProgramWithOmittedUuid) =>
        set((state) => ({
          ...state,
          createdBookings: [...get().createdBookings, createdBooking],
        })),
      createBooking: (createdBooking: ProgramWithOmittedUuid) =>
        set((state) => ({
          ...state,
          editedChannelUuid: createdBooking.channelUuid,
          createdBookings: [
            ...get().createdBookings.filter(
              (booking) => (booking as any).type !== "createdBooking"
            ),
            createdBooking,
          ],
        })),
      setCreatedBooking: (
        _channelUuid: string,
        createdBooking: ProgramWithOmittedUuid
      ) =>
        set((state) => ({
          ...state,
          editedChannelUuid: _channelUuid,
          createdBookings: get().createdBookings.map((booking) => {
            if (booking.channelUuid === _channelUuid) {
              return {
                ...booking,
                till: createdBooking.till,
                since: createdBooking?.since ?? booking.since,
              };
            }
            return booking;
          }),
        })),
      setCreatedToBooking: () =>
        set((state) => ({
          ...state,
          editedChannelUuid: "",
          createdBookings: get().createdBookings.map((booking) => {
            if (booking.type === "createdBooking") {
              return {
                ...booking,
                type: "newBooking",
              };
            }
            return booking;
          }),
        })),
      clearCreatedBooking: (_channelUuid: string) =>
        set((state) => ({
          ...state,
          editedChannelUuid: "",
          createdBookings: get().createdBookings.filter(
            (booking) => (booking as any).type !== "createdBooking"
          ),
        })),
      removeBooking: (_channelUuid: string) =>
        set((state) => ({
          ...state,
          editedChannelUuid: "",
          createdBookings: get().createdBookings.filter(
            (booking) => booking.channelUuid !== _channelUuid
          ),
        })),
    };
  }
);
