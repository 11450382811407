import {
  Box,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { LocationAvailabilitiesBeachChairsStyle } from "../../utils/hooks/styles/locationAvailabilitiesBeachChairsStyle";
import { useSelector } from "react-redux";
import {
  getDateFromSelector,
  getDateToSelector,
  getIsSubSectionsSelector,
  mainComponentShowSelector,
} from "../../store/selectors/mainAppSelectors";
import { getVendorSelector } from "../../store/selectors/vendorSelectors";
import AvailabilitiesBeachChairTimeline, {
  SBColor,
} from "./availabilitiesBeachChair/availabilitiesBeachChairTimeline";
import AvailabilitiesBeachChairs from "./availabilitiesBeachChair/availabilitiesBeachChairs";
import Waves from "../static/waves";
import React, { useMemo, useState } from "react";
import { useAppDispatch } from "../../store";
import { CalendarMonth, Close, Grid3x3 } from "@mui/icons-material";
import { setMapStateRequest } from "../../store/reducers/mapReducer";
import { mainComponentShowChangeRequest } from "../../store/reducers/mainAppReducer";
import { EMapState } from "../../utils/models/EMapState";
import { useTranslation } from "react-i18next";
import {
  getSelectedSectionLeadTimeHoursSelector,
  getSelectedSectionSelector,
} from "../../store/selectors/sectionSelectors";
import { getSectionsSelector } from "../../store/selectors/availabilitySelectors";
import { getPublicReferenceSelector } from "../../store/selectors/bookingSelectors";
import { getPublicSectionsBeachChairsAvailabilityRequest } from "../../store/reducers/availabilityReducer";

export const formatDate = (year: Number, season: String) => {
  const seasonMonthDay = season.split("-");
  return new Date(+year, +seasonMonthDay[1] - 1, +seasonMonthDay[0]);
};

const LocationAvailabilitiesBeachChairs = () => {
  const { t } = useTranslation("availabilities");
  const mainComponentShow = useSelector(mainComponentShowSelector);
  const dispatch = useAppDispatch();
  const vendor = useSelector(getVendorSelector);
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.down("md"));
  const parentRef = React.useRef<HTMLDivElement>(null);
  const subSection = useSelector(getIsSubSectionsSelector);
  const [switchTimeline, setSwitchTimeline] = useState(false);
  const start = useSelector(getDateFromSelector);
  const end = useSelector(getDateToSelector);
  const section = useSelector(getSelectedSectionSelector);
  const startRef = React.useRef<string>(start);
  const endRef = React.useRef<string>(end);
  const publicReference = useSelector(getPublicReferenceSelector);
  const thisYearSesonStart = formatDate(
    +start.split("-")[0],
    section?.location?.seasonStart || ""
  );
  const thisYearSesonEnd = formatDate(
    +start.split("-")[0],
    section?.location?.seasonEnd || ""
  );

  const locationFirstAvailableDate = useSelector(
    getSelectedSectionLeadTimeHoursSelector
  );

  const seasonStartEndDisplay = useMemo(
    () =>
      thisYearSesonEnd < new Date(start) ||
      thisYearSesonStart > new Date(start),
    [thisYearSesonEnd, thisYearSesonStart, start]
  );

  React.useEffect(() => {
    if (
      section &&
      publicReference &&
      (startRef.current !== start || endRef.current !== end)
    ) {
      dispatch(
        getPublicSectionsBeachChairsAvailabilityRequest({
          publicReference,
          sectionId: section!.id,
          start,
          end,
          hasSubsections: false,
        })
      );
      startRef.current = start;
      endRef.current = end;
    }
    // eslint-disable-next-line
  }, [start, end]);

  const rows = useSelector(getSectionsSelector);

  const displayRows = useMemo(() => {
    return (
      rows.length > 0 &&
      rows.every((row) => (row?.rowData?.length ? true : false))
    );
  }, [rows]);

  if (mainComponentShow) {
    return (
      <Box
        sx={LocationAvailabilitiesBeachChairsStyle.root}
        style={vendor?.configuration.gridLineBreak ? { display: "block" } : {}}
      >
        <Box sx={LocationAvailabilitiesBeachChairsStyle.sticky} ref={parentRef}>
          <Box sx={LocationAvailabilitiesBeachChairsStyle.waterTypography}>
            <Typography sx={{ color: SBColor.blue, fontWeight: "bold" }}>{`${t(
              "water"
            )}`}</Typography>
          </Box>
          <Waves />
          {displayRows ? (
            <Box sx={LocationAvailabilitiesBeachChairsStyle.switchMin}>
              <FormControlLabel
                onChange={() => {
                  setSwitchTimeline((prev) => !prev);
                }}
                value={switchTimeline}
                control={<Switch color="primary" />}
                label={
                  switchTimeline ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                      }}
                    >
                      <Grid3x3 sx={{ color: SBColor.blue }} />
                      <Typography
                        sx={{ color: SBColor.blue, fontWeight: "bold" }}
                      >{`${t("grid")}`}</Typography>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                      }}
                    >
                      <CalendarMonth sx={{ color: SBColor.blue }} />
                      <Typography
                        sx={{ color: SBColor.blue, fontWeight: "bold" }}
                      >{`${t("timeline")}`}</Typography>
                    </Box>
                  )
                }
                labelPlacement="end"
              />
            </Box>
          ) : (
            <Box sx={LocationAvailabilitiesBeachChairsStyle.switchMin}>
              <CircularProgress sx={{ color: SBColor.blue }} />
            </Box>
          )}
          {seasonStartEndDisplay && (
            <Box sx={LocationAvailabilitiesBeachChairsStyle.noData}>
              <Typography
                variant={"h2"}
                sx={LocationAvailabilitiesBeachChairsStyle.noData}
              >
                {t("outOfSeason")}
              </Typography>
              <Typography
                variant={"h3"}
                sx={{
                  ...LocationAvailabilitiesBeachChairsStyle.noDataText,
                  padding: 5,
                }}
              >
                {t("seasonStart")}: {thisYearSesonStart.getDate()}.{" "}
                {t(`month.${thisYearSesonStart.getMonth()}`)}
                {` // `}
                {t("seasonEnd")}: {thisYearSesonEnd.getDate()}.{" "}
                {t(`month.${thisYearSesonEnd.getMonth()}`)}
              </Typography>
              <Typography
                variant={"body1"}
                sx={LocationAvailabilitiesBeachChairsStyle.noDataText}
              >
                {t("outOfSeasonText")}
              </Typography>
            </Box>
          )}
          {locationFirstAvailableDate !== undefined && (
            <Box sx={LocationAvailabilitiesBeachChairsStyle.noData}>
              <Typography
                variant={"h2"}
                sx={LocationAvailabilitiesBeachChairsStyle.noData}
              >
                {t(`locationFirstAvailableDateTitle`)}
              </Typography>
              <Typography
                variant={"h3"}
                sx={{
                  ...LocationAvailabilitiesBeachChairsStyle.noDataText,
                  padding: 5,
                }}
              >
                {t(`locationFirstAvailableDate`, {
                  time: locationFirstAvailableDate.leadTimeHours,
                  date: locationFirstAvailableDate.addedHours,
                })}
              </Typography>
            </Box>
          )}
          {!matchesMd && (
            <IconButton
              sx={LocationAvailabilitiesBeachChairsStyle.buttonMin}
              aria-label="minmax"
              onClick={() => {
                dispatch(mainComponentShowChangeRequest({ toggle: false }));
                dispatch(
                  setMapStateRequest({
                    mapState: subSection ? EMapState.ROWS : EMapState.BEACH,
                  })
                );
              }}
            >
              <Close />
            </IconButton>
          )}
        </Box>
        {!(locationFirstAvailableDate !== undefined) ? (
          !seasonStartEndDisplay ? (
            switchTimeline ? (
              <AvailabilitiesBeachChairTimeline />
            ) : (
              <AvailabilitiesBeachChairs />
            )
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
      </Box>
    );
  } else {
    return <></>;
  }
};

export default LocationAvailabilitiesBeachChairs;
