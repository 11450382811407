import {
  LocationFailurePayload,
  SetGlobalLocationRequest,
} from "./../actionPayload/locationPayloads";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ILocationState {
  pending: boolean;
  error: string;
}

const initialState: ILocationState = {
  pending: false,
  error: "",
};

const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    setGlobalRegionRequest(
      state,
      action: PayloadAction<SetGlobalLocationRequest>
    ) {},
    setGlobalCityRequest(
      state,
      action: PayloadAction<SetGlobalLocationRequest>
    ) {},
    setGlobalBeachRequest(
      state,
      action: PayloadAction<SetGlobalLocationRequest>
    ) {},
    setGlobalSectionRequest(
      state,
      action: PayloadAction<SetGlobalLocationRequest>
    ) {},
    setGlobalBeachChairRequest(
      state,
      action: PayloadAction<SetGlobalLocationRequest>
    ) {},
    locationFailure(state, action: PayloadAction<LocationFailurePayload>) {
      state.pending = false;
      state.error = action.payload.error;
    },
  },
});

export const {
  setGlobalRegionRequest,
  setGlobalCityRequest,
  setGlobalBeachRequest,
  setGlobalSectionRequest,
  setGlobalBeachChairRequest,
  locationFailure,
} = locationSlice.actions;

export default locationSlice.reducer;
