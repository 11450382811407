export enum EColor {
    'silver' = '#C0C0C0',
    'darkgreen' = '#006400',
    'green' = '#00FF00',
    'lightgreen' = '#90EE90',
    'darkblue' = '#00008B',
    'blue' = '#0000FF',
    'lightblue' = '#ADD8E6',
    'darkred' = '#8B0000',
    'red' = '#ff0000',
    'lightred' = '#FFCCCB',
    'white' = '#eeeeee',
    "brightyellow" = "#FFE900",
    "yellow" = "#FFEF00",
    "darkyellow" = "#E0A526",
    "black" = "#000000",
    "brightbrown" = "#E2BB7B",
    "brown" = "#B3632B",
    "darkbrown" = "#4D2B12",
    "beige" = "#f5f5dc",
    "orange" = "#ffaa00",
    "grey" = "#909090",
    "purple" = "#5a005a",
    "pink" = "#c09da4",
}
