import { combineReducers } from "redux";
import locationReducer from "./locationReducer";
import mainAppReducer from "./mainAppReducer";
import mapReducer from "./mapReducer";
import availabilityReducer from "./availabilityReducer";
import regionReducer from "./regionReducer";
import cityReducer from "./cityReducer";
import beachReducer from "./beachReducer";
import sectionReducer from "./sectionReducer";
import priceReducer from "./priceReducer";
import bookingReducer from "./bookingReducer";
import vendorReducer from "./vendorReducer";
import extrasReducer from "./extrasReducer";

const rootReducer = combineReducers({
  main: mainAppReducer,
  region: regionReducer,
  city: cityReducer,
  beach: beachReducer,
  section: sectionReducer,
  location: locationReducer,
  availability: availabilityReducer,
  price: priceReducer,
  map: mapReducer,
  booking: bookingReducer,
  vendor: vendorReducer,
  extras: extrasReducer
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
