import { Box, Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../store";
import { redirectRequest } from "../../store/reducers/mainAppReducer";
import { ResultStyles } from "../../utils/hooks/styles/resultStyles";

const Page404 = () => {
  const { t } = useTranslation(["common"]);
  const dispatch = useAppDispatch();

  return (
    <Box sx={ResultStyles.root}>
      <Box sx={ResultStyles.content}>
        <Box sx={ResultStyles.icon}>?</Box>
        <Box sx={ResultStyles.errorCode}>404</Box>
        <Box sx={ResultStyles.helperText}>{t("404Info")}</Box>
        <Button
          variant="contained"
          color="primary"
          sx={ResultStyles.button}
          onClick={() => {
            dispatch(redirectRequest({ path: "/" }));
          }}
        >
          {t("backToStart")}
        </Button>
      </Box>
    </Box>
  );
};

export default Page404;
