import { makeStyles } from "@mui/styles";
import { stylesConfig } from "../../styles/styles";

/** COMPONENT STRUCTURE

 abababababbbab

 lc  mcmcmc  rc
 lc  mcmcmc  rc
 lc  mcmcmc  rc

 ab = appBar
 lc = leftComponent
 rc = rightComponent
 mc = mainComponent
 */

export const AppStyles = {
  mobileView: {
    maxHeight: {
      xs: `calc(100vh - 2*${stylesConfig.appBarHeight} - ${stylesConfig.footerHeight} + 20px)`,
      md: `100vh`,
    },
    marginTop: {
      xs: `calc(2*${stylesConfig.appBarHeight} - 20px)`,
      md: 0,
    },
    marginBottom: {
      xs: stylesConfig.footerHeight,
      md: 0,
    },
    overflowY: "scroll",
    boxSizing: "content-box",
    "&::-webkit-scrollbar": {
      width: 1,
      height: 0,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: stylesConfig.blueColor,
      borderRadius: stylesConfig.sxBorderRadius,
    },
  } as const,
  mobileViewExpanded: {
    maxHeight: {
      xs: `calc(100vh - 230px - ${stylesConfig.footerHeight} + 10px)`,
      md: "100vh",
    },
    marginTop: {
      xs: "232px",
      md: 0,
    },
    overflowY: "scroll",
    boxSizing: "content-box",
    "&::-webkit-scrollbar": {
      width: 1,
      height: 0,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: stylesConfig.blueColor,
      borderRadius: stylesConfig.sxBorderRadius,
    },
  } as const,
  colorBlack: {
    color: stylesConfig.blueColor,
    fontWeight: "bold",
  },
  datePickerIconColor: { color: stylesConfig.blackDarkTransparentColor },
  bottomNavigation: {
    zIndex: 9999,
    top: "auto",
    bottom: 0,
    backgroundColor: `${stylesConfig.whiteColor}`,
    height: `${stylesConfig.footerHeight}`,
    justifyContent: "center",
    boxShadow: {
      xs: stylesConfig.blueBoxShadow,
      sm: stylesConfig.blueBoxShadow,
      md: stylesConfig.boxShadow,
    },
    width: "100%",
  } as const,
  bottomToolbar: {
    display: "grid",
    gridTemplateColumns: "auto auto auto auto auto",
    justifyContent: "right",
    fontWeight: "bold",
    fontSize: {
      xs: 10,
      sm: 10,
      md: 14,
    },
    "& a": {
      textDecoration: "none",
      marginRight: "10px",
      color: stylesConfig.blueColor,
    },
  },
  appBar: {
    zIndex: 9999,
    position: "fixed",
    backgroundColor: `${stylesConfig.whiteColor}`,
    py: {
      xs: 2,
      sm: 2,
      md: 0,
    },
    boxShadow: {
      xs: stylesConfig.blueBoxShadow,
      sm: stylesConfig.blueBoxShadow,
      md: stylesConfig.boxShadow,
    },
    minHeight: {
      xs: `calc(2*${stylesConfig.appBarHeight})`,
      sm: `calc(2*${stylesConfig.appBarHeight})`,
      md: `${stylesConfig.appBarHeight}`,
    },
    maxHeight: {
      xs: `calc(4*${stylesConfig.appBarHeight})`,
      sm: `calc(4*${stylesConfig.appBarHeight})`,
      md: `${stylesConfig.appBarHeight}`,
    },
    width: {
      xs: `100%`,
      sm: `100%`,
      md: `calc(100vw - ${stylesConfig.marginLeft} - ${stylesConfig.marginRight})`,
    },
    borderRadius: {
      xs: `0px 0px 20px 20px`,
      sm: `0px 0px 20px 20px`,
      md: stylesConfig.sxBorderRadius,
    },
    marginTop: {
      xs: 0,
      sm: 0,
      md: stylesConfig.marginTop,
    },
    marginLeft: {
      xs: 0,
      sm: 0,
      md: stylesConfig.marginLeft,
    },
    marginRight: {
      xs: 0,
      sm: 0,
      md: stylesConfig.marginRight,
    },
  } as const,
  textRoundedField: {
    "&:hover": {
      borderColor: stylesConfig.blueColor,
    },
    [`& fieldset`]: {
      borderRadius: stylesConfig.borderRadius,
      borderColor: stylesConfig.blackTransparentColor,
    },
  },
  toolbar: {
    display: "grid",
    gap: 1,
    minHeight: {
      xs: `calc(2*${stylesConfig.appBarHeight})`,
      sm: `calc(2*${stylesConfig.appBarHeight})`,
      md: `${stylesConfig.appBarHeight}`,
    },
    maxHeight: {
      xs: `calc(4*${stylesConfig.appBarHeight})`,
      sm: `calc(4*${stylesConfig.appBarHeight})`,
      md: `${stylesConfig.appBarHeight}`,
    },
    gridTemplateColumns: {
      xs: "130px auto",
      sm: "130px auto",
      md: "200px 350px auto",
    },
    gridTemplateRows: {
      xs: "40px 50px auto auto",
      sm: "40px 50px auto auto",
      md: `${stylesConfig.appBarHeight}`,
      lg: `${stylesConfig.appBarHeight}`,
    },
  },
  datePickersCenter: {
    gridColumnStart: "span 2",
    display: "grid",
    gap: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  datePickers: {
    display: "grid",
    alignItems: "center",
    justifyContent: "center",
    gap: 1,
    width: 350,
    gridTemplateColumns: "auto auto",
  },
  navigationRight: {
    display: "grid",
    gridTemplateColumns: {
      xs: "auto auto auto",
      sm: "auto auto auto",
      lg: "auto auto auto auto auto",
    },
    columnGap: "10px",
    alignItems: "center",
    justifyContent: "right",
  },
  menuButton: {
    marginRight: 2,
    color: stylesConfig.blueColor,
  },
  navItem: {
    height: {
      xs: 50,
      sm: 50,
      md: 70,
    },
    color: stylesConfig.blueColor,
    display: "grid",
    justifyContent: "center",
    gridTemplateRows: "4px auto",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: stylesConfig.whiteColor,
      color: stylesConfig.orangeColor,
    },
  },
  navIcon: {
    height: {
      xs: 20,
      sm: 20,
      md: 30,
    },
    width: {
      xs: 20,
      sm: 20,
      md: 30,
    },
    color: stylesConfig.blueColor,
    display: "grid",
    justifyItems: "center",
    alignItems: "center",
    gridTemplateRows: "4px auto",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: stylesConfig.whiteColor,
      color: stylesConfig.orangeColor,
    },
  },
  background: {
    marginTop: "50px",
    paddingBottom: "1em",
    backgroundColor: stylesConfig.whiteColor,
    borderRadius: stylesConfig.sxBorderRadius,
    boxShadow: stylesConfig.blueBoxShadow,
  },
  marginTop: {
    marginTop: { sm: "0px", lg: "20px" },
    justifyItems: "center",
    alignItems: "center",
    textAlign: "center",
  } as const,
  select: {
    width: "inherit",
    height: "4px",
    backgroundColor: stylesConfig.orangeColor,
  },
  selected: {
    color: stylesConfig.orangeColor,
  },
  logoIcon: {
    imageRendering: "crisp-edges",
    cursor: "pointer",
    maxHeight: "60px",
    maxWidth: "160px",
  } as const,
  leftAbsoluteComponent: {
    position: {
      xs: "fixed",
      sm: "fixed",
      md: "fixed",
      xl: "fixed",
    },
    bottom: {
      xs: 0,
      sm: 0,
    },
    marginBottom: {
      xs: 0,
      sm: 0,
    },
    marginLeft: {
      xs: 0,
      sm: 0,
      md: stylesConfig.marginLeft,
      xl: stylesConfig.marginLeft,
    },
    width: {
      xs: `100%`,
      sm: `100%`,
      md: stylesConfig.leftMinComponentWidth,
      xl: stylesConfig.leftComponentWidth,
    },
    zIndex: 11111,
  } as const,
  marginTopExpanded: {
    marginTop: "20px !important",
  },
  leftComponent: {
    position: {
      xs: "relative",
      sm: "relative",
      md: "fixed",
      xl: "fixed",
    },
    marginTop: {
      xs: "30px",
      sm: "30px",
      md: `calc(${stylesConfig.appBarHeight} + 2*${stylesConfig.marginTop})`,
      xl: `calc(${stylesConfig.appBarHeight} + 2*${stylesConfig.marginTop})`,
    },
    marginLeft: {
      xs: 0,
      sm: 0,
      md: stylesConfig.marginLeft,
      xl: stylesConfig.marginLeft,
    },
    width: {
      xs: `100%`,
      sm: `100%`,
      md: stylesConfig.leftMinComponentWidth,
      xl: stylesConfig.leftComponentWidth,
    },
    zIndex: 8888,
  } as const,
  mainComponent: {
    position: {
      xs: "relative",
      sm: "relative",
      md: "fixed",
      xl: "fixed",
    },
    marginTop: {
      xs: "10px",
      sm: "10px",
      md: `calc(${stylesConfig.appBarHeight} + 2*${stylesConfig.marginTop})`,
      xl: `calc(${stylesConfig.appBarHeight} + 2*${stylesConfig.marginTop})`,
    },
    marginLeft: {
      xs: 0,
      sm: 0,
      md: `calc(${stylesConfig.leftMinComponentWidth} + 2*${stylesConfig.marginLeft})`,
      xl: `calc(${stylesConfig.leftComponentWidth} + 2*${stylesConfig.marginLeft})`,
    },
    width: {
      xs: `100%`,
      sm: `100%`,
      md: `calc(100vw - ${stylesConfig.leftMinComponentWidth} - 3*${stylesConfig.marginLeft})`,
      xl: `calc(100vw - ${stylesConfig.leftComponentWidth} - 3*${stylesConfig.marginLeft})`,
    },
    zIndex: 6666,
  } as const,
  noTextDecoration: {
    textDecoration: "none",
    color: stylesConfig.blueColor,
  },
  notchedOutline: {
    borderWidth: "1px",
    borderColor: `${stylesConfig.blueColor}`,
    borderRadius: stylesConfig.borderRadius,
    boxShadow: stylesConfig.boxShadow,
  },
  datePicker: {
    width: 150,
    marginTop: 0,
    marginBottom: 0,
  },
  toaster: {
    zIndex: 99999999999,
    "& .Toastify__toast-container": {
      zIndex: 99999999999,
    },
    "& .Toastify__toast--error": {
      zIndex: 99999999999,
      background: stylesConfig.orangeColor,
      borderRadius: stylesConfig.borderRadius,
      boxShadow: stylesConfig.boxShadow,
    },
  },
  badge: {
    alignItems: "center",
    justifyContent: "center",
  },
  hamburgerMenuItem: {
    width: 200,
    marginRight: 20,
    marginLeft: 20,
    marginTop: 10,
    marginBottom: 10,
    display: "grid",
    columnGap: "20px",
    gridTemplateColumns: "auto 40px",
  },
  navHamburgerItem: {
    marginTop: 5,
    color: stylesConfig.blueColor,
    display: "grid",
    justifyContent: "center",
    gridTemplateRows: "4px auto",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: stylesConfig.whiteColor,
      color: stylesConfig.orangeColor,
    },
  },
  calendar: {
    zIndex: 999999999,
    backgroundColor: stylesConfig.blueBackground,
  },

  // Widget overrides
  widgetMode: {
    toolbar: {
      gridTemplateColumns: {
        xs: "auto 1fr",
        md: "auto repeat(2, 1fr)",
      },
    },
    navigationRight: {
      gridTemplateColumns: {
        xs: "auto auto auto",
        sm: "auto auto auto",
        lg: "1fr",
      },
      textAlign: "right",
    },
    mobileView: {
      height: {
        xs: "calc(100vh - 175px)",
        md: "100vh",
      },
      marginBottom: 0,
      marginTop: {
        xs: "175px",
        md: 0,
      },
    },
    mobileViewExpanded: {
      height: {
        xs: "calc(100vh - 230px)",
        md: "100vh",
      },
      maxHeight: "unset",
      marginTop: {
        xs: "230px",
        md: 0,
      },
      marginBottom: 0,
    },
  },
};

export const useAppStyles = makeStyles(() => ({
  textRoundedField: {
    "&:hover": {
      borderColor: stylesConfig.blueColor,
    },
    [`& fieldset`]: {
      borderRadius: stylesConfig.borderRadius,
      borderColor: stylesConfig.blackTransparentColor,
    },
  },
  datePickers: {
    display: "grid !important",
    width: 350,
    columnGap: "20px",
    gridTemplateColumns: "auto auto",
  },
  navItem: {
    height: 70,
    color: stylesConfig.blueColor,
    display: "grid",
    justifyContent: "center",
    gridTemplateRows: "4px auto",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: stylesConfig.whiteColor,
      color: stylesConfig.orangeColor,
    },
  },
  navIcon: {
    height: 30,
    width: 30,
    color: stylesConfig.blueColor,
    display: "grid !important",
    justifyContent: "center",
    gridTemplateRows: "4px auto",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: stylesConfig.whiteColor,
      color: stylesConfig.orangeColor,
    },
  },
  select: {
    width: "inherit",
    height: "4px",
    backgroundColor: stylesConfig.orangeColor,
  },
  selected: {
    color: stylesConfig.orangeColor,
  },
  noTextDecoration: {
    textDecoration: "none",
    color: stylesConfig.blueColor,
  },
  customTextField: {
    "& input::placeholder": {
      fontSize: "20px",
    },
  },
  datePicker: {
    width: 150,
    marginTop: "0 !important",
    marginBottom: "0 !importan",
  },
  toaster: {
    zIndex: 999999,
    "& .Toastify__toast-container": {
      zIndex: 999999,
    },
    "& .Toastify__toast--error": {
      zIndex: 999999,
      background: stylesConfig.orangeColor,
      borderRadius: stylesConfig.borderRadius,
      boxShadow: stylesConfig.boxShadow,
    },
  },
  customBadge: {
    backgroundColor: `${stylesConfig.orangeColor} !important`,
    color: `${stylesConfig.whiteColor} !important`,
  },
  hamburgerMenuItem: {
    marginRight: 20,
    marginLeft: 20,
    marginTop: 10,
    marginBottom: 10,
    minWidth: 250,
    display: "grid",
    columnGap: "20px",
    gridTemplateColumns: "auto 40px",
  },
  navHamburgerItem: {
    marginTop: 10,
    color: stylesConfig.blueColor,
    display: "grid",
    justifyContent: "center",
    gridTemplateRows: "4px auto",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: stylesConfig.whiteColor,
      color: stylesConfig.orangeColor,
    },
  },
}));
