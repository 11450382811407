import { createContext, PropsWithChildren, useContext, useMemo } from "react";
import { useWidgetZustand } from "./zustand/widgetZustand";

interface WidgetContextValue {
  isWidgetActive: boolean;
}

const WidgetContext = createContext<WidgetContextValue>({
  isWidgetActive: false,
});

// Unfortunately, the widget param is removed from the URL due to
// the complex redirects thus value isWidgetActive must be set on
// initial load.
export const WidgetProvider: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const { setwidgetUrlZustand, setPublicRef } = useWidgetZustand();
  const isWidgetActive = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const isWidget = urlParams.get("widget");
    const publicRef = urlParams.get("publicReference");
    if (isWidget === "true" && publicRef !== null) {
      setwidgetUrlZustand(window.location.pathname);
      setPublicRef(publicRef);
    }
    return isWidget === "true";
    // eslint-disable-next-line
  }, []);

  const value = useMemo(() => ({ isWidgetActive }), [isWidgetActive]);

  return (
    <WidgetContext.Provider value={value}>{children}</WidgetContext.Provider>
  );
};

export const useWidget = (): WidgetContextValue => {
  const context = useContext<WidgetContextValue>(WidgetContext);

  if (!context) {
    throw new Error("useWidget must be used within a WidgetProvider");
  }

  return context;
};
