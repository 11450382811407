import React from "react";
import { Autocomplete, Box, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store";
import {
  setSelectedExtraTypeRequest,
} from "../../store/reducers/extrasReducer";
import { getAllExtrasSelector } from "../../store/selectors/extraSelectors";
import { EURO } from "../../utils/convertions/euroFormater";

function AutocompleteSearchAdditionalItems() {
  const { t } = useTranslation(["additionalItems"]);
  const items = useSelector(getAllExtrasSelector);
  const dispatch = useAppDispatch();
  return (
    <Box>
      <Autocomplete
        disablePortal
        id="search"
        options={items}
        getOptionLabel={(item) =>
          `${item.name} ${EURO(item.price || 0).format()}`
        }
        onChange={(event, value) => {
          if (value) {
            dispatch(setSelectedExtraTypeRequest({ option: value.type }));
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("buyItems")}
            sx={{
              [`& fieldset`]: {
                borderRadius: 20,
              },
            }}
          />
        )}
      />
    </Box>
  );
}

export default AutocompleteSearchAdditionalItems;
