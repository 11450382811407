import { Marker } from "@react-google-maps/api";
import React from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../store";
import { setGlobalCityRequest } from "../../../store/reducers/locationReducer";
import { getCitiesSelector } from "../../../store/selectors/citySelectors";
import { getSelectedRegionSelector } from "../../../store/selectors/regionSelectors";
import { geoJsonGuard } from "../../../utils/functions/geoJsonGuard";
import { IGeoPoint } from "../../../utils/models/IGeoPoint";
import { IMapStateProps } from "../../../utils/models/IMapStateProps";
import logo from "./../../../assets/SBLMarker.png";

const RegionMapState = ({ setMapBounds }: IMapStateProps) => {
  const dispatch = useAppDispatch();
  const region = useSelector(getSelectedRegionSelector);
  const cities = useSelector(getCitiesSelector);

  const regionCities = React.useMemo(
    () =>
      cities
        .filter((city) => geoJsonGuard(city.geoJson))
        .filter((city) => city.regionId === region?.id || 0),
    [region, cities]
  );

  React.useEffect(() => {
    const bounds = new window.google.maps.LatLngBounds();
    regionCities.forEach((city) => {
      bounds.extend({
        lat: (city.geoJson as IGeoPoint).coordinates[1],
        lng: (city.geoJson as IGeoPoint).coordinates[0],
      });
    });
    setMapBounds(bounds);
  }, [regionCities, setMapBounds]);

  return (
    <>
      {regionCities.map((city) => {
        return (
          <Marker
            key={`city-${city.id}`}
            icon={{
              url: logo,
              scaledSize: new google.maps.Size(50, 50),
            }}
            position={{
              lat: (city.geoJson as IGeoPoint).coordinates[1],
              lng: (city.geoJson as IGeoPoint).coordinates[0],
            }}
            onClick={() => {
              dispatch(setGlobalCityRequest({ id: city.id }));
            }}
          />
        );
      })}
    </>
  );
};

export default RegionMapState;
