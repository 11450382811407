import { Box, Typography } from "@mui/material";
import { useWidget } from "../../contexts";
import { SearchStyle } from "../../utils/hooks/styles/searchStyle";
import { useTranslation } from "react-i18next";
import SearchSwitch from "./searchSwitch/searchSwitch";
import SearchDateRange from "./searchDateRange/searchDateRange";
import SearchAutocomplete from "./searchAutocomplete/searchAutocomplete";

function Search() {
  const { t } = useTranslation();
  const { isWidgetActive } = useWidget();

  return (
    <Box sx={SearchStyle.root}>
      <Typography sx={SearchStyle.typographyBoldBlue}>
        {t("common:searchTitle")}
      </Typography>
      <SearchDateRange />
      <SearchAutocomplete />
      {!isWidgetActive && <SearchSwitch />}
    </Box>
  );
}

export default Search;
