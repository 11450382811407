import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ContentStyles } from "../../utils/hooks/styles/contentStyles";
import ReactMarkdown from "react-markdown";
import {
  TextField,
  FormControl,
  Button,
  Box,
  Stack,
  InputLabel,
  Input,
  Typography,
} from "@mui/material";
import MarkdownDeFile from "../../assets/markdown/vendor.de.md";
import MarkdownEnFile from "../../assets/markdown/vendor.en.md";
import { useLocation } from "react-router";
import queryString from "query-string";
import CloseButton from "./closeButton";

const ContactForm = () => {
  const { t } = useTranslation(["common"]);

  return (
    <form
      method="POST"
      name="fa-form-1"
      action="https://webhook.frontapp.com/forms/68165134e46d2dbf75fd/mTJPyP1LNzlEVygeJ_5Gy3Lym3o0ImENz4rstEjXvFVZX97NCGLmUk_sBsvCtcMr1Knvlo36uizKvqBbjW8ISTq_q0gsvAAI5FtlJvo4Q8bPnycMIJaFHMglQdjNVg"
      encType="multipart/form-data"
      acceptCharset="utf-8"
    >
      <FormControl fullWidth={true} sx={ContentStyles.contact}>
        <TextField
          id="name"
          label={`${t("firstName")} ${t("lastName")}`}
          type="text"
          name="name"
          variant="outlined"
          fullWidth={true}
        />
        <TextField
          id="email"
          label="E-Mail"
          type="email"
          name="email"
          variant="outlined"
          fullWidth={true}
        />
        <TextField
          id="body"
          label={t("message")}
          multiline
          rows={6}
          name="body"
          defaultValue=""
          variant="outlined"
        />
        <FormControl variant="standard">
          <InputLabel htmlFor="attachment-field">{t("attachment")}</InputLabel>
          <Input id="attachment-field" type="file" name="attachment" />
        </FormControl>
        <br />
        <Button variant="contained" color="primary" type="submit">
          {t("send")}
        </Button>
      </FormControl>
    </form>
  );
};
const Vendor = () => {
  const { search } = useLocation();
  const values = queryString.parse(search);
  const { i18n, t } = useTranslation(["common"]);
  const [text, setText] = useState("");

  useEffect(() => {
    const userLanguage = i18n.language;
    fetch(
      !userLanguage || userLanguage === "de" || userLanguage === "de-DE"
        ? MarkdownDeFile
        : MarkdownEnFile
    )
      .then((res) => res.text())
      .then((text) => setText(text));
  }, [i18n.language, i18n.languages]);

  const emailSuccess = values?.success !== undefined;
  const emailError = values?.error !== undefined;
  const displayMessages = emailSuccess || emailError;

  return (
    <Box sx={ContentStyles.root}>
      <Box sx={ContentStyles.content}>
        <Box sx={ContentStyles.helperText}>
          <Box sx={ContentStyles.background}>
            <CloseButton />
            {displayMessages ? (
              <Stack spacing={2} alignItems="center">
                {emailSuccess && (
                  <Box sx={ContentStyles.markDown}>
                    <Typography sx={ContentStyles.errorCode}>
                      {t("Success")}
                    </Typography>
                    <Typography sx={ContentStyles.helperText}>
                      {t("SuccessfullyReceivedYourEmail")}
                    </Typography>
                    <Typography sx={ContentStyles.helperText}>
                      {t("thankyou")}
                    </Typography>
                  </Box>
                )}
                {emailError && (
                  <Box sx={ContentStyles.markDown}>
                    <Typography sx={ContentStyles.errorCode}>
                      {t("Error")}
                    </Typography>
                    <Typography sx={ContentStyles.helperText}>
                      {t("Error Message")}
                    </Typography>
                    <Typography sx={ContentStyles.helperText}>
                      {t((values?.message as string) || "")}
                    </Typography>
                  </Box>
                )}
              </Stack>
            ) : (
              <Stack spacing={2} alignItems="center">
                <Box sx={ContentStyles.markDown}>
                  <ReactMarkdown>{text}</ReactMarkdown>
                </Box>
                <ContactForm />
              </Stack>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Vendor;
