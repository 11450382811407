import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ContentStyles } from "../../utils/hooks/styles/contentStyles";
import ReactMarkdown from "react-markdown";
import MarkdownDeFile from "../../assets/markdown/about.de.md";
import MarkdownEnFile from "../../assets/markdown/about.en.md";
import { Box, Button } from "@mui/material";
import { useAppDispatch } from "../../store";
import {
  redirectRequest,
  toggleMapRequest,
} from "../../store/reducers/mainAppReducer";
import { setMapStateRequest } from "../../store/reducers/mapReducer";
import { EMapState } from "../../utils/models/EMapState";
import { useLocation } from "react-router";
import CloseButton from "./closeButton";

const About = () => {
  const { i18n, t } = useTranslation(["common"]);
  const [text, setText] = useState("");
  const dispatch = useAppDispatch();

  useEffect(() => {
    const userLanguage = i18n.language;
    fetch(
      !userLanguage || userLanguage === "de" || userLanguage === "de-DE"
        ? MarkdownDeFile
        : MarkdownEnFile
    )
      .then((res) => res.text())
      .then((text) => setText(text));
  }, [i18n.language, i18n.languages]);
  const location = useLocation();

  return (
    <>
      {location.pathname === "/static/headless/about" ? (
        <Box sx={ContentStyles.background}>
          <Box sx={ContentStyles.markDownAbout}>
            <ReactMarkdown>{text}</ReactMarkdown>
          </Box>
        </Box>
      ) : (
        <Box sx={ContentStyles.root}>
          <Box sx={ContentStyles.content}>
            <Box sx={ContentStyles.helperText}>
              <Box sx={ContentStyles.background}>
                <CloseButton />
                <Box sx={ContentStyles.markDownAbout}>
                  <ReactMarkdown>{text}</ReactMarkdown>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={ContentStyles.button}
                    onClick={() => {
                      dispatch(redirectRequest({ path: "/" }));
                      dispatch(toggleMapRequest({ toggle: true }));
                      dispatch(
                        setMapStateRequest({
                          mapState: EMapState.ALL_CITIES,
                        })
                      );
                    }}
                  >
                    {t("backToStart")}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default About;
