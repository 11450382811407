export enum MetaDataSource {
  WEBSITE = "WEBSITE",
  PARTNER = "PARTNER",
  /** @deprecated */
  APP = "APP",
  WIDGET = "WIDGET",
  BACK_OFFICE = "BACK_OFFICE",
  VENDOR_APP = "VENDOR_APP",
  CUSTOMER_APP = "CUSTOMER_APP",
  NOT_DEFINED = "NOT_DEFINED",
}
