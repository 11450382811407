import { createSelector } from "reselect";
import {
  getBeachChairById,
  getSelectedBeachChairDetailedInformationById,
} from "../reducers/availabilityReducer";
import { AppState } from "../reducers/rootReducer";

const getAppState = (state: AppState) => state;
const getFilters = (state: AppState) => state.availability.filters;
const getSections = (state: AppState) => state.availability.sections;
const getTimeline = (state: AppState) => state.availability.timeline;
const getSelectedBeachChair = (state: AppState) =>
  state.availability.selectedBeachChair;
const getSelectedBeachChairTrgger = (state: AppState) =>
  state.availability.selectedBeachChairTrigger;
const getSelectedRow = (state: AppState) => state.availability.selectedRow;
const getSelectedBeachChairModelRate = (state: AppState) =>
  state.availability.selectedBeachChairModelRate;
const getRowIdSubSection = (state: AppState) =>
  state.availability.rowIdSubSection;
const getPending = (state: AppState) => state.availability.pending;

export const getFiltersSelector = createSelector(
  getFilters,
  (filters) => filters
);

export const getSectionsSelector = createSelector(
  getSections,
  (sections) => sections
);

export const getTimelineSelector = createSelector(
  getTimeline,
  (timeline) => timeline
);

export const getSelectedBeachChairSelector = createSelector(
  getSelectedBeachChair,
  (selectedBeachChair) => selectedBeachChair
);

export const getSelectedBeachChairTriggerSelector = createSelector(
  getSelectedBeachChairTrgger,
  (trigger) => trigger
);

export const selectedBeachChairDetailedInformationSelector = (
  beachChairId?: number
) =>
  createSelector(getAppState, (state) =>
    beachChairId
      ? getSelectedBeachChairDetailedInformationById(state, beachChairId)
      : undefined
  );

export const getSelectedRowSelector = createSelector(
  getSelectedRow,
  (row) => row
);

export const getSelectedBeachChairModelRateSelector = createSelector(
  getSelectedBeachChairModelRate,
  (selectedBeachChair) => selectedBeachChair
);

export const getRowIdSubSectionSelector = createSelector(
  getRowIdSubSection,
  (rowId) => rowId
);

export const getPendingAvailabilitiesSelector = createSelector(
  getPending,
  (pending) => pending
);

export const getBeachChairByIdSelector = (id: number) =>
  createSelector(getAppState, (state) => {
    return getBeachChairById(state, id)!;
  });
