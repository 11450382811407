import {
  GetBeachRequestPayload,
  GetBeachSuccessPayload,
  BeachFailurePayload,
  SetBeachRequestPayload,
  ClearBeachRequestPayload,
} from "./../actionPayload/beachPayloads";
import { createEntityAdapter, createSlice, EntityState, PayloadAction } from "@reduxjs/toolkit";
import { BeachResponseDto } from "../../api-client/generated";
import { AppState } from "./rootReducer";

const beachAdapter = createEntityAdapter<BeachResponseDto>({
  selectId: (beach) => beach.id,
});

interface IBeachState {
  pending: boolean;
  beachAdapter: EntityState<BeachResponseDto>;
  beach?: BeachResponseDto;
  error: string;
}

const initialState: IBeachState = {
  pending: false,
  beachAdapter: beachAdapter.getInitialState({}),
  beach: undefined,
  error: "",
};

const beachnSlice = createSlice({
  name: "beach",
  initialState,
  reducers: {
    getBeachRequest(state, action: PayloadAction<GetBeachRequestPayload>) {
      state.pending = true;
    },
    getBeachSuccess(state, action: PayloadAction<GetBeachSuccessPayload>) {
      state.pending = false;
      beachAdapter.removeAll(state.beachAdapter);
      beachAdapter.addMany(state.beachAdapter, action.payload.beaches);
    },
    setBeachRequest(state, action: PayloadAction<SetBeachRequestPayload>) {
      state.beach = action.payload.beach;
    },
    clearBeachRequest(state, action: PayloadAction<ClearBeachRequestPayload>) {
      state.beach = undefined;
    },
    beachFailure(state, action: PayloadAction<BeachFailurePayload>) {
      state.pending = false;
      state.error = action.payload.error;
    },
  },
});

export const {
  selectAll: getAllBeaches,
  selectById: getBeachById,
} = beachAdapter.getSelectors<AppState>((state) => state.beach.beachAdapter);


export const {
  getBeachRequest,
  getBeachSuccess,
  setBeachRequest,
  clearBeachRequest,
  beachFailure,
} = beachnSlice.actions;

export default beachnSlice.reducer;
