import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import { ResponseExtrasDto } from "../../api-client/generated";
import {
  ExtraPreBookingRequestPayload,
  ExtraFailurePayload,
  GetExtrasRequestPayload,
  GetExtrasSuccessPayload,
  AddPreBookingExtrasToBookingRequestPayload,
  SetSelectedExtraTypeRequestPayload,
} from "../actionPayload/extraPayloads";
import { AppState } from "./rootReducer";

export interface PreExtraBooking {
  id: number;
  count: number;
  price: number;
}

const extrasAdapter = createEntityAdapter<ResponseExtrasDto>({
  selectId: (extra) => extra.id,
});

const preBookingAdapter = createEntityAdapter<PreExtraBooking>({
  selectId: (extra) => extra.id,
});

interface IExtrasState {
  pending: boolean;
  extras: EntityState<ResponseExtrasDto>;
  preBooking: EntityState<PreExtraBooking>;
  selectedType: string;
  selectedCategory: string;
  error: string;
}

const initialState: IExtrasState = {
  pending: false,
  extras: extrasAdapter.getInitialState({}),
  preBooking: preBookingAdapter.getInitialState({}),
  selectedType: "FOOD",
  selectedCategory: "",
  error: "",
};

const extrasSlice = createSlice({
  name: "extras",
  initialState,
  reducers: {
    getExtrasRequest(state, action: PayloadAction<GetExtrasRequestPayload>) {
      state.pending = true;
    },
    getExtrasSuccess(state, action: PayloadAction<GetExtrasSuccessPayload>) {
      state.pending = false;
      extrasAdapter.removeAll(state.extras);
      extrasAdapter.addMany(state.extras, action.payload.extras);
    },
    addExtraToPreBookingRequest(
      state,
      action: PayloadAction<ExtraPreBookingRequestPayload>
    ) {
      preBookingAdapter.upsertOne(state.preBooking, {
        id: action.payload.extraId,
        count: action.payload.count + 1,
        price: (action.payload.count + 1) * action.payload.price,
      });
    },
    removeExtraFromPreBookingRequest(
      state,
      action: PayloadAction<ExtraPreBookingRequestPayload>
    ) {
      if (action.payload.count - 1 === 0) {
        preBookingAdapter.removeOne(state.preBooking, action.payload.extraId);
      } else {
        preBookingAdapter.upsertOne(state.preBooking, {
          id: action.payload.extraId,
          count: action.payload.count - 1,
          price: (action.payload.count - 1) * action.payload.price,
        });
      }
    },
    addPreBookingExtrasToBookingRequest(
      state,
      action: PayloadAction<AddPreBookingExtrasToBookingRequestPayload>
    ) {},
    addPreBookingExtrasToBookingSuccess(state) {
      preBookingAdapter.removeAll(state.preBooking);
    },
    resetPreBookingExtrasStateRequest(state) {
      preBookingAdapter.removeAll(state.preBooking);
    },
    setSelectedExtraTypeRequest(
      state,
      action: PayloadAction<SetSelectedExtraTypeRequestPayload>
    ) {
      state.selectedType = action.payload.option;
    },
    setSelectedExtraCategoryRequest(
      state,
      action: PayloadAction<SetSelectedExtraTypeRequestPayload>
    ) {
      state.selectedCategory = action.payload.option;
    },
    extraFailure(state, action: PayloadAction<ExtraFailurePayload>) {
      state.pending = false;
      state.error = action.payload.error;
    },
  },
});

export const { selectAll: getAllExtras } = extrasAdapter.getSelectors<AppState>(
  (state) => state.extras.extras
);

export const {
  selectAll: getAllPreBooking,
  selectById: getPreBookingExtraById,
} = preBookingAdapter.getSelectors<AppState>(
  (state) => state.extras.preBooking
);

export const {
  getExtrasRequest,
  getExtrasSuccess,
  addExtraToPreBookingRequest,
  removeExtraFromPreBookingRequest,
  addPreBookingExtrasToBookingRequest,
  addPreBookingExtrasToBookingSuccess,
  setSelectedExtraTypeRequest,
  setSelectedExtraCategoryRequest,
  resetPreBookingExtrasStateRequest,
  extraFailure,
} = extrasSlice.actions;

export default extrasSlice.reducer;
