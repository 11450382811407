/* tslint:disable */
/* eslint-disable */
/**
 * Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AdditionalDataDto
 */
export interface AdditionalDataDto {
    /**
     * 
     * @type {boolean}
     * @memberof AdditionalDataDto
     */
    marketing?: boolean;
}
/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    companyName?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    website?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    street: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    zip: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    country: string;
}
/**
 * 
 * @export
 * @interface AuthTokenDto
 */
export interface AuthTokenDto {
    /**
     * 
     * @type {string}
     * @memberof AuthTokenDto
     */
    token: string;
}
/**
 * 
 * @export
 * @interface BankDetails
 */
export interface BankDetails {
    /**
     * 
     * @type {string}
     * @memberof BankDetails
     */
    iban?: string;
    /**
     * 
     * @type {string}
     * @memberof BankDetails
     */
    bic?: string;
    /**
     * 
     * @type {string}
     * @memberof BankDetails
     */
    bankName?: string;
    /**
     * 
     * @type {string}
     * @memberof BankDetails
     */
    accountHolder?: string;
    /**
     * 
     * @type {string}
     * @memberof BankDetails
     */
    creditorIdentifier?: string;
}
/**
 * 
 * @export
 * @interface BatchUpdateBeachChairBodyDto
 */
export interface BatchUpdateBeachChairBodyDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof BatchUpdateBeachChairBodyDto
     */
    ids: Array<string>;
    /**
     * 
     * @type {object}
     * @memberof BatchUpdateBeachChairBodyDto
     */
    update: object;
}
/**
 * 
 * @export
 * @interface Beach
 */
export interface Beach {
    /**
     * 
     * @type {number}
     * @memberof Beach
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Beach
     */
    name: string;
}
/**
 * 
 * @export
 * @interface BeachChair
 */
export interface BeachChair {
    /**
     * 
     * @type {string}
     * @memberof BeachChair
     */
    model: string;
    /**
     * 
     * @type {string}
     * @memberof BeachChair
     */
    publicNumber: string;
}
/**
 * 
 * @export
 * @interface BeachChairAttributes
 */
export interface BeachChairAttributes {
    /**
     * 
     * @type {string}
     * @memberof BeachChairAttributes
     */
    color: string;
    /**
     * 
     * @type {boolean}
     * @memberof BeachChairAttributes
     */
    barrierFree: boolean;
    /**
     * 
     * @type {string}
     * @memberof BeachChairAttributes
     */
    condition: string;
}
/**
 * 
 * @export
 * @interface BeachChairAvailability
 */
export interface BeachChairAvailability {
    /**
     * 
     * @type {string}
     * @memberof BeachChairAvailability
     */
    date: string;
    /**
     * 
     * @type {number}
     * @memberof BeachChairAvailability
     */
    beachChairId: number;
    /**
     * 
     * @type {string}
     * @memberof BeachChairAvailability
     */
    beachChairAvailabilityName: string;
    /**
     * 
     * @type {number}
     * @memberof BeachChairAvailability
     */
    bookingId: number;
    /**
     * 
     * @type {boolean}
     * @memberof BeachChairAvailability
     */
    reserved: boolean;
}
/**
 * 
 * @export
 * @interface BeachChairBooking
 */
export interface BeachChairBooking {
    /**
     * 
     * @type {BeachChair}
     * @memberof BeachChairBooking
     */
    beachChair: BeachChair;
    /**
     * 
     * @type {string}
     * @memberof BeachChairBooking
     */
    section: string;
    /**
     * 
     * @type {string}
     * @memberof BeachChairBooking
     */
    row: string;
    /**
     * 
     * @type {string}
     * @memberof BeachChairBooking
     */
    start: string;
    /**
     * 
     * @type {object}
     * @memberof BeachChairBooking
     */
    extraInformation: object;
    /**
     * 
     * @type {string}
     * @memberof BeachChairBooking
     */
    end: string;
    /**
     * 
     * @type {number}
     * @memberof BeachChairBooking
     */
    price: number;
}
/**
 * 
 * @export
 * @interface BeachChairBookingFilterQuery
 */
export interface BeachChairBookingFilterQuery {
    /**
     * 
     * @type {number}
     * @memberof BeachChairBookingFilterQuery
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof BeachChairBookingFilterQuery
     */
    vendorId?: number;
    /**
     * 
     * @type {string}
     * @memberof BeachChairBookingFilterQuery
     */
    publicNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof BeachChairBookingFilterQuery
     */
    model?: string;
    /**
     * 
     * @type {string}
     * @memberof BeachChairBookingFilterQuery
     */
    customer?: string;
    /**
     * 
     * @type {number}
     * @memberof BeachChairBookingFilterQuery
     */
    sectionId?: number;
    /**
     * 
     * @type {number}
     * @memberof BeachChairBookingFilterQuery
     */
    rowId?: number;
    /**
     * 
     * @type {string}
     * @memberof BeachChairBookingFilterQuery
     */
    hasLock?: string;
    /**
     * 
     * @type {string}
     * @memberof BeachChairBookingFilterQuery
     */
    startFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof BeachChairBookingFilterQuery
     */
    startTo?: string;
    /**
     * 
     * @type {string}
     * @memberof BeachChairBookingFilterQuery
     */
    endFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof BeachChairBookingFilterQuery
     */
    endTo?: string;
    /**
     * 
     * @type {string}
     * @memberof BeachChairBookingFilterQuery
     */
    bookingDateFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof BeachChairBookingFilterQuery
     */
    bookingDateTo?: string;
    /**
     * 
     * @type {number}
     * @memberof BeachChairBookingFilterQuery
     */
    days?: number;
    /**
     * 
     * @type {string}
     * @memberof BeachChairBookingFilterQuery
     */
    discount?: string;
    /**
     * 
     * @type {string}
     * @memberof BeachChairBookingFilterQuery
     */
    bookingRef?: string;
    /**
     * 
     * @type {string}
     * @memberof BeachChairBookingFilterQuery
     */
    status?: string;
    /**
     * 
     * @type {number}
     * @memberof BeachChairBookingFilterQuery
     */
    price?: number;
}
/**
 * 
 * @export
 * @interface BeachChairBookingReportQuery
 */
export interface BeachChairBookingReportQuery {
    /**
     * 
     * @type {string}
     * @memberof BeachChairBookingReportQuery
     */
    publicNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof BeachChairBookingReportQuery
     */
    dateStart?: string;
    /**
     * 
     * @type {string}
     * @memberof BeachChairBookingReportQuery
     */
    date?: string;
    /**
     * 
     * @type {number}
     * @memberof BeachChairBookingReportQuery
     */
    locationId?: number;
    /**
     * 
     * @type {string}
     * @memberof BeachChairBookingReportQuery
     */
    bookingRef?: string;
}
/**
 * 
 * @export
 * @interface BeachChairBookingResponseDto
 */
export interface BeachChairBookingResponseDto {
    /**
     * 
     * @type {number}
     * @memberof BeachChairBookingResponseDto
     */
    beachChairId: number;
    /**
     * 
     * @type {string}
     * @memberof BeachChairBookingResponseDto
     */
    start: string;
    /**
     * 
     * @type {string}
     * @memberof BeachChairBookingResponseDto
     */
    end: string;
    /**
     * 
     * @type {number}
     * @memberof BeachChairBookingResponseDto
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof BeachChairBookingResponseDto
     */
    priceType?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BeachChairBookingResponseDto
     */
    discount?: boolean;
    /**
     * 
     * @type {number}
     * @memberof BeachChairBookingResponseDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof BeachChairBookingResponseDto
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof BeachChairBookingResponseDto
     */
    publicNumber: string;
    /**
     * 
     * @type {string}
     * @memberof BeachChairBookingResponseDto
     */
    model: string;
}
/**
 * 
 * @export
 * @interface BeachChairBookingsChunkResponseDto
 */
export interface BeachChairBookingsChunkResponseDto {
    /**
     * 
     * @type {Array<BeachChairBookingsResponseDto>}
     * @memberof BeachChairBookingsChunkResponseDto
     */
    items: Array<BeachChairBookingsResponseDto>;
    /**
     * 
     * @type {number}
     * @memberof BeachChairBookingsChunkResponseDto
     */
    count: number;
}
/**
 * 
 * @export
 * @interface BeachChairBookingsReportChunkResponseDto
 */
export interface BeachChairBookingsReportChunkResponseDto {
    /**
     * 
     * @type {Array<BeachChairBookingsReportChunkResponseItemDto>}
     * @memberof BeachChairBookingsReportChunkResponseDto
     */
    items: Array<BeachChairBookingsReportChunkResponseItemDto>;
    /**
     * 
     * @type {number}
     * @memberof BeachChairBookingsReportChunkResponseDto
     */
    count: number;
}
/**
 * 
 * @export
 * @interface BeachChairBookingsReportChunkResponseItemDto
 */
export interface BeachChairBookingsReportChunkResponseItemDto {
    /**
     * 
     * @type {number}
     * @memberof BeachChairBookingsReportChunkResponseItemDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof BeachChairBookingsReportChunkResponseItemDto
     */
    bookingId: number;
    /**
     * 
     * @type {number}
     * @memberof BeachChairBookingsReportChunkResponseItemDto
     */
    beachChairId: number;
    /**
     * 
     * @type {number}
     * @memberof BeachChairBookingsReportChunkResponseItemDto
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof BeachChairBookingsReportChunkResponseItemDto
     */
    publicNumber: string;
    /**
     * 
     * @type {string}
     * @memberof BeachChairBookingsReportChunkResponseItemDto
     */
    bookingRef: string;
    /**
     * 
     * @type {string}
     * @memberof BeachChairBookingsReportChunkResponseItemDto
     */
    start: string;
    /**
     * 
     * @type {string}
     * @memberof BeachChairBookingsReportChunkResponseItemDto
     */
    end: string;
    /**
     * 
     * @type {string}
     * @memberof BeachChairBookingsReportChunkResponseItemDto
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof BeachChairBookingsReportChunkResponseItemDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof BeachChairBookingsReportChunkResponseItemDto
     */
    model: string;
    /**
     * 
     * @type {boolean}
     * @memberof BeachChairBookingsReportChunkResponseItemDto
     */
    discount: boolean;
    /**
     * 
     * @type {object}
     * @memberof BeachChairBookingsReportChunkResponseItemDto
     */
    location: object;
}
/**
 * 
 * @export
 * @interface BeachChairBookingsResponseDto
 */
export interface BeachChairBookingsResponseDto {
    /**
     * 
     * @type {number}
     * @memberof BeachChairBookingsResponseDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof BeachChairBookingsResponseDto
     */
    bookingDate: string;
    /**
     * 
     * @type {number}
     * @memberof BeachChairBookingsResponseDto
     */
    bookingId: number;
    /**
     * 
     * @type {number}
     * @memberof BeachChairBookingsResponseDto
     */
    beachChairId: number;
    /**
     * 
     * @type {number}
     * @memberof BeachChairBookingsResponseDto
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof BeachChairBookingsResponseDto
     */
    publicNumber: string;
    /**
     * 
     * @type {string}
     * @memberof BeachChairBookingsResponseDto
     */
    bookingRef: string;
    /**
     * 
     * @type {string}
     * @memberof BeachChairBookingsResponseDto
     */
    comment: string;
    /**
     * 
     * @type {string}
     * @memberof BeachChairBookingsResponseDto
     */
    customerComment: string;
    /**
     * 
     * @type {string}
     * @memberof BeachChairBookingsResponseDto
     */
    start: string;
    /**
     * 
     * @type {string}
     * @memberof BeachChairBookingsResponseDto
     */
    end: string;
    /**
     * 
     * @type {string}
     * @memberof BeachChairBookingsResponseDto
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof BeachChairBookingsResponseDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof BeachChairBookingsResponseDto
     */
    model: string;
    /**
     * 
     * @type {boolean}
     * @memberof BeachChairBookingsResponseDto
     */
    discount: boolean;
    /**
     * 
     * @type {object}
     * @memberof BeachChairBookingsResponseDto
     */
    section: object;
    /**
     * 
     * @type {object}
     * @memberof BeachChairBookingsResponseDto
     */
    row: object;
    /**
     * 
     * @type {object}
     * @memberof BeachChairBookingsResponseDto
     */
    customer: object;
    /**
     * 
     * @type {number}
     * @memberof BeachChairBookingsResponseDto
     */
    lockNumber: number;
    /**
     * 
     * @type {object}
     * @memberof BeachChairBookingsResponseDto
     */
    location: object;
}
/**
 * 
 * @export
 * @interface BeachChairByIdParamDto
 */
export interface BeachChairByIdParamDto {
    /**
     * ID of the beach chair
     * @type {number}
     * @memberof BeachChairByIdParamDto
     */
    id: number;
}
/**
 * 
 * @export
 * @interface BeachChairChunkResponseDto
 */
export interface BeachChairChunkResponseDto {
    /**
     * 
     * @type {number}
     * @memberof BeachChairChunkResponseDto
     */
    count: number;
    /**
     * 
     * @type {Array<ExtendedBeachChairResponseDto>}
     * @memberof BeachChairChunkResponseDto
     */
    items: Array<ExtendedBeachChairResponseDto>;
    /**
     * 
     * @type {BeachChairFiltersResponseDto}
     * @memberof BeachChairChunkResponseDto
     */
    filter: BeachChairFiltersResponseDto;
}
/**
 * 
 * @export
 * @interface BeachChairDetails
 */
export interface BeachChairDetails {
    /**
     * 
     * @type {object}
     * @memberof BeachChairDetails
     */
    extraInformation?: object;
}
/**
 * 
 * @export
 * @interface BeachChairFiltersResponseDto
 */
export interface BeachChairFiltersResponseDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof BeachChairFiltersResponseDto
     */
    row: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BeachChairFiltersResponseDto
     */
    section: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BeachChairFiltersResponseDto
     */
    model: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BeachChairFiltersResponseDto
     */
    status: Array<string>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum BeachChairModel {
    _2SeatRecliner = '2_SEAT_RECLINER',
    _25SeatRecliner = '2.5_SEAT_RECLINER',
    BalticBeachChair = 'BALTIC_BEACH_CHAIR',
    _2SeatChair = '2_SEAT_CHAIR',
    _3SeatChair = '3_SEAT_CHAIR',
    FullRecliner = 'FULL_RECLINER',
    BeachChairBed = 'BEACH_CHAIR_BED',
    Daybed = 'DAYBED',
    XlBeachChair = 'XL_BEACH_CHAIR',
    Sauna = 'SAUNA',
    FamilyBeachChair = 'FAMILY_BEACH_CHAIR',
    XlBeachChairTurnable = 'XL_BEACH_CHAIR_TURNABLE',
    _2SeatChairTurnable = '2_SEAT_CHAIR_TURNABLE',
    Comfort = 'COMFORT',
    Wheelchair = 'WHEELCHAIR',
    Childbeachchair = 'CHILDBEACHCHAIR',
    XxlBeachChair = 'XXL_BEACH_CHAIR',
    DogBeachChair = 'DOG_BEACH_CHAIR',
    FkkBudje = 'FKK_BUDJE',
    Nordort = 'NORDORT',
    Premium = 'PREMIUM',
    Retro = 'RETRO'
}

/**
 * 
 * @export
 * @interface BeachChairResponseDto
 */
export interface BeachChairResponseDto {
    /**
     * 
     * @type {number}
     * @memberof BeachChairResponseDto
     */
    vendorId: number;
    /**
     * 
     * @type {string}
     * @memberof BeachChairResponseDto
     */
    model: string;
    /**
     * 
     * @type {number}
     * @memberof BeachChairResponseDto
     */
    sectionId: number;
    /**
     * 
     * @type {number}
     * @memberof BeachChairResponseDto
     */
    rowId: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof BeachChairResponseDto
     */
    stopSaleDates?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof BeachChairResponseDto
     */
    lockId?: number;
    /**
     * 
     * @type {string}
     * @memberof BeachChairResponseDto
     */
    publicNumber: string;
    /**
     * 
     * @type {string}
     * @memberof BeachChairResponseDto
     */
    what3words?: string;
    /**
     * 
     * @type {string}
     * @memberof BeachChairResponseDto
     */
    internalNote?: string;
    /**
     * 
     * @type {object}
     * @memberof BeachChairResponseDto
     */
    geoJson: object;
    /**
     * 
     * @type {string}
     * @memberof BeachChairResponseDto
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BeachChairResponseDto
     */
    afterHourBooking?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BeachChairResponseDto
     */
    buyingDate?: string;
    /**
     * 
     * @type {string}
     * @memberof BeachChairResponseDto
     */
    status: string;
    /**
     * 
     * @type {boolean}
     * @memberof BeachChairResponseDto
     */
    onlineBooking?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BeachChairResponseDto
     */
    seasonBeachChair?: boolean;
    /**
     * 
     * @type {BeachChairDetails}
     * @memberof BeachChairResponseDto
     */
    details?: BeachChairDetails;
    /**
     * 
     * @type {BeachChairAttributes}
     * @memberof BeachChairResponseDto
     */
    attributes?: BeachChairAttributes;
    /**
     * 
     * @type {Array<string>}
     * @memberof BeachChairResponseDto
     */
    images?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof BeachChairResponseDto
     */
    id: number;
}
/**
 * 
 * @export
 * @interface BeachChairTakenResponseDto
 */
export interface BeachChairTakenResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof BeachChairTakenResponseDto
     */
    available: boolean;
}
/**
 * 
 * @export
 * @interface BeachChairsDetails
 */
export interface BeachChairsDetails {
    /**
     * 
     * @type {number}
     * @memberof BeachChairsDetails
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof BeachChairsDetails
     */
    row: number;
    /**
     * 
     * @type {boolean}
     * @memberof BeachChairsDetails
     */
    available: boolean;
    /**
     * 
     * @type {object}
     * @memberof BeachChairsDetails
     */
    geoJson: object;
    /**
     * 
     * @type {string}
     * @memberof BeachChairsDetails
     */
    lockNumber: string;
    /**
     * 
     * @type {string}
     * @memberof BeachChairsDetails
     */
    model: string;
}
/**
 * 
 * @export
 * @interface BeachChairsFiltersDto
 */
export interface BeachChairsFiltersDto {
    /**
     * 
     * @type {number}
     * @memberof BeachChairsFiltersDto
     */
    vendorId?: number;
    /**
     * 
     * @type {number}
     * @memberof BeachChairsFiltersDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof BeachChairsFiltersDto
     */
    model?: string;
    /**
     * 
     * @type {number}
     * @memberof BeachChairsFiltersDto
     */
    sectionId?: number;
    /**
     * 
     * @type {number}
     * @memberof BeachChairsFiltersDto
     */
    rowId?: number;
    /**
     * 
     * @type {number}
     * @memberof BeachChairsFiltersDto
     */
    lockId?: number;
    /**
     * 
     * @type {string}
     * @memberof BeachChairsFiltersDto
     */
    publicNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof BeachChairsFiltersDto
     */
    what3words?: string;
    /**
     * 
     * @type {string}
     * @memberof BeachChairsFiltersDto
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BeachChairsFiltersDto
     */
    afterHourBooking?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BeachChairsFiltersDto
     */
    status?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BeachChairsFiltersDto
     */
    onlineBooking?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BeachChairsFiltersDto
     */
    seasonBeachChair?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof BeachChairsFiltersDto
     */
    images?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof BeachChairsFiltersDto
     */
    buyingDateFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof BeachChairsFiltersDto
     */
    buyingDateTo?: string;
    /**
     * 
     * @type {string}
     * @memberof BeachChairsFiltersDto
     */
    attributes_color?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BeachChairsFiltersDto
     */
    hasImages?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BeachChairsFiltersDto
     */
    hasInternalNote?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BeachChairsFiltersDto
     */
    hasStopSaleDates?: boolean;
}
/**
 * 
 * @export
 * @interface BeachChunkResponseDto
 */
export interface BeachChunkResponseDto {
    /**
     * Array of the beaches
     * @type {Array<BeachResponseDto>}
     * @memberof BeachChunkResponseDto
     */
    items: Array<BeachResponseDto>;
    /**
     * Total number of the available beaches
     * @type {number}
     * @memberof BeachChunkResponseDto
     */
    count: number;
}
/**
 * 
 * @export
 * @interface BeachResponseDto
 */
export interface BeachResponseDto {
    /**
     * 
     * @type {number}
     * @memberof BeachResponseDto
     */
    cityId?: number;
    /**
     * 
     * @type {number}
     * @memberof BeachResponseDto
     */
    regionId: number;
    /**
     * 
     * @type {string}
     * @memberof BeachResponseDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof BeachResponseDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof BeachResponseDto
     */
    shortDescription?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BeachResponseDto
     */
    active?: boolean;
    /**
     * 
     * @type {object}
     * @memberof BeachResponseDto
     */
    attributes?: object;
    /**
     * 
     * @type {object}
     * @memberof BeachResponseDto
     */
    geoJson: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof BeachResponseDto
     */
    images?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof BeachResponseDto
     */
    id: number;
}
/**
 * 
 * @export
 * @interface BeachesFiltersDto
 */
export interface BeachesFiltersDto {
    /**
     * 
     * @type {boolean}
     * @memberof BeachesFiltersDto
     */
    active?: boolean;
    /**
     * 
     * @type {number}
     * @memberof BeachesFiltersDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof BeachesFiltersDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof BeachesFiltersDto
     */
    shortDescription?: string;
    /**
     * 
     * @type {number}
     * @memberof BeachesFiltersDto
     */
    cityId?: number;
}
/**
 * 
 * @export
 * @interface BillingOptions
 */
export interface BillingOptions {
    /**
     * 
     * @type {string}
     * @memberof BillingOptions
     */
    gln: string;
    /**
     * 
     * @type {boolean}
     * @memberof BillingOptions
     */
    withhold_billing: boolean;
    /**
     * 
     * @type {string}
     * @memberof BillingOptions
     */
    bill_to_organization: string;
}
/**
 * 
 * @export
 * @interface BookingCommission
 */
export interface BookingCommission {
    /**
     * 
     * @type {number}
     * @memberof BookingCommission
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof BookingCommission
     */
    rate: number;
    /**
     * 
     * @type {number}
     * @memberof BookingCommission
     */
    lockTotal?: number;
}
/**
 * 
 * @export
 * @interface BookingDetailFiltersDto
 */
export interface BookingDetailFiltersDto {
    /**
     * 
     * @type {number}
     * @memberof BookingDetailFiltersDto
     */
    vendorId?: number;
    /**
     * 
     * @type {BookingStatus}
     * @memberof BookingDetailFiltersDto
     */
    status?: BookingStatus;
    /**
     * 
     * @type {number}
     * @memberof BookingDetailFiltersDto
     */
    customerId?: number;
    /**
     * 
     * @type {BookingPaymentMethod}
     * @memberof BookingDetailFiltersDto
     */
    paymentMethod?: BookingPaymentMethod;
    /**
     * 
     * @type {string}
     * @memberof BookingDetailFiltersDto
     */
    invoiceNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingDetailFiltersDto
     */
    cancellationNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof BookingDetailFiltersDto
     */
    id?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof BookingDetailFiltersDto
     */
    ids?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof BookingDetailFiltersDto
     */
    bookingRef?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingDetailFiltersDto
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingDetailFiltersDto
     */
    lastName?: string;
    /**
     * 
     * @type {number}
     * @memberof BookingDetailFiltersDto
     */
    totalPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof BookingDetailFiltersDto
     */
    from?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingDetailFiltersDto
     */
    to?: string;
    /**
     * 
     * @type {number}
     * @memberof BookingDetailFiltersDto
     */
    beachChairId?: number;
    /**
     * 
     * @type {string}
     * @memberof BookingDetailFiltersDto
     */
    bookingStart?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingDetailFiltersDto
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingDetailFiltersDto
     */
    discount?: string;
}
/**
 * 
 * @export
 * @interface BookingDetails
 */
export interface BookingDetails {
    /**
     * 
     * @type {number}
     * @memberof BookingDetails
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof BookingDetails
     */
    totalPrice: number;
    /**
     * 
     * @type {string}
     * @memberof BookingDetails
     */
    bookingRef: string;
    /**
     * 
     * @type {string}
     * @memberof BookingDetails
     */
    invoiceNumber: string;
    /**
     * 
     * @type {string}
     * @memberof BookingDetails
     */
    cancellationNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingDetails
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof BookingDetails
     */
    comment: string;
    /**
     * 
     * @type {string}
     * @memberof BookingDetails
     */
    customerComment: string;
    /**
     * 
     * @type {string}
     * @memberof BookingDetails
     */
    paymentMethod: string;
    /**
     * 
     * @type {Array<BeachChairBooking>}
     * @memberof BookingDetails
     */
    beachChairBookings: Array<BeachChairBooking>;
    /**
     * 
     * @type {Array<ExtraBooking>}
     * @memberof BookingDetails
     */
    extrasBookings: Array<ExtraBooking>;
    /**
     * 
     * @type {number}
     * @memberof BookingDetails
     */
    cancellationFee?: number;
    /**
     * 
     * @type {MetaData}
     * @memberof BookingDetails
     */
    metaData?: MetaData;
    /**
     * 
     * @type {string}
     * @memberof BookingDetails
     */
    tssQrCode?: string;
}
/**
 * 
 * @export
 * @interface BookingDetailsChunkResponseDto
 */
export interface BookingDetailsChunkResponseDto {
    /**
     * 
     * @type {Array<BookingDetailsResponseDto>}
     * @memberof BookingDetailsChunkResponseDto
     */
    items: Array<BookingDetailsResponseDto>;
    /**
     * 
     * @type {number}
     * @memberof BookingDetailsChunkResponseDto
     */
    count: number;
}
/**
 * 
 * @export
 * @interface BookingDetailsResponseDto
 */
export interface BookingDetailsResponseDto {
    /**
     * 
     * @type {BookingStatus}
     * @memberof BookingDetailsResponseDto
     */
    status: BookingStatus;
    /**
     * 
     * @type {number}
     * @memberof BookingDetailsResponseDto
     */
    totalPrice: number;
    /**
     * 
     * @type {string}
     * @memberof BookingDetailsResponseDto
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingDetailsResponseDto
     */
    customerComment?: string;
    /**
     * 
     * @type {number}
     * @memberof BookingDetailsResponseDto
     */
    customerId?: number;
    /**
     * 
     * @type {BookingPaymentMethod}
     * @memberof BookingDetailsResponseDto
     */
    paymentMethod: BookingPaymentMethod;
    /**
     * 
     * @type {string}
     * @memberof BookingDetailsResponseDto
     */
    invoiceNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingDetailsResponseDto
     */
    cancellationNumber?: string;
    /**
     * 
     * @type {BookingCommission}
     * @memberof BookingDetailsResponseDto
     */
    commission?: BookingCommission;
    /**
     * 
     * @type {MetaData}
     * @memberof BookingDetailsResponseDto
     */
    metaData?: MetaData;
    /**
     * 
     * @type {number}
     * @memberof BookingDetailsResponseDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof BookingDetailsResponseDto
     */
    vendorId: number;
    /**
     * 
     * @type {string}
     * @memberof BookingDetailsResponseDto
     */
    bookingRef: string;
    /**
     * 
     * @type {string}
     * @memberof BookingDetailsResponseDto
     */
    backOfficeUser: string;
    /**
     * 
     * @type {string}
     * @memberof BookingDetailsResponseDto
     */
    tssQrCode?: string;
    /**
     * 
     * @type {number}
     * @memberof BookingDetailsResponseDto
     */
    locationId: number;
    /**
     * 
     * @type {string}
     * @memberof BookingDetailsResponseDto
     */
    createdAt: string;
    /**
     * 
     * @type {Array<ExtendBeachChairBookingResponseDto>}
     * @memberof BookingDetailsResponseDto
     */
    beachChairBookings: Array<ExtendBeachChairBookingResponseDto>;
    /**
     * 
     * @type {Array<ExternalServiceBookingResponseDto>}
     * @memberof BookingDetailsResponseDto
     */
    externalServicesBookings: Array<ExternalServiceBookingResponseDto>;
    /**
     * 
     * @type {Array<ResponseExtrasBookingDto>}
     * @memberof BookingDetailsResponseDto
     */
    extras: Array<ResponseExtrasBookingDto>;
    /**
     * 
     * @type {CustomerResponseDto}
     * @memberof BookingDetailsResponseDto
     */
    customer: CustomerResponseDto;
    /**
     * 
     * @type {VendorResponseDto}
     * @memberof BookingDetailsResponseDto
     */
    vendor: VendorResponseDto;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum BookingPaymentMethod {
    BankTransfer = 'BANK_TRANSFER',
    Cash = 'CASH',
    Ec = 'EC',
    Cc = 'CC',
    Invoice = 'INVOICE',
    Paypal = 'PAYPAL',
    CustomSepa = 'CUSTOM_SEPA',
    OtherStripe = 'OTHER_STRIPE',
    DirectDebit = 'DIRECT_DEBIT',
    CardTerminal = 'CARD_TERMINAL',
    StripeBankTransfer = 'STRIPE_BANK_TRANSFER',
    StripeDirectDebit = 'STRIPE_DIRECT_DEBIT',
    StripeSofort = 'STRIPE_SOFORT',
    StripeCreditCard = 'STRIPE_CREDIT_CARD',
    StripePaypal = 'STRIPE_PAYPAL',
    StripePaymentLink = 'STRIPE_PAYMENT_LINK',
    ExternalPp = 'EXTERNAL_PP'
}

/**
 * 
 * @export
 * @interface BookingResponseDto
 */
export interface BookingResponseDto {
    /**
     * 
     * @type {BookingStatus}
     * @memberof BookingResponseDto
     */
    status: BookingStatus;
    /**
     * 
     * @type {number}
     * @memberof BookingResponseDto
     */
    totalPrice: number;
    /**
     * 
     * @type {string}
     * @memberof BookingResponseDto
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingResponseDto
     */
    customerComment?: string;
    /**
     * 
     * @type {number}
     * @memberof BookingResponseDto
     */
    customerId?: number;
    /**
     * 
     * @type {BookingPaymentMethod}
     * @memberof BookingResponseDto
     */
    paymentMethod: BookingPaymentMethod;
    /**
     * 
     * @type {string}
     * @memberof BookingResponseDto
     */
    invoiceNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingResponseDto
     */
    cancellationNumber?: string;
    /**
     * 
     * @type {BookingCommission}
     * @memberof BookingResponseDto
     */
    commission?: BookingCommission;
    /**
     * 
     * @type {MetaData}
     * @memberof BookingResponseDto
     */
    metaData?: MetaData;
    /**
     * 
     * @type {number}
     * @memberof BookingResponseDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof BookingResponseDto
     */
    vendorId: number;
    /**
     * 
     * @type {string}
     * @memberof BookingResponseDto
     */
    bookingRef: string;
    /**
     * 
     * @type {string}
     * @memberof BookingResponseDto
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof BookingResponseDto
     */
    backOfficeUser: string;
    /**
     * 
     * @type {number}
     * @memberof BookingResponseDto
     */
    locationId: number;
    /**
     * 
     * @type {Array<BeachChairBookingResponseDto>}
     * @memberof BookingResponseDto
     */
    beachChairBookings: Array<BeachChairBookingResponseDto>;
    /**
     * 
     * @type {Array<ExternalServiceBookingResponseDto>}
     * @memberof BookingResponseDto
     */
    externalServicesBookings: Array<ExternalServiceBookingResponseDto>;
    /**
     * 
     * @type {Array<ResponseExtrasBookingDto>}
     * @memberof BookingResponseDto
     */
    extras: Array<ResponseExtrasBookingDto>;
}
/**
 * 
 * @export
 * @interface BookingSearchResponseDto
 */
export interface BookingSearchResponseDto {
    /**
     * 
     * @type {number}
     * @memberof BookingSearchResponseDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof BookingSearchResponseDto
     */
    vendorId: number;
    /**
     * 
     * @type {string}
     * @memberof BookingSearchResponseDto
     */
    bookingRef: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum BookingStatus {
    Confirmed = 'CONFIRMED',
    Booked = 'BOOKED',
    Open = 'OPEN',
    Pending = 'PENDING',
    Canceled = 'CANCELED',
    Reserved = 'RESERVED',
    Expired = 'EXPIRED'
}

/**
 * 
 * @export
 * @interface Buyer
 */
export interface Buyer {
    /**
     * 
     * @type {string}
     * @memberof Buyer
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Buyer
     */
    type: string;
    /**
     * 
     * @type {Address}
     * @memberof Buyer
     */
    address: Address;
    /**
     * 
     * @type {string}
     * @memberof Buyer
     */
    vat_id_number: string;
    /**
     * 
     * @type {string}
     * @memberof Buyer
     */
    buyer_export_id: string;
}
/**
 * 
 * @export
 * @interface CancellationCondition
 */
export interface CancellationCondition {
    /**
     * 
     * @type {number}
     * @memberof CancellationCondition
     */
    percentOfAmountToRefund?: number;
    /**
     * 
     * @type {number}
     * @memberof CancellationCondition
     */
    fixedAmount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CancellationCondition
     */
    isReturningFee?: boolean;
}
/**
 * 
 * @export
 * @interface CancellationRule
 */
export interface CancellationRule {
    /**
     * 
     * @type {number}
     * @memberof CancellationRule
     */
    minHoursBeforeBookingStartDate: number;
    /**
     * 
     * @type {CancellationCondition}
     * @memberof CancellationRule
     */
    condition: CancellationCondition;
}
/**
 * 
 * @export
 * @interface CashPointClosingUser
 */
export interface CashPointClosingUser {
    /**
     * 
     * @type {string}
     * @memberof CashPointClosingUser
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof CashPointClosingUser
     */
    lastName: string;
}
/**
 * 
 * @export
 * @interface CashPointStatus
 */
export interface CashPointStatus {
    /**
     * 
     * @type {string}
     * @memberof CashPointStatus
     */
    registerState: string;
    /**
     * 
     * @type {string}
     * @memberof CashPointStatus
     */
    closingState: string;
    /**
     * 
     * @type {number}
     * @memberof CashPointStatus
     */
    cashBalance: number;
}
/**
 * 
 * @export
 * @interface CashPointWithdraw
 */
export interface CashPointWithdraw {
    /**
     * 
     * @type {number}
     * @memberof CashPointWithdraw
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof CashPointWithdraw
     */
    locationId: number;
}
/**
 * 
 * @export
 * @interface CashStatementDto
 */
export interface CashStatementDto {
    /**
     * 
     * @type {Array<FiskalyBusinessCaseDto>}
     * @memberof CashStatementDto
     */
    business_cases: Array<FiskalyBusinessCaseDto>;
    /**
     * 
     * @type {FiskalyCreateCashPointClosingCashStatementPaymentDto}
     * @memberof CashStatementDto
     */
    payment: FiskalyCreateCashPointClosingCashStatementPaymentDto;
}
/**
 * 
 * @export
 * @interface CashStatisticsReportDtoResponse
 */
export interface CashStatisticsReportDtoResponse {
    /**
     * 
     * @type {number}
     * @memberof CashStatisticsReportDtoResponse
     */
    total: number;
}
/**
 * 
 * @export
 * @interface CitiesFiltersDto
 */
export interface CitiesFiltersDto {
    /**
     * 
     * @type {number}
     * @memberof CitiesFiltersDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CitiesFiltersDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CitiesFiltersDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CitiesFiltersDto
     */
    shortDescription?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CitiesFiltersDto
     */
    active?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CitiesFiltersDto
     */
    regionId?: number;
}
/**
 * 
 * @export
 * @interface CityChunkResponseDto
 */
export interface CityChunkResponseDto {
    /**
     * 
     * @type {Array<CityResponseDto>}
     * @memberof CityChunkResponseDto
     */
    items: Array<CityResponseDto>;
    /**
     * 
     * @type {number}
     * @memberof CityChunkResponseDto
     */
    count: number;
}
/**
 * 
 * @export
 * @interface CityResponseDto
 */
export interface CityResponseDto {
    /**
     * 
     * @type {number}
     * @memberof CityResponseDto
     */
    regionId: number;
    /**
     * 
     * @type {string}
     * @memberof CityResponseDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CityResponseDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof CityResponseDto
     */
    shortDescription: string;
    /**
     * 
     * @type {boolean}
     * @memberof CityResponseDto
     */
    active?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CityResponseDto
     */
    images?: Array<string>;
    /**
     * 
     * @type {object}
     * @memberof CityResponseDto
     */
    geoJson: object;
    /**
     * 
     * @type {number}
     * @memberof CityResponseDto
     */
    id: number;
}
/**
 * 
 * @export
 * @interface CommissionFees
 */
export interface CommissionFees {
    /**
     * 
     * @type {number}
     * @memberof CommissionFees
     */
    offlineBookingFee?: number;
    /**
     * 
     * @type {number}
     * @memberof CommissionFees
     */
    onlineBookingFee?: number;
    /**
     * 
     * @type {number}
     * @memberof CommissionFees
     */
    lockFee?: number;
}
/**
 * 
 * @export
 * @interface ConfirmPasswordDto
 */
export interface ConfirmPasswordDto {
    /**
     * 
     * @type {string}
     * @memberof ConfirmPasswordDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ConfirmPasswordDto
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof ConfirmPasswordDto
     */
    code: string;
}
/**
 * 
 * @export
 * @interface ConfirmRegistrationDto
 */
export interface ConfirmRegistrationDto {
    /**
     * 
     * @type {string}
     * @memberof ConfirmRegistrationDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ConfirmRegistrationDto
     */
    code: string;
}
/**
 * 
 * @export
 * @interface CountryResponseDto
 */
export interface CountryResponseDto {
    /**
     * 
     * @type {number}
     * @memberof CountryResponseDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CountryResponseDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CountryResponseDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof CountryResponseDto
     */
    shortCode: string;
    /**
     * 
     * @type {string}
     * @memberof CountryResponseDto
     */
    currency: string;
}
/**
 * 
 * @export
 * @interface CreateBeachBodyDto
 */
export interface CreateBeachBodyDto {
    /**
     * 
     * @type {number}
     * @memberof CreateBeachBodyDto
     */
    cityId?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateBeachBodyDto
     */
    regionId: number;
    /**
     * 
     * @type {string}
     * @memberof CreateBeachBodyDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBeachBodyDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBeachBodyDto
     */
    shortDescription?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateBeachBodyDto
     */
    active?: boolean;
    /**
     * 
     * @type {object}
     * @memberof CreateBeachBodyDto
     */
    attributes?: object;
    /**
     * 
     * @type {object}
     * @memberof CreateBeachBodyDto
     */
    geoJson: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateBeachBodyDto
     */
    images?: Array<string>;
}
/**
 * 
 * @export
 * @interface CreateBeachChairBodyDto
 */
export interface CreateBeachChairBodyDto {
    /**
     * 
     * @type {number}
     * @memberof CreateBeachChairBodyDto
     */
    vendorId: number;
    /**
     * 
     * @type {string}
     * @memberof CreateBeachChairBodyDto
     */
    model: string;
    /**
     * 
     * @type {number}
     * @memberof CreateBeachChairBodyDto
     */
    sectionId: number;
    /**
     * 
     * @type {number}
     * @memberof CreateBeachChairBodyDto
     */
    rowId: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateBeachChairBodyDto
     */
    stopSaleDates?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CreateBeachChairBodyDto
     */
    lockId?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateBeachChairBodyDto
     */
    publicNumber: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBeachChairBodyDto
     */
    what3words?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBeachChairBodyDto
     */
    internalNote?: string;
    /**
     * 
     * @type {object}
     * @memberof CreateBeachChairBodyDto
     */
    geoJson: object;
    /**
     * 
     * @type {string}
     * @memberof CreateBeachChairBodyDto
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateBeachChairBodyDto
     */
    afterHourBooking?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateBeachChairBodyDto
     */
    buyingDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBeachChairBodyDto
     */
    status: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateBeachChairBodyDto
     */
    onlineBooking?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateBeachChairBodyDto
     */
    seasonBeachChair?: boolean;
    /**
     * 
     * @type {BeachChairDetails}
     * @memberof CreateBeachChairBodyDto
     */
    details?: BeachChairDetails;
    /**
     * 
     * @type {BeachChairAttributes}
     * @memberof CreateBeachChairBodyDto
     */
    attributes?: BeachChairAttributes;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateBeachChairBodyDto
     */
    images?: Array<string>;
}
/**
 * 
 * @export
 * @interface CreateBeachChairBookingBodyDto
 */
export interface CreateBeachChairBookingBodyDto {
    /**
     * 
     * @type {number}
     * @memberof CreateBeachChairBookingBodyDto
     */
    beachChairId: number;
    /**
     * 
     * @type {string}
     * @memberof CreateBeachChairBookingBodyDto
     */
    start: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBeachChairBookingBodyDto
     */
    end: string;
    /**
     * 
     * @type {number}
     * @memberof CreateBeachChairBookingBodyDto
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof CreateBeachChairBookingBodyDto
     */
    priceType?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateBeachChairBookingBodyDto
     */
    discount?: boolean;
}
/**
 * 
 * @export
 * @interface CreateCityBodyDto
 */
export interface CreateCityBodyDto {
    /**
     * 
     * @type {number}
     * @memberof CreateCityBodyDto
     */
    regionId: number;
    /**
     * 
     * @type {string}
     * @memberof CreateCityBodyDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCityBodyDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCityBodyDto
     */
    shortDescription: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCityBodyDto
     */
    active?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateCityBodyDto
     */
    images?: Array<string>;
    /**
     * 
     * @type {object}
     * @memberof CreateCityBodyDto
     */
    geoJson: object;
}
/**
 * 
 * @export
 * @interface CreateCustomerBodyDto
 */
export interface CreateCustomerBodyDto {
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerBodyDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerBodyDto
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerBodyDto
     */
    salutation: string;
    /**
     * 
     * @type {CustomerAddressNestedDto}
     * @memberof CreateCustomerBodyDto
     */
    address: CustomerAddressNestedDto;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerBodyDto
     */
    telephone: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerBodyDto
     */
    email: string;
    /**
     * 
     * @type {Languages}
     * @memberof CreateCustomerBodyDto
     */
    language?: Languages;
    /**
     * 
     * @type {BankDetails}
     * @memberof CreateCustomerBodyDto
     */
    bankDetails?: BankDetails;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCustomerBodyDto
     */
    _default?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerBodyDto
     */
    userId: string;
    /**
     * 
     * @type {StripeDetailsDto}
     * @memberof CreateCustomerBodyDto
     */
    stripe?: StripeDetailsDto;
    /**
     * 
     * @type {AdditionalDataDto}
     * @memberof CreateCustomerBodyDto
     */
    additionalData?: AdditionalDataDto;
}
/**
 * 
 * @export
 * @interface CreateExternalServicesDto
 */
export interface CreateExternalServicesDto {
    /**
     * 
     * @type {number}
     * @memberof CreateExternalServicesDto
     */
    locationId: number;
    /**
     * 
     * @type {number}
     * @memberof CreateExternalServicesDto
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof CreateExternalServicesDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateExternalServicesDto
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateExternalServicesDto
     */
    active: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateExternalServicesDto
     */
    vat?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateExternalServicesDto
     */
    type: string;
}
/**
 * 
 * @export
 * @interface CreateExtrasBookingBulkDto
 */
export interface CreateExtrasBookingBulkDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateExtrasBookingBulkDto
     */
    items: Array<string>;
}
/**
 * 
 * @export
 * @interface CreateExtrasBookingDto
 */
export interface CreateExtrasBookingDto {
    /**
     * 
     * @type {number}
     * @memberof CreateExtrasBookingDto
     */
    extraId: number;
    /**
     * 
     * @type {number}
     * @memberof CreateExtrasBookingDto
     */
    amount: number;
}
/**
 * 
 * @export
 * @interface CreateExtrasDto
 */
export interface CreateExtrasDto {
    /**
     * 
     * @type {number}
     * @memberof CreateExtrasDto
     */
    locationId: number;
    /**
     * 
     * @type {number}
     * @memberof CreateExtrasDto
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof CreateExtrasDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateExtrasDto
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateExtrasDto
     */
    vat?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateExtrasDto
     */
    type: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateExtrasDto
     */
    images?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CreateExtrasDto
     */
    onlineBooking: boolean;
}
/**
 * 
 * @export
 * @interface CreateItemsDto
 */
export interface CreateItemsDto {
    /**
     * 
     * @type {number}
     * @memberof CreateItemsDto
     */
    locationId: number;
    /**
     * 
     * @type {number}
     * @memberof CreateItemsDto
     */
    foodCategoryId: number;
    /**
     * 
     * @type {number}
     * @memberof CreateItemsDto
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof CreateItemsDto
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateItemsDto
     */
    active: boolean;
    /**
     * 
     * @type {object}
     * @memberof CreateItemsDto
     */
    ingredients: object;
    /**
     * 
     * @type {string}
     * @memberof CreateItemsDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateItemsDto
     */
    shortDescription?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateItemsDto
     */
    vat?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateItemsDto
     */
    images?: Array<string>;
}
/**
 * 
 * @export
 * @interface CreateLocationDto
 */
export interface CreateLocationDto {
    /**
     * Location id
     * @type {number}
     * @memberof CreateLocationDto
     */
    beachId: number;
    /**
     * Lead time
     * @type {number}
     * @memberof CreateLocationDto
     */
    leadTimeHours?: number;
    /**
     * Lead time limit hour, can be used instead of leadTimeHours
     * @type {string}
     * @memberof CreateLocationDto
     */
    leadTimeLimit?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLocationDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLocationDto
     */
    checkoutTime: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLocationDto
     */
    description: string;
    /**
     * 
     * @type {object}
     * @memberof CreateLocationDto
     */
    geoJson: object;
    /**
     * 
     * @type {string}
     * @memberof CreateLocationDto
     */
    seasonStart?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLocationDto
     */
    seasonEnd?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLocationDto
     */
    entranceName: string;
    /**
     * 
     * @type {object}
     * @memberof CreateLocationDto
     */
    terms?: object;
    /**
     * 
     * @type {object}
     * @memberof CreateLocationDto
     */
    priceText?: object;
    /**
     * 
     * @type {string}
     * @memberof CreateLocationDto
     */
    mapImageUrl: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateLocationDto
     */
    foodDelivery: boolean;
    /**
     * 
     * @type {LocationAddress}
     * @memberof CreateLocationDto
     */
    address: LocationAddress;
    /**
     * 
     * @type {Details}
     * @memberof CreateLocationDto
     */
    details: Details;
}
/**
 * 
 * @export
 * @interface CreateLockBodyDto
 */
export interface CreateLockBodyDto {
    /**
     * 
     * @type {string}
     * @memberof CreateLockBodyDto
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLockBodyDto
     */
    bcd: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLockBodyDto
     */
    mac?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLockBodyDto
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLockBodyDto
     */
    secondaryPassword?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateLockBodyDto
     */
    vendorId?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateLockBodyDto
     */
    status: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateLockBodyDto
     */
    opened: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateLockBodyDto
     */
    batteryLevel: number;
    /**
     * 
     * @type {NestedLockDetailsBodyDto}
     * @memberof CreateLockBodyDto
     */
    details: NestedLockDetailsBodyDto;
}
/**
 * 
 * @export
 * @interface CreatePriceDto
 */
export interface CreatePriceDto {
    /**
     * 
     * @type {number}
     * @memberof CreatePriceDto
     */
    vendorId: number;
    /**
     * 
     * @type {PriceRules}
     * @memberof CreatePriceDto
     */
    rules: PriceRules;
    /**
     * 
     * @type {string}
     * @memberof CreatePriceDto
     */
    bookingStart: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePriceDto
     */
    bookingEnd: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePriceDto
     */
    offerStart?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePriceDto
     */
    offerEnd?: string;
}
/**
 * 
 * @export
 * @interface CreateRegionBodyDto
 */
export interface CreateRegionBodyDto {
    /**
     * 
     * @type {number}
     * @memberof CreateRegionBodyDto
     */
    countryId: number;
    /**
     * 
     * @type {string}
     * @memberof CreateRegionBodyDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRegionBodyDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRegionBodyDto
     */
    shortDescription?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateRegionBodyDto
     */
    images?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CreateRegionBodyDto
     */
    active?: boolean;
    /**
     * 
     * @type {object}
     * @memberof CreateRegionBodyDto
     */
    geoJson: object;
}
/**
 * 
 * @export
 * @interface CreateRowBodyDto
 */
export interface CreateRowBodyDto {
    /**
     * 
     * @type {number}
     * @memberof CreateRowBodyDto
     */
    sectionId: number;
    /**
     * 
     * @type {string}
     * @memberof CreateRowBodyDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRowBodyDto
     */
    description: string;
    /**
     * 
     * @type {object}
     * @memberof CreateRowBodyDto
     */
    geoJson: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateRowBodyDto
     */
    beachChairOrder?: Array<string>;
}
/**
 * 
 * @export
 * @interface CreateSectionBodyDto
 */
export interface CreateSectionBodyDto {
    /**
     * 
     * @type {number}
     * @memberof CreateSectionBodyDto
     */
    beachId: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateSectionBodyDto
     */
    rowOrder?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateSectionBodyDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSectionBodyDto
     */
    seoDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSectionBodyDto
     */
    seoShortDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSectionBodyDto
     */
    description: string;
    /**
     * 
     * @type {object}
     * @memberof CreateSectionBodyDto
     */
    geoJson: object;
    /**
     * 
     * @type {object}
     * @memberof CreateSectionBodyDto
     */
    attributes: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateSectionBodyDto
     */
    images?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CreateSectionBodyDto
     */
    locationId?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateSectionBodyDto
     */
    beachPlan: string;
    /**
     * 
     * @type {number}
     * @memberof CreateSectionBodyDto
     */
    vendorId: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSectionBodyDto
     */
    active?: boolean;
}
/**
 * 
 * @export
 * @interface CreateUserBodyDto
 */
export interface CreateUserBodyDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUserBodyDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserBodyDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserBodyDto
     */
    lastName: string;
    /**
     * 
     * @type {Salutation}
     * @memberof CreateUserBodyDto
     */
    salutation: Salutation;
    /**
     * 
     * @type {number}
     * @memberof CreateUserBodyDto
     */
    vendorId: number;
    /**
     * 
     * @type {number}
     * @memberof CreateUserBodyDto
     */
    defaultLocationId?: number;
    /**
     * 
     * @type {object}
     * @memberof CreateUserBodyDto
     */
    customerDefaults?: object;
}
/**
 * 
 * @export
 * @interface CreateVendorDto
 */
export interface CreateVendorDto {
    /**
     * 
     * @type {Address}
     * @memberof CreateVendorDto
     */
    address: Address;
    /**
     * 
     * @type {Address}
     * @memberof CreateVendorDto
     */
    invoiceAddress?: Address;
    /**
     * 
     * @type {string}
     * @memberof CreateVendorDto
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateVendorDto
     */
    foodProvider: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateVendorDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateVendorDto
     */
    companyName: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateVendorDto
     */
    sectionOrder: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateVendorDto
     */
    allowedHosts: Array<string>;
    /**
     * 
     * @type {VDetails}
     * @memberof CreateVendorDto
     */
    details: VDetails;
    /**
     * 
     * @type {VendorConfiguration}
     * @memberof CreateVendorDto
     */
    configuration?: VendorConfiguration;
    /**
     * 
     * @type {boolean}
     * @memberof CreateVendorDto
     */
    activeAvailability: boolean;
    /**
     * 
     * @type {object}
     * @memberof CreateVendorDto
     */
    terms?: object;
}
/**
 * 
 * @export
 * @interface CustomerAddressNestedDto
 */
export interface CustomerAddressNestedDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerAddressNestedDto
     */
    street: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAddressNestedDto
     */
    zip: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAddressNestedDto
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAddressNestedDto
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerAddressNestedDto
     */
    additionalInfo: string;
}
/**
 * 
 * @export
 * @interface CustomerChunkResponseDto
 */
export interface CustomerChunkResponseDto {
    /**
     * 
     * @type {number}
     * @memberof CustomerChunkResponseDto
     */
    count: number;
    /**
     * 
     * @type {Array<CustomerResponseDto>}
     * @memberof CustomerChunkResponseDto
     */
    items: Array<CustomerResponseDto>;
}
/**
 * 
 * @export
 * @interface CustomerDetails
 */
export interface CustomerDetails {
    /**
     * 
     * @type {Address}
     * @memberof CustomerDetails
     */
    address: Address;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetails
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetails
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetails
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDetails
     */
    salutation: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerDetails
     */
    id: number;
}
/**
 * 
 * @export
 * @interface CustomerFiltersDto
 */
export interface CustomerFiltersDto {
    /**
     * 
     * @type {number}
     * @memberof CustomerFiltersDto
     */
    vendorId?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerFiltersDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerFiltersDto
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerFiltersDto
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerFiltersDto
     */
    lastName?: string;
    /**
     * 
     * @type {Languages}
     * @memberof CustomerFiltersDto
     */
    language?: Languages;
    /**
     * 
     * @type {string}
     * @memberof CustomerFiltersDto
     */
    telephone?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerFiltersDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerFiltersDto
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerFiltersDto
     */
    zip?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerFiltersDto
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerFiltersDto
     */
    country?: string;
}
/**
 * 
 * @export
 * @interface CustomerResponseDto
 */
export interface CustomerResponseDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerResponseDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerResponseDto
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerResponseDto
     */
    salutation: string;
    /**
     * 
     * @type {CustomerAddressNestedDto}
     * @memberof CustomerResponseDto
     */
    address: CustomerAddressNestedDto;
    /**
     * 
     * @type {string}
     * @memberof CustomerResponseDto
     */
    telephone: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerResponseDto
     */
    email: string;
    /**
     * 
     * @type {Languages}
     * @memberof CustomerResponseDto
     */
    language?: Languages;
    /**
     * 
     * @type {BankDetails}
     * @memberof CustomerResponseDto
     */
    bankDetails?: BankDetails;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerResponseDto
     */
    _default?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomerResponseDto
     */
    userId: string;
    /**
     * 
     * @type {StripeDetailsDto}
     * @memberof CustomerResponseDto
     */
    stripe?: StripeDetailsDto;
    /**
     * 
     * @type {AdditionalDataDto}
     * @memberof CustomerResponseDto
     */
    additionalData?: AdditionalDataDto;
    /**
     * 
     * @type {number}
     * @memberof CustomerResponseDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerResponseDto
     */
    vendorId: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerResponseDto
     */
    bookingCount?: number;
}
/**
 * 
 * @export
 * @interface DayRule
 */
export interface DayRule {
    /**
     * 
     * @type {number}
     * @memberof DayRule
     */
    minDays: number;
    /**
     * 
     * @type {number}
     * @memberof DayRule
     */
    maxDays: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof DayRule
     */
    conditions: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof DayRule
     */
    discount: boolean;
}
/**
 * 
 * @export
 * @interface Details
 */
export interface Details {
    /**
     * 
     * @type {Array<string>}
     * @memberof Details
     */
    openingHours: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Details
     */
    exceptionOpeningHours: Array<string>;
}
/**
 * 
 * @export
 * @interface DocumentsChunkResponseDto
 */
export interface DocumentsChunkResponseDto {
    /**
     * 
     * @type {Array<DocumentsResponseDto>}
     * @memberof DocumentsChunkResponseDto
     */
    items: Array<DocumentsResponseDto>;
    /**
     * 
     * @type {number}
     * @memberof DocumentsChunkResponseDto
     */
    count: number;
}
/**
 * 
 * @export
 * @interface DocumentsFiltersDto
 */
export interface DocumentsFiltersDto {
    /**
     * 
     * @type {string}
     * @memberof DocumentsFiltersDto
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentsFiltersDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentsFiltersDto
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentsFiltersDto
     */
    s3Key?: string;
    /**
     * 
     * @type {number}
     * @memberof DocumentsFiltersDto
     */
    vendorId?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentsFiltersDto
     */
    from?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentsFiltersDto
     */
    to?: string;
}
/**
 * 
 * @export
 * @interface DocumentsResponseDto
 */
export interface DocumentsResponseDto {
    /**
     * 
     * @type {string}
     * @memberof DocumentsResponseDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentsResponseDto
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentsResponseDto
     */
    s3Key: string;
    /**
     * 
     * @type {number}
     * @memberof DocumentsResponseDto
     */
    vendorId: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentsResponseDto
     */
    userId: string;
    /**
     * 
     * @type {object}
     * @memberof DocumentsResponseDto
     */
    details?: object;
    /**
     * 
     * @type {string}
     * @memberof DocumentsResponseDto
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentsResponseDto
     */
    userName: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentsResponseDto
     */
    url: string;
}
/**
 * 
 * @export
 * @interface DurationRule
 */
export interface DurationRule {
    /**
     * 
     * @type {string}
     * @memberof DurationRule
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof DurationRule
     */
    range: string;
    /**
     * 
     * @type {number}
     * @memberof DurationRule
     */
    minHours: number;
    /**
     * 
     * @type {boolean}
     * @memberof DurationRule
     */
    sameDay: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof DurationRule
     */
    conditions: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof DurationRule
     */
    discount: boolean;
}
/**
 * 
 * @export
 * @interface EmailBeachChair
 */
export interface EmailBeachChair {
    /**
     * 
     * @type {string}
     * @memberof EmailBeachChair
     */
    model: string;
    /**
     * 
     * @type {string}
     * @memberof EmailBeachChair
     */
    section: string;
    /**
     * 
     * @type {string}
     * @memberof EmailBeachChair
     */
    row: string;
    /**
     * 
     * @type {LocationResponseDto}
     * @memberof EmailBeachChair
     */
    location: LocationResponseDto;
    /**
     * 
     * @type {string}
     * @memberof EmailBeachChair
     */
    publicNumber: string;
    /**
     * 
     * @type {object}
     * @memberof EmailBeachChair
     */
    extraInformation: object;
}
/**
 * 
 * @export
 * @interface EmailBeachChairBooking
 */
export interface EmailBeachChairBooking {
    /**
     * 
     * @type {EmailBeachChair}
     * @memberof EmailBeachChairBooking
     */
    beachChair: EmailBeachChair;
    /**
     * 
     * @type {string}
     * @memberof EmailBeachChairBooking
     */
    start: string;
    /**
     * 
     * @type {string}
     * @memberof EmailBeachChairBooking
     */
    end: string;
}
/**
 * 
 * @export
 * @interface EmailBookingDetails
 */
export interface EmailBookingDetails {
    /**
     * 
     * @type {string}
     * @memberof EmailBookingDetails
     */
    bookingRef: string;
    /**
     * 
     * @type {string}
     * @memberof EmailBookingDetails
     */
    paymentMethod: string;
    /**
     * 
     * @type {string}
     * @memberof EmailBookingDetails
     */
    totalPrice: string;
    /**
     * 
     * @type {CustomerResponseDto}
     * @memberof EmailBookingDetails
     */
    customer: CustomerResponseDto;
    /**
     * 
     * @type {string}
     * @memberof EmailBookingDetails
     */
    customerComment: string;
    /**
     * 
     * @type {string}
     * @memberof EmailBookingDetails
     */
    status: string;
    /**
     * 
     * @type {Array<EmailBeachChairBooking>}
     * @memberof EmailBookingDetails
     */
    beachChairBookings: Array<EmailBeachChairBooking>;
    /**
     * 
     * @type {Array<ExtraBooking>}
     * @memberof EmailBookingDetails
     */
    extrasBookings: Array<ExtraBooking>;
    /**
     * 
     * @type {boolean}
     * @memberof EmailBookingDetails
     */
    isLock?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EmailBookingDetails
     */
    createdAt: string;
}
/**
 * 
 * @export
 * @interface ExtendBeachChairBookingResponseDto
 */
export interface ExtendBeachChairBookingResponseDto {
    /**
     * 
     * @type {string}
     * @memberof ExtendBeachChairBookingResponseDto
     */
    start: string;
    /**
     * 
     * @type {string}
     * @memberof ExtendBeachChairBookingResponseDto
     */
    end: string;
    /**
     * 
     * @type {number}
     * @memberof ExtendBeachChairBookingResponseDto
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof ExtendBeachChairBookingResponseDto
     */
    priceType?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ExtendBeachChairBookingResponseDto
     */
    discount?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ExtendBeachChairBookingResponseDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ExtendBeachChairBookingResponseDto
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof ExtendBeachChairBookingResponseDto
     */
    publicNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ExtendBeachChairBookingResponseDto
     */
    model: string;
    /**
     * 
     * @type {ExtendedBeachChairResponseDto}
     * @memberof ExtendBeachChairBookingResponseDto
     */
    beachChair: ExtendedBeachChairResponseDto;
}
/**
 * 
 * @export
 * @interface ExtendedBeachChairResponseDto
 */
export interface ExtendedBeachChairResponseDto {
    /**
     * 
     * @type {number}
     * @memberof ExtendedBeachChairResponseDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ExtendedBeachChairResponseDto
     */
    publicNumber: string;
    /**
     * 
     * @type {object}
     * @memberof ExtendedBeachChairResponseDto
     */
    geoJson: object;
    /**
     * 
     * @type {boolean}
     * @memberof ExtendedBeachChairResponseDto
     */
    afterHourBooking: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExtendedBeachChairResponseDto
     */
    images?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExtendedBeachChairResponseDto
     */
    stopSaleDates?: Array<string>;
    /**
     * 
     * @type {LockPublicResponseDto}
     * @memberof ExtendedBeachChairResponseDto
     */
    lock: LockPublicResponseDto;
    /**
     * 
     * @type {string}
     * @memberof ExtendedBeachChairResponseDto
     */
    model: string;
    /**
     * 
     * @type {boolean}
     * @memberof ExtendedBeachChairResponseDto
     */
    onlineBooking: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ExtendedBeachChairResponseDto
     */
    seasonBeachChair: boolean;
    /**
     * 
     * @type {LocationResponseDto}
     * @memberof ExtendedBeachChairResponseDto
     */
    location: LocationResponseDto;
    /**
     * 
     * @type {FilterOptionDto}
     * @memberof ExtendedBeachChairResponseDto
     */
    section: FilterOptionDto;
    /**
     * 
     * @type {FilterOptionDto}
     * @memberof ExtendedBeachChairResponseDto
     */
    row: FilterOptionDto;
    /**
     * 
     * @type {string}
     * @memberof ExtendedBeachChairResponseDto
     */
    status: string;
    /**
     * 
     * @type {FilterOptionDto}
     * @memberof ExtendedBeachChairResponseDto
     */
    vendor: FilterOptionDto;
    /**
     * 
     * @type {BeachChairAttributes}
     * @memberof ExtendedBeachChairResponseDto
     */
    attributes: BeachChairAttributes;
    /**
     * 
     * @type {string}
     * @memberof ExtendedBeachChairResponseDto
     */
    buyingDate: string;
    /**
     * 
     * @type {string}
     * @memberof ExtendedBeachChairResponseDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof ExtendedBeachChairResponseDto
     */
    internalNote: string;
    /**
     * 
     * @type {BeachChairDetails}
     * @memberof ExtendedBeachChairResponseDto
     */
    details: BeachChairDetails;
}
/**
 * 
 * @export
 * @interface ExternalServiceBookingResponseDto
 */
export interface ExternalServiceBookingResponseDto {
    /**
     * 
     * @type {number}
     * @memberof ExternalServiceBookingResponseDto
     */
    externalServicesId: number;
    /**
     * 
     * @type {number}
     * @memberof ExternalServiceBookingResponseDto
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof ExternalServiceBookingResponseDto
     */
    activationDate: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalServiceBookingResponseDto
     */
    expirationDate: string;
    /**
     * 
     * @type {number}
     * @memberof ExternalServiceBookingResponseDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ExternalServiceBookingResponseDto
     */
    status: string;
}
/**
 * 
 * @export
 * @interface ExternalServicesChunkResponseDto
 */
export interface ExternalServicesChunkResponseDto {
    /**
     * 
     * @type {Array<ResponseExternalServicesDto>}
     * @memberof ExternalServicesChunkResponseDto
     */
    items: Array<ResponseExternalServicesDto>;
    /**
     * 
     * @type {number}
     * @memberof ExternalServicesChunkResponseDto
     */
    count: number;
}
/**
 * 
 * @export
 * @interface Extra
 */
export interface Extra {
    /**
     * 
     * @type {string}
     * @memberof Extra
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof Extra
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof Extra
     */
    price: number;
    /**
     * 
     * @type {number}
     * @memberof Extra
     */
    vat: number;
}
/**
 * 
 * @export
 * @interface ExtraBooking
 */
export interface ExtraBooking {
    /**
     * 
     * @type {Extra}
     * @memberof ExtraBooking
     */
    extra: Extra;
    /**
     * 
     * @type {number}
     * @memberof ExtraBooking
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof ExtraBooking
     */
    totalPrice: number;
}
/**
 * 
 * @export
 * @interface ExtraText
 */
export interface ExtraText {
    /**
     * 
     * @type {object}
     * @memberof ExtraText
     */
    emailText?: object;
    /**
     * 
     * @type {object}
     * @memberof ExtraText
     */
    invoiceText?: object;
    /**
     * 
     * @type {object}
     * @memberof ExtraText
     */
    cancellationText?: object;
}
/**
 * 
 * @export
 * @interface ExtrasChunkResponseDto
 */
export interface ExtrasChunkResponseDto {
    /**
     * 
     * @type {Array<ResponseExtrasDto>}
     * @memberof ExtrasChunkResponseDto
     */
    items: Array<ResponseExtrasDto>;
    /**
     * 
     * @type {number}
     * @memberof ExtrasChunkResponseDto
     */
    count: number;
}
/**
 * 
 * @export
 * @interface ExtrasFiltersDto
 */
export interface ExtrasFiltersDto {
    /**
     * 
     * @type {number}
     * @memberof ExtrasFiltersDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ExtrasFiltersDto
     */
    vendorId?: number;
    /**
     * 
     * @type {string}
     * @memberof ExtrasFiltersDto
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof ExtrasFiltersDto
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof ExtrasFiltersDto
     */
    locationId?: number;
    /**
     * 
     * @type {string}
     * @memberof ExtrasFiltersDto
     */
    type?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ExtrasFiltersDto
     */
    onlineBooking?: boolean;
}
/**
 * 
 * @export
 * @interface FilterChairDto
 */
export interface FilterChairDto {
    /**
     * 
     * @type {Array<SectionFilterDto>}
     * @memberof FilterChairDto
     */
    sections: Array<SectionFilterDto>;
    /**
     * 
     * @type {Array<FilterOptionDto>}
     * @memberof FilterChairDto
     */
    locks: Array<FilterOptionDto>;
    /**
     * 
     * @type {Array<FilterOptionDto>}
     * @memberof FilterChairDto
     */
    unassignedLocks: Array<FilterOptionDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FilterChairDto
     */
    models: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FilterChairDto
     */
    statuses: Array<string>;
}
/**
 * 
 * @export
 * @interface FilterOptionDto
 */
export interface FilterOptionDto {
    /**
     * 
     * @type {number}
     * @memberof FilterOptionDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof FilterOptionDto
     */
    value: string;
}
/**
 * 
 * @export
 * @interface FilterOptionDtoDto
 */
export interface FilterOptionDtoDto {
    /**
     * 
     * @type {number}
     * @memberof FilterOptionDtoDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof FilterOptionDtoDto
     */
    name: string;
}
/**
 * 
 * @export
 * @interface FiltersResponseDtoClass
 */
export interface FiltersResponseDtoClass {
    /**
     * 
     * @type {FilterChairDto}
     * @memberof FiltersResponseDtoClass
     */
    filter: FilterChairDto;
}
/**
 * 
 * @export
 * @interface FiskalyAmountPerVatIdDto
 */
export interface FiskalyAmountPerVatIdDto {
    /**
     * 
     * @type {number}
     * @memberof FiskalyAmountPerVatIdDto
     */
    incl_vat: number;
    /**
     * 
     * @type {number}
     * @memberof FiskalyAmountPerVatIdDto
     */
    excl_vat: number;
    /**
     * 
     * @type {number}
     * @memberof FiskalyAmountPerVatIdDto
     */
    vat: number;
    /**
     * 
     * @type {number}
     * @memberof FiskalyAmountPerVatIdDto
     */
    vat_definition_export_id: number;
}
/**
 * 
 * @export
 * @interface FiskalyBusinessCaseDto
 */
export interface FiskalyBusinessCaseDto {
    /**
     * 
     * @type {string}
     * @memberof FiskalyBusinessCaseDto
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof FiskalyBusinessCaseDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof FiskalyBusinessCaseDto
     */
    purchaser_agency_id: string;
    /**
     * 
     * @type {Array<FiskalyAmountPerVatIdDto>}
     * @memberof FiskalyBusinessCaseDto
     */
    amounts_per_vat_id: Array<FiskalyAmountPerVatIdDto>;
}
/**
 * 
 * @export
 * @interface FiskalyCashAmountsByCurrencyDto
 */
export interface FiskalyCashAmountsByCurrencyDto {
    /**
     * 
     * @type {string}
     * @memberof FiskalyCashAmountsByCurrencyDto
     */
    currency_code: string;
    /**
     * 
     * @type {number}
     * @memberof FiskalyCashAmountsByCurrencyDto
     */
    amount: number;
}
/**
 * 
 * @export
 * @interface FiskalyCashPointDetailResponseDto
 */
export interface FiskalyCashPointDetailResponseDto {
    /**
     * 
     * @type {FiskalyCreateCashPointClosingDetailResponseDto}
     * @memberof FiskalyCashPointDetailResponseDto
     */
    cashPointClosing: FiskalyCreateCashPointClosingDetailResponseDto;
    /**
     * 
     * @type {FiskalyCreateOrganizationDto}
     * @memberof FiskalyCashPointDetailResponseDto
     */
    organization: FiskalyCreateOrganizationDto;
    /**
     * 
     * @type {LocationResponseDto}
     * @memberof FiskalyCashPointDetailResponseDto
     */
    location: LocationResponseDto;
    /**
     * 
     * @type {CashPointClosingUser}
     * @memberof FiskalyCashPointDetailResponseDto
     */
    user: CashPointClosingUser;
}
/**
 * 
 * @export
 * @interface FiskalyCashPointTransactionDto
 */
export interface FiskalyCashPointTransactionDto {
    /**
     * 
     * @type {FiskalyCashPointTransactionHeadDto}
     * @memberof FiskalyCashPointTransactionDto
     */
    head: FiskalyCashPointTransactionHeadDto;
    /**
     * 
     * @type {FiskalyCashRegistryTransactionDataDto}
     * @memberof FiskalyCashPointTransactionDto
     */
    data: FiskalyCashRegistryTransactionDataDto;
    /**
     * 
     * @type {object}
     * @memberof FiskalyCashPointTransactionDto
     */
    security: object;
}
/**
 * 
 * @export
 * @interface FiskalyCashPointTransactionHeadDto
 */
export interface FiskalyCashPointTransactionHeadDto {
    /**
     * 
     * @type {string}
     * @memberof FiskalyCashPointTransactionHeadDto
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof FiskalyCashPointTransactionHeadDto
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof FiskalyCashPointTransactionHeadDto
     */
    storno: boolean;
    /**
     * 
     * @type {number}
     * @memberof FiskalyCashPointTransactionHeadDto
     */
    number: number;
    /**
     * 
     * @type {number}
     * @memberof FiskalyCashPointTransactionHeadDto
     */
    timestamp_start: number;
    /**
     * 
     * @type {number}
     * @memberof FiskalyCashPointTransactionHeadDto
     */
    timestamp_end: number;
    /**
     * 
     * @type {User}
     * @memberof FiskalyCashPointTransactionHeadDto
     */
    user: User;
    /**
     * 
     * @type {Buyer}
     * @memberof FiskalyCashPointTransactionHeadDto
     */
    buyer: Buyer;
    /**
     * 
     * @type {Array<FiskalyReferenceBillDto>}
     * @memberof FiskalyCashPointTransactionHeadDto
     */
    references: Array<FiskalyReferenceBillDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FiskalyCashPointTransactionHeadDto
     */
    allocation_groups: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FiskalyCashPointTransactionHeadDto
     */
    tx_id: string;
    /**
     * 
     * @type {string}
     * @memberof FiskalyCashPointTransactionHeadDto
     */
    transaction_export_id: string;
    /**
     * 
     * @type {string}
     * @memberof FiskalyCashPointTransactionHeadDto
     */
    closing_client_id: string;
}
/**
 * 
 * @export
 * @interface FiskalyCashRegisterEntityResponseDto
 */
export interface FiskalyCashRegisterEntityResponseDto {
    /**
     * 
     * @type {number}
     * @memberof FiskalyCashRegisterEntityResponseDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof FiskalyCashRegisterEntityResponseDto
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof FiskalyCashRegisterEntityResponseDto
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof FiskalyCashRegisterEntityResponseDto
     */
    deleted_at: string;
    /**
     * 
     * @type {number}
     * @memberof FiskalyCashRegisterEntityResponseDto
     */
    locationId: number;
    /**
     * 
     * @type {string}
     * @memberof FiskalyCashRegisterEntityResponseDto
     */
    clientId: string;
    /**
     * 
     * @type {number}
     * @memberof FiskalyCashRegisterEntityResponseDto
     */
    lastClosingNumber: number;
    /**
     * 
     * @type {string}
     * @memberof FiskalyCashRegisterEntityResponseDto
     */
    lastClosingId: string;
    /**
     * 
     * @type {number}
     * @memberof FiskalyCashRegisterEntityResponseDto
     */
    lastReceiptNumber: number;
    /**
     * 
     * @type {string}
     * @memberof FiskalyCashRegisterEntityResponseDto
     */
    lastOpenDate: string;
    /**
     * 
     * @type {number}
     * @memberof FiskalyCashRegisterEntityResponseDto
     */
    firstTransactionId: number;
    /**
     * 
     * @type {string}
     * @memberof FiskalyCashRegisterEntityResponseDto
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof FiskalyCashRegisterEntityResponseDto
     */
    currentUserId: string;
    /**
     * 
     * @type {FiskalyCreateCashPointClosingResponseDto}
     * @memberof FiskalyCashRegisterEntityResponseDto
     */
    fiskalyData: FiskalyCreateCashPointClosingResponseDto;
    /**
     * 
     * @type {number}
     * @memberof FiskalyCashRegisterEntityResponseDto
     */
    cashBalance: number;
    /**
     * 
     * @type {object}
     * @memberof FiskalyCashRegisterEntityResponseDto
     */
    currentUser: object;
}
/**
 * 
 * @export
 * @interface FiskalyCashRegistryTransactionDataDto
 */
export interface FiskalyCashRegistryTransactionDataDto {
    /**
     * 
     * @type {number}
     * @memberof FiskalyCashRegistryTransactionDataDto
     */
    full_amount_incl_vat: number;
    /**
     * 
     * @type {Array<FiskalyPaymentTypesDto>}
     * @memberof FiskalyCashRegistryTransactionDataDto
     */
    payment_types: Array<FiskalyPaymentTypesDto>;
    /**
     * 
     * @type {Array<FiskalyAmountPerVatIdDto>}
     * @memberof FiskalyCashRegistryTransactionDataDto
     */
    amounts_per_vat_id: Array<FiskalyAmountPerVatIdDto>;
    /**
     * 
     * @type {string}
     * @memberof FiskalyCashRegistryTransactionDataDto
     */
    notes: string;
    /**
     * 
     * @type {Array<FiskalyCashRegistryTransactionLineDto>}
     * @memberof FiskalyCashRegistryTransactionDataDto
     */
    lines: Array<FiskalyCashRegistryTransactionLineDto>;
}
/**
 * 
 * @export
 * @interface FiskalyCashRegistryTransactionLineDto
 */
export interface FiskalyCashRegistryTransactionLineDto {
    /**
     * 
     * @type {FiskalyBusinessCaseDto}
     * @memberof FiskalyCashRegistryTransactionLineDto
     */
    business_case: FiskalyBusinessCaseDto;
    /**
     * 
     * @type {boolean}
     * @memberof FiskalyCashRegistryTransactionLineDto
     */
    in_house: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FiskalyCashRegistryTransactionLineDto
     */
    storno: boolean;
    /**
     * 
     * @type {Array<FiskalyReferenceBillDto>}
     * @memberof FiskalyCashRegistryTransactionLineDto
     */
    references: Array<FiskalyReferenceBillDto>;
    /**
     * 
     * @type {string}
     * @memberof FiskalyCashRegistryTransactionLineDto
     */
    voucher_id: string;
    /**
     * 
     * @type {string}
     * @memberof FiskalyCashRegistryTransactionLineDto
     */
    text: string;
    /**
     * 
     * @type {Array<FiskalyTransactionItemDto>}
     * @memberof FiskalyCashRegistryTransactionLineDto
     */
    item: Array<FiskalyTransactionItemDto>;
    /**
     * 
     * @type {string}
     * @memberof FiskalyCashRegistryTransactionLineDto
     */
    lineitem_export_id: string;
    /**
     * 
     * @type {string}
     * @memberof FiskalyCashRegistryTransactionLineDto
     */
    source_cash_register: string;
}
/**
 * 
 * @export
 * @interface FiskalyCreateCashPointClosingCashStatementPaymentDto
 */
export interface FiskalyCreateCashPointClosingCashStatementPaymentDto {
    /**
     * 
     * @type {number}
     * @memberof FiskalyCreateCashPointClosingCashStatementPaymentDto
     */
    full_amount: number;
    /**
     * 
     * @type {number}
     * @memberof FiskalyCreateCashPointClosingCashStatementPaymentDto
     */
    cash_amount: number;
    /**
     * 
     * @type {Array<FiskalyCashAmountsByCurrencyDto>}
     * @memberof FiskalyCreateCashPointClosingCashStatementPaymentDto
     */
    cash_amounts_by_currency: Array<FiskalyCashAmountsByCurrencyDto>;
    /**
     * 
     * @type {Array<FiskalyPaymentTypesDto>}
     * @memberof FiskalyCreateCashPointClosingCashStatementPaymentDto
     */
    payment_types: Array<FiskalyPaymentTypesDto>;
}
/**
 * 
 * @export
 * @interface FiskalyCreateCashPointClosingDetailResponseDto
 */
export interface FiskalyCreateCashPointClosingDetailResponseDto {
    /**
     * 
     * @type {FiskalyCreateCashPointClosingHeadRequestDto}
     * @memberof FiskalyCreateCashPointClosingDetailResponseDto
     */
    head: FiskalyCreateCashPointClosingHeadRequestDto;
    /**
     * 
     * @type {CashStatementDto}
     * @memberof FiskalyCreateCashPointClosingDetailResponseDto
     */
    cash_statement: CashStatementDto;
    /**
     * 
     * @type {Array<FiskalyCashPointTransactionDto>}
     * @memberof FiskalyCreateCashPointClosingDetailResponseDto
     */
    transactions: Array<FiskalyCashPointTransactionDto>;
    /**
     * 
     * @type {string}
     * @memberof FiskalyCreateCashPointClosingDetailResponseDto
     */
    client_id: string;
    /**
     * 
     * @type {number}
     * @memberof FiskalyCreateCashPointClosingDetailResponseDto
     */
    cash_point_closing_export_id: number;
    /**
     * 
     * @type {object}
     * @memberof FiskalyCreateCashPointClosingDetailResponseDto
     */
    metadata: object;
    /**
     * 
     * @type {FiskalyCreateCashPointClosingRequestDto}
     * @memberof FiskalyCreateCashPointClosingDetailResponseDto
     */
    cash_point_closing: FiskalyCreateCashPointClosingRequestDto;
    /**
     * 
     * @type {string}
     * @memberof FiskalyCreateCashPointClosingDetailResponseDto
     */
    state: string;
    /**
     * 
     * @type {object}
     * @memberof FiskalyCreateCashPointClosingDetailResponseDto
     */
    error: object;
    /**
     * 
     * @type {number}
     * @memberof FiskalyCreateCashPointClosingDetailResponseDto
     */
    time_creation: number;
    /**
     * 
     * @type {number}
     * @memberof FiskalyCreateCashPointClosingDetailResponseDto
     */
    time_update: number;
    /**
     * 
     * @type {number}
     * @memberof FiskalyCreateCashPointClosingDetailResponseDto
     */
    time_deleted: number;
    /**
     * 
     * @type {string}
     * @memberof FiskalyCreateCashPointClosingDetailResponseDto
     */
    _id: string;
    /**
     * 
     * @type {string}
     * @memberof FiskalyCreateCashPointClosingDetailResponseDto
     */
    _type: string;
    /**
     * 
     * @type {string}
     * @memberof FiskalyCreateCashPointClosingDetailResponseDto
     */
    _env: string;
    /**
     * 
     * @type {string}
     * @memberof FiskalyCreateCashPointClosingDetailResponseDto
     */
    _version: string;
    /**
     * 
     * @type {string}
     * @memberof FiskalyCreateCashPointClosingDetailResponseDto
     */
    taxonomy_version: string;
}
/**
 * 
 * @export
 * @interface FiskalyCreateCashPointClosingHeadRequestDto
 */
export interface FiskalyCreateCashPointClosingHeadRequestDto {
    /**
     * 
     * @type {string}
     * @memberof FiskalyCreateCashPointClosingHeadRequestDto
     */
    business_date: string;
    /**
     * 
     * @type {string}
     * @memberof FiskalyCreateCashPointClosingHeadRequestDto
     */
    first_transaction_export_id: string;
    /**
     * 
     * @type {string}
     * @memberof FiskalyCreateCashPointClosingHeadRequestDto
     */
    last_transaction_export_id: string;
    /**
     * 
     * @type {number}
     * @memberof FiskalyCreateCashPointClosingHeadRequestDto
     */
    export_creation_date: number;
}
/**
 * 
 * @export
 * @interface FiskalyCreateCashPointClosingRequestDto
 */
export interface FiskalyCreateCashPointClosingRequestDto {
    /**
     * 
     * @type {FiskalyCreateCashPointClosingHeadRequestDto}
     * @memberof FiskalyCreateCashPointClosingRequestDto
     */
    head: FiskalyCreateCashPointClosingHeadRequestDto;
    /**
     * 
     * @type {CashStatementDto}
     * @memberof FiskalyCreateCashPointClosingRequestDto
     */
    cash_statement: CashStatementDto;
    /**
     * 
     * @type {Array<FiskalyCashPointTransactionDto>}
     * @memberof FiskalyCreateCashPointClosingRequestDto
     */
    transactions: Array<FiskalyCashPointTransactionDto>;
    /**
     * 
     * @type {string}
     * @memberof FiskalyCreateCashPointClosingRequestDto
     */
    client_id: string;
    /**
     * 
     * @type {number}
     * @memberof FiskalyCreateCashPointClosingRequestDto
     */
    cash_point_closing_export_id: number;
    /**
     * 
     * @type {object}
     * @memberof FiskalyCreateCashPointClosingRequestDto
     */
    metadata: object;
}
/**
 * 
 * @export
 * @interface FiskalyCreateCashPointClosingResponseDto
 */
export interface FiskalyCreateCashPointClosingResponseDto {
    /**
     * 
     * @type {string}
     * @memberof FiskalyCreateCashPointClosingResponseDto
     */
    closing_id: string;
    /**
     * 
     * @type {number}
     * @memberof FiskalyCreateCashPointClosingResponseDto
     */
    cash_point_closing_export_id: number;
    /**
     * 
     * @type {string}
     * @memberof FiskalyCreateCashPointClosingResponseDto
     */
    state: string;
    /**
     * 
     * @type {object}
     * @memberof FiskalyCreateCashPointClosingResponseDto
     */
    error: object;
    /**
     * 
     * @type {string}
     * @memberof FiskalyCreateCashPointClosingResponseDto
     */
    client_id: string;
    /**
     * 
     * @type {string}
     * @memberof FiskalyCreateCashPointClosingResponseDto
     */
    business_date: string;
    /**
     * 
     * @type {string}
     * @memberof FiskalyCreateCashPointClosingResponseDto
     */
    first_transaction_export_id: string;
    /**
     * 
     * @type {string}
     * @memberof FiskalyCreateCashPointClosingResponseDto
     */
    last_transaction_export_id: string;
    /**
     * 
     * @type {number}
     * @memberof FiskalyCreateCashPointClosingResponseDto
     */
    export_creation_date: number;
    /**
     * 
     * @type {number}
     * @memberof FiskalyCreateCashPointClosingResponseDto
     */
    full_amount: number;
    /**
     * 
     * @type {number}
     * @memberof FiskalyCreateCashPointClosingResponseDto
     */
    cash_amount: number;
    /**
     * 
     * @type {object}
     * @memberof FiskalyCreateCashPointClosingResponseDto
     */
    metadata: object;
    /**
     * 
     * @type {number}
     * @memberof FiskalyCreateCashPointClosingResponseDto
     */
    time_creation: number;
    /**
     * 
     * @type {number}
     * @memberof FiskalyCreateCashPointClosingResponseDto
     */
    time_update: number;
    /**
     * 
     * @type {number}
     * @memberof FiskalyCreateCashPointClosingResponseDto
     */
    time_deleted: number;
    /**
     * 
     * @type {string}
     * @memberof FiskalyCreateCashPointClosingResponseDto
     */
    _type: string;
    /**
     * 
     * @type {string}
     * @memberof FiskalyCreateCashPointClosingResponseDto
     */
    _env: string;
    /**
     * 
     * @type {string}
     * @memberof FiskalyCreateCashPointClosingResponseDto
     */
    _version: string;
    /**
     * 
     * @type {string}
     * @memberof FiskalyCreateCashPointClosingResponseDto
     */
    taxonomy_version: string;
}
/**
 * 
 * @export
 * @interface FiskalyCreateOrganizationDto
 */
export interface FiskalyCreateOrganizationDto {
    /**
     * 
     * @type {string}
     * @memberof FiskalyCreateOrganizationDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof FiskalyCreateOrganizationDto
     */
    display_name: string;
    /**
     * 
     * @type {string}
     * @memberof FiskalyCreateOrganizationDto
     */
    vat_id: string;
    /**
     * 
     * @type {string}
     * @memberof FiskalyCreateOrganizationDto
     */
    contact_person_id: string;
    /**
     * 
     * @type {string}
     * @memberof FiskalyCreateOrganizationDto
     */
    address_line1: string;
    /**
     * 
     * @type {string}
     * @memberof FiskalyCreateOrganizationDto
     */
    address_line2: string;
    /**
     * 
     * @type {string}
     * @memberof FiskalyCreateOrganizationDto
     */
    zip: string;
    /**
     * 
     * @type {string}
     * @memberof FiskalyCreateOrganizationDto
     */
    town: string;
    /**
     * 
     * @type {string}
     * @memberof FiskalyCreateOrganizationDto
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof FiskalyCreateOrganizationDto
     */
    country_code: string;
    /**
     * 
     * @type {string}
     * @memberof FiskalyCreateOrganizationDto
     */
    tax_number: string;
    /**
     * 
     * @type {string}
     * @memberof FiskalyCreateOrganizationDto
     */
    economy_id: string;
    /**
     * 
     * @type {BillingOptions}
     * @memberof FiskalyCreateOrganizationDto
     */
    billing_options: BillingOptions;
    /**
     * 
     * @type {string}
     * @memberof FiskalyCreateOrganizationDto
     */
    billing_address_id: string;
    /**
     * 
     * @type {object}
     * @memberof FiskalyCreateOrganizationDto
     */
    meta_data: object;
    /**
     * 
     * @type {string}
     * @memberof FiskalyCreateOrganizationDto
     */
    managed_by_organization_id: string;
}
/**
 * 
 * @export
 * @interface FiskalyPaymentTypesDto
 */
export interface FiskalyPaymentTypesDto {
    /**
     * 
     * @type {string}
     * @memberof FiskalyPaymentTypesDto
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof FiskalyPaymentTypesDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof FiskalyPaymentTypesDto
     */
    currency_code: string;
    /**
     * 
     * @type {number}
     * @memberof FiskalyPaymentTypesDto
     */
    foreign_amount: number;
    /**
     * 
     * @type {number}
     * @memberof FiskalyPaymentTypesDto
     */
    amount: number;
}
/**
 * 
 * @export
 * @interface FiskalyReferenceBillDto
 */
export interface FiskalyReferenceBillDto {
    /**
     * 
     * @type {string}
     * @memberof FiskalyReferenceBillDto
     */
    type: string;
    /**
     * 
     * @type {number}
     * @memberof FiskalyReferenceBillDto
     */
    date: number;
    /**
     * 
     * @type {string}
     * @memberof FiskalyReferenceBillDto
     */
    external_export_id: string;
}
/**
 * 
 * @export
 * @interface FiskalyTransactionItemDto
 */
export interface FiskalyTransactionItemDto {
    /**
     * 
     * @type {string}
     * @memberof FiskalyTransactionItemDto
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof FiskalyTransactionItemDto
     */
    gtin: string;
    /**
     * 
     * @type {number}
     * @memberof FiskalyTransactionItemDto
     */
    quantity: number;
    /**
     * 
     * @type {number}
     * @memberof FiskalyTransactionItemDto
     */
    quantity_factor: number;
    /**
     * 
     * @type {string}
     * @memberof FiskalyTransactionItemDto
     */
    quantity_measure: string;
    /**
     * 
     * @type {string}
     * @memberof FiskalyTransactionItemDto
     */
    group_id: string;
    /**
     * 
     * @type {string}
     * @memberof FiskalyTransactionItemDto
     */
    group_name: string;
    /**
     * 
     * @type {number}
     * @memberof FiskalyTransactionItemDto
     */
    price_per_unit: number;
    /**
     * 
     * @type {Array<FiskalyAmountPerVatIdDto>}
     * @memberof FiskalyTransactionItemDto
     */
    base_amounts_per_vat_id: Array<FiskalyAmountPerVatIdDto>;
    /**
     * 
     * @type {Array<FiskalyAmountPerVatIdDto>}
     * @memberof FiskalyTransactionItemDto
     */
    discounts_per_vat_id: Array<FiskalyAmountPerVatIdDto>;
    /**
     * 
     * @type {Array<FiskalyAmountPerVatIdDto>}
     * @memberof FiskalyTransactionItemDto
     */
    extra_amounts_per_vat_id: Array<FiskalyAmountPerVatIdDto>;
    /**
     * 
     * @type {Array<FiskalyTransactionSubItemDto>}
     * @memberof FiskalyTransactionItemDto
     */
    sub_items: Array<FiskalyTransactionSubItemDto>;
}
/**
 * 
 * @export
 * @interface FiskalyTransactionSubItemDto
 */
export interface FiskalyTransactionSubItemDto {
    /**
     * 
     * @type {string}
     * @memberof FiskalyTransactionSubItemDto
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof FiskalyTransactionSubItemDto
     */
    gtin: string;
    /**
     * 
     * @type {number}
     * @memberof FiskalyTransactionSubItemDto
     */
    quantity: number;
    /**
     * 
     * @type {number}
     * @memberof FiskalyTransactionSubItemDto
     */
    quantity_factor: number;
    /**
     * 
     * @type {string}
     * @memberof FiskalyTransactionSubItemDto
     */
    quantity_measure: string;
    /**
     * 
     * @type {string}
     * @memberof FiskalyTransactionSubItemDto
     */
    group_id: string;
    /**
     * 
     * @type {string}
     * @memberof FiskalyTransactionSubItemDto
     */
    group_name: string;
    /**
     * 
     * @type {Array<FiskalyAmountPerVatIdDto>}
     * @memberof FiskalyTransactionSubItemDto
     */
    amount_per_vat_id: Array<FiskalyAmountPerVatIdDto>;
}
/**
 * 
 * @export
 * @interface GenerateEmailDto
 */
export interface GenerateEmailDto {
    /**
     * Booking Object
     * @type {EmailBookingDetails}
     * @memberof GenerateEmailDto
     */
    booking: EmailBookingDetails;
    /**
     * Vendor Object
     * @type {VendorDetails}
     * @memberof GenerateEmailDto
     */
    vendor: VendorDetails;
    /**
     * 
     * @type {string}
     * @memberof GenerateEmailDto
     */
    paymentLink?: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateEmailDto
     */
    viewLink?: string;
    /**
     * Extra email data
     * @type {object}
     * @memberof GenerateEmailDto
     */
    extraData?: object;
    /**
     * Payment Object
     * @type {PaymentEmailObject}
     * @memberof GenerateEmailDto
     */
    payment?: PaymentEmailObject;
    /**
     * 
     * @type {Array<string>}
     * @memberof GenerateEmailDto
     */
    paymentIcons?: Array<string>;
    /**
     * 
     * @type {StripeBankData}
     * @memberof GenerateEmailDto
     */
    prepayment?: StripeBankData;
}
/**
 * 
 * @export
 * @interface GenericCustomErrorDto
 */
export interface GenericCustomErrorDto {
    /**
     * 
     * @type {string}
     * @memberof GenericCustomErrorDto
     */
    errorCode: string;
    /**
     * 
     * @type {object}
     * @memberof GenericCustomErrorDto
     */
    payload: object;
}
/**
 * 
 * @export
 * @interface GetPricesChunkResponseDto
 */
export interface GetPricesChunkResponseDto {
    /**
     * 
     * @type {Array<ResponsePriceDto>}
     * @memberof GetPricesChunkResponseDto
     */
    items: Array<ResponsePriceDto>;
    /**
     * 
     * @type {number}
     * @memberof GetPricesChunkResponseDto
     */
    count: number;
}
/**
 * 
 * @export
 * @interface GetRedirectsFiltersDto
 */
export interface GetRedirectsFiltersDto {
    /**
     * 
     * @type {number}
     * @memberof GetRedirectsFiltersDto
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof GetRedirectsFiltersDto
     */
    itemsPerPage?: number;
    /**
     * 
     * @type {string}
     * @memberof GetRedirectsFiltersDto
     */
    orderBy?: string;
    /**
     * 
     * @type {string}
     * @memberof GetRedirectsFiltersDto
     */
    orderDir?: string;
    /**
     * 
     * @type {number}
     * @memberof GetRedirectsFiltersDto
     */
    tagId?: number;
    /**
     * 
     * @type {number}
     * @memberof GetRedirectsFiltersDto
     */
    previousCount?: number;
    /**
     * 
     * @type {number}
     * @memberof GetRedirectsFiltersDto
     */
    counter?: number;
    /**
     * 
     * @type {string}
     * @memberof GetRedirectsFiltersDto
     */
    uid?: string;
    /**
     * 
     * @type {string}
     * @memberof GetRedirectsFiltersDto
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof GetRedirectsFiltersDto
     */
    updatedAt?: string;
}
/**
 * 
 * @export
 * @interface GetUsersChunkDto
 */
export interface GetUsersChunkDto {
    /**
     * 
     * @type {Array<ResponseUserDto>}
     * @memberof GetUsersChunkDto
     */
    items: Array<ResponseUserDto>;
    /**
     * 
     * @type {number}
     * @memberof GetUsersChunkDto
     */
    count: number;
}
/**
 * 
 * @export
 * @interface GroupDto
 */
export interface GroupDto {
    /**
     * 
     * @type {number}
     * @memberof GroupDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof GroupDto
     */
    model: string;
    /**
     * 
     * @type {string}
     * @memberof GroupDto
     */
    publicName: string;
}
/**
 * 
 * @export
 * @interface HourRule
 */
export interface HourRule {
    /**
     * 
     * @type {number}
     * @memberof HourRule
     */
    minHours: number;
    /**
     * 
     * @type {number}
     * @memberof HourRule
     */
    maxHours: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof HourRule
     */
    conditions: Array<string>;
}
/**
 * 
 * @export
 * @interface Id
 */
export interface Id {
    /**
     * 
     * @type {number}
     * @memberof Id
     */
    id: number;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    status?: string;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof InlineResponse200
     */
    info?: { [key: string]: { [key: string]: string; }; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof InlineResponse200
     */
    error?: { [key: string]: { [key: string]: string; }; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof InlineResponse200
     */
    details?: { [key: string]: { [key: string]: string; }; };
}
/**
 * 
 * @export
 * @interface InlineResponse503
 */
export interface InlineResponse503 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse503
     */
    status?: string;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof InlineResponse503
     */
    info?: { [key: string]: { [key: string]: string; }; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof InlineResponse503
     */
    error?: { [key: string]: { [key: string]: string; }; } | null;
    /**
     * 
     * @type {{ [key: string]: { [key: string]: string; }; }}
     * @memberof InlineResponse503
     */
    details?: { [key: string]: { [key: string]: string; }; };
}
/**
 * 
 * @export
 * @interface InvalidPasswordDto
 */
export interface InvalidPasswordDto {
    /**
     * 
     * @type {string}
     * @memberof InvalidPasswordDto
     */
    errorCode: string;
    /**
     * 
     * @type {object}
     * @memberof InvalidPasswordDto
     */
    payload: object;
}
/**
 * 
 * @export
 * @interface InvoicesCreateDto
 */
export interface InvoicesCreateDto {
    /**
     * Customer Object
     * @type {CustomerDetails}
     * @memberof InvoicesCreateDto
     */
    customer: CustomerDetails;
    /**
     * Booking Object
     * @type {BookingDetails}
     * @memberof InvoicesCreateDto
     */
    booking: BookingDetails;
    /**
     * Vendor Object
     * @type {VendorDetails}
     * @memberof InvoicesCreateDto
     */
    vendor: VendorDetails;
    /**
     * 
     * @type {ReceiptType}
     * @memberof InvoicesCreateDto
     */
    receiptType: ReceiptType;
}
/**
 * 
 * @export
 * @interface InvoicesResponseDto
 */
export interface InvoicesResponseDto {
    /**
     * 
     * @type {string}
     * @memberof InvoicesResponseDto
     */
    url: string;
    /**
     * 
     * @type {number}
     * @memberof InvoicesResponseDto
     */
    bookingId: number;
    /**
     * 
     * @type {string}
     * @memberof InvoicesResponseDto
     */
    invoiceNumber: string;
    /**
     * 
     * @type {string}
     * @memberof InvoicesResponseDto
     */
    receiptType: string;
}
/**
 * 
 * @export
 * @interface ItemDto
 */
export interface ItemDto {
    /**
     * 
     * @type {number}
     * @memberof ItemDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ItemDto
     */
    group: number;
    /**
     * 
     * @type {string}
     * @memberof ItemDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ItemDto
     */
    start_time: string;
    /**
     * 
     * @type {string}
     * @memberof ItemDto
     */
    end_time: string;
    /**
     * 
     * @type {string}
     * @memberof ItemDto
     */
    type: string;
}
/**
 * 
 * @export
 * @interface ItemsChunkResponseDto
 */
export interface ItemsChunkResponseDto {
    /**
     * 
     * @type {Array<ResponseItemsDto>}
     * @memberof ItemsChunkResponseDto
     */
    items: Array<ResponseItemsDto>;
    /**
     * 
     * @type {number}
     * @memberof ItemsChunkResponseDto
     */
    count: number;
}
/**
 * 
 * @export
 * @interface ItemsFiltersDto
 */
export interface ItemsFiltersDto {
    /**
     * 
     * @type {number}
     * @memberof ItemsFiltersDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemsFiltersDto
     */
    locationId?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemsFiltersDto
     */
    foodCategoryId?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemsFiltersDto
     */
    price?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ItemsFiltersDto
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ItemsFiltersDto
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface JWTRefreshResponseDto
 */
export interface JWTRefreshResponseDto {
    /**
     * 
     * @type {string}
     * @memberof JWTRefreshResponseDto
     */
    jwt: string;
    /**
     * 
     * @type {string}
     * @memberof JWTRefreshResponseDto
     */
    refresh: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum Languages {
    En = 'en',
    De = 'de'
}

/**
 * 
 * @export
 * @interface LocationAddress
 */
export interface LocationAddress {
    /**
     * 
     * @type {string}
     * @memberof LocationAddress
     */
    street: string;
    /**
     * 
     * @type {string}
     * @memberof LocationAddress
     */
    zip: string;
    /**
     * 
     * @type {string}
     * @memberof LocationAddress
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof LocationAddress
     */
    country: string;
}
/**
 * 
 * @export
 * @interface LocationChunkResponseDto
 */
export interface LocationChunkResponseDto {
    /**
     * 
     * @type {Array<LocationResponseDto>}
     * @memberof LocationChunkResponseDto
     */
    items: Array<LocationResponseDto>;
    /**
     * 
     * @type {number}
     * @memberof LocationChunkResponseDto
     */
    count: number;
}
/**
 * 
 * @export
 * @interface LocationResponseDto
 */
export interface LocationResponseDto {
    /**
     * Location id
     * @type {number}
     * @memberof LocationResponseDto
     */
    beachId: number;
    /**
     * Lead time
     * @type {number}
     * @memberof LocationResponseDto
     */
    leadTimeHours?: number;
    /**
     * Lead time limit hour, can be used instead of leadTimeHours
     * @type {string}
     * @memberof LocationResponseDto
     */
    leadTimeLimit?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationResponseDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof LocationResponseDto
     */
    checkoutTime: string;
    /**
     * 
     * @type {string}
     * @memberof LocationResponseDto
     */
    description: string;
    /**
     * 
     * @type {object}
     * @memberof LocationResponseDto
     */
    geoJson: object;
    /**
     * 
     * @type {string}
     * @memberof LocationResponseDto
     */
    seasonStart?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationResponseDto
     */
    seasonEnd?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationResponseDto
     */
    entranceName: string;
    /**
     * 
     * @type {object}
     * @memberof LocationResponseDto
     */
    terms?: object;
    /**
     * 
     * @type {object}
     * @memberof LocationResponseDto
     */
    priceText?: object;
    /**
     * 
     * @type {string}
     * @memberof LocationResponseDto
     */
    mapImageUrl: string;
    /**
     * 
     * @type {boolean}
     * @memberof LocationResponseDto
     */
    foodDelivery: boolean;
    /**
     * 
     * @type {LocationAddress}
     * @memberof LocationResponseDto
     */
    address: LocationAddress;
    /**
     * 
     * @type {Details}
     * @memberof LocationResponseDto
     */
    details: Details;
    /**
     * 
     * @type {number}
     * @memberof LocationResponseDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof LocationResponseDto
     */
    vendorId: number;
}
/**
 * 
 * @export
 * @interface LocationsFiltersDto
 */
export interface LocationsFiltersDto {
    /**
     * 
     * @type {number}
     * @memberof LocationsFiltersDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LocationsFiltersDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationsFiltersDto
     */
    entranceName?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationsFiltersDto
     */
    seasonStart?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationsFiltersDto
     */
    seasonEnd?: string;
    /**
     * 
     * @type {number}
     * @memberof LocationsFiltersDto
     */
    vendorId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LocationsFiltersDto
     */
    foodDelivery?: boolean;
}
/**
 * 
 * @export
 * @interface LockChunkResponseDto
 */
export interface LockChunkResponseDto {
    /**
     * 
     * @type {Array<LockResponseDto>}
     * @memberof LockChunkResponseDto
     */
    items: Array<LockResponseDto>;
    /**
     * 
     * @type {number}
     * @memberof LockChunkResponseDto
     */
    count: number;
}
/**
 * 
 * @export
 * @interface LockHealthDto
 */
export interface LockHealthDto {
    /**
     * 
     * @type {string}
     * @memberof LockHealthDto
     */
    status: string;
    /**
     * 
     * @type {number}
     * @memberof LockHealthDto
     */
    batteryLevel: number;
}
/**
 * 
 * @export
 * @interface LockPublicResponseDto
 */
export interface LockPublicResponseDto {
    /**
     * 
     * @type {number}
     * @memberof LockPublicResponseDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof LockPublicResponseDto
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof LockPublicResponseDto
     * @deprecated
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof LockPublicResponseDto
     */
    unlockPacket: string;
    /**
     * 
     * @type {string}
     * @memberof LockPublicResponseDto
     */
    lockPacket: string;
    /**
     * 
     * @type {string}
     * @memberof LockPublicResponseDto
     */
    mac: string;
    /**
     * 
     * @type {string}
     * @memberof LockPublicResponseDto
     */
    password: string;
}
/**
 * 
 * @export
 * @interface LockResponseDto
 */
export interface LockResponseDto {
    /**
     * 
     * @type {string}
     * @memberof LockResponseDto
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof LockResponseDto
     */
    bcd: string;
    /**
     * 
     * @type {string}
     * @memberof LockResponseDto
     */
    mac?: string;
    /**
     * 
     * @type {string}
     * @memberof LockResponseDto
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof LockResponseDto
     */
    secondaryPassword?: string;
    /**
     * 
     * @type {number}
     * @memberof LockResponseDto
     */
    vendorId?: number;
    /**
     * 
     * @type {string}
     * @memberof LockResponseDto
     */
    status: string;
    /**
     * 
     * @type {boolean}
     * @memberof LockResponseDto
     */
    opened: boolean;
    /**
     * 
     * @type {number}
     * @memberof LockResponseDto
     */
    batteryLevel: number;
    /**
     * 
     * @type {NestedLockDetailsBodyDto}
     * @memberof LockResponseDto
     */
    details: NestedLockDetailsBodyDto;
    /**
     * 
     * @type {number}
     * @memberof LockResponseDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof LockResponseDto
     */
    updatedAt: string;
    /**
     * 
     * @type {BeachChairResponseDto}
     * @memberof LockResponseDto
     */
    beachChair: BeachChairResponseDto;
}
/**
 * 
 * @export
 * @interface LockResponseDtoWithPasswords
 */
export interface LockResponseDtoWithPasswords {
    /**
     * 
     * @type {string}
     * @memberof LockResponseDtoWithPasswords
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof LockResponseDtoWithPasswords
     */
    bcd: string;
    /**
     * 
     * @type {string}
     * @memberof LockResponseDtoWithPasswords
     */
    mac?: string;
    /**
     * 
     * @type {string}
     * @memberof LockResponseDtoWithPasswords
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof LockResponseDtoWithPasswords
     */
    secondaryPassword?: string;
    /**
     * 
     * @type {number}
     * @memberof LockResponseDtoWithPasswords
     */
    vendorId?: number;
    /**
     * 
     * @type {string}
     * @memberof LockResponseDtoWithPasswords
     */
    status: string;
    /**
     * 
     * @type {boolean}
     * @memberof LockResponseDtoWithPasswords
     */
    opened: boolean;
    /**
     * 
     * @type {number}
     * @memberof LockResponseDtoWithPasswords
     */
    batteryLevel: number;
    /**
     * 
     * @type {NestedLockDetailsBodyDto}
     * @memberof LockResponseDtoWithPasswords
     */
    details: NestedLockDetailsBodyDto;
    /**
     * 
     * @type {number}
     * @memberof LockResponseDtoWithPasswords
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof LockResponseDtoWithPasswords
     */
    updatedAt: string;
    /**
     * 
     * @type {BeachChairResponseDto}
     * @memberof LockResponseDtoWithPasswords
     */
    beachChair: BeachChairResponseDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof LockResponseDtoWithPasswords
     */
    passwords: Array<string>;
}
/**
 * 
 * @export
 * @interface LocksFiltersDto
 */
export interface LocksFiltersDto {
    /**
     * 
     * @type {number}
     * @memberof LocksFiltersDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof LocksFiltersDto
     */
    vendorId?: number;
    /**
     * 
     * @type {string}
     * @memberof LocksFiltersDto
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof LocksFiltersDto
     */
    from?: string;
    /**
     * 
     * @type {string}
     * @memberof LocksFiltersDto
     */
    to?: string;
    /**
     * 
     * @type {string}
     * @memberof LocksFiltersDto
     */
    number?: string;
    /**
     * 
     * @type {string}
     * @memberof LocksFiltersDto
     */
    status?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LocksFiltersDto
     */
    opened?: boolean;
}
/**
 * 
 * @export
 * @interface MetaData
 */
export interface MetaData {
    /**
     * 
     * @type {string}
     * @memberof MetaData
     */
    source: string;
    /**
     * 
     * @type {StripeBankData}
     * @memberof MetaData
     */
    bankData: StripeBankData;
    /**
     * 
     * @type {string}
     * @memberof MetaData
     */
    affiliateReference: string;
}
/**
 * 
 * @export
 * @interface MobileConfigResponseDto
 */
export interface MobileConfigResponseDto {
    /**
     * 
     * @type {string}
     * @memberof MobileConfigResponseDto
     */
    stripePublicKey: string;
    /**
     * 
     * @type {string}
     * @memberof MobileConfigResponseDto
     */
    vendorStripeAccountId: string;
    /**
     * 
     * @type {string}
     * @memberof MobileConfigResponseDto
     */
    vendorRef: string;
}
/**
 * 
 * @export
 * @interface NestedLockDetailsBodyDto
 */
export interface NestedLockDetailsBodyDto {
    /**
     * 
     * @type {string}
     * @memberof NestedLockDetailsBodyDto
     */
    model: string;
    /**
     * 
     * @type {number}
     * @memberof NestedLockDetailsBodyDto
     */
    openCount: number;
    /**
     * 
     * @type {string}
     * @memberof NestedLockDetailsBodyDto
     */
    buyDate: string;
    /**
     * 
     * @type {string}
     * @memberof NestedLockDetailsBodyDto
     */
    manufactor: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum OrderByDirection {
    Asc = 'ASC',
    Desc = 'DESC'
}

/**
 * 
 * @export
 * @interface PaymentEmailObject
 */
export interface PaymentEmailObject {
    /**
     * 
     * @type {number}
     * @memberof PaymentEmailObject
     */
    refundAmount: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentEmailObject
     */
    cancellationFee: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum PaymentFlow {
    Website = 'WEBSITE',
    Direct = 'DIRECT',
    Widget = 'WIDGET',
    Email = 'EMAIL'
}

/**
 * 
 * @export
 * @interface PaymentsResponseDto
 */
export interface PaymentsResponseDto {
    /**
     * 
     * @type {string}
     * @memberof PaymentsResponseDto
     */
    sessionId: string;
}
/**
 * 
 * @export
 * @interface PriceDetailsResponseDto
 */
export interface PriceDetailsResponseDto {
    /**
     * 
     * @type {number}
     * @memberof PriceDetailsResponseDto
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof PriceDetailsResponseDto
     */
    type: string;
    /**
     * 
     * @type {boolean}
     * @memberof PriceDetailsResponseDto
     */
    discount: boolean;
}
/**
 * 
 * @export
 * @interface PriceRules
 */
export interface PriceRules {
    /**
     * 
     * @type {Array<DurationRule>}
     * @memberof PriceRules
     */
    durationRules?: Array<DurationRule>;
    /**
     * 
     * @type {Array<DayRule>}
     * @memberof PriceRules
     */
    dayRules?: Array<DayRule>;
    /**
     * 
     * @type {Array<HourRule>}
     * @memberof PriceRules
     */
    hourRules?: Array<HourRule>;
    /**
     * 
     * @type {Array<CancellationRule>}
     * @memberof PriceRules
     */
    cancellationRules?: Array<CancellationRule>;
}
/**
 * 
 * @export
 * @interface PublicCreateBeachChairBookingBodyDto
 */
export interface PublicCreateBeachChairBookingBodyDto {
    /**
     * 
     * @type {number}
     * @memberof PublicCreateBeachChairBookingBodyDto
     */
    beachChairId: number;
    /**
     * 
     * @type {string}
     * @memberof PublicCreateBeachChairBookingBodyDto
     */
    start: string;
    /**
     * 
     * @type {string}
     * @memberof PublicCreateBeachChairBookingBodyDto
     */
    end: string;
    /**
     * 
     * @type {string}
     * @memberof PublicCreateBeachChairBookingBodyDto
     */
    rate?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicCreateBeachChairBookingBodyDto
     */
    model: string;
    /**
     * 
     * @type {boolean}
     * @memberof PublicCreateBeachChairBookingBodyDto
     */
    discount?: boolean;
}
/**
 * 
 * @export
 * @interface PublicCreateCustomerBodyDto
 */
export interface PublicCreateCustomerBodyDto {
    /**
     * 
     * @type {string}
     * @memberof PublicCreateCustomerBodyDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof PublicCreateCustomerBodyDto
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof PublicCreateCustomerBodyDto
     */
    salutation: string;
    /**
     * 
     * @type {CustomerAddressNestedDto}
     * @memberof PublicCreateCustomerBodyDto
     */
    address: CustomerAddressNestedDto;
    /**
     * 
     * @type {string}
     * @memberof PublicCreateCustomerBodyDto
     */
    telephone: string;
    /**
     * 
     * @type {string}
     * @memberof PublicCreateCustomerBodyDto
     */
    email: string;
    /**
     * 
     * @type {Languages}
     * @memberof PublicCreateCustomerBodyDto
     */
    language?: Languages;
    /**
     * 
     * @type {BankDetails}
     * @memberof PublicCreateCustomerBodyDto
     */
    bankDetails?: BankDetails;
    /**
     * 
     * @type {string}
     * @memberof PublicCreateCustomerBodyDto
     */
    userId: string;
    /**
     * 
     * @type {StripeDetailsDto}
     * @memberof PublicCreateCustomerBodyDto
     */
    stripe?: StripeDetailsDto;
    /**
     * 
     * @type {AdditionalDataDto}
     * @memberof PublicCreateCustomerBodyDto
     */
    additionalData?: AdditionalDataDto;
}
/**
 * 
 * @export
 * @interface PublicLockReportBodyDto
 */
export interface PublicLockReportBodyDto {
    /**
     * 
     * @type {string}
     * @memberof PublicLockReportBodyDto
     */
    packetData: string;
    /**
     * 
     * @type {number}
     * @memberof PublicLockReportBodyDto
     */
    lat: number;
    /**
     * 
     * @type {number}
     * @memberof PublicLockReportBodyDto
     */
    _long: number;
    /**
     * 
     * @type {number}
     * @memberof PublicLockReportBodyDto
     */
    expiresAt: number;
}
/**
 * 
 * @export
 * @interface PublicUpdatePaymentMethod
 */
export interface PublicUpdatePaymentMethod {
    /**
     * 
     * @type {BookingPaymentMethod}
     * @memberof PublicUpdatePaymentMethod
     */
    paymentMethod: BookingPaymentMethod;
    /**
     * 
     * @type {string}
     * @memberof PublicUpdatePaymentMethod
     */
    customerComment?: string;
    /**
     * 
     * @type {BankDetails}
     * @memberof PublicUpdatePaymentMethod
     */
    bankDetails?: BankDetails;
}
/**
 * 
 * @export
 * @interface PublicVendorConfiguration
 */
export interface PublicVendorConfiguration {
    /**
     * 
     * @type {boolean}
     * @memberof PublicVendorConfiguration
     */
    hideCustomerComment?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PublicVendorConfiguration
     */
    hideOpeningHours?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PublicVendorConfiguration
     */
    gridLineBreak?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PublicVendorConfiguration
     */
    bookingStartdate?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicVendorConfiguration
     */
    beachChairView?: string;
}
/**
 * 
 * @export
 * @interface PublicVendorResponseDto
 */
export interface PublicVendorResponseDto {
    /**
     * 
     * @type {Address}
     * @memberof PublicVendorResponseDto
     */
    address: Address;
    /**
     * 
     * @type {string}
     * @memberof PublicVendorResponseDto
     */
    name: string;
    /**
     * 
     * @type {PublicVendorsDetails}
     * @memberof PublicVendorResponseDto
     */
    details: PublicVendorsDetails;
    /**
     * 
     * @type {PublicVendorConfiguration}
     * @memberof PublicVendorResponseDto
     */
    configuration: PublicVendorConfiguration;
    /**
     * 
     * @type {Array<string>}
     * @memberof PublicVendorResponseDto
     */
    paymentMethods: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PublicVendorResponseDto
     */
    companyName: string;
    /**
     * 
     * @type {boolean}
     * @memberof PublicVendorResponseDto
     */
    activeAvailability: boolean;
    /**
     * 
     * @type {string}
     * @memberof PublicVendorResponseDto
     */
    description: string;
    /**
     * 
     * @type {boolean}
     * @memberof PublicVendorResponseDto
     */
    foodProvider: boolean;
    /**
     * 
     * @type {string}
     * @memberof PublicVendorResponseDto
     */
    publicReference: string;
}
/**
 * 
 * @export
 * @interface PublicVendorsDetails
 */
export interface PublicVendorsDetails {
    /**
     * 
     * @type {string}
     * @memberof PublicVendorsDetails
     */
    website: string;
    /**
     * 
     * @type {string}
     * @memberof PublicVendorsDetails
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof PublicVendorsDetails
     */
    telephone: string;
    /**
     * 
     * @type {string}
     * @memberof PublicVendorsDetails
     */
    logo?: string;
    /**
     * 
     * @type {BankDetails}
     * @memberof PublicVendorsDetails
     */
    bankDetails?: BankDetails;
    /**
     * 
     * @type {ExtraText}
     * @memberof PublicVendorsDetails
     */
    extraText?: ExtraText;
    /**
     * 
     * @type {boolean}
     * @memberof PublicVendorsDetails
     */
    openBeachchairs?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PublicVendorsDetails
     */
    whatsAppNumber?: string;
}
/**
 * 
 * @export
 * @interface RatesQueryResponse
 */
export interface RatesQueryResponse {
    /**
     * 
     * @type {string}
     * @memberof RatesQueryResponse
     */
    type: string;
    /**
     * 
     * @type {number}
     * @memberof RatesQueryResponse
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof RatesQueryResponse
     */
    range: string;
    /**
     * 
     * @type {boolean}
     * @memberof RatesQueryResponse
     */
    discount: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ReceiptType {
    Invoice = 'INVOICE',
    Cancellation = 'CANCELLATION',
    BeachChairCancellation = 'BEACH_CHAIR_CANCELLATION',
    ShortInvoice = 'SHORT_INVOICE'
}

/**
 * 
 * @export
 * @interface RedirectCreateBodyDto
 */
export interface RedirectCreateBodyDto {
    /**
     * 
     * @type {string}
     * @memberof RedirectCreateBodyDto
     */
    uid: string;
    /**
     * 
     * @type {string}
     * @memberof RedirectCreateBodyDto
     */
    url: string;
    /**
     * 
     * @type {number}
     * @memberof RedirectCreateBodyDto
     */
    vendorId: number;
    /**
     * 
     * @type {number}
     * @memberof RedirectCreateBodyDto
     */
    tagId: number;
}
/**
 * 
 * @export
 * @interface RedirectCreateResponseDto
 */
export interface RedirectCreateResponseDto {
    /**
     * 
     * @type {string}
     * @memberof RedirectCreateResponseDto
     */
    uid: string;
    /**
     * 
     * @type {string}
     * @memberof RedirectCreateResponseDto
     */
    url: string;
    /**
     * 
     * @type {number}
     * @memberof RedirectCreateResponseDto
     */
    vendorId: number;
    /**
     * 
     * @type {number}
     * @memberof RedirectCreateResponseDto
     */
    counter: number;
    /**
     * 
     * @type {number}
     * @memberof RedirectCreateResponseDto
     */
    previousCount: number;
    /**
     * 
     * @type {number}
     * @memberof RedirectCreateResponseDto
     */
    tagId: number;
    /**
     * 
     * @type {string}
     * @memberof RedirectCreateResponseDto
     */
    updatedAt: string;
    /**
     * 
     * @type {string}
     * @memberof RedirectCreateResponseDto
     */
    deletedAt: string;
}
/**
 * 
 * @export
 * @interface RedirectUpdateBodyDto
 */
export interface RedirectUpdateBodyDto {
    /**
     * 
     * @type {string}
     * @memberof RedirectUpdateBodyDto
     */
    uid: string;
    /**
     * 
     * @type {string}
     * @memberof RedirectUpdateBodyDto
     */
    url: string;
    /**
     * 
     * @type {number}
     * @memberof RedirectUpdateBodyDto
     */
    vendorId: number;
    /**
     * 
     * @type {number}
     * @memberof RedirectUpdateBodyDto
     */
    tagId: number;
}
/**
 * 
 * @export
 * @interface RedirectsDto
 */
export interface RedirectsDto {
    /**
     * 
     * @type {number}
     * @memberof RedirectsDto
     */
    tagId: number;
    /**
     * 
     * @type {number}
     * @memberof RedirectsDto
     */
    counter: number;
    /**
     * 
     * @type {string}
     * @memberof RedirectsDto
     */
    updatedAt: string;
    /**
     * 
     * @type {number}
     * @memberof RedirectsDto
     */
    vendorId: number;
    /**
     * 
     * @type {string}
     * @memberof RedirectsDto
     */
    uid: string;
    /**
     * 
     * @type {string}
     * @memberof RedirectsDto
     */
    url: string;
}
/**
 * 
 * @export
 * @interface RedirectsFindAllChunkResponseDto
 */
export interface RedirectsFindAllChunkResponseDto {
    /**
     * 
     * @type {Array<RedirectsDto>}
     * @memberof RedirectsFindAllChunkResponseDto
     */
    items: Array<RedirectsDto>;
    /**
     * 
     * @type {number}
     * @memberof RedirectsFindAllChunkResponseDto
     */
    count: number;
}
/**
 * 
 * @export
 * @interface RefreshTokenDto
 */
export interface RefreshTokenDto {
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenDto
     */
    refreshToken: string;
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenDto
     */
    email: string;
}
/**
 * 
 * @export
 * @interface RefundErrorMissingPaymentDto
 */
export interface RefundErrorMissingPaymentDto {
    /**
     * 
     * @type {string}
     * @memberof RefundErrorMissingPaymentDto
     */
    errorCode: string;
    /**
     * 
     * @type {object}
     * @memberof RefundErrorMissingPaymentDto
     */
    payload: object;
}
/**
 * 
 * @export
 * @interface RegionChunkResponseDto
 */
export interface RegionChunkResponseDto {
    /**
     * 
     * @type {Array<RegionResponseDto>}
     * @memberof RegionChunkResponseDto
     */
    items: Array<RegionResponseDto>;
    /**
     * 
     * @type {number}
     * @memberof RegionChunkResponseDto
     */
    count: number;
}
/**
 * 
 * @export
 * @interface RegionResponseDto
 */
export interface RegionResponseDto {
    /**
     * 
     * @type {number}
     * @memberof RegionResponseDto
     */
    countryId: number;
    /**
     * 
     * @type {string}
     * @memberof RegionResponseDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RegionResponseDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof RegionResponseDto
     */
    shortDescription?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RegionResponseDto
     */
    images?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof RegionResponseDto
     */
    active?: boolean;
    /**
     * 
     * @type {object}
     * @memberof RegionResponseDto
     */
    geoJson: object;
    /**
     * 
     * @type {number}
     * @memberof RegionResponseDto
     */
    id: number;
}
/**
 * 
 * @export
 * @interface RegionsFiltersDto
 */
export interface RegionsFiltersDto {
    /**
     * 
     * @type {number}
     * @memberof RegionsFiltersDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof RegionsFiltersDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof RegionsFiltersDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof RegionsFiltersDto
     */
    shortDescription?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RegionsFiltersDto
     */
    active?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RegionsFiltersDto
     */
    countryId?: number;
}
/**
 * 
 * @export
 * @interface RegisterUserDto
 */
export interface RegisterUserDto {
    /**
     * 
     * @type {string}
     * @memberof RegisterUserDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserDto
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserDto
     */
    CustomerSalutation: RegisterUserDtoCustomerSalutationEnum;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserDto
     */
    Languages: RegisterUserDtoLanguagesEnum;
    /**
     * 
     * @type {object}
     * @memberof RegisterUserDto
     */
    address: object;
    /**
     * 
     * @type {string}
     * @memberof RegisterUserDto
     */
    telephone: string;
}

/**
    * @export
    * @enum {string}
    */
export enum RegisterUserDtoCustomerSalutationEnum {
    Ms = 'MS',
    Mr = 'MR',
    Mx = 'MX',
    Fm = 'FM'
}
/**
    * @export
    * @enum {string}
    */
export enum RegisterUserDtoLanguagesEnum {
    En = 'en',
    De = 'de'
}

/**
 * 
 * @export
 * @interface ReportResponseDto
 */
export interface ReportResponseDto {
    /**
     * 
     * @type {string}
     * @memberof ReportResponseDto
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof ReportResponseDto
     */
    reportNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ReportResponseDto
     */
    reportType: string;
}
/**
 * 
 * @export
 * @interface ResendConfirmRegistrationDto
 */
export interface ResendConfirmRegistrationDto {
    /**
     * 
     * @type {string}
     * @memberof ResendConfirmRegistrationDto
     */
    email: string;
}
/**
 * 
 * @export
 * @interface ResponseExternalServicesDto
 */
export interface ResponseExternalServicesDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseExternalServicesDto
     */
    locationId: number;
    /**
     * 
     * @type {number}
     * @memberof ResponseExternalServicesDto
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof ResponseExternalServicesDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseExternalServicesDto
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseExternalServicesDto
     */
    active: boolean;
    /**
     * 
     * @type {number}
     * @memberof ResponseExternalServicesDto
     */
    vat?: number;
    /**
     * 
     * @type {string}
     * @memberof ResponseExternalServicesDto
     */
    type: string;
    /**
     * 
     * @type {number}
     * @memberof ResponseExternalServicesDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ResponseExternalServicesDto
     */
    vendorId: number;
}
/**
 * 
 * @export
 * @interface ResponseExtrasBookingDto
 */
export interface ResponseExtrasBookingDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseExtrasBookingDto
     */
    extraId: number;
    /**
     * 
     * @type {number}
     * @memberof ResponseExtrasBookingDto
     */
    amount: number;
    /**
     * 
     * @type {number}
     * @memberof ResponseExtrasBookingDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ResponseExtrasBookingDto
     */
    totalPrice: number;
    /**
     * 
     * @type {number}
     * @memberof ResponseExtrasBookingDto
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof ResponseExtrasBookingDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseExtrasBookingDto
     */
    type: string;
    /**
     * 
     * @type {number}
     * @memberof ResponseExtrasBookingDto
     */
    vat: number;
    /**
     * 
     * @type {string}
     * @memberof ResponseExtrasBookingDto
     */
    description: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ResponseExtrasBookingDto
     */
    images: Array<string>;
}
/**
 * 
 * @export
 * @interface ResponseExtrasDto
 */
export interface ResponseExtrasDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseExtrasDto
     */
    locationId: number;
    /**
     * 
     * @type {number}
     * @memberof ResponseExtrasDto
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof ResponseExtrasDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseExtrasDto
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof ResponseExtrasDto
     */
    vat?: number;
    /**
     * 
     * @type {string}
     * @memberof ResponseExtrasDto
     */
    type: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ResponseExtrasDto
     */
    images?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseExtrasDto
     */
    onlineBooking: boolean;
    /**
     * 
     * @type {number}
     * @memberof ResponseExtrasDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ResponseExtrasDto
     */
    vendorId: number;
}
/**
 * 
 * @export
 * @interface ResponseItemsDto
 */
export interface ResponseItemsDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseItemsDto
     */
    locationId: number;
    /**
     * 
     * @type {number}
     * @memberof ResponseItemsDto
     */
    foodCategoryId: number;
    /**
     * 
     * @type {number}
     * @memberof ResponseItemsDto
     */
    price: number;
    /**
     * 
     * @type {string}
     * @memberof ResponseItemsDto
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseItemsDto
     */
    active: boolean;
    /**
     * 
     * @type {object}
     * @memberof ResponseItemsDto
     */
    ingredients: object;
    /**
     * 
     * @type {string}
     * @memberof ResponseItemsDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseItemsDto
     */
    shortDescription?: string;
    /**
     * 
     * @type {number}
     * @memberof ResponseItemsDto
     */
    vat?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ResponseItemsDto
     */
    images?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ResponseItemsDto
     */
    id: number;
}
/**
 * 
 * @export
 * @interface ResponsePriceDto
 */
export interface ResponsePriceDto {
    /**
     * 
     * @type {number}
     * @memberof ResponsePriceDto
     */
    vendorId: number;
    /**
     * 
     * @type {PriceRules}
     * @memberof ResponsePriceDto
     */
    rules: PriceRules;
    /**
     * 
     * @type {string}
     * @memberof ResponsePriceDto
     */
    bookingStart: string;
    /**
     * 
     * @type {string}
     * @memberof ResponsePriceDto
     */
    bookingEnd: string;
    /**
     * 
     * @type {string}
     * @memberof ResponsePriceDto
     */
    offerStart?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponsePriceDto
     */
    offerEnd?: string;
    /**
     * 
     * @type {number}
     * @memberof ResponsePriceDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ResponsePriceDto
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof ResponsePriceDto
     */
    updatedAt: string;
}
/**
 * 
 * @export
 * @interface ResponseUserDto
 */
export interface ResponseUserDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseUserDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserDto
     */
    lastName: string;
    /**
     * 
     * @type {Salutation}
     * @memberof ResponseUserDto
     */
    salutation: Salutation;
    /**
     * 
     * @type {number}
     * @memberof ResponseUserDto
     */
    vendorId: number;
    /**
     * 
     * @type {number}
     * @memberof ResponseUserDto
     */
    defaultLocationId?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ResponseUserDto
     */
    roles: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserDto
     */
    vendorName: string;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseUserDto
     */
    enabled: boolean;
}
/**
 * 
 * @export
 * @interface RowChunkResponseDto
 */
export interface RowChunkResponseDto {
    /**
     * 
     * @type {Array<RowResponseDto>}
     * @memberof RowChunkResponseDto
     */
    items: Array<RowResponseDto>;
    /**
     * 
     * @type {number}
     * @memberof RowChunkResponseDto
     */
    count: number;
}
/**
 * 
 * @export
 * @interface RowDataDto
 */
export interface RowDataDto {
    /**
     * 
     * @type {number}
     * @memberof RowDataDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof RowDataDto
     */
    lockId: number;
    /**
     * 
     * @type {string}
     * @memberof RowDataDto
     */
    publicNumber: string;
    /**
     * 
     * @type {string}
     * @memberof RowDataDto
     */
    timeEnum: string;
    /**
     * 
     * @type {string}
     * @memberof RowDataDto
     */
    reason: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RowDataDto
     */
    images: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RowDataDto
     */
    model: string;
    /**
     * 
     * @type {boolean}
     * @memberof RowDataDto
     */
    seasonBeachChair: boolean;
    /**
     * 
     * @type {number}
     * @memberof RowDataDto
     */
    bookingId: number;
    /**
     * 
     * @type {boolean}
     * @memberof RowDataDto
     */
    reserved: boolean;
    /**
     * 
     * @type {BeachChairAttributes}
     * @memberof RowDataDto
     */
    attributes: BeachChairAttributes;
    /**
     * 
     * @type {string}
     * @memberof RowDataDto
     */
    sectionName: string;
    /**
     * 
     * @type {string}
     * @memberof RowDataDto
     */
    rowName: string;
    /**
     * 
     * @type {object}
     * @memberof RowDataDto
     */
    geoJson: object;
}
/**
 * 
 * @export
 * @interface RowResponseDto
 */
export interface RowResponseDto {
    /**
     * 
     * @type {number}
     * @memberof RowResponseDto
     */
    sectionId: number;
    /**
     * 
     * @type {string}
     * @memberof RowResponseDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RowResponseDto
     */
    description: string;
    /**
     * 
     * @type {object}
     * @memberof RowResponseDto
     */
    geoJson: object;
    /**
     * 
     * @type {number}
     * @memberof RowResponseDto
     */
    id: number;
}
/**
 * 
 * @export
 * @interface RowsFiltersDto
 */
export interface RowsFiltersDto {
    /**
     * 
     * @type {number}
     * @memberof RowsFiltersDto
     */
    sectionId?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum Salutation {
    Mr = 'MR',
    Ms = 'MS',
    Mx = 'MX',
    Fm = 'FM'
}

/**
 * 
 * @export
 * @interface SectionCalendarAvailabilityResponseDto
 */
export interface SectionCalendarAvailabilityResponseDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof SectionCalendarAvailabilityResponseDto
     */
    channels: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SectionCalendarAvailabilityResponseDto
     */
    egp: Array<string>;
}
/**
 * 
 * @export
 * @interface SectionChunkResponseDto
 */
export interface SectionChunkResponseDto {
    /**
     * 
     * @type {Array<SectionResponseDto>}
     * @memberof SectionChunkResponseDto
     */
    items: Array<SectionResponseDto>;
    /**
     * 
     * @type {number}
     * @memberof SectionChunkResponseDto
     */
    count: number;
}
/**
 * 
 * @export
 * @interface SectionDetailsDto
 */
export interface SectionDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof SectionDetailsDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof SectionDetailsDto
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof SectionDetailsDto
     */
    booked: number;
    /**
     * 
     * @type {number}
     * @memberof SectionDetailsDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof SectionDetailsDto
     */
    partly: number;
    /**
     * 
     * @type {Beach}
     * @memberof SectionDetailsDto
     */
    beach: Beach;
}
/**
 * 
 * @export
 * @interface SectionDetailsViewDto
 */
export interface SectionDetailsViewDto {
    /**
     * 
     * @type {number}
     * @memberof SectionDetailsViewDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof SectionDetailsViewDto
     */
    name: string;
    /**
     * 
     * @type {FilterOptionDtoDto}
     * @memberof SectionDetailsViewDto
     */
    beach: FilterOptionDtoDto;
    /**
     * 
     * @type {FilterOptionDtoDto}
     * @memberof SectionDetailsViewDto
     */
    city: FilterOptionDtoDto;
    /**
     * 
     * @type {FilterOptionDtoDto}
     * @memberof SectionDetailsViewDto
     */
    vendor: FilterOptionDtoDto;
    /**
     * 
     * @type {object}
     * @memberof SectionDetailsViewDto
     */
    geoJson: object;
    /**
     * 
     * @type {object}
     * @memberof SectionDetailsViewDto
     */
    attributes: object;
    /**
     * 
     * @type {string}
     * @memberof SectionDetailsViewDto
     */
    entryName: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SectionDetailsViewDto
     */
    images: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SectionDetailsViewDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof SectionDetailsViewDto
     */
    seoDescription: string;
    /**
     * 
     * @type {string}
     * @memberof SectionDetailsViewDto
     */
    seoShortDescription: string;
    /**
     * 
     * @type {boolean}
     * @memberof SectionDetailsViewDto
     */
    active: boolean;
    /**
     * 
     * @type {Array<BeachChairsDetails>}
     * @memberof SectionDetailsViewDto
     */
    beachChairs: Array<BeachChairsDetails>;
}
/**
 * 
 * @export
 * @interface SectionFilterDto
 */
export interface SectionFilterDto {
    /**
     * 
     * @type {number}
     * @memberof SectionFilterDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof SectionFilterDto
     */
    value: string;
    /**
     * 
     * @type {Array<FilterOptionDto>}
     * @memberof SectionFilterDto
     */
    rows: Array<FilterOptionDto>;
}
/**
 * 
 * @export
 * @interface SectionResponseDto
 */
export interface SectionResponseDto {
    /**
     * 
     * @type {number}
     * @memberof SectionResponseDto
     */
    beachId: number;
    /**
     * 
     * @type {string}
     * @memberof SectionResponseDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SectionResponseDto
     */
    seoDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof SectionResponseDto
     */
    seoShortDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof SectionResponseDto
     */
    description: string;
    /**
     * 
     * @type {object}
     * @memberof SectionResponseDto
     */
    geoJson: object;
    /**
     * 
     * @type {object}
     * @memberof SectionResponseDto
     */
    attributes: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof SectionResponseDto
     */
    images?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof SectionResponseDto
     */
    locationId?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionResponseDto
     */
    beachPlan: string;
    /**
     * 
     * @type {number}
     * @memberof SectionResponseDto
     */
    vendorId: number;
    /**
     * 
     * @type {boolean}
     * @memberof SectionResponseDto
     */
    active?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SectionResponseDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof SectionResponseDto
     */
    vendorName: string;
    /**
     * 
     * @type {number}
     * @memberof SectionResponseDto
     */
    cityId: number;
    /**
     * 
     * @type {LocationResponseDto}
     * @memberof SectionResponseDto
     */
    location: LocationResponseDto;
    /**
     * 
     * @type {VendorResponseDto}
     * @memberof SectionResponseDto
     */
    vendor: VendorResponseDto;
}
/**
 * 
 * @export
 * @interface SectionTimeFilterParamDto
 */
export interface SectionTimeFilterParamDto {
    /**
     * 
     * @type {string}
     * @memberof SectionTimeFilterParamDto
     */
    start: string;
    /**
     * 
     * @type {string}
     * @memberof SectionTimeFilterParamDto
     */
    end?: string;
    /**
     * Id of the row which to beach chair is assigned
     * @type {number}
     * @memberof SectionTimeFilterParamDto
     */
    row?: number;
    /**
     * 
     * @type {BeachChairModel}
     * @memberof SectionTimeFilterParamDto
     */
    model?: BeachChairModel;
    /**
     * 
     * @type {boolean}
     * @memberof SectionTimeFilterParamDto
     */
    free?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SectionTimeFilterParamDto
     */
    timeZone?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SectionTimeFilterParamDto
     */
    lock?: boolean;
}
/**
 * 
 * @export
 * @interface SectionTimeFrameBookingDataResponseDto
 */
export interface SectionTimeFrameBookingDataResponseDto {
    /**
     * 
     * @type {Array<GroupDto>}
     * @memberof SectionTimeFrameBookingDataResponseDto
     */
    groups: Array<GroupDto>;
    /**
     * 
     * @type {Array<ItemDto>}
     * @memberof SectionTimeFrameBookingDataResponseDto
     */
    items: Array<ItemDto>;
}
/**
 * 
 * @export
 * @interface SectionTimeFrameRowDataResponseDto
 */
export interface SectionTimeFrameRowDataResponseDto {
    /**
     * 
     * @type {string}
     * @memberof SectionTimeFrameRowDataResponseDto
     */
    sectionName: string;
    /**
     * 
     * @type {string}
     * @memberof SectionTimeFrameRowDataResponseDto
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof SectionTimeFrameRowDataResponseDto
     */
    id: number;
    /**
     * 
     * @type {object}
     * @memberof SectionTimeFrameRowDataResponseDto
     */
    geoJson: object;
    /**
     * 
     * @type {RowDataDto}
     * @memberof SectionTimeFrameRowDataResponseDto
     */
    rowData: RowDataDto;
}
/**
 * 
 * @export
 * @interface SectionsFiltersDto
 */
export interface SectionsFiltersDto {
    /**
     * 
     * @type {number}
     * @memberof SectionsFiltersDto
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof SectionsFiltersDto
     */
    beachId?: number;
    /**
     * 
     * @type {number}
     * @memberof SectionsFiltersDto
     */
    vendorId?: number;
    /**
     * 
     * @type {string}
     * @memberof SectionsFiltersDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof SectionsFiltersDto
     */
    vendorName?: string;
    /**
     * 
     * @type {string}
     * @memberof SectionsFiltersDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof SectionsFiltersDto
     */
    publicReference?: string;
    /**
     * 
     * @type {number}
     * @memberof SectionsFiltersDto
     */
    locationId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SectionsFiltersDto
     */
    onlineBooking?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SectionsFiltersDto
     */
    active?: boolean;
}
/**
 * 
 * @export
 * @interface SectionsStatisticsResponseDto
 */
export interface SectionsStatisticsResponseDto {
    /**
     * 
     * @type {number}
     * @memberof SectionsStatisticsResponseDto
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof SectionsStatisticsResponseDto
     */
    taken: number;
    /**
     * 
     * @type {number}
     * @memberof SectionsStatisticsResponseDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof SectionsStatisticsResponseDto
     */
    sectionId: number;
    /**
     * 
     * @type {number}
     * @memberof SectionsStatisticsResponseDto
     */
    vendorId: number;
    /**
     * 
     * @type {string}
     * @memberof SectionsStatisticsResponseDto
     */
    reportDate: string;
}
/**
 * 
 * @export
 * @interface SetLocationContextDto
 */
export interface SetLocationContextDto {
    /**
     * 
     * @type {number}
     * @memberof SetLocationContextDto
     */
    locationId: number;
}
/**
 * 
 * @export
 * @interface SetVendorContextDto
 */
export interface SetVendorContextDto {
    /**
     * 
     * @type {number}
     * @memberof SetVendorContextDto
     */
    vendorId: number;
}
/**
 * 
 * @export
 * @interface StripeBankData
 */
export interface StripeBankData {
    /**
     * 
     * @type {string}
     * @memberof StripeBankData
     */
    iban: string;
    /**
     * 
     * @type {string}
     * @memberof StripeBankData
     */
    bic: string;
    /**
     * 
     * @type {string}
     * @memberof StripeBankData
     */
    accountHolder: string;
    /**
     * 
     * @type {string}
     * @memberof StripeBankData
     */
    reference: string;
}
/**
 * 
 * @export
 * @interface StripeDetailsDto
 */
export interface StripeDetailsDto {
    /**
     * 
     * @type {string}
     * @memberof StripeDetailsDto
     */
    customerId?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum StripeFlow {
    Direct = 'DIRECT',
    Destination = 'DESTINATION'
}

/**
 * 
 * @export
 * @interface UpdateBeachBodyDto
 */
export interface UpdateBeachBodyDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateBeachBodyDto
     */
    cityId?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateBeachBodyDto
     */
    regionId?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateBeachBodyDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBeachBodyDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBeachBodyDto
     */
    shortDescription?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateBeachBodyDto
     */
    active?: boolean;
    /**
     * 
     * @type {object}
     * @memberof UpdateBeachBodyDto
     */
    attributes?: object;
    /**
     * 
     * @type {object}
     * @memberof UpdateBeachBodyDto
     */
    geoJson?: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateBeachBodyDto
     */
    images?: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateBeachChairBodyDto
 */
export interface UpdateBeachChairBodyDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateBeachChairBodyDto
     */
    vendorId: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateBeachChairBodyDto
     */
    model: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateBeachChairBodyDto
     */
    sectionId: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateBeachChairBodyDto
     */
    rowId: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateBeachChairBodyDto
     */
    stopSaleDates?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof UpdateBeachChairBodyDto
     */
    lockId?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateBeachChairBodyDto
     */
    publicNumber: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBeachChairBodyDto
     */
    what3words?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBeachChairBodyDto
     */
    internalNote?: string;
    /**
     * 
     * @type {object}
     * @memberof UpdateBeachChairBodyDto
     */
    geoJson: object;
    /**
     * 
     * @type {string}
     * @memberof UpdateBeachChairBodyDto
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateBeachChairBodyDto
     */
    afterHourBooking?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateBeachChairBodyDto
     */
    buyingDate?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBeachChairBodyDto
     */
    status: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateBeachChairBodyDto
     */
    onlineBooking?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateBeachChairBodyDto
     */
    seasonBeachChair?: boolean;
    /**
     * 
     * @type {BeachChairDetails}
     * @memberof UpdateBeachChairBodyDto
     */
    details?: BeachChairDetails;
    /**
     * 
     * @type {BeachChairAttributes}
     * @memberof UpdateBeachChairBodyDto
     */
    attributes?: BeachChairAttributes;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateBeachChairBodyDto
     */
    images?: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateBeachChairBookingBodyDto
 */
export interface UpdateBeachChairBookingBodyDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateBeachChairBookingBodyDto
     */
    beachChairId?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateBeachChairBookingBodyDto
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBeachChairBookingBodyDto
     */
    start?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBeachChairBookingBodyDto
     */
    end?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateBeachChairBookingBodyDto
     */
    price?: number;
}
/**
 * 
 * @export
 * @interface UpdateBookingBodyDto
 */
export interface UpdateBookingBodyDto {
    /**
     * 
     * @type {BookingStatus}
     * @memberof UpdateBookingBodyDto
     */
    status?: BookingStatus;
    /**
     * 
     * @type {number}
     * @memberof UpdateBookingBodyDto
     */
    totalPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateBookingBodyDto
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBookingBodyDto
     */
    customerComment?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateBookingBodyDto
     */
    customerId?: number;
    /**
     * 
     * @type {BookingPaymentMethod}
     * @memberof UpdateBookingBodyDto
     */
    paymentMethod?: BookingPaymentMethod;
    /**
     * 
     * @type {string}
     * @memberof UpdateBookingBodyDto
     */
    invoiceNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBookingBodyDto
     */
    cancellationNumber?: string;
    /**
     * 
     * @type {BookingCommission}
     * @memberof UpdateBookingBodyDto
     */
    commission?: BookingCommission;
    /**
     * 
     * @type {MetaData}
     * @memberof UpdateBookingBodyDto
     */
    metaData?: MetaData;
}
/**
 * 
 * @export
 * @interface UpdateCityBodyDto
 */
export interface UpdateCityBodyDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateCityBodyDto
     */
    regionId?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateCityBodyDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCityBodyDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCityBodyDto
     */
    shortDescription?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCityBodyDto
     */
    active?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateCityBodyDto
     */
    images?: Array<string>;
    /**
     * 
     * @type {object}
     * @memberof UpdateCityBodyDto
     */
    geoJson?: object;
}
/**
 * 
 * @export
 * @interface UpdateCustomerBodyDto
 */
export interface UpdateCustomerBodyDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerBodyDto
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerBodyDto
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerBodyDto
     */
    salutation?: string;
    /**
     * 
     * @type {CustomerAddressNestedDto}
     * @memberof UpdateCustomerBodyDto
     */
    address?: CustomerAddressNestedDto;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerBodyDto
     */
    telephone?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerBodyDto
     */
    email?: string;
    /**
     * 
     * @type {Languages}
     * @memberof UpdateCustomerBodyDto
     */
    language?: Languages;
    /**
     * 
     * @type {BankDetails}
     * @memberof UpdateCustomerBodyDto
     */
    bankDetails?: BankDetails;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCustomerBodyDto
     */
    _default?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateCustomerBodyDto
     */
    userId?: string;
    /**
     * 
     * @type {StripeDetailsDto}
     * @memberof UpdateCustomerBodyDto
     */
    stripe?: StripeDetailsDto;
    /**
     * 
     * @type {AdditionalDataDto}
     * @memberof UpdateCustomerBodyDto
     */
    additionalData?: AdditionalDataDto;
}
/**
 * 
 * @export
 * @interface UpdateDetails
 */
export interface UpdateDetails {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateDetails
     */
    openingHours?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateDetails
     */
    exceptionOpeningHours?: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateExternalServicesDto
 */
export interface UpdateExternalServicesDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateExternalServicesDto
     */
    locationId?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateExternalServicesDto
     */
    price?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateExternalServicesDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateExternalServicesDto
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateExternalServicesDto
     */
    active?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateExternalServicesDto
     */
    vat?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateExternalServicesDto
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface UpdateExtrasBookingDto
 */
export interface UpdateExtrasBookingDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateExtrasBookingDto
     */
    amount?: number;
}
/**
 * 
 * @export
 * @interface UpdateExtrasDto
 */
export interface UpdateExtrasDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateExtrasDto
     */
    locationId?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateExtrasDto
     */
    price?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateExtrasDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateExtrasDto
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateExtrasDto
     */
    vat?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateExtrasDto
     */
    type?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateExtrasDto
     */
    images?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateExtrasDto
     */
    onlineBooking?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateItemsDto
 */
export interface UpdateItemsDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateItemsDto
     */
    locationId?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateItemsDto
     */
    foodCategoryId?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateItemsDto
     */
    price?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateItemsDto
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateItemsDto
     */
    active?: boolean;
    /**
     * 
     * @type {object}
     * @memberof UpdateItemsDto
     */
    ingredients?: object;
    /**
     * 
     * @type {string}
     * @memberof UpdateItemsDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateItemsDto
     */
    shortDescription?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateItemsDto
     */
    vat?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateItemsDto
     */
    images?: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateLocationAddress
 */
export interface UpdateLocationAddress {
    /**
     * 
     * @type {string}
     * @memberof UpdateLocationAddress
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLocationAddress
     */
    zip?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLocationAddress
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLocationAddress
     */
    country?: string;
}
/**
 * 
 * @export
 * @interface UpdateLocationDto
 */
export interface UpdateLocationDto {
    /**
     * Location id
     * @type {number}
     * @memberof UpdateLocationDto
     */
    beachId?: number;
    /**
     * Lead time
     * @type {number}
     * @memberof UpdateLocationDto
     */
    leadTimeHours?: number;
    /**
     * Lead time limit hour, can be used instead of leadTimeHours
     * @type {string}
     * @memberof UpdateLocationDto
     */
    leadTimeLimit?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLocationDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLocationDto
     */
    checkoutTime?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLocationDto
     */
    description?: string;
    /**
     * 
     * @type {object}
     * @memberof UpdateLocationDto
     */
    geoJson?: object;
    /**
     * 
     * @type {string}
     * @memberof UpdateLocationDto
     */
    seasonStart?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLocationDto
     */
    seasonEnd?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLocationDto
     */
    entranceName?: string;
    /**
     * 
     * @type {object}
     * @memberof UpdateLocationDto
     */
    terms?: object;
    /**
     * 
     * @type {object}
     * @memberof UpdateLocationDto
     */
    priceText?: object;
    /**
     * 
     * @type {string}
     * @memberof UpdateLocationDto
     */
    mapImageUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateLocationDto
     */
    foodDelivery?: boolean;
    /**
     * 
     * @type {UpdateDetails}
     * @memberof UpdateLocationDto
     */
    details?: UpdateDetails;
    /**
     * 
     * @type {UpdateLocationAddress}
     * @memberof UpdateLocationDto
     */
    address?: UpdateLocationAddress;
    /**
     * 
     * @type {number}
     * @memberof UpdateLocationDto
     */
    vendorId?: number;
}
/**
 * 
 * @export
 * @interface UpdateLockBodyDto
 */
export interface UpdateLockBodyDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateLockBodyDto
     */
    number?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLockBodyDto
     */
    bcd?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLockBodyDto
     */
    mac?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLockBodyDto
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLockBodyDto
     */
    secondaryPassword?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateLockBodyDto
     */
    vendorId?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateLockBodyDto
     */
    status?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateLockBodyDto
     */
    opened?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateLockBodyDto
     */
    batteryLevel?: number;
    /**
     * 
     * @type {NestedLockDetailsBodyDto}
     * @memberof UpdateLockBodyDto
     */
    details?: NestedLockDetailsBodyDto;
}
/**
 * 
 * @export
 * @interface UpdatePriceDto
 */
export interface UpdatePriceDto {
    /**
     * 
     * @type {number}
     * @memberof UpdatePriceDto
     */
    vendorId?: number;
    /**
     * 
     * @type {PriceRules}
     * @memberof UpdatePriceDto
     */
    rules?: PriceRules;
    /**
     * 
     * @type {string}
     * @memberof UpdatePriceDto
     */
    bookingStart?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePriceDto
     */
    bookingEnd?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePriceDto
     */
    offerStart?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePriceDto
     */
    offerEnd?: string;
}
/**
 * 
 * @export
 * @interface UpdateRegionBodyDto
 */
export interface UpdateRegionBodyDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateRegionBodyDto
     */
    countryId?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateRegionBodyDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRegionBodyDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRegionBodyDto
     */
    shortDescription?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateRegionBodyDto
     */
    images?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateRegionBodyDto
     */
    active?: boolean;
    /**
     * 
     * @type {object}
     * @memberof UpdateRegionBodyDto
     */
    geoJson?: object;
}
/**
 * 
 * @export
 * @interface UpdateRowBodyDto
 */
export interface UpdateRowBodyDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateRowBodyDto
     */
    sectionId?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateRowBodyDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRowBodyDto
     */
    description?: string;
    /**
     * 
     * @type {object}
     * @memberof UpdateRowBodyDto
     */
    geoJson?: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateRowBodyDto
     */
    beachChairOrder?: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateSectionBodyDto
 */
export interface UpdateSectionBodyDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateSectionBodyDto
     */
    beachId?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateSectionBodyDto
     */
    rowOrder?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateSectionBodyDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSectionBodyDto
     */
    seoDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSectionBodyDto
     */
    seoShortDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSectionBodyDto
     */
    description?: string;
    /**
     * 
     * @type {object}
     * @memberof UpdateSectionBodyDto
     */
    geoJson?: object;
    /**
     * 
     * @type {object}
     * @memberof UpdateSectionBodyDto
     */
    attributes?: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateSectionBodyDto
     */
    images?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof UpdateSectionBodyDto
     */
    locationId?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateSectionBodyDto
     */
    beachPlan?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateSectionBodyDto
     */
    vendorId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateSectionBodyDto
     */
    active?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateUserBodyDto
 */
export interface UpdateUserBodyDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserBodyDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserBodyDto
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserBodyDto
     */
    lastName?: string;
    /**
     * 
     * @type {Salutation}
     * @memberof UpdateUserBodyDto
     */
    salutation?: Salutation;
    /**
     * 
     * @type {number}
     * @memberof UpdateUserBodyDto
     */
    defaultLocationId?: number;
}
/**
 * 
 * @export
 * @interface UpdateUserRolesDto
 */
export interface UpdateUserRolesDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateUserRolesDto
     */
    roles: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateVendorDto
 */
export interface UpdateVendorDto {
    /**
     * 
     * @type {Address}
     * @memberof UpdateVendorDto
     */
    address?: Address;
    /**
     * 
     * @type {Address}
     * @memberof UpdateVendorDto
     */
    invoiceAddress?: Address;
    /**
     * 
     * @type {string}
     * @memberof UpdateVendorDto
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateVendorDto
     */
    foodProvider?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateVendorDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateVendorDto
     */
    companyName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateVendorDto
     */
    sectionOrder?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateVendorDto
     */
    allowedHosts?: Array<string>;
    /**
     * 
     * @type {VDetails}
     * @memberof UpdateVendorDto
     */
    details?: VDetails;
    /**
     * 
     * @type {VendorConfiguration}
     * @memberof UpdateVendorDto
     */
    configuration?: VendorConfiguration;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateVendorDto
     */
    activeAvailability?: boolean;
    /**
     * 
     * @type {object}
     * @memberof UpdateVendorDto
     */
    terms?: object;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    user_export_id: string;
}
/**
 * 
 * @export
 * @interface UserAlreadyExistsDto
 */
export interface UserAlreadyExistsDto {
    /**
     * 
     * @type {string}
     * @memberof UserAlreadyExistsDto
     */
    errorCode: string;
    /**
     * 
     * @type {object}
     * @memberof UserAlreadyExistsDto
     */
    payload: object;
}
/**
 * 
 * @export
 * @interface UserCredentialsDto
 */
export interface UserCredentialsDto {
    /**
     * 
     * @type {string}
     * @memberof UserCredentialsDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserCredentialsDto
     */
    password: string;
}
/**
 * 
 * @export
 * @interface UsersFiltersDto
 */
export interface UsersFiltersDto {
    /**
     * 
     * @type {string}
     * @memberof UsersFiltersDto
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersFiltersDto
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UsersFiltersDto
     */
    email?: string;
    /**
     * 
     * @type {number}
     * @memberof UsersFiltersDto
     */
    vendorId?: number;
}
/**
 * 
 * @export
 * @interface VDetails
 */
export interface VDetails {
    /**
     * 
     * @type {string}
     * @memberof VDetails
     */
    website: string;
    /**
     * 
     * @type {string}
     * @memberof VDetails
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof VDetails
     */
    telephone: string;
    /**
     * 
     * @type {string}
     * @memberof VDetails
     */
    taxNumber: string;
    /**
     * 
     * @type {string}
     * @memberof VDetails
     */
    vatId: string;
    /**
     * 
     * @type {boolean}
     * @memberof VDetails
     */
    smallBusiness: boolean;
    /**
     * 
     * @type {string}
     * @memberof VDetails
     */
    logo?: string;
    /**
     * 
     * @type {BankDetails}
     * @memberof VDetails
     */
    bankDetails?: BankDetails;
    /**
     * 
     * @type {ExtraText}
     * @memberof VDetails
     */
    extraText?: ExtraText;
    /**
     * 
     * @type {boolean}
     * @memberof VDetails
     */
    openBeachchairs?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VDetails
     */
    whatsAppNumber?: string;
}
/**
 * 
 * @export
 * @interface VendorBillingConfigResponseDto
 */
export interface VendorBillingConfigResponseDto {
    /**
     * 
     * @type {number}
     * @memberof VendorBillingConfigResponseDto
     */
    vendorId: number;
    /**
     * 
     * @type {boolean}
     * @memberof VendorBillingConfigResponseDto
     */
    billingEnabled: boolean;
    /**
     * 
     * @type {object}
     * @memberof VendorBillingConfigResponseDto
     */
    commission: object;
    /**
     * 
     * @type {object}
     * @memberof VendorBillingConfigResponseDto
     */
    serviceFee: object;
    /**
     * 
     * @type {object}
     * @memberof VendorBillingConfigResponseDto
     */
    bookingDays: object;
}
/**
 * 
 * @export
 * @interface VendorBillingConfigUpdateDto
 */
export interface VendorBillingConfigUpdateDto {
    /**
     * 
     * @type {boolean}
     * @memberof VendorBillingConfigUpdateDto
     */
    billingEnabled: boolean;
    /**
     * 
     * @type {object}
     * @memberof VendorBillingConfigUpdateDto
     */
    commission: object;
    /**
     * 
     * @type {object}
     * @memberof VendorBillingConfigUpdateDto
     */
    serviceFee: object;
    /**
     * 
     * @type {object}
     * @memberof VendorBillingConfigUpdateDto
     */
    bookingDays: object;
}
/**
 * 
 * @export
 * @interface VendorChunkResponseDto
 */
export interface VendorChunkResponseDto {
    /**
     * 
     * @type {Array<VendorResponseDto>}
     * @memberof VendorChunkResponseDto
     */
    items: Array<VendorResponseDto>;
    /**
     * 
     * @type {number}
     * @memberof VendorChunkResponseDto
     */
    count: number;
}
/**
 * 
 * @export
 * @interface VendorConfiguration
 */
export interface VendorConfiguration {
    /**
     * 
     * @type {string}
     * @memberof VendorConfiguration
     */
    widgetUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorConfiguration
     */
    stripeAccountId?: string;
    /**
     * 
     * @type {StripeFlow}
     * @memberof VendorConfiguration
     */
    stripeFlow?: StripeFlow;
    /**
     * 
     * @type {string}
     * @memberof VendorConfiguration
     */
    payPalClientId?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorConfiguration
     */
    payPalClientSecret?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorConfiguration
     */
    SevDeskCustomerId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof VendorConfiguration
     */
    paymentMethods?: Array<string>;
    /**
     * 
     * @type {CommissionFees}
     * @memberof VendorConfiguration
     */
    commission?: CommissionFees;
    /**
     * 
     * @type {boolean}
     * @memberof VendorConfiguration
     */
    reservationCronJob?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VendorConfiguration
     */
    masterKey?: string;
    /**
     * 
     * @type {boolean}
     * @memberof VendorConfiguration
     */
    hideCustomerComment?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VendorConfiguration
     */
    hideOpeningHours?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VendorConfiguration
     */
    gridLineBreak?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VendorConfiguration
     */
    receiptPrint?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VendorConfiguration
     */
    hasTSE?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VendorConfiguration
     */
    bookingStartdate?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorConfiguration
     */
    metabaseUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorConfiguration
     */
    beachChairView?: string;
}
/**
 * 
 * @export
 * @interface VendorDetails
 */
export interface VendorDetails {
    /**
     * 
     * @type {number}
     * @memberof VendorDetails
     */
    id: number;
    /**
     * 
     * @type {Address}
     * @memberof VendorDetails
     */
    address: Address;
    /**
     * 
     * @type {string}
     * @memberof VendorDetails
     */
    companyName: string;
    /**
     * 
     * @type {string}
     * @memberof VendorDetails
     */
    website: string;
    /**
     * 
     * @type {string}
     * @memberof VendorDetails
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof VendorDetails
     */
    telephone: string;
    /**
     * 
     * @type {object}
     * @memberof VendorDetails
     */
    invoiceText?: object;
    /**
     * 
     * @type {object}
     * @memberof VendorDetails
     */
    cancellationText?: object;
    /**
     * 
     * @type {string}
     * @memberof VendorDetails
     */
    logo?: string;
    /**
     * 
     * @type {BankDetails}
     * @memberof VendorDetails
     */
    bankDetails: BankDetails;
    /**
     * 
     * @type {VDetails}
     * @memberof VendorDetails
     */
    details?: VDetails;
}
/**
 * 
 * @export
 * @interface VendorResponseDto
 */
export interface VendorResponseDto {
    /**
     * 
     * @type {Address}
     * @memberof VendorResponseDto
     */
    address: Address;
    /**
     * 
     * @type {Address}
     * @memberof VendorResponseDto
     */
    invoiceAddress?: Address;
    /**
     * 
     * @type {string}
     * @memberof VendorResponseDto
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof VendorResponseDto
     */
    foodProvider: boolean;
    /**
     * 
     * @type {string}
     * @memberof VendorResponseDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorResponseDto
     */
    companyName: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof VendorResponseDto
     */
    sectionOrder: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof VendorResponseDto
     */
    allowedHosts: Array<string>;
    /**
     * 
     * @type {VDetails}
     * @memberof VendorResponseDto
     */
    details: VDetails;
    /**
     * 
     * @type {VendorConfiguration}
     * @memberof VendorResponseDto
     */
    configuration?: VendorConfiguration;
    /**
     * 
     * @type {boolean}
     * @memberof VendorResponseDto
     */
    activeAvailability: boolean;
    /**
     * 
     * @type {object}
     * @memberof VendorResponseDto
     */
    terms?: object;
    /**
     * 
     * @type {number}
     * @memberof VendorResponseDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof VendorResponseDto
     */
    publicReference: string;
}
/**
 * 
 * @export
 * @interface VendorsFiltersDto
 */
export interface VendorsFiltersDto {
    /**
     * 
     * @type {number}
     * @memberof VendorsFiltersDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof VendorsFiltersDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorsFiltersDto
     */
    companyName?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorsFiltersDto
     */
    city?: string;
    /**
     * 
     * @type {boolean}
     * @memberof VendorsFiltersDto
     */
    activeAvailability?: boolean;
}

/**
 * BeachApi - axios parameter creator
 * @export
 */
export const BeachApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateBeachBodyDto} createBeachBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beachesControllerCreate: async (createBeachBodyDto: CreateBeachBodyDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createBeachBodyDto' is not null or undefined
            assertParamExists('beachesControllerCreate', 'createBeachBodyDto', createBeachBodyDto)
            const localVarPath = `/api/v1/destinations/beaches`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBeachBodyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beachesControllerDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('beachesControllerDelete', 'id', id)
            const localVarPath = `/api/v1/destinations/beaches/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {BeachesFiltersDto} [filters] 
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beachesControllerFindMany: async (page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, filters?: BeachesFiltersDto, csv?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/destinations/beaches`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['orderDir'] = orderDir;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (filterBy !== undefined) {
                localVarQueryParameter['filterBy'] = filterBy;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (filters !== undefined) {
                localVarQueryParameter['filters'] = filters;
            }

            if (csv !== undefined) {
                localVarQueryParameter['csv'] = csv;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beachesControllerFindOne: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('beachesControllerFindOne', 'id', id)
            const localVarPath = `/api/v1/destinations/beaches/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateBeachBodyDto} updateBeachBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beachesControllerUpdate: async (id: number, updateBeachBodyDto: UpdateBeachBodyDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('beachesControllerUpdate', 'id', id)
            // verify required parameter 'updateBeachBodyDto' is not null or undefined
            assertParamExists('beachesControllerUpdate', 'updateBeachBodyDto', updateBeachBodyDto)
            const localVarPath = `/api/v1/destinations/beaches/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBeachBodyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BeachApi - functional programming interface
 * @export
 */
export const BeachApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BeachApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateBeachBodyDto} createBeachBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async beachesControllerCreate(createBeachBodyDto: CreateBeachBodyDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BeachResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.beachesControllerCreate(createBeachBodyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async beachesControllerDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.beachesControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {BeachesFiltersDto} [filters] 
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async beachesControllerFindMany(page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, filters?: BeachesFiltersDto, csv?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BeachChunkResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.beachesControllerFindMany(page, itemsPerPage, orderBy, orderDir, search, filterBy, filter, filters, csv, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async beachesControllerFindOne(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BeachResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.beachesControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateBeachBodyDto} updateBeachBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async beachesControllerUpdate(id: number, updateBeachBodyDto: UpdateBeachBodyDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.beachesControllerUpdate(id, updateBeachBodyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BeachApi - factory interface
 * @export
 */
export const BeachApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BeachApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateBeachBodyDto} createBeachBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beachesControllerCreate(createBeachBodyDto: CreateBeachBodyDto, options?: any): AxiosPromise<BeachResponseDto> {
            return localVarFp.beachesControllerCreate(createBeachBodyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beachesControllerDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.beachesControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {BeachesFiltersDto} [filters] 
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beachesControllerFindMany(page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, filters?: BeachesFiltersDto, csv?: string, options?: any): AxiosPromise<BeachChunkResponseDto> {
            return localVarFp.beachesControllerFindMany(page, itemsPerPage, orderBy, orderDir, search, filterBy, filter, filters, csv, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beachesControllerFindOne(id: number, options?: any): AxiosPromise<BeachResponseDto> {
            return localVarFp.beachesControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateBeachBodyDto} updateBeachBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beachesControllerUpdate(id: number, updateBeachBodyDto: UpdateBeachBodyDto, options?: any): AxiosPromise<void> {
            return localVarFp.beachesControllerUpdate(id, updateBeachBodyDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for beachesControllerCreate operation in BeachApi.
 * @export
 * @interface BeachApiBeachesControllerCreateRequest
 */
export interface BeachApiBeachesControllerCreateRequest {
    /**
     * 
     * @type {CreateBeachBodyDto}
     * @memberof BeachApiBeachesControllerCreate
     */
    readonly createBeachBodyDto: CreateBeachBodyDto
}

/**
 * Request parameters for beachesControllerDelete operation in BeachApi.
 * @export
 * @interface BeachApiBeachesControllerDeleteRequest
 */
export interface BeachApiBeachesControllerDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof BeachApiBeachesControllerDelete
     */
    readonly id: number
}

/**
 * Request parameters for beachesControllerFindMany operation in BeachApi.
 * @export
 * @interface BeachApiBeachesControllerFindManyRequest
 */
export interface BeachApiBeachesControllerFindManyRequest {
    /**
     * Number of the page to fetch
     * @type {number}
     * @memberof BeachApiBeachesControllerFindMany
     */
    readonly page?: number

    /**
     * Number of items per page
     * @type {number}
     * @memberof BeachApiBeachesControllerFindMany
     */
    readonly itemsPerPage?: number

    /**
     * Name of the property to order by
     * @type {string}
     * @memberof BeachApiBeachesControllerFindMany
     */
    readonly orderBy?: string

    /**
     * Value which specify direction of the order
     * @type {OrderByDirection}
     * @memberof BeachApiBeachesControllerFindMany
     */
    readonly orderDir?: OrderByDirection

    /**
     * Text which will be used to search through table data
     * @type {string}
     * @memberof BeachApiBeachesControllerFindMany
     */
    readonly search?: string

    /**
     * Name of the property to filter by
     * @type {string}
     * @memberof BeachApiBeachesControllerFindMany
     */
    readonly filterBy?: string

    /**
     * Value which will be used to filter table data
     * @type {string}
     * @memberof BeachApiBeachesControllerFindMany
     */
    readonly filter?: string

    /**
     * 
     * @type {BeachesFiltersDto}
     * @memberof BeachApiBeachesControllerFindMany
     */
    readonly filters?: BeachesFiltersDto

    /**
     * 
     * @type {string}
     * @memberof BeachApiBeachesControllerFindMany
     */
    readonly csv?: string
}

/**
 * Request parameters for beachesControllerFindOne operation in BeachApi.
 * @export
 * @interface BeachApiBeachesControllerFindOneRequest
 */
export interface BeachApiBeachesControllerFindOneRequest {
    /**
     * 
     * @type {number}
     * @memberof BeachApiBeachesControllerFindOne
     */
    readonly id: number
}

/**
 * Request parameters for beachesControllerUpdate operation in BeachApi.
 * @export
 * @interface BeachApiBeachesControllerUpdateRequest
 */
export interface BeachApiBeachesControllerUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof BeachApiBeachesControllerUpdate
     */
    readonly id: number

    /**
     * 
     * @type {UpdateBeachBodyDto}
     * @memberof BeachApiBeachesControllerUpdate
     */
    readonly updateBeachBodyDto: UpdateBeachBodyDto
}

/**
 * BeachApi - object-oriented interface
 * @export
 * @class BeachApi
 * @extends {BaseAPI}
 */
export class BeachApi extends BaseAPI {
    /**
     * 
     * @param {BeachApiBeachesControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BeachApi
     */
    public beachesControllerCreate(requestParameters: BeachApiBeachesControllerCreateRequest, options?: any) {
        return BeachApiFp(this.configuration).beachesControllerCreate(requestParameters.createBeachBodyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BeachApiBeachesControllerDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BeachApi
     */
    public beachesControllerDelete(requestParameters: BeachApiBeachesControllerDeleteRequest, options?: any) {
        return BeachApiFp(this.configuration).beachesControllerDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BeachApiBeachesControllerFindManyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BeachApi
     */
    public beachesControllerFindMany(requestParameters: BeachApiBeachesControllerFindManyRequest = {}, options?: any) {
        return BeachApiFp(this.configuration).beachesControllerFindMany(requestParameters.page, requestParameters.itemsPerPage, requestParameters.orderBy, requestParameters.orderDir, requestParameters.search, requestParameters.filterBy, requestParameters.filter, requestParameters.filters, requestParameters.csv, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BeachApiBeachesControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BeachApi
     */
    public beachesControllerFindOne(requestParameters: BeachApiBeachesControllerFindOneRequest, options?: any) {
        return BeachApiFp(this.configuration).beachesControllerFindOne(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BeachApiBeachesControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BeachApi
     */
    public beachesControllerUpdate(requestParameters: BeachApiBeachesControllerUpdateRequest, options?: any) {
        return BeachApiFp(this.configuration).beachesControllerUpdate(requestParameters.id, requestParameters.updateBeachBodyDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BeachChairsApi - axios parameter creator
 * @export
 */
export const BeachChairsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BatchUpdateBeachChairBodyDto} batchUpdateBeachChairBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beachChairsControllerBatchUpdate: async (batchUpdateBeachChairBodyDto: BatchUpdateBeachChairBodyDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'batchUpdateBeachChairBodyDto' is not null or undefined
            assertParamExists('beachChairsControllerBatchUpdate', 'batchUpdateBeachChairBodyDto', batchUpdateBeachChairBodyDto)
            const localVarPath = `/api/v1/bookings/beach-chairs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(batchUpdateBeachChairBodyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id ID of the beach chair
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beachChairsControllerCancel: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('beachChairsControllerCancel', 'id', id)
            const localVarPath = `/api/v1/bookings/beach-chairs/cancel/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateBeachChairBodyDto} createBeachChairBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beachChairsControllerCreate: async (createBeachChairBodyDto: CreateBeachChairBodyDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createBeachChairBodyDto' is not null or undefined
            assertParamExists('beachChairsControllerCreate', 'createBeachChairBodyDto', createBeachChairBodyDto)
            const localVarPath = `/api/v1/bookings/beach-chairs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBeachChairBodyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id ID of the beach chair
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beachChairsControllerDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('beachChairsControllerDelete', 'id', id)
            const localVarPath = `/api/v1/bookings/beach-chairs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {BeachChairsFiltersDto} [filters] 
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beachChairsControllerFindMany: async (page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, filters?: BeachChairsFiltersDto, csv?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/bookings/beach-chairs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['orderDir'] = orderDir;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (filterBy !== undefined) {
                localVarQueryParameter['filterBy'] = filterBy;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (filters !== undefined) {
                localVarQueryParameter['filters'] = filters;
            }

            if (csv !== undefined) {
                localVarQueryParameter['csv'] = csv;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id ID of the beach chair
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beachChairsControllerFindOne: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('beachChairsControllerFindOne', 'id', id)
            const localVarPath = `/api/v1/bookings/beach-chairs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id ID of the beach chair
         * @param {UpdateBeachChairBodyDto} updateBeachChairBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beachChairsControllerUpdate: async (id: number, updateBeachChairBodyDto: UpdateBeachChairBodyDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('beachChairsControllerUpdate', 'id', id)
            // verify required parameter 'updateBeachChairBodyDto' is not null or undefined
            assertParamExists('beachChairsControllerUpdate', 'updateBeachChairBodyDto', updateBeachChairBodyDto)
            const localVarPath = `/api/v1/bookings/beach-chairs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBeachChairBodyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BeachChairsApi - functional programming interface
 * @export
 */
export const BeachChairsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BeachChairsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {BatchUpdateBeachChairBodyDto} batchUpdateBeachChairBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async beachChairsControllerBatchUpdate(batchUpdateBeachChairBodyDto: BatchUpdateBeachChairBodyDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BeachChairResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.beachChairsControllerBatchUpdate(batchUpdateBeachChairBodyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id ID of the beach chair
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async beachChairsControllerCancel(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.beachChairsControllerCancel(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateBeachChairBodyDto} createBeachChairBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async beachChairsControllerCreate(createBeachChairBodyDto: CreateBeachChairBodyDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BeachChairResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.beachChairsControllerCreate(createBeachChairBodyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id ID of the beach chair
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async beachChairsControllerDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.beachChairsControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {BeachChairsFiltersDto} [filters] 
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async beachChairsControllerFindMany(page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, filters?: BeachChairsFiltersDto, csv?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BeachChairChunkResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.beachChairsControllerFindMany(page, itemsPerPage, orderBy, orderDir, search, filterBy, filter, filters, csv, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id ID of the beach chair
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async beachChairsControllerFindOne(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BeachChairResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.beachChairsControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id ID of the beach chair
         * @param {UpdateBeachChairBodyDto} updateBeachChairBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async beachChairsControllerUpdate(id: number, updateBeachChairBodyDto: UpdateBeachChairBodyDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BeachChairResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.beachChairsControllerUpdate(id, updateBeachChairBodyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BeachChairsApi - factory interface
 * @export
 */
export const BeachChairsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BeachChairsApiFp(configuration)
    return {
        /**
         * 
         * @param {BatchUpdateBeachChairBodyDto} batchUpdateBeachChairBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beachChairsControllerBatchUpdate(batchUpdateBeachChairBodyDto: BatchUpdateBeachChairBodyDto, options?: any): AxiosPromise<BeachChairResponseDto> {
            return localVarFp.beachChairsControllerBatchUpdate(batchUpdateBeachChairBodyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id ID of the beach chair
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beachChairsControllerCancel(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.beachChairsControllerCancel(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateBeachChairBodyDto} createBeachChairBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beachChairsControllerCreate(createBeachChairBodyDto: CreateBeachChairBodyDto, options?: any): AxiosPromise<BeachChairResponseDto> {
            return localVarFp.beachChairsControllerCreate(createBeachChairBodyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id ID of the beach chair
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beachChairsControllerDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.beachChairsControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {BeachChairsFiltersDto} [filters] 
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beachChairsControllerFindMany(page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, filters?: BeachChairsFiltersDto, csv?: string, options?: any): AxiosPromise<BeachChairChunkResponseDto> {
            return localVarFp.beachChairsControllerFindMany(page, itemsPerPage, orderBy, orderDir, search, filterBy, filter, filters, csv, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id ID of the beach chair
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beachChairsControllerFindOne(id: number, options?: any): AxiosPromise<BeachChairResponseDto> {
            return localVarFp.beachChairsControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id ID of the beach chair
         * @param {UpdateBeachChairBodyDto} updateBeachChairBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beachChairsControllerUpdate(id: number, updateBeachChairBodyDto: UpdateBeachChairBodyDto, options?: any): AxiosPromise<BeachChairResponseDto> {
            return localVarFp.beachChairsControllerUpdate(id, updateBeachChairBodyDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for beachChairsControllerBatchUpdate operation in BeachChairsApi.
 * @export
 * @interface BeachChairsApiBeachChairsControllerBatchUpdateRequest
 */
export interface BeachChairsApiBeachChairsControllerBatchUpdateRequest {
    /**
     * 
     * @type {BatchUpdateBeachChairBodyDto}
     * @memberof BeachChairsApiBeachChairsControllerBatchUpdate
     */
    readonly batchUpdateBeachChairBodyDto: BatchUpdateBeachChairBodyDto
}

/**
 * Request parameters for beachChairsControllerCancel operation in BeachChairsApi.
 * @export
 * @interface BeachChairsApiBeachChairsControllerCancelRequest
 */
export interface BeachChairsApiBeachChairsControllerCancelRequest {
    /**
     * ID of the beach chair
     * @type {number}
     * @memberof BeachChairsApiBeachChairsControllerCancel
     */
    readonly id: number
}

/**
 * Request parameters for beachChairsControllerCreate operation in BeachChairsApi.
 * @export
 * @interface BeachChairsApiBeachChairsControllerCreateRequest
 */
export interface BeachChairsApiBeachChairsControllerCreateRequest {
    /**
     * 
     * @type {CreateBeachChairBodyDto}
     * @memberof BeachChairsApiBeachChairsControllerCreate
     */
    readonly createBeachChairBodyDto: CreateBeachChairBodyDto
}

/**
 * Request parameters for beachChairsControllerDelete operation in BeachChairsApi.
 * @export
 * @interface BeachChairsApiBeachChairsControllerDeleteRequest
 */
export interface BeachChairsApiBeachChairsControllerDeleteRequest {
    /**
     * ID of the beach chair
     * @type {number}
     * @memberof BeachChairsApiBeachChairsControllerDelete
     */
    readonly id: number
}

/**
 * Request parameters for beachChairsControllerFindMany operation in BeachChairsApi.
 * @export
 * @interface BeachChairsApiBeachChairsControllerFindManyRequest
 */
export interface BeachChairsApiBeachChairsControllerFindManyRequest {
    /**
     * Number of the page to fetch
     * @type {number}
     * @memberof BeachChairsApiBeachChairsControllerFindMany
     */
    readonly page?: number

    /**
     * Number of items per page
     * @type {number}
     * @memberof BeachChairsApiBeachChairsControllerFindMany
     */
    readonly itemsPerPage?: number

    /**
     * Name of the property to order by
     * @type {string}
     * @memberof BeachChairsApiBeachChairsControllerFindMany
     */
    readonly orderBy?: string

    /**
     * Value which specify direction of the order
     * @type {OrderByDirection}
     * @memberof BeachChairsApiBeachChairsControllerFindMany
     */
    readonly orderDir?: OrderByDirection

    /**
     * Text which will be used to search through table data
     * @type {string}
     * @memberof BeachChairsApiBeachChairsControllerFindMany
     */
    readonly search?: string

    /**
     * Name of the property to filter by
     * @type {string}
     * @memberof BeachChairsApiBeachChairsControllerFindMany
     */
    readonly filterBy?: string

    /**
     * Value which will be used to filter table data
     * @type {string}
     * @memberof BeachChairsApiBeachChairsControllerFindMany
     */
    readonly filter?: string

    /**
     * 
     * @type {BeachChairsFiltersDto}
     * @memberof BeachChairsApiBeachChairsControllerFindMany
     */
    readonly filters?: BeachChairsFiltersDto

    /**
     * 
     * @type {string}
     * @memberof BeachChairsApiBeachChairsControllerFindMany
     */
    readonly csv?: string
}

/**
 * Request parameters for beachChairsControllerFindOne operation in BeachChairsApi.
 * @export
 * @interface BeachChairsApiBeachChairsControllerFindOneRequest
 */
export interface BeachChairsApiBeachChairsControllerFindOneRequest {
    /**
     * ID of the beach chair
     * @type {number}
     * @memberof BeachChairsApiBeachChairsControllerFindOne
     */
    readonly id: number
}

/**
 * Request parameters for beachChairsControllerUpdate operation in BeachChairsApi.
 * @export
 * @interface BeachChairsApiBeachChairsControllerUpdateRequest
 */
export interface BeachChairsApiBeachChairsControllerUpdateRequest {
    /**
     * ID of the beach chair
     * @type {number}
     * @memberof BeachChairsApiBeachChairsControllerUpdate
     */
    readonly id: number

    /**
     * 
     * @type {UpdateBeachChairBodyDto}
     * @memberof BeachChairsApiBeachChairsControllerUpdate
     */
    readonly updateBeachChairBodyDto: UpdateBeachChairBodyDto
}

/**
 * BeachChairsApi - object-oriented interface
 * @export
 * @class BeachChairsApi
 * @extends {BaseAPI}
 */
export class BeachChairsApi extends BaseAPI {
    /**
     * 
     * @param {BeachChairsApiBeachChairsControllerBatchUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BeachChairsApi
     */
    public beachChairsControllerBatchUpdate(requestParameters: BeachChairsApiBeachChairsControllerBatchUpdateRequest, options?: any) {
        return BeachChairsApiFp(this.configuration).beachChairsControllerBatchUpdate(requestParameters.batchUpdateBeachChairBodyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BeachChairsApiBeachChairsControllerCancelRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BeachChairsApi
     */
    public beachChairsControllerCancel(requestParameters: BeachChairsApiBeachChairsControllerCancelRequest, options?: any) {
        return BeachChairsApiFp(this.configuration).beachChairsControllerCancel(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BeachChairsApiBeachChairsControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BeachChairsApi
     */
    public beachChairsControllerCreate(requestParameters: BeachChairsApiBeachChairsControllerCreateRequest, options?: any) {
        return BeachChairsApiFp(this.configuration).beachChairsControllerCreate(requestParameters.createBeachChairBodyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BeachChairsApiBeachChairsControllerDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BeachChairsApi
     */
    public beachChairsControllerDelete(requestParameters: BeachChairsApiBeachChairsControllerDeleteRequest, options?: any) {
        return BeachChairsApiFp(this.configuration).beachChairsControllerDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BeachChairsApiBeachChairsControllerFindManyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BeachChairsApi
     */
    public beachChairsControllerFindMany(requestParameters: BeachChairsApiBeachChairsControllerFindManyRequest = {}, options?: any) {
        return BeachChairsApiFp(this.configuration).beachChairsControllerFindMany(requestParameters.page, requestParameters.itemsPerPage, requestParameters.orderBy, requestParameters.orderDir, requestParameters.search, requestParameters.filterBy, requestParameters.filter, requestParameters.filters, requestParameters.csv, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BeachChairsApiBeachChairsControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BeachChairsApi
     */
    public beachChairsControllerFindOne(requestParameters: BeachChairsApiBeachChairsControllerFindOneRequest, options?: any) {
        return BeachChairsApiFp(this.configuration).beachChairsControllerFindOne(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BeachChairsApiBeachChairsControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BeachChairsApi
     */
    public beachChairsControllerUpdate(requestParameters: BeachChairsApiBeachChairsControllerUpdateRequest, options?: any) {
        return BeachChairsApiFp(this.configuration).beachChairsControllerUpdate(requestParameters.id, requestParameters.updateBeachChairBodyDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BillingApi - axios parameter creator
 * @export
 */
export const BillingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id ID of the vendor
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingVendorsControllerGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('billingVendorsControllerGet', 'id', id)
            const localVarPath = `/api/v1/billing/vendors/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id ID of the vendor
         * @param {VendorBillingConfigUpdateDto} vendorBillingConfigUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingVendorsControllerSave: async (id: number, vendorBillingConfigUpdateDto: VendorBillingConfigUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('billingVendorsControllerSave', 'id', id)
            // verify required parameter 'vendorBillingConfigUpdateDto' is not null or undefined
            assertParamExists('billingVendorsControllerSave', 'vendorBillingConfigUpdateDto', vendorBillingConfigUpdateDto)
            const localVarPath = `/api/v1/billing/vendors/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(vendorBillingConfigUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BillingApi - functional programming interface
 * @export
 */
export const BillingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BillingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id ID of the vendor
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingVendorsControllerGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VendorBillingConfigResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingVendorsControllerGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id ID of the vendor
         * @param {VendorBillingConfigUpdateDto} vendorBillingConfigUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingVendorsControllerSave(id: number, vendorBillingConfigUpdateDto: VendorBillingConfigUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VendorBillingConfigResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingVendorsControllerSave(id, vendorBillingConfigUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BillingApi - factory interface
 * @export
 */
export const BillingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BillingApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id ID of the vendor
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingVendorsControllerGet(id: number, options?: any): AxiosPromise<VendorBillingConfigResponseDto> {
            return localVarFp.billingVendorsControllerGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id ID of the vendor
         * @param {VendorBillingConfigUpdateDto} vendorBillingConfigUpdateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingVendorsControllerSave(id: number, vendorBillingConfigUpdateDto: VendorBillingConfigUpdateDto, options?: any): AxiosPromise<VendorBillingConfigResponseDto> {
            return localVarFp.billingVendorsControllerSave(id, vendorBillingConfigUpdateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for billingVendorsControllerGet operation in BillingApi.
 * @export
 * @interface BillingApiBillingVendorsControllerGetRequest
 */
export interface BillingApiBillingVendorsControllerGetRequest {
    /**
     * ID of the vendor
     * @type {number}
     * @memberof BillingApiBillingVendorsControllerGet
     */
    readonly id: number
}

/**
 * Request parameters for billingVendorsControllerSave operation in BillingApi.
 * @export
 * @interface BillingApiBillingVendorsControllerSaveRequest
 */
export interface BillingApiBillingVendorsControllerSaveRequest {
    /**
     * ID of the vendor
     * @type {number}
     * @memberof BillingApiBillingVendorsControllerSave
     */
    readonly id: number

    /**
     * 
     * @type {VendorBillingConfigUpdateDto}
     * @memberof BillingApiBillingVendorsControllerSave
     */
    readonly vendorBillingConfigUpdateDto: VendorBillingConfigUpdateDto
}

/**
 * BillingApi - object-oriented interface
 * @export
 * @class BillingApi
 * @extends {BaseAPI}
 */
export class BillingApi extends BaseAPI {
    /**
     * 
     * @param {BillingApiBillingVendorsControllerGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingVendorsControllerGet(requestParameters: BillingApiBillingVendorsControllerGetRequest, options?: any) {
        return BillingApiFp(this.configuration).billingVendorsControllerGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BillingApiBillingVendorsControllerSaveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingVendorsControllerSave(requestParameters: BillingApiBillingVendorsControllerSaveRequest, options?: any) {
        return BillingApiFp(this.configuration).billingVendorsControllerSave(requestParameters.id, requestParameters.vendorBillingConfigUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BookingsApi - axios parameter creator
 * @export
 */
export const BookingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {Id} id2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingsControllerAddCustomerToBooking: async (id: number, id2: Id, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bookingsControllerAddCustomerToBooking', 'id', id)
            // verify required parameter 'id2' is not null or undefined
            assertParamExists('bookingsControllerAddCustomerToBooking', 'id2', id2)
            const localVarPath = `/api/v1/bookings/bookings/{id}/customer`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(id2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {CreateExtrasBookingDto} createExtrasBookingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingsControllerAddExtras: async (id: number, createExtrasBookingDto: CreateExtrasBookingDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bookingsControllerAddExtras', 'id', id)
            // verify required parameter 'createExtrasBookingDto' is not null or undefined
            assertParamExists('bookingsControllerAddExtras', 'createExtrasBookingDto', createExtrasBookingDto)
            const localVarPath = `/api/v1/bookings/bookings/{id}/extras`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createExtrasBookingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingsControllerCreate: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/bookings/bookings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingsControllerDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bookingsControllerDelete', 'id', id)
            const localVarPath = `/api/v1/bookings/bookings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingsControllerFindOne: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bookingsControllerFindOne', 'id', id)
            const localVarPath = `/api/v1/bookings/bookings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} beachChairBookingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingsControllerFindOneBeachChairBooking: async (id: number, beachChairBookingId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bookingsControllerFindOneBeachChairBooking', 'id', id)
            // verify required parameter 'beachChairBookingId' is not null or undefined
            assertParamExists('bookingsControllerFindOneBeachChairBooking', 'beachChairBookingId', beachChairBookingId)
            const localVarPath = `/api/v1/bookings/bookings/{id}/beach_chair_booking/{beach_chair_booking_id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"beach_chair_booking_id"}}`, encodeURIComponent(String(beachChairBookingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingsControllerGetHistory: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bookingsControllerGetHistory', 'id', id)
            const localVarPath = `/api/v1/bookings/bookings/{id}/history`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} extrasBookingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingsControllerRemoveExtrasFromBooking: async (id: number, extrasBookingId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bookingsControllerRemoveExtrasFromBooking', 'id', id)
            // verify required parameter 'extrasBookingId' is not null or undefined
            assertParamExists('bookingsControllerRemoveExtrasFromBooking', 'extrasBookingId', extrasBookingId)
            const localVarPath = `/api/v1/bookings/bookings/{id}/extras/{extrasBookingId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"extrasBookingId"}}`, encodeURIComponent(String(extrasBookingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} beachChairBookingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingsControllerRemoveFromBooking: async (id: number, beachChairBookingId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bookingsControllerRemoveFromBooking', 'id', id)
            // verify required parameter 'beachChairBookingId' is not null or undefined
            assertParamExists('bookingsControllerRemoveFromBooking', 'beachChairBookingId', beachChairBookingId)
            const localVarPath = `/api/v1/bookings/bookings/{id}/beach_chair_booking/{beach_chair_booking_id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"beach_chair_booking_id"}}`, encodeURIComponent(String(beachChairBookingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} search 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingsControllerSearch: async (search: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'search' is not null or undefined
            assertParamExists('bookingsControllerSearch', 'search', search)
            const localVarPath = `/api/v1/bookings/bookings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} extrasBookingId 
         * @param {UpdateExtrasBookingDto} updateExtrasBookingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingsControllerSetAmountExtrasInBooking: async (id: number, extrasBookingId: number, updateExtrasBookingDto: UpdateExtrasBookingDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bookingsControllerSetAmountExtrasInBooking', 'id', id)
            // verify required parameter 'extrasBookingId' is not null or undefined
            assertParamExists('bookingsControllerSetAmountExtrasInBooking', 'extrasBookingId', extrasBookingId)
            // verify required parameter 'updateExtrasBookingDto' is not null or undefined
            assertParamExists('bookingsControllerSetAmountExtrasInBooking', 'updateExtrasBookingDto', updateExtrasBookingDto)
            const localVarPath = `/api/v1/bookings/bookings/{id}/extras/{extrasBookingId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"extrasBookingId"}}`, encodeURIComponent(String(extrasBookingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateExtrasBookingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateBookingBodyDto} updateBookingBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingsControllerUpdate: async (id: number, updateBookingBodyDto: UpdateBookingBodyDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bookingsControllerUpdate', 'id', id)
            // verify required parameter 'updateBookingBodyDto' is not null or undefined
            assertParamExists('bookingsControllerUpdate', 'updateBookingBodyDto', updateBookingBodyDto)
            const localVarPath = `/api/v1/bookings/bookings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBookingBodyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BookingsApi - functional programming interface
 * @export
 */
export const BookingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BookingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {Id} id2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingsControllerAddCustomerToBooking(id: number, id2: Id, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingsControllerAddCustomerToBooking(id, id2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {CreateExtrasBookingDto} createExtrasBookingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingsControllerAddExtras(id: number, createExtrasBookingDto: CreateExtrasBookingDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingsControllerAddExtras(id, createExtrasBookingDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingsControllerCreate(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingsControllerCreate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingsControllerDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingsControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingsControllerFindOne(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingsControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} beachChairBookingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingsControllerFindOneBeachChairBooking(id: number, beachChairBookingId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BeachChairBookingResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingsControllerFindOneBeachChairBooking(id, beachChairBookingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingsControllerGetHistory(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingsControllerGetHistory(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} extrasBookingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingsControllerRemoveExtrasFromBooking(id: number, extrasBookingId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingsControllerRemoveExtrasFromBooking(id, extrasBookingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} beachChairBookingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingsControllerRemoveFromBooking(id: number, beachChairBookingId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingsControllerRemoveFromBooking(id, beachChairBookingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} search 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingsControllerSearch(search: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BookingSearchResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingsControllerSearch(search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} extrasBookingId 
         * @param {UpdateExtrasBookingDto} updateExtrasBookingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingsControllerSetAmountExtrasInBooking(id: number, extrasBookingId: number, updateExtrasBookingDto: UpdateExtrasBookingDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingsControllerSetAmountExtrasInBooking(id, extrasBookingId, updateExtrasBookingDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateBookingBodyDto} updateBookingBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingsControllerUpdate(id: number, updateBookingBodyDto: UpdateBookingBodyDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingsControllerUpdate(id, updateBookingBodyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BookingsApi - factory interface
 * @export
 */
export const BookingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BookingsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {Id} id2 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingsControllerAddCustomerToBooking(id: number, id2: Id, options?: any): AxiosPromise<void> {
            return localVarFp.bookingsControllerAddCustomerToBooking(id, id2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {CreateExtrasBookingDto} createExtrasBookingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingsControllerAddExtras(id: number, createExtrasBookingDto: CreateExtrasBookingDto, options?: any): AxiosPromise<BookingResponseDto> {
            return localVarFp.bookingsControllerAddExtras(id, createExtrasBookingDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingsControllerCreate(options?: any): AxiosPromise<BookingResponseDto> {
            return localVarFp.bookingsControllerCreate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingsControllerDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.bookingsControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingsControllerFindOne(id: number, options?: any): AxiosPromise<BookingResponseDto> {
            return localVarFp.bookingsControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} beachChairBookingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingsControllerFindOneBeachChairBooking(id: number, beachChairBookingId: number, options?: any): AxiosPromise<BeachChairBookingResponseDto> {
            return localVarFp.bookingsControllerFindOneBeachChairBooking(id, beachChairBookingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingsControllerGetHistory(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.bookingsControllerGetHistory(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} extrasBookingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingsControllerRemoveExtrasFromBooking(id: number, extrasBookingId: number, options?: any): AxiosPromise<BookingResponseDto> {
            return localVarFp.bookingsControllerRemoveExtrasFromBooking(id, extrasBookingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} beachChairBookingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingsControllerRemoveFromBooking(id: number, beachChairBookingId: number, options?: any): AxiosPromise<BookingResponseDto> {
            return localVarFp.bookingsControllerRemoveFromBooking(id, beachChairBookingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} search 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingsControllerSearch(search: string, options?: any): AxiosPromise<Array<BookingSearchResponseDto>> {
            return localVarFp.bookingsControllerSearch(search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} extrasBookingId 
         * @param {UpdateExtrasBookingDto} updateExtrasBookingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingsControllerSetAmountExtrasInBooking(id: number, extrasBookingId: number, updateExtrasBookingDto: UpdateExtrasBookingDto, options?: any): AxiosPromise<BookingResponseDto> {
            return localVarFp.bookingsControllerSetAmountExtrasInBooking(id, extrasBookingId, updateExtrasBookingDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateBookingBodyDto} updateBookingBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingsControllerUpdate(id: number, updateBookingBodyDto: UpdateBookingBodyDto, options?: any): AxiosPromise<BookingResponseDto> {
            return localVarFp.bookingsControllerUpdate(id, updateBookingBodyDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for bookingsControllerAddCustomerToBooking operation in BookingsApi.
 * @export
 * @interface BookingsApiBookingsControllerAddCustomerToBookingRequest
 */
export interface BookingsApiBookingsControllerAddCustomerToBookingRequest {
    /**
     * 
     * @type {number}
     * @memberof BookingsApiBookingsControllerAddCustomerToBooking
     */
    readonly id: number

    /**
     * 
     * @type {Id}
     * @memberof BookingsApiBookingsControllerAddCustomerToBooking
     */
    readonly id2: Id
}

/**
 * Request parameters for bookingsControllerAddExtras operation in BookingsApi.
 * @export
 * @interface BookingsApiBookingsControllerAddExtrasRequest
 */
export interface BookingsApiBookingsControllerAddExtrasRequest {
    /**
     * 
     * @type {number}
     * @memberof BookingsApiBookingsControllerAddExtras
     */
    readonly id: number

    /**
     * 
     * @type {CreateExtrasBookingDto}
     * @memberof BookingsApiBookingsControllerAddExtras
     */
    readonly createExtrasBookingDto: CreateExtrasBookingDto
}

/**
 * Request parameters for bookingsControllerDelete operation in BookingsApi.
 * @export
 * @interface BookingsApiBookingsControllerDeleteRequest
 */
export interface BookingsApiBookingsControllerDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof BookingsApiBookingsControllerDelete
     */
    readonly id: number
}

/**
 * Request parameters for bookingsControllerFindOne operation in BookingsApi.
 * @export
 * @interface BookingsApiBookingsControllerFindOneRequest
 */
export interface BookingsApiBookingsControllerFindOneRequest {
    /**
     * 
     * @type {number}
     * @memberof BookingsApiBookingsControllerFindOne
     */
    readonly id: number
}

/**
 * Request parameters for bookingsControllerFindOneBeachChairBooking operation in BookingsApi.
 * @export
 * @interface BookingsApiBookingsControllerFindOneBeachChairBookingRequest
 */
export interface BookingsApiBookingsControllerFindOneBeachChairBookingRequest {
    /**
     * 
     * @type {number}
     * @memberof BookingsApiBookingsControllerFindOneBeachChairBooking
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof BookingsApiBookingsControllerFindOneBeachChairBooking
     */
    readonly beachChairBookingId: number
}

/**
 * Request parameters for bookingsControllerGetHistory operation in BookingsApi.
 * @export
 * @interface BookingsApiBookingsControllerGetHistoryRequest
 */
export interface BookingsApiBookingsControllerGetHistoryRequest {
    /**
     * 
     * @type {number}
     * @memberof BookingsApiBookingsControllerGetHistory
     */
    readonly id: number
}

/**
 * Request parameters for bookingsControllerRemoveExtrasFromBooking operation in BookingsApi.
 * @export
 * @interface BookingsApiBookingsControllerRemoveExtrasFromBookingRequest
 */
export interface BookingsApiBookingsControllerRemoveExtrasFromBookingRequest {
    /**
     * 
     * @type {number}
     * @memberof BookingsApiBookingsControllerRemoveExtrasFromBooking
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof BookingsApiBookingsControllerRemoveExtrasFromBooking
     */
    readonly extrasBookingId: number
}

/**
 * Request parameters for bookingsControllerRemoveFromBooking operation in BookingsApi.
 * @export
 * @interface BookingsApiBookingsControllerRemoveFromBookingRequest
 */
export interface BookingsApiBookingsControllerRemoveFromBookingRequest {
    /**
     * 
     * @type {number}
     * @memberof BookingsApiBookingsControllerRemoveFromBooking
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof BookingsApiBookingsControllerRemoveFromBooking
     */
    readonly beachChairBookingId: number
}

/**
 * Request parameters for bookingsControllerSearch operation in BookingsApi.
 * @export
 * @interface BookingsApiBookingsControllerSearchRequest
 */
export interface BookingsApiBookingsControllerSearchRequest {
    /**
     * 
     * @type {string}
     * @memberof BookingsApiBookingsControllerSearch
     */
    readonly search: string
}

/**
 * Request parameters for bookingsControllerSetAmountExtrasInBooking operation in BookingsApi.
 * @export
 * @interface BookingsApiBookingsControllerSetAmountExtrasInBookingRequest
 */
export interface BookingsApiBookingsControllerSetAmountExtrasInBookingRequest {
    /**
     * 
     * @type {number}
     * @memberof BookingsApiBookingsControllerSetAmountExtrasInBooking
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof BookingsApiBookingsControllerSetAmountExtrasInBooking
     */
    readonly extrasBookingId: number

    /**
     * 
     * @type {UpdateExtrasBookingDto}
     * @memberof BookingsApiBookingsControllerSetAmountExtrasInBooking
     */
    readonly updateExtrasBookingDto: UpdateExtrasBookingDto
}

/**
 * Request parameters for bookingsControllerUpdate operation in BookingsApi.
 * @export
 * @interface BookingsApiBookingsControllerUpdateRequest
 */
export interface BookingsApiBookingsControllerUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof BookingsApiBookingsControllerUpdate
     */
    readonly id: number

    /**
     * 
     * @type {UpdateBookingBodyDto}
     * @memberof BookingsApiBookingsControllerUpdate
     */
    readonly updateBookingBodyDto: UpdateBookingBodyDto
}

/**
 * BookingsApi - object-oriented interface
 * @export
 * @class BookingsApi
 * @extends {BaseAPI}
 */
export class BookingsApi extends BaseAPI {
    /**
     * 
     * @param {BookingsApiBookingsControllerAddCustomerToBookingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public bookingsControllerAddCustomerToBooking(requestParameters: BookingsApiBookingsControllerAddCustomerToBookingRequest, options?: any) {
        return BookingsApiFp(this.configuration).bookingsControllerAddCustomerToBooking(requestParameters.id, requestParameters.id2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BookingsApiBookingsControllerAddExtrasRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public bookingsControllerAddExtras(requestParameters: BookingsApiBookingsControllerAddExtrasRequest, options?: any) {
        return BookingsApiFp(this.configuration).bookingsControllerAddExtras(requestParameters.id, requestParameters.createExtrasBookingDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public bookingsControllerCreate(options?: any) {
        return BookingsApiFp(this.configuration).bookingsControllerCreate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BookingsApiBookingsControllerDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public bookingsControllerDelete(requestParameters: BookingsApiBookingsControllerDeleteRequest, options?: any) {
        return BookingsApiFp(this.configuration).bookingsControllerDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BookingsApiBookingsControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public bookingsControllerFindOne(requestParameters: BookingsApiBookingsControllerFindOneRequest, options?: any) {
        return BookingsApiFp(this.configuration).bookingsControllerFindOne(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BookingsApiBookingsControllerFindOneBeachChairBookingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public bookingsControllerFindOneBeachChairBooking(requestParameters: BookingsApiBookingsControllerFindOneBeachChairBookingRequest, options?: any) {
        return BookingsApiFp(this.configuration).bookingsControllerFindOneBeachChairBooking(requestParameters.id, requestParameters.beachChairBookingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BookingsApiBookingsControllerGetHistoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public bookingsControllerGetHistory(requestParameters: BookingsApiBookingsControllerGetHistoryRequest, options?: any) {
        return BookingsApiFp(this.configuration).bookingsControllerGetHistory(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BookingsApiBookingsControllerRemoveExtrasFromBookingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public bookingsControllerRemoveExtrasFromBooking(requestParameters: BookingsApiBookingsControllerRemoveExtrasFromBookingRequest, options?: any) {
        return BookingsApiFp(this.configuration).bookingsControllerRemoveExtrasFromBooking(requestParameters.id, requestParameters.extrasBookingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BookingsApiBookingsControllerRemoveFromBookingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public bookingsControllerRemoveFromBooking(requestParameters: BookingsApiBookingsControllerRemoveFromBookingRequest, options?: any) {
        return BookingsApiFp(this.configuration).bookingsControllerRemoveFromBooking(requestParameters.id, requestParameters.beachChairBookingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BookingsApiBookingsControllerSearchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public bookingsControllerSearch(requestParameters: BookingsApiBookingsControllerSearchRequest, options?: any) {
        return BookingsApiFp(this.configuration).bookingsControllerSearch(requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BookingsApiBookingsControllerSetAmountExtrasInBookingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public bookingsControllerSetAmountExtrasInBooking(requestParameters: BookingsApiBookingsControllerSetAmountExtrasInBookingRequest, options?: any) {
        return BookingsApiFp(this.configuration).bookingsControllerSetAmountExtrasInBooking(requestParameters.id, requestParameters.extrasBookingId, requestParameters.updateExtrasBookingDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BookingsApiBookingsControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public bookingsControllerUpdate(requestParameters: BookingsApiBookingsControllerUpdateRequest, options?: any) {
        return BookingsApiFp(this.configuration).bookingsControllerUpdate(requestParameters.id, requestParameters.updateBookingBodyDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CashPointApi - axios parameter creator
 * @export
 */
export const CashPointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cashPointControllerCloseCashPoint: async (locationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('cashPointControllerCloseCashPoint', 'locationId', locationId)
            const localVarPath = `/api/v1/cash-point/close`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (locationId !== undefined) {
                localVarQueryParameter['locationId'] = locationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CashPointWithdraw} cashPointWithdraw 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cashPointControllerDepositCash: async (cashPointWithdraw: CashPointWithdraw, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cashPointWithdraw' is not null or undefined
            assertParamExists('cashPointControllerDepositCash', 'cashPointWithdraw', cashPointWithdraw)
            const localVarPath = `/api/v1/cash-point/deposit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cashPointWithdraw, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} locationId 
         * @param {string} closingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cashPointControllerGetCashPointClosing: async (locationId: number, closingId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('cashPointControllerGetCashPointClosing', 'locationId', locationId)
            // verify required parameter 'closingId' is not null or undefined
            assertParamExists('cashPointControllerGetCashPointClosing', 'closingId', closingId)
            const localVarPath = `/api/v1/cash-point/{locationId}/closings/{closingId}`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)))
                .replace(`{${"closingId"}}`, encodeURIComponent(String(closingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cashPointControllerGetCashPointClosingPdf: async (locationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('cashPointControllerGetCashPointClosingPdf', 'locationId', locationId)
            const localVarPath = `/api/v1/cash-point/{locationId}/closings/{closingId}.pdf`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} locationId 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cashPointControllerGetCashPointClosings: async (locationId: number, page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, csv?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('cashPointControllerGetCashPointClosings', 'locationId', locationId)
            const localVarPath = `/api/v1/cash-point/{locationId}/closings`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['orderDir'] = orderDir;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (filterBy !== undefined) {
                localVarQueryParameter['filterBy'] = filterBy;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (csv !== undefined) {
                localVarQueryParameter['csv'] = csv;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cashPointControllerGetCashPointStatus: async (locationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('cashPointControllerGetCashPointStatus', 'locationId', locationId)
            const localVarPath = `/api/v1/cash-point/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (locationId !== undefined) {
                localVarQueryParameter['locationId'] = locationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cashPointControllerOpenCashPoint: async (locationId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('cashPointControllerOpenCashPoint', 'locationId', locationId)
            const localVarPath = `/api/v1/cash-point/open`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (locationId !== undefined) {
                localVarQueryParameter['locationId'] = locationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CashPointWithdraw} cashPointWithdraw 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cashPointControllerWithdrawCash: async (cashPointWithdraw: CashPointWithdraw, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cashPointWithdraw' is not null or undefined
            assertParamExists('cashPointControllerWithdrawCash', 'cashPointWithdraw', cashPointWithdraw)
            const localVarPath = `/api/v1/cash-point/withdraw`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cashPointWithdraw, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CashPointApi - functional programming interface
 * @export
 */
export const CashPointApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CashPointApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cashPointControllerCloseCashPoint(locationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FiskalyCashRegisterEntityResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cashPointControllerCloseCashPoint(locationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CashPointWithdraw} cashPointWithdraw 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cashPointControllerDepositCash(cashPointWithdraw: CashPointWithdraw, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cashPointControllerDepositCash(cashPointWithdraw, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} locationId 
         * @param {string} closingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cashPointControllerGetCashPointClosing(locationId: number, closingId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FiskalyCashPointDetailResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cashPointControllerGetCashPointClosing(locationId, closingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cashPointControllerGetCashPointClosingPdf(locationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cashPointControllerGetCashPointClosingPdf(locationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} locationId 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cashPointControllerGetCashPointClosings(locationId: number, page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, csv?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FiskalyCreateCashPointClosingResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cashPointControllerGetCashPointClosings(locationId, page, itemsPerPage, orderBy, orderDir, search, filterBy, filter, csv, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cashPointControllerGetCashPointStatus(locationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CashPointStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cashPointControllerGetCashPointStatus(locationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cashPointControllerOpenCashPoint(locationId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cashPointControllerOpenCashPoint(locationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CashPointWithdraw} cashPointWithdraw 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cashPointControllerWithdrawCash(cashPointWithdraw: CashPointWithdraw, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cashPointControllerWithdrawCash(cashPointWithdraw, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CashPointApi - factory interface
 * @export
 */
export const CashPointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CashPointApiFp(configuration)
    return {
        /**
         * 
         * @param {number} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cashPointControllerCloseCashPoint(locationId: number, options?: any): AxiosPromise<FiskalyCashRegisterEntityResponseDto> {
            return localVarFp.cashPointControllerCloseCashPoint(locationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CashPointWithdraw} cashPointWithdraw 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cashPointControllerDepositCash(cashPointWithdraw: CashPointWithdraw, options?: any): AxiosPromise<void> {
            return localVarFp.cashPointControllerDepositCash(cashPointWithdraw, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} locationId 
         * @param {string} closingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cashPointControllerGetCashPointClosing(locationId: number, closingId: string, options?: any): AxiosPromise<FiskalyCashPointDetailResponseDto> {
            return localVarFp.cashPointControllerGetCashPointClosing(locationId, closingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cashPointControllerGetCashPointClosingPdf(locationId: number, options?: any): AxiosPromise<void> {
            return localVarFp.cashPointControllerGetCashPointClosingPdf(locationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} locationId 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cashPointControllerGetCashPointClosings(locationId: number, page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, csv?: string, options?: any): AxiosPromise<Array<FiskalyCreateCashPointClosingResponseDto>> {
            return localVarFp.cashPointControllerGetCashPointClosings(locationId, page, itemsPerPage, orderBy, orderDir, search, filterBy, filter, csv, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cashPointControllerGetCashPointStatus(locationId: number, options?: any): AxiosPromise<CashPointStatus> {
            return localVarFp.cashPointControllerGetCashPointStatus(locationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cashPointControllerOpenCashPoint(locationId: number, options?: any): AxiosPromise<void> {
            return localVarFp.cashPointControllerOpenCashPoint(locationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CashPointWithdraw} cashPointWithdraw 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cashPointControllerWithdrawCash(cashPointWithdraw: CashPointWithdraw, options?: any): AxiosPromise<void> {
            return localVarFp.cashPointControllerWithdrawCash(cashPointWithdraw, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for cashPointControllerCloseCashPoint operation in CashPointApi.
 * @export
 * @interface CashPointApiCashPointControllerCloseCashPointRequest
 */
export interface CashPointApiCashPointControllerCloseCashPointRequest {
    /**
     * 
     * @type {number}
     * @memberof CashPointApiCashPointControllerCloseCashPoint
     */
    readonly locationId: number
}

/**
 * Request parameters for cashPointControllerDepositCash operation in CashPointApi.
 * @export
 * @interface CashPointApiCashPointControllerDepositCashRequest
 */
export interface CashPointApiCashPointControllerDepositCashRequest {
    /**
     * 
     * @type {CashPointWithdraw}
     * @memberof CashPointApiCashPointControllerDepositCash
     */
    readonly cashPointWithdraw: CashPointWithdraw
}

/**
 * Request parameters for cashPointControllerGetCashPointClosing operation in CashPointApi.
 * @export
 * @interface CashPointApiCashPointControllerGetCashPointClosingRequest
 */
export interface CashPointApiCashPointControllerGetCashPointClosingRequest {
    /**
     * 
     * @type {number}
     * @memberof CashPointApiCashPointControllerGetCashPointClosing
     */
    readonly locationId: number

    /**
     * 
     * @type {string}
     * @memberof CashPointApiCashPointControllerGetCashPointClosing
     */
    readonly closingId: string
}

/**
 * Request parameters for cashPointControllerGetCashPointClosingPdf operation in CashPointApi.
 * @export
 * @interface CashPointApiCashPointControllerGetCashPointClosingPdfRequest
 */
export interface CashPointApiCashPointControllerGetCashPointClosingPdfRequest {
    /**
     * 
     * @type {number}
     * @memberof CashPointApiCashPointControllerGetCashPointClosingPdf
     */
    readonly locationId: number
}

/**
 * Request parameters for cashPointControllerGetCashPointClosings operation in CashPointApi.
 * @export
 * @interface CashPointApiCashPointControllerGetCashPointClosingsRequest
 */
export interface CashPointApiCashPointControllerGetCashPointClosingsRequest {
    /**
     * 
     * @type {number}
     * @memberof CashPointApiCashPointControllerGetCashPointClosings
     */
    readonly locationId: number

    /**
     * Number of the page to fetch
     * @type {number}
     * @memberof CashPointApiCashPointControllerGetCashPointClosings
     */
    readonly page?: number

    /**
     * Number of items per page
     * @type {number}
     * @memberof CashPointApiCashPointControllerGetCashPointClosings
     */
    readonly itemsPerPage?: number

    /**
     * Name of the property to order by
     * @type {string}
     * @memberof CashPointApiCashPointControllerGetCashPointClosings
     */
    readonly orderBy?: string

    /**
     * Value which specify direction of the order
     * @type {OrderByDirection}
     * @memberof CashPointApiCashPointControllerGetCashPointClosings
     */
    readonly orderDir?: OrderByDirection

    /**
     * Text which will be used to search through table data
     * @type {string}
     * @memberof CashPointApiCashPointControllerGetCashPointClosings
     */
    readonly search?: string

    /**
     * Name of the property to filter by
     * @type {string}
     * @memberof CashPointApiCashPointControllerGetCashPointClosings
     */
    readonly filterBy?: string

    /**
     * Value which will be used to filter table data
     * @type {string}
     * @memberof CashPointApiCashPointControllerGetCashPointClosings
     */
    readonly filter?: string

    /**
     * 
     * @type {string}
     * @memberof CashPointApiCashPointControllerGetCashPointClosings
     */
    readonly csv?: string
}

/**
 * Request parameters for cashPointControllerGetCashPointStatus operation in CashPointApi.
 * @export
 * @interface CashPointApiCashPointControllerGetCashPointStatusRequest
 */
export interface CashPointApiCashPointControllerGetCashPointStatusRequest {
    /**
     * 
     * @type {number}
     * @memberof CashPointApiCashPointControllerGetCashPointStatus
     */
    readonly locationId: number
}

/**
 * Request parameters for cashPointControllerOpenCashPoint operation in CashPointApi.
 * @export
 * @interface CashPointApiCashPointControllerOpenCashPointRequest
 */
export interface CashPointApiCashPointControllerOpenCashPointRequest {
    /**
     * 
     * @type {number}
     * @memberof CashPointApiCashPointControllerOpenCashPoint
     */
    readonly locationId: number
}

/**
 * Request parameters for cashPointControllerWithdrawCash operation in CashPointApi.
 * @export
 * @interface CashPointApiCashPointControllerWithdrawCashRequest
 */
export interface CashPointApiCashPointControllerWithdrawCashRequest {
    /**
     * 
     * @type {CashPointWithdraw}
     * @memberof CashPointApiCashPointControllerWithdrawCash
     */
    readonly cashPointWithdraw: CashPointWithdraw
}

/**
 * CashPointApi - object-oriented interface
 * @export
 * @class CashPointApi
 * @extends {BaseAPI}
 */
export class CashPointApi extends BaseAPI {
    /**
     * 
     * @param {CashPointApiCashPointControllerCloseCashPointRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CashPointApi
     */
    public cashPointControllerCloseCashPoint(requestParameters: CashPointApiCashPointControllerCloseCashPointRequest, options?: any) {
        return CashPointApiFp(this.configuration).cashPointControllerCloseCashPoint(requestParameters.locationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CashPointApiCashPointControllerDepositCashRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CashPointApi
     */
    public cashPointControllerDepositCash(requestParameters: CashPointApiCashPointControllerDepositCashRequest, options?: any) {
        return CashPointApiFp(this.configuration).cashPointControllerDepositCash(requestParameters.cashPointWithdraw, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CashPointApiCashPointControllerGetCashPointClosingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CashPointApi
     */
    public cashPointControllerGetCashPointClosing(requestParameters: CashPointApiCashPointControllerGetCashPointClosingRequest, options?: any) {
        return CashPointApiFp(this.configuration).cashPointControllerGetCashPointClosing(requestParameters.locationId, requestParameters.closingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CashPointApiCashPointControllerGetCashPointClosingPdfRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CashPointApi
     */
    public cashPointControllerGetCashPointClosingPdf(requestParameters: CashPointApiCashPointControllerGetCashPointClosingPdfRequest, options?: any) {
        return CashPointApiFp(this.configuration).cashPointControllerGetCashPointClosingPdf(requestParameters.locationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CashPointApiCashPointControllerGetCashPointClosingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CashPointApi
     */
    public cashPointControllerGetCashPointClosings(requestParameters: CashPointApiCashPointControllerGetCashPointClosingsRequest, options?: any) {
        return CashPointApiFp(this.configuration).cashPointControllerGetCashPointClosings(requestParameters.locationId, requestParameters.page, requestParameters.itemsPerPage, requestParameters.orderBy, requestParameters.orderDir, requestParameters.search, requestParameters.filterBy, requestParameters.filter, requestParameters.csv, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CashPointApiCashPointControllerGetCashPointStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CashPointApi
     */
    public cashPointControllerGetCashPointStatus(requestParameters: CashPointApiCashPointControllerGetCashPointStatusRequest, options?: any) {
        return CashPointApiFp(this.configuration).cashPointControllerGetCashPointStatus(requestParameters.locationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CashPointApiCashPointControllerOpenCashPointRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CashPointApi
     */
    public cashPointControllerOpenCashPoint(requestParameters: CashPointApiCashPointControllerOpenCashPointRequest, options?: any) {
        return CashPointApiFp(this.configuration).cashPointControllerOpenCashPoint(requestParameters.locationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CashPointApiCashPointControllerWithdrawCashRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CashPointApi
     */
    public cashPointControllerWithdrawCash(requestParameters: CashPointApiCashPointControllerWithdrawCashRequest, options?: any) {
        return CashPointApiFp(this.configuration).cashPointControllerWithdrawCash(requestParameters.cashPointWithdraw, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CityApi - axios parameter creator
 * @export
 */
export const CityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateCityBodyDto} createCityBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        citiesControllerCreate: async (createCityBodyDto: CreateCityBodyDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCityBodyDto' is not null or undefined
            assertParamExists('citiesControllerCreate', 'createCityBodyDto', createCityBodyDto)
            const localVarPath = `/api/v1/destinations/cities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCityBodyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        citiesControllerDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('citiesControllerDelete', 'id', id)
            const localVarPath = `/api/v1/destinations/cities/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {CitiesFiltersDto} [filters] 
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        citiesControllerFindMany: async (page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, filters?: CitiesFiltersDto, csv?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/destinations/cities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['orderDir'] = orderDir;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (filterBy !== undefined) {
                localVarQueryParameter['filterBy'] = filterBy;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (filters !== undefined) {
                localVarQueryParameter['filters'] = filters;
            }

            if (csv !== undefined) {
                localVarQueryParameter['csv'] = csv;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        citiesControllerFindOne: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('citiesControllerFindOne', 'id', id)
            const localVarPath = `/api/v1/destinations/cities/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateCityBodyDto} updateCityBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        citiesControllerUpdate: async (id: number, updateCityBodyDto: UpdateCityBodyDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('citiesControllerUpdate', 'id', id)
            // verify required parameter 'updateCityBodyDto' is not null or undefined
            assertParamExists('citiesControllerUpdate', 'updateCityBodyDto', updateCityBodyDto)
            const localVarPath = `/api/v1/destinations/cities/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCityBodyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CityApi - functional programming interface
 * @export
 */
export const CityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateCityBodyDto} createCityBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async citiesControllerCreate(createCityBodyDto: CreateCityBodyDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CityResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.citiesControllerCreate(createCityBodyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async citiesControllerDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.citiesControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {CitiesFiltersDto} [filters] 
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async citiesControllerFindMany(page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, filters?: CitiesFiltersDto, csv?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CityChunkResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.citiesControllerFindMany(page, itemsPerPage, orderBy, orderDir, search, filterBy, filter, filters, csv, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async citiesControllerFindOne(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CityResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.citiesControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateCityBodyDto} updateCityBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async citiesControllerUpdate(id: number, updateCityBodyDto: UpdateCityBodyDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.citiesControllerUpdate(id, updateCityBodyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CityApi - factory interface
 * @export
 */
export const CityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CityApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateCityBodyDto} createCityBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        citiesControllerCreate(createCityBodyDto: CreateCityBodyDto, options?: any): AxiosPromise<CityResponseDto> {
            return localVarFp.citiesControllerCreate(createCityBodyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        citiesControllerDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.citiesControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {CitiesFiltersDto} [filters] 
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        citiesControllerFindMany(page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, filters?: CitiesFiltersDto, csv?: string, options?: any): AxiosPromise<CityChunkResponseDto> {
            return localVarFp.citiesControllerFindMany(page, itemsPerPage, orderBy, orderDir, search, filterBy, filter, filters, csv, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        citiesControllerFindOne(id: number, options?: any): AxiosPromise<CityResponseDto> {
            return localVarFp.citiesControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateCityBodyDto} updateCityBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        citiesControllerUpdate(id: number, updateCityBodyDto: UpdateCityBodyDto, options?: any): AxiosPromise<void> {
            return localVarFp.citiesControllerUpdate(id, updateCityBodyDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for citiesControllerCreate operation in CityApi.
 * @export
 * @interface CityApiCitiesControllerCreateRequest
 */
export interface CityApiCitiesControllerCreateRequest {
    /**
     * 
     * @type {CreateCityBodyDto}
     * @memberof CityApiCitiesControllerCreate
     */
    readonly createCityBodyDto: CreateCityBodyDto
}

/**
 * Request parameters for citiesControllerDelete operation in CityApi.
 * @export
 * @interface CityApiCitiesControllerDeleteRequest
 */
export interface CityApiCitiesControllerDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof CityApiCitiesControllerDelete
     */
    readonly id: number
}

/**
 * Request parameters for citiesControllerFindMany operation in CityApi.
 * @export
 * @interface CityApiCitiesControllerFindManyRequest
 */
export interface CityApiCitiesControllerFindManyRequest {
    /**
     * Number of the page to fetch
     * @type {number}
     * @memberof CityApiCitiesControllerFindMany
     */
    readonly page?: number

    /**
     * Number of items per page
     * @type {number}
     * @memberof CityApiCitiesControllerFindMany
     */
    readonly itemsPerPage?: number

    /**
     * Name of the property to order by
     * @type {string}
     * @memberof CityApiCitiesControllerFindMany
     */
    readonly orderBy?: string

    /**
     * Value which specify direction of the order
     * @type {OrderByDirection}
     * @memberof CityApiCitiesControllerFindMany
     */
    readonly orderDir?: OrderByDirection

    /**
     * Text which will be used to search through table data
     * @type {string}
     * @memberof CityApiCitiesControllerFindMany
     */
    readonly search?: string

    /**
     * Name of the property to filter by
     * @type {string}
     * @memberof CityApiCitiesControllerFindMany
     */
    readonly filterBy?: string

    /**
     * Value which will be used to filter table data
     * @type {string}
     * @memberof CityApiCitiesControllerFindMany
     */
    readonly filter?: string

    /**
     * 
     * @type {CitiesFiltersDto}
     * @memberof CityApiCitiesControllerFindMany
     */
    readonly filters?: CitiesFiltersDto

    /**
     * 
     * @type {string}
     * @memberof CityApiCitiesControllerFindMany
     */
    readonly csv?: string
}

/**
 * Request parameters for citiesControllerFindOne operation in CityApi.
 * @export
 * @interface CityApiCitiesControllerFindOneRequest
 */
export interface CityApiCitiesControllerFindOneRequest {
    /**
     * 
     * @type {number}
     * @memberof CityApiCitiesControllerFindOne
     */
    readonly id: number
}

/**
 * Request parameters for citiesControllerUpdate operation in CityApi.
 * @export
 * @interface CityApiCitiesControllerUpdateRequest
 */
export interface CityApiCitiesControllerUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof CityApiCitiesControllerUpdate
     */
    readonly id: number

    /**
     * 
     * @type {UpdateCityBodyDto}
     * @memberof CityApiCitiesControllerUpdate
     */
    readonly updateCityBodyDto: UpdateCityBodyDto
}

/**
 * CityApi - object-oriented interface
 * @export
 * @class CityApi
 * @extends {BaseAPI}
 */
export class CityApi extends BaseAPI {
    /**
     * 
     * @param {CityApiCitiesControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CityApi
     */
    public citiesControllerCreate(requestParameters: CityApiCitiesControllerCreateRequest, options?: any) {
        return CityApiFp(this.configuration).citiesControllerCreate(requestParameters.createCityBodyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CityApiCitiesControllerDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CityApi
     */
    public citiesControllerDelete(requestParameters: CityApiCitiesControllerDeleteRequest, options?: any) {
        return CityApiFp(this.configuration).citiesControllerDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CityApiCitiesControllerFindManyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CityApi
     */
    public citiesControllerFindMany(requestParameters: CityApiCitiesControllerFindManyRequest = {}, options?: any) {
        return CityApiFp(this.configuration).citiesControllerFindMany(requestParameters.page, requestParameters.itemsPerPage, requestParameters.orderBy, requestParameters.orderDir, requestParameters.search, requestParameters.filterBy, requestParameters.filter, requestParameters.filters, requestParameters.csv, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CityApiCitiesControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CityApi
     */
    public citiesControllerFindOne(requestParameters: CityApiCitiesControllerFindOneRequest, options?: any) {
        return CityApiFp(this.configuration).citiesControllerFindOne(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CityApiCitiesControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CityApi
     */
    public citiesControllerUpdate(requestParameters: CityApiCitiesControllerUpdateRequest, options?: any) {
        return CityApiFp(this.configuration).citiesControllerUpdate(requestParameters.id, requestParameters.updateCityBodyDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CountryApi - axios parameter creator
 * @export
 */
export const CountryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countriesControllerFindCityByCountryId: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('countriesControllerFindCityByCountryId', 'id', id)
            const localVarPath = `/api/v1/destinations/countries/{id}/cities`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countriesControllerFindOne: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('countriesControllerFindOne', 'id', id)
            const localVarPath = `/api/v1/destinations/countries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countriesControllerFindRegionByCountryId: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('countriesControllerFindRegionByCountryId', 'id', id)
            const localVarPath = `/api/v1/destinations/countries/{id}/regions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CountryApi - functional programming interface
 * @export
 */
export const CountryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CountryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countriesControllerFindCityByCountryId(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CityResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countriesControllerFindCityByCountryId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countriesControllerFindOne(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountryResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countriesControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countriesControllerFindRegionByCountryId(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RegionResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countriesControllerFindRegionByCountryId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CountryApi - factory interface
 * @export
 */
export const CountryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CountryApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countriesControllerFindCityByCountryId(id: number, options?: any): AxiosPromise<Array<CityResponseDto>> {
            return localVarFp.countriesControllerFindCityByCountryId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countriesControllerFindOne(id: number, options?: any): AxiosPromise<CountryResponseDto> {
            return localVarFp.countriesControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countriesControllerFindRegionByCountryId(id: number, options?: any): AxiosPromise<Array<RegionResponseDto>> {
            return localVarFp.countriesControllerFindRegionByCountryId(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for countriesControllerFindCityByCountryId operation in CountryApi.
 * @export
 * @interface CountryApiCountriesControllerFindCityByCountryIdRequest
 */
export interface CountryApiCountriesControllerFindCityByCountryIdRequest {
    /**
     * 
     * @type {number}
     * @memberof CountryApiCountriesControllerFindCityByCountryId
     */
    readonly id: number
}

/**
 * Request parameters for countriesControllerFindOne operation in CountryApi.
 * @export
 * @interface CountryApiCountriesControllerFindOneRequest
 */
export interface CountryApiCountriesControllerFindOneRequest {
    /**
     * 
     * @type {number}
     * @memberof CountryApiCountriesControllerFindOne
     */
    readonly id: number
}

/**
 * Request parameters for countriesControllerFindRegionByCountryId operation in CountryApi.
 * @export
 * @interface CountryApiCountriesControllerFindRegionByCountryIdRequest
 */
export interface CountryApiCountriesControllerFindRegionByCountryIdRequest {
    /**
     * 
     * @type {number}
     * @memberof CountryApiCountriesControllerFindRegionByCountryId
     */
    readonly id: number
}

/**
 * CountryApi - object-oriented interface
 * @export
 * @class CountryApi
 * @extends {BaseAPI}
 */
export class CountryApi extends BaseAPI {
    /**
     * 
     * @param {CountryApiCountriesControllerFindCityByCountryIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountryApi
     */
    public countriesControllerFindCityByCountryId(requestParameters: CountryApiCountriesControllerFindCityByCountryIdRequest, options?: any) {
        return CountryApiFp(this.configuration).countriesControllerFindCityByCountryId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CountryApiCountriesControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountryApi
     */
    public countriesControllerFindOne(requestParameters: CountryApiCountriesControllerFindOneRequest, options?: any) {
        return CountryApiFp(this.configuration).countriesControllerFindOne(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CountryApiCountriesControllerFindRegionByCountryIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CountryApi
     */
    public countriesControllerFindRegionByCountryId(requestParameters: CountryApiCountriesControllerFindRegionByCountryIdRequest, options?: any) {
        return CountryApiFp(this.configuration).countriesControllerFindRegionByCountryId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CustomerApi - axios parameter creator
 * @export
 */
export const CustomerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateCustomerBodyDto} createCustomerBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerCreate: async (createCustomerBodyDto: CreateCustomerBodyDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCustomerBodyDto' is not null or undefined
            assertParamExists('customersControllerCreate', 'createCustomerBodyDto', createCustomerBodyDto)
            const localVarPath = `/api/v1/bookings/customers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCustomerBodyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('customersControllerDelete', 'id', id)
            const localVarPath = `/api/v1/bookings/customers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {CustomerFiltersDto} [filters] 
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerFindMany: async (page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, filters?: CustomerFiltersDto, csv?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/bookings/customers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['orderDir'] = orderDir;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (filterBy !== undefined) {
                localVarQueryParameter['filterBy'] = filterBy;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (filters !== undefined) {
                localVarQueryParameter['filters'] = filters;
            }

            if (csv !== undefined) {
                localVarQueryParameter['csv'] = csv;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerFindOne: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('customersControllerFindOne', 'id', id)
            const localVarPath = `/api/v1/bookings/customers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateCustomerBodyDto} updateCustomerBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerUpdate: async (id: number, updateCustomerBodyDto: UpdateCustomerBodyDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('customersControllerUpdate', 'id', id)
            // verify required parameter 'updateCustomerBodyDto' is not null or undefined
            assertParamExists('customersControllerUpdate', 'updateCustomerBodyDto', updateCustomerBodyDto)
            const localVarPath = `/api/v1/bookings/customers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCustomerBodyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerApi - functional programming interface
 * @export
 */
export const CustomerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateCustomerBodyDto} createCustomerBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customersControllerCreate(createCustomerBodyDto: CreateCustomerBodyDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customersControllerCreate(createCustomerBodyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customersControllerDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customersControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {CustomerFiltersDto} [filters] 
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customersControllerFindMany(page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, filters?: CustomerFiltersDto, csv?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerChunkResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customersControllerFindMany(page, itemsPerPage, orderBy, orderDir, search, filterBy, filter, filters, csv, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customersControllerFindOne(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customersControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateCustomerBodyDto} updateCustomerBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customersControllerUpdate(id: number, updateCustomerBodyDto: UpdateCustomerBodyDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customersControllerUpdate(id, updateCustomerBodyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerApi - factory interface
 * @export
 */
export const CustomerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateCustomerBodyDto} createCustomerBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerCreate(createCustomerBodyDto: CreateCustomerBodyDto, options?: any): AxiosPromise<CustomerResponseDto> {
            return localVarFp.customersControllerCreate(createCustomerBodyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.customersControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {CustomerFiltersDto} [filters] 
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerFindMany(page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, filters?: CustomerFiltersDto, csv?: string, options?: any): AxiosPromise<CustomerChunkResponseDto> {
            return localVarFp.customersControllerFindMany(page, itemsPerPage, orderBy, orderDir, search, filterBy, filter, filters, csv, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerFindOne(id: number, options?: any): AxiosPromise<CustomerResponseDto> {
            return localVarFp.customersControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateCustomerBodyDto} updateCustomerBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customersControllerUpdate(id: number, updateCustomerBodyDto: UpdateCustomerBodyDto, options?: any): AxiosPromise<CustomerResponseDto> {
            return localVarFp.customersControllerUpdate(id, updateCustomerBodyDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for customersControllerCreate operation in CustomerApi.
 * @export
 * @interface CustomerApiCustomersControllerCreateRequest
 */
export interface CustomerApiCustomersControllerCreateRequest {
    /**
     * 
     * @type {CreateCustomerBodyDto}
     * @memberof CustomerApiCustomersControllerCreate
     */
    readonly createCustomerBodyDto: CreateCustomerBodyDto
}

/**
 * Request parameters for customersControllerDelete operation in CustomerApi.
 * @export
 * @interface CustomerApiCustomersControllerDeleteRequest
 */
export interface CustomerApiCustomersControllerDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof CustomerApiCustomersControllerDelete
     */
    readonly id: number
}

/**
 * Request parameters for customersControllerFindMany operation in CustomerApi.
 * @export
 * @interface CustomerApiCustomersControllerFindManyRequest
 */
export interface CustomerApiCustomersControllerFindManyRequest {
    /**
     * Number of the page to fetch
     * @type {number}
     * @memberof CustomerApiCustomersControllerFindMany
     */
    readonly page?: number

    /**
     * Number of items per page
     * @type {number}
     * @memberof CustomerApiCustomersControllerFindMany
     */
    readonly itemsPerPage?: number

    /**
     * Name of the property to order by
     * @type {string}
     * @memberof CustomerApiCustomersControllerFindMany
     */
    readonly orderBy?: string

    /**
     * Value which specify direction of the order
     * @type {OrderByDirection}
     * @memberof CustomerApiCustomersControllerFindMany
     */
    readonly orderDir?: OrderByDirection

    /**
     * Text which will be used to search through table data
     * @type {string}
     * @memberof CustomerApiCustomersControllerFindMany
     */
    readonly search?: string

    /**
     * Name of the property to filter by
     * @type {string}
     * @memberof CustomerApiCustomersControllerFindMany
     */
    readonly filterBy?: string

    /**
     * Value which will be used to filter table data
     * @type {string}
     * @memberof CustomerApiCustomersControllerFindMany
     */
    readonly filter?: string

    /**
     * 
     * @type {CustomerFiltersDto}
     * @memberof CustomerApiCustomersControllerFindMany
     */
    readonly filters?: CustomerFiltersDto

    /**
     * 
     * @type {string}
     * @memberof CustomerApiCustomersControllerFindMany
     */
    readonly csv?: string
}

/**
 * Request parameters for customersControllerFindOne operation in CustomerApi.
 * @export
 * @interface CustomerApiCustomersControllerFindOneRequest
 */
export interface CustomerApiCustomersControllerFindOneRequest {
    /**
     * 
     * @type {number}
     * @memberof CustomerApiCustomersControllerFindOne
     */
    readonly id: number
}

/**
 * Request parameters for customersControllerUpdate operation in CustomerApi.
 * @export
 * @interface CustomerApiCustomersControllerUpdateRequest
 */
export interface CustomerApiCustomersControllerUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof CustomerApiCustomersControllerUpdate
     */
    readonly id: number

    /**
     * 
     * @type {UpdateCustomerBodyDto}
     * @memberof CustomerApiCustomersControllerUpdate
     */
    readonly updateCustomerBodyDto: UpdateCustomerBodyDto
}

/**
 * CustomerApi - object-oriented interface
 * @export
 * @class CustomerApi
 * @extends {BaseAPI}
 */
export class CustomerApi extends BaseAPI {
    /**
     * 
     * @param {CustomerApiCustomersControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public customersControllerCreate(requestParameters: CustomerApiCustomersControllerCreateRequest, options?: any) {
        return CustomerApiFp(this.configuration).customersControllerCreate(requestParameters.createCustomerBodyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomerApiCustomersControllerDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public customersControllerDelete(requestParameters: CustomerApiCustomersControllerDeleteRequest, options?: any) {
        return CustomerApiFp(this.configuration).customersControllerDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomerApiCustomersControllerFindManyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public customersControllerFindMany(requestParameters: CustomerApiCustomersControllerFindManyRequest = {}, options?: any) {
        return CustomerApiFp(this.configuration).customersControllerFindMany(requestParameters.page, requestParameters.itemsPerPage, requestParameters.orderBy, requestParameters.orderDir, requestParameters.search, requestParameters.filterBy, requestParameters.filter, requestParameters.filters, requestParameters.csv, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomerApiCustomersControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public customersControllerFindOne(requestParameters: CustomerApiCustomersControllerFindOneRequest, options?: any) {
        return CustomerApiFp(this.configuration).customersControllerFindOne(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomerApiCustomersControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public customersControllerUpdate(requestParameters: CustomerApiCustomersControllerUpdateRequest, options?: any) {
        return CustomerApiFp(this.configuration).customersControllerUpdate(requestParameters.id, requestParameters.updateCustomerBodyDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerLiveness: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/health/liveness`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerReadiness: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/health/rediness`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} filename 
         * @param {string} format 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        staticControllerGetStaticContent: async (filename: string, format: string, lang: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'filename' is not null or undefined
            assertParamExists('staticControllerGetStaticContent', 'filename', filename)
            // verify required parameter 'format' is not null or undefined
            assertParamExists('staticControllerGetStaticContent', 'format', format)
            // verify required parameter 'lang' is not null or undefined
            assertParamExists('staticControllerGetStaticContent', 'lang', lang)
            const localVarPath = `/api/v1/static/{filename}.{format}`
                .replace(`{${"filename"}}`, encodeURIComponent(String(filename)))
                .replace(`{${"format"}}`, encodeURIComponent(String(format)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthControllerLiveness(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthControllerLiveness(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthControllerReadiness(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthControllerReadiness(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} filename 
         * @param {string} format 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async staticControllerGetStaticContent(filename: string, format: string, lang: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.staticControllerGetStaticContent(filename, format, lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerLiveness(options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.healthControllerLiveness(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerReadiness(options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.healthControllerReadiness(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} filename 
         * @param {string} format 
         * @param {string} lang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        staticControllerGetStaticContent(filename: string, format: string, lang: string, options?: any): AxiosPromise<void> {
            return localVarFp.staticControllerGetStaticContent(filename, format, lang, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for staticControllerGetStaticContent operation in DefaultApi.
 * @export
 * @interface DefaultApiStaticControllerGetStaticContentRequest
 */
export interface DefaultApiStaticControllerGetStaticContentRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiStaticControllerGetStaticContent
     */
    readonly filename: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiStaticControllerGetStaticContent
     */
    readonly format: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiStaticControllerGetStaticContent
     */
    readonly lang: string
}

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public healthControllerLiveness(options?: any) {
        return DefaultApiFp(this.configuration).healthControllerLiveness(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public healthControllerReadiness(options?: any) {
        return DefaultApiFp(this.configuration).healthControllerReadiness(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiStaticControllerGetStaticContentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public staticControllerGetStaticContent(requestParameters: DefaultApiStaticControllerGetStaticContentRequest, options?: any) {
        return DefaultApiFp(this.configuration).staticControllerGetStaticContent(requestParameters.filename, requestParameters.format, requestParameters.lang, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExternalPpApi - axios parameter creator
 * @export
 */
export const ExternalPpApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {boolean} [skipEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalPpControllerConfirmExternalPayment: async (id: number, skipEmail?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('externalPpControllerConfirmExternalPayment', 'id', id)
            const localVarPath = `/api/v1/payments/external_pp/{id}/pay`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skipEmail !== undefined) {
                localVarQueryParameter['skipEmail'] = skipEmail;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExternalPpApi - functional programming interface
 * @export
 */
export const ExternalPpApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExternalPpApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {boolean} [skipEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async externalPpControllerConfirmExternalPayment(id: number, skipEmail?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.externalPpControllerConfirmExternalPayment(id, skipEmail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExternalPpApi - factory interface
 * @export
 */
export const ExternalPpApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExternalPpApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {boolean} [skipEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalPpControllerConfirmExternalPayment(id: number, skipEmail?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.externalPpControllerConfirmExternalPayment(id, skipEmail, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for externalPpControllerConfirmExternalPayment operation in ExternalPpApi.
 * @export
 * @interface ExternalPpApiExternalPpControllerConfirmExternalPaymentRequest
 */
export interface ExternalPpApiExternalPpControllerConfirmExternalPaymentRequest {
    /**
     * 
     * @type {number}
     * @memberof ExternalPpApiExternalPpControllerConfirmExternalPayment
     */
    readonly id: number

    /**
     * 
     * @type {boolean}
     * @memberof ExternalPpApiExternalPpControllerConfirmExternalPayment
     */
    readonly skipEmail?: boolean
}

/**
 * ExternalPpApi - object-oriented interface
 * @export
 * @class ExternalPpApi
 * @extends {BaseAPI}
 */
export class ExternalPpApi extends BaseAPI {
    /**
     * 
     * @param {ExternalPpApiExternalPpControllerConfirmExternalPaymentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalPpApi
     */
    public externalPpControllerConfirmExternalPayment(requestParameters: ExternalPpApiExternalPpControllerConfirmExternalPaymentRequest, options?: any) {
        return ExternalPpApiFp(this.configuration).externalPpControllerConfirmExternalPayment(requestParameters.id, requestParameters.skipEmail, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExtrasApi - axios parameter creator
 * @export
 */
export const ExtrasApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateExternalServicesDto} createExternalServicesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalServicesControllerCreate: async (createExternalServicesDto: CreateExternalServicesDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createExternalServicesDto' is not null or undefined
            assertParamExists('externalServicesControllerCreate', 'createExternalServicesDto', createExternalServicesDto)
            const localVarPath = `/api/v1/bookings/extras`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createExternalServicesDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalServicesControllerDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('externalServicesControllerDelete', 'id', id)
            const localVarPath = `/api/v1/bookings/extras/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalServicesControllerFindMany: async (page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, csv?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/bookings/extras`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['orderDir'] = orderDir;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (filterBy !== undefined) {
                localVarQueryParameter['filterBy'] = filterBy;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (csv !== undefined) {
                localVarQueryParameter['csv'] = csv;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalServicesControllerFindOne: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('externalServicesControllerFindOne', 'id', id)
            const localVarPath = `/api/v1/bookings/extras/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateExternalServicesDto} updateExternalServicesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalServicesControllerUpdate: async (id: number, updateExternalServicesDto: UpdateExternalServicesDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('externalServicesControllerUpdate', 'id', id)
            // verify required parameter 'updateExternalServicesDto' is not null or undefined
            assertParamExists('externalServicesControllerUpdate', 'updateExternalServicesDto', updateExternalServicesDto)
            const localVarPath = `/api/v1/bookings/extras/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateExternalServicesDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExtrasApi - functional programming interface
 * @export
 */
export const ExtrasApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExtrasApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateExternalServicesDto} createExternalServicesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async externalServicesControllerCreate(createExternalServicesDto: CreateExternalServicesDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateExternalServicesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.externalServicesControllerCreate(createExternalServicesDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async externalServicesControllerDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.externalServicesControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async externalServicesControllerFindMany(page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, csv?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExternalServicesChunkResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.externalServicesControllerFindMany(page, itemsPerPage, orderBy, orderDir, search, filterBy, filter, csv, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async externalServicesControllerFindOne(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseExternalServicesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.externalServicesControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateExternalServicesDto} updateExternalServicesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async externalServicesControllerUpdate(id: number, updateExternalServicesDto: UpdateExternalServicesDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseExternalServicesDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.externalServicesControllerUpdate(id, updateExternalServicesDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExtrasApi - factory interface
 * @export
 */
export const ExtrasApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExtrasApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateExternalServicesDto} createExternalServicesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalServicesControllerCreate(createExternalServicesDto: CreateExternalServicesDto, options?: any): AxiosPromise<CreateExternalServicesDto> {
            return localVarFp.externalServicesControllerCreate(createExternalServicesDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalServicesControllerDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.externalServicesControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalServicesControllerFindMany(page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, csv?: string, options?: any): AxiosPromise<ExternalServicesChunkResponseDto> {
            return localVarFp.externalServicesControllerFindMany(page, itemsPerPage, orderBy, orderDir, search, filterBy, filter, csv, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalServicesControllerFindOne(id: number, options?: any): AxiosPromise<ResponseExternalServicesDto> {
            return localVarFp.externalServicesControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateExternalServicesDto} updateExternalServicesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalServicesControllerUpdate(id: number, updateExternalServicesDto: UpdateExternalServicesDto, options?: any): AxiosPromise<ResponseExternalServicesDto> {
            return localVarFp.externalServicesControllerUpdate(id, updateExternalServicesDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for externalServicesControllerCreate operation in ExtrasApi.
 * @export
 * @interface ExtrasApiExternalServicesControllerCreateRequest
 */
export interface ExtrasApiExternalServicesControllerCreateRequest {
    /**
     * 
     * @type {CreateExternalServicesDto}
     * @memberof ExtrasApiExternalServicesControllerCreate
     */
    readonly createExternalServicesDto: CreateExternalServicesDto
}

/**
 * Request parameters for externalServicesControllerDelete operation in ExtrasApi.
 * @export
 * @interface ExtrasApiExternalServicesControllerDeleteRequest
 */
export interface ExtrasApiExternalServicesControllerDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof ExtrasApiExternalServicesControllerDelete
     */
    readonly id: number
}

/**
 * Request parameters for externalServicesControllerFindMany operation in ExtrasApi.
 * @export
 * @interface ExtrasApiExternalServicesControllerFindManyRequest
 */
export interface ExtrasApiExternalServicesControllerFindManyRequest {
    /**
     * Number of the page to fetch
     * @type {number}
     * @memberof ExtrasApiExternalServicesControllerFindMany
     */
    readonly page?: number

    /**
     * Number of items per page
     * @type {number}
     * @memberof ExtrasApiExternalServicesControllerFindMany
     */
    readonly itemsPerPage?: number

    /**
     * Name of the property to order by
     * @type {string}
     * @memberof ExtrasApiExternalServicesControllerFindMany
     */
    readonly orderBy?: string

    /**
     * Value which specify direction of the order
     * @type {OrderByDirection}
     * @memberof ExtrasApiExternalServicesControllerFindMany
     */
    readonly orderDir?: OrderByDirection

    /**
     * Text which will be used to search through table data
     * @type {string}
     * @memberof ExtrasApiExternalServicesControllerFindMany
     */
    readonly search?: string

    /**
     * Name of the property to filter by
     * @type {string}
     * @memberof ExtrasApiExternalServicesControllerFindMany
     */
    readonly filterBy?: string

    /**
     * Value which will be used to filter table data
     * @type {string}
     * @memberof ExtrasApiExternalServicesControllerFindMany
     */
    readonly filter?: string

    /**
     * 
     * @type {string}
     * @memberof ExtrasApiExternalServicesControllerFindMany
     */
    readonly csv?: string
}

/**
 * Request parameters for externalServicesControllerFindOne operation in ExtrasApi.
 * @export
 * @interface ExtrasApiExternalServicesControllerFindOneRequest
 */
export interface ExtrasApiExternalServicesControllerFindOneRequest {
    /**
     * 
     * @type {number}
     * @memberof ExtrasApiExternalServicesControllerFindOne
     */
    readonly id: number
}

/**
 * Request parameters for externalServicesControllerUpdate operation in ExtrasApi.
 * @export
 * @interface ExtrasApiExternalServicesControllerUpdateRequest
 */
export interface ExtrasApiExternalServicesControllerUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof ExtrasApiExternalServicesControllerUpdate
     */
    readonly id: number

    /**
     * 
     * @type {UpdateExternalServicesDto}
     * @memberof ExtrasApiExternalServicesControllerUpdate
     */
    readonly updateExternalServicesDto: UpdateExternalServicesDto
}

/**
 * ExtrasApi - object-oriented interface
 * @export
 * @class ExtrasApi
 * @extends {BaseAPI}
 */
export class ExtrasApi extends BaseAPI {
    /**
     * 
     * @param {ExtrasApiExternalServicesControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExtrasApi
     */
    public externalServicesControllerCreate(requestParameters: ExtrasApiExternalServicesControllerCreateRequest, options?: any) {
        return ExtrasApiFp(this.configuration).externalServicesControllerCreate(requestParameters.createExternalServicesDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExtrasApiExternalServicesControllerDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExtrasApi
     */
    public externalServicesControllerDelete(requestParameters: ExtrasApiExternalServicesControllerDeleteRequest, options?: any) {
        return ExtrasApiFp(this.configuration).externalServicesControllerDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExtrasApiExternalServicesControllerFindManyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExtrasApi
     */
    public externalServicesControllerFindMany(requestParameters: ExtrasApiExternalServicesControllerFindManyRequest = {}, options?: any) {
        return ExtrasApiFp(this.configuration).externalServicesControllerFindMany(requestParameters.page, requestParameters.itemsPerPage, requestParameters.orderBy, requestParameters.orderDir, requestParameters.search, requestParameters.filterBy, requestParameters.filter, requestParameters.csv, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExtrasApiExternalServicesControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExtrasApi
     */
    public externalServicesControllerFindOne(requestParameters: ExtrasApiExternalServicesControllerFindOneRequest, options?: any) {
        return ExtrasApiFp(this.configuration).externalServicesControllerFindOne(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExtrasApiExternalServicesControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExtrasApi
     */
    public externalServicesControllerUpdate(requestParameters: ExtrasApiExternalServicesControllerUpdateRequest, options?: any) {
        return ExtrasApiFp(this.configuration).externalServicesControllerUpdate(requestParameters.id, requestParameters.updateExternalServicesDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FeaturesApi - axios parameter creator
 * @export
 */
export const FeaturesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {string} from 
         * @param {string} to 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        availabilitiesControllerGetBeachChairAvailability: async (id: number, from: string, to: string, timeZone?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('availabilitiesControllerGetBeachChairAvailability', 'id', id)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('availabilitiesControllerGetBeachChairAvailability', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('availabilitiesControllerGetBeachChairAvailability', 'to', to)
            const localVarPath = `/api/v1/features/availabilities/beach_chairs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (timeZone !== undefined) {
                localVarQueryParameter['timeZone'] = timeZone;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [vendorId] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [date] 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        availabilitiesControllerGetSectionsAvailability: async (vendorId?: number, startDate?: string, endDate?: string, date?: string, timeZone?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/features/availabilities/sections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (vendorId !== undefined) {
                localVarQueryParameter['vendorId'] = vendorId;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (timeZone !== undefined) {
                localVarQueryParameter['timeZone'] = timeZone;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateBeachChairBodyDto} createBeachChairBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beachChairsControllerCreate: async (createBeachChairBodyDto: CreateBeachChairBodyDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createBeachChairBodyDto' is not null or undefined
            assertParamExists('beachChairsControllerCreate', 'createBeachChairBodyDto', createBeachChairBodyDto)
            const localVarPath = `/api/v1/features/beach_chair`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBeachChairBodyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BeachChairsFiltersDto} [filters] 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beachChairsControllerGetChairsByVendorId: async (filters?: BeachChairsFiltersDto, page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, csv?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/features/vendor/beach_chairs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filters !== undefined) {
                localVarQueryParameter['filters'] = filters;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['orderDir'] = orderDir;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (filterBy !== undefined) {
                localVarQueryParameter['filterBy'] = filterBy;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (csv !== undefined) {
                localVarQueryParameter['csv'] = csv;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id ID of the beach chair
         * @param {UpdateBeachChairBodyDto} updateBeachChairBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beachChairsControllerUpdate: async (id: number, updateBeachChairBodyDto: UpdateBeachChairBodyDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('beachChairsControllerUpdate', 'id', id)
            // verify required parameter 'updateBeachChairBodyDto' is not null or undefined
            assertParamExists('beachChairsControllerUpdate', 'updateBeachChairBodyDto', updateBeachChairBodyDto)
            const localVarPath = `/api/v1/features/beach_chair/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBeachChairBodyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id ID of the lock
         * @param {BeachChairByIdParamDto} beachChairByIdParamDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beachChairsControllerUpdateBeachChairLock: async (id: number, beachChairByIdParamDto: BeachChairByIdParamDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('beachChairsControllerUpdateBeachChairLock', 'id', id)
            // verify required parameter 'beachChairByIdParamDto' is not null or undefined
            assertParamExists('beachChairsControllerUpdateBeachChairLock', 'beachChairByIdParamDto', beachChairByIdParamDto)
            const localVarPath = `/api/v1/features/beach_chair/lock/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(beachChairByIdParamDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {CreateBeachChairBookingBodyDto} createBeachChairBookingBodyDto 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingDetailsControllerAddToBooking: async (id: number, createBeachChairBookingBodyDto: CreateBeachChairBookingBodyDto, timeZone?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bookingDetailsControllerAddToBooking', 'id', id)
            // verify required parameter 'createBeachChairBookingBodyDto' is not null or undefined
            assertParamExists('bookingDetailsControllerAddToBooking', 'createBeachChairBookingBodyDto', createBeachChairBookingBodyDto)
            const localVarPath = `/api/v1/features/booking_details/{id}/beach_chair_bookings`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (timeZone !== undefined) {
                localVarQueryParameter['timeZone'] = timeZone;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBeachChairBookingBodyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingDetailsControllerCalculateCancellationFee: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bookingDetailsControllerCalculateCancellationFee', 'id', id)
            const localVarPath = `/api/v1/features/booking_details/{id}/calculateCancellationFee`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} beachChairBookingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingDetailsControllerCancelBeachChairBooking: async (id: number, beachChairBookingId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bookingDetailsControllerCancelBeachChairBooking', 'id', id)
            // verify required parameter 'beachChairBookingId' is not null or undefined
            assertParamExists('bookingDetailsControllerCancelBeachChairBooking', 'beachChairBookingId', beachChairBookingId)
            const localVarPath = `/api/v1/features/booking_details/{id}/cancel/{beachChairBookingId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"beachChairBookingId"}}`, encodeURIComponent(String(beachChairBookingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [timeZone] 
         * @param {number} [cancellationFee] 
         * @param {boolean} [skipRefund] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingDetailsControllerCancelBooking: async (id: number, timeZone?: string, cancellationFee?: number, skipRefund?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bookingDetailsControllerCancelBooking', 'id', id)
            const localVarPath = `/api/v1/features/booking_details/{id}/cancel`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (timeZone !== undefined) {
                localVarQueryParameter['timeZone'] = timeZone;
            }

            if (cancellationFee !== undefined) {
                localVarQueryParameter['cancellationFee'] = cancellationFee;
            }

            if (skipRefund !== undefined) {
                localVarQueryParameter['skipRefund'] = skipRefund;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingDetailsControllerConfirmBooking: async (id: number, timeZone?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bookingDetailsControllerConfirmBooking', 'id', id)
            const localVarPath = `/api/v1/features/booking_details/{id}/confirm`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (timeZone !== undefined) {
                localVarQueryParameter['timeZone'] = timeZone;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [timeZone] 
         * @param {string} [buffer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingDetailsControllerCreateShortInvoice: async (id: number, timeZone?: string, buffer?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bookingDetailsControllerCreateShortInvoice', 'id', id)
            const localVarPath = `/api/v1/features/booking_details/{id}/shortInvoice`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (timeZone !== undefined) {
                localVarQueryParameter['timeZone'] = timeZone;
            }

            if (buffer !== undefined) {
                localVarQueryParameter['buffer'] = buffer;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BookingDetailFiltersDto} [filters] 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingDetailsControllerFindMany: async (filters?: BookingDetailFiltersDto, page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, csv?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/features/booking_details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filters !== undefined) {
                localVarQueryParameter['filters'] = filters;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['orderDir'] = orderDir;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (filterBy !== undefined) {
                localVarQueryParameter['filterBy'] = filterBy;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (csv !== undefined) {
                localVarQueryParameter['csv'] = csv;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BeachChairBookingFilterQuery} [filters] 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingDetailsControllerFindManyBeachChairBookings: async (filters?: BeachChairBookingFilterQuery, page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, csv?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/features/booking_details/beach_chair_bookings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filters !== undefined) {
                localVarQueryParameter['filters'] = filters;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['orderDir'] = orderDir;
            }

            if (csv !== undefined) {
                localVarQueryParameter['csv'] = csv;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BookingDetailFiltersDto} [filters] 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingDetailsControllerFindManyByCustomerId: async (filters?: BookingDetailFiltersDto, page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, csv?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/features/booking_details/customer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filters !== undefined) {
                localVarQueryParameter['filters'] = filters;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['orderDir'] = orderDir;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (filterBy !== undefined) {
                localVarQueryParameter['filterBy'] = filterBy;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (csv !== undefined) {
                localVarQueryParameter['csv'] = csv;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BeachChairBookingReportQuery} [filters] 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingDetailsControllerFindManyReport: async (filters?: BeachChairBookingReportQuery, page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, csv?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/features/booking_details/beach_chair_bookings_report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filters !== undefined) {
                localVarQueryParameter['filters'] = filters;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['orderDir'] = orderDir;
            }

            if (csv !== undefined) {
                localVarQueryParameter['csv'] = csv;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingDetailsControllerFindOne: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bookingDetailsControllerFindOne', 'id', id)
            const localVarPath = `/api/v1/features/booking_details/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingDetailsControllerGetFile: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bookingDetailsControllerGetFile', 'id', id)
            const localVarPath = `/api/v1/features/booking_details/{id}/prepayment`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingDetailsControllerGetInvoiceData: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bookingDetailsControllerGetInvoiceData', 'id', id)
            const localVarPath = `/api/v1/features/booking_details/{id}/invoiceData`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingDetailsControllerPayForBooking: async (id: number, timeZone?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bookingDetailsControllerPayForBooking', 'id', id)
            const localVarPath = `/api/v1/features/booking_details/{id}/pay`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (timeZone !== undefined) {
                localVarQueryParameter['timeZone'] = timeZone;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} onlineBooking 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingDetailsControllerRecalculation: async (id: string, onlineBooking: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bookingDetailsControllerRecalculation', 'id', id)
            // verify required parameter 'onlineBooking' is not null or undefined
            assertParamExists('bookingDetailsControllerRecalculation', 'onlineBooking', onlineBooking)
            const localVarPath = `/api/v1/features/booking_details/{id}/recalculateCommission`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (onlineBooking !== undefined) {
                localVarQueryParameter['onlineBooking'] = onlineBooking;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingDetailsControllerRecoverBooking: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bookingDetailsControllerRecoverBooking', 'id', id)
            const localVarPath = `/api/v1/features/booking_details/{id}/recover`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Use this endpoint to regenerate an Invoice. This will generate a new Invoicenumber.
         * @param {number} id 
         * @param {string} [invoiceNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingDetailsControllerRegenerateInvoice: async (id: number, invoiceNumber?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bookingDetailsControllerRegenerateInvoice', 'id', id)
            const localVarPath = `/api/v1/features/booking_details/{id}/invoice`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (invoiceNumber !== undefined) {
                localVarQueryParameter['invoiceNumber'] = invoiceNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingDetailsControllerResendBankTransfer: async (id: number, timeZone?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bookingDetailsControllerResendBankTransfer', 'id', id)
            const localVarPath = `/api/v1/features/booking_details/{id}/resendBankTransfer`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (timeZone !== undefined) {
                localVarQueryParameter['timeZone'] = timeZone;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingDetailsControllerResendPaymentLink: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bookingDetailsControllerResendPaymentLink', 'id', id)
            const localVarPath = `/api/v1/features/booking_details/{id}/resendPaymentLink`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingDetailsControllerReserveBooking: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bookingDetailsControllerReserveBooking', 'id', id)
            const localVarPath = `/api/v1/features/booking_details/{id}/reserve`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateBeachChairBookingBodyDto} updateBeachChairBookingBodyDto 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingDetailsControllerUpdateBeachChairBooking: async (id: number, updateBeachChairBookingBodyDto: UpdateBeachChairBookingBodyDto, timeZone?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('bookingDetailsControllerUpdateBeachChairBooking', 'id', id)
            // verify required parameter 'updateBeachChairBookingBodyDto' is not null or undefined
            assertParamExists('bookingDetailsControllerUpdateBeachChairBooking', 'updateBeachChairBookingBodyDto', updateBeachChairBookingBodyDto)
            const localVarPath = `/api/v1/features/booking_details/beach_chair_bookings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (timeZone !== undefined) {
                localVarQueryParameter['timeZone'] = timeZone;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBeachChairBookingBodyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DocumentsFiltersDto} [filters] 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerFindMany: async (filters?: DocumentsFiltersDto, page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, csv?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/features/documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filters !== undefined) {
                localVarQueryParameter['filters'] = filters;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['orderDir'] = orderDir;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (filterBy !== undefined) {
                localVarQueryParameter['filterBy'] = filterBy;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (csv !== undefined) {
                localVarQueryParameter['csv'] = csv;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} s3Key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerFindOne: async (s3Key: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 's3Key' is not null or undefined
            assertParamExists('documentsControllerFindOne', 's3Key', s3Key)
            const localVarPath = `/api/v1/features/documents/{s3Key}`
                .replace(`{${"s3Key"}}`, encodeURIComponent(String(s3Key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        featuresRowControllerDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('featuresRowControllerDelete', 'id', id)
            const localVarPath = `/api/v1/features/rows/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id File public_id in S3
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesControllerDeleteFile: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('filesControllerDeleteFile', 'id', id)
            const localVarPath = `/api/v1/features/files/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [file] Field which will be used to access file binary which was uploaded
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesControllerUploadFile: async (file?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/features/files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [vendorId] Vendor id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filtersControllerGetChairsFilters: async (vendorId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/features/filters/beach_chairs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (vendorId !== undefined) {
                localVarQueryParameter['vendorId'] = vendorId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id Image public_id in Cloudinary
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesControllerBeachById: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('imagesControllerBeachById', 'id', id)
            const localVarPath = `/api/v1/features/images/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [width] Width image to transform
         * @param {number} [height] Height image to transform
         * @param {any} [file] Field which will be used to access file binary which was uploaded
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesControllerUploadImage: async (width?: number, height?: number, file?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/features/images`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (width !== undefined) {
                localVarQueryParameter['width'] = width;
            }

            if (height !== undefined) {
                localVarQueryParameter['height'] = height;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateLocationDto} createLocationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationsControllerCreate: async (createLocationDto: CreateLocationDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createLocationDto' is not null or undefined
            assertParamExists('locationsControllerCreate', 'createLocationDto', createLocationDto)
            const localVarPath = `/api/v1/features/locations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createLocationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateLocationDto} updateLocationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationsControllerUpdate: async (id: number, updateLocationDto: UpdateLocationDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('locationsControllerUpdate', 'id', id)
            // verify required parameter 'updateLocationDto' is not null or undefined
            assertParamExists('locationsControllerUpdate', 'updateLocationDto', updateLocationDto)
            const localVarPath = `/api/v1/features/locations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLocationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {SectionsFiltersDto} [filters] 
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sectionsControllerFindMany: async (page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, filters?: SectionsFiltersDto, csv?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/features/sections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['orderDir'] = orderDir;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (filterBy !== undefined) {
                localVarQueryParameter['filterBy'] = filterBy;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (filters !== undefined) {
                localVarQueryParameter['filters'] = filters;
            }

            if (csv !== undefined) {
                localVarQueryParameter['csv'] = csv;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sectionsControllerGetChairsBySectionId: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sectionsControllerGetChairsBySectionId', 'id', id)
            const localVarPath = `/api/v1/features/sections/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {SectionTimeFilterParamDto} [filters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sectionsControllerGetSectionsBeachChairs: async (id: number, filters?: SectionTimeFilterParamDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sectionsControllerGetSectionsBeachChairs', 'id', id)
            const localVarPath = `/api/v1/features/sections/{id}/beach_chairs_availability`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filters !== undefined) {
                localVarQueryParameter['filters'] = filters;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {SectionTimeFilterParamDto} [filters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sectionsControllerGetSectionsBeachChairsBookings: async (id: number, filters?: SectionTimeFilterParamDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sectionsControllerGetSectionsBeachChairsBookings', 'id', id)
            const localVarPath = `/api/v1/features/sections/{id}/beach_chairs_bookings`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filters !== undefined) {
                localVarQueryParameter['filters'] = filters;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserCredentialsDto} userCredentialsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerAuthenticate: async (userCredentialsDto: UserCredentialsDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userCredentialsDto' is not null or undefined
            assertParamExists('usersControllerAuthenticate', 'userCredentialsDto', userCredentialsDto)
            const localVarPath = `/api/v1/features/users/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCredentialsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserCredentialsDto} userCredentialsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerAuthenticateWithRefresh: async (userCredentialsDto: UserCredentialsDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userCredentialsDto' is not null or undefined
            assertParamExists('usersControllerAuthenticateWithRefresh', 'userCredentialsDto', userCredentialsDto)
            const localVarPath = `/api/v1/features/users/loginWithRefresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCredentialsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ConfirmRegistrationDto} confirmRegistrationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerConfirm: async (confirmRegistrationDto: ConfirmRegistrationDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'confirmRegistrationDto' is not null or undefined
            assertParamExists('usersControllerConfirm', 'confirmRegistrationDto', confirmRegistrationDto)
            const localVarPath = `/api/v1/features/users/confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(confirmRegistrationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ConfirmPasswordDto} confirmPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerConfirmPassword: async (confirmPasswordDto: ConfirmPasswordDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'confirmPasswordDto' is not null or undefined
            assertParamExists('usersControllerConfirmPassword', 'confirmPasswordDto', confirmPasswordDto)
            const localVarPath = `/api/v1/features/users/password/confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(confirmPasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetAuthToken: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/features/users/admin/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RefreshTokenDto} refreshTokenDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerRefresh: async (refreshTokenDto: RefreshTokenDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'refreshTokenDto' is not null or undefined
            assertParamExists('usersControllerRefresh', 'refreshTokenDto', refreshTokenDto)
            const localVarPath = `/api/v1/features/users/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(refreshTokenDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegisterUserDto} registerUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerRegister: async (registerUserDto: RegisterUserDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'registerUserDto' is not null or undefined
            assertParamExists('usersControllerRegister', 'registerUserDto', registerUserDto)
            const localVarPath = `/api/v1/features/users/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ResendConfirmRegistrationDto} resendConfirmRegistrationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerResendConfirm: async (resendConfirmRegistrationDto: ResendConfirmRegistrationDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'resendConfirmRegistrationDto' is not null or undefined
            assertParamExists('usersControllerResendConfirm', 'resendConfirmRegistrationDto', resendConfirmRegistrationDto)
            const localVarPath = `/api/v1/features/users/confirm/resend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resendConfirmRegistrationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ResendConfirmRegistrationDto} resendConfirmRegistrationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerResetPassword: async (resendConfirmRegistrationDto: ResendConfirmRegistrationDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'resendConfirmRegistrationDto' is not null or undefined
            assertParamExists('usersControllerResetPassword', 'resendConfirmRegistrationDto', resendConfirmRegistrationDto)
            const localVarPath = `/api/v1/features/users/password/reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resendConfirmRegistrationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SetLocationContextDto} setLocationContextDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerSetLocationContext: async (setLocationContextDto: SetLocationContextDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'setLocationContextDto' is not null or undefined
            assertParamExists('usersControllerSetLocationContext', 'setLocationContextDto', setLocationContextDto)
            const localVarPath = `/api/v1/features/users/context/location`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setLocationContextDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SetVendorContextDto} setVendorContextDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerSetVendorContext: async (setVendorContextDto: SetVendorContextDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'setVendorContextDto' is not null or undefined
            assertParamExists('usersControllerSetVendorContext', 'setVendorContextDto', setVendorContextDto)
            const localVarPath = `/api/v1/features/users/context/vendor`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setVendorContextDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeaturesApi - functional programming interface
 * @export
 */
export const FeaturesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeaturesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {string} from 
         * @param {string} to 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async availabilitiesControllerGetBeachChairAvailability(id: number, from: string, to: string, timeZone?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BeachChairAvailability>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.availabilitiesControllerGetBeachChairAvailability(id, from, to, timeZone, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [vendorId] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [date] 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async availabilitiesControllerGetSectionsAvailability(vendorId?: number, startDate?: string, endDate?: string, date?: string, timeZone?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SectionDetailsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.availabilitiesControllerGetSectionsAvailability(vendorId, startDate, endDate, date, timeZone, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateBeachChairBodyDto} createBeachChairBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async beachChairsControllerCreate(createBeachChairBodyDto: CreateBeachChairBodyDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExtendedBeachChairResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.beachChairsControllerCreate(createBeachChairBodyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BeachChairsFiltersDto} [filters] 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async beachChairsControllerGetChairsByVendorId(filters?: BeachChairsFiltersDto, page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, csv?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BeachChairChunkResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.beachChairsControllerGetChairsByVendorId(filters, page, itemsPerPage, orderBy, orderDir, search, filterBy, filter, csv, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id ID of the beach chair
         * @param {UpdateBeachChairBodyDto} updateBeachChairBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async beachChairsControllerUpdate(id: number, updateBeachChairBodyDto: UpdateBeachChairBodyDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExtendedBeachChairResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.beachChairsControllerUpdate(id, updateBeachChairBodyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id ID of the lock
         * @param {BeachChairByIdParamDto} beachChairByIdParamDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async beachChairsControllerUpdateBeachChairLock(id: number, beachChairByIdParamDto: BeachChairByIdParamDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LockResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.beachChairsControllerUpdateBeachChairLock(id, beachChairByIdParamDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {CreateBeachChairBookingBodyDto} createBeachChairBookingBodyDto 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingDetailsControllerAddToBooking(id: number, createBeachChairBookingBodyDto: CreateBeachChairBookingBodyDto, timeZone?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingDetailsControllerAddToBooking(id, createBeachChairBookingBodyDto, timeZone, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingDetailsControllerCalculateCancellationFee(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingDetailsControllerCalculateCancellationFee(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} beachChairBookingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingDetailsControllerCancelBeachChairBooking(id: number, beachChairBookingId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingDetailsControllerCancelBeachChairBooking(id, beachChairBookingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [timeZone] 
         * @param {number} [cancellationFee] 
         * @param {boolean} [skipRefund] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingDetailsControllerCancelBooking(id: number, timeZone?: string, cancellationFee?: number, skipRefund?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingDetailsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingDetailsControllerCancelBooking(id, timeZone, cancellationFee, skipRefund, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingDetailsControllerConfirmBooking(id: number, timeZone?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingDetailsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingDetailsControllerConfirmBooking(id, timeZone, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [timeZone] 
         * @param {string} [buffer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingDetailsControllerCreateShortInvoice(id: number, timeZone?: string, buffer?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoicesResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingDetailsControllerCreateShortInvoice(id, timeZone, buffer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BookingDetailFiltersDto} [filters] 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingDetailsControllerFindMany(filters?: BookingDetailFiltersDto, page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, csv?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingDetailsChunkResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingDetailsControllerFindMany(filters, page, itemsPerPage, orderBy, orderDir, search, filterBy, filter, csv, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BeachChairBookingFilterQuery} [filters] 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingDetailsControllerFindManyBeachChairBookings(filters?: BeachChairBookingFilterQuery, page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, csv?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BeachChairBookingsChunkResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingDetailsControllerFindManyBeachChairBookings(filters, page, itemsPerPage, orderBy, orderDir, csv, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BookingDetailFiltersDto} [filters] 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingDetailsControllerFindManyByCustomerId(filters?: BookingDetailFiltersDto, page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, csv?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingDetailsChunkResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingDetailsControllerFindManyByCustomerId(filters, page, itemsPerPage, orderBy, orderDir, search, filterBy, filter, csv, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BeachChairBookingReportQuery} [filters] 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingDetailsControllerFindManyReport(filters?: BeachChairBookingReportQuery, page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, csv?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BeachChairBookingsReportChunkResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingDetailsControllerFindManyReport(filters, page, itemsPerPage, orderBy, orderDir, csv, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingDetailsControllerFindOne(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingDetailsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingDetailsControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingDetailsControllerGetFile(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingDetailsControllerGetFile(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingDetailsControllerGetInvoiceData(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoicesCreateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingDetailsControllerGetInvoiceData(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingDetailsControllerPayForBooking(id: number, timeZone?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingDetailsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingDetailsControllerPayForBooking(id, timeZone, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} onlineBooking 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingDetailsControllerRecalculation(id: string, onlineBooking: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingDetailsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingDetailsControllerRecalculation(id, onlineBooking, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingDetailsControllerRecoverBooking(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingDetailsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingDetailsControllerRecoverBooking(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Use this endpoint to regenerate an Invoice. This will generate a new Invoicenumber.
         * @param {number} id 
         * @param {string} [invoiceNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingDetailsControllerRegenerateInvoice(id: number, invoiceNumber?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingDetailsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingDetailsControllerRegenerateInvoice(id, invoiceNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingDetailsControllerResendBankTransfer(id: number, timeZone?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingDetailsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingDetailsControllerResendBankTransfer(id, timeZone, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingDetailsControllerResendPaymentLink(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingDetailsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingDetailsControllerResendPaymentLink(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingDetailsControllerReserveBooking(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingDetailsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingDetailsControllerReserveBooking(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateBeachChairBookingBodyDto} updateBeachChairBookingBodyDto 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingDetailsControllerUpdateBeachChairBooking(id: number, updateBeachChairBookingBodyDto: UpdateBeachChairBookingBodyDto, timeZone?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingDetailsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingDetailsControllerUpdateBeachChairBooking(id, updateBeachChairBookingBodyDto, timeZone, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DocumentsFiltersDto} [filters] 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentsControllerFindMany(filters?: DocumentsFiltersDto, page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, csv?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentsChunkResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentsControllerFindMany(filters, page, itemsPerPage, orderBy, orderDir, search, filterBy, filter, csv, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} s3Key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentsControllerFindOne(s3Key: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentsControllerFindOne(s3Key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async featuresRowControllerDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.featuresRowControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id File public_id in S3
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filesControllerDeleteFile(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filesControllerDeleteFile(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [file] Field which will be used to access file binary which was uploaded
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filesControllerUploadFile(file?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filesControllerUploadFile(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [vendorId] Vendor id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async filtersControllerGetChairsFilters(vendorId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FiltersResponseDtoClass>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.filtersControllerGetChairsFilters(vendorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id Image public_id in Cloudinary
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imagesControllerBeachById(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imagesControllerBeachById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [width] Width image to transform
         * @param {number} [height] Height image to transform
         * @param {any} [file] Field which will be used to access file binary which was uploaded
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imagesControllerUploadImage(width?: number, height?: number, file?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imagesControllerUploadImage(width, height, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateLocationDto} createLocationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async locationsControllerCreate(createLocationDto: CreateLocationDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.locationsControllerCreate(createLocationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateLocationDto} updateLocationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async locationsControllerUpdate(id: number, updateLocationDto: UpdateLocationDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.locationsControllerUpdate(id, updateLocationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {SectionsFiltersDto} [filters] 
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sectionsControllerFindMany(page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, filters?: SectionsFiltersDto, csv?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectionChunkResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sectionsControllerFindMany(page, itemsPerPage, orderBy, orderDir, search, filterBy, filter, filters, csv, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sectionsControllerGetChairsBySectionId(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectionDetailsViewDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sectionsControllerGetChairsBySectionId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {SectionTimeFilterParamDto} [filters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sectionsControllerGetSectionsBeachChairs(id: number, filters?: SectionTimeFilterParamDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SectionTimeFrameRowDataResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sectionsControllerGetSectionsBeachChairs(id, filters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {SectionTimeFilterParamDto} [filters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sectionsControllerGetSectionsBeachChairsBookings(id: number, filters?: SectionTimeFilterParamDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SectionTimeFrameBookingDataResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sectionsControllerGetSectionsBeachChairsBookings(id, filters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserCredentialsDto} userCredentialsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerAuthenticate(userCredentialsDto: UserCredentialsDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerAuthenticate(userCredentialsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserCredentialsDto} userCredentialsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerAuthenticateWithRefresh(userCredentialsDto: UserCredentialsDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JWTRefreshResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerAuthenticateWithRefresh(userCredentialsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ConfirmRegistrationDto} confirmRegistrationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerConfirm(confirmRegistrationDto: ConfirmRegistrationDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerConfirm(confirmRegistrationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ConfirmPasswordDto} confirmPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerConfirmPassword(confirmPasswordDto: ConfirmPasswordDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerConfirmPassword(confirmPasswordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerGetAuthToken(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthTokenDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerGetAuthToken(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RefreshTokenDto} refreshTokenDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerRefresh(refreshTokenDto: RefreshTokenDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerRefresh(refreshTokenDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RegisterUserDto} registerUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerRegister(registerUserDto: RegisterUserDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerRegister(registerUserDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ResendConfirmRegistrationDto} resendConfirmRegistrationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerResendConfirm(resendConfirmRegistrationDto: ResendConfirmRegistrationDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerResendConfirm(resendConfirmRegistrationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ResendConfirmRegistrationDto} resendConfirmRegistrationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerResetPassword(resendConfirmRegistrationDto: ResendConfirmRegistrationDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerResetPassword(resendConfirmRegistrationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SetLocationContextDto} setLocationContextDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerSetLocationContext(setLocationContextDto: SetLocationContextDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerSetLocationContext(setLocationContextDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SetVendorContextDto} setVendorContextDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerSetVendorContext(setVendorContextDto: SetVendorContextDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerSetVendorContext(setVendorContextDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FeaturesApi - factory interface
 * @export
 */
export const FeaturesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeaturesApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {string} from 
         * @param {string} to 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        availabilitiesControllerGetBeachChairAvailability(id: number, from: string, to: string, timeZone?: string, options?: any): AxiosPromise<Array<BeachChairAvailability>> {
            return localVarFp.availabilitiesControllerGetBeachChairAvailability(id, from, to, timeZone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [vendorId] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [date] 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        availabilitiesControllerGetSectionsAvailability(vendorId?: number, startDate?: string, endDate?: string, date?: string, timeZone?: string, options?: any): AxiosPromise<Array<SectionDetailsDto>> {
            return localVarFp.availabilitiesControllerGetSectionsAvailability(vendorId, startDate, endDate, date, timeZone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateBeachChairBodyDto} createBeachChairBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beachChairsControllerCreate(createBeachChairBodyDto: CreateBeachChairBodyDto, options?: any): AxiosPromise<ExtendedBeachChairResponseDto> {
            return localVarFp.beachChairsControllerCreate(createBeachChairBodyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BeachChairsFiltersDto} [filters] 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beachChairsControllerGetChairsByVendorId(filters?: BeachChairsFiltersDto, page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, csv?: string, options?: any): AxiosPromise<BeachChairChunkResponseDto> {
            return localVarFp.beachChairsControllerGetChairsByVendorId(filters, page, itemsPerPage, orderBy, orderDir, search, filterBy, filter, csv, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id ID of the beach chair
         * @param {UpdateBeachChairBodyDto} updateBeachChairBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beachChairsControllerUpdate(id: number, updateBeachChairBodyDto: UpdateBeachChairBodyDto, options?: any): AxiosPromise<ExtendedBeachChairResponseDto> {
            return localVarFp.beachChairsControllerUpdate(id, updateBeachChairBodyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id ID of the lock
         * @param {BeachChairByIdParamDto} beachChairByIdParamDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        beachChairsControllerUpdateBeachChairLock(id: number, beachChairByIdParamDto: BeachChairByIdParamDto, options?: any): AxiosPromise<LockResponseDto> {
            return localVarFp.beachChairsControllerUpdateBeachChairLock(id, beachChairByIdParamDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {CreateBeachChairBookingBodyDto} createBeachChairBookingBodyDto 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingDetailsControllerAddToBooking(id: number, createBeachChairBookingBodyDto: CreateBeachChairBookingBodyDto, timeZone?: string, options?: any): AxiosPromise<BookingResponseDto> {
            return localVarFp.bookingDetailsControllerAddToBooking(id, createBeachChairBookingBodyDto, timeZone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingDetailsControllerCalculateCancellationFee(id: number, options?: any): AxiosPromise<number> {
            return localVarFp.bookingDetailsControllerCalculateCancellationFee(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} beachChairBookingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingDetailsControllerCancelBeachChairBooking(id: number, beachChairBookingId: number, options?: any): AxiosPromise<void> {
            return localVarFp.bookingDetailsControllerCancelBeachChairBooking(id, beachChairBookingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [timeZone] 
         * @param {number} [cancellationFee] 
         * @param {boolean} [skipRefund] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingDetailsControllerCancelBooking(id: number, timeZone?: string, cancellationFee?: number, skipRefund?: boolean, options?: any): AxiosPromise<BookingDetailsResponseDto> {
            return localVarFp.bookingDetailsControllerCancelBooking(id, timeZone, cancellationFee, skipRefund, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingDetailsControllerConfirmBooking(id: number, timeZone?: string, options?: any): AxiosPromise<BookingDetailsResponseDto> {
            return localVarFp.bookingDetailsControllerConfirmBooking(id, timeZone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [timeZone] 
         * @param {string} [buffer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingDetailsControllerCreateShortInvoice(id: number, timeZone?: string, buffer?: string, options?: any): AxiosPromise<InvoicesResponseDto> {
            return localVarFp.bookingDetailsControllerCreateShortInvoice(id, timeZone, buffer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BookingDetailFiltersDto} [filters] 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingDetailsControllerFindMany(filters?: BookingDetailFiltersDto, page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, csv?: string, options?: any): AxiosPromise<BookingDetailsChunkResponseDto> {
            return localVarFp.bookingDetailsControllerFindMany(filters, page, itemsPerPage, orderBy, orderDir, search, filterBy, filter, csv, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BeachChairBookingFilterQuery} [filters] 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingDetailsControllerFindManyBeachChairBookings(filters?: BeachChairBookingFilterQuery, page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, csv?: string, options?: any): AxiosPromise<BeachChairBookingsChunkResponseDto> {
            return localVarFp.bookingDetailsControllerFindManyBeachChairBookings(filters, page, itemsPerPage, orderBy, orderDir, csv, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BookingDetailFiltersDto} [filters] 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingDetailsControllerFindManyByCustomerId(filters?: BookingDetailFiltersDto, page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, csv?: string, options?: any): AxiosPromise<BookingDetailsChunkResponseDto> {
            return localVarFp.bookingDetailsControllerFindManyByCustomerId(filters, page, itemsPerPage, orderBy, orderDir, search, filterBy, filter, csv, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BeachChairBookingReportQuery} [filters] 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingDetailsControllerFindManyReport(filters?: BeachChairBookingReportQuery, page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, csv?: string, options?: any): AxiosPromise<BeachChairBookingsReportChunkResponseDto> {
            return localVarFp.bookingDetailsControllerFindManyReport(filters, page, itemsPerPage, orderBy, orderDir, csv, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingDetailsControllerFindOne(id: string, options?: any): AxiosPromise<BookingDetailsResponseDto> {
            return localVarFp.bookingDetailsControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingDetailsControllerGetFile(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.bookingDetailsControllerGetFile(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingDetailsControllerGetInvoiceData(id: number, options?: any): AxiosPromise<InvoicesCreateDto> {
            return localVarFp.bookingDetailsControllerGetInvoiceData(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingDetailsControllerPayForBooking(id: number, timeZone?: string, options?: any): AxiosPromise<BookingDetailsResponseDto> {
            return localVarFp.bookingDetailsControllerPayForBooking(id, timeZone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} onlineBooking 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingDetailsControllerRecalculation(id: string, onlineBooking: string, options?: any): AxiosPromise<BookingDetailsResponseDto> {
            return localVarFp.bookingDetailsControllerRecalculation(id, onlineBooking, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingDetailsControllerRecoverBooking(id: number, options?: any): AxiosPromise<BookingDetailsResponseDto> {
            return localVarFp.bookingDetailsControllerRecoverBooking(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Use this endpoint to regenerate an Invoice. This will generate a new Invoicenumber.
         * @param {number} id 
         * @param {string} [invoiceNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingDetailsControllerRegenerateInvoice(id: number, invoiceNumber?: string, options?: any): AxiosPromise<BookingDetailsResponseDto> {
            return localVarFp.bookingDetailsControllerRegenerateInvoice(id, invoiceNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingDetailsControllerResendBankTransfer(id: number, timeZone?: string, options?: any): AxiosPromise<BookingDetailsResponseDto> {
            return localVarFp.bookingDetailsControllerResendBankTransfer(id, timeZone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingDetailsControllerResendPaymentLink(id: number, options?: any): AxiosPromise<BookingDetailsResponseDto> {
            return localVarFp.bookingDetailsControllerResendPaymentLink(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingDetailsControllerReserveBooking(id: number, options?: any): AxiosPromise<BookingDetailsResponseDto> {
            return localVarFp.bookingDetailsControllerReserveBooking(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateBeachChairBookingBodyDto} updateBeachChairBookingBodyDto 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingDetailsControllerUpdateBeachChairBooking(id: number, updateBeachChairBookingBodyDto: UpdateBeachChairBookingBodyDto, timeZone?: string, options?: any): AxiosPromise<BookingDetailsResponseDto> {
            return localVarFp.bookingDetailsControllerUpdateBeachChairBooking(id, updateBeachChairBookingBodyDto, timeZone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DocumentsFiltersDto} [filters] 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerFindMany(filters?: DocumentsFiltersDto, page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, csv?: string, options?: any): AxiosPromise<DocumentsChunkResponseDto> {
            return localVarFp.documentsControllerFindMany(filters, page, itemsPerPage, orderBy, orderDir, search, filterBy, filter, csv, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} s3Key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerFindOne(s3Key: string, options?: any): AxiosPromise<DocumentsResponseDto> {
            return localVarFp.documentsControllerFindOne(s3Key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        featuresRowControllerDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.featuresRowControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id File public_id in S3
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesControllerDeleteFile(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.filesControllerDeleteFile(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [file] Field which will be used to access file binary which was uploaded
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesControllerUploadFile(file?: any, options?: any): AxiosPromise<void> {
            return localVarFp.filesControllerUploadFile(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [vendorId] Vendor id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filtersControllerGetChairsFilters(vendorId?: number, options?: any): AxiosPromise<FiltersResponseDtoClass> {
            return localVarFp.filtersControllerGetChairsFilters(vendorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id Image public_id in Cloudinary
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesControllerBeachById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.imagesControllerBeachById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [width] Width image to transform
         * @param {number} [height] Height image to transform
         * @param {any} [file] Field which will be used to access file binary which was uploaded
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagesControllerUploadImage(width?: number, height?: number, file?: any, options?: any): AxiosPromise<void> {
            return localVarFp.imagesControllerUploadImage(width, height, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateLocationDto} createLocationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationsControllerCreate(createLocationDto: CreateLocationDto, options?: any): AxiosPromise<LocationResponseDto> {
            return localVarFp.locationsControllerCreate(createLocationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateLocationDto} updateLocationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationsControllerUpdate(id: number, updateLocationDto: UpdateLocationDto, options?: any): AxiosPromise<void> {
            return localVarFp.locationsControllerUpdate(id, updateLocationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {SectionsFiltersDto} [filters] 
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sectionsControllerFindMany(page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, filters?: SectionsFiltersDto, csv?: string, options?: any): AxiosPromise<SectionChunkResponseDto> {
            return localVarFp.sectionsControllerFindMany(page, itemsPerPage, orderBy, orderDir, search, filterBy, filter, filters, csv, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sectionsControllerGetChairsBySectionId(id: number, options?: any): AxiosPromise<SectionDetailsViewDto> {
            return localVarFp.sectionsControllerGetChairsBySectionId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {SectionTimeFilterParamDto} [filters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sectionsControllerGetSectionsBeachChairs(id: number, filters?: SectionTimeFilterParamDto, options?: any): AxiosPromise<Array<SectionTimeFrameRowDataResponseDto>> {
            return localVarFp.sectionsControllerGetSectionsBeachChairs(id, filters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {SectionTimeFilterParamDto} [filters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sectionsControllerGetSectionsBeachChairsBookings(id: number, filters?: SectionTimeFilterParamDto, options?: any): AxiosPromise<Array<SectionTimeFrameBookingDataResponseDto>> {
            return localVarFp.sectionsControllerGetSectionsBeachChairsBookings(id, filters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserCredentialsDto} userCredentialsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerAuthenticate(userCredentialsDto: UserCredentialsDto, options?: any): AxiosPromise<void> {
            return localVarFp.usersControllerAuthenticate(userCredentialsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserCredentialsDto} userCredentialsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerAuthenticateWithRefresh(userCredentialsDto: UserCredentialsDto, options?: any): AxiosPromise<JWTRefreshResponseDto> {
            return localVarFp.usersControllerAuthenticateWithRefresh(userCredentialsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ConfirmRegistrationDto} confirmRegistrationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerConfirm(confirmRegistrationDto: ConfirmRegistrationDto, options?: any): AxiosPromise<void> {
            return localVarFp.usersControllerConfirm(confirmRegistrationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ConfirmPasswordDto} confirmPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerConfirmPassword(confirmPasswordDto: ConfirmPasswordDto, options?: any): AxiosPromise<void> {
            return localVarFp.usersControllerConfirmPassword(confirmPasswordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetAuthToken(options?: any): AxiosPromise<AuthTokenDto> {
            return localVarFp.usersControllerGetAuthToken(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RefreshTokenDto} refreshTokenDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerRefresh(refreshTokenDto: RefreshTokenDto, options?: any): AxiosPromise<void> {
            return localVarFp.usersControllerRefresh(refreshTokenDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RegisterUserDto} registerUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerRegister(registerUserDto: RegisterUserDto, options?: any): AxiosPromise<void> {
            return localVarFp.usersControllerRegister(registerUserDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ResendConfirmRegistrationDto} resendConfirmRegistrationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerResendConfirm(resendConfirmRegistrationDto: ResendConfirmRegistrationDto, options?: any): AxiosPromise<void> {
            return localVarFp.usersControllerResendConfirm(resendConfirmRegistrationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ResendConfirmRegistrationDto} resendConfirmRegistrationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerResetPassword(resendConfirmRegistrationDto: ResendConfirmRegistrationDto, options?: any): AxiosPromise<void> {
            return localVarFp.usersControllerResetPassword(resendConfirmRegistrationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SetLocationContextDto} setLocationContextDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerSetLocationContext(setLocationContextDto: SetLocationContextDto, options?: any): AxiosPromise<void> {
            return localVarFp.usersControllerSetLocationContext(setLocationContextDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SetVendorContextDto} setVendorContextDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerSetVendorContext(setVendorContextDto: SetVendorContextDto, options?: any): AxiosPromise<void> {
            return localVarFp.usersControllerSetVendorContext(setVendorContextDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for availabilitiesControllerGetBeachChairAvailability operation in FeaturesApi.
 * @export
 * @interface FeaturesApiAvailabilitiesControllerGetBeachChairAvailabilityRequest
 */
export interface FeaturesApiAvailabilitiesControllerGetBeachChairAvailabilityRequest {
    /**
     * 
     * @type {number}
     * @memberof FeaturesApiAvailabilitiesControllerGetBeachChairAvailability
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof FeaturesApiAvailabilitiesControllerGetBeachChairAvailability
     */
    readonly from: string

    /**
     * 
     * @type {string}
     * @memberof FeaturesApiAvailabilitiesControllerGetBeachChairAvailability
     */
    readonly to: string

    /**
     * 
     * @type {string}
     * @memberof FeaturesApiAvailabilitiesControllerGetBeachChairAvailability
     */
    readonly timeZone?: string
}

/**
 * Request parameters for availabilitiesControllerGetSectionsAvailability operation in FeaturesApi.
 * @export
 * @interface FeaturesApiAvailabilitiesControllerGetSectionsAvailabilityRequest
 */
export interface FeaturesApiAvailabilitiesControllerGetSectionsAvailabilityRequest {
    /**
     * 
     * @type {number}
     * @memberof FeaturesApiAvailabilitiesControllerGetSectionsAvailability
     */
    readonly vendorId?: number

    /**
     * 
     * @type {string}
     * @memberof FeaturesApiAvailabilitiesControllerGetSectionsAvailability
     */
    readonly startDate?: string

    /**
     * 
     * @type {string}
     * @memberof FeaturesApiAvailabilitiesControllerGetSectionsAvailability
     */
    readonly endDate?: string

    /**
     * 
     * @type {string}
     * @memberof FeaturesApiAvailabilitiesControllerGetSectionsAvailability
     */
    readonly date?: string

    /**
     * 
     * @type {string}
     * @memberof FeaturesApiAvailabilitiesControllerGetSectionsAvailability
     */
    readonly timeZone?: string
}

/**
 * Request parameters for beachChairsControllerCreate operation in FeaturesApi.
 * @export
 * @interface FeaturesApiBeachChairsControllerCreateRequest
 */
export interface FeaturesApiBeachChairsControllerCreateRequest {
    /**
     * 
     * @type {CreateBeachChairBodyDto}
     * @memberof FeaturesApiBeachChairsControllerCreate
     */
    readonly createBeachChairBodyDto: CreateBeachChairBodyDto
}

/**
 * Request parameters for beachChairsControllerGetChairsByVendorId operation in FeaturesApi.
 * @export
 * @interface FeaturesApiBeachChairsControllerGetChairsByVendorIdRequest
 */
export interface FeaturesApiBeachChairsControllerGetChairsByVendorIdRequest {
    /**
     * 
     * @type {BeachChairsFiltersDto}
     * @memberof FeaturesApiBeachChairsControllerGetChairsByVendorId
     */
    readonly filters?: BeachChairsFiltersDto

    /**
     * Number of the page to fetch
     * @type {number}
     * @memberof FeaturesApiBeachChairsControllerGetChairsByVendorId
     */
    readonly page?: number

    /**
     * Number of items per page
     * @type {number}
     * @memberof FeaturesApiBeachChairsControllerGetChairsByVendorId
     */
    readonly itemsPerPage?: number

    /**
     * Name of the property to order by
     * @type {string}
     * @memberof FeaturesApiBeachChairsControllerGetChairsByVendorId
     */
    readonly orderBy?: string

    /**
     * Value which specify direction of the order
     * @type {OrderByDirection}
     * @memberof FeaturesApiBeachChairsControllerGetChairsByVendorId
     */
    readonly orderDir?: OrderByDirection

    /**
     * Text which will be used to search through table data
     * @type {string}
     * @memberof FeaturesApiBeachChairsControllerGetChairsByVendorId
     */
    readonly search?: string

    /**
     * Name of the property to filter by
     * @type {string}
     * @memberof FeaturesApiBeachChairsControllerGetChairsByVendorId
     */
    readonly filterBy?: string

    /**
     * Value which will be used to filter table data
     * @type {string}
     * @memberof FeaturesApiBeachChairsControllerGetChairsByVendorId
     */
    readonly filter?: string

    /**
     * 
     * @type {string}
     * @memberof FeaturesApiBeachChairsControllerGetChairsByVendorId
     */
    readonly csv?: string
}

/**
 * Request parameters for beachChairsControllerUpdate operation in FeaturesApi.
 * @export
 * @interface FeaturesApiBeachChairsControllerUpdateRequest
 */
export interface FeaturesApiBeachChairsControllerUpdateRequest {
    /**
     * ID of the beach chair
     * @type {number}
     * @memberof FeaturesApiBeachChairsControllerUpdate
     */
    readonly id: number

    /**
     * 
     * @type {UpdateBeachChairBodyDto}
     * @memberof FeaturesApiBeachChairsControllerUpdate
     */
    readonly updateBeachChairBodyDto: UpdateBeachChairBodyDto
}

/**
 * Request parameters for beachChairsControllerUpdateBeachChairLock operation in FeaturesApi.
 * @export
 * @interface FeaturesApiBeachChairsControllerUpdateBeachChairLockRequest
 */
export interface FeaturesApiBeachChairsControllerUpdateBeachChairLockRequest {
    /**
     * ID of the lock
     * @type {number}
     * @memberof FeaturesApiBeachChairsControllerUpdateBeachChairLock
     */
    readonly id: number

    /**
     * 
     * @type {BeachChairByIdParamDto}
     * @memberof FeaturesApiBeachChairsControllerUpdateBeachChairLock
     */
    readonly beachChairByIdParamDto: BeachChairByIdParamDto
}

/**
 * Request parameters for bookingDetailsControllerAddToBooking operation in FeaturesApi.
 * @export
 * @interface FeaturesApiBookingDetailsControllerAddToBookingRequest
 */
export interface FeaturesApiBookingDetailsControllerAddToBookingRequest {
    /**
     * 
     * @type {number}
     * @memberof FeaturesApiBookingDetailsControllerAddToBooking
     */
    readonly id: number

    /**
     * 
     * @type {CreateBeachChairBookingBodyDto}
     * @memberof FeaturesApiBookingDetailsControllerAddToBooking
     */
    readonly createBeachChairBookingBodyDto: CreateBeachChairBookingBodyDto

    /**
     * 
     * @type {string}
     * @memberof FeaturesApiBookingDetailsControllerAddToBooking
     */
    readonly timeZone?: string
}

/**
 * Request parameters for bookingDetailsControllerCalculateCancellationFee operation in FeaturesApi.
 * @export
 * @interface FeaturesApiBookingDetailsControllerCalculateCancellationFeeRequest
 */
export interface FeaturesApiBookingDetailsControllerCalculateCancellationFeeRequest {
    /**
     * 
     * @type {number}
     * @memberof FeaturesApiBookingDetailsControllerCalculateCancellationFee
     */
    readonly id: number
}

/**
 * Request parameters for bookingDetailsControllerCancelBeachChairBooking operation in FeaturesApi.
 * @export
 * @interface FeaturesApiBookingDetailsControllerCancelBeachChairBookingRequest
 */
export interface FeaturesApiBookingDetailsControllerCancelBeachChairBookingRequest {
    /**
     * 
     * @type {number}
     * @memberof FeaturesApiBookingDetailsControllerCancelBeachChairBooking
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof FeaturesApiBookingDetailsControllerCancelBeachChairBooking
     */
    readonly beachChairBookingId: number
}

/**
 * Request parameters for bookingDetailsControllerCancelBooking operation in FeaturesApi.
 * @export
 * @interface FeaturesApiBookingDetailsControllerCancelBookingRequest
 */
export interface FeaturesApiBookingDetailsControllerCancelBookingRequest {
    /**
     * 
     * @type {number}
     * @memberof FeaturesApiBookingDetailsControllerCancelBooking
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof FeaturesApiBookingDetailsControllerCancelBooking
     */
    readonly timeZone?: string

    /**
     * 
     * @type {number}
     * @memberof FeaturesApiBookingDetailsControllerCancelBooking
     */
    readonly cancellationFee?: number

    /**
     * 
     * @type {boolean}
     * @memberof FeaturesApiBookingDetailsControllerCancelBooking
     */
    readonly skipRefund?: boolean
}

/**
 * Request parameters for bookingDetailsControllerConfirmBooking operation in FeaturesApi.
 * @export
 * @interface FeaturesApiBookingDetailsControllerConfirmBookingRequest
 */
export interface FeaturesApiBookingDetailsControllerConfirmBookingRequest {
    /**
     * 
     * @type {number}
     * @memberof FeaturesApiBookingDetailsControllerConfirmBooking
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof FeaturesApiBookingDetailsControllerConfirmBooking
     */
    readonly timeZone?: string
}

/**
 * Request parameters for bookingDetailsControllerCreateShortInvoice operation in FeaturesApi.
 * @export
 * @interface FeaturesApiBookingDetailsControllerCreateShortInvoiceRequest
 */
export interface FeaturesApiBookingDetailsControllerCreateShortInvoiceRequest {
    /**
     * 
     * @type {number}
     * @memberof FeaturesApiBookingDetailsControllerCreateShortInvoice
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof FeaturesApiBookingDetailsControllerCreateShortInvoice
     */
    readonly timeZone?: string

    /**
     * 
     * @type {string}
     * @memberof FeaturesApiBookingDetailsControllerCreateShortInvoice
     */
    readonly buffer?: string
}

/**
 * Request parameters for bookingDetailsControllerFindMany operation in FeaturesApi.
 * @export
 * @interface FeaturesApiBookingDetailsControllerFindManyRequest
 */
export interface FeaturesApiBookingDetailsControllerFindManyRequest {
    /**
     * 
     * @type {BookingDetailFiltersDto}
     * @memberof FeaturesApiBookingDetailsControllerFindMany
     */
    readonly filters?: BookingDetailFiltersDto

    /**
     * Number of the page to fetch
     * @type {number}
     * @memberof FeaturesApiBookingDetailsControllerFindMany
     */
    readonly page?: number

    /**
     * Number of items per page
     * @type {number}
     * @memberof FeaturesApiBookingDetailsControllerFindMany
     */
    readonly itemsPerPage?: number

    /**
     * Name of the property to order by
     * @type {string}
     * @memberof FeaturesApiBookingDetailsControllerFindMany
     */
    readonly orderBy?: string

    /**
     * Value which specify direction of the order
     * @type {OrderByDirection}
     * @memberof FeaturesApiBookingDetailsControllerFindMany
     */
    readonly orderDir?: OrderByDirection

    /**
     * Text which will be used to search through table data
     * @type {string}
     * @memberof FeaturesApiBookingDetailsControllerFindMany
     */
    readonly search?: string

    /**
     * Name of the property to filter by
     * @type {string}
     * @memberof FeaturesApiBookingDetailsControllerFindMany
     */
    readonly filterBy?: string

    /**
     * Value which will be used to filter table data
     * @type {string}
     * @memberof FeaturesApiBookingDetailsControllerFindMany
     */
    readonly filter?: string

    /**
     * 
     * @type {string}
     * @memberof FeaturesApiBookingDetailsControllerFindMany
     */
    readonly csv?: string
}

/**
 * Request parameters for bookingDetailsControllerFindManyBeachChairBookings operation in FeaturesApi.
 * @export
 * @interface FeaturesApiBookingDetailsControllerFindManyBeachChairBookingsRequest
 */
export interface FeaturesApiBookingDetailsControllerFindManyBeachChairBookingsRequest {
    /**
     * 
     * @type {BeachChairBookingFilterQuery}
     * @memberof FeaturesApiBookingDetailsControllerFindManyBeachChairBookings
     */
    readonly filters?: BeachChairBookingFilterQuery

    /**
     * Number of the page to fetch
     * @type {number}
     * @memberof FeaturesApiBookingDetailsControllerFindManyBeachChairBookings
     */
    readonly page?: number

    /**
     * Number of items per page
     * @type {number}
     * @memberof FeaturesApiBookingDetailsControllerFindManyBeachChairBookings
     */
    readonly itemsPerPage?: number

    /**
     * Name of the property to order by
     * @type {string}
     * @memberof FeaturesApiBookingDetailsControllerFindManyBeachChairBookings
     */
    readonly orderBy?: string

    /**
     * Value which specify direction of the order
     * @type {OrderByDirection}
     * @memberof FeaturesApiBookingDetailsControllerFindManyBeachChairBookings
     */
    readonly orderDir?: OrderByDirection

    /**
     * 
     * @type {string}
     * @memberof FeaturesApiBookingDetailsControllerFindManyBeachChairBookings
     */
    readonly csv?: string
}

/**
 * Request parameters for bookingDetailsControllerFindManyByCustomerId operation in FeaturesApi.
 * @export
 * @interface FeaturesApiBookingDetailsControllerFindManyByCustomerIdRequest
 */
export interface FeaturesApiBookingDetailsControllerFindManyByCustomerIdRequest {
    /**
     * 
     * @type {BookingDetailFiltersDto}
     * @memberof FeaturesApiBookingDetailsControllerFindManyByCustomerId
     */
    readonly filters?: BookingDetailFiltersDto

    /**
     * Number of the page to fetch
     * @type {number}
     * @memberof FeaturesApiBookingDetailsControllerFindManyByCustomerId
     */
    readonly page?: number

    /**
     * Number of items per page
     * @type {number}
     * @memberof FeaturesApiBookingDetailsControllerFindManyByCustomerId
     */
    readonly itemsPerPage?: number

    /**
     * Name of the property to order by
     * @type {string}
     * @memberof FeaturesApiBookingDetailsControllerFindManyByCustomerId
     */
    readonly orderBy?: string

    /**
     * Value which specify direction of the order
     * @type {OrderByDirection}
     * @memberof FeaturesApiBookingDetailsControllerFindManyByCustomerId
     */
    readonly orderDir?: OrderByDirection

    /**
     * Text which will be used to search through table data
     * @type {string}
     * @memberof FeaturesApiBookingDetailsControllerFindManyByCustomerId
     */
    readonly search?: string

    /**
     * Name of the property to filter by
     * @type {string}
     * @memberof FeaturesApiBookingDetailsControllerFindManyByCustomerId
     */
    readonly filterBy?: string

    /**
     * Value which will be used to filter table data
     * @type {string}
     * @memberof FeaturesApiBookingDetailsControllerFindManyByCustomerId
     */
    readonly filter?: string

    /**
     * 
     * @type {string}
     * @memberof FeaturesApiBookingDetailsControllerFindManyByCustomerId
     */
    readonly csv?: string
}

/**
 * Request parameters for bookingDetailsControllerFindManyReport operation in FeaturesApi.
 * @export
 * @interface FeaturesApiBookingDetailsControllerFindManyReportRequest
 */
export interface FeaturesApiBookingDetailsControllerFindManyReportRequest {
    /**
     * 
     * @type {BeachChairBookingReportQuery}
     * @memberof FeaturesApiBookingDetailsControllerFindManyReport
     */
    readonly filters?: BeachChairBookingReportQuery

    /**
     * Number of the page to fetch
     * @type {number}
     * @memberof FeaturesApiBookingDetailsControllerFindManyReport
     */
    readonly page?: number

    /**
     * Number of items per page
     * @type {number}
     * @memberof FeaturesApiBookingDetailsControllerFindManyReport
     */
    readonly itemsPerPage?: number

    /**
     * Name of the property to order by
     * @type {string}
     * @memberof FeaturesApiBookingDetailsControllerFindManyReport
     */
    readonly orderBy?: string

    /**
     * Value which specify direction of the order
     * @type {OrderByDirection}
     * @memberof FeaturesApiBookingDetailsControllerFindManyReport
     */
    readonly orderDir?: OrderByDirection

    /**
     * 
     * @type {string}
     * @memberof FeaturesApiBookingDetailsControllerFindManyReport
     */
    readonly csv?: string
}

/**
 * Request parameters for bookingDetailsControllerFindOne operation in FeaturesApi.
 * @export
 * @interface FeaturesApiBookingDetailsControllerFindOneRequest
 */
export interface FeaturesApiBookingDetailsControllerFindOneRequest {
    /**
     * 
     * @type {string}
     * @memberof FeaturesApiBookingDetailsControllerFindOne
     */
    readonly id: string
}

/**
 * Request parameters for bookingDetailsControllerGetFile operation in FeaturesApi.
 * @export
 * @interface FeaturesApiBookingDetailsControllerGetFileRequest
 */
export interface FeaturesApiBookingDetailsControllerGetFileRequest {
    /**
     * 
     * @type {number}
     * @memberof FeaturesApiBookingDetailsControllerGetFile
     */
    readonly id: number
}

/**
 * Request parameters for bookingDetailsControllerGetInvoiceData operation in FeaturesApi.
 * @export
 * @interface FeaturesApiBookingDetailsControllerGetInvoiceDataRequest
 */
export interface FeaturesApiBookingDetailsControllerGetInvoiceDataRequest {
    /**
     * 
     * @type {number}
     * @memberof FeaturesApiBookingDetailsControllerGetInvoiceData
     */
    readonly id: number
}

/**
 * Request parameters for bookingDetailsControllerPayForBooking operation in FeaturesApi.
 * @export
 * @interface FeaturesApiBookingDetailsControllerPayForBookingRequest
 */
export interface FeaturesApiBookingDetailsControllerPayForBookingRequest {
    /**
     * 
     * @type {number}
     * @memberof FeaturesApiBookingDetailsControllerPayForBooking
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof FeaturesApiBookingDetailsControllerPayForBooking
     */
    readonly timeZone?: string
}

/**
 * Request parameters for bookingDetailsControllerRecalculation operation in FeaturesApi.
 * @export
 * @interface FeaturesApiBookingDetailsControllerRecalculationRequest
 */
export interface FeaturesApiBookingDetailsControllerRecalculationRequest {
    /**
     * 
     * @type {string}
     * @memberof FeaturesApiBookingDetailsControllerRecalculation
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof FeaturesApiBookingDetailsControllerRecalculation
     */
    readonly onlineBooking: string
}

/**
 * Request parameters for bookingDetailsControllerRecoverBooking operation in FeaturesApi.
 * @export
 * @interface FeaturesApiBookingDetailsControllerRecoverBookingRequest
 */
export interface FeaturesApiBookingDetailsControllerRecoverBookingRequest {
    /**
     * 
     * @type {number}
     * @memberof FeaturesApiBookingDetailsControllerRecoverBooking
     */
    readonly id: number
}

/**
 * Request parameters for bookingDetailsControllerRegenerateInvoice operation in FeaturesApi.
 * @export
 * @interface FeaturesApiBookingDetailsControllerRegenerateInvoiceRequest
 */
export interface FeaturesApiBookingDetailsControllerRegenerateInvoiceRequest {
    /**
     * 
     * @type {number}
     * @memberof FeaturesApiBookingDetailsControllerRegenerateInvoice
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof FeaturesApiBookingDetailsControllerRegenerateInvoice
     */
    readonly invoiceNumber?: string
}

/**
 * Request parameters for bookingDetailsControllerResendBankTransfer operation in FeaturesApi.
 * @export
 * @interface FeaturesApiBookingDetailsControllerResendBankTransferRequest
 */
export interface FeaturesApiBookingDetailsControllerResendBankTransferRequest {
    /**
     * 
     * @type {number}
     * @memberof FeaturesApiBookingDetailsControllerResendBankTransfer
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof FeaturesApiBookingDetailsControllerResendBankTransfer
     */
    readonly timeZone?: string
}

/**
 * Request parameters for bookingDetailsControllerResendPaymentLink operation in FeaturesApi.
 * @export
 * @interface FeaturesApiBookingDetailsControllerResendPaymentLinkRequest
 */
export interface FeaturesApiBookingDetailsControllerResendPaymentLinkRequest {
    /**
     * 
     * @type {number}
     * @memberof FeaturesApiBookingDetailsControllerResendPaymentLink
     */
    readonly id: number
}

/**
 * Request parameters for bookingDetailsControllerReserveBooking operation in FeaturesApi.
 * @export
 * @interface FeaturesApiBookingDetailsControllerReserveBookingRequest
 */
export interface FeaturesApiBookingDetailsControllerReserveBookingRequest {
    /**
     * 
     * @type {number}
     * @memberof FeaturesApiBookingDetailsControllerReserveBooking
     */
    readonly id: number
}

/**
 * Request parameters for bookingDetailsControllerUpdateBeachChairBooking operation in FeaturesApi.
 * @export
 * @interface FeaturesApiBookingDetailsControllerUpdateBeachChairBookingRequest
 */
export interface FeaturesApiBookingDetailsControllerUpdateBeachChairBookingRequest {
    /**
     * 
     * @type {number}
     * @memberof FeaturesApiBookingDetailsControllerUpdateBeachChairBooking
     */
    readonly id: number

    /**
     * 
     * @type {UpdateBeachChairBookingBodyDto}
     * @memberof FeaturesApiBookingDetailsControllerUpdateBeachChairBooking
     */
    readonly updateBeachChairBookingBodyDto: UpdateBeachChairBookingBodyDto

    /**
     * 
     * @type {string}
     * @memberof FeaturesApiBookingDetailsControllerUpdateBeachChairBooking
     */
    readonly timeZone?: string
}

/**
 * Request parameters for documentsControllerFindMany operation in FeaturesApi.
 * @export
 * @interface FeaturesApiDocumentsControllerFindManyRequest
 */
export interface FeaturesApiDocumentsControllerFindManyRequest {
    /**
     * 
     * @type {DocumentsFiltersDto}
     * @memberof FeaturesApiDocumentsControllerFindMany
     */
    readonly filters?: DocumentsFiltersDto

    /**
     * Number of the page to fetch
     * @type {number}
     * @memberof FeaturesApiDocumentsControllerFindMany
     */
    readonly page?: number

    /**
     * Number of items per page
     * @type {number}
     * @memberof FeaturesApiDocumentsControllerFindMany
     */
    readonly itemsPerPage?: number

    /**
     * Name of the property to order by
     * @type {string}
     * @memberof FeaturesApiDocumentsControllerFindMany
     */
    readonly orderBy?: string

    /**
     * Value which specify direction of the order
     * @type {OrderByDirection}
     * @memberof FeaturesApiDocumentsControllerFindMany
     */
    readonly orderDir?: OrderByDirection

    /**
     * Text which will be used to search through table data
     * @type {string}
     * @memberof FeaturesApiDocumentsControllerFindMany
     */
    readonly search?: string

    /**
     * Name of the property to filter by
     * @type {string}
     * @memberof FeaturesApiDocumentsControllerFindMany
     */
    readonly filterBy?: string

    /**
     * Value which will be used to filter table data
     * @type {string}
     * @memberof FeaturesApiDocumentsControllerFindMany
     */
    readonly filter?: string

    /**
     * 
     * @type {string}
     * @memberof FeaturesApiDocumentsControllerFindMany
     */
    readonly csv?: string
}

/**
 * Request parameters for documentsControllerFindOne operation in FeaturesApi.
 * @export
 * @interface FeaturesApiDocumentsControllerFindOneRequest
 */
export interface FeaturesApiDocumentsControllerFindOneRequest {
    /**
     * 
     * @type {string}
     * @memberof FeaturesApiDocumentsControllerFindOne
     */
    readonly s3Key: string
}

/**
 * Request parameters for featuresRowControllerDelete operation in FeaturesApi.
 * @export
 * @interface FeaturesApiFeaturesRowControllerDeleteRequest
 */
export interface FeaturesApiFeaturesRowControllerDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof FeaturesApiFeaturesRowControllerDelete
     */
    readonly id: number
}

/**
 * Request parameters for filesControllerDeleteFile operation in FeaturesApi.
 * @export
 * @interface FeaturesApiFilesControllerDeleteFileRequest
 */
export interface FeaturesApiFilesControllerDeleteFileRequest {
    /**
     * File public_id in S3
     * @type {string}
     * @memberof FeaturesApiFilesControllerDeleteFile
     */
    readonly id: string
}

/**
 * Request parameters for filesControllerUploadFile operation in FeaturesApi.
 * @export
 * @interface FeaturesApiFilesControllerUploadFileRequest
 */
export interface FeaturesApiFilesControllerUploadFileRequest {
    /**
     * Field which will be used to access file binary which was uploaded
     * @type {any}
     * @memberof FeaturesApiFilesControllerUploadFile
     */
    readonly file?: any
}

/**
 * Request parameters for filtersControllerGetChairsFilters operation in FeaturesApi.
 * @export
 * @interface FeaturesApiFiltersControllerGetChairsFiltersRequest
 */
export interface FeaturesApiFiltersControllerGetChairsFiltersRequest {
    /**
     * Vendor id
     * @type {number}
     * @memberof FeaturesApiFiltersControllerGetChairsFilters
     */
    readonly vendorId?: number
}

/**
 * Request parameters for imagesControllerBeachById operation in FeaturesApi.
 * @export
 * @interface FeaturesApiImagesControllerBeachByIdRequest
 */
export interface FeaturesApiImagesControllerBeachByIdRequest {
    /**
     * Image public_id in Cloudinary
     * @type {string}
     * @memberof FeaturesApiImagesControllerBeachById
     */
    readonly id: string
}

/**
 * Request parameters for imagesControllerUploadImage operation in FeaturesApi.
 * @export
 * @interface FeaturesApiImagesControllerUploadImageRequest
 */
export interface FeaturesApiImagesControllerUploadImageRequest {
    /**
     * Width image to transform
     * @type {number}
     * @memberof FeaturesApiImagesControllerUploadImage
     */
    readonly width?: number

    /**
     * Height image to transform
     * @type {number}
     * @memberof FeaturesApiImagesControllerUploadImage
     */
    readonly height?: number

    /**
     * Field which will be used to access file binary which was uploaded
     * @type {any}
     * @memberof FeaturesApiImagesControllerUploadImage
     */
    readonly file?: any
}

/**
 * Request parameters for locationsControllerCreate operation in FeaturesApi.
 * @export
 * @interface FeaturesApiLocationsControllerCreateRequest
 */
export interface FeaturesApiLocationsControllerCreateRequest {
    /**
     * 
     * @type {CreateLocationDto}
     * @memberof FeaturesApiLocationsControllerCreate
     */
    readonly createLocationDto: CreateLocationDto
}

/**
 * Request parameters for locationsControllerUpdate operation in FeaturesApi.
 * @export
 * @interface FeaturesApiLocationsControllerUpdateRequest
 */
export interface FeaturesApiLocationsControllerUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof FeaturesApiLocationsControllerUpdate
     */
    readonly id: number

    /**
     * 
     * @type {UpdateLocationDto}
     * @memberof FeaturesApiLocationsControllerUpdate
     */
    readonly updateLocationDto: UpdateLocationDto
}

/**
 * Request parameters for sectionsControllerFindMany operation in FeaturesApi.
 * @export
 * @interface FeaturesApiSectionsControllerFindManyRequest
 */
export interface FeaturesApiSectionsControllerFindManyRequest {
    /**
     * Number of the page to fetch
     * @type {number}
     * @memberof FeaturesApiSectionsControllerFindMany
     */
    readonly page?: number

    /**
     * Number of items per page
     * @type {number}
     * @memberof FeaturesApiSectionsControllerFindMany
     */
    readonly itemsPerPage?: number

    /**
     * Name of the property to order by
     * @type {string}
     * @memberof FeaturesApiSectionsControllerFindMany
     */
    readonly orderBy?: string

    /**
     * Value which specify direction of the order
     * @type {OrderByDirection}
     * @memberof FeaturesApiSectionsControllerFindMany
     */
    readonly orderDir?: OrderByDirection

    /**
     * Text which will be used to search through table data
     * @type {string}
     * @memberof FeaturesApiSectionsControllerFindMany
     */
    readonly search?: string

    /**
     * Name of the property to filter by
     * @type {string}
     * @memberof FeaturesApiSectionsControllerFindMany
     */
    readonly filterBy?: string

    /**
     * Value which will be used to filter table data
     * @type {string}
     * @memberof FeaturesApiSectionsControllerFindMany
     */
    readonly filter?: string

    /**
     * 
     * @type {SectionsFiltersDto}
     * @memberof FeaturesApiSectionsControllerFindMany
     */
    readonly filters?: SectionsFiltersDto

    /**
     * 
     * @type {string}
     * @memberof FeaturesApiSectionsControllerFindMany
     */
    readonly csv?: string
}

/**
 * Request parameters for sectionsControllerGetChairsBySectionId operation in FeaturesApi.
 * @export
 * @interface FeaturesApiSectionsControllerGetChairsBySectionIdRequest
 */
export interface FeaturesApiSectionsControllerGetChairsBySectionIdRequest {
    /**
     * 
     * @type {number}
     * @memberof FeaturesApiSectionsControllerGetChairsBySectionId
     */
    readonly id: number
}

/**
 * Request parameters for sectionsControllerGetSectionsBeachChairs operation in FeaturesApi.
 * @export
 * @interface FeaturesApiSectionsControllerGetSectionsBeachChairsRequest
 */
export interface FeaturesApiSectionsControllerGetSectionsBeachChairsRequest {
    /**
     * 
     * @type {number}
     * @memberof FeaturesApiSectionsControllerGetSectionsBeachChairs
     */
    readonly id: number

    /**
     * 
     * @type {SectionTimeFilterParamDto}
     * @memberof FeaturesApiSectionsControllerGetSectionsBeachChairs
     */
    readonly filters?: SectionTimeFilterParamDto
}

/**
 * Request parameters for sectionsControllerGetSectionsBeachChairsBookings operation in FeaturesApi.
 * @export
 * @interface FeaturesApiSectionsControllerGetSectionsBeachChairsBookingsRequest
 */
export interface FeaturesApiSectionsControllerGetSectionsBeachChairsBookingsRequest {
    /**
     * 
     * @type {number}
     * @memberof FeaturesApiSectionsControllerGetSectionsBeachChairsBookings
     */
    readonly id: number

    /**
     * 
     * @type {SectionTimeFilterParamDto}
     * @memberof FeaturesApiSectionsControllerGetSectionsBeachChairsBookings
     */
    readonly filters?: SectionTimeFilterParamDto
}

/**
 * Request parameters for usersControllerAuthenticate operation in FeaturesApi.
 * @export
 * @interface FeaturesApiUsersControllerAuthenticateRequest
 */
export interface FeaturesApiUsersControllerAuthenticateRequest {
    /**
     * 
     * @type {UserCredentialsDto}
     * @memberof FeaturesApiUsersControllerAuthenticate
     */
    readonly userCredentialsDto: UserCredentialsDto
}

/**
 * Request parameters for usersControllerAuthenticateWithRefresh operation in FeaturesApi.
 * @export
 * @interface FeaturesApiUsersControllerAuthenticateWithRefreshRequest
 */
export interface FeaturesApiUsersControllerAuthenticateWithRefreshRequest {
    /**
     * 
     * @type {UserCredentialsDto}
     * @memberof FeaturesApiUsersControllerAuthenticateWithRefresh
     */
    readonly userCredentialsDto: UserCredentialsDto
}

/**
 * Request parameters for usersControllerConfirm operation in FeaturesApi.
 * @export
 * @interface FeaturesApiUsersControllerConfirmRequest
 */
export interface FeaturesApiUsersControllerConfirmRequest {
    /**
     * 
     * @type {ConfirmRegistrationDto}
     * @memberof FeaturesApiUsersControllerConfirm
     */
    readonly confirmRegistrationDto: ConfirmRegistrationDto
}

/**
 * Request parameters for usersControllerConfirmPassword operation in FeaturesApi.
 * @export
 * @interface FeaturesApiUsersControllerConfirmPasswordRequest
 */
export interface FeaturesApiUsersControllerConfirmPasswordRequest {
    /**
     * 
     * @type {ConfirmPasswordDto}
     * @memberof FeaturesApiUsersControllerConfirmPassword
     */
    readonly confirmPasswordDto: ConfirmPasswordDto
}

/**
 * Request parameters for usersControllerRefresh operation in FeaturesApi.
 * @export
 * @interface FeaturesApiUsersControllerRefreshRequest
 */
export interface FeaturesApiUsersControllerRefreshRequest {
    /**
     * 
     * @type {RefreshTokenDto}
     * @memberof FeaturesApiUsersControllerRefresh
     */
    readonly refreshTokenDto: RefreshTokenDto
}

/**
 * Request parameters for usersControllerRegister operation in FeaturesApi.
 * @export
 * @interface FeaturesApiUsersControllerRegisterRequest
 */
export interface FeaturesApiUsersControllerRegisterRequest {
    /**
     * 
     * @type {RegisterUserDto}
     * @memberof FeaturesApiUsersControllerRegister
     */
    readonly registerUserDto: RegisterUserDto
}

/**
 * Request parameters for usersControllerResendConfirm operation in FeaturesApi.
 * @export
 * @interface FeaturesApiUsersControllerResendConfirmRequest
 */
export interface FeaturesApiUsersControllerResendConfirmRequest {
    /**
     * 
     * @type {ResendConfirmRegistrationDto}
     * @memberof FeaturesApiUsersControllerResendConfirm
     */
    readonly resendConfirmRegistrationDto: ResendConfirmRegistrationDto
}

/**
 * Request parameters for usersControllerResetPassword operation in FeaturesApi.
 * @export
 * @interface FeaturesApiUsersControllerResetPasswordRequest
 */
export interface FeaturesApiUsersControllerResetPasswordRequest {
    /**
     * 
     * @type {ResendConfirmRegistrationDto}
     * @memberof FeaturesApiUsersControllerResetPassword
     */
    readonly resendConfirmRegistrationDto: ResendConfirmRegistrationDto
}

/**
 * Request parameters for usersControllerSetLocationContext operation in FeaturesApi.
 * @export
 * @interface FeaturesApiUsersControllerSetLocationContextRequest
 */
export interface FeaturesApiUsersControllerSetLocationContextRequest {
    /**
     * 
     * @type {SetLocationContextDto}
     * @memberof FeaturesApiUsersControllerSetLocationContext
     */
    readonly setLocationContextDto: SetLocationContextDto
}

/**
 * Request parameters for usersControllerSetVendorContext operation in FeaturesApi.
 * @export
 * @interface FeaturesApiUsersControllerSetVendorContextRequest
 */
export interface FeaturesApiUsersControllerSetVendorContextRequest {
    /**
     * 
     * @type {SetVendorContextDto}
     * @memberof FeaturesApiUsersControllerSetVendorContext
     */
    readonly setVendorContextDto: SetVendorContextDto
}

/**
 * FeaturesApi - object-oriented interface
 * @export
 * @class FeaturesApi
 * @extends {BaseAPI}
 */
export class FeaturesApi extends BaseAPI {
    /**
     * 
     * @param {FeaturesApiAvailabilitiesControllerGetBeachChairAvailabilityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public availabilitiesControllerGetBeachChairAvailability(requestParameters: FeaturesApiAvailabilitiesControllerGetBeachChairAvailabilityRequest, options?: any) {
        return FeaturesApiFp(this.configuration).availabilitiesControllerGetBeachChairAvailability(requestParameters.id, requestParameters.from, requestParameters.to, requestParameters.timeZone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeaturesApiAvailabilitiesControllerGetSectionsAvailabilityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public availabilitiesControllerGetSectionsAvailability(requestParameters: FeaturesApiAvailabilitiesControllerGetSectionsAvailabilityRequest = {}, options?: any) {
        return FeaturesApiFp(this.configuration).availabilitiesControllerGetSectionsAvailability(requestParameters.vendorId, requestParameters.startDate, requestParameters.endDate, requestParameters.date, requestParameters.timeZone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeaturesApiBeachChairsControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public beachChairsControllerCreate(requestParameters: FeaturesApiBeachChairsControllerCreateRequest, options?: any) {
        return FeaturesApiFp(this.configuration).beachChairsControllerCreate(requestParameters.createBeachChairBodyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeaturesApiBeachChairsControllerGetChairsByVendorIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public beachChairsControllerGetChairsByVendorId(requestParameters: FeaturesApiBeachChairsControllerGetChairsByVendorIdRequest = {}, options?: any) {
        return FeaturesApiFp(this.configuration).beachChairsControllerGetChairsByVendorId(requestParameters.filters, requestParameters.page, requestParameters.itemsPerPage, requestParameters.orderBy, requestParameters.orderDir, requestParameters.search, requestParameters.filterBy, requestParameters.filter, requestParameters.csv, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeaturesApiBeachChairsControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public beachChairsControllerUpdate(requestParameters: FeaturesApiBeachChairsControllerUpdateRequest, options?: any) {
        return FeaturesApiFp(this.configuration).beachChairsControllerUpdate(requestParameters.id, requestParameters.updateBeachChairBodyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeaturesApiBeachChairsControllerUpdateBeachChairLockRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public beachChairsControllerUpdateBeachChairLock(requestParameters: FeaturesApiBeachChairsControllerUpdateBeachChairLockRequest, options?: any) {
        return FeaturesApiFp(this.configuration).beachChairsControllerUpdateBeachChairLock(requestParameters.id, requestParameters.beachChairByIdParamDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeaturesApiBookingDetailsControllerAddToBookingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public bookingDetailsControllerAddToBooking(requestParameters: FeaturesApiBookingDetailsControllerAddToBookingRequest, options?: any) {
        return FeaturesApiFp(this.configuration).bookingDetailsControllerAddToBooking(requestParameters.id, requestParameters.createBeachChairBookingBodyDto, requestParameters.timeZone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeaturesApiBookingDetailsControllerCalculateCancellationFeeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public bookingDetailsControllerCalculateCancellationFee(requestParameters: FeaturesApiBookingDetailsControllerCalculateCancellationFeeRequest, options?: any) {
        return FeaturesApiFp(this.configuration).bookingDetailsControllerCalculateCancellationFee(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeaturesApiBookingDetailsControllerCancelBeachChairBookingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public bookingDetailsControllerCancelBeachChairBooking(requestParameters: FeaturesApiBookingDetailsControllerCancelBeachChairBookingRequest, options?: any) {
        return FeaturesApiFp(this.configuration).bookingDetailsControllerCancelBeachChairBooking(requestParameters.id, requestParameters.beachChairBookingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeaturesApiBookingDetailsControllerCancelBookingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public bookingDetailsControllerCancelBooking(requestParameters: FeaturesApiBookingDetailsControllerCancelBookingRequest, options?: any) {
        return FeaturesApiFp(this.configuration).bookingDetailsControllerCancelBooking(requestParameters.id, requestParameters.timeZone, requestParameters.cancellationFee, requestParameters.skipRefund, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeaturesApiBookingDetailsControllerConfirmBookingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public bookingDetailsControllerConfirmBooking(requestParameters: FeaturesApiBookingDetailsControllerConfirmBookingRequest, options?: any) {
        return FeaturesApiFp(this.configuration).bookingDetailsControllerConfirmBooking(requestParameters.id, requestParameters.timeZone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeaturesApiBookingDetailsControllerCreateShortInvoiceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public bookingDetailsControllerCreateShortInvoice(requestParameters: FeaturesApiBookingDetailsControllerCreateShortInvoiceRequest, options?: any) {
        return FeaturesApiFp(this.configuration).bookingDetailsControllerCreateShortInvoice(requestParameters.id, requestParameters.timeZone, requestParameters.buffer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeaturesApiBookingDetailsControllerFindManyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public bookingDetailsControllerFindMany(requestParameters: FeaturesApiBookingDetailsControllerFindManyRequest = {}, options?: any) {
        return FeaturesApiFp(this.configuration).bookingDetailsControllerFindMany(requestParameters.filters, requestParameters.page, requestParameters.itemsPerPage, requestParameters.orderBy, requestParameters.orderDir, requestParameters.search, requestParameters.filterBy, requestParameters.filter, requestParameters.csv, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeaturesApiBookingDetailsControllerFindManyBeachChairBookingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public bookingDetailsControllerFindManyBeachChairBookings(requestParameters: FeaturesApiBookingDetailsControllerFindManyBeachChairBookingsRequest = {}, options?: any) {
        return FeaturesApiFp(this.configuration).bookingDetailsControllerFindManyBeachChairBookings(requestParameters.filters, requestParameters.page, requestParameters.itemsPerPage, requestParameters.orderBy, requestParameters.orderDir, requestParameters.csv, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeaturesApiBookingDetailsControllerFindManyByCustomerIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public bookingDetailsControllerFindManyByCustomerId(requestParameters: FeaturesApiBookingDetailsControllerFindManyByCustomerIdRequest = {}, options?: any) {
        return FeaturesApiFp(this.configuration).bookingDetailsControllerFindManyByCustomerId(requestParameters.filters, requestParameters.page, requestParameters.itemsPerPage, requestParameters.orderBy, requestParameters.orderDir, requestParameters.search, requestParameters.filterBy, requestParameters.filter, requestParameters.csv, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeaturesApiBookingDetailsControllerFindManyReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public bookingDetailsControllerFindManyReport(requestParameters: FeaturesApiBookingDetailsControllerFindManyReportRequest = {}, options?: any) {
        return FeaturesApiFp(this.configuration).bookingDetailsControllerFindManyReport(requestParameters.filters, requestParameters.page, requestParameters.itemsPerPage, requestParameters.orderBy, requestParameters.orderDir, requestParameters.csv, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeaturesApiBookingDetailsControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public bookingDetailsControllerFindOne(requestParameters: FeaturesApiBookingDetailsControllerFindOneRequest, options?: any) {
        return FeaturesApiFp(this.configuration).bookingDetailsControllerFindOne(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeaturesApiBookingDetailsControllerGetFileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public bookingDetailsControllerGetFile(requestParameters: FeaturesApiBookingDetailsControllerGetFileRequest, options?: any) {
        return FeaturesApiFp(this.configuration).bookingDetailsControllerGetFile(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeaturesApiBookingDetailsControllerGetInvoiceDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public bookingDetailsControllerGetInvoiceData(requestParameters: FeaturesApiBookingDetailsControllerGetInvoiceDataRequest, options?: any) {
        return FeaturesApiFp(this.configuration).bookingDetailsControllerGetInvoiceData(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeaturesApiBookingDetailsControllerPayForBookingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public bookingDetailsControllerPayForBooking(requestParameters: FeaturesApiBookingDetailsControllerPayForBookingRequest, options?: any) {
        return FeaturesApiFp(this.configuration).bookingDetailsControllerPayForBooking(requestParameters.id, requestParameters.timeZone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeaturesApiBookingDetailsControllerRecalculationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public bookingDetailsControllerRecalculation(requestParameters: FeaturesApiBookingDetailsControllerRecalculationRequest, options?: any) {
        return FeaturesApiFp(this.configuration).bookingDetailsControllerRecalculation(requestParameters.id, requestParameters.onlineBooking, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeaturesApiBookingDetailsControllerRecoverBookingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public bookingDetailsControllerRecoverBooking(requestParameters: FeaturesApiBookingDetailsControllerRecoverBookingRequest, options?: any) {
        return FeaturesApiFp(this.configuration).bookingDetailsControllerRecoverBooking(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Use this endpoint to regenerate an Invoice. This will generate a new Invoicenumber.
     * @param {FeaturesApiBookingDetailsControllerRegenerateInvoiceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public bookingDetailsControllerRegenerateInvoice(requestParameters: FeaturesApiBookingDetailsControllerRegenerateInvoiceRequest, options?: any) {
        return FeaturesApiFp(this.configuration).bookingDetailsControllerRegenerateInvoice(requestParameters.id, requestParameters.invoiceNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeaturesApiBookingDetailsControllerResendBankTransferRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public bookingDetailsControllerResendBankTransfer(requestParameters: FeaturesApiBookingDetailsControllerResendBankTransferRequest, options?: any) {
        return FeaturesApiFp(this.configuration).bookingDetailsControllerResendBankTransfer(requestParameters.id, requestParameters.timeZone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeaturesApiBookingDetailsControllerResendPaymentLinkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public bookingDetailsControllerResendPaymentLink(requestParameters: FeaturesApiBookingDetailsControllerResendPaymentLinkRequest, options?: any) {
        return FeaturesApiFp(this.configuration).bookingDetailsControllerResendPaymentLink(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeaturesApiBookingDetailsControllerReserveBookingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public bookingDetailsControllerReserveBooking(requestParameters: FeaturesApiBookingDetailsControllerReserveBookingRequest, options?: any) {
        return FeaturesApiFp(this.configuration).bookingDetailsControllerReserveBooking(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeaturesApiBookingDetailsControllerUpdateBeachChairBookingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public bookingDetailsControllerUpdateBeachChairBooking(requestParameters: FeaturesApiBookingDetailsControllerUpdateBeachChairBookingRequest, options?: any) {
        return FeaturesApiFp(this.configuration).bookingDetailsControllerUpdateBeachChairBooking(requestParameters.id, requestParameters.updateBeachChairBookingBodyDto, requestParameters.timeZone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeaturesApiDocumentsControllerFindManyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public documentsControllerFindMany(requestParameters: FeaturesApiDocumentsControllerFindManyRequest = {}, options?: any) {
        return FeaturesApiFp(this.configuration).documentsControllerFindMany(requestParameters.filters, requestParameters.page, requestParameters.itemsPerPage, requestParameters.orderBy, requestParameters.orderDir, requestParameters.search, requestParameters.filterBy, requestParameters.filter, requestParameters.csv, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeaturesApiDocumentsControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public documentsControllerFindOne(requestParameters: FeaturesApiDocumentsControllerFindOneRequest, options?: any) {
        return FeaturesApiFp(this.configuration).documentsControllerFindOne(requestParameters.s3Key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeaturesApiFeaturesRowControllerDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public featuresRowControllerDelete(requestParameters: FeaturesApiFeaturesRowControllerDeleteRequest, options?: any) {
        return FeaturesApiFp(this.configuration).featuresRowControllerDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeaturesApiFilesControllerDeleteFileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public filesControllerDeleteFile(requestParameters: FeaturesApiFilesControllerDeleteFileRequest, options?: any) {
        return FeaturesApiFp(this.configuration).filesControllerDeleteFile(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeaturesApiFilesControllerUploadFileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public filesControllerUploadFile(requestParameters: FeaturesApiFilesControllerUploadFileRequest = {}, options?: any) {
        return FeaturesApiFp(this.configuration).filesControllerUploadFile(requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeaturesApiFiltersControllerGetChairsFiltersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public filtersControllerGetChairsFilters(requestParameters: FeaturesApiFiltersControllerGetChairsFiltersRequest = {}, options?: any) {
        return FeaturesApiFp(this.configuration).filtersControllerGetChairsFilters(requestParameters.vendorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeaturesApiImagesControllerBeachByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public imagesControllerBeachById(requestParameters: FeaturesApiImagesControllerBeachByIdRequest, options?: any) {
        return FeaturesApiFp(this.configuration).imagesControllerBeachById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeaturesApiImagesControllerUploadImageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public imagesControllerUploadImage(requestParameters: FeaturesApiImagesControllerUploadImageRequest = {}, options?: any) {
        return FeaturesApiFp(this.configuration).imagesControllerUploadImage(requestParameters.width, requestParameters.height, requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeaturesApiLocationsControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public locationsControllerCreate(requestParameters: FeaturesApiLocationsControllerCreateRequest, options?: any) {
        return FeaturesApiFp(this.configuration).locationsControllerCreate(requestParameters.createLocationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeaturesApiLocationsControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public locationsControllerUpdate(requestParameters: FeaturesApiLocationsControllerUpdateRequest, options?: any) {
        return FeaturesApiFp(this.configuration).locationsControllerUpdate(requestParameters.id, requestParameters.updateLocationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeaturesApiSectionsControllerFindManyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public sectionsControllerFindMany(requestParameters: FeaturesApiSectionsControllerFindManyRequest = {}, options?: any) {
        return FeaturesApiFp(this.configuration).sectionsControllerFindMany(requestParameters.page, requestParameters.itemsPerPage, requestParameters.orderBy, requestParameters.orderDir, requestParameters.search, requestParameters.filterBy, requestParameters.filter, requestParameters.filters, requestParameters.csv, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeaturesApiSectionsControllerGetChairsBySectionIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public sectionsControllerGetChairsBySectionId(requestParameters: FeaturesApiSectionsControllerGetChairsBySectionIdRequest, options?: any) {
        return FeaturesApiFp(this.configuration).sectionsControllerGetChairsBySectionId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeaturesApiSectionsControllerGetSectionsBeachChairsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public sectionsControllerGetSectionsBeachChairs(requestParameters: FeaturesApiSectionsControllerGetSectionsBeachChairsRequest, options?: any) {
        return FeaturesApiFp(this.configuration).sectionsControllerGetSectionsBeachChairs(requestParameters.id, requestParameters.filters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeaturesApiSectionsControllerGetSectionsBeachChairsBookingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public sectionsControllerGetSectionsBeachChairsBookings(requestParameters: FeaturesApiSectionsControllerGetSectionsBeachChairsBookingsRequest, options?: any) {
        return FeaturesApiFp(this.configuration).sectionsControllerGetSectionsBeachChairsBookings(requestParameters.id, requestParameters.filters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeaturesApiUsersControllerAuthenticateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public usersControllerAuthenticate(requestParameters: FeaturesApiUsersControllerAuthenticateRequest, options?: any) {
        return FeaturesApiFp(this.configuration).usersControllerAuthenticate(requestParameters.userCredentialsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeaturesApiUsersControllerAuthenticateWithRefreshRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public usersControllerAuthenticateWithRefresh(requestParameters: FeaturesApiUsersControllerAuthenticateWithRefreshRequest, options?: any) {
        return FeaturesApiFp(this.configuration).usersControllerAuthenticateWithRefresh(requestParameters.userCredentialsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeaturesApiUsersControllerConfirmRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public usersControllerConfirm(requestParameters: FeaturesApiUsersControllerConfirmRequest, options?: any) {
        return FeaturesApiFp(this.configuration).usersControllerConfirm(requestParameters.confirmRegistrationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeaturesApiUsersControllerConfirmPasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public usersControllerConfirmPassword(requestParameters: FeaturesApiUsersControllerConfirmPasswordRequest, options?: any) {
        return FeaturesApiFp(this.configuration).usersControllerConfirmPassword(requestParameters.confirmPasswordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public usersControllerGetAuthToken(options?: any) {
        return FeaturesApiFp(this.configuration).usersControllerGetAuthToken(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeaturesApiUsersControllerRefreshRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public usersControllerRefresh(requestParameters: FeaturesApiUsersControllerRefreshRequest, options?: any) {
        return FeaturesApiFp(this.configuration).usersControllerRefresh(requestParameters.refreshTokenDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeaturesApiUsersControllerRegisterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public usersControllerRegister(requestParameters: FeaturesApiUsersControllerRegisterRequest, options?: any) {
        return FeaturesApiFp(this.configuration).usersControllerRegister(requestParameters.registerUserDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeaturesApiUsersControllerResendConfirmRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public usersControllerResendConfirm(requestParameters: FeaturesApiUsersControllerResendConfirmRequest, options?: any) {
        return FeaturesApiFp(this.configuration).usersControllerResendConfirm(requestParameters.resendConfirmRegistrationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeaturesApiUsersControllerResetPasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public usersControllerResetPassword(requestParameters: FeaturesApiUsersControllerResetPasswordRequest, options?: any) {
        return FeaturesApiFp(this.configuration).usersControllerResetPassword(requestParameters.resendConfirmRegistrationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeaturesApiUsersControllerSetLocationContextRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public usersControllerSetLocationContext(requestParameters: FeaturesApiUsersControllerSetLocationContextRequest, options?: any) {
        return FeaturesApiFp(this.configuration).usersControllerSetLocationContext(requestParameters.setLocationContextDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FeaturesApiUsersControllerSetVendorContextRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeaturesApi
     */
    public usersControllerSetVendorContext(requestParameters: FeaturesApiUsersControllerSetVendorContextRequest, options?: any) {
        return FeaturesApiFp(this.configuration).usersControllerSetVendorContext(requestParameters.setVendorContextDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GeneratorApi - axios parameter creator
 * @export
 */
export const GeneratorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} s3Key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerDelete: async (s3Key: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 's3Key' is not null or undefined
            assertParamExists('documentsControllerDelete', 's3Key', s3Key)
            const localVarPath = `/api/v1/documents/{s3Key}`
                .replace(`{${"s3Key"}}`, encodeURIComponent(String(s3Key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} subject 
         * @param {string} templateName 
         * @param {GenerateEmailDto} generateEmailDto 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailsControllerGeneratePDF: async (subject: string, templateName: string, generateEmailDto: GenerateEmailDto, lang?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'subject' is not null or undefined
            assertParamExists('emailsControllerGeneratePDF', 'subject', subject)
            // verify required parameter 'templateName' is not null or undefined
            assertParamExists('emailsControllerGeneratePDF', 'templateName', templateName)
            // verify required parameter 'generateEmailDto' is not null or undefined
            assertParamExists('emailsControllerGeneratePDF', 'generateEmailDto', generateEmailDto)
            const localVarPath = `/api/v1/emails/render_email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (subject !== undefined) {
                localVarQueryParameter['subject'] = subject;
            }

            if (templateName !== undefined) {
                localVarQueryParameter['templateName'] = templateName;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generateEmailDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} subject 
         * @param {string} templateName 
         * @param {string} to 
         * @param {GenerateEmailDto} generateEmailDto 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailsControllerSendEmail: async (subject: string, templateName: string, to: string, generateEmailDto: GenerateEmailDto, lang?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'subject' is not null or undefined
            assertParamExists('emailsControllerSendEmail', 'subject', subject)
            // verify required parameter 'templateName' is not null or undefined
            assertParamExists('emailsControllerSendEmail', 'templateName', templateName)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('emailsControllerSendEmail', 'to', to)
            // verify required parameter 'generateEmailDto' is not null or undefined
            assertParamExists('emailsControllerSendEmail', 'generateEmailDto', generateEmailDto)
            const localVarPath = `/api/v1/emails/send_email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (subject !== undefined) {
                localVarQueryParameter['subject'] = subject;
            }

            if (templateName !== undefined) {
                localVarQueryParameter['templateName'] = templateName;
            }

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generateEmailDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InvoicesCreateDto} invoicesCreateDto 
         * @param {string} [timeZone] 
         * @param {string} [buffer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesControllerGeneratePDF: async (invoicesCreateDto: InvoicesCreateDto, timeZone?: string, buffer?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoicesCreateDto' is not null or undefined
            assertParamExists('invoicesControllerGeneratePDF', 'invoicesCreateDto', invoicesCreateDto)
            const localVarPath = `/api/v1/invoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (timeZone !== undefined) {
                localVarQueryParameter['timeZone'] = timeZone;
            }

            if (buffer !== undefined) {
                localVarQueryParameter['buffer'] = buffer;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(invoicesCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} vendorId 
         * @param {string} invoiceNumber 
         * @param {number} bookingId 
         * @param {ReceiptType} receiptType INVOICE or CANCELLATION
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesControllerGetInvoicePDF: async (vendorId: number, invoiceNumber: string, bookingId: number, receiptType: ReceiptType, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'vendorId' is not null or undefined
            assertParamExists('invoicesControllerGetInvoicePDF', 'vendorId', vendorId)
            // verify required parameter 'invoiceNumber' is not null or undefined
            assertParamExists('invoicesControllerGetInvoicePDF', 'invoiceNumber', invoiceNumber)
            // verify required parameter 'bookingId' is not null or undefined
            assertParamExists('invoicesControllerGetInvoicePDF', 'bookingId', bookingId)
            // verify required parameter 'receiptType' is not null or undefined
            assertParamExists('invoicesControllerGetInvoicePDF', 'receiptType', receiptType)
            const localVarPath = `/api/v1/invoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (vendorId !== undefined) {
                localVarQueryParameter['vendorId'] = vendorId;
            }

            if (invoiceNumber !== undefined) {
                localVarQueryParameter['invoiceNumber'] = invoiceNumber;
            }

            if (bookingId !== undefined) {
                localVarQueryParameter['bookingId'] = bookingId;
            }

            if (receiptType !== undefined) {
                localVarQueryParameter['receiptType'] = receiptType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GeneratorApi - functional programming interface
 * @export
 */
export const GeneratorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GeneratorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} s3Key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentsControllerDelete(s3Key: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentsControllerDelete(s3Key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} subject 
         * @param {string} templateName 
         * @param {GenerateEmailDto} generateEmailDto 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailsControllerGeneratePDF(subject: string, templateName: string, generateEmailDto: GenerateEmailDto, lang?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailsControllerGeneratePDF(subject, templateName, generateEmailDto, lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} subject 
         * @param {string} templateName 
         * @param {string} to 
         * @param {GenerateEmailDto} generateEmailDto 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailsControllerSendEmail(subject: string, templateName: string, to: string, generateEmailDto: GenerateEmailDto, lang?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailsControllerSendEmail(subject, templateName, to, generateEmailDto, lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InvoicesCreateDto} invoicesCreateDto 
         * @param {string} [timeZone] 
         * @param {string} [buffer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invoicesControllerGeneratePDF(invoicesCreateDto: InvoicesCreateDto, timeZone?: string, buffer?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoicesResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invoicesControllerGeneratePDF(invoicesCreateDto, timeZone, buffer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} vendorId 
         * @param {string} invoiceNumber 
         * @param {number} bookingId 
         * @param {ReceiptType} receiptType INVOICE or CANCELLATION
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invoicesControllerGetInvoicePDF(vendorId: number, invoiceNumber: string, bookingId: number, receiptType: ReceiptType, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoicesResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invoicesControllerGetInvoicePDF(vendorId, invoiceNumber, bookingId, receiptType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GeneratorApi - factory interface
 * @export
 */
export const GeneratorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GeneratorApiFp(configuration)
    return {
        /**
         * 
         * @param {string} s3Key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerDelete(s3Key: string, options?: any): AxiosPromise<void> {
            return localVarFp.documentsControllerDelete(s3Key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} subject 
         * @param {string} templateName 
         * @param {GenerateEmailDto} generateEmailDto 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailsControllerGeneratePDF(subject: string, templateName: string, generateEmailDto: GenerateEmailDto, lang?: string, options?: any): AxiosPromise<void> {
            return localVarFp.emailsControllerGeneratePDF(subject, templateName, generateEmailDto, lang, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} subject 
         * @param {string} templateName 
         * @param {string} to 
         * @param {GenerateEmailDto} generateEmailDto 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailsControllerSendEmail(subject: string, templateName: string, to: string, generateEmailDto: GenerateEmailDto, lang?: string, options?: any): AxiosPromise<void> {
            return localVarFp.emailsControllerSendEmail(subject, templateName, to, generateEmailDto, lang, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InvoicesCreateDto} invoicesCreateDto 
         * @param {string} [timeZone] 
         * @param {string} [buffer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesControllerGeneratePDF(invoicesCreateDto: InvoicesCreateDto, timeZone?: string, buffer?: string, options?: any): AxiosPromise<InvoicesResponseDto> {
            return localVarFp.invoicesControllerGeneratePDF(invoicesCreateDto, timeZone, buffer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} vendorId 
         * @param {string} invoiceNumber 
         * @param {number} bookingId 
         * @param {ReceiptType} receiptType INVOICE or CANCELLATION
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invoicesControllerGetInvoicePDF(vendorId: number, invoiceNumber: string, bookingId: number, receiptType: ReceiptType, options?: any): AxiosPromise<InvoicesResponseDto> {
            return localVarFp.invoicesControllerGetInvoicePDF(vendorId, invoiceNumber, bookingId, receiptType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for documentsControllerDelete operation in GeneratorApi.
 * @export
 * @interface GeneratorApiDocumentsControllerDeleteRequest
 */
export interface GeneratorApiDocumentsControllerDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof GeneratorApiDocumentsControllerDelete
     */
    readonly s3Key: string
}

/**
 * Request parameters for emailsControllerGeneratePDF operation in GeneratorApi.
 * @export
 * @interface GeneratorApiEmailsControllerGeneratePDFRequest
 */
export interface GeneratorApiEmailsControllerGeneratePDFRequest {
    /**
     * 
     * @type {string}
     * @memberof GeneratorApiEmailsControllerGeneratePDF
     */
    readonly subject: string

    /**
     * 
     * @type {string}
     * @memberof GeneratorApiEmailsControllerGeneratePDF
     */
    readonly templateName: string

    /**
     * 
     * @type {GenerateEmailDto}
     * @memberof GeneratorApiEmailsControllerGeneratePDF
     */
    readonly generateEmailDto: GenerateEmailDto

    /**
     * 
     * @type {string}
     * @memberof GeneratorApiEmailsControllerGeneratePDF
     */
    readonly lang?: string
}

/**
 * Request parameters for emailsControllerSendEmail operation in GeneratorApi.
 * @export
 * @interface GeneratorApiEmailsControllerSendEmailRequest
 */
export interface GeneratorApiEmailsControllerSendEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof GeneratorApiEmailsControllerSendEmail
     */
    readonly subject: string

    /**
     * 
     * @type {string}
     * @memberof GeneratorApiEmailsControllerSendEmail
     */
    readonly templateName: string

    /**
     * 
     * @type {string}
     * @memberof GeneratorApiEmailsControllerSendEmail
     */
    readonly to: string

    /**
     * 
     * @type {GenerateEmailDto}
     * @memberof GeneratorApiEmailsControllerSendEmail
     */
    readonly generateEmailDto: GenerateEmailDto

    /**
     * 
     * @type {string}
     * @memberof GeneratorApiEmailsControllerSendEmail
     */
    readonly lang?: string
}

/**
 * Request parameters for invoicesControllerGeneratePDF operation in GeneratorApi.
 * @export
 * @interface GeneratorApiInvoicesControllerGeneratePDFRequest
 */
export interface GeneratorApiInvoicesControllerGeneratePDFRequest {
    /**
     * 
     * @type {InvoicesCreateDto}
     * @memberof GeneratorApiInvoicesControllerGeneratePDF
     */
    readonly invoicesCreateDto: InvoicesCreateDto

    /**
     * 
     * @type {string}
     * @memberof GeneratorApiInvoicesControllerGeneratePDF
     */
    readonly timeZone?: string

    /**
     * 
     * @type {string}
     * @memberof GeneratorApiInvoicesControllerGeneratePDF
     */
    readonly buffer?: string
}

/**
 * Request parameters for invoicesControllerGetInvoicePDF operation in GeneratorApi.
 * @export
 * @interface GeneratorApiInvoicesControllerGetInvoicePDFRequest
 */
export interface GeneratorApiInvoicesControllerGetInvoicePDFRequest {
    /**
     * 
     * @type {number}
     * @memberof GeneratorApiInvoicesControllerGetInvoicePDF
     */
    readonly vendorId: number

    /**
     * 
     * @type {string}
     * @memberof GeneratorApiInvoicesControllerGetInvoicePDF
     */
    readonly invoiceNumber: string

    /**
     * 
     * @type {number}
     * @memberof GeneratorApiInvoicesControllerGetInvoicePDF
     */
    readonly bookingId: number

    /**
     * INVOICE or CANCELLATION
     * @type {ReceiptType}
     * @memberof GeneratorApiInvoicesControllerGetInvoicePDF
     */
    readonly receiptType: ReceiptType
}

/**
 * GeneratorApi - object-oriented interface
 * @export
 * @class GeneratorApi
 * @extends {BaseAPI}
 */
export class GeneratorApi extends BaseAPI {
    /**
     * 
     * @param {GeneratorApiDocumentsControllerDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneratorApi
     */
    public documentsControllerDelete(requestParameters: GeneratorApiDocumentsControllerDeleteRequest, options?: any) {
        return GeneratorApiFp(this.configuration).documentsControllerDelete(requestParameters.s3Key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GeneratorApiEmailsControllerGeneratePDFRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneratorApi
     */
    public emailsControllerGeneratePDF(requestParameters: GeneratorApiEmailsControllerGeneratePDFRequest, options?: any) {
        return GeneratorApiFp(this.configuration).emailsControllerGeneratePDF(requestParameters.subject, requestParameters.templateName, requestParameters.generateEmailDto, requestParameters.lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GeneratorApiEmailsControllerSendEmailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneratorApi
     */
    public emailsControllerSendEmail(requestParameters: GeneratorApiEmailsControllerSendEmailRequest, options?: any) {
        return GeneratorApiFp(this.configuration).emailsControllerSendEmail(requestParameters.subject, requestParameters.templateName, requestParameters.to, requestParameters.generateEmailDto, requestParameters.lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GeneratorApiInvoicesControllerGeneratePDFRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneratorApi
     */
    public invoicesControllerGeneratePDF(requestParameters: GeneratorApiInvoicesControllerGeneratePDFRequest, options?: any) {
        return GeneratorApiFp(this.configuration).invoicesControllerGeneratePDF(requestParameters.invoicesCreateDto, requestParameters.timeZone, requestParameters.buffer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GeneratorApiInvoicesControllerGetInvoicePDFRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneratorApi
     */
    public invoicesControllerGetInvoicePDF(requestParameters: GeneratorApiInvoicesControllerGetInvoicePDFRequest, options?: any) {
        return GeneratorApiFp(this.configuration).invoicesControllerGetInvoicePDF(requestParameters.vendorId, requestParameters.invoiceNumber, requestParameters.bookingId, requestParameters.receiptType, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GeneratorsApi - axios parameter creator
 * @export
 */
export const GeneratorsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {RedirectCreateBodyDto} redirectCreateBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redirectsControllerCreate: async (redirectCreateBodyDto: RedirectCreateBodyDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'redirectCreateBodyDto' is not null or undefined
            assertParamExists('redirectsControllerCreate', 'redirectCreateBodyDto', redirectCreateBodyDto)
            const localVarPath = `/api/v1/generators/redirects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(redirectCreateBodyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redirectsControllerDelete: async (uid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('redirectsControllerDelete', 'uid', uid)
            const localVarPath = `/api/v1/generators/redirects/{uid}`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {GetRedirectsFiltersDto} [filters] 
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redirectsControllerFindMany: async (page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, filters?: GetRedirectsFiltersDto, csv?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/generators/redirects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['orderDir'] = orderDir;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (filterBy !== undefined) {
                localVarQueryParameter['filterBy'] = filterBy;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (filters !== undefined) {
                localVarQueryParameter['filters'] = filters;
            }

            if (csv !== undefined) {
                localVarQueryParameter['csv'] = csv;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redirectsControllerFindOne: async (uid: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('redirectsControllerFindOne', 'uid', uid)
            const localVarPath = `/api/v1/generators/redirects/{uid}`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uid 
         * @param {RedirectUpdateBodyDto} redirectUpdateBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redirectsControllerUpdate: async (uid: string, redirectUpdateBodyDto: RedirectUpdateBodyDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('redirectsControllerUpdate', 'uid', uid)
            // verify required parameter 'redirectUpdateBodyDto' is not null or undefined
            assertParamExists('redirectsControllerUpdate', 'redirectUpdateBodyDto', redirectUpdateBodyDto)
            const localVarPath = `/api/v1/generators/redirects/{uid}`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(redirectUpdateBodyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GeneratorsApi - functional programming interface
 * @export
 */
export const GeneratorsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GeneratorsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {RedirectCreateBodyDto} redirectCreateBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async redirectsControllerCreate(redirectCreateBodyDto: RedirectCreateBodyDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RedirectCreateResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.redirectsControllerCreate(redirectCreateBodyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async redirectsControllerDelete(uid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.redirectsControllerDelete(uid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {GetRedirectsFiltersDto} [filters] 
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async redirectsControllerFindMany(page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, filters?: GetRedirectsFiltersDto, csv?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RedirectsFindAllChunkResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.redirectsControllerFindMany(page, itemsPerPage, orderBy, orderDir, search, filterBy, filter, filters, csv, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async redirectsControllerFindOne(uid: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.redirectsControllerFindOne(uid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} uid 
         * @param {RedirectUpdateBodyDto} redirectUpdateBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async redirectsControllerUpdate(uid: string, redirectUpdateBodyDto: RedirectUpdateBodyDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RedirectsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.redirectsControllerUpdate(uid, redirectUpdateBodyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GeneratorsApi - factory interface
 * @export
 */
export const GeneratorsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GeneratorsApiFp(configuration)
    return {
        /**
         * 
         * @param {RedirectCreateBodyDto} redirectCreateBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redirectsControllerCreate(redirectCreateBodyDto: RedirectCreateBodyDto, options?: any): AxiosPromise<RedirectCreateResponseDto> {
            return localVarFp.redirectsControllerCreate(redirectCreateBodyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redirectsControllerDelete(uid: string, options?: any): AxiosPromise<void> {
            return localVarFp.redirectsControllerDelete(uid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {GetRedirectsFiltersDto} [filters] 
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redirectsControllerFindMany(page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, filters?: GetRedirectsFiltersDto, csv?: string, options?: any): AxiosPromise<RedirectsFindAllChunkResponseDto> {
            return localVarFp.redirectsControllerFindMany(page, itemsPerPage, orderBy, orderDir, search, filterBy, filter, filters, csv, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redirectsControllerFindOne(uid: string, options?: any): AxiosPromise<void> {
            return localVarFp.redirectsControllerFindOne(uid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} uid 
         * @param {RedirectUpdateBodyDto} redirectUpdateBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redirectsControllerUpdate(uid: string, redirectUpdateBodyDto: RedirectUpdateBodyDto, options?: any): AxiosPromise<RedirectsDto> {
            return localVarFp.redirectsControllerUpdate(uid, redirectUpdateBodyDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for redirectsControllerCreate operation in GeneratorsApi.
 * @export
 * @interface GeneratorsApiRedirectsControllerCreateRequest
 */
export interface GeneratorsApiRedirectsControllerCreateRequest {
    /**
     * 
     * @type {RedirectCreateBodyDto}
     * @memberof GeneratorsApiRedirectsControllerCreate
     */
    readonly redirectCreateBodyDto: RedirectCreateBodyDto
}

/**
 * Request parameters for redirectsControllerDelete operation in GeneratorsApi.
 * @export
 * @interface GeneratorsApiRedirectsControllerDeleteRequest
 */
export interface GeneratorsApiRedirectsControllerDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof GeneratorsApiRedirectsControllerDelete
     */
    readonly uid: string
}

/**
 * Request parameters for redirectsControllerFindMany operation in GeneratorsApi.
 * @export
 * @interface GeneratorsApiRedirectsControllerFindManyRequest
 */
export interface GeneratorsApiRedirectsControllerFindManyRequest {
    /**
     * Number of the page to fetch
     * @type {number}
     * @memberof GeneratorsApiRedirectsControllerFindMany
     */
    readonly page?: number

    /**
     * Number of items per page
     * @type {number}
     * @memberof GeneratorsApiRedirectsControllerFindMany
     */
    readonly itemsPerPage?: number

    /**
     * Name of the property to order by
     * @type {string}
     * @memberof GeneratorsApiRedirectsControllerFindMany
     */
    readonly orderBy?: string

    /**
     * Value which specify direction of the order
     * @type {OrderByDirection}
     * @memberof GeneratorsApiRedirectsControllerFindMany
     */
    readonly orderDir?: OrderByDirection

    /**
     * Text which will be used to search through table data
     * @type {string}
     * @memberof GeneratorsApiRedirectsControllerFindMany
     */
    readonly search?: string

    /**
     * Name of the property to filter by
     * @type {string}
     * @memberof GeneratorsApiRedirectsControllerFindMany
     */
    readonly filterBy?: string

    /**
     * Value which will be used to filter table data
     * @type {string}
     * @memberof GeneratorsApiRedirectsControllerFindMany
     */
    readonly filter?: string

    /**
     * 
     * @type {GetRedirectsFiltersDto}
     * @memberof GeneratorsApiRedirectsControllerFindMany
     */
    readonly filters?: GetRedirectsFiltersDto

    /**
     * 
     * @type {string}
     * @memberof GeneratorsApiRedirectsControllerFindMany
     */
    readonly csv?: string
}

/**
 * Request parameters for redirectsControllerFindOne operation in GeneratorsApi.
 * @export
 * @interface GeneratorsApiRedirectsControllerFindOneRequest
 */
export interface GeneratorsApiRedirectsControllerFindOneRequest {
    /**
     * 
     * @type {string}
     * @memberof GeneratorsApiRedirectsControllerFindOne
     */
    readonly uid: string
}

/**
 * Request parameters for redirectsControllerUpdate operation in GeneratorsApi.
 * @export
 * @interface GeneratorsApiRedirectsControllerUpdateRequest
 */
export interface GeneratorsApiRedirectsControllerUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof GeneratorsApiRedirectsControllerUpdate
     */
    readonly uid: string

    /**
     * 
     * @type {RedirectUpdateBodyDto}
     * @memberof GeneratorsApiRedirectsControllerUpdate
     */
    readonly redirectUpdateBodyDto: RedirectUpdateBodyDto
}

/**
 * GeneratorsApi - object-oriented interface
 * @export
 * @class GeneratorsApi
 * @extends {BaseAPI}
 */
export class GeneratorsApi extends BaseAPI {
    /**
     * 
     * @param {GeneratorsApiRedirectsControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneratorsApi
     */
    public redirectsControllerCreate(requestParameters: GeneratorsApiRedirectsControllerCreateRequest, options?: any) {
        return GeneratorsApiFp(this.configuration).redirectsControllerCreate(requestParameters.redirectCreateBodyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GeneratorsApiRedirectsControllerDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneratorsApi
     */
    public redirectsControllerDelete(requestParameters: GeneratorsApiRedirectsControllerDeleteRequest, options?: any) {
        return GeneratorsApiFp(this.configuration).redirectsControllerDelete(requestParameters.uid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GeneratorsApiRedirectsControllerFindManyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneratorsApi
     */
    public redirectsControllerFindMany(requestParameters: GeneratorsApiRedirectsControllerFindManyRequest = {}, options?: any) {
        return GeneratorsApiFp(this.configuration).redirectsControllerFindMany(requestParameters.page, requestParameters.itemsPerPage, requestParameters.orderBy, requestParameters.orderDir, requestParameters.search, requestParameters.filterBy, requestParameters.filter, requestParameters.filters, requestParameters.csv, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GeneratorsApiRedirectsControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneratorsApi
     */
    public redirectsControllerFindOne(requestParameters: GeneratorsApiRedirectsControllerFindOneRequest, options?: any) {
        return GeneratorsApiFp(this.configuration).redirectsControllerFindOne(requestParameters.uid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GeneratorsApiRedirectsControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneratorsApi
     */
    public redirectsControllerUpdate(requestParameters: GeneratorsApiRedirectsControllerUpdateRequest, options?: any) {
        return GeneratorsApiFp(this.configuration).redirectsControllerUpdate(requestParameters.uid, requestParameters.redirectUpdateBodyDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ItemsApi - axios parameter creator
 * @export
 */
export const ItemsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateItemsDto} createItemsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemsControllerCreate: async (createItemsDto: CreateItemsDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createItemsDto' is not null or undefined
            assertParamExists('itemsControllerCreate', 'createItemsDto', createItemsDto)
            const localVarPath = `/api/v1/food/items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createItemsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemsControllerDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('itemsControllerDelete', 'id', id)
            const localVarPath = `/api/v1/food/items/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {ItemsFiltersDto} [filters] 
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemsControllerFindMany: async (page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, filters?: ItemsFiltersDto, csv?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/food/items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['orderDir'] = orderDir;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (filterBy !== undefined) {
                localVarQueryParameter['filterBy'] = filterBy;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (filters !== undefined) {
                localVarQueryParameter['filters'] = filters;
            }

            if (csv !== undefined) {
                localVarQueryParameter['csv'] = csv;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemsControllerFindOne: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('itemsControllerFindOne', 'id', id)
            const localVarPath = `/api/v1/food/items/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateItemsDto} updateItemsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemsControllerUpdate: async (id: number, updateItemsDto: UpdateItemsDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('itemsControllerUpdate', 'id', id)
            // verify required parameter 'updateItemsDto' is not null or undefined
            assertParamExists('itemsControllerUpdate', 'updateItemsDto', updateItemsDto)
            const localVarPath = `/api/v1/food/items/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateItemsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ItemsApi - functional programming interface
 * @export
 */
export const ItemsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ItemsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateItemsDto} createItemsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async itemsControllerCreate(createItemsDto: CreateItemsDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseItemsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.itemsControllerCreate(createItemsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async itemsControllerDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.itemsControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {ItemsFiltersDto} [filters] 
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async itemsControllerFindMany(page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, filters?: ItemsFiltersDto, csv?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemsChunkResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.itemsControllerFindMany(page, itemsPerPage, orderBy, orderDir, search, filterBy, filter, filters, csv, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async itemsControllerFindOne(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseItemsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.itemsControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateItemsDto} updateItemsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async itemsControllerUpdate(id: number, updateItemsDto: UpdateItemsDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseItemsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.itemsControllerUpdate(id, updateItemsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ItemsApi - factory interface
 * @export
 */
export const ItemsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ItemsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateItemsDto} createItemsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemsControllerCreate(createItemsDto: CreateItemsDto, options?: any): AxiosPromise<ResponseItemsDto> {
            return localVarFp.itemsControllerCreate(createItemsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemsControllerDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.itemsControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {ItemsFiltersDto} [filters] 
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemsControllerFindMany(page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, filters?: ItemsFiltersDto, csv?: string, options?: any): AxiosPromise<ItemsChunkResponseDto> {
            return localVarFp.itemsControllerFindMany(page, itemsPerPage, orderBy, orderDir, search, filterBy, filter, filters, csv, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemsControllerFindOne(id: number, options?: any): AxiosPromise<ResponseItemsDto> {
            return localVarFp.itemsControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateItemsDto} updateItemsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        itemsControllerUpdate(id: number, updateItemsDto: UpdateItemsDto, options?: any): AxiosPromise<ResponseItemsDto> {
            return localVarFp.itemsControllerUpdate(id, updateItemsDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for itemsControllerCreate operation in ItemsApi.
 * @export
 * @interface ItemsApiItemsControllerCreateRequest
 */
export interface ItemsApiItemsControllerCreateRequest {
    /**
     * 
     * @type {CreateItemsDto}
     * @memberof ItemsApiItemsControllerCreate
     */
    readonly createItemsDto: CreateItemsDto
}

/**
 * Request parameters for itemsControllerDelete operation in ItemsApi.
 * @export
 * @interface ItemsApiItemsControllerDeleteRequest
 */
export interface ItemsApiItemsControllerDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof ItemsApiItemsControllerDelete
     */
    readonly id: number
}

/**
 * Request parameters for itemsControllerFindMany operation in ItemsApi.
 * @export
 * @interface ItemsApiItemsControllerFindManyRequest
 */
export interface ItemsApiItemsControllerFindManyRequest {
    /**
     * Number of the page to fetch
     * @type {number}
     * @memberof ItemsApiItemsControllerFindMany
     */
    readonly page?: number

    /**
     * Number of items per page
     * @type {number}
     * @memberof ItemsApiItemsControllerFindMany
     */
    readonly itemsPerPage?: number

    /**
     * Name of the property to order by
     * @type {string}
     * @memberof ItemsApiItemsControllerFindMany
     */
    readonly orderBy?: string

    /**
     * Value which specify direction of the order
     * @type {OrderByDirection}
     * @memberof ItemsApiItemsControllerFindMany
     */
    readonly orderDir?: OrderByDirection

    /**
     * Text which will be used to search through table data
     * @type {string}
     * @memberof ItemsApiItemsControllerFindMany
     */
    readonly search?: string

    /**
     * Name of the property to filter by
     * @type {string}
     * @memberof ItemsApiItemsControllerFindMany
     */
    readonly filterBy?: string

    /**
     * Value which will be used to filter table data
     * @type {string}
     * @memberof ItemsApiItemsControllerFindMany
     */
    readonly filter?: string

    /**
     * 
     * @type {ItemsFiltersDto}
     * @memberof ItemsApiItemsControllerFindMany
     */
    readonly filters?: ItemsFiltersDto

    /**
     * 
     * @type {string}
     * @memberof ItemsApiItemsControllerFindMany
     */
    readonly csv?: string
}

/**
 * Request parameters for itemsControllerFindOne operation in ItemsApi.
 * @export
 * @interface ItemsApiItemsControllerFindOneRequest
 */
export interface ItemsApiItemsControllerFindOneRequest {
    /**
     * 
     * @type {number}
     * @memberof ItemsApiItemsControllerFindOne
     */
    readonly id: number
}

/**
 * Request parameters for itemsControllerUpdate operation in ItemsApi.
 * @export
 * @interface ItemsApiItemsControllerUpdateRequest
 */
export interface ItemsApiItemsControllerUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof ItemsApiItemsControllerUpdate
     */
    readonly id: number

    /**
     * 
     * @type {UpdateItemsDto}
     * @memberof ItemsApiItemsControllerUpdate
     */
    readonly updateItemsDto: UpdateItemsDto
}

/**
 * ItemsApi - object-oriented interface
 * @export
 * @class ItemsApi
 * @extends {BaseAPI}
 */
export class ItemsApi extends BaseAPI {
    /**
     * 
     * @param {ItemsApiItemsControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public itemsControllerCreate(requestParameters: ItemsApiItemsControllerCreateRequest, options?: any) {
        return ItemsApiFp(this.configuration).itemsControllerCreate(requestParameters.createItemsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ItemsApiItemsControllerDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public itemsControllerDelete(requestParameters: ItemsApiItemsControllerDeleteRequest, options?: any) {
        return ItemsApiFp(this.configuration).itemsControllerDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ItemsApiItemsControllerFindManyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public itemsControllerFindMany(requestParameters: ItemsApiItemsControllerFindManyRequest = {}, options?: any) {
        return ItemsApiFp(this.configuration).itemsControllerFindMany(requestParameters.page, requestParameters.itemsPerPage, requestParameters.orderBy, requestParameters.orderDir, requestParameters.search, requestParameters.filterBy, requestParameters.filter, requestParameters.filters, requestParameters.csv, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ItemsApiItemsControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public itemsControllerFindOne(requestParameters: ItemsApiItemsControllerFindOneRequest, options?: any) {
        return ItemsApiFp(this.configuration).itemsControllerFindOne(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ItemsApiItemsControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public itemsControllerUpdate(requestParameters: ItemsApiItemsControllerUpdateRequest, options?: any) {
        return ItemsApiFp(this.configuration).itemsControllerUpdate(requestParameters.id, requestParameters.updateItemsDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * JobsApi - axios parameter creator
 * @export
 */
export const JobsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {boolean} [oldBookings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobsControllerOverlapBookings: async (oldBookings?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/jobs/manual/overlapBookings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (oldBookings !== undefined) {
                localVarQueryParameter['oldBookings'] = oldBookings;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobsControllerRedirectsReportsJob: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/jobs/manual/redirectsReports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dryRun 
         * @param {number} [vendorId] 
         * @param {string} [testEmail] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobsControllerSevDeskJob: async (dryRun: string, vendorId?: number, testEmail?: string, startDate?: string, endDate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'dryRun' is not null or undefined
            assertParamExists('jobsControllerSevDeskJob', 'dryRun', dryRun)
            const localVarPath = `/api/v1/jobs/manual/sevDeskInvoice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (vendorId !== undefined) {
                localVarQueryParameter['vendorId'] = vendorId;
            }

            if (testEmail !== undefined) {
                localVarQueryParameter['testEmail'] = testEmail;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobsControllerStripeBankTransferCleanerJob: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/jobs/manual/stripeBankTransferCleaner`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JobsApi - functional programming interface
 * @export
 */
export const JobsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = JobsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {boolean} [oldBookings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async jobsControllerOverlapBookings(oldBookings?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.jobsControllerOverlapBookings(oldBookings, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async jobsControllerRedirectsReportsJob(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.jobsControllerRedirectsReportsJob(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} dryRun 
         * @param {number} [vendorId] 
         * @param {string} [testEmail] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async jobsControllerSevDeskJob(dryRun: string, vendorId?: number, testEmail?: string, startDate?: string, endDate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.jobsControllerSevDeskJob(dryRun, vendorId, testEmail, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async jobsControllerStripeBankTransferCleanerJob(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.jobsControllerStripeBankTransferCleanerJob(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * JobsApi - factory interface
 * @export
 */
export const JobsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = JobsApiFp(configuration)
    return {
        /**
         * 
         * @param {boolean} [oldBookings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobsControllerOverlapBookings(oldBookings?: boolean, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.jobsControllerOverlapBookings(oldBookings, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobsControllerRedirectsReportsJob(options?: any): AxiosPromise<void> {
            return localVarFp.jobsControllerRedirectsReportsJob(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dryRun 
         * @param {number} [vendorId] 
         * @param {string} [testEmail] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobsControllerSevDeskJob(dryRun: string, vendorId?: number, testEmail?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<void> {
            return localVarFp.jobsControllerSevDeskJob(dryRun, vendorId, testEmail, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobsControllerStripeBankTransferCleanerJob(options?: any): AxiosPromise<void> {
            return localVarFp.jobsControllerStripeBankTransferCleanerJob(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for jobsControllerOverlapBookings operation in JobsApi.
 * @export
 * @interface JobsApiJobsControllerOverlapBookingsRequest
 */
export interface JobsApiJobsControllerOverlapBookingsRequest {
    /**
     * 
     * @type {boolean}
     * @memberof JobsApiJobsControllerOverlapBookings
     */
    readonly oldBookings?: boolean
}

/**
 * Request parameters for jobsControllerSevDeskJob operation in JobsApi.
 * @export
 * @interface JobsApiJobsControllerSevDeskJobRequest
 */
export interface JobsApiJobsControllerSevDeskJobRequest {
    /**
     * 
     * @type {string}
     * @memberof JobsApiJobsControllerSevDeskJob
     */
    readonly dryRun: string

    /**
     * 
     * @type {number}
     * @memberof JobsApiJobsControllerSevDeskJob
     */
    readonly vendorId?: number

    /**
     * 
     * @type {string}
     * @memberof JobsApiJobsControllerSevDeskJob
     */
    readonly testEmail?: string

    /**
     * 
     * @type {string}
     * @memberof JobsApiJobsControllerSevDeskJob
     */
    readonly startDate?: string

    /**
     * 
     * @type {string}
     * @memberof JobsApiJobsControllerSevDeskJob
     */
    readonly endDate?: string
}

/**
 * JobsApi - object-oriented interface
 * @export
 * @class JobsApi
 * @extends {BaseAPI}
 */
export class JobsApi extends BaseAPI {
    /**
     * 
     * @param {JobsApiJobsControllerOverlapBookingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobsApi
     */
    public jobsControllerOverlapBookings(requestParameters: JobsApiJobsControllerOverlapBookingsRequest = {}, options?: any) {
        return JobsApiFp(this.configuration).jobsControllerOverlapBookings(requestParameters.oldBookings, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobsApi
     */
    public jobsControllerRedirectsReportsJob(options?: any) {
        return JobsApiFp(this.configuration).jobsControllerRedirectsReportsJob(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {JobsApiJobsControllerSevDeskJobRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobsApi
     */
    public jobsControllerSevDeskJob(requestParameters: JobsApiJobsControllerSevDeskJobRequest, options?: any) {
        return JobsApiFp(this.configuration).jobsControllerSevDeskJob(requestParameters.dryRun, requestParameters.vendorId, requestParameters.testEmail, requestParameters.startDate, requestParameters.endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobsApi
     */
    public jobsControllerStripeBankTransferCleanerJob(options?: any) {
        return JobsApiFp(this.configuration).jobsControllerStripeBankTransferCleanerJob(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LocationApi - axios parameter creator
 * @export
 */
export const LocationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationsControllerDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('locationsControllerDelete', 'id', id)
            const localVarPath = `/api/v1/vendor/locations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {LocationsFiltersDto} [filters] 
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationsControllerFindMany: async (page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, filters?: LocationsFiltersDto, csv?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/vendor/locations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['orderDir'] = orderDir;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (filterBy !== undefined) {
                localVarQueryParameter['filterBy'] = filterBy;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (filters !== undefined) {
                localVarQueryParameter['filters'] = filters;
            }

            if (csv !== undefined) {
                localVarQueryParameter['csv'] = csv;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationsControllerFindOne: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('locationsControllerFindOne', 'id', id)
            const localVarPath = `/api/v1/vendor/locations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationsControllerTermsHtml: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('locationsControllerTermsHtml', 'id', id)
            const localVarPath = `/api/v1/vendor/locations/terms/{id}/html`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationsControllerTermsPdf: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('locationsControllerTermsPdf', 'id', id)
            const localVarPath = `/api/v1/vendor/locations/terms/{id}/pdf`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LocationApi - functional programming interface
 * @export
 */
export const LocationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LocationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async locationsControllerDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.locationsControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {LocationsFiltersDto} [filters] 
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async locationsControllerFindMany(page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, filters?: LocationsFiltersDto, csv?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationChunkResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.locationsControllerFindMany(page, itemsPerPage, orderBy, orderDir, search, filterBy, filter, filters, csv, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async locationsControllerFindOne(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.locationsControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async locationsControllerTermsHtml(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.locationsControllerTermsHtml(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async locationsControllerTermsPdf(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.locationsControllerTermsPdf(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LocationApi - factory interface
 * @export
 */
export const LocationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LocationApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationsControllerDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.locationsControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {LocationsFiltersDto} [filters] 
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationsControllerFindMany(page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, filters?: LocationsFiltersDto, csv?: string, options?: any): AxiosPromise<LocationChunkResponseDto> {
            return localVarFp.locationsControllerFindMany(page, itemsPerPage, orderBy, orderDir, search, filterBy, filter, filters, csv, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationsControllerFindOne(id: number, options?: any): AxiosPromise<LocationResponseDto> {
            return localVarFp.locationsControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationsControllerTermsHtml(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.locationsControllerTermsHtml(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationsControllerTermsPdf(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.locationsControllerTermsPdf(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for locationsControllerDelete operation in LocationApi.
 * @export
 * @interface LocationApiLocationsControllerDeleteRequest
 */
export interface LocationApiLocationsControllerDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof LocationApiLocationsControllerDelete
     */
    readonly id: number
}

/**
 * Request parameters for locationsControllerFindMany operation in LocationApi.
 * @export
 * @interface LocationApiLocationsControllerFindManyRequest
 */
export interface LocationApiLocationsControllerFindManyRequest {
    /**
     * Number of the page to fetch
     * @type {number}
     * @memberof LocationApiLocationsControllerFindMany
     */
    readonly page?: number

    /**
     * Number of items per page
     * @type {number}
     * @memberof LocationApiLocationsControllerFindMany
     */
    readonly itemsPerPage?: number

    /**
     * Name of the property to order by
     * @type {string}
     * @memberof LocationApiLocationsControllerFindMany
     */
    readonly orderBy?: string

    /**
     * Value which specify direction of the order
     * @type {OrderByDirection}
     * @memberof LocationApiLocationsControllerFindMany
     */
    readonly orderDir?: OrderByDirection

    /**
     * Text which will be used to search through table data
     * @type {string}
     * @memberof LocationApiLocationsControllerFindMany
     */
    readonly search?: string

    /**
     * Name of the property to filter by
     * @type {string}
     * @memberof LocationApiLocationsControllerFindMany
     */
    readonly filterBy?: string

    /**
     * Value which will be used to filter table data
     * @type {string}
     * @memberof LocationApiLocationsControllerFindMany
     */
    readonly filter?: string

    /**
     * 
     * @type {LocationsFiltersDto}
     * @memberof LocationApiLocationsControllerFindMany
     */
    readonly filters?: LocationsFiltersDto

    /**
     * 
     * @type {string}
     * @memberof LocationApiLocationsControllerFindMany
     */
    readonly csv?: string
}

/**
 * Request parameters for locationsControllerFindOne operation in LocationApi.
 * @export
 * @interface LocationApiLocationsControllerFindOneRequest
 */
export interface LocationApiLocationsControllerFindOneRequest {
    /**
     * 
     * @type {number}
     * @memberof LocationApiLocationsControllerFindOne
     */
    readonly id: number
}

/**
 * Request parameters for locationsControllerTermsHtml operation in LocationApi.
 * @export
 * @interface LocationApiLocationsControllerTermsHtmlRequest
 */
export interface LocationApiLocationsControllerTermsHtmlRequest {
    /**
     * 
     * @type {number}
     * @memberof LocationApiLocationsControllerTermsHtml
     */
    readonly id: number
}

/**
 * Request parameters for locationsControllerTermsPdf operation in LocationApi.
 * @export
 * @interface LocationApiLocationsControllerTermsPdfRequest
 */
export interface LocationApiLocationsControllerTermsPdfRequest {
    /**
     * 
     * @type {number}
     * @memberof LocationApiLocationsControllerTermsPdf
     */
    readonly id: number
}

/**
 * LocationApi - object-oriented interface
 * @export
 * @class LocationApi
 * @extends {BaseAPI}
 */
export class LocationApi extends BaseAPI {
    /**
     * 
     * @param {LocationApiLocationsControllerDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public locationsControllerDelete(requestParameters: LocationApiLocationsControllerDeleteRequest, options?: any) {
        return LocationApiFp(this.configuration).locationsControllerDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LocationApiLocationsControllerFindManyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public locationsControllerFindMany(requestParameters: LocationApiLocationsControllerFindManyRequest = {}, options?: any) {
        return LocationApiFp(this.configuration).locationsControllerFindMany(requestParameters.page, requestParameters.itemsPerPage, requestParameters.orderBy, requestParameters.orderDir, requestParameters.search, requestParameters.filterBy, requestParameters.filter, requestParameters.filters, requestParameters.csv, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LocationApiLocationsControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public locationsControllerFindOne(requestParameters: LocationApiLocationsControllerFindOneRequest, options?: any) {
        return LocationApiFp(this.configuration).locationsControllerFindOne(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LocationApiLocationsControllerTermsHtmlRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public locationsControllerTermsHtml(requestParameters: LocationApiLocationsControllerTermsHtmlRequest, options?: any) {
        return LocationApiFp(this.configuration).locationsControllerTermsHtml(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LocationApiLocationsControllerTermsPdfRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public locationsControllerTermsPdf(requestParameters: LocationApiLocationsControllerTermsPdfRequest, options?: any) {
        return LocationApiFp(this.configuration).locationsControllerTermsPdf(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LocksApi - axios parameter creator
 * @export
 */
export const LocksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateLockBodyDto} createLockBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locksControllerCreate: async (createLockBodyDto: CreateLockBodyDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createLockBodyDto' is not null or undefined
            assertParamExists('locksControllerCreate', 'createLockBodyDto', createLockBodyDto)
            const localVarPath = `/api/v1/bookings/locks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createLockBodyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id ID of the lock
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locksControllerDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('locksControllerDelete', 'id', id)
            const localVarPath = `/api/v1/bookings/locks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {LocksFiltersDto} [filters] 
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locksControllerFindMany: async (page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, filters?: LocksFiltersDto, csv?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/bookings/locks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['orderDir'] = orderDir;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (filterBy !== undefined) {
                localVarQueryParameter['filterBy'] = filterBy;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (filters !== undefined) {
                localVarQueryParameter['filters'] = filters;
            }

            if (csv !== undefined) {
                localVarQueryParameter['csv'] = csv;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id ID of the lock
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locksControllerFindOne: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('locksControllerFindOne', 'id', id)
            const localVarPath = `/api/v1/bookings/locks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} number Number of the lock
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locksControllerFindOneWithNumber: async (number: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'number' is not null or undefined
            assertParamExists('locksControllerFindOneWithNumber', 'number', number)
            const localVarPath = `/api/v1/bookings/locks/name/{number}`
                .replace(`{${"number"}}`, encodeURIComponent(String(number)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id ID of the lock
         * @param {UpdateLockBodyDto} updateLockBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locksControllerUpdate: async (id: number, updateLockBodyDto: UpdateLockBodyDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('locksControllerUpdate', 'id', id)
            // verify required parameter 'updateLockBodyDto' is not null or undefined
            assertParamExists('locksControllerUpdate', 'updateLockBodyDto', updateLockBodyDto)
            const localVarPath = `/api/v1/bookings/locks/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLockBodyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LocksApi - functional programming interface
 * @export
 */
export const LocksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LocksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateLockBodyDto} createLockBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async locksControllerCreate(createLockBodyDto: CreateLockBodyDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LockResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.locksControllerCreate(createLockBodyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id ID of the lock
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async locksControllerDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.locksControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {LocksFiltersDto} [filters] 
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async locksControllerFindMany(page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, filters?: LocksFiltersDto, csv?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LockChunkResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.locksControllerFindMany(page, itemsPerPage, orderBy, orderDir, search, filterBy, filter, filters, csv, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id ID of the lock
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async locksControllerFindOne(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LockResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.locksControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} number Number of the lock
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async locksControllerFindOneWithNumber(number: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LockResponseDtoWithPasswords>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.locksControllerFindOneWithNumber(number, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id ID of the lock
         * @param {UpdateLockBodyDto} updateLockBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async locksControllerUpdate(id: number, updateLockBodyDto: UpdateLockBodyDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LockResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.locksControllerUpdate(id, updateLockBodyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LocksApi - factory interface
 * @export
 */
export const LocksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LocksApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateLockBodyDto} createLockBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locksControllerCreate(createLockBodyDto: CreateLockBodyDto, options?: any): AxiosPromise<LockResponseDto> {
            return localVarFp.locksControllerCreate(createLockBodyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id ID of the lock
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locksControllerDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.locksControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {LocksFiltersDto} [filters] 
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locksControllerFindMany(page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, filters?: LocksFiltersDto, csv?: string, options?: any): AxiosPromise<LockChunkResponseDto> {
            return localVarFp.locksControllerFindMany(page, itemsPerPage, orderBy, orderDir, search, filterBy, filter, filters, csv, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id ID of the lock
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locksControllerFindOne(id: number, options?: any): AxiosPromise<LockResponseDto> {
            return localVarFp.locksControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} number Number of the lock
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locksControllerFindOneWithNumber(number: string, options?: any): AxiosPromise<LockResponseDtoWithPasswords> {
            return localVarFp.locksControllerFindOneWithNumber(number, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id ID of the lock
         * @param {UpdateLockBodyDto} updateLockBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locksControllerUpdate(id: number, updateLockBodyDto: UpdateLockBodyDto, options?: any): AxiosPromise<LockResponseDto> {
            return localVarFp.locksControllerUpdate(id, updateLockBodyDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for locksControllerCreate operation in LocksApi.
 * @export
 * @interface LocksApiLocksControllerCreateRequest
 */
export interface LocksApiLocksControllerCreateRequest {
    /**
     * 
     * @type {CreateLockBodyDto}
     * @memberof LocksApiLocksControllerCreate
     */
    readonly createLockBodyDto: CreateLockBodyDto
}

/**
 * Request parameters for locksControllerDelete operation in LocksApi.
 * @export
 * @interface LocksApiLocksControllerDeleteRequest
 */
export interface LocksApiLocksControllerDeleteRequest {
    /**
     * ID of the lock
     * @type {number}
     * @memberof LocksApiLocksControllerDelete
     */
    readonly id: number
}

/**
 * Request parameters for locksControllerFindMany operation in LocksApi.
 * @export
 * @interface LocksApiLocksControllerFindManyRequest
 */
export interface LocksApiLocksControllerFindManyRequest {
    /**
     * Number of the page to fetch
     * @type {number}
     * @memberof LocksApiLocksControllerFindMany
     */
    readonly page?: number

    /**
     * Number of items per page
     * @type {number}
     * @memberof LocksApiLocksControllerFindMany
     */
    readonly itemsPerPage?: number

    /**
     * Name of the property to order by
     * @type {string}
     * @memberof LocksApiLocksControllerFindMany
     */
    readonly orderBy?: string

    /**
     * Value which specify direction of the order
     * @type {OrderByDirection}
     * @memberof LocksApiLocksControllerFindMany
     */
    readonly orderDir?: OrderByDirection

    /**
     * Text which will be used to search through table data
     * @type {string}
     * @memberof LocksApiLocksControllerFindMany
     */
    readonly search?: string

    /**
     * Name of the property to filter by
     * @type {string}
     * @memberof LocksApiLocksControllerFindMany
     */
    readonly filterBy?: string

    /**
     * Value which will be used to filter table data
     * @type {string}
     * @memberof LocksApiLocksControllerFindMany
     */
    readonly filter?: string

    /**
     * 
     * @type {LocksFiltersDto}
     * @memberof LocksApiLocksControllerFindMany
     */
    readonly filters?: LocksFiltersDto

    /**
     * 
     * @type {string}
     * @memberof LocksApiLocksControllerFindMany
     */
    readonly csv?: string
}

/**
 * Request parameters for locksControllerFindOne operation in LocksApi.
 * @export
 * @interface LocksApiLocksControllerFindOneRequest
 */
export interface LocksApiLocksControllerFindOneRequest {
    /**
     * ID of the lock
     * @type {number}
     * @memberof LocksApiLocksControllerFindOne
     */
    readonly id: number
}

/**
 * Request parameters for locksControllerFindOneWithNumber operation in LocksApi.
 * @export
 * @interface LocksApiLocksControllerFindOneWithNumberRequest
 */
export interface LocksApiLocksControllerFindOneWithNumberRequest {
    /**
     * Number of the lock
     * @type {string}
     * @memberof LocksApiLocksControllerFindOneWithNumber
     */
    readonly number: string
}

/**
 * Request parameters for locksControllerUpdate operation in LocksApi.
 * @export
 * @interface LocksApiLocksControllerUpdateRequest
 */
export interface LocksApiLocksControllerUpdateRequest {
    /**
     * ID of the lock
     * @type {number}
     * @memberof LocksApiLocksControllerUpdate
     */
    readonly id: number

    /**
     * 
     * @type {UpdateLockBodyDto}
     * @memberof LocksApiLocksControllerUpdate
     */
    readonly updateLockBodyDto: UpdateLockBodyDto
}

/**
 * LocksApi - object-oriented interface
 * @export
 * @class LocksApi
 * @extends {BaseAPI}
 */
export class LocksApi extends BaseAPI {
    /**
     * 
     * @param {LocksApiLocksControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocksApi
     */
    public locksControllerCreate(requestParameters: LocksApiLocksControllerCreateRequest, options?: any) {
        return LocksApiFp(this.configuration).locksControllerCreate(requestParameters.createLockBodyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LocksApiLocksControllerDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocksApi
     */
    public locksControllerDelete(requestParameters: LocksApiLocksControllerDeleteRequest, options?: any) {
        return LocksApiFp(this.configuration).locksControllerDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LocksApiLocksControllerFindManyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocksApi
     */
    public locksControllerFindMany(requestParameters: LocksApiLocksControllerFindManyRequest = {}, options?: any) {
        return LocksApiFp(this.configuration).locksControllerFindMany(requestParameters.page, requestParameters.itemsPerPage, requestParameters.orderBy, requestParameters.orderDir, requestParameters.search, requestParameters.filterBy, requestParameters.filter, requestParameters.filters, requestParameters.csv, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LocksApiLocksControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocksApi
     */
    public locksControllerFindOne(requestParameters: LocksApiLocksControllerFindOneRequest, options?: any) {
        return LocksApiFp(this.configuration).locksControllerFindOne(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LocksApiLocksControllerFindOneWithNumberRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocksApi
     */
    public locksControllerFindOneWithNumber(requestParameters: LocksApiLocksControllerFindOneWithNumberRequest, options?: any) {
        return LocksApiFp(this.configuration).locksControllerFindOneWithNumber(requestParameters.number, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LocksApiLocksControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocksApi
     */
    public locksControllerUpdate(requestParameters: LocksApiLocksControllerUpdateRequest, options?: any) {
        return LocksApiFp(this.configuration).locksControllerUpdate(requestParameters.id, requestParameters.updateLockBodyDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaymentsApi - axios parameter creator
 * @export
 */
export const PaymentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {boolean} [skipEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalPpControllerConfirmExternalPayment: async (id: number, skipEmail?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('externalPpControllerConfirmExternalPayment', 'id', id)
            const localVarPath = `/api/v1/payments/external_pp/{id}/pay`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (skipEmail !== undefined) {
                localVarQueryParameter['skipEmail'] = skipEmail;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} bookingRef 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerConfirmFromPayment: async (bookingRef: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingRef' is not null or undefined
            assertParamExists('paymentsControllerConfirmFromPayment', 'bookingRef', bookingRef)
            const localVarPath = `/api/v1/payments/admin/confirm-from-payment/{bookingRef}`
                .replace(`{${"bookingRef"}}`, encodeURIComponent(String(bookingRef)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {string} bookingRef 
         * @param {PaymentFlow} [flow] 
         * @param {string} [timeZone] 
         * @param {string} [paymentId] 
         * @param {string} [token] 
         * @param {string} [payerID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerCreatePayPalPayment: async (email: string, bookingRef: string, flow?: PaymentFlow, timeZone?: string, paymentId?: string, token?: string, payerID?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('paymentsControllerCreatePayPalPayment', 'email', email)
            // verify required parameter 'bookingRef' is not null or undefined
            assertParamExists('paymentsControllerCreatePayPalPayment', 'bookingRef', bookingRef)
            const localVarPath = `/api/v1/payments/paypal/payment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (bookingRef !== undefined) {
                localVarQueryParameter['bookingRef'] = bookingRef;
            }

            if (flow !== undefined) {
                localVarQueryParameter['flow'] = flow;
            }

            if (timeZone !== undefined) {
                localVarQueryParameter['timeZone'] = timeZone;
            }

            if (paymentId !== undefined) {
                localVarQueryParameter['paymentId'] = paymentId;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (payerID !== undefined) {
                localVarQueryParameter['PayerID'] = payerID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {string} bookingRef 
         * @param {PaymentFlow} [flow] 
         * @param {string} [timeZone] 
         * @param {string} [paymentId] 
         * @param {string} [token] 
         * @param {string} [payerID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerCreateStripeCheckoutSession: async (email: string, bookingRef: string, flow?: PaymentFlow, timeZone?: string, paymentId?: string, token?: string, payerID?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('paymentsControllerCreateStripeCheckoutSession', 'email', email)
            // verify required parameter 'bookingRef' is not null or undefined
            assertParamExists('paymentsControllerCreateStripeCheckoutSession', 'bookingRef', bookingRef)
            const localVarPath = `/api/v1/payments/stripe/checkout-session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (bookingRef !== undefined) {
                localVarQueryParameter['bookingRef'] = bookingRef;
            }

            if (flow !== undefined) {
                localVarQueryParameter['flow'] = flow;
            }

            if (timeZone !== undefined) {
                localVarQueryParameter['timeZone'] = timeZone;
            }

            if (paymentId !== undefined) {
                localVarQueryParameter['paymentId'] = paymentId;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (payerID !== undefined) {
                localVarQueryParameter['PayerID'] = payerID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {string} bookingRef 
         * @param {PaymentFlow} [flow] 
         * @param {string} [timeZone] 
         * @param {string} [paymentId] 
         * @param {string} [token] 
         * @param {string} [payerID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerHandlePaypalError: async (email: string, bookingRef: string, flow?: PaymentFlow, timeZone?: string, paymentId?: string, token?: string, payerID?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('paymentsControllerHandlePaypalError', 'email', email)
            // verify required parameter 'bookingRef' is not null or undefined
            assertParamExists('paymentsControllerHandlePaypalError', 'bookingRef', bookingRef)
            const localVarPath = `/api/v1/payments/paypal/error`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (bookingRef !== undefined) {
                localVarQueryParameter['bookingRef'] = bookingRef;
            }

            if (flow !== undefined) {
                localVarQueryParameter['flow'] = flow;
            }

            if (timeZone !== undefined) {
                localVarQueryParameter['timeZone'] = timeZone;
            }

            if (paymentId !== undefined) {
                localVarQueryParameter['paymentId'] = paymentId;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (payerID !== undefined) {
                localVarQueryParameter['PayerID'] = payerID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {string} bookingRef 
         * @param {PaymentFlow} [flow] 
         * @param {string} [timeZone] 
         * @param {string} [paymentId] 
         * @param {string} [token] 
         * @param {string} [payerID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerHandlePaypalSuccess: async (email: string, bookingRef: string, flow?: PaymentFlow, timeZone?: string, paymentId?: string, token?: string, payerID?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('paymentsControllerHandlePaypalSuccess', 'email', email)
            // verify required parameter 'bookingRef' is not null or undefined
            assertParamExists('paymentsControllerHandlePaypalSuccess', 'bookingRef', bookingRef)
            const localVarPath = `/api/v1/payments/paypal/success`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (bookingRef !== undefined) {
                localVarQueryParameter['bookingRef'] = bookingRef;
            }

            if (flow !== undefined) {
                localVarQueryParameter['flow'] = flow;
            }

            if (timeZone !== undefined) {
                localVarQueryParameter['timeZone'] = timeZone;
            }

            if (paymentId !== undefined) {
                localVarQueryParameter['paymentId'] = paymentId;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (payerID !== undefined) {
                localVarQueryParameter['PayerID'] = payerID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} stripeSignature Stripe signature used to authenticate the request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerHandleStripeMessage: async (stripeSignature: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'stripeSignature' is not null or undefined
            assertParamExists('paymentsControllerHandleStripeMessage', 'stripeSignature', stripeSignature)
            const localVarPath = `/api/v1/payments/stripe/webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stripeSignature !== undefined && stripeSignature !== null) {
                localVarHeaderParameter['stripe-signature'] = String(stripeSignature);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {string} bookingRef 
         * @param {PaymentFlow} [flow] 
         * @param {string} [timeZone] 
         * @param {string} [paymentId] 
         * @param {string} [token] 
         * @param {string} [payerID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerPublicAbortPayment: async (email: string, bookingRef: string, flow?: PaymentFlow, timeZone?: string, paymentId?: string, token?: string, payerID?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('paymentsControllerPublicAbortPayment', 'email', email)
            // verify required parameter 'bookingRef' is not null or undefined
            assertParamExists('paymentsControllerPublicAbortPayment', 'bookingRef', bookingRef)
            const localVarPath = `/api/v1/payments/stripe/payment/abort`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (bookingRef !== undefined) {
                localVarQueryParameter['bookingRef'] = bookingRef;
            }

            if (flow !== undefined) {
                localVarQueryParameter['flow'] = flow;
            }

            if (timeZone !== undefined) {
                localVarQueryParameter['timeZone'] = timeZone;
            }

            if (paymentId !== undefined) {
                localVarQueryParameter['paymentId'] = paymentId;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (payerID !== undefined) {
                localVarQueryParameter['PayerID'] = payerID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentsApi - functional programming interface
 * @export
 */
export const PaymentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {boolean} [skipEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async externalPpControllerConfirmExternalPayment(id: number, skipEmail?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.externalPpControllerConfirmExternalPayment(id, skipEmail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} bookingRef 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsControllerConfirmFromPayment(bookingRef: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsControllerConfirmFromPayment(bookingRef, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} email 
         * @param {string} bookingRef 
         * @param {PaymentFlow} [flow] 
         * @param {string} [timeZone] 
         * @param {string} [paymentId] 
         * @param {string} [token] 
         * @param {string} [payerID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsControllerCreatePayPalPayment(email: string, bookingRef: string, flow?: PaymentFlow, timeZone?: string, paymentId?: string, token?: string, payerID?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsControllerCreatePayPalPayment(email, bookingRef, flow, timeZone, paymentId, token, payerID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} email 
         * @param {string} bookingRef 
         * @param {PaymentFlow} [flow] 
         * @param {string} [timeZone] 
         * @param {string} [paymentId] 
         * @param {string} [token] 
         * @param {string} [payerID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsControllerCreateStripeCheckoutSession(email: string, bookingRef: string, flow?: PaymentFlow, timeZone?: string, paymentId?: string, token?: string, payerID?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsControllerCreateStripeCheckoutSession(email, bookingRef, flow, timeZone, paymentId, token, payerID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} email 
         * @param {string} bookingRef 
         * @param {PaymentFlow} [flow] 
         * @param {string} [timeZone] 
         * @param {string} [paymentId] 
         * @param {string} [token] 
         * @param {string} [payerID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsControllerHandlePaypalError(email: string, bookingRef: string, flow?: PaymentFlow, timeZone?: string, paymentId?: string, token?: string, payerID?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsControllerHandlePaypalError(email, bookingRef, flow, timeZone, paymentId, token, payerID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} email 
         * @param {string} bookingRef 
         * @param {PaymentFlow} [flow] 
         * @param {string} [timeZone] 
         * @param {string} [paymentId] 
         * @param {string} [token] 
         * @param {string} [payerID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsControllerHandlePaypalSuccess(email: string, bookingRef: string, flow?: PaymentFlow, timeZone?: string, paymentId?: string, token?: string, payerID?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsControllerHandlePaypalSuccess(email, bookingRef, flow, timeZone, paymentId, token, payerID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} stripeSignature Stripe signature used to authenticate the request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsControllerHandleStripeMessage(stripeSignature: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsControllerHandleStripeMessage(stripeSignature, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} email 
         * @param {string} bookingRef 
         * @param {PaymentFlow} [flow] 
         * @param {string} [timeZone] 
         * @param {string} [paymentId] 
         * @param {string} [token] 
         * @param {string} [payerID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsControllerPublicAbortPayment(email: string, bookingRef: string, flow?: PaymentFlow, timeZone?: string, paymentId?: string, token?: string, payerID?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingDetailsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsControllerPublicAbortPayment(email, bookingRef, flow, timeZone, paymentId, token, payerID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentsApi - factory interface
 * @export
 */
export const PaymentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {boolean} [skipEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalPpControllerConfirmExternalPayment(id: number, skipEmail?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.externalPpControllerConfirmExternalPayment(id, skipEmail, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} bookingRef 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerConfirmFromPayment(bookingRef: string, options?: any): AxiosPromise<void> {
            return localVarFp.paymentsControllerConfirmFromPayment(bookingRef, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {string} bookingRef 
         * @param {PaymentFlow} [flow] 
         * @param {string} [timeZone] 
         * @param {string} [paymentId] 
         * @param {string} [token] 
         * @param {string} [payerID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerCreatePayPalPayment(email: string, bookingRef: string, flow?: PaymentFlow, timeZone?: string, paymentId?: string, token?: string, payerID?: string, options?: any): AxiosPromise<PaymentsResponseDto> {
            return localVarFp.paymentsControllerCreatePayPalPayment(email, bookingRef, flow, timeZone, paymentId, token, payerID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {string} bookingRef 
         * @param {PaymentFlow} [flow] 
         * @param {string} [timeZone] 
         * @param {string} [paymentId] 
         * @param {string} [token] 
         * @param {string} [payerID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerCreateStripeCheckoutSession(email: string, bookingRef: string, flow?: PaymentFlow, timeZone?: string, paymentId?: string, token?: string, payerID?: string, options?: any): AxiosPromise<PaymentsResponseDto> {
            return localVarFp.paymentsControllerCreateStripeCheckoutSession(email, bookingRef, flow, timeZone, paymentId, token, payerID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {string} bookingRef 
         * @param {PaymentFlow} [flow] 
         * @param {string} [timeZone] 
         * @param {string} [paymentId] 
         * @param {string} [token] 
         * @param {string} [payerID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerHandlePaypalError(email: string, bookingRef: string, flow?: PaymentFlow, timeZone?: string, paymentId?: string, token?: string, payerID?: string, options?: any): AxiosPromise<void> {
            return localVarFp.paymentsControllerHandlePaypalError(email, bookingRef, flow, timeZone, paymentId, token, payerID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {string} bookingRef 
         * @param {PaymentFlow} [flow] 
         * @param {string} [timeZone] 
         * @param {string} [paymentId] 
         * @param {string} [token] 
         * @param {string} [payerID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerHandlePaypalSuccess(email: string, bookingRef: string, flow?: PaymentFlow, timeZone?: string, paymentId?: string, token?: string, payerID?: string, options?: any): AxiosPromise<void> {
            return localVarFp.paymentsControllerHandlePaypalSuccess(email, bookingRef, flow, timeZone, paymentId, token, payerID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} stripeSignature Stripe signature used to authenticate the request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerHandleStripeMessage(stripeSignature: string, options?: any): AxiosPromise<void> {
            return localVarFp.paymentsControllerHandleStripeMessage(stripeSignature, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {string} bookingRef 
         * @param {PaymentFlow} [flow] 
         * @param {string} [timeZone] 
         * @param {string} [paymentId] 
         * @param {string} [token] 
         * @param {string} [payerID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerPublicAbortPayment(email: string, bookingRef: string, flow?: PaymentFlow, timeZone?: string, paymentId?: string, token?: string, payerID?: string, options?: any): AxiosPromise<BookingDetailsResponseDto> {
            return localVarFp.paymentsControllerPublicAbortPayment(email, bookingRef, flow, timeZone, paymentId, token, payerID, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for externalPpControllerConfirmExternalPayment operation in PaymentsApi.
 * @export
 * @interface PaymentsApiExternalPpControllerConfirmExternalPaymentRequest
 */
export interface PaymentsApiExternalPpControllerConfirmExternalPaymentRequest {
    /**
     * 
     * @type {number}
     * @memberof PaymentsApiExternalPpControllerConfirmExternalPayment
     */
    readonly id: number

    /**
     * 
     * @type {boolean}
     * @memberof PaymentsApiExternalPpControllerConfirmExternalPayment
     */
    readonly skipEmail?: boolean
}

/**
 * Request parameters for paymentsControllerConfirmFromPayment operation in PaymentsApi.
 * @export
 * @interface PaymentsApiPaymentsControllerConfirmFromPaymentRequest
 */
export interface PaymentsApiPaymentsControllerConfirmFromPaymentRequest {
    /**
     * 
     * @type {string}
     * @memberof PaymentsApiPaymentsControllerConfirmFromPayment
     */
    readonly bookingRef: string
}

/**
 * Request parameters for paymentsControllerCreatePayPalPayment operation in PaymentsApi.
 * @export
 * @interface PaymentsApiPaymentsControllerCreatePayPalPaymentRequest
 */
export interface PaymentsApiPaymentsControllerCreatePayPalPaymentRequest {
    /**
     * 
     * @type {string}
     * @memberof PaymentsApiPaymentsControllerCreatePayPalPayment
     */
    readonly email: string

    /**
     * 
     * @type {string}
     * @memberof PaymentsApiPaymentsControllerCreatePayPalPayment
     */
    readonly bookingRef: string

    /**
     * 
     * @type {PaymentFlow}
     * @memberof PaymentsApiPaymentsControllerCreatePayPalPayment
     */
    readonly flow?: PaymentFlow

    /**
     * 
     * @type {string}
     * @memberof PaymentsApiPaymentsControllerCreatePayPalPayment
     */
    readonly timeZone?: string

    /**
     * 
     * @type {string}
     * @memberof PaymentsApiPaymentsControllerCreatePayPalPayment
     */
    readonly paymentId?: string

    /**
     * 
     * @type {string}
     * @memberof PaymentsApiPaymentsControllerCreatePayPalPayment
     */
    readonly token?: string

    /**
     * 
     * @type {string}
     * @memberof PaymentsApiPaymentsControllerCreatePayPalPayment
     */
    readonly payerID?: string
}

/**
 * Request parameters for paymentsControllerCreateStripeCheckoutSession operation in PaymentsApi.
 * @export
 * @interface PaymentsApiPaymentsControllerCreateStripeCheckoutSessionRequest
 */
export interface PaymentsApiPaymentsControllerCreateStripeCheckoutSessionRequest {
    /**
     * 
     * @type {string}
     * @memberof PaymentsApiPaymentsControllerCreateStripeCheckoutSession
     */
    readonly email: string

    /**
     * 
     * @type {string}
     * @memberof PaymentsApiPaymentsControllerCreateStripeCheckoutSession
     */
    readonly bookingRef: string

    /**
     * 
     * @type {PaymentFlow}
     * @memberof PaymentsApiPaymentsControllerCreateStripeCheckoutSession
     */
    readonly flow?: PaymentFlow

    /**
     * 
     * @type {string}
     * @memberof PaymentsApiPaymentsControllerCreateStripeCheckoutSession
     */
    readonly timeZone?: string

    /**
     * 
     * @type {string}
     * @memberof PaymentsApiPaymentsControllerCreateStripeCheckoutSession
     */
    readonly paymentId?: string

    /**
     * 
     * @type {string}
     * @memberof PaymentsApiPaymentsControllerCreateStripeCheckoutSession
     */
    readonly token?: string

    /**
     * 
     * @type {string}
     * @memberof PaymentsApiPaymentsControllerCreateStripeCheckoutSession
     */
    readonly payerID?: string
}

/**
 * Request parameters for paymentsControllerHandlePaypalError operation in PaymentsApi.
 * @export
 * @interface PaymentsApiPaymentsControllerHandlePaypalErrorRequest
 */
export interface PaymentsApiPaymentsControllerHandlePaypalErrorRequest {
    /**
     * 
     * @type {string}
     * @memberof PaymentsApiPaymentsControllerHandlePaypalError
     */
    readonly email: string

    /**
     * 
     * @type {string}
     * @memberof PaymentsApiPaymentsControllerHandlePaypalError
     */
    readonly bookingRef: string

    /**
     * 
     * @type {PaymentFlow}
     * @memberof PaymentsApiPaymentsControllerHandlePaypalError
     */
    readonly flow?: PaymentFlow

    /**
     * 
     * @type {string}
     * @memberof PaymentsApiPaymentsControllerHandlePaypalError
     */
    readonly timeZone?: string

    /**
     * 
     * @type {string}
     * @memberof PaymentsApiPaymentsControllerHandlePaypalError
     */
    readonly paymentId?: string

    /**
     * 
     * @type {string}
     * @memberof PaymentsApiPaymentsControllerHandlePaypalError
     */
    readonly token?: string

    /**
     * 
     * @type {string}
     * @memberof PaymentsApiPaymentsControllerHandlePaypalError
     */
    readonly payerID?: string
}

/**
 * Request parameters for paymentsControllerHandlePaypalSuccess operation in PaymentsApi.
 * @export
 * @interface PaymentsApiPaymentsControllerHandlePaypalSuccessRequest
 */
export interface PaymentsApiPaymentsControllerHandlePaypalSuccessRequest {
    /**
     * 
     * @type {string}
     * @memberof PaymentsApiPaymentsControllerHandlePaypalSuccess
     */
    readonly email: string

    /**
     * 
     * @type {string}
     * @memberof PaymentsApiPaymentsControllerHandlePaypalSuccess
     */
    readonly bookingRef: string

    /**
     * 
     * @type {PaymentFlow}
     * @memberof PaymentsApiPaymentsControllerHandlePaypalSuccess
     */
    readonly flow?: PaymentFlow

    /**
     * 
     * @type {string}
     * @memberof PaymentsApiPaymentsControllerHandlePaypalSuccess
     */
    readonly timeZone?: string

    /**
     * 
     * @type {string}
     * @memberof PaymentsApiPaymentsControllerHandlePaypalSuccess
     */
    readonly paymentId?: string

    /**
     * 
     * @type {string}
     * @memberof PaymentsApiPaymentsControllerHandlePaypalSuccess
     */
    readonly token?: string

    /**
     * 
     * @type {string}
     * @memberof PaymentsApiPaymentsControllerHandlePaypalSuccess
     */
    readonly payerID?: string
}

/**
 * Request parameters for paymentsControllerHandleStripeMessage operation in PaymentsApi.
 * @export
 * @interface PaymentsApiPaymentsControllerHandleStripeMessageRequest
 */
export interface PaymentsApiPaymentsControllerHandleStripeMessageRequest {
    /**
     * Stripe signature used to authenticate the request
     * @type {string}
     * @memberof PaymentsApiPaymentsControllerHandleStripeMessage
     */
    readonly stripeSignature: string
}

/**
 * Request parameters for paymentsControllerPublicAbortPayment operation in PaymentsApi.
 * @export
 * @interface PaymentsApiPaymentsControllerPublicAbortPaymentRequest
 */
export interface PaymentsApiPaymentsControllerPublicAbortPaymentRequest {
    /**
     * 
     * @type {string}
     * @memberof PaymentsApiPaymentsControllerPublicAbortPayment
     */
    readonly email: string

    /**
     * 
     * @type {string}
     * @memberof PaymentsApiPaymentsControllerPublicAbortPayment
     */
    readonly bookingRef: string

    /**
     * 
     * @type {PaymentFlow}
     * @memberof PaymentsApiPaymentsControllerPublicAbortPayment
     */
    readonly flow?: PaymentFlow

    /**
     * 
     * @type {string}
     * @memberof PaymentsApiPaymentsControllerPublicAbortPayment
     */
    readonly timeZone?: string

    /**
     * 
     * @type {string}
     * @memberof PaymentsApiPaymentsControllerPublicAbortPayment
     */
    readonly paymentId?: string

    /**
     * 
     * @type {string}
     * @memberof PaymentsApiPaymentsControllerPublicAbortPayment
     */
    readonly token?: string

    /**
     * 
     * @type {string}
     * @memberof PaymentsApiPaymentsControllerPublicAbortPayment
     */
    readonly payerID?: string
}

/**
 * PaymentsApi - object-oriented interface
 * @export
 * @class PaymentsApi
 * @extends {BaseAPI}
 */
export class PaymentsApi extends BaseAPI {
    /**
     * 
     * @param {PaymentsApiExternalPpControllerConfirmExternalPaymentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public externalPpControllerConfirmExternalPayment(requestParameters: PaymentsApiExternalPpControllerConfirmExternalPaymentRequest, options?: any) {
        return PaymentsApiFp(this.configuration).externalPpControllerConfirmExternalPayment(requestParameters.id, requestParameters.skipEmail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PaymentsApiPaymentsControllerConfirmFromPaymentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public paymentsControllerConfirmFromPayment(requestParameters: PaymentsApiPaymentsControllerConfirmFromPaymentRequest, options?: any) {
        return PaymentsApiFp(this.configuration).paymentsControllerConfirmFromPayment(requestParameters.bookingRef, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PaymentsApiPaymentsControllerCreatePayPalPaymentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public paymentsControllerCreatePayPalPayment(requestParameters: PaymentsApiPaymentsControllerCreatePayPalPaymentRequest, options?: any) {
        return PaymentsApiFp(this.configuration).paymentsControllerCreatePayPalPayment(requestParameters.email, requestParameters.bookingRef, requestParameters.flow, requestParameters.timeZone, requestParameters.paymentId, requestParameters.token, requestParameters.payerID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PaymentsApiPaymentsControllerCreateStripeCheckoutSessionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public paymentsControllerCreateStripeCheckoutSession(requestParameters: PaymentsApiPaymentsControllerCreateStripeCheckoutSessionRequest, options?: any) {
        return PaymentsApiFp(this.configuration).paymentsControllerCreateStripeCheckoutSession(requestParameters.email, requestParameters.bookingRef, requestParameters.flow, requestParameters.timeZone, requestParameters.paymentId, requestParameters.token, requestParameters.payerID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PaymentsApiPaymentsControllerHandlePaypalErrorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public paymentsControllerHandlePaypalError(requestParameters: PaymentsApiPaymentsControllerHandlePaypalErrorRequest, options?: any) {
        return PaymentsApiFp(this.configuration).paymentsControllerHandlePaypalError(requestParameters.email, requestParameters.bookingRef, requestParameters.flow, requestParameters.timeZone, requestParameters.paymentId, requestParameters.token, requestParameters.payerID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PaymentsApiPaymentsControllerHandlePaypalSuccessRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public paymentsControllerHandlePaypalSuccess(requestParameters: PaymentsApiPaymentsControllerHandlePaypalSuccessRequest, options?: any) {
        return PaymentsApiFp(this.configuration).paymentsControllerHandlePaypalSuccess(requestParameters.email, requestParameters.bookingRef, requestParameters.flow, requestParameters.timeZone, requestParameters.paymentId, requestParameters.token, requestParameters.payerID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PaymentsApiPaymentsControllerHandleStripeMessageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public paymentsControllerHandleStripeMessage(requestParameters: PaymentsApiPaymentsControllerHandleStripeMessageRequest, options?: any) {
        return PaymentsApiFp(this.configuration).paymentsControllerHandleStripeMessage(requestParameters.stripeSignature, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PaymentsApiPaymentsControllerPublicAbortPaymentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public paymentsControllerPublicAbortPayment(requestParameters: PaymentsApiPaymentsControllerPublicAbortPaymentRequest, options?: any) {
        return PaymentsApiFp(this.configuration).paymentsControllerPublicAbortPayment(requestParameters.email, requestParameters.bookingRef, requestParameters.flow, requestParameters.timeZone, requestParameters.paymentId, requestParameters.token, requestParameters.payerID, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaypalApi - axios parameter creator
 * @export
 */
export const PaypalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} email 
         * @param {string} bookingRef 
         * @param {PaymentFlow} [flow] 
         * @param {string} [timeZone] 
         * @param {string} [paymentId] 
         * @param {string} [token] 
         * @param {string} [payerID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerCreatePayPalPayment: async (email: string, bookingRef: string, flow?: PaymentFlow, timeZone?: string, paymentId?: string, token?: string, payerID?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('paymentsControllerCreatePayPalPayment', 'email', email)
            // verify required parameter 'bookingRef' is not null or undefined
            assertParamExists('paymentsControllerCreatePayPalPayment', 'bookingRef', bookingRef)
            const localVarPath = `/api/v1/payments/paypal/payment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (bookingRef !== undefined) {
                localVarQueryParameter['bookingRef'] = bookingRef;
            }

            if (flow !== undefined) {
                localVarQueryParameter['flow'] = flow;
            }

            if (timeZone !== undefined) {
                localVarQueryParameter['timeZone'] = timeZone;
            }

            if (paymentId !== undefined) {
                localVarQueryParameter['paymentId'] = paymentId;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (payerID !== undefined) {
                localVarQueryParameter['PayerID'] = payerID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {string} bookingRef 
         * @param {PaymentFlow} [flow] 
         * @param {string} [timeZone] 
         * @param {string} [paymentId] 
         * @param {string} [token] 
         * @param {string} [payerID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerHandlePaypalError: async (email: string, bookingRef: string, flow?: PaymentFlow, timeZone?: string, paymentId?: string, token?: string, payerID?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('paymentsControllerHandlePaypalError', 'email', email)
            // verify required parameter 'bookingRef' is not null or undefined
            assertParamExists('paymentsControllerHandlePaypalError', 'bookingRef', bookingRef)
            const localVarPath = `/api/v1/payments/paypal/error`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (bookingRef !== undefined) {
                localVarQueryParameter['bookingRef'] = bookingRef;
            }

            if (flow !== undefined) {
                localVarQueryParameter['flow'] = flow;
            }

            if (timeZone !== undefined) {
                localVarQueryParameter['timeZone'] = timeZone;
            }

            if (paymentId !== undefined) {
                localVarQueryParameter['paymentId'] = paymentId;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (payerID !== undefined) {
                localVarQueryParameter['PayerID'] = payerID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {string} bookingRef 
         * @param {PaymentFlow} [flow] 
         * @param {string} [timeZone] 
         * @param {string} [paymentId] 
         * @param {string} [token] 
         * @param {string} [payerID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerHandlePaypalSuccess: async (email: string, bookingRef: string, flow?: PaymentFlow, timeZone?: string, paymentId?: string, token?: string, payerID?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('paymentsControllerHandlePaypalSuccess', 'email', email)
            // verify required parameter 'bookingRef' is not null or undefined
            assertParamExists('paymentsControllerHandlePaypalSuccess', 'bookingRef', bookingRef)
            const localVarPath = `/api/v1/payments/paypal/success`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (bookingRef !== undefined) {
                localVarQueryParameter['bookingRef'] = bookingRef;
            }

            if (flow !== undefined) {
                localVarQueryParameter['flow'] = flow;
            }

            if (timeZone !== undefined) {
                localVarQueryParameter['timeZone'] = timeZone;
            }

            if (paymentId !== undefined) {
                localVarQueryParameter['paymentId'] = paymentId;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (payerID !== undefined) {
                localVarQueryParameter['PayerID'] = payerID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaypalApi - functional programming interface
 * @export
 */
export const PaypalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaypalApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} email 
         * @param {string} bookingRef 
         * @param {PaymentFlow} [flow] 
         * @param {string} [timeZone] 
         * @param {string} [paymentId] 
         * @param {string} [token] 
         * @param {string} [payerID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsControllerCreatePayPalPayment(email: string, bookingRef: string, flow?: PaymentFlow, timeZone?: string, paymentId?: string, token?: string, payerID?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsControllerCreatePayPalPayment(email, bookingRef, flow, timeZone, paymentId, token, payerID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} email 
         * @param {string} bookingRef 
         * @param {PaymentFlow} [flow] 
         * @param {string} [timeZone] 
         * @param {string} [paymentId] 
         * @param {string} [token] 
         * @param {string} [payerID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsControllerHandlePaypalError(email: string, bookingRef: string, flow?: PaymentFlow, timeZone?: string, paymentId?: string, token?: string, payerID?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsControllerHandlePaypalError(email, bookingRef, flow, timeZone, paymentId, token, payerID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} email 
         * @param {string} bookingRef 
         * @param {PaymentFlow} [flow] 
         * @param {string} [timeZone] 
         * @param {string} [paymentId] 
         * @param {string} [token] 
         * @param {string} [payerID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsControllerHandlePaypalSuccess(email: string, bookingRef: string, flow?: PaymentFlow, timeZone?: string, paymentId?: string, token?: string, payerID?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsControllerHandlePaypalSuccess(email, bookingRef, flow, timeZone, paymentId, token, payerID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaypalApi - factory interface
 * @export
 */
export const PaypalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaypalApiFp(configuration)
    return {
        /**
         * 
         * @param {string} email 
         * @param {string} bookingRef 
         * @param {PaymentFlow} [flow] 
         * @param {string} [timeZone] 
         * @param {string} [paymentId] 
         * @param {string} [token] 
         * @param {string} [payerID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerCreatePayPalPayment(email: string, bookingRef: string, flow?: PaymentFlow, timeZone?: string, paymentId?: string, token?: string, payerID?: string, options?: any): AxiosPromise<PaymentsResponseDto> {
            return localVarFp.paymentsControllerCreatePayPalPayment(email, bookingRef, flow, timeZone, paymentId, token, payerID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {string} bookingRef 
         * @param {PaymentFlow} [flow] 
         * @param {string} [timeZone] 
         * @param {string} [paymentId] 
         * @param {string} [token] 
         * @param {string} [payerID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerHandlePaypalError(email: string, bookingRef: string, flow?: PaymentFlow, timeZone?: string, paymentId?: string, token?: string, payerID?: string, options?: any): AxiosPromise<void> {
            return localVarFp.paymentsControllerHandlePaypalError(email, bookingRef, flow, timeZone, paymentId, token, payerID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {string} bookingRef 
         * @param {PaymentFlow} [flow] 
         * @param {string} [timeZone] 
         * @param {string} [paymentId] 
         * @param {string} [token] 
         * @param {string} [payerID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerHandlePaypalSuccess(email: string, bookingRef: string, flow?: PaymentFlow, timeZone?: string, paymentId?: string, token?: string, payerID?: string, options?: any): AxiosPromise<void> {
            return localVarFp.paymentsControllerHandlePaypalSuccess(email, bookingRef, flow, timeZone, paymentId, token, payerID, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for paymentsControllerCreatePayPalPayment operation in PaypalApi.
 * @export
 * @interface PaypalApiPaymentsControllerCreatePayPalPaymentRequest
 */
export interface PaypalApiPaymentsControllerCreatePayPalPaymentRequest {
    /**
     * 
     * @type {string}
     * @memberof PaypalApiPaymentsControllerCreatePayPalPayment
     */
    readonly email: string

    /**
     * 
     * @type {string}
     * @memberof PaypalApiPaymentsControllerCreatePayPalPayment
     */
    readonly bookingRef: string

    /**
     * 
     * @type {PaymentFlow}
     * @memberof PaypalApiPaymentsControllerCreatePayPalPayment
     */
    readonly flow?: PaymentFlow

    /**
     * 
     * @type {string}
     * @memberof PaypalApiPaymentsControllerCreatePayPalPayment
     */
    readonly timeZone?: string

    /**
     * 
     * @type {string}
     * @memberof PaypalApiPaymentsControllerCreatePayPalPayment
     */
    readonly paymentId?: string

    /**
     * 
     * @type {string}
     * @memberof PaypalApiPaymentsControllerCreatePayPalPayment
     */
    readonly token?: string

    /**
     * 
     * @type {string}
     * @memberof PaypalApiPaymentsControllerCreatePayPalPayment
     */
    readonly payerID?: string
}

/**
 * Request parameters for paymentsControllerHandlePaypalError operation in PaypalApi.
 * @export
 * @interface PaypalApiPaymentsControllerHandlePaypalErrorRequest
 */
export interface PaypalApiPaymentsControllerHandlePaypalErrorRequest {
    /**
     * 
     * @type {string}
     * @memberof PaypalApiPaymentsControllerHandlePaypalError
     */
    readonly email: string

    /**
     * 
     * @type {string}
     * @memberof PaypalApiPaymentsControllerHandlePaypalError
     */
    readonly bookingRef: string

    /**
     * 
     * @type {PaymentFlow}
     * @memberof PaypalApiPaymentsControllerHandlePaypalError
     */
    readonly flow?: PaymentFlow

    /**
     * 
     * @type {string}
     * @memberof PaypalApiPaymentsControllerHandlePaypalError
     */
    readonly timeZone?: string

    /**
     * 
     * @type {string}
     * @memberof PaypalApiPaymentsControllerHandlePaypalError
     */
    readonly paymentId?: string

    /**
     * 
     * @type {string}
     * @memberof PaypalApiPaymentsControllerHandlePaypalError
     */
    readonly token?: string

    /**
     * 
     * @type {string}
     * @memberof PaypalApiPaymentsControllerHandlePaypalError
     */
    readonly payerID?: string
}

/**
 * Request parameters for paymentsControllerHandlePaypalSuccess operation in PaypalApi.
 * @export
 * @interface PaypalApiPaymentsControllerHandlePaypalSuccessRequest
 */
export interface PaypalApiPaymentsControllerHandlePaypalSuccessRequest {
    /**
     * 
     * @type {string}
     * @memberof PaypalApiPaymentsControllerHandlePaypalSuccess
     */
    readonly email: string

    /**
     * 
     * @type {string}
     * @memberof PaypalApiPaymentsControllerHandlePaypalSuccess
     */
    readonly bookingRef: string

    /**
     * 
     * @type {PaymentFlow}
     * @memberof PaypalApiPaymentsControllerHandlePaypalSuccess
     */
    readonly flow?: PaymentFlow

    /**
     * 
     * @type {string}
     * @memberof PaypalApiPaymentsControllerHandlePaypalSuccess
     */
    readonly timeZone?: string

    /**
     * 
     * @type {string}
     * @memberof PaypalApiPaymentsControllerHandlePaypalSuccess
     */
    readonly paymentId?: string

    /**
     * 
     * @type {string}
     * @memberof PaypalApiPaymentsControllerHandlePaypalSuccess
     */
    readonly token?: string

    /**
     * 
     * @type {string}
     * @memberof PaypalApiPaymentsControllerHandlePaypalSuccess
     */
    readonly payerID?: string
}

/**
 * PaypalApi - object-oriented interface
 * @export
 * @class PaypalApi
 * @extends {BaseAPI}
 */
export class PaypalApi extends BaseAPI {
    /**
     * 
     * @param {PaypalApiPaymentsControllerCreatePayPalPaymentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaypalApi
     */
    public paymentsControllerCreatePayPalPayment(requestParameters: PaypalApiPaymentsControllerCreatePayPalPaymentRequest, options?: any) {
        return PaypalApiFp(this.configuration).paymentsControllerCreatePayPalPayment(requestParameters.email, requestParameters.bookingRef, requestParameters.flow, requestParameters.timeZone, requestParameters.paymentId, requestParameters.token, requestParameters.payerID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PaypalApiPaymentsControllerHandlePaypalErrorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaypalApi
     */
    public paymentsControllerHandlePaypalError(requestParameters: PaypalApiPaymentsControllerHandlePaypalErrorRequest, options?: any) {
        return PaypalApiFp(this.configuration).paymentsControllerHandlePaypalError(requestParameters.email, requestParameters.bookingRef, requestParameters.flow, requestParameters.timeZone, requestParameters.paymentId, requestParameters.token, requestParameters.payerID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PaypalApiPaymentsControllerHandlePaypalSuccessRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaypalApi
     */
    public paymentsControllerHandlePaypalSuccess(requestParameters: PaypalApiPaymentsControllerHandlePaypalSuccessRequest, options?: any) {
        return PaypalApiFp(this.configuration).paymentsControllerHandlePaypalSuccess(requestParameters.email, requestParameters.bookingRef, requestParameters.flow, requestParameters.timeZone, requestParameters.paymentId, requestParameters.token, requestParameters.payerID, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PricesApi - axios parameter creator
 * @export
 */
export const PricesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} start 
         * @param {string} end 
         * @param {string} appVersion 
         * @param {number} [sectionId] 
         * @param {number} [rowId] 
         * @param {string} [model] 
         * @param {string} [publicReference] 
         * @param {boolean} [isPublic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pricesControllerCalculatePrice: async (start: string, end: string, appVersion: string, sectionId?: number, rowId?: number, model?: string, publicReference?: string, isPublic?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'start' is not null or undefined
            assertParamExists('pricesControllerCalculatePrice', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('pricesControllerCalculatePrice', 'end', end)
            // verify required parameter 'appVersion' is not null or undefined
            assertParamExists('pricesControllerCalculatePrice', 'appVersion', appVersion)
            const localVarPath = `/api/v1/prices/calculate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sectionId !== undefined) {
                localVarQueryParameter['sectionId'] = sectionId;
            }

            if (rowId !== undefined) {
                localVarQueryParameter['rowId'] = rowId;
            }

            if (model !== undefined) {
                localVarQueryParameter['model'] = model;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }

            if (publicReference !== undefined) {
                localVarQueryParameter['publicReference'] = publicReference;
            }

            if (isPublic !== undefined) {
                localVarQueryParameter['isPublic'] = isPublic;
            }

            if (appVersion !== undefined && appVersion !== null) {
                localVarHeaderParameter['appVersion'] = String(appVersion);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreatePriceDto} createPriceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pricesControllerCreate: async (createPriceDto: CreatePriceDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPriceDto' is not null or undefined
            assertParamExists('pricesControllerCreate', 'createPriceDto', createPriceDto)
            const localVarPath = `/api/v1/vendor/prices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPriceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pricesControllerDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('pricesControllerDelete', 'id', id)
            const localVarPath = `/api/v1/vendor/prices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pricesControllerFindMany: async (page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, csv?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/vendor/prices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['orderDir'] = orderDir;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (filterBy !== undefined) {
                localVarQueryParameter['filterBy'] = filterBy;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (csv !== undefined) {
                localVarQueryParameter['csv'] = csv;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pricesControllerFindOne: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('pricesControllerFindOne', 'id', id)
            const localVarPath = `/api/v1/vendor/prices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} start 
         * @param {string} end 
         * @param {number} [sectionId] 
         * @param {number} [rowId] 
         * @param {string} [model] 
         * @param {string} [publicReference] 
         * @param {boolean} [isPublic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pricesControllerGetRates: async (start: string, end: string, sectionId?: number, rowId?: number, model?: string, publicReference?: string, isPublic?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'start' is not null or undefined
            assertParamExists('pricesControllerGetRates', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('pricesControllerGetRates', 'end', end)
            const localVarPath = `/api/v1/prices/rates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sectionId !== undefined) {
                localVarQueryParameter['sectionId'] = sectionId;
            }

            if (rowId !== undefined) {
                localVarQueryParameter['rowId'] = rowId;
            }

            if (model !== undefined) {
                localVarQueryParameter['model'] = model;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }

            if (publicReference !== undefined) {
                localVarQueryParameter['publicReference'] = publicReference;
            }

            if (isPublic !== undefined) {
                localVarQueryParameter['isPublic'] = isPublic;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdatePriceDto} updatePriceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pricesControllerUpdate: async (id: number, updatePriceDto: UpdatePriceDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('pricesControllerUpdate', 'id', id)
            // verify required parameter 'updatePriceDto' is not null or undefined
            assertParamExists('pricesControllerUpdate', 'updatePriceDto', updatePriceDto)
            const localVarPath = `/api/v1/vendor/prices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePriceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PricesApi - functional programming interface
 * @export
 */
export const PricesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PricesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} start 
         * @param {string} end 
         * @param {string} appVersion 
         * @param {number} [sectionId] 
         * @param {number} [rowId] 
         * @param {string} [model] 
         * @param {string} [publicReference] 
         * @param {boolean} [isPublic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pricesControllerCalculatePrice(start: string, end: string, appVersion: string, sectionId?: number, rowId?: number, model?: string, publicReference?: string, isPublic?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PriceDetailsResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pricesControllerCalculatePrice(start, end, appVersion, sectionId, rowId, model, publicReference, isPublic, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreatePriceDto} createPriceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pricesControllerCreate(createPriceDto: CreatePriceDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsePriceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pricesControllerCreate(createPriceDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pricesControllerDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pricesControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pricesControllerFindMany(page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, csv?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPricesChunkResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pricesControllerFindMany(page, itemsPerPage, orderBy, orderDir, search, filterBy, filter, csv, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pricesControllerFindOne(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsePriceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pricesControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} start 
         * @param {string} end 
         * @param {number} [sectionId] 
         * @param {number} [rowId] 
         * @param {string} [model] 
         * @param {string} [publicReference] 
         * @param {boolean} [isPublic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pricesControllerGetRates(start: string, end: string, sectionId?: number, rowId?: number, model?: string, publicReference?: string, isPublic?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RatesQueryResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pricesControllerGetRates(start, end, sectionId, rowId, model, publicReference, isPublic, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdatePriceDto} updatePriceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pricesControllerUpdate(id: number, updatePriceDto: UpdatePriceDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pricesControllerUpdate(id, updatePriceDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PricesApi - factory interface
 * @export
 */
export const PricesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PricesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} start 
         * @param {string} end 
         * @param {string} appVersion 
         * @param {number} [sectionId] 
         * @param {number} [rowId] 
         * @param {string} [model] 
         * @param {string} [publicReference] 
         * @param {boolean} [isPublic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pricesControllerCalculatePrice(start: string, end: string, appVersion: string, sectionId?: number, rowId?: number, model?: string, publicReference?: string, isPublic?: boolean, options?: any): AxiosPromise<Array<PriceDetailsResponseDto>> {
            return localVarFp.pricesControllerCalculatePrice(start, end, appVersion, sectionId, rowId, model, publicReference, isPublic, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreatePriceDto} createPriceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pricesControllerCreate(createPriceDto: CreatePriceDto, options?: any): AxiosPromise<ResponsePriceDto> {
            return localVarFp.pricesControllerCreate(createPriceDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pricesControllerDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.pricesControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pricesControllerFindMany(page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, csv?: string, options?: any): AxiosPromise<GetPricesChunkResponseDto> {
            return localVarFp.pricesControllerFindMany(page, itemsPerPage, orderBy, orderDir, search, filterBy, filter, csv, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pricesControllerFindOne(id: number, options?: any): AxiosPromise<ResponsePriceDto> {
            return localVarFp.pricesControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} start 
         * @param {string} end 
         * @param {number} [sectionId] 
         * @param {number} [rowId] 
         * @param {string} [model] 
         * @param {string} [publicReference] 
         * @param {boolean} [isPublic] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pricesControllerGetRates(start: string, end: string, sectionId?: number, rowId?: number, model?: string, publicReference?: string, isPublic?: boolean, options?: any): AxiosPromise<Array<RatesQueryResponse>> {
            return localVarFp.pricesControllerGetRates(start, end, sectionId, rowId, model, publicReference, isPublic, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdatePriceDto} updatePriceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pricesControllerUpdate(id: number, updatePriceDto: UpdatePriceDto, options?: any): AxiosPromise<void> {
            return localVarFp.pricesControllerUpdate(id, updatePriceDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for pricesControllerCalculatePrice operation in PricesApi.
 * @export
 * @interface PricesApiPricesControllerCalculatePriceRequest
 */
export interface PricesApiPricesControllerCalculatePriceRequest {
    /**
     * 
     * @type {string}
     * @memberof PricesApiPricesControllerCalculatePrice
     */
    readonly start: string

    /**
     * 
     * @type {string}
     * @memberof PricesApiPricesControllerCalculatePrice
     */
    readonly end: string

    /**
     * 
     * @type {string}
     * @memberof PricesApiPricesControllerCalculatePrice
     */
    readonly appVersion: string

    /**
     * 
     * @type {number}
     * @memberof PricesApiPricesControllerCalculatePrice
     */
    readonly sectionId?: number

    /**
     * 
     * @type {number}
     * @memberof PricesApiPricesControllerCalculatePrice
     */
    readonly rowId?: number

    /**
     * 
     * @type {string}
     * @memberof PricesApiPricesControllerCalculatePrice
     */
    readonly model?: string

    /**
     * 
     * @type {string}
     * @memberof PricesApiPricesControllerCalculatePrice
     */
    readonly publicReference?: string

    /**
     * 
     * @type {boolean}
     * @memberof PricesApiPricesControllerCalculatePrice
     */
    readonly isPublic?: boolean
}

/**
 * Request parameters for pricesControllerCreate operation in PricesApi.
 * @export
 * @interface PricesApiPricesControllerCreateRequest
 */
export interface PricesApiPricesControllerCreateRequest {
    /**
     * 
     * @type {CreatePriceDto}
     * @memberof PricesApiPricesControllerCreate
     */
    readonly createPriceDto: CreatePriceDto
}

/**
 * Request parameters for pricesControllerDelete operation in PricesApi.
 * @export
 * @interface PricesApiPricesControllerDeleteRequest
 */
export interface PricesApiPricesControllerDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof PricesApiPricesControllerDelete
     */
    readonly id: number
}

/**
 * Request parameters for pricesControllerFindMany operation in PricesApi.
 * @export
 * @interface PricesApiPricesControllerFindManyRequest
 */
export interface PricesApiPricesControllerFindManyRequest {
    /**
     * Number of the page to fetch
     * @type {number}
     * @memberof PricesApiPricesControllerFindMany
     */
    readonly page?: number

    /**
     * Number of items per page
     * @type {number}
     * @memberof PricesApiPricesControllerFindMany
     */
    readonly itemsPerPage?: number

    /**
     * Name of the property to order by
     * @type {string}
     * @memberof PricesApiPricesControllerFindMany
     */
    readonly orderBy?: string

    /**
     * Value which specify direction of the order
     * @type {OrderByDirection}
     * @memberof PricesApiPricesControllerFindMany
     */
    readonly orderDir?: OrderByDirection

    /**
     * Text which will be used to search through table data
     * @type {string}
     * @memberof PricesApiPricesControllerFindMany
     */
    readonly search?: string

    /**
     * Name of the property to filter by
     * @type {string}
     * @memberof PricesApiPricesControllerFindMany
     */
    readonly filterBy?: string

    /**
     * Value which will be used to filter table data
     * @type {string}
     * @memberof PricesApiPricesControllerFindMany
     */
    readonly filter?: string

    /**
     * 
     * @type {string}
     * @memberof PricesApiPricesControllerFindMany
     */
    readonly csv?: string
}

/**
 * Request parameters for pricesControllerFindOne operation in PricesApi.
 * @export
 * @interface PricesApiPricesControllerFindOneRequest
 */
export interface PricesApiPricesControllerFindOneRequest {
    /**
     * 
     * @type {number}
     * @memberof PricesApiPricesControllerFindOne
     */
    readonly id: number
}

/**
 * Request parameters for pricesControllerGetRates operation in PricesApi.
 * @export
 * @interface PricesApiPricesControllerGetRatesRequest
 */
export interface PricesApiPricesControllerGetRatesRequest {
    /**
     * 
     * @type {string}
     * @memberof PricesApiPricesControllerGetRates
     */
    readonly start: string

    /**
     * 
     * @type {string}
     * @memberof PricesApiPricesControllerGetRates
     */
    readonly end: string

    /**
     * 
     * @type {number}
     * @memberof PricesApiPricesControllerGetRates
     */
    readonly sectionId?: number

    /**
     * 
     * @type {number}
     * @memberof PricesApiPricesControllerGetRates
     */
    readonly rowId?: number

    /**
     * 
     * @type {string}
     * @memberof PricesApiPricesControllerGetRates
     */
    readonly model?: string

    /**
     * 
     * @type {string}
     * @memberof PricesApiPricesControllerGetRates
     */
    readonly publicReference?: string

    /**
     * 
     * @type {boolean}
     * @memberof PricesApiPricesControllerGetRates
     */
    readonly isPublic?: boolean
}

/**
 * Request parameters for pricesControllerUpdate operation in PricesApi.
 * @export
 * @interface PricesApiPricesControllerUpdateRequest
 */
export interface PricesApiPricesControllerUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof PricesApiPricesControllerUpdate
     */
    readonly id: number

    /**
     * 
     * @type {UpdatePriceDto}
     * @memberof PricesApiPricesControllerUpdate
     */
    readonly updatePriceDto: UpdatePriceDto
}

/**
 * PricesApi - object-oriented interface
 * @export
 * @class PricesApi
 * @extends {BaseAPI}
 */
export class PricesApi extends BaseAPI {
    /**
     * 
     * @param {PricesApiPricesControllerCalculatePriceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PricesApi
     */
    public pricesControllerCalculatePrice(requestParameters: PricesApiPricesControllerCalculatePriceRequest, options?: any) {
        return PricesApiFp(this.configuration).pricesControllerCalculatePrice(requestParameters.start, requestParameters.end, requestParameters.appVersion, requestParameters.sectionId, requestParameters.rowId, requestParameters.model, requestParameters.publicReference, requestParameters.isPublic, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PricesApiPricesControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PricesApi
     */
    public pricesControllerCreate(requestParameters: PricesApiPricesControllerCreateRequest, options?: any) {
        return PricesApiFp(this.configuration).pricesControllerCreate(requestParameters.createPriceDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PricesApiPricesControllerDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PricesApi
     */
    public pricesControllerDelete(requestParameters: PricesApiPricesControllerDeleteRequest, options?: any) {
        return PricesApiFp(this.configuration).pricesControllerDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PricesApiPricesControllerFindManyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PricesApi
     */
    public pricesControllerFindMany(requestParameters: PricesApiPricesControllerFindManyRequest = {}, options?: any) {
        return PricesApiFp(this.configuration).pricesControllerFindMany(requestParameters.page, requestParameters.itemsPerPage, requestParameters.orderBy, requestParameters.orderDir, requestParameters.search, requestParameters.filterBy, requestParameters.filter, requestParameters.csv, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PricesApiPricesControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PricesApi
     */
    public pricesControllerFindOne(requestParameters: PricesApiPricesControllerFindOneRequest, options?: any) {
        return PricesApiFp(this.configuration).pricesControllerFindOne(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PricesApiPricesControllerGetRatesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PricesApi
     */
    public pricesControllerGetRates(requestParameters: PricesApiPricesControllerGetRatesRequest, options?: any) {
        return PricesApiFp(this.configuration).pricesControllerGetRates(requestParameters.start, requestParameters.end, requestParameters.sectionId, requestParameters.rowId, requestParameters.model, requestParameters.publicReference, requestParameters.isPublic, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PricesApiPricesControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PricesApi
     */
    public pricesControllerUpdate(requestParameters: PricesApiPricesControllerUpdateRequest, options?: any) {
        return PricesApiFp(this.configuration).pricesControllerUpdate(requestParameters.id, requestParameters.updatePriceDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PublicApi - axios parameter creator
 * @export
 */
export const PublicApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} bookingRef 
         * @param {string} publicReference 
         * @param {PublicCreateBeachChairBookingBodyDto} publicCreateBeachChairBookingBodyDto 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerAddBeachChairBookingToBooking: async (bookingRef: string, publicReference: string, publicCreateBeachChairBookingBodyDto: PublicCreateBeachChairBookingBodyDto, timeZone?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingRef' is not null or undefined
            assertParamExists('publicControllerAddBeachChairBookingToBooking', 'bookingRef', bookingRef)
            // verify required parameter 'publicReference' is not null or undefined
            assertParamExists('publicControllerAddBeachChairBookingToBooking', 'publicReference', publicReference)
            // verify required parameter 'publicCreateBeachChairBookingBodyDto' is not null or undefined
            assertParamExists('publicControllerAddBeachChairBookingToBooking', 'publicCreateBeachChairBookingBodyDto', publicCreateBeachChairBookingBodyDto)
            const localVarPath = `/api/v1/features/public/booking/{bookingRef}/beach_chair_bookings`
                .replace(`{${"bookingRef"}}`, encodeURIComponent(String(bookingRef)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (publicReference !== undefined) {
                localVarQueryParameter['publicReference'] = publicReference;
            }

            if (timeZone !== undefined) {
                localVarQueryParameter['timeZone'] = timeZone;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(publicCreateBeachChairBookingBodyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} bookingReference 
         * @param {string} publicReference 
         * @param {CreateExtrasBookingBulkDto} createExtrasBookingBulkDto 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerAddExtras: async (bookingReference: string, publicReference: string, createExtrasBookingBulkDto: CreateExtrasBookingBulkDto, timeZone?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingReference' is not null or undefined
            assertParamExists('publicControllerAddExtras', 'bookingReference', bookingReference)
            // verify required parameter 'publicReference' is not null or undefined
            assertParamExists('publicControllerAddExtras', 'publicReference', publicReference)
            // verify required parameter 'createExtrasBookingBulkDto' is not null or undefined
            assertParamExists('publicControllerAddExtras', 'createExtrasBookingBulkDto', createExtrasBookingBulkDto)
            const localVarPath = `/api/v1/features/public/{bookingReference}/extras`
                .replace(`{${"bookingReference"}}`, encodeURIComponent(String(bookingReference)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (publicReference !== undefined) {
                localVarQueryParameter['publicReference'] = publicReference;
            }

            if (timeZone !== undefined) {
                localVarQueryParameter['timeZone'] = timeZone;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createExtrasBookingBulkDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {string} bookingRef 
         * @param {PublicUpdatePaymentMethod} publicUpdatePaymentMethod 
         * @param {PaymentFlow} [flow] 
         * @param {string} [timeZone] 
         * @param {string} [paymentId] 
         * @param {string} [token] 
         * @param {string} [payerID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerChangePaymentMethod: async (email: string, bookingRef: string, publicUpdatePaymentMethod: PublicUpdatePaymentMethod, flow?: PaymentFlow, timeZone?: string, paymentId?: string, token?: string, payerID?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('publicControllerChangePaymentMethod', 'email', email)
            // verify required parameter 'bookingRef' is not null or undefined
            assertParamExists('publicControllerChangePaymentMethod', 'bookingRef', bookingRef)
            // verify required parameter 'publicUpdatePaymentMethod' is not null or undefined
            assertParamExists('publicControllerChangePaymentMethod', 'publicUpdatePaymentMethod', publicUpdatePaymentMethod)
            const localVarPath = `/api/v1/features/public/booking`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (bookingRef !== undefined) {
                localVarQueryParameter['bookingRef'] = bookingRef;
            }

            if (flow !== undefined) {
                localVarQueryParameter['flow'] = flow;
            }

            if (timeZone !== undefined) {
                localVarQueryParameter['timeZone'] = timeZone;
            }

            if (paymentId !== undefined) {
                localVarQueryParameter['paymentId'] = paymentId;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (payerID !== undefined) {
                localVarQueryParameter['PayerID'] = payerID;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(publicUpdatePaymentMethod, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} beachChairId 
         * @param {string} publicReference 
         * @param {string} start 
         * @param {string} end 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerCheckBeachChairAvailability: async (beachChairId: number, publicReference: string, start: string, end: string, timeZone?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'beachChairId' is not null or undefined
            assertParamExists('publicControllerCheckBeachChairAvailability', 'beachChairId', beachChairId)
            // verify required parameter 'publicReference' is not null or undefined
            assertParamExists('publicControllerCheckBeachChairAvailability', 'publicReference', publicReference)
            // verify required parameter 'start' is not null or undefined
            assertParamExists('publicControllerCheckBeachChairAvailability', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('publicControllerCheckBeachChairAvailability', 'end', end)
            const localVarPath = `/api/v1/features/public/booking/beach_chair/{beachChairId}/available`
                .replace(`{${"beachChairId"}}`, encodeURIComponent(String(beachChairId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (publicReference !== undefined) {
                localVarQueryParameter['publicReference'] = publicReference;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }

            if (timeZone !== undefined) {
                localVarQueryParameter['timeZone'] = timeZone;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {string} publicReference 
         * @param {string} bookingReference 
         * @param {string} skjApiKey SKJ api key used to authenticate the request
         * @param {string} [bookingRef] 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerConfirmReservation: async (email: string, publicReference: string, bookingReference: string, skjApiKey: string, bookingRef?: string, timeZone?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('publicControllerConfirmReservation', 'email', email)
            // verify required parameter 'publicReference' is not null or undefined
            assertParamExists('publicControllerConfirmReservation', 'publicReference', publicReference)
            // verify required parameter 'bookingReference' is not null or undefined
            assertParamExists('publicControllerConfirmReservation', 'bookingReference', bookingReference)
            // verify required parameter 'skjApiKey' is not null or undefined
            assertParamExists('publicControllerConfirmReservation', 'skjApiKey', skjApiKey)
            const localVarPath = `/api/v1/features/public/reservation/{bookingReference}/confirm`
                .replace(`{${"bookingReference"}}`, encodeURIComponent(String(bookingReference)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (bookingRef !== undefined) {
                localVarQueryParameter['bookingRef'] = bookingRef;
            }

            if (timeZone !== undefined) {
                localVarQueryParameter['timeZone'] = timeZone;
            }

            if (publicReference !== undefined) {
                localVarQueryParameter['publicReference'] = publicReference;
            }

            if (skjApiKey !== undefined && skjApiKey !== null) {
                localVarHeaderParameter['skj-api-key'] = String(skjApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} bookingReference 
         * @param {string} publicReference 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerGetBookingStatus: async (bookingReference: string, publicReference: string, timeZone?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingReference' is not null or undefined
            assertParamExists('publicControllerGetBookingStatus', 'bookingReference', bookingReference)
            // verify required parameter 'publicReference' is not null or undefined
            assertParamExists('publicControllerGetBookingStatus', 'publicReference', publicReference)
            const localVarPath = `/api/v1/features/public/booking_status/{bookingReference}`
                .replace(`{${"bookingReference"}}`, encodeURIComponent(String(bookingReference)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (publicReference !== undefined) {
                localVarQueryParameter['publicReference'] = publicReference;
            }

            if (timeZone !== undefined) {
                localVarQueryParameter['timeZone'] = timeZone;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} publicReference 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerGetExtras: async (publicReference: string, page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, csv?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'publicReference' is not null or undefined
            assertParamExists('publicControllerGetExtras', 'publicReference', publicReference)
            const localVarPath = `/api/v1/features/public/extras`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['orderDir'] = orderDir;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (filterBy !== undefined) {
                localVarQueryParameter['filterBy'] = filterBy;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (csv !== undefined) {
                localVarQueryParameter['csv'] = csv;
            }

            if (publicReference !== undefined) {
                localVarQueryParameter['publicReference'] = publicReference;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} publicReference 
         * @param {string} skjApiKey SKJ api key used to authenticate the request
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerGetMobileConfig: async (publicReference: string, skjApiKey: string, timeZone?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'publicReference' is not null or undefined
            assertParamExists('publicControllerGetMobileConfig', 'publicReference', publicReference)
            // verify required parameter 'skjApiKey' is not null or undefined
            assertParamExists('publicControllerGetMobileConfig', 'skjApiKey', skjApiKey)
            const localVarPath = `/api/v1/features/public/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (publicReference !== undefined) {
                localVarQueryParameter['publicReference'] = publicReference;
            }

            if (timeZone !== undefined) {
                localVarQueryParameter['timeZone'] = timeZone;
            }

            if (skjApiKey !== undefined && skjApiKey !== null) {
                localVarHeaderParameter['skj-api-key'] = String(skjApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} publicReference 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerGetPublicChairsFilters: async (publicReference: string, timeZone?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'publicReference' is not null or undefined
            assertParamExists('publicControllerGetPublicChairsFilters', 'publicReference', publicReference)
            const localVarPath = `/api/v1/features/public/filters/beach_chairs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (publicReference !== undefined) {
                localVarQueryParameter['publicReference'] = publicReference;
            }

            if (timeZone !== undefined) {
                localVarQueryParameter['timeZone'] = timeZone;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} start 
         * @param {string} publicReference 
         * @param {string} [end] 
         * @param {number} [row] Id of the row which to beach chair is assigned
         * @param {BeachChairModel} [model] 
         * @param {boolean} [free] 
         * @param {string} [timeZone] 
         * @param {boolean} [lock] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerGetPublicSectionsBeachChairs: async (id: number, start: string, publicReference: string, end?: string, row?: number, model?: BeachChairModel, free?: boolean, timeZone?: string, lock?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('publicControllerGetPublicSectionsBeachChairs', 'id', id)
            // verify required parameter 'start' is not null or undefined
            assertParamExists('publicControllerGetPublicSectionsBeachChairs', 'start', start)
            // verify required parameter 'publicReference' is not null or undefined
            assertParamExists('publicControllerGetPublicSectionsBeachChairs', 'publicReference', publicReference)
            const localVarPath = `/api/v1/features/public/sections/{id}/beach_chairs_availability`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            if (row !== undefined) {
                localVarQueryParameter['row'] = row;
            }

            if (model !== undefined) {
                localVarQueryParameter['model'] = model;
            }

            if (free !== undefined) {
                localVarQueryParameter['free'] = free;
            }

            if (timeZone !== undefined) {
                localVarQueryParameter['timeZone'] = timeZone;
            }

            if (lock !== undefined) {
                localVarQueryParameter['lock'] = lock;
            }

            if (publicReference !== undefined) {
                localVarQueryParameter['publicReference'] = publicReference;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} publicReference 
         * @param {string} start 
         * @param {string} end 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerGetPublicSectionsCalendar: async (id: number, publicReference: string, start: string, end: string, timeZone?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('publicControllerGetPublicSectionsCalendar', 'id', id)
            // verify required parameter 'publicReference' is not null or undefined
            assertParamExists('publicControllerGetPublicSectionsCalendar', 'publicReference', publicReference)
            // verify required parameter 'start' is not null or undefined
            assertParamExists('publicControllerGetPublicSectionsCalendar', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('publicControllerGetPublicSectionsCalendar', 'end', end)
            const localVarPath = `/api/v1/features/public/sections/{id}/calendar_availability`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (publicReference !== undefined) {
                localVarQueryParameter['publicReference'] = publicReference;
            }

            if (timeZone !== undefined) {
                localVarQueryParameter['timeZone'] = timeZone;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {string} bookingRef 
         * @param {PaymentFlow} [flow] 
         * @param {string} [timeZone] 
         * @param {string} [paymentId] 
         * @param {string} [token] 
         * @param {string} [payerID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerPublicAbortPayment: async (email: string, bookingRef: string, flow?: PaymentFlow, timeZone?: string, paymentId?: string, token?: string, payerID?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('publicControllerPublicAbortPayment', 'email', email)
            // verify required parameter 'bookingRef' is not null or undefined
            assertParamExists('publicControllerPublicAbortPayment', 'bookingRef', bookingRef)
            const localVarPath = `/api/v1/features/public/booking/abort`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (bookingRef !== undefined) {
                localVarQueryParameter['bookingRef'] = bookingRef;
            }

            if (flow !== undefined) {
                localVarQueryParameter['flow'] = flow;
            }

            if (timeZone !== undefined) {
                localVarQueryParameter['timeZone'] = timeZone;
            }

            if (paymentId !== undefined) {
                localVarQueryParameter['paymentId'] = paymentId;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (payerID !== undefined) {
                localVarQueryParameter['PayerID'] = payerID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} bookingRef 
         * @param {string} publicReference 
         * @param {PublicCreateCustomerBodyDto} publicCreateCustomerBodyDto 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerPublicAddCustomerToBooking: async (bookingRef: string, publicReference: string, publicCreateCustomerBodyDto: PublicCreateCustomerBodyDto, timeZone?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingRef' is not null or undefined
            assertParamExists('publicControllerPublicAddCustomerToBooking', 'bookingRef', bookingRef)
            // verify required parameter 'publicReference' is not null or undefined
            assertParamExists('publicControllerPublicAddCustomerToBooking', 'publicReference', publicReference)
            // verify required parameter 'publicCreateCustomerBodyDto' is not null or undefined
            assertParamExists('publicControllerPublicAddCustomerToBooking', 'publicCreateCustomerBodyDto', publicCreateCustomerBodyDto)
            const localVarPath = `/api/v1/features/public/booking/{bookingRef}/add_customer`
                .replace(`{${"bookingRef"}}`, encodeURIComponent(String(bookingRef)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (publicReference !== undefined) {
                localVarQueryParameter['publicReference'] = publicReference;
            }

            if (timeZone !== undefined) {
                localVarQueryParameter['timeZone'] = timeZone;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(publicCreateCustomerBodyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {string} bookingRef 
         * @param {PaymentFlow} [flow] 
         * @param {string} [timeZone] 
         * @param {string} [paymentId] 
         * @param {string} [token] 
         * @param {string} [payerID] 
         * @param {string} [skjApiKey] SKJ api key used to authenticate the request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerPublicBookingDetails: async (email: string, bookingRef: string, flow?: PaymentFlow, timeZone?: string, paymentId?: string, token?: string, payerID?: string, skjApiKey?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('publicControllerPublicBookingDetails', 'email', email)
            // verify required parameter 'bookingRef' is not null or undefined
            assertParamExists('publicControllerPublicBookingDetails', 'bookingRef', bookingRef)
            const localVarPath = `/api/v1/features/public/booking_details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (bookingRef !== undefined) {
                localVarQueryParameter['bookingRef'] = bookingRef;
            }

            if (flow !== undefined) {
                localVarQueryParameter['flow'] = flow;
            }

            if (timeZone !== undefined) {
                localVarQueryParameter['timeZone'] = timeZone;
            }

            if (paymentId !== undefined) {
                localVarQueryParameter['paymentId'] = paymentId;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (payerID !== undefined) {
                localVarQueryParameter['PayerID'] = payerID;
            }

            if (skjApiKey !== undefined && skjApiKey !== null) {
                localVarHeaderParameter['skj-api-key'] = String(skjApiKey);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {string} bookingRef 
         * @param {PaymentFlow} [flow] 
         * @param {string} [timeZone] 
         * @param {string} [paymentId] 
         * @param {string} [token] 
         * @param {string} [payerID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerPublicCancelBooking: async (email: string, bookingRef: string, flow?: PaymentFlow, timeZone?: string, paymentId?: string, token?: string, payerID?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('publicControllerPublicCancelBooking', 'email', email)
            // verify required parameter 'bookingRef' is not null or undefined
            assertParamExists('publicControllerPublicCancelBooking', 'bookingRef', bookingRef)
            const localVarPath = `/api/v1/features/public/booking/cancel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (bookingRef !== undefined) {
                localVarQueryParameter['bookingRef'] = bookingRef;
            }

            if (flow !== undefined) {
                localVarQueryParameter['flow'] = flow;
            }

            if (timeZone !== undefined) {
                localVarQueryParameter['timeZone'] = timeZone;
            }

            if (paymentId !== undefined) {
                localVarQueryParameter['paymentId'] = paymentId;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (payerID !== undefined) {
                localVarQueryParameter['PayerID'] = payerID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {string} bookingRef 
         * @param {PaymentFlow} [flow] 
         * @param {string} [timeZone] 
         * @param {string} [paymentId] 
         * @param {string} [token] 
         * @param {string} [payerID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerPublicConfirmBooking: async (email: string, bookingRef: string, flow?: PaymentFlow, timeZone?: string, paymentId?: string, token?: string, payerID?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('publicControllerPublicConfirmBooking', 'email', email)
            // verify required parameter 'bookingRef' is not null or undefined
            assertParamExists('publicControllerPublicConfirmBooking', 'bookingRef', bookingRef)
            const localVarPath = `/api/v1/features/public/booking/confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (bookingRef !== undefined) {
                localVarQueryParameter['bookingRef'] = bookingRef;
            }

            if (flow !== undefined) {
                localVarQueryParameter['flow'] = flow;
            }

            if (timeZone !== undefined) {
                localVarQueryParameter['timeZone'] = timeZone;
            }

            if (paymentId !== undefined) {
                localVarQueryParameter['paymentId'] = paymentId;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (payerID !== undefined) {
                localVarQueryParameter['PayerID'] = payerID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} publicReference 
         * @param {string} [timeZone] 
         * @param {string} [source] 
         * @param {string} [affiliateReference] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerPublicCreateBooking: async (publicReference: string, timeZone?: string, source?: string, affiliateReference?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'publicReference' is not null or undefined
            assertParamExists('publicControllerPublicCreateBooking', 'publicReference', publicReference)
            const localVarPath = `/api/v1/features/public/booking`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (publicReference !== undefined) {
                localVarQueryParameter['publicReference'] = publicReference;
            }

            if (timeZone !== undefined) {
                localVarQueryParameter['timeZone'] = timeZone;
            }

            if (source !== undefined) {
                localVarQueryParameter['source'] = source;
            }

            if (affiliateReference !== undefined) {
                localVarQueryParameter['affiliateReference'] = affiliateReference;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerPublicLockHealth: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('publicControllerPublicLockHealth', 'id', id)
            const localVarPath = `/api/v1/features/public/lock/{id}/health`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} publicReference 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerPublicVendor: async (publicReference: string, timeZone?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'publicReference' is not null or undefined
            assertParamExists('publicControllerPublicVendor', 'publicReference', publicReference)
            const localVarPath = `/api/v1/features/public/vendor`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (publicReference !== undefined) {
                localVarQueryParameter['publicReference'] = publicReference;
            }

            if (timeZone !== undefined) {
                localVarQueryParameter['timeZone'] = timeZone;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} bookingReference 
         * @param {number} bookingExtrasId 
         * @param {string} publicReference 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerRemoveExtrasFromBooking: async (bookingReference: string, bookingExtrasId: number, publicReference: string, timeZone?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingReference' is not null or undefined
            assertParamExists('publicControllerRemoveExtrasFromBooking', 'bookingReference', bookingReference)
            // verify required parameter 'bookingExtrasId' is not null or undefined
            assertParamExists('publicControllerRemoveExtrasFromBooking', 'bookingExtrasId', bookingExtrasId)
            // verify required parameter 'publicReference' is not null or undefined
            assertParamExists('publicControllerRemoveExtrasFromBooking', 'publicReference', publicReference)
            const localVarPath = `/api/v1/features/public/{bookingReference}/extras/{bookingExtrasId}`
                .replace(`{${"bookingReference"}}`, encodeURIComponent(String(bookingReference)))
                .replace(`{${"bookingExtrasId"}}`, encodeURIComponent(String(bookingExtrasId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (publicReference !== undefined) {
                localVarQueryParameter['publicReference'] = publicReference;
            }

            if (timeZone !== undefined) {
                localVarQueryParameter['timeZone'] = timeZone;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} bookingRef 
         * @param {number} beachChairBookingId 
         * @param {string} publicReference 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerRemoveFromBooking: async (bookingRef: string, beachChairBookingId: number, publicReference: string, timeZone?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingRef' is not null or undefined
            assertParamExists('publicControllerRemoveFromBooking', 'bookingRef', bookingRef)
            // verify required parameter 'beachChairBookingId' is not null or undefined
            assertParamExists('publicControllerRemoveFromBooking', 'beachChairBookingId', beachChairBookingId)
            // verify required parameter 'publicReference' is not null or undefined
            assertParamExists('publicControllerRemoveFromBooking', 'publicReference', publicReference)
            const localVarPath = `/api/v1/features/public/booking/{bookingRef}/beach_chair_booking/{beach_chair_booking_id}`
                .replace(`{${"bookingRef"}}`, encodeURIComponent(String(bookingRef)))
                .replace(`{${"beach_chair_booking_id"}}`, encodeURIComponent(String(beachChairBookingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (publicReference !== undefined) {
                localVarQueryParameter['publicReference'] = publicReference;
            }

            if (timeZone !== undefined) {
                localVarQueryParameter['timeZone'] = timeZone;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} skjApiKey 
         * @param {number} id 
         * @param {string} publicReference 
         * @param {PublicLockReportBodyDto} publicLockReportBodyDto 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerReportLock: async (skjApiKey: string, id: number, publicReference: string, publicLockReportBodyDto: PublicLockReportBodyDto, timeZone?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'skjApiKey' is not null or undefined
            assertParamExists('publicControllerReportLock', 'skjApiKey', skjApiKey)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('publicControllerReportLock', 'id', id)
            // verify required parameter 'publicReference' is not null or undefined
            assertParamExists('publicControllerReportLock', 'publicReference', publicReference)
            // verify required parameter 'publicLockReportBodyDto' is not null or undefined
            assertParamExists('publicControllerReportLock', 'publicLockReportBodyDto', publicLockReportBodyDto)
            const localVarPath = `/api/v1/features/public/lock/{id}/sync`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (publicReference !== undefined) {
                localVarQueryParameter['publicReference'] = publicReference;
            }

            if (timeZone !== undefined) {
                localVarQueryParameter['timeZone'] = timeZone;
            }

            if (skjApiKey !== undefined && skjApiKey !== null) {
                localVarHeaderParameter['skj-api-key'] = String(skjApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(publicLockReportBodyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} bookingReference 
         * @param {number} bookingExtrasId 
         * @param {string} publicReference 
         * @param {UpdateExtrasBookingDto} updateExtrasBookingDto 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerSetAmountExtrasInBooking: async (bookingReference: string, bookingExtrasId: number, publicReference: string, updateExtrasBookingDto: UpdateExtrasBookingDto, timeZone?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingReference' is not null or undefined
            assertParamExists('publicControllerSetAmountExtrasInBooking', 'bookingReference', bookingReference)
            // verify required parameter 'bookingExtrasId' is not null or undefined
            assertParamExists('publicControllerSetAmountExtrasInBooking', 'bookingExtrasId', bookingExtrasId)
            // verify required parameter 'publicReference' is not null or undefined
            assertParamExists('publicControllerSetAmountExtrasInBooking', 'publicReference', publicReference)
            // verify required parameter 'updateExtrasBookingDto' is not null or undefined
            assertParamExists('publicControllerSetAmountExtrasInBooking', 'updateExtrasBookingDto', updateExtrasBookingDto)
            const localVarPath = `/api/v1/features/public/{bookingReference}/extras/{bookingExtrasId}`
                .replace(`{${"bookingReference"}}`, encodeURIComponent(String(bookingReference)))
                .replace(`{${"bookingExtrasId"}}`, encodeURIComponent(String(bookingExtrasId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (publicReference !== undefined) {
                localVarQueryParameter['publicReference'] = publicReference;
            }

            if (timeZone !== undefined) {
                localVarQueryParameter['timeZone'] = timeZone;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateExtrasBookingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicApi - functional programming interface
 * @export
 */
export const PublicApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublicApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} bookingRef 
         * @param {string} publicReference 
         * @param {PublicCreateBeachChairBookingBodyDto} publicCreateBeachChairBookingBodyDto 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicControllerAddBeachChairBookingToBooking(bookingRef: string, publicReference: string, publicCreateBeachChairBookingBodyDto: PublicCreateBeachChairBookingBodyDto, timeZone?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicControllerAddBeachChairBookingToBooking(bookingRef, publicReference, publicCreateBeachChairBookingBodyDto, timeZone, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} bookingReference 
         * @param {string} publicReference 
         * @param {CreateExtrasBookingBulkDto} createExtrasBookingBulkDto 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicControllerAddExtras(bookingReference: string, publicReference: string, createExtrasBookingBulkDto: CreateExtrasBookingBulkDto, timeZone?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicControllerAddExtras(bookingReference, publicReference, createExtrasBookingBulkDto, timeZone, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} email 
         * @param {string} bookingRef 
         * @param {PublicUpdatePaymentMethod} publicUpdatePaymentMethod 
         * @param {PaymentFlow} [flow] 
         * @param {string} [timeZone] 
         * @param {string} [paymentId] 
         * @param {string} [token] 
         * @param {string} [payerID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicControllerChangePaymentMethod(email: string, bookingRef: string, publicUpdatePaymentMethod: PublicUpdatePaymentMethod, flow?: PaymentFlow, timeZone?: string, paymentId?: string, token?: string, payerID?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicControllerChangePaymentMethod(email, bookingRef, publicUpdatePaymentMethod, flow, timeZone, paymentId, token, payerID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} beachChairId 
         * @param {string} publicReference 
         * @param {string} start 
         * @param {string} end 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicControllerCheckBeachChairAvailability(beachChairId: number, publicReference: string, start: string, end: string, timeZone?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BeachChairTakenResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicControllerCheckBeachChairAvailability(beachChairId, publicReference, start, end, timeZone, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} email 
         * @param {string} publicReference 
         * @param {string} bookingReference 
         * @param {string} skjApiKey SKJ api key used to authenticate the request
         * @param {string} [bookingRef] 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicControllerConfirmReservation(email: string, publicReference: string, bookingReference: string, skjApiKey: string, bookingRef?: string, timeZone?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicControllerConfirmReservation(email, publicReference, bookingReference, skjApiKey, bookingRef, timeZone, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} bookingReference 
         * @param {string} publicReference 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicControllerGetBookingStatus(bookingReference: string, publicReference: string, timeZone?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicControllerGetBookingStatus(bookingReference, publicReference, timeZone, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} publicReference 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicControllerGetExtras(publicReference: string, page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, csv?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExtrasChunkResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicControllerGetExtras(publicReference, page, itemsPerPage, orderBy, orderDir, search, filterBy, filter, csv, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} publicReference 
         * @param {string} skjApiKey SKJ api key used to authenticate the request
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicControllerGetMobileConfig(publicReference: string, skjApiKey: string, timeZone?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MobileConfigResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicControllerGetMobileConfig(publicReference, skjApiKey, timeZone, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} publicReference 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicControllerGetPublicChairsFilters(publicReference: string, timeZone?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FiltersResponseDtoClass>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicControllerGetPublicChairsFilters(publicReference, timeZone, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} start 
         * @param {string} publicReference 
         * @param {string} [end] 
         * @param {number} [row] Id of the row which to beach chair is assigned
         * @param {BeachChairModel} [model] 
         * @param {boolean} [free] 
         * @param {string} [timeZone] 
         * @param {boolean} [lock] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicControllerGetPublicSectionsBeachChairs(id: number, start: string, publicReference: string, end?: string, row?: number, model?: BeachChairModel, free?: boolean, timeZone?: string, lock?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SectionTimeFrameRowDataResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicControllerGetPublicSectionsBeachChairs(id, start, publicReference, end, row, model, free, timeZone, lock, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} publicReference 
         * @param {string} start 
         * @param {string} end 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicControllerGetPublicSectionsCalendar(id: number, publicReference: string, start: string, end: string, timeZone?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectionCalendarAvailabilityResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicControllerGetPublicSectionsCalendar(id, publicReference, start, end, timeZone, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} email 
         * @param {string} bookingRef 
         * @param {PaymentFlow} [flow] 
         * @param {string} [timeZone] 
         * @param {string} [paymentId] 
         * @param {string} [token] 
         * @param {string} [payerID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicControllerPublicAbortPayment(email: string, bookingRef: string, flow?: PaymentFlow, timeZone?: string, paymentId?: string, token?: string, payerID?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingDetailsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicControllerPublicAbortPayment(email, bookingRef, flow, timeZone, paymentId, token, payerID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} bookingRef 
         * @param {string} publicReference 
         * @param {PublicCreateCustomerBodyDto} publicCreateCustomerBodyDto 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicControllerPublicAddCustomerToBooking(bookingRef: string, publicReference: string, publicCreateCustomerBodyDto: PublicCreateCustomerBodyDto, timeZone?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicControllerPublicAddCustomerToBooking(bookingRef, publicReference, publicCreateCustomerBodyDto, timeZone, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} email 
         * @param {string} bookingRef 
         * @param {PaymentFlow} [flow] 
         * @param {string} [timeZone] 
         * @param {string} [paymentId] 
         * @param {string} [token] 
         * @param {string} [payerID] 
         * @param {string} [skjApiKey] SKJ api key used to authenticate the request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicControllerPublicBookingDetails(email: string, bookingRef: string, flow?: PaymentFlow, timeZone?: string, paymentId?: string, token?: string, payerID?: string, skjApiKey?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingDetailsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicControllerPublicBookingDetails(email, bookingRef, flow, timeZone, paymentId, token, payerID, skjApiKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} email 
         * @param {string} bookingRef 
         * @param {PaymentFlow} [flow] 
         * @param {string} [timeZone] 
         * @param {string} [paymentId] 
         * @param {string} [token] 
         * @param {string} [payerID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicControllerPublicCancelBooking(email: string, bookingRef: string, flow?: PaymentFlow, timeZone?: string, paymentId?: string, token?: string, payerID?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingDetailsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicControllerPublicCancelBooking(email, bookingRef, flow, timeZone, paymentId, token, payerID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} email 
         * @param {string} bookingRef 
         * @param {PaymentFlow} [flow] 
         * @param {string} [timeZone] 
         * @param {string} [paymentId] 
         * @param {string} [token] 
         * @param {string} [payerID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicControllerPublicConfirmBooking(email: string, bookingRef: string, flow?: PaymentFlow, timeZone?: string, paymentId?: string, token?: string, payerID?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicControllerPublicConfirmBooking(email, bookingRef, flow, timeZone, paymentId, token, payerID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} publicReference 
         * @param {string} [timeZone] 
         * @param {string} [source] 
         * @param {string} [affiliateReference] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicControllerPublicCreateBooking(publicReference: string, timeZone?: string, source?: string, affiliateReference?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicControllerPublicCreateBooking(publicReference, timeZone, source, affiliateReference, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicControllerPublicLockHealth(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LockHealthDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicControllerPublicLockHealth(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} publicReference 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicControllerPublicVendor(publicReference: string, timeZone?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicVendorResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicControllerPublicVendor(publicReference, timeZone, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} bookingReference 
         * @param {number} bookingExtrasId 
         * @param {string} publicReference 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicControllerRemoveExtrasFromBooking(bookingReference: string, bookingExtrasId: number, publicReference: string, timeZone?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicControllerRemoveExtrasFromBooking(bookingReference, bookingExtrasId, publicReference, timeZone, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} bookingRef 
         * @param {number} beachChairBookingId 
         * @param {string} publicReference 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicControllerRemoveFromBooking(bookingRef: string, beachChairBookingId: number, publicReference: string, timeZone?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicControllerRemoveFromBooking(bookingRef, beachChairBookingId, publicReference, timeZone, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} skjApiKey 
         * @param {number} id 
         * @param {string} publicReference 
         * @param {PublicLockReportBodyDto} publicLockReportBodyDto 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicControllerReportLock(skjApiKey: string, id: number, publicReference: string, publicLockReportBodyDto: PublicLockReportBodyDto, timeZone?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SectionTimeFrameRowDataResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicControllerReportLock(skjApiKey, id, publicReference, publicLockReportBodyDto, timeZone, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} bookingReference 
         * @param {number} bookingExtrasId 
         * @param {string} publicReference 
         * @param {UpdateExtrasBookingDto} updateExtrasBookingDto 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicControllerSetAmountExtrasInBooking(bookingReference: string, bookingExtrasId: number, publicReference: string, updateExtrasBookingDto: UpdateExtrasBookingDto, timeZone?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicControllerSetAmountExtrasInBooking(bookingReference, bookingExtrasId, publicReference, updateExtrasBookingDto, timeZone, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PublicApi - factory interface
 * @export
 */
export const PublicApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublicApiFp(configuration)
    return {
        /**
         * 
         * @param {string} bookingRef 
         * @param {string} publicReference 
         * @param {PublicCreateBeachChairBookingBodyDto} publicCreateBeachChairBookingBodyDto 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerAddBeachChairBookingToBooking(bookingRef: string, publicReference: string, publicCreateBeachChairBookingBodyDto: PublicCreateBeachChairBookingBodyDto, timeZone?: string, options?: any): AxiosPromise<BookingResponseDto> {
            return localVarFp.publicControllerAddBeachChairBookingToBooking(bookingRef, publicReference, publicCreateBeachChairBookingBodyDto, timeZone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} bookingReference 
         * @param {string} publicReference 
         * @param {CreateExtrasBookingBulkDto} createExtrasBookingBulkDto 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerAddExtras(bookingReference: string, publicReference: string, createExtrasBookingBulkDto: CreateExtrasBookingBulkDto, timeZone?: string, options?: any): AxiosPromise<BookingResponseDto> {
            return localVarFp.publicControllerAddExtras(bookingReference, publicReference, createExtrasBookingBulkDto, timeZone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {string} bookingRef 
         * @param {PublicUpdatePaymentMethod} publicUpdatePaymentMethod 
         * @param {PaymentFlow} [flow] 
         * @param {string} [timeZone] 
         * @param {string} [paymentId] 
         * @param {string} [token] 
         * @param {string} [payerID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerChangePaymentMethod(email: string, bookingRef: string, publicUpdatePaymentMethod: PublicUpdatePaymentMethod, flow?: PaymentFlow, timeZone?: string, paymentId?: string, token?: string, payerID?: string, options?: any): AxiosPromise<BookingResponseDto> {
            return localVarFp.publicControllerChangePaymentMethod(email, bookingRef, publicUpdatePaymentMethod, flow, timeZone, paymentId, token, payerID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} beachChairId 
         * @param {string} publicReference 
         * @param {string} start 
         * @param {string} end 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerCheckBeachChairAvailability(beachChairId: number, publicReference: string, start: string, end: string, timeZone?: string, options?: any): AxiosPromise<BeachChairTakenResponseDto> {
            return localVarFp.publicControllerCheckBeachChairAvailability(beachChairId, publicReference, start, end, timeZone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {string} publicReference 
         * @param {string} bookingReference 
         * @param {string} skjApiKey SKJ api key used to authenticate the request
         * @param {string} [bookingRef] 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerConfirmReservation(email: string, publicReference: string, bookingReference: string, skjApiKey: string, bookingRef?: string, timeZone?: string, options?: any): AxiosPromise<BookingResponseDto> {
            return localVarFp.publicControllerConfirmReservation(email, publicReference, bookingReference, skjApiKey, bookingRef, timeZone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} bookingReference 
         * @param {string} publicReference 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerGetBookingStatus(bookingReference: string, publicReference: string, timeZone?: string, options?: any): AxiosPromise<void> {
            return localVarFp.publicControllerGetBookingStatus(bookingReference, publicReference, timeZone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} publicReference 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerGetExtras(publicReference: string, page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, csv?: string, options?: any): AxiosPromise<ExtrasChunkResponseDto> {
            return localVarFp.publicControllerGetExtras(publicReference, page, itemsPerPage, orderBy, orderDir, search, filterBy, filter, csv, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} publicReference 
         * @param {string} skjApiKey SKJ api key used to authenticate the request
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerGetMobileConfig(publicReference: string, skjApiKey: string, timeZone?: string, options?: any): AxiosPromise<MobileConfigResponseDto> {
            return localVarFp.publicControllerGetMobileConfig(publicReference, skjApiKey, timeZone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} publicReference 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerGetPublicChairsFilters(publicReference: string, timeZone?: string, options?: any): AxiosPromise<FiltersResponseDtoClass> {
            return localVarFp.publicControllerGetPublicChairsFilters(publicReference, timeZone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} start 
         * @param {string} publicReference 
         * @param {string} [end] 
         * @param {number} [row] Id of the row which to beach chair is assigned
         * @param {BeachChairModel} [model] 
         * @param {boolean} [free] 
         * @param {string} [timeZone] 
         * @param {boolean} [lock] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerGetPublicSectionsBeachChairs(id: number, start: string, publicReference: string, end?: string, row?: number, model?: BeachChairModel, free?: boolean, timeZone?: string, lock?: boolean, options?: any): AxiosPromise<Array<SectionTimeFrameRowDataResponseDto>> {
            return localVarFp.publicControllerGetPublicSectionsBeachChairs(id, start, publicReference, end, row, model, free, timeZone, lock, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} publicReference 
         * @param {string} start 
         * @param {string} end 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerGetPublicSectionsCalendar(id: number, publicReference: string, start: string, end: string, timeZone?: string, options?: any): AxiosPromise<SectionCalendarAvailabilityResponseDto> {
            return localVarFp.publicControllerGetPublicSectionsCalendar(id, publicReference, start, end, timeZone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {string} bookingRef 
         * @param {PaymentFlow} [flow] 
         * @param {string} [timeZone] 
         * @param {string} [paymentId] 
         * @param {string} [token] 
         * @param {string} [payerID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerPublicAbortPayment(email: string, bookingRef: string, flow?: PaymentFlow, timeZone?: string, paymentId?: string, token?: string, payerID?: string, options?: any): AxiosPromise<BookingDetailsResponseDto> {
            return localVarFp.publicControllerPublicAbortPayment(email, bookingRef, flow, timeZone, paymentId, token, payerID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} bookingRef 
         * @param {string} publicReference 
         * @param {PublicCreateCustomerBodyDto} publicCreateCustomerBodyDto 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerPublicAddCustomerToBooking(bookingRef: string, publicReference: string, publicCreateCustomerBodyDto: PublicCreateCustomerBodyDto, timeZone?: string, options?: any): AxiosPromise<BookingResponseDto> {
            return localVarFp.publicControllerPublicAddCustomerToBooking(bookingRef, publicReference, publicCreateCustomerBodyDto, timeZone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {string} bookingRef 
         * @param {PaymentFlow} [flow] 
         * @param {string} [timeZone] 
         * @param {string} [paymentId] 
         * @param {string} [token] 
         * @param {string} [payerID] 
         * @param {string} [skjApiKey] SKJ api key used to authenticate the request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerPublicBookingDetails(email: string, bookingRef: string, flow?: PaymentFlow, timeZone?: string, paymentId?: string, token?: string, payerID?: string, skjApiKey?: string, options?: any): AxiosPromise<BookingDetailsResponseDto> {
            return localVarFp.publicControllerPublicBookingDetails(email, bookingRef, flow, timeZone, paymentId, token, payerID, skjApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {string} bookingRef 
         * @param {PaymentFlow} [flow] 
         * @param {string} [timeZone] 
         * @param {string} [paymentId] 
         * @param {string} [token] 
         * @param {string} [payerID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerPublicCancelBooking(email: string, bookingRef: string, flow?: PaymentFlow, timeZone?: string, paymentId?: string, token?: string, payerID?: string, options?: any): AxiosPromise<BookingDetailsResponseDto> {
            return localVarFp.publicControllerPublicCancelBooking(email, bookingRef, flow, timeZone, paymentId, token, payerID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {string} bookingRef 
         * @param {PaymentFlow} [flow] 
         * @param {string} [timeZone] 
         * @param {string} [paymentId] 
         * @param {string} [token] 
         * @param {string} [payerID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerPublicConfirmBooking(email: string, bookingRef: string, flow?: PaymentFlow, timeZone?: string, paymentId?: string, token?: string, payerID?: string, options?: any): AxiosPromise<void> {
            return localVarFp.publicControllerPublicConfirmBooking(email, bookingRef, flow, timeZone, paymentId, token, payerID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} publicReference 
         * @param {string} [timeZone] 
         * @param {string} [source] 
         * @param {string} [affiliateReference] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerPublicCreateBooking(publicReference: string, timeZone?: string, source?: string, affiliateReference?: string, options?: any): AxiosPromise<BookingResponseDto> {
            return localVarFp.publicControllerPublicCreateBooking(publicReference, timeZone, source, affiliateReference, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerPublicLockHealth(id: number, options?: any): AxiosPromise<LockHealthDto> {
            return localVarFp.publicControllerPublicLockHealth(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} publicReference 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerPublicVendor(publicReference: string, timeZone?: string, options?: any): AxiosPromise<PublicVendorResponseDto> {
            return localVarFp.publicControllerPublicVendor(publicReference, timeZone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} bookingReference 
         * @param {number} bookingExtrasId 
         * @param {string} publicReference 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerRemoveExtrasFromBooking(bookingReference: string, bookingExtrasId: number, publicReference: string, timeZone?: string, options?: any): AxiosPromise<BookingResponseDto> {
            return localVarFp.publicControllerRemoveExtrasFromBooking(bookingReference, bookingExtrasId, publicReference, timeZone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} bookingRef 
         * @param {number} beachChairBookingId 
         * @param {string} publicReference 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerRemoveFromBooking(bookingRef: string, beachChairBookingId: number, publicReference: string, timeZone?: string, options?: any): AxiosPromise<BookingResponseDto> {
            return localVarFp.publicControllerRemoveFromBooking(bookingRef, beachChairBookingId, publicReference, timeZone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} skjApiKey 
         * @param {number} id 
         * @param {string} publicReference 
         * @param {PublicLockReportBodyDto} publicLockReportBodyDto 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerReportLock(skjApiKey: string, id: number, publicReference: string, publicLockReportBodyDto: PublicLockReportBodyDto, timeZone?: string, options?: any): AxiosPromise<Array<SectionTimeFrameRowDataResponseDto>> {
            return localVarFp.publicControllerReportLock(skjApiKey, id, publicReference, publicLockReportBodyDto, timeZone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} bookingReference 
         * @param {number} bookingExtrasId 
         * @param {string} publicReference 
         * @param {UpdateExtrasBookingDto} updateExtrasBookingDto 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicControllerSetAmountExtrasInBooking(bookingReference: string, bookingExtrasId: number, publicReference: string, updateExtrasBookingDto: UpdateExtrasBookingDto, timeZone?: string, options?: any): AxiosPromise<BookingResponseDto> {
            return localVarFp.publicControllerSetAmountExtrasInBooking(bookingReference, bookingExtrasId, publicReference, updateExtrasBookingDto, timeZone, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for publicControllerAddBeachChairBookingToBooking operation in PublicApi.
 * @export
 * @interface PublicApiPublicControllerAddBeachChairBookingToBookingRequest
 */
export interface PublicApiPublicControllerAddBeachChairBookingToBookingRequest {
    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerAddBeachChairBookingToBooking
     */
    readonly bookingRef: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerAddBeachChairBookingToBooking
     */
    readonly publicReference: string

    /**
     * 
     * @type {PublicCreateBeachChairBookingBodyDto}
     * @memberof PublicApiPublicControllerAddBeachChairBookingToBooking
     */
    readonly publicCreateBeachChairBookingBodyDto: PublicCreateBeachChairBookingBodyDto

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerAddBeachChairBookingToBooking
     */
    readonly timeZone?: string
}

/**
 * Request parameters for publicControllerAddExtras operation in PublicApi.
 * @export
 * @interface PublicApiPublicControllerAddExtrasRequest
 */
export interface PublicApiPublicControllerAddExtrasRequest {
    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerAddExtras
     */
    readonly bookingReference: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerAddExtras
     */
    readonly publicReference: string

    /**
     * 
     * @type {CreateExtrasBookingBulkDto}
     * @memberof PublicApiPublicControllerAddExtras
     */
    readonly createExtrasBookingBulkDto: CreateExtrasBookingBulkDto

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerAddExtras
     */
    readonly timeZone?: string
}

/**
 * Request parameters for publicControllerChangePaymentMethod operation in PublicApi.
 * @export
 * @interface PublicApiPublicControllerChangePaymentMethodRequest
 */
export interface PublicApiPublicControllerChangePaymentMethodRequest {
    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerChangePaymentMethod
     */
    readonly email: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerChangePaymentMethod
     */
    readonly bookingRef: string

    /**
     * 
     * @type {PublicUpdatePaymentMethod}
     * @memberof PublicApiPublicControllerChangePaymentMethod
     */
    readonly publicUpdatePaymentMethod: PublicUpdatePaymentMethod

    /**
     * 
     * @type {PaymentFlow}
     * @memberof PublicApiPublicControllerChangePaymentMethod
     */
    readonly flow?: PaymentFlow

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerChangePaymentMethod
     */
    readonly timeZone?: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerChangePaymentMethod
     */
    readonly paymentId?: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerChangePaymentMethod
     */
    readonly token?: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerChangePaymentMethod
     */
    readonly payerID?: string
}

/**
 * Request parameters for publicControllerCheckBeachChairAvailability operation in PublicApi.
 * @export
 * @interface PublicApiPublicControllerCheckBeachChairAvailabilityRequest
 */
export interface PublicApiPublicControllerCheckBeachChairAvailabilityRequest {
    /**
     * 
     * @type {number}
     * @memberof PublicApiPublicControllerCheckBeachChairAvailability
     */
    readonly beachChairId: number

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerCheckBeachChairAvailability
     */
    readonly publicReference: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerCheckBeachChairAvailability
     */
    readonly start: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerCheckBeachChairAvailability
     */
    readonly end: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerCheckBeachChairAvailability
     */
    readonly timeZone?: string
}

/**
 * Request parameters for publicControllerConfirmReservation operation in PublicApi.
 * @export
 * @interface PublicApiPublicControllerConfirmReservationRequest
 */
export interface PublicApiPublicControllerConfirmReservationRequest {
    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerConfirmReservation
     */
    readonly email: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerConfirmReservation
     */
    readonly publicReference: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerConfirmReservation
     */
    readonly bookingReference: string

    /**
     * SKJ api key used to authenticate the request
     * @type {string}
     * @memberof PublicApiPublicControllerConfirmReservation
     */
    readonly skjApiKey: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerConfirmReservation
     */
    readonly bookingRef?: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerConfirmReservation
     */
    readonly timeZone?: string
}

/**
 * Request parameters for publicControllerGetBookingStatus operation in PublicApi.
 * @export
 * @interface PublicApiPublicControllerGetBookingStatusRequest
 */
export interface PublicApiPublicControllerGetBookingStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerGetBookingStatus
     */
    readonly bookingReference: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerGetBookingStatus
     */
    readonly publicReference: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerGetBookingStatus
     */
    readonly timeZone?: string
}

/**
 * Request parameters for publicControllerGetExtras operation in PublicApi.
 * @export
 * @interface PublicApiPublicControllerGetExtrasRequest
 */
export interface PublicApiPublicControllerGetExtrasRequest {
    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerGetExtras
     */
    readonly publicReference: string

    /**
     * Number of the page to fetch
     * @type {number}
     * @memberof PublicApiPublicControllerGetExtras
     */
    readonly page?: number

    /**
     * Number of items per page
     * @type {number}
     * @memberof PublicApiPublicControllerGetExtras
     */
    readonly itemsPerPage?: number

    /**
     * Name of the property to order by
     * @type {string}
     * @memberof PublicApiPublicControllerGetExtras
     */
    readonly orderBy?: string

    /**
     * Value which specify direction of the order
     * @type {OrderByDirection}
     * @memberof PublicApiPublicControllerGetExtras
     */
    readonly orderDir?: OrderByDirection

    /**
     * Text which will be used to search through table data
     * @type {string}
     * @memberof PublicApiPublicControllerGetExtras
     */
    readonly search?: string

    /**
     * Name of the property to filter by
     * @type {string}
     * @memberof PublicApiPublicControllerGetExtras
     */
    readonly filterBy?: string

    /**
     * Value which will be used to filter table data
     * @type {string}
     * @memberof PublicApiPublicControllerGetExtras
     */
    readonly filter?: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerGetExtras
     */
    readonly csv?: string
}

/**
 * Request parameters for publicControllerGetMobileConfig operation in PublicApi.
 * @export
 * @interface PublicApiPublicControllerGetMobileConfigRequest
 */
export interface PublicApiPublicControllerGetMobileConfigRequest {
    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerGetMobileConfig
     */
    readonly publicReference: string

    /**
     * SKJ api key used to authenticate the request
     * @type {string}
     * @memberof PublicApiPublicControllerGetMobileConfig
     */
    readonly skjApiKey: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerGetMobileConfig
     */
    readonly timeZone?: string
}

/**
 * Request parameters for publicControllerGetPublicChairsFilters operation in PublicApi.
 * @export
 * @interface PublicApiPublicControllerGetPublicChairsFiltersRequest
 */
export interface PublicApiPublicControllerGetPublicChairsFiltersRequest {
    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerGetPublicChairsFilters
     */
    readonly publicReference: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerGetPublicChairsFilters
     */
    readonly timeZone?: string
}

/**
 * Request parameters for publicControllerGetPublicSectionsBeachChairs operation in PublicApi.
 * @export
 * @interface PublicApiPublicControllerGetPublicSectionsBeachChairsRequest
 */
export interface PublicApiPublicControllerGetPublicSectionsBeachChairsRequest {
    /**
     * 
     * @type {number}
     * @memberof PublicApiPublicControllerGetPublicSectionsBeachChairs
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerGetPublicSectionsBeachChairs
     */
    readonly start: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerGetPublicSectionsBeachChairs
     */
    readonly publicReference: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerGetPublicSectionsBeachChairs
     */
    readonly end?: string

    /**
     * Id of the row which to beach chair is assigned
     * @type {number}
     * @memberof PublicApiPublicControllerGetPublicSectionsBeachChairs
     */
    readonly row?: number

    /**
     * 
     * @type {BeachChairModel}
     * @memberof PublicApiPublicControllerGetPublicSectionsBeachChairs
     */
    readonly model?: BeachChairModel

    /**
     * 
     * @type {boolean}
     * @memberof PublicApiPublicControllerGetPublicSectionsBeachChairs
     */
    readonly free?: boolean

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerGetPublicSectionsBeachChairs
     */
    readonly timeZone?: string

    /**
     * 
     * @type {boolean}
     * @memberof PublicApiPublicControllerGetPublicSectionsBeachChairs
     */
    readonly lock?: boolean
}

/**
 * Request parameters for publicControllerGetPublicSectionsCalendar operation in PublicApi.
 * @export
 * @interface PublicApiPublicControllerGetPublicSectionsCalendarRequest
 */
export interface PublicApiPublicControllerGetPublicSectionsCalendarRequest {
    /**
     * 
     * @type {number}
     * @memberof PublicApiPublicControllerGetPublicSectionsCalendar
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerGetPublicSectionsCalendar
     */
    readonly publicReference: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerGetPublicSectionsCalendar
     */
    readonly start: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerGetPublicSectionsCalendar
     */
    readonly end: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerGetPublicSectionsCalendar
     */
    readonly timeZone?: string
}

/**
 * Request parameters for publicControllerPublicAbortPayment operation in PublicApi.
 * @export
 * @interface PublicApiPublicControllerPublicAbortPaymentRequest
 */
export interface PublicApiPublicControllerPublicAbortPaymentRequest {
    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerPublicAbortPayment
     */
    readonly email: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerPublicAbortPayment
     */
    readonly bookingRef: string

    /**
     * 
     * @type {PaymentFlow}
     * @memberof PublicApiPublicControllerPublicAbortPayment
     */
    readonly flow?: PaymentFlow

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerPublicAbortPayment
     */
    readonly timeZone?: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerPublicAbortPayment
     */
    readonly paymentId?: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerPublicAbortPayment
     */
    readonly token?: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerPublicAbortPayment
     */
    readonly payerID?: string
}

/**
 * Request parameters for publicControllerPublicAddCustomerToBooking operation in PublicApi.
 * @export
 * @interface PublicApiPublicControllerPublicAddCustomerToBookingRequest
 */
export interface PublicApiPublicControllerPublicAddCustomerToBookingRequest {
    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerPublicAddCustomerToBooking
     */
    readonly bookingRef: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerPublicAddCustomerToBooking
     */
    readonly publicReference: string

    /**
     * 
     * @type {PublicCreateCustomerBodyDto}
     * @memberof PublicApiPublicControllerPublicAddCustomerToBooking
     */
    readonly publicCreateCustomerBodyDto: PublicCreateCustomerBodyDto

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerPublicAddCustomerToBooking
     */
    readonly timeZone?: string
}

/**
 * Request parameters for publicControllerPublicBookingDetails operation in PublicApi.
 * @export
 * @interface PublicApiPublicControllerPublicBookingDetailsRequest
 */
export interface PublicApiPublicControllerPublicBookingDetailsRequest {
    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerPublicBookingDetails
     */
    readonly email: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerPublicBookingDetails
     */
    readonly bookingRef: string

    /**
     * 
     * @type {PaymentFlow}
     * @memberof PublicApiPublicControllerPublicBookingDetails
     */
    readonly flow?: PaymentFlow

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerPublicBookingDetails
     */
    readonly timeZone?: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerPublicBookingDetails
     */
    readonly paymentId?: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerPublicBookingDetails
     */
    readonly token?: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerPublicBookingDetails
     */
    readonly payerID?: string

    /**
     * SKJ api key used to authenticate the request
     * @type {string}
     * @memberof PublicApiPublicControllerPublicBookingDetails
     */
    readonly skjApiKey?: string
}

/**
 * Request parameters for publicControllerPublicCancelBooking operation in PublicApi.
 * @export
 * @interface PublicApiPublicControllerPublicCancelBookingRequest
 */
export interface PublicApiPublicControllerPublicCancelBookingRequest {
    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerPublicCancelBooking
     */
    readonly email: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerPublicCancelBooking
     */
    readonly bookingRef: string

    /**
     * 
     * @type {PaymentFlow}
     * @memberof PublicApiPublicControllerPublicCancelBooking
     */
    readonly flow?: PaymentFlow

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerPublicCancelBooking
     */
    readonly timeZone?: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerPublicCancelBooking
     */
    readonly paymentId?: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerPublicCancelBooking
     */
    readonly token?: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerPublicCancelBooking
     */
    readonly payerID?: string
}

/**
 * Request parameters for publicControllerPublicConfirmBooking operation in PublicApi.
 * @export
 * @interface PublicApiPublicControllerPublicConfirmBookingRequest
 */
export interface PublicApiPublicControllerPublicConfirmBookingRequest {
    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerPublicConfirmBooking
     */
    readonly email: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerPublicConfirmBooking
     */
    readonly bookingRef: string

    /**
     * 
     * @type {PaymentFlow}
     * @memberof PublicApiPublicControllerPublicConfirmBooking
     */
    readonly flow?: PaymentFlow

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerPublicConfirmBooking
     */
    readonly timeZone?: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerPublicConfirmBooking
     */
    readonly paymentId?: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerPublicConfirmBooking
     */
    readonly token?: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerPublicConfirmBooking
     */
    readonly payerID?: string
}

/**
 * Request parameters for publicControllerPublicCreateBooking operation in PublicApi.
 * @export
 * @interface PublicApiPublicControllerPublicCreateBookingRequest
 */
export interface PublicApiPublicControllerPublicCreateBookingRequest {
    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerPublicCreateBooking
     */
    readonly publicReference: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerPublicCreateBooking
     */
    readonly timeZone?: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerPublicCreateBooking
     */
    readonly source?: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerPublicCreateBooking
     */
    readonly affiliateReference?: string
}

/**
 * Request parameters for publicControllerPublicLockHealth operation in PublicApi.
 * @export
 * @interface PublicApiPublicControllerPublicLockHealthRequest
 */
export interface PublicApiPublicControllerPublicLockHealthRequest {
    /**
     * 
     * @type {number}
     * @memberof PublicApiPublicControllerPublicLockHealth
     */
    readonly id: number
}

/**
 * Request parameters for publicControllerPublicVendor operation in PublicApi.
 * @export
 * @interface PublicApiPublicControllerPublicVendorRequest
 */
export interface PublicApiPublicControllerPublicVendorRequest {
    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerPublicVendor
     */
    readonly publicReference: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerPublicVendor
     */
    readonly timeZone?: string
}

/**
 * Request parameters for publicControllerRemoveExtrasFromBooking operation in PublicApi.
 * @export
 * @interface PublicApiPublicControllerRemoveExtrasFromBookingRequest
 */
export interface PublicApiPublicControllerRemoveExtrasFromBookingRequest {
    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerRemoveExtrasFromBooking
     */
    readonly bookingReference: string

    /**
     * 
     * @type {number}
     * @memberof PublicApiPublicControllerRemoveExtrasFromBooking
     */
    readonly bookingExtrasId: number

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerRemoveExtrasFromBooking
     */
    readonly publicReference: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerRemoveExtrasFromBooking
     */
    readonly timeZone?: string
}

/**
 * Request parameters for publicControllerRemoveFromBooking operation in PublicApi.
 * @export
 * @interface PublicApiPublicControllerRemoveFromBookingRequest
 */
export interface PublicApiPublicControllerRemoveFromBookingRequest {
    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerRemoveFromBooking
     */
    readonly bookingRef: string

    /**
     * 
     * @type {number}
     * @memberof PublicApiPublicControllerRemoveFromBooking
     */
    readonly beachChairBookingId: number

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerRemoveFromBooking
     */
    readonly publicReference: string

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerRemoveFromBooking
     */
    readonly timeZone?: string
}

/**
 * Request parameters for publicControllerReportLock operation in PublicApi.
 * @export
 * @interface PublicApiPublicControllerReportLockRequest
 */
export interface PublicApiPublicControllerReportLockRequest {
    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerReportLock
     */
    readonly skjApiKey: string

    /**
     * 
     * @type {number}
     * @memberof PublicApiPublicControllerReportLock
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerReportLock
     */
    readonly publicReference: string

    /**
     * 
     * @type {PublicLockReportBodyDto}
     * @memberof PublicApiPublicControllerReportLock
     */
    readonly publicLockReportBodyDto: PublicLockReportBodyDto

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerReportLock
     */
    readonly timeZone?: string
}

/**
 * Request parameters for publicControllerSetAmountExtrasInBooking operation in PublicApi.
 * @export
 * @interface PublicApiPublicControllerSetAmountExtrasInBookingRequest
 */
export interface PublicApiPublicControllerSetAmountExtrasInBookingRequest {
    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerSetAmountExtrasInBooking
     */
    readonly bookingReference: string

    /**
     * 
     * @type {number}
     * @memberof PublicApiPublicControllerSetAmountExtrasInBooking
     */
    readonly bookingExtrasId: number

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerSetAmountExtrasInBooking
     */
    readonly publicReference: string

    /**
     * 
     * @type {UpdateExtrasBookingDto}
     * @memberof PublicApiPublicControllerSetAmountExtrasInBooking
     */
    readonly updateExtrasBookingDto: UpdateExtrasBookingDto

    /**
     * 
     * @type {string}
     * @memberof PublicApiPublicControllerSetAmountExtrasInBooking
     */
    readonly timeZone?: string
}

/**
 * PublicApi - object-oriented interface
 * @export
 * @class PublicApi
 * @extends {BaseAPI}
 */
export class PublicApi extends BaseAPI {
    /**
     * 
     * @param {PublicApiPublicControllerAddBeachChairBookingToBookingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public publicControllerAddBeachChairBookingToBooking(requestParameters: PublicApiPublicControllerAddBeachChairBookingToBookingRequest, options?: any) {
        return PublicApiFp(this.configuration).publicControllerAddBeachChairBookingToBooking(requestParameters.bookingRef, requestParameters.publicReference, requestParameters.publicCreateBeachChairBookingBodyDto, requestParameters.timeZone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PublicApiPublicControllerAddExtrasRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public publicControllerAddExtras(requestParameters: PublicApiPublicControllerAddExtrasRequest, options?: any) {
        return PublicApiFp(this.configuration).publicControllerAddExtras(requestParameters.bookingReference, requestParameters.publicReference, requestParameters.createExtrasBookingBulkDto, requestParameters.timeZone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PublicApiPublicControllerChangePaymentMethodRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public publicControllerChangePaymentMethod(requestParameters: PublicApiPublicControllerChangePaymentMethodRequest, options?: any) {
        return PublicApiFp(this.configuration).publicControllerChangePaymentMethod(requestParameters.email, requestParameters.bookingRef, requestParameters.publicUpdatePaymentMethod, requestParameters.flow, requestParameters.timeZone, requestParameters.paymentId, requestParameters.token, requestParameters.payerID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PublicApiPublicControllerCheckBeachChairAvailabilityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public publicControllerCheckBeachChairAvailability(requestParameters: PublicApiPublicControllerCheckBeachChairAvailabilityRequest, options?: any) {
        return PublicApiFp(this.configuration).publicControllerCheckBeachChairAvailability(requestParameters.beachChairId, requestParameters.publicReference, requestParameters.start, requestParameters.end, requestParameters.timeZone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PublicApiPublicControllerConfirmReservationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public publicControllerConfirmReservation(requestParameters: PublicApiPublicControllerConfirmReservationRequest, options?: any) {
        return PublicApiFp(this.configuration).publicControllerConfirmReservation(requestParameters.email, requestParameters.publicReference, requestParameters.bookingReference, requestParameters.skjApiKey, requestParameters.bookingRef, requestParameters.timeZone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PublicApiPublicControllerGetBookingStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public publicControllerGetBookingStatus(requestParameters: PublicApiPublicControllerGetBookingStatusRequest, options?: any) {
        return PublicApiFp(this.configuration).publicControllerGetBookingStatus(requestParameters.bookingReference, requestParameters.publicReference, requestParameters.timeZone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PublicApiPublicControllerGetExtrasRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public publicControllerGetExtras(requestParameters: PublicApiPublicControllerGetExtrasRequest, options?: any) {
        return PublicApiFp(this.configuration).publicControllerGetExtras(requestParameters.publicReference, requestParameters.page, requestParameters.itemsPerPage, requestParameters.orderBy, requestParameters.orderDir, requestParameters.search, requestParameters.filterBy, requestParameters.filter, requestParameters.csv, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PublicApiPublicControllerGetMobileConfigRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public publicControllerGetMobileConfig(requestParameters: PublicApiPublicControllerGetMobileConfigRequest, options?: any) {
        return PublicApiFp(this.configuration).publicControllerGetMobileConfig(requestParameters.publicReference, requestParameters.skjApiKey, requestParameters.timeZone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PublicApiPublicControllerGetPublicChairsFiltersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public publicControllerGetPublicChairsFilters(requestParameters: PublicApiPublicControllerGetPublicChairsFiltersRequest, options?: any) {
        return PublicApiFp(this.configuration).publicControllerGetPublicChairsFilters(requestParameters.publicReference, requestParameters.timeZone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PublicApiPublicControllerGetPublicSectionsBeachChairsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public publicControllerGetPublicSectionsBeachChairs(requestParameters: PublicApiPublicControllerGetPublicSectionsBeachChairsRequest, options?: any) {
        return PublicApiFp(this.configuration).publicControllerGetPublicSectionsBeachChairs(requestParameters.id, requestParameters.start, requestParameters.publicReference, requestParameters.end, requestParameters.row, requestParameters.model, requestParameters.free, requestParameters.timeZone, requestParameters.lock, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PublicApiPublicControllerGetPublicSectionsCalendarRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public publicControllerGetPublicSectionsCalendar(requestParameters: PublicApiPublicControllerGetPublicSectionsCalendarRequest, options?: any) {
        return PublicApiFp(this.configuration).publicControllerGetPublicSectionsCalendar(requestParameters.id, requestParameters.publicReference, requestParameters.start, requestParameters.end, requestParameters.timeZone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PublicApiPublicControllerPublicAbortPaymentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public publicControllerPublicAbortPayment(requestParameters: PublicApiPublicControllerPublicAbortPaymentRequest, options?: any) {
        return PublicApiFp(this.configuration).publicControllerPublicAbortPayment(requestParameters.email, requestParameters.bookingRef, requestParameters.flow, requestParameters.timeZone, requestParameters.paymentId, requestParameters.token, requestParameters.payerID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PublicApiPublicControllerPublicAddCustomerToBookingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public publicControllerPublicAddCustomerToBooking(requestParameters: PublicApiPublicControllerPublicAddCustomerToBookingRequest, options?: any) {
        return PublicApiFp(this.configuration).publicControllerPublicAddCustomerToBooking(requestParameters.bookingRef, requestParameters.publicReference, requestParameters.publicCreateCustomerBodyDto, requestParameters.timeZone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PublicApiPublicControllerPublicBookingDetailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public publicControllerPublicBookingDetails(requestParameters: PublicApiPublicControllerPublicBookingDetailsRequest, options?: any) {
        return PublicApiFp(this.configuration).publicControllerPublicBookingDetails(requestParameters.email, requestParameters.bookingRef, requestParameters.flow, requestParameters.timeZone, requestParameters.paymentId, requestParameters.token, requestParameters.payerID, requestParameters.skjApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PublicApiPublicControllerPublicCancelBookingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public publicControllerPublicCancelBooking(requestParameters: PublicApiPublicControllerPublicCancelBookingRequest, options?: any) {
        return PublicApiFp(this.configuration).publicControllerPublicCancelBooking(requestParameters.email, requestParameters.bookingRef, requestParameters.flow, requestParameters.timeZone, requestParameters.paymentId, requestParameters.token, requestParameters.payerID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PublicApiPublicControllerPublicConfirmBookingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public publicControllerPublicConfirmBooking(requestParameters: PublicApiPublicControllerPublicConfirmBookingRequest, options?: any) {
        return PublicApiFp(this.configuration).publicControllerPublicConfirmBooking(requestParameters.email, requestParameters.bookingRef, requestParameters.flow, requestParameters.timeZone, requestParameters.paymentId, requestParameters.token, requestParameters.payerID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PublicApiPublicControllerPublicCreateBookingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public publicControllerPublicCreateBooking(requestParameters: PublicApiPublicControllerPublicCreateBookingRequest, options?: any) {
        return PublicApiFp(this.configuration).publicControllerPublicCreateBooking(requestParameters.publicReference, requestParameters.timeZone, requestParameters.source, requestParameters.affiliateReference, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PublicApiPublicControllerPublicLockHealthRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public publicControllerPublicLockHealth(requestParameters: PublicApiPublicControllerPublicLockHealthRequest, options?: any) {
        return PublicApiFp(this.configuration).publicControllerPublicLockHealth(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PublicApiPublicControllerPublicVendorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public publicControllerPublicVendor(requestParameters: PublicApiPublicControllerPublicVendorRequest, options?: any) {
        return PublicApiFp(this.configuration).publicControllerPublicVendor(requestParameters.publicReference, requestParameters.timeZone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PublicApiPublicControllerRemoveExtrasFromBookingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public publicControllerRemoveExtrasFromBooking(requestParameters: PublicApiPublicControllerRemoveExtrasFromBookingRequest, options?: any) {
        return PublicApiFp(this.configuration).publicControllerRemoveExtrasFromBooking(requestParameters.bookingReference, requestParameters.bookingExtrasId, requestParameters.publicReference, requestParameters.timeZone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PublicApiPublicControllerRemoveFromBookingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public publicControllerRemoveFromBooking(requestParameters: PublicApiPublicControllerRemoveFromBookingRequest, options?: any) {
        return PublicApiFp(this.configuration).publicControllerRemoveFromBooking(requestParameters.bookingRef, requestParameters.beachChairBookingId, requestParameters.publicReference, requestParameters.timeZone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PublicApiPublicControllerReportLockRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public publicControllerReportLock(requestParameters: PublicApiPublicControllerReportLockRequest, options?: any) {
        return PublicApiFp(this.configuration).publicControllerReportLock(requestParameters.skjApiKey, requestParameters.id, requestParameters.publicReference, requestParameters.publicLockReportBodyDto, requestParameters.timeZone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PublicApiPublicControllerSetAmountExtrasInBookingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public publicControllerSetAmountExtrasInBooking(requestParameters: PublicApiPublicControllerSetAmountExtrasInBookingRequest, options?: any) {
        return PublicApiFp(this.configuration).publicControllerSetAmountExtrasInBooking(requestParameters.bookingReference, requestParameters.bookingExtrasId, requestParameters.publicReference, requestParameters.updateExtrasBookingDto, requestParameters.timeZone, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RegionApi - axios parameter creator
 * @export
 */
export const RegionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateRegionBodyDto} createRegionBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regionsControllerCreate: async (createRegionBodyDto: CreateRegionBodyDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRegionBodyDto' is not null or undefined
            assertParamExists('regionsControllerCreate', 'createRegionBodyDto', createRegionBodyDto)
            const localVarPath = `/api/v1/destinations/regions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRegionBodyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regionsControllerDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('regionsControllerDelete', 'id', id)
            const localVarPath = `/api/v1/destinations/regions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {RegionsFiltersDto} [filters] 
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regionsControllerFindMany: async (page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, filters?: RegionsFiltersDto, csv?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/destinations/regions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['orderDir'] = orderDir;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (filterBy !== undefined) {
                localVarQueryParameter['filterBy'] = filterBy;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (filters !== undefined) {
                localVarQueryParameter['filters'] = filters;
            }

            if (csv !== undefined) {
                localVarQueryParameter['csv'] = csv;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regionsControllerFindOne: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('regionsControllerFindOne', 'id', id)
            const localVarPath = `/api/v1/destinations/regions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateRegionBodyDto} updateRegionBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regionsControllerUpdate: async (id: number, updateRegionBodyDto: UpdateRegionBodyDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('regionsControllerUpdate', 'id', id)
            // verify required parameter 'updateRegionBodyDto' is not null or undefined
            assertParamExists('regionsControllerUpdate', 'updateRegionBodyDto', updateRegionBodyDto)
            const localVarPath = `/api/v1/destinations/regions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRegionBodyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RegionApi - functional programming interface
 * @export
 */
export const RegionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RegionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateRegionBodyDto} createRegionBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async regionsControllerCreate(createRegionBodyDto: CreateRegionBodyDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegionResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.regionsControllerCreate(createRegionBodyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async regionsControllerDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.regionsControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {RegionsFiltersDto} [filters] 
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async regionsControllerFindMany(page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, filters?: RegionsFiltersDto, csv?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegionChunkResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.regionsControllerFindMany(page, itemsPerPage, orderBy, orderDir, search, filterBy, filter, filters, csv, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async regionsControllerFindOne(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegionResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.regionsControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateRegionBodyDto} updateRegionBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async regionsControllerUpdate(id: number, updateRegionBodyDto: UpdateRegionBodyDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.regionsControllerUpdate(id, updateRegionBodyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RegionApi - factory interface
 * @export
 */
export const RegionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RegionApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateRegionBodyDto} createRegionBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regionsControllerCreate(createRegionBodyDto: CreateRegionBodyDto, options?: any): AxiosPromise<RegionResponseDto> {
            return localVarFp.regionsControllerCreate(createRegionBodyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regionsControllerDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.regionsControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {RegionsFiltersDto} [filters] 
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regionsControllerFindMany(page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, filters?: RegionsFiltersDto, csv?: string, options?: any): AxiosPromise<RegionChunkResponseDto> {
            return localVarFp.regionsControllerFindMany(page, itemsPerPage, orderBy, orderDir, search, filterBy, filter, filters, csv, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regionsControllerFindOne(id: number, options?: any): AxiosPromise<RegionResponseDto> {
            return localVarFp.regionsControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateRegionBodyDto} updateRegionBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regionsControllerUpdate(id: number, updateRegionBodyDto: UpdateRegionBodyDto, options?: any): AxiosPromise<void> {
            return localVarFp.regionsControllerUpdate(id, updateRegionBodyDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for regionsControllerCreate operation in RegionApi.
 * @export
 * @interface RegionApiRegionsControllerCreateRequest
 */
export interface RegionApiRegionsControllerCreateRequest {
    /**
     * 
     * @type {CreateRegionBodyDto}
     * @memberof RegionApiRegionsControllerCreate
     */
    readonly createRegionBodyDto: CreateRegionBodyDto
}

/**
 * Request parameters for regionsControllerDelete operation in RegionApi.
 * @export
 * @interface RegionApiRegionsControllerDeleteRequest
 */
export interface RegionApiRegionsControllerDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof RegionApiRegionsControllerDelete
     */
    readonly id: number
}

/**
 * Request parameters for regionsControllerFindMany operation in RegionApi.
 * @export
 * @interface RegionApiRegionsControllerFindManyRequest
 */
export interface RegionApiRegionsControllerFindManyRequest {
    /**
     * Number of the page to fetch
     * @type {number}
     * @memberof RegionApiRegionsControllerFindMany
     */
    readonly page?: number

    /**
     * Number of items per page
     * @type {number}
     * @memberof RegionApiRegionsControllerFindMany
     */
    readonly itemsPerPage?: number

    /**
     * Name of the property to order by
     * @type {string}
     * @memberof RegionApiRegionsControllerFindMany
     */
    readonly orderBy?: string

    /**
     * Value which specify direction of the order
     * @type {OrderByDirection}
     * @memberof RegionApiRegionsControllerFindMany
     */
    readonly orderDir?: OrderByDirection

    /**
     * Text which will be used to search through table data
     * @type {string}
     * @memberof RegionApiRegionsControllerFindMany
     */
    readonly search?: string

    /**
     * Name of the property to filter by
     * @type {string}
     * @memberof RegionApiRegionsControllerFindMany
     */
    readonly filterBy?: string

    /**
     * Value which will be used to filter table data
     * @type {string}
     * @memberof RegionApiRegionsControllerFindMany
     */
    readonly filter?: string

    /**
     * 
     * @type {RegionsFiltersDto}
     * @memberof RegionApiRegionsControllerFindMany
     */
    readonly filters?: RegionsFiltersDto

    /**
     * 
     * @type {string}
     * @memberof RegionApiRegionsControllerFindMany
     */
    readonly csv?: string
}

/**
 * Request parameters for regionsControllerFindOne operation in RegionApi.
 * @export
 * @interface RegionApiRegionsControllerFindOneRequest
 */
export interface RegionApiRegionsControllerFindOneRequest {
    /**
     * 
     * @type {number}
     * @memberof RegionApiRegionsControllerFindOne
     */
    readonly id: number
}

/**
 * Request parameters for regionsControllerUpdate operation in RegionApi.
 * @export
 * @interface RegionApiRegionsControllerUpdateRequest
 */
export interface RegionApiRegionsControllerUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof RegionApiRegionsControllerUpdate
     */
    readonly id: number

    /**
     * 
     * @type {UpdateRegionBodyDto}
     * @memberof RegionApiRegionsControllerUpdate
     */
    readonly updateRegionBodyDto: UpdateRegionBodyDto
}

/**
 * RegionApi - object-oriented interface
 * @export
 * @class RegionApi
 * @extends {BaseAPI}
 */
export class RegionApi extends BaseAPI {
    /**
     * 
     * @param {RegionApiRegionsControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionApi
     */
    public regionsControllerCreate(requestParameters: RegionApiRegionsControllerCreateRequest, options?: any) {
        return RegionApiFp(this.configuration).regionsControllerCreate(requestParameters.createRegionBodyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RegionApiRegionsControllerDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionApi
     */
    public regionsControllerDelete(requestParameters: RegionApiRegionsControllerDeleteRequest, options?: any) {
        return RegionApiFp(this.configuration).regionsControllerDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RegionApiRegionsControllerFindManyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionApi
     */
    public regionsControllerFindMany(requestParameters: RegionApiRegionsControllerFindManyRequest = {}, options?: any) {
        return RegionApiFp(this.configuration).regionsControllerFindMany(requestParameters.page, requestParameters.itemsPerPage, requestParameters.orderBy, requestParameters.orderDir, requestParameters.search, requestParameters.filterBy, requestParameters.filter, requestParameters.filters, requestParameters.csv, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RegionApiRegionsControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionApi
     */
    public regionsControllerFindOne(requestParameters: RegionApiRegionsControllerFindOneRequest, options?: any) {
        return RegionApiFp(this.configuration).regionsControllerFindOne(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RegionApiRegionsControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegionApi
     */
    public regionsControllerUpdate(requestParameters: RegionApiRegionsControllerUpdateRequest, options?: any) {
        return RegionApiFp(this.configuration).regionsControllerUpdate(requestParameters.id, requestParameters.updateRegionBodyDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportsApi - axios parameter creator
 * @export
 */
export const ReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} reportType 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [bookingIds] 
         * @param {string} [userReport] 
         * @param {string} [sortBy] 
         * @param {string} [createdAtPeriod] 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsControllerGenerateReportPDF: async (reportType: string, startDate?: string, endDate?: string, bookingIds?: string, userReport?: string, sortBy?: string, createdAtPeriod?: string, timeZone?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportType' is not null or undefined
            assertParamExists('reportsControllerGenerateReportPDF', 'reportType', reportType)
            const localVarPath = `/api/v1/features/reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (reportType !== undefined) {
                localVarQueryParameter['reportType'] = reportType;
            }

            if (bookingIds !== undefined) {
                localVarQueryParameter['bookingIds'] = bookingIds;
            }

            if (userReport !== undefined) {
                localVarQueryParameter['userReport'] = userReport;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (createdAtPeriod !== undefined) {
                localVarQueryParameter['createdAtPeriod'] = createdAtPeriod;
            }

            if (timeZone !== undefined) {
                localVarQueryParameter['timeZone'] = timeZone;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} vendorId 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsControllerGetCashReport: async (vendorId: number, date?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'vendorId' is not null or undefined
            assertParamExists('reportsControllerGetCashReport', 'vendorId', vendorId)
            const localVarPath = `/api/v1/features/reports/cash`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (vendorId !== undefined) {
                localVarQueryParameter['vendorId'] = vendorId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reportNumber 
         * @param {string} reportType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsControllerGetReport: async (reportNumber: string, reportType: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportNumber' is not null or undefined
            assertParamExists('reportsControllerGetReport', 'reportNumber', reportNumber)
            // verify required parameter 'reportType' is not null or undefined
            assertParamExists('reportsControllerGetReport', 'reportType', reportType)
            const localVarPath = `/api/v1/features/reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (reportNumber !== undefined) {
                localVarQueryParameter['reportNumber'] = reportNumber;
            }

            if (reportType !== undefined) {
                localVarQueryParameter['reportType'] = reportType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} date 
         * @param {number} sectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsControllerGetSectionsAvailabilityReport: async (date: string, sectionId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('reportsControllerGetSectionsAvailabilityReport', 'date', date)
            // verify required parameter 'sectionId' is not null or undefined
            assertParamExists('reportsControllerGetSectionsAvailabilityReport', 'sectionId', sectionId)
            const localVarPath = `/api/v1/features/reports/sections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (sectionId !== undefined) {
                localVarQueryParameter['sectionId'] = sectionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsApi - functional programming interface
 * @export
 */
export const ReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} reportType 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [bookingIds] 
         * @param {string} [userReport] 
         * @param {string} [sortBy] 
         * @param {string} [createdAtPeriod] 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportsControllerGenerateReportPDF(reportType: string, startDate?: string, endDate?: string, bookingIds?: string, userReport?: string, sortBy?: string, createdAtPeriod?: string, timeZone?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportsControllerGenerateReportPDF(reportType, startDate, endDate, bookingIds, userReport, sortBy, createdAtPeriod, timeZone, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} vendorId 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportsControllerGetCashReport(vendorId: number, date?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CashStatisticsReportDtoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportsControllerGetCashReport(vendorId, date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reportNumber 
         * @param {string} reportType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportsControllerGetReport(reportNumber: string, reportType: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportsControllerGetReport(reportNumber, reportType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} date 
         * @param {number} sectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportsControllerGetSectionsAvailabilityReport(date: string, sectionId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SectionsStatisticsResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportsControllerGetSectionsAvailabilityReport(date, sectionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsApi - factory interface
 * @export
 */
export const ReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} reportType 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {string} [bookingIds] 
         * @param {string} [userReport] 
         * @param {string} [sortBy] 
         * @param {string} [createdAtPeriod] 
         * @param {string} [timeZone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsControllerGenerateReportPDF(reportType: string, startDate?: string, endDate?: string, bookingIds?: string, userReport?: string, sortBy?: string, createdAtPeriod?: string, timeZone?: string, options?: any): AxiosPromise<ReportResponseDto> {
            return localVarFp.reportsControllerGenerateReportPDF(reportType, startDate, endDate, bookingIds, userReport, sortBy, createdAtPeriod, timeZone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} vendorId 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsControllerGetCashReport(vendorId: number, date?: string, options?: any): AxiosPromise<CashStatisticsReportDtoResponse> {
            return localVarFp.reportsControllerGetCashReport(vendorId, date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reportNumber 
         * @param {string} reportType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsControllerGetReport(reportNumber: string, reportType: string, options?: any): AxiosPromise<ReportResponseDto> {
            return localVarFp.reportsControllerGetReport(reportNumber, reportType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} date 
         * @param {number} sectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsControllerGetSectionsAvailabilityReport(date: string, sectionId: number, options?: any): AxiosPromise<Array<SectionsStatisticsResponseDto>> {
            return localVarFp.reportsControllerGetSectionsAvailabilityReport(date, sectionId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for reportsControllerGenerateReportPDF operation in ReportsApi.
 * @export
 * @interface ReportsApiReportsControllerGenerateReportPDFRequest
 */
export interface ReportsApiReportsControllerGenerateReportPDFRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsApiReportsControllerGenerateReportPDF
     */
    readonly reportType: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiReportsControllerGenerateReportPDF
     */
    readonly startDate?: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiReportsControllerGenerateReportPDF
     */
    readonly endDate?: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiReportsControllerGenerateReportPDF
     */
    readonly bookingIds?: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiReportsControllerGenerateReportPDF
     */
    readonly userReport?: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiReportsControllerGenerateReportPDF
     */
    readonly sortBy?: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiReportsControllerGenerateReportPDF
     */
    readonly createdAtPeriod?: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiReportsControllerGenerateReportPDF
     */
    readonly timeZone?: string
}

/**
 * Request parameters for reportsControllerGetCashReport operation in ReportsApi.
 * @export
 * @interface ReportsApiReportsControllerGetCashReportRequest
 */
export interface ReportsApiReportsControllerGetCashReportRequest {
    /**
     * 
     * @type {number}
     * @memberof ReportsApiReportsControllerGetCashReport
     */
    readonly vendorId: number

    /**
     * 
     * @type {string}
     * @memberof ReportsApiReportsControllerGetCashReport
     */
    readonly date?: string
}

/**
 * Request parameters for reportsControllerGetReport operation in ReportsApi.
 * @export
 * @interface ReportsApiReportsControllerGetReportRequest
 */
export interface ReportsApiReportsControllerGetReportRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsApiReportsControllerGetReport
     */
    readonly reportNumber: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiReportsControllerGetReport
     */
    readonly reportType: string
}

/**
 * Request parameters for reportsControllerGetSectionsAvailabilityReport operation in ReportsApi.
 * @export
 * @interface ReportsApiReportsControllerGetSectionsAvailabilityReportRequest
 */
export interface ReportsApiReportsControllerGetSectionsAvailabilityReportRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsApiReportsControllerGetSectionsAvailabilityReport
     */
    readonly date: string

    /**
     * 
     * @type {number}
     * @memberof ReportsApiReportsControllerGetSectionsAvailabilityReport
     */
    readonly sectionId: number
}

/**
 * ReportsApi - object-oriented interface
 * @export
 * @class ReportsApi
 * @extends {BaseAPI}
 */
export class ReportsApi extends BaseAPI {
    /**
     * 
     * @param {ReportsApiReportsControllerGenerateReportPDFRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public reportsControllerGenerateReportPDF(requestParameters: ReportsApiReportsControllerGenerateReportPDFRequest, options?: any) {
        return ReportsApiFp(this.configuration).reportsControllerGenerateReportPDF(requestParameters.reportType, requestParameters.startDate, requestParameters.endDate, requestParameters.bookingIds, requestParameters.userReport, requestParameters.sortBy, requestParameters.createdAtPeriod, requestParameters.timeZone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportsApiReportsControllerGetCashReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public reportsControllerGetCashReport(requestParameters: ReportsApiReportsControllerGetCashReportRequest, options?: any) {
        return ReportsApiFp(this.configuration).reportsControllerGetCashReport(requestParameters.vendorId, requestParameters.date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportsApiReportsControllerGetReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public reportsControllerGetReport(requestParameters: ReportsApiReportsControllerGetReportRequest, options?: any) {
        return ReportsApiFp(this.configuration).reportsControllerGetReport(requestParameters.reportNumber, requestParameters.reportType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportsApiReportsControllerGetSectionsAvailabilityReportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public reportsControllerGetSectionsAvailabilityReport(requestParameters: ReportsApiReportsControllerGetSectionsAvailabilityReportRequest, options?: any) {
        return ReportsApiFp(this.configuration).reportsControllerGetSectionsAvailabilityReport(requestParameters.date, requestParameters.sectionId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RowApi - axios parameter creator
 * @export
 */
export const RowApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateRowBodyDto} createRowBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rowsControllerCreate: async (createRowBodyDto: CreateRowBodyDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRowBodyDto' is not null or undefined
            assertParamExists('rowsControllerCreate', 'createRowBodyDto', createRowBodyDto)
            const localVarPath = `/api/v1/destinations/rows`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRowBodyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rowsControllerDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rowsControllerDelete', 'id', id)
            const localVarPath = `/api/v1/destinations/rows/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {RowsFiltersDto} [filters] 
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rowsControllerFindMany: async (page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, filters?: RowsFiltersDto, csv?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/destinations/rows`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['orderDir'] = orderDir;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (filterBy !== undefined) {
                localVarQueryParameter['filterBy'] = filterBy;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (filters !== undefined) {
                localVarQueryParameter['filters'] = filters;
            }

            if (csv !== undefined) {
                localVarQueryParameter['csv'] = csv;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rowsControllerFindOne: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rowsControllerFindOne', 'id', id)
            const localVarPath = `/api/v1/destinations/rows/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateRowBodyDto} updateRowBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rowsControllerUpdate: async (id: number, updateRowBodyDto: UpdateRowBodyDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rowsControllerUpdate', 'id', id)
            // verify required parameter 'updateRowBodyDto' is not null or undefined
            assertParamExists('rowsControllerUpdate', 'updateRowBodyDto', updateRowBodyDto)
            const localVarPath = `/api/v1/destinations/rows/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRowBodyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RowApi - functional programming interface
 * @export
 */
export const RowApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RowApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateRowBodyDto} createRowBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rowsControllerCreate(createRowBodyDto: CreateRowBodyDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RowResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rowsControllerCreate(createRowBodyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rowsControllerDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rowsControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {RowsFiltersDto} [filters] 
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rowsControllerFindMany(page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, filters?: RowsFiltersDto, csv?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RowChunkResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rowsControllerFindMany(page, itemsPerPage, orderBy, orderDir, search, filterBy, filter, filters, csv, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rowsControllerFindOne(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RowResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rowsControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateRowBodyDto} updateRowBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rowsControllerUpdate(id: number, updateRowBodyDto: UpdateRowBodyDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rowsControllerUpdate(id, updateRowBodyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RowApi - factory interface
 * @export
 */
export const RowApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RowApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateRowBodyDto} createRowBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rowsControllerCreate(createRowBodyDto: CreateRowBodyDto, options?: any): AxiosPromise<RowResponseDto> {
            return localVarFp.rowsControllerCreate(createRowBodyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rowsControllerDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.rowsControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {RowsFiltersDto} [filters] 
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rowsControllerFindMany(page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, filters?: RowsFiltersDto, csv?: string, options?: any): AxiosPromise<RowChunkResponseDto> {
            return localVarFp.rowsControllerFindMany(page, itemsPerPage, orderBy, orderDir, search, filterBy, filter, filters, csv, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rowsControllerFindOne(id: number, options?: any): AxiosPromise<RowResponseDto> {
            return localVarFp.rowsControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateRowBodyDto} updateRowBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rowsControllerUpdate(id: number, updateRowBodyDto: UpdateRowBodyDto, options?: any): AxiosPromise<void> {
            return localVarFp.rowsControllerUpdate(id, updateRowBodyDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for rowsControllerCreate operation in RowApi.
 * @export
 * @interface RowApiRowsControllerCreateRequest
 */
export interface RowApiRowsControllerCreateRequest {
    /**
     * 
     * @type {CreateRowBodyDto}
     * @memberof RowApiRowsControllerCreate
     */
    readonly createRowBodyDto: CreateRowBodyDto
}

/**
 * Request parameters for rowsControllerDelete operation in RowApi.
 * @export
 * @interface RowApiRowsControllerDeleteRequest
 */
export interface RowApiRowsControllerDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof RowApiRowsControllerDelete
     */
    readonly id: number
}

/**
 * Request parameters for rowsControllerFindMany operation in RowApi.
 * @export
 * @interface RowApiRowsControllerFindManyRequest
 */
export interface RowApiRowsControllerFindManyRequest {
    /**
     * Number of the page to fetch
     * @type {number}
     * @memberof RowApiRowsControllerFindMany
     */
    readonly page?: number

    /**
     * Number of items per page
     * @type {number}
     * @memberof RowApiRowsControllerFindMany
     */
    readonly itemsPerPage?: number

    /**
     * Name of the property to order by
     * @type {string}
     * @memberof RowApiRowsControllerFindMany
     */
    readonly orderBy?: string

    /**
     * Value which specify direction of the order
     * @type {OrderByDirection}
     * @memberof RowApiRowsControllerFindMany
     */
    readonly orderDir?: OrderByDirection

    /**
     * Text which will be used to search through table data
     * @type {string}
     * @memberof RowApiRowsControllerFindMany
     */
    readonly search?: string

    /**
     * Name of the property to filter by
     * @type {string}
     * @memberof RowApiRowsControllerFindMany
     */
    readonly filterBy?: string

    /**
     * Value which will be used to filter table data
     * @type {string}
     * @memberof RowApiRowsControllerFindMany
     */
    readonly filter?: string

    /**
     * 
     * @type {RowsFiltersDto}
     * @memberof RowApiRowsControllerFindMany
     */
    readonly filters?: RowsFiltersDto

    /**
     * 
     * @type {string}
     * @memberof RowApiRowsControllerFindMany
     */
    readonly csv?: string
}

/**
 * Request parameters for rowsControllerFindOne operation in RowApi.
 * @export
 * @interface RowApiRowsControllerFindOneRequest
 */
export interface RowApiRowsControllerFindOneRequest {
    /**
     * 
     * @type {number}
     * @memberof RowApiRowsControllerFindOne
     */
    readonly id: number
}

/**
 * Request parameters for rowsControllerUpdate operation in RowApi.
 * @export
 * @interface RowApiRowsControllerUpdateRequest
 */
export interface RowApiRowsControllerUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof RowApiRowsControllerUpdate
     */
    readonly id: number

    /**
     * 
     * @type {UpdateRowBodyDto}
     * @memberof RowApiRowsControllerUpdate
     */
    readonly updateRowBodyDto: UpdateRowBodyDto
}

/**
 * RowApi - object-oriented interface
 * @export
 * @class RowApi
 * @extends {BaseAPI}
 */
export class RowApi extends BaseAPI {
    /**
     * 
     * @param {RowApiRowsControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RowApi
     */
    public rowsControllerCreate(requestParameters: RowApiRowsControllerCreateRequest, options?: any) {
        return RowApiFp(this.configuration).rowsControllerCreate(requestParameters.createRowBodyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RowApiRowsControllerDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RowApi
     */
    public rowsControllerDelete(requestParameters: RowApiRowsControllerDeleteRequest, options?: any) {
        return RowApiFp(this.configuration).rowsControllerDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RowApiRowsControllerFindManyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RowApi
     */
    public rowsControllerFindMany(requestParameters: RowApiRowsControllerFindManyRequest = {}, options?: any) {
        return RowApiFp(this.configuration).rowsControllerFindMany(requestParameters.page, requestParameters.itemsPerPage, requestParameters.orderBy, requestParameters.orderDir, requestParameters.search, requestParameters.filterBy, requestParameters.filter, requestParameters.filters, requestParameters.csv, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RowApiRowsControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RowApi
     */
    public rowsControllerFindOne(requestParameters: RowApiRowsControllerFindOneRequest, options?: any) {
        return RowApiFp(this.configuration).rowsControllerFindOne(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RowApiRowsControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RowApi
     */
    public rowsControllerUpdate(requestParameters: RowApiRowsControllerUpdateRequest, options?: any) {
        return RowApiFp(this.configuration).rowsControllerUpdate(requestParameters.id, requestParameters.updateRowBodyDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SectionApi - axios parameter creator
 * @export
 */
export const SectionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateSectionBodyDto} createSectionBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sectionsControllerCreate: async (createSectionBodyDto: CreateSectionBodyDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSectionBodyDto' is not null or undefined
            assertParamExists('sectionsControllerCreate', 'createSectionBodyDto', createSectionBodyDto)
            const localVarPath = `/api/v1/destinations/sections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSectionBodyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sectionsControllerDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sectionsControllerDelete', 'id', id)
            const localVarPath = `/api/v1/destinations/sections/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {SectionsFiltersDto} [filters] 
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sectionsControllerFindMany: async (page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, filters?: SectionsFiltersDto, csv?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/destinations/sections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['orderDir'] = orderDir;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (filterBy !== undefined) {
                localVarQueryParameter['filterBy'] = filterBy;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (filters !== undefined) {
                localVarQueryParameter['filters'] = filters;
            }

            if (csv !== undefined) {
                localVarQueryParameter['csv'] = csv;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sectionsControllerFindOne: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sectionsControllerFindOne', 'id', id)
            const localVarPath = `/api/v1/destinations/sections/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sectionsControllerFindRowsBySectionId: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sectionsControllerFindRowsBySectionId', 'id', id)
            const localVarPath = `/api/v1/destinations/sections/{id}/rows`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateSectionBodyDto} updateSectionBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sectionsControllerUpdate: async (id: number, updateSectionBodyDto: UpdateSectionBodyDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sectionsControllerUpdate', 'id', id)
            // verify required parameter 'updateSectionBodyDto' is not null or undefined
            assertParamExists('sectionsControllerUpdate', 'updateSectionBodyDto', updateSectionBodyDto)
            const localVarPath = `/api/v1/destinations/sections/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSectionBodyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SectionApi - functional programming interface
 * @export
 */
export const SectionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SectionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateSectionBodyDto} createSectionBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sectionsControllerCreate(createSectionBodyDto: CreateSectionBodyDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectionResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sectionsControllerCreate(createSectionBodyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sectionsControllerDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sectionsControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {SectionsFiltersDto} [filters] 
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sectionsControllerFindMany(page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, filters?: SectionsFiltersDto, csv?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectionChunkResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sectionsControllerFindMany(page, itemsPerPage, orderBy, orderDir, search, filterBy, filter, filters, csv, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sectionsControllerFindOne(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SectionResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sectionsControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sectionsControllerFindRowsBySectionId(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RowResponseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sectionsControllerFindRowsBySectionId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateSectionBodyDto} updateSectionBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sectionsControllerUpdate(id: number, updateSectionBodyDto: UpdateSectionBodyDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sectionsControllerUpdate(id, updateSectionBodyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SectionApi - factory interface
 * @export
 */
export const SectionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SectionApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateSectionBodyDto} createSectionBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sectionsControllerCreate(createSectionBodyDto: CreateSectionBodyDto, options?: any): AxiosPromise<SectionResponseDto> {
            return localVarFp.sectionsControllerCreate(createSectionBodyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sectionsControllerDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.sectionsControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {SectionsFiltersDto} [filters] 
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sectionsControllerFindMany(page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, filters?: SectionsFiltersDto, csv?: string, options?: any): AxiosPromise<SectionChunkResponseDto> {
            return localVarFp.sectionsControllerFindMany(page, itemsPerPage, orderBy, orderDir, search, filterBy, filter, filters, csv, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sectionsControllerFindOne(id: number, options?: any): AxiosPromise<SectionResponseDto> {
            return localVarFp.sectionsControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sectionsControllerFindRowsBySectionId(id: number, options?: any): AxiosPromise<Array<RowResponseDto>> {
            return localVarFp.sectionsControllerFindRowsBySectionId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateSectionBodyDto} updateSectionBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sectionsControllerUpdate(id: number, updateSectionBodyDto: UpdateSectionBodyDto, options?: any): AxiosPromise<void> {
            return localVarFp.sectionsControllerUpdate(id, updateSectionBodyDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for sectionsControllerCreate operation in SectionApi.
 * @export
 * @interface SectionApiSectionsControllerCreateRequest
 */
export interface SectionApiSectionsControllerCreateRequest {
    /**
     * 
     * @type {CreateSectionBodyDto}
     * @memberof SectionApiSectionsControllerCreate
     */
    readonly createSectionBodyDto: CreateSectionBodyDto
}

/**
 * Request parameters for sectionsControllerDelete operation in SectionApi.
 * @export
 * @interface SectionApiSectionsControllerDeleteRequest
 */
export interface SectionApiSectionsControllerDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof SectionApiSectionsControllerDelete
     */
    readonly id: number
}

/**
 * Request parameters for sectionsControllerFindMany operation in SectionApi.
 * @export
 * @interface SectionApiSectionsControllerFindManyRequest
 */
export interface SectionApiSectionsControllerFindManyRequest {
    /**
     * Number of the page to fetch
     * @type {number}
     * @memberof SectionApiSectionsControllerFindMany
     */
    readonly page?: number

    /**
     * Number of items per page
     * @type {number}
     * @memberof SectionApiSectionsControllerFindMany
     */
    readonly itemsPerPage?: number

    /**
     * Name of the property to order by
     * @type {string}
     * @memberof SectionApiSectionsControllerFindMany
     */
    readonly orderBy?: string

    /**
     * Value which specify direction of the order
     * @type {OrderByDirection}
     * @memberof SectionApiSectionsControllerFindMany
     */
    readonly orderDir?: OrderByDirection

    /**
     * Text which will be used to search through table data
     * @type {string}
     * @memberof SectionApiSectionsControllerFindMany
     */
    readonly search?: string

    /**
     * Name of the property to filter by
     * @type {string}
     * @memberof SectionApiSectionsControllerFindMany
     */
    readonly filterBy?: string

    /**
     * Value which will be used to filter table data
     * @type {string}
     * @memberof SectionApiSectionsControllerFindMany
     */
    readonly filter?: string

    /**
     * 
     * @type {SectionsFiltersDto}
     * @memberof SectionApiSectionsControllerFindMany
     */
    readonly filters?: SectionsFiltersDto

    /**
     * 
     * @type {string}
     * @memberof SectionApiSectionsControllerFindMany
     */
    readonly csv?: string
}

/**
 * Request parameters for sectionsControllerFindOne operation in SectionApi.
 * @export
 * @interface SectionApiSectionsControllerFindOneRequest
 */
export interface SectionApiSectionsControllerFindOneRequest {
    /**
     * 
     * @type {number}
     * @memberof SectionApiSectionsControllerFindOne
     */
    readonly id: number
}

/**
 * Request parameters for sectionsControllerFindRowsBySectionId operation in SectionApi.
 * @export
 * @interface SectionApiSectionsControllerFindRowsBySectionIdRequest
 */
export interface SectionApiSectionsControllerFindRowsBySectionIdRequest {
    /**
     * 
     * @type {number}
     * @memberof SectionApiSectionsControllerFindRowsBySectionId
     */
    readonly id: number
}

/**
 * Request parameters for sectionsControllerUpdate operation in SectionApi.
 * @export
 * @interface SectionApiSectionsControllerUpdateRequest
 */
export interface SectionApiSectionsControllerUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof SectionApiSectionsControllerUpdate
     */
    readonly id: number

    /**
     * 
     * @type {UpdateSectionBodyDto}
     * @memberof SectionApiSectionsControllerUpdate
     */
    readonly updateSectionBodyDto: UpdateSectionBodyDto
}

/**
 * SectionApi - object-oriented interface
 * @export
 * @class SectionApi
 * @extends {BaseAPI}
 */
export class SectionApi extends BaseAPI {
    /**
     * 
     * @param {SectionApiSectionsControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionApi
     */
    public sectionsControllerCreate(requestParameters: SectionApiSectionsControllerCreateRequest, options?: any) {
        return SectionApiFp(this.configuration).sectionsControllerCreate(requestParameters.createSectionBodyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SectionApiSectionsControllerDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionApi
     */
    public sectionsControllerDelete(requestParameters: SectionApiSectionsControllerDeleteRequest, options?: any) {
        return SectionApiFp(this.configuration).sectionsControllerDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SectionApiSectionsControllerFindManyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionApi
     */
    public sectionsControllerFindMany(requestParameters: SectionApiSectionsControllerFindManyRequest = {}, options?: any) {
        return SectionApiFp(this.configuration).sectionsControllerFindMany(requestParameters.page, requestParameters.itemsPerPage, requestParameters.orderBy, requestParameters.orderDir, requestParameters.search, requestParameters.filterBy, requestParameters.filter, requestParameters.filters, requestParameters.csv, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SectionApiSectionsControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionApi
     */
    public sectionsControllerFindOne(requestParameters: SectionApiSectionsControllerFindOneRequest, options?: any) {
        return SectionApiFp(this.configuration).sectionsControllerFindOne(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SectionApiSectionsControllerFindRowsBySectionIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionApi
     */
    public sectionsControllerFindRowsBySectionId(requestParameters: SectionApiSectionsControllerFindRowsBySectionIdRequest, options?: any) {
        return SectionApiFp(this.configuration).sectionsControllerFindRowsBySectionId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SectionApiSectionsControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionApi
     */
    public sectionsControllerUpdate(requestParameters: SectionApiSectionsControllerUpdateRequest, options?: any) {
        return SectionApiFp(this.configuration).sectionsControllerUpdate(requestParameters.id, requestParameters.updateSectionBodyDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StripeApi - axios parameter creator
 * @export
 */
export const StripeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} email 
         * @param {string} bookingRef 
         * @param {PaymentFlow} [flow] 
         * @param {string} [timeZone] 
         * @param {string} [paymentId] 
         * @param {string} [token] 
         * @param {string} [payerID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerCreateStripeCheckoutSession: async (email: string, bookingRef: string, flow?: PaymentFlow, timeZone?: string, paymentId?: string, token?: string, payerID?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('paymentsControllerCreateStripeCheckoutSession', 'email', email)
            // verify required parameter 'bookingRef' is not null or undefined
            assertParamExists('paymentsControllerCreateStripeCheckoutSession', 'bookingRef', bookingRef)
            const localVarPath = `/api/v1/payments/stripe/checkout-session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (bookingRef !== undefined) {
                localVarQueryParameter['bookingRef'] = bookingRef;
            }

            if (flow !== undefined) {
                localVarQueryParameter['flow'] = flow;
            }

            if (timeZone !== undefined) {
                localVarQueryParameter['timeZone'] = timeZone;
            }

            if (paymentId !== undefined) {
                localVarQueryParameter['paymentId'] = paymentId;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (payerID !== undefined) {
                localVarQueryParameter['PayerID'] = payerID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} stripeSignature Stripe signature used to authenticate the request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerHandleStripeMessage: async (stripeSignature: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'stripeSignature' is not null or undefined
            assertParamExists('paymentsControllerHandleStripeMessage', 'stripeSignature', stripeSignature)
            const localVarPath = `/api/v1/payments/stripe/webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stripeSignature !== undefined && stripeSignature !== null) {
                localVarHeaderParameter['stripe-signature'] = String(stripeSignature);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StripeApi - functional programming interface
 * @export
 */
export const StripeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StripeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} email 
         * @param {string} bookingRef 
         * @param {PaymentFlow} [flow] 
         * @param {string} [timeZone] 
         * @param {string} [paymentId] 
         * @param {string} [token] 
         * @param {string} [payerID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsControllerCreateStripeCheckoutSession(email: string, bookingRef: string, flow?: PaymentFlow, timeZone?: string, paymentId?: string, token?: string, payerID?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsControllerCreateStripeCheckoutSession(email, bookingRef, flow, timeZone, paymentId, token, payerID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} stripeSignature Stripe signature used to authenticate the request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsControllerHandleStripeMessage(stripeSignature: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsControllerHandleStripeMessage(stripeSignature, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StripeApi - factory interface
 * @export
 */
export const StripeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StripeApiFp(configuration)
    return {
        /**
         * 
         * @param {string} email 
         * @param {string} bookingRef 
         * @param {PaymentFlow} [flow] 
         * @param {string} [timeZone] 
         * @param {string} [paymentId] 
         * @param {string} [token] 
         * @param {string} [payerID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerCreateStripeCheckoutSession(email: string, bookingRef: string, flow?: PaymentFlow, timeZone?: string, paymentId?: string, token?: string, payerID?: string, options?: any): AxiosPromise<PaymentsResponseDto> {
            return localVarFp.paymentsControllerCreateStripeCheckoutSession(email, bookingRef, flow, timeZone, paymentId, token, payerID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} stripeSignature Stripe signature used to authenticate the request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsControllerHandleStripeMessage(stripeSignature: string, options?: any): AxiosPromise<void> {
            return localVarFp.paymentsControllerHandleStripeMessage(stripeSignature, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for paymentsControllerCreateStripeCheckoutSession operation in StripeApi.
 * @export
 * @interface StripeApiPaymentsControllerCreateStripeCheckoutSessionRequest
 */
export interface StripeApiPaymentsControllerCreateStripeCheckoutSessionRequest {
    /**
     * 
     * @type {string}
     * @memberof StripeApiPaymentsControllerCreateStripeCheckoutSession
     */
    readonly email: string

    /**
     * 
     * @type {string}
     * @memberof StripeApiPaymentsControllerCreateStripeCheckoutSession
     */
    readonly bookingRef: string

    /**
     * 
     * @type {PaymentFlow}
     * @memberof StripeApiPaymentsControllerCreateStripeCheckoutSession
     */
    readonly flow?: PaymentFlow

    /**
     * 
     * @type {string}
     * @memberof StripeApiPaymentsControllerCreateStripeCheckoutSession
     */
    readonly timeZone?: string

    /**
     * 
     * @type {string}
     * @memberof StripeApiPaymentsControllerCreateStripeCheckoutSession
     */
    readonly paymentId?: string

    /**
     * 
     * @type {string}
     * @memberof StripeApiPaymentsControllerCreateStripeCheckoutSession
     */
    readonly token?: string

    /**
     * 
     * @type {string}
     * @memberof StripeApiPaymentsControllerCreateStripeCheckoutSession
     */
    readonly payerID?: string
}

/**
 * Request parameters for paymentsControllerHandleStripeMessage operation in StripeApi.
 * @export
 * @interface StripeApiPaymentsControllerHandleStripeMessageRequest
 */
export interface StripeApiPaymentsControllerHandleStripeMessageRequest {
    /**
     * Stripe signature used to authenticate the request
     * @type {string}
     * @memberof StripeApiPaymentsControllerHandleStripeMessage
     */
    readonly stripeSignature: string
}

/**
 * StripeApi - object-oriented interface
 * @export
 * @class StripeApi
 * @extends {BaseAPI}
 */
export class StripeApi extends BaseAPI {
    /**
     * 
     * @param {StripeApiPaymentsControllerCreateStripeCheckoutSessionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeApi
     */
    public paymentsControllerCreateStripeCheckoutSession(requestParameters: StripeApiPaymentsControllerCreateStripeCheckoutSessionRequest, options?: any) {
        return StripeApiFp(this.configuration).paymentsControllerCreateStripeCheckoutSession(requestParameters.email, requestParameters.bookingRef, requestParameters.flow, requestParameters.timeZone, requestParameters.paymentId, requestParameters.token, requestParameters.payerID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StripeApiPaymentsControllerHandleStripeMessageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StripeApi
     */
    public paymentsControllerHandleStripeMessage(requestParameters: StripeApiPaymentsControllerHandleStripeMessageRequest, options?: any) {
        return StripeApiFp(this.configuration).paymentsControllerHandleStripeMessage(requestParameters.stripeSignature, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {UpdateUserRolesDto} updateUserRolesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerChangeRoles: async (id: string, updateUserRolesDto: UpdateUserRolesDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersControllerChangeRoles', 'id', id)
            // verify required parameter 'updateUserRolesDto' is not null or undefined
            assertParamExists('usersControllerChangeRoles', 'updateUserRolesDto', updateUserRolesDto)
            const localVarPath = `/api/v1/users/users/{id}/roles`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserRolesDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateUserBodyDto} createUserBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerCreate: async (createUserBodyDto: CreateUserBodyDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUserBodyDto' is not null or undefined
            assertParamExists('usersControllerCreate', 'createUserBodyDto', createUserBodyDto)
            const localVarPath = `/api/v1/users/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserBodyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerDelete: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersControllerDelete', 'id', id)
            const localVarPath = `/api/v1/users/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerDisable: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersControllerDisable', 'id', id)
            const localVarPath = `/api/v1/users/users/{id}/disable`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerEnable: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersControllerEnable', 'id', id)
            const localVarPath = `/api/v1/users/users/{id}/enable`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {UsersFiltersDto} [filters] 
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerFindMany: async (page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, filters?: UsersFiltersDto, csv?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['orderDir'] = orderDir;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (filterBy !== undefined) {
                localVarQueryParameter['filterBy'] = filterBy;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (filters !== undefined) {
                localVarQueryParameter['filters'] = filters;
            }

            if (csv !== undefined) {
                localVarQueryParameter['csv'] = csv;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetCurrentUser: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/users/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetUser: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersControllerGetUser', 'id', id)
            const localVarPath = `/api/v1/users/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerResetPassword: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersControllerResetPassword', 'id', id)
            const localVarPath = `/api/v1/users/users/{id}/reset-password`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateUserBodyDto} updateUserBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdate: async (id: string, updateUserBodyDto: UpdateUserBodyDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersControllerUpdate', 'id', id)
            // verify required parameter 'updateUserBodyDto' is not null or undefined
            assertParamExists('usersControllerUpdate', 'updateUserBodyDto', updateUserBodyDto)
            const localVarPath = `/api/v1/users/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserBodyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {UpdateUserRolesDto} updateUserRolesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerChangeRoles(id: string, updateUserRolesDto: UpdateUserRolesDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerChangeRoles(id, updateUserRolesDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateUserBodyDto} createUserBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerCreate(createUserBodyDto: CreateUserBodyDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerCreate(createUserBodyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerDelete(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerDisable(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerDisable(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerEnable(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerEnable(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {UsersFiltersDto} [filters] 
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerFindMany(page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, filters?: UsersFiltersDto, csv?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUsersChunkDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerFindMany(page, itemsPerPage, orderBy, orderDir, search, filterBy, filter, filters, csv, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerGetCurrentUser(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerGetCurrentUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerGetUser(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerGetUser(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerResetPassword(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerResetPassword(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateUserBodyDto} updateUserBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerUpdate(id: string, updateUserBodyDto: UpdateUserBodyDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerUpdate(id, updateUserBodyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {UpdateUserRolesDto} updateUserRolesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerChangeRoles(id: string, updateUserRolesDto: UpdateUserRolesDto, options?: any): AxiosPromise<ResponseUserDto> {
            return localVarFp.usersControllerChangeRoles(id, updateUserRolesDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateUserBodyDto} createUserBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerCreate(createUserBodyDto: CreateUserBodyDto, options?: any): AxiosPromise<ResponseUserDto> {
            return localVarFp.usersControllerCreate(createUserBodyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.usersControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerDisable(id: string, options?: any): AxiosPromise<ResponseUserDto> {
            return localVarFp.usersControllerDisable(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerEnable(id: string, options?: any): AxiosPromise<ResponseUserDto> {
            return localVarFp.usersControllerEnable(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {UsersFiltersDto} [filters] 
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerFindMany(page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, filters?: UsersFiltersDto, csv?: string, options?: any): AxiosPromise<GetUsersChunkDto> {
            return localVarFp.usersControllerFindMany(page, itemsPerPage, orderBy, orderDir, search, filterBy, filter, filters, csv, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetCurrentUser(options?: any): AxiosPromise<ResponseUserDto> {
            return localVarFp.usersControllerGetCurrentUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetUser(id: string, options?: any): AxiosPromise<ResponseUserDto> {
            return localVarFp.usersControllerGetUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerResetPassword(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.usersControllerResetPassword(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateUserBodyDto} updateUserBodyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdate(id: string, updateUserBodyDto: UpdateUserBodyDto, options?: any): AxiosPromise<ResponseUserDto> {
            return localVarFp.usersControllerUpdate(id, updateUserBodyDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for usersControllerChangeRoles operation in UsersApi.
 * @export
 * @interface UsersApiUsersControllerChangeRolesRequest
 */
export interface UsersApiUsersControllerChangeRolesRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiUsersControllerChangeRoles
     */
    readonly id: string

    /**
     * 
     * @type {UpdateUserRolesDto}
     * @memberof UsersApiUsersControllerChangeRoles
     */
    readonly updateUserRolesDto: UpdateUserRolesDto
}

/**
 * Request parameters for usersControllerCreate operation in UsersApi.
 * @export
 * @interface UsersApiUsersControllerCreateRequest
 */
export interface UsersApiUsersControllerCreateRequest {
    /**
     * 
     * @type {CreateUserBodyDto}
     * @memberof UsersApiUsersControllerCreate
     */
    readonly createUserBodyDto: CreateUserBodyDto
}

/**
 * Request parameters for usersControllerDelete operation in UsersApi.
 * @export
 * @interface UsersApiUsersControllerDeleteRequest
 */
export interface UsersApiUsersControllerDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiUsersControllerDelete
     */
    readonly id: string
}

/**
 * Request parameters for usersControllerDisable operation in UsersApi.
 * @export
 * @interface UsersApiUsersControllerDisableRequest
 */
export interface UsersApiUsersControllerDisableRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiUsersControllerDisable
     */
    readonly id: string
}

/**
 * Request parameters for usersControllerEnable operation in UsersApi.
 * @export
 * @interface UsersApiUsersControllerEnableRequest
 */
export interface UsersApiUsersControllerEnableRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiUsersControllerEnable
     */
    readonly id: string
}

/**
 * Request parameters for usersControllerFindMany operation in UsersApi.
 * @export
 * @interface UsersApiUsersControllerFindManyRequest
 */
export interface UsersApiUsersControllerFindManyRequest {
    /**
     * Number of the page to fetch
     * @type {number}
     * @memberof UsersApiUsersControllerFindMany
     */
    readonly page?: number

    /**
     * Number of items per page
     * @type {number}
     * @memberof UsersApiUsersControllerFindMany
     */
    readonly itemsPerPage?: number

    /**
     * Name of the property to order by
     * @type {string}
     * @memberof UsersApiUsersControllerFindMany
     */
    readonly orderBy?: string

    /**
     * Value which specify direction of the order
     * @type {OrderByDirection}
     * @memberof UsersApiUsersControllerFindMany
     */
    readonly orderDir?: OrderByDirection

    /**
     * Text which will be used to search through table data
     * @type {string}
     * @memberof UsersApiUsersControllerFindMany
     */
    readonly search?: string

    /**
     * Name of the property to filter by
     * @type {string}
     * @memberof UsersApiUsersControllerFindMany
     */
    readonly filterBy?: string

    /**
     * Value which will be used to filter table data
     * @type {string}
     * @memberof UsersApiUsersControllerFindMany
     */
    readonly filter?: string

    /**
     * 
     * @type {UsersFiltersDto}
     * @memberof UsersApiUsersControllerFindMany
     */
    readonly filters?: UsersFiltersDto

    /**
     * 
     * @type {string}
     * @memberof UsersApiUsersControllerFindMany
     */
    readonly csv?: string
}

/**
 * Request parameters for usersControllerGetUser operation in UsersApi.
 * @export
 * @interface UsersApiUsersControllerGetUserRequest
 */
export interface UsersApiUsersControllerGetUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiUsersControllerGetUser
     */
    readonly id: string
}

/**
 * Request parameters for usersControllerResetPassword operation in UsersApi.
 * @export
 * @interface UsersApiUsersControllerResetPasswordRequest
 */
export interface UsersApiUsersControllerResetPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiUsersControllerResetPassword
     */
    readonly id: string
}

/**
 * Request parameters for usersControllerUpdate operation in UsersApi.
 * @export
 * @interface UsersApiUsersControllerUpdateRequest
 */
export interface UsersApiUsersControllerUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiUsersControllerUpdate
     */
    readonly id: string

    /**
     * 
     * @type {UpdateUserBodyDto}
     * @memberof UsersApiUsersControllerUpdate
     */
    readonly updateUserBodyDto: UpdateUserBodyDto
}

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @param {UsersApiUsersControllerChangeRolesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerChangeRoles(requestParameters: UsersApiUsersControllerChangeRolesRequest, options?: any) {
        return UsersApiFp(this.configuration).usersControllerChangeRoles(requestParameters.id, requestParameters.updateUserRolesDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiUsersControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerCreate(requestParameters: UsersApiUsersControllerCreateRequest, options?: any) {
        return UsersApiFp(this.configuration).usersControllerCreate(requestParameters.createUserBodyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiUsersControllerDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerDelete(requestParameters: UsersApiUsersControllerDeleteRequest, options?: any) {
        return UsersApiFp(this.configuration).usersControllerDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiUsersControllerDisableRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerDisable(requestParameters: UsersApiUsersControllerDisableRequest, options?: any) {
        return UsersApiFp(this.configuration).usersControllerDisable(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiUsersControllerEnableRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerEnable(requestParameters: UsersApiUsersControllerEnableRequest, options?: any) {
        return UsersApiFp(this.configuration).usersControllerEnable(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiUsersControllerFindManyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerFindMany(requestParameters: UsersApiUsersControllerFindManyRequest = {}, options?: any) {
        return UsersApiFp(this.configuration).usersControllerFindMany(requestParameters.page, requestParameters.itemsPerPage, requestParameters.orderBy, requestParameters.orderDir, requestParameters.search, requestParameters.filterBy, requestParameters.filter, requestParameters.filters, requestParameters.csv, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerGetCurrentUser(options?: any) {
        return UsersApiFp(this.configuration).usersControllerGetCurrentUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiUsersControllerGetUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerGetUser(requestParameters: UsersApiUsersControllerGetUserRequest, options?: any) {
        return UsersApiFp(this.configuration).usersControllerGetUser(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiUsersControllerResetPasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerResetPassword(requestParameters: UsersApiUsersControllerResetPasswordRequest, options?: any) {
        return UsersApiFp(this.configuration).usersControllerResetPassword(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiUsersControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerUpdate(requestParameters: UsersApiUsersControllerUpdateRequest, options?: any) {
        return UsersApiFp(this.configuration).usersControllerUpdate(requestParameters.id, requestParameters.updateUserBodyDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VendorApi - axios parameter creator
 * @export
 */
export const VendorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateVendorDto} createVendorDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendorsControllerCreate: async (createVendorDto: CreateVendorDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createVendorDto' is not null or undefined
            assertParamExists('vendorsControllerCreate', 'createVendorDto', createVendorDto)
            const localVarPath = `/api/v1/vendor/vendors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createVendorDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendorsControllerDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('vendorsControllerDelete', 'id', id)
            const localVarPath = `/api/v1/vendor/vendors/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {VendorsFiltersDto} [filters] 
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendorsControllerFindMany: async (page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, filters?: VendorsFiltersDto, csv?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/vendor/vendors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['orderDir'] = orderDir;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (filterBy !== undefined) {
                localVarQueryParameter['filterBy'] = filterBy;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (filters !== undefined) {
                localVarQueryParameter['filters'] = filters;
            }

            if (csv !== undefined) {
                localVarQueryParameter['csv'] = csv;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendorsControllerFindOne: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('vendorsControllerFindOne', 'id', id)
            const localVarPath = `/api/v1/vendor/vendors/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendorsControllerSevDeskCreateContact: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('vendorsControllerSevDeskCreateContact', 'id', id)
            const localVarPath = `/api/v1/vendor/vendors/sevDesk/createContact/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendorsControllerTermsHtml: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('vendorsControllerTermsHtml', 'id', id)
            const localVarPath = `/api/v1/vendor/vendors/terms/{id}/html`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendorsControllerTermsPdf: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('vendorsControllerTermsPdf', 'id', id)
            const localVarPath = `/api/v1/vendor/vendors/terms/{id}/pdf`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateVendorDto} updateVendorDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendorsControllerUpdate: async (id: number, updateVendorDto: UpdateVendorDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('vendorsControllerUpdate', 'id', id)
            // verify required parameter 'updateVendorDto' is not null or undefined
            assertParamExists('vendorsControllerUpdate', 'updateVendorDto', updateVendorDto)
            const localVarPath = `/api/v1/vendor/vendors/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateVendorDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VendorApi - functional programming interface
 * @export
 */
export const VendorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VendorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateVendorDto} createVendorDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vendorsControllerCreate(createVendorDto: CreateVendorDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VendorResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vendorsControllerCreate(createVendorDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vendorsControllerDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vendorsControllerDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {VendorsFiltersDto} [filters] 
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vendorsControllerFindMany(page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, filters?: VendorsFiltersDto, csv?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VendorChunkResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vendorsControllerFindMany(page, itemsPerPage, orderBy, orderDir, search, filterBy, filter, filters, csv, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vendorsControllerFindOne(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VendorResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vendorsControllerFindOne(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vendorsControllerSevDeskCreateContact(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vendorsControllerSevDeskCreateContact(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vendorsControllerTermsHtml(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vendorsControllerTermsHtml(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vendorsControllerTermsPdf(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vendorsControllerTermsPdf(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateVendorDto} updateVendorDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async vendorsControllerUpdate(id: number, updateVendorDto: UpdateVendorDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VendorResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.vendorsControllerUpdate(id, updateVendorDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VendorApi - factory interface
 * @export
 */
export const VendorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VendorApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateVendorDto} createVendorDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendorsControllerCreate(createVendorDto: CreateVendorDto, options?: any): AxiosPromise<VendorResponseDto> {
            return localVarFp.vendorsControllerCreate(createVendorDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendorsControllerDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.vendorsControllerDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Number of the page to fetch
         * @param {number} [itemsPerPage] Number of items per page
         * @param {string} [orderBy] Name of the property to order by
         * @param {OrderByDirection} [orderDir] Value which specify direction of the order
         * @param {string} [search] Text which will be used to search through table data
         * @param {string} [filterBy] Name of the property to filter by
         * @param {string} [filter] Value which will be used to filter table data
         * @param {VendorsFiltersDto} [filters] 
         * @param {string} [csv] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendorsControllerFindMany(page?: number, itemsPerPage?: number, orderBy?: string, orderDir?: OrderByDirection, search?: string, filterBy?: string, filter?: string, filters?: VendorsFiltersDto, csv?: string, options?: any): AxiosPromise<VendorChunkResponseDto> {
            return localVarFp.vendorsControllerFindMany(page, itemsPerPage, orderBy, orderDir, search, filterBy, filter, filters, csv, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendorsControllerFindOne(id: number, options?: any): AxiosPromise<VendorResponseDto> {
            return localVarFp.vendorsControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendorsControllerSevDeskCreateContact(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.vendorsControllerSevDeskCreateContact(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendorsControllerTermsHtml(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.vendorsControllerTermsHtml(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendorsControllerTermsPdf(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.vendorsControllerTermsPdf(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateVendorDto} updateVendorDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        vendorsControllerUpdate(id: number, updateVendorDto: UpdateVendorDto, options?: any): AxiosPromise<VendorResponseDto> {
            return localVarFp.vendorsControllerUpdate(id, updateVendorDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for vendorsControllerCreate operation in VendorApi.
 * @export
 * @interface VendorApiVendorsControllerCreateRequest
 */
export interface VendorApiVendorsControllerCreateRequest {
    /**
     * 
     * @type {CreateVendorDto}
     * @memberof VendorApiVendorsControllerCreate
     */
    readonly createVendorDto: CreateVendorDto
}

/**
 * Request parameters for vendorsControllerDelete operation in VendorApi.
 * @export
 * @interface VendorApiVendorsControllerDeleteRequest
 */
export interface VendorApiVendorsControllerDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof VendorApiVendorsControllerDelete
     */
    readonly id: number
}

/**
 * Request parameters for vendorsControllerFindMany operation in VendorApi.
 * @export
 * @interface VendorApiVendorsControllerFindManyRequest
 */
export interface VendorApiVendorsControllerFindManyRequest {
    /**
     * Number of the page to fetch
     * @type {number}
     * @memberof VendorApiVendorsControllerFindMany
     */
    readonly page?: number

    /**
     * Number of items per page
     * @type {number}
     * @memberof VendorApiVendorsControllerFindMany
     */
    readonly itemsPerPage?: number

    /**
     * Name of the property to order by
     * @type {string}
     * @memberof VendorApiVendorsControllerFindMany
     */
    readonly orderBy?: string

    /**
     * Value which specify direction of the order
     * @type {OrderByDirection}
     * @memberof VendorApiVendorsControllerFindMany
     */
    readonly orderDir?: OrderByDirection

    /**
     * Text which will be used to search through table data
     * @type {string}
     * @memberof VendorApiVendorsControllerFindMany
     */
    readonly search?: string

    /**
     * Name of the property to filter by
     * @type {string}
     * @memberof VendorApiVendorsControllerFindMany
     */
    readonly filterBy?: string

    /**
     * Value which will be used to filter table data
     * @type {string}
     * @memberof VendorApiVendorsControllerFindMany
     */
    readonly filter?: string

    /**
     * 
     * @type {VendorsFiltersDto}
     * @memberof VendorApiVendorsControllerFindMany
     */
    readonly filters?: VendorsFiltersDto

    /**
     * 
     * @type {string}
     * @memberof VendorApiVendorsControllerFindMany
     */
    readonly csv?: string
}

/**
 * Request parameters for vendorsControllerFindOne operation in VendorApi.
 * @export
 * @interface VendorApiVendorsControllerFindOneRequest
 */
export interface VendorApiVendorsControllerFindOneRequest {
    /**
     * 
     * @type {number}
     * @memberof VendorApiVendorsControllerFindOne
     */
    readonly id: number
}

/**
 * Request parameters for vendorsControllerSevDeskCreateContact operation in VendorApi.
 * @export
 * @interface VendorApiVendorsControllerSevDeskCreateContactRequest
 */
export interface VendorApiVendorsControllerSevDeskCreateContactRequest {
    /**
     * 
     * @type {number}
     * @memberof VendorApiVendorsControllerSevDeskCreateContact
     */
    readonly id: number
}

/**
 * Request parameters for vendorsControllerTermsHtml operation in VendorApi.
 * @export
 * @interface VendorApiVendorsControllerTermsHtmlRequest
 */
export interface VendorApiVendorsControllerTermsHtmlRequest {
    /**
     * 
     * @type {number}
     * @memberof VendorApiVendorsControllerTermsHtml
     */
    readonly id: number
}

/**
 * Request parameters for vendorsControllerTermsPdf operation in VendorApi.
 * @export
 * @interface VendorApiVendorsControllerTermsPdfRequest
 */
export interface VendorApiVendorsControllerTermsPdfRequest {
    /**
     * 
     * @type {number}
     * @memberof VendorApiVendorsControllerTermsPdf
     */
    readonly id: number
}

/**
 * Request parameters for vendorsControllerUpdate operation in VendorApi.
 * @export
 * @interface VendorApiVendorsControllerUpdateRequest
 */
export interface VendorApiVendorsControllerUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof VendorApiVendorsControllerUpdate
     */
    readonly id: number

    /**
     * 
     * @type {UpdateVendorDto}
     * @memberof VendorApiVendorsControllerUpdate
     */
    readonly updateVendorDto: UpdateVendorDto
}

/**
 * VendorApi - object-oriented interface
 * @export
 * @class VendorApi
 * @extends {BaseAPI}
 */
export class VendorApi extends BaseAPI {
    /**
     * 
     * @param {VendorApiVendorsControllerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VendorApi
     */
    public vendorsControllerCreate(requestParameters: VendorApiVendorsControllerCreateRequest, options?: any) {
        return VendorApiFp(this.configuration).vendorsControllerCreate(requestParameters.createVendorDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VendorApiVendorsControllerDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VendorApi
     */
    public vendorsControllerDelete(requestParameters: VendorApiVendorsControllerDeleteRequest, options?: any) {
        return VendorApiFp(this.configuration).vendorsControllerDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VendorApiVendorsControllerFindManyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VendorApi
     */
    public vendorsControllerFindMany(requestParameters: VendorApiVendorsControllerFindManyRequest = {}, options?: any) {
        return VendorApiFp(this.configuration).vendorsControllerFindMany(requestParameters.page, requestParameters.itemsPerPage, requestParameters.orderBy, requestParameters.orderDir, requestParameters.search, requestParameters.filterBy, requestParameters.filter, requestParameters.filters, requestParameters.csv, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VendorApiVendorsControllerFindOneRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VendorApi
     */
    public vendorsControllerFindOne(requestParameters: VendorApiVendorsControllerFindOneRequest, options?: any) {
        return VendorApiFp(this.configuration).vendorsControllerFindOne(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VendorApiVendorsControllerSevDeskCreateContactRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VendorApi
     */
    public vendorsControllerSevDeskCreateContact(requestParameters: VendorApiVendorsControllerSevDeskCreateContactRequest, options?: any) {
        return VendorApiFp(this.configuration).vendorsControllerSevDeskCreateContact(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VendorApiVendorsControllerTermsHtmlRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VendorApi
     */
    public vendorsControllerTermsHtml(requestParameters: VendorApiVendorsControllerTermsHtmlRequest, options?: any) {
        return VendorApiFp(this.configuration).vendorsControllerTermsHtml(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VendorApiVendorsControllerTermsPdfRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VendorApi
     */
    public vendorsControllerTermsPdf(requestParameters: VendorApiVendorsControllerTermsPdfRequest, options?: any) {
        return VendorApiFp(this.configuration).vendorsControllerTermsPdf(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VendorApiVendorsControllerUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VendorApi
     */
    public vendorsControllerUpdate(requestParameters: VendorApiVendorsControllerUpdateRequest, options?: any) {
        return VendorApiFp(this.configuration).vendorsControllerUpdate(requestParameters.id, requestParameters.updateVendorDto, options).then((request) => request(this.axios, this.basePath));
    }
}


