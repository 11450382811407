import { create } from "zustand";

interface BeachChairTimelineModalStore {
  isBeachChairTimelineModal: boolean;
  setModal: (isBeachChairTimelineModal: boolean) => void;
}

export const useBeachChairTimelineModal = create<BeachChairTimelineModalStore>(
  (set) => {
    return {
      isBeachChairTimelineModal: false,
      setModal: (isBeachChairTimelineModal: boolean) =>
        set((state) => ({
          ...state,
          isBeachChairTimelineModal,
        })),
    };
  }
);
