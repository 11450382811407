import {
  BeachResponseDto,
  CityResponseDto,
  SectionResponseDto,
  RegionResponseDto
} from "../../api-client/generated";

export type TreeItem = RegionResponseDto | CityResponseDto | BeachResponseDto | SectionResponseDto;

export enum SectionKindEnum {
  REGION = "REGION",
  CITY = "CITY",
  BEACH = "BEACH",
  BEACH_SECTION = "BEACH_SECTION"
}

export interface ITreeSelect {
  id: string;
  name: string;
  obj: TreeItem;
  kind: SectionKindEnum;
  regionId: number;
  parentId?: string;
}
