import { stylesConfig } from "../../styles/styles";

export const RowDividerStyles = {
  content: {
    display: "grid",
    gridTemplateColumns: "auto 270px auto",
    alignItems: "center",
    textAlign: "center",
  } as const,
  divider: {
    background: stylesConfig.blueColor,
    height: 2,
  },
  boldTypography: {
    fontWeight: "bolder",
    color: stylesConfig.blueColor,
  },
};
