import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ChangeBeachChairDatesRequestPayload,
  ChangeBeachChairDatesSelectedDatesRequestPayload,
  ChangeDateFromRequestPayload,
  ChangeDateToRequestPayload,
  RedirectRequestPayload,
  TogglePayload,
} from "../actionPayload/mainAppPayloads";
import {
  isEqual,
  isAfter,
  isBefore,
  // differenceInDays
} from "date-fns";

const date = new Date();
const seasonStartDate = new Date(date.getFullYear(), 5, 1);
const seasonEndDate = new Date(date.getFullYear() + 1, 9, 31);

const selectTodaysDate = () => {
  if (isEqual(date, seasonStartDate)) {
    // return new Date(date.setDate(date.getDate() + 2));
    return new Date(date.setDate(date.getDate()));
  }
  if (isAfter(date, seasonEndDate)) {
    return seasonEndDate;
  }
  if (isAfter(date, seasonStartDate)) {
    // const addition = differenceInDays(seasonEndDate, date) <= 2 ? 0 : 2;
    const addition = 0;
    return new Date(date.setDate(date.getDate() + addition));
  }
  if (isBefore(date, seasonStartDate)) {
    return seasonStartDate;
  }
  return date;
};

const todayDate = selectTodaysDate();
const tomorrow = new Date(todayDate);

const mainAppSlice = createSlice({
  name: "mainApp",
  initialState: {
    dateFrom: todayDate.toISOString(),
    dateTo: tomorrow.toISOString(),
    beachChairDateFrom: todayDate.toISOString(),
    beachChairDateTo: tomorrow.toISOString(),
    seasonStartDate: seasonStartDate.toISOString(),
    seasonEndDate: seasonEndDate.toISOString(),
    rightDrawer: false,
    rightBookingDetailsDrawer: false,
    leftDrawer: false,
    mainComponent: false,
    selectionModal: false,
    showStart: true,
    focus: false,
    searchSwitch: false,
    mobileViewExpandMenu: true,
    ignoreCloseExpandMenu: false,
    showMap: false,
    isSubSections: false,
    isChangeDateByUser: false,
  },
  reducers: {
    showStartToggle(state, action: PayloadAction<TogglePayload>) {
      state.showStart = action.payload.toggle;
    },
    changeDateFromRequest(
      state,
      action: PayloadAction<ChangeDateFromRequestPayload>
    ) {
      state.dateFrom = action.payload.dateFrom;
    },
    changeDateToRequest(
      state,
      action: PayloadAction<ChangeDateToRequestPayload>
    ) {
      state.dateTo = action.payload.dateTo;
    },
    changeBeachChairDates(
      state,
      action: PayloadAction<ChangeBeachChairDatesRequestPayload>
    ) {
      state.beachChairDateFrom = state.dateFrom;
      state.beachChairDateTo = state.dateTo;
    },
    changeBeachChairDatesSelected(
      state,
      action: PayloadAction<ChangeBeachChairDatesSelectedDatesRequestPayload>
    ) {
      state.beachChairDateFrom = state.dateFrom;
      state.beachChairDateTo = state.dateTo;
    },
    rightDrawerChangeRequest(state, action: PayloadAction<TogglePayload>) {
      state.rightDrawer = action.payload.toggle;
      if (state.leftDrawer) state.leftDrawer = false;
    },
    leftDrawerChangeRequest(state, action: PayloadAction<TogglePayload>) {
      state.leftDrawer = action.payload.toggle;
      if (state.rightDrawer) state.rightDrawer = false;
    },
    rightDrawerBookingDetailsChangeRequest(
      state,
      action: PayloadAction<TogglePayload>
    ) {
      state.leftDrawer = false;
      state.rightDrawer = false;
      state.rightBookingDetailsDrawer = action.payload.toggle;
    },
    mainComponentShowChangeRequest(
      state,
      action: PayloadAction<TogglePayload>
    ) {
      state.mainComponent = action.payload.toggle;
    },
    selectionModalRequest(state, action: PayloadAction<TogglePayload>) {
      state.selectionModal = action.payload.toggle;
    },
    redirectRequest(state, action: PayloadAction<RedirectRequestPayload>) {},
    setFocusRequest(state, action: PayloadAction<TogglePayload>) {
      state.focus = action.payload.toggle;
    },
    toggleSearchSwitchRequest(state, action: PayloadAction<TogglePayload>) {
      state.searchSwitch = action.payload.toggle;
    },
    toggleMobileViewExpandMenuRequest(
      state,
      action: PayloadAction<TogglePayload>
    ) {
      state.mobileViewExpandMenu = action.payload.toggle;
      state.ignoreCloseExpandMenu = false;
    },
    toggleIgnoreCloseExpandMenuRequest(
      state,
      action: PayloadAction<TogglePayload>
    ) {
      state.ignoreCloseExpandMenu = action.payload.toggle;
    },
    toggleMapRequest(state, action: PayloadAction<TogglePayload>) {
      state.showMap = action.payload.toggle;
    },
    subSectionRequest(state, action: PayloadAction<TogglePayload>) {
      state.isSubSections = action.payload.toggle;
    },
    isChangeDateByUserRequest(state, action: PayloadAction<TogglePayload>) {
      state.isChangeDateByUser = action.payload.toggle;
    },
  },
});

export const {
  changeDateFromRequest,
  changeDateToRequest,
  changeBeachChairDates,
  changeBeachChairDatesSelected,
  rightDrawerChangeRequest,
  rightDrawerBookingDetailsChangeRequest,
  leftDrawerChangeRequest,
  mainComponentShowChangeRequest,
  selectionModalRequest,
  redirectRequest,
  showStartToggle,
  setFocusRequest,
  toggleSearchSwitchRequest,
  toggleMobileViewExpandMenuRequest,
  toggleIgnoreCloseExpandMenuRequest,
  toggleMapRequest,
  subSectionRequest,
  isChangeDateByUserRequest,
} = mainAppSlice.actions;

export default mainAppSlice.reducer;
