export default (function getOS() {
  const platform = navigator.platform.toLowerCase();

  return {
    isWindows: (platform.indexOf('win') !== -1),
    isMacOS: (platform.indexOf('mac') !== -1),
    isUnix: (platform.indexOf('x11') !== -1),
    isLinux: (platform.indexOf('linux') !== -1),
    isIPhone: (platform.indexOf('iphone') !== -1),
    isAndroid: (platform.indexOf('android') !== -1)
  }
})()
