import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { useWidget } from "../../../contexts";
import {
  ModalStyles,
  useModalStyles,
} from "../../../utils/hooks/styles/modalStyle";
import { useAppDispatch } from "../../../store";
import {
  addCommentToBookingRequest,
  confirmBankTransferBookingRequest,
  updateBookingPaymentMethodRequest,
} from "../../../store/reducers/bookingReducer";
import { useSelector } from "react-redux";
import {
  getBookingRefSelector,
  getBookingSelector,
} from "../../../store/selectors/bookingSelectors";
import { EBookingPaymentMethod } from "../../../utils/models/EBookingPaymentMethod";
import { useTranslation } from "react-i18next";
import { useCustomerForm } from "../../../utils/hooks/customHooks/useCustomerForm";
import { Controller } from "react-hook-form";
import { rightDrawerChangeRequest } from "../../../store/reducers/mainAppReducer";
import { useSEPAForm } from "../../../utils/hooks/customHooks/useSEPAForm";
import { BankDetails, PaymentFlow } from "../../../api-client/generated";
import PaymentMethodsRadioButtons from "./paymentMethodsRadioButtons/paymentMethodsRadioButtons";
import { BookingStyles } from "../../../utils/hooks/styles/bookingStyles";
import useDebouncedCallback from "use-debounce/lib/useDebouncedCallback";
import { getSelectedSectionSelector } from "../../../store/selectors/sectionSelectors";
import { apiConfig } from "../../../utils/oidc/oidcConfig";
import * as localizedCountries from "localized-countries/data/de.json";
import { getVendorSelector } from "../../../store/selectors/vendorSelectors";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

interface ICheckoutModalProps {
  onClose: () => void;
}

const countriesArray = [
  "DE",
  "AT",
  "CH",
  "DK",
  "PL",
  "NL",
  "IT",
  "ES",
  "SE",
  "NO",
  "FR",
  "CZ",
  "HR",
  "GB",
];

const CheckoutModal = ({ onClose }: ICheckoutModalProps) => {
  const translatedCountries = (localizedCountries as any).default;
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useModalStyles();
  const { t } = useTranslation();
  const bookingRef = useSelector(getBookingRefSelector);
  const section = useSelector(getSelectedSectionSelector);
  const booking = useSelector(getBookingSelector);
  const location = useLocation();
  const vendorId = section?.location?.vendorId;
  const vendorConfiguration = useSelector(getVendorSelector)?.configuration;
  const checkTerms = section
    ? (Object.keys((section?.location as any)?.terms)?.length ?? 0) === 0
    : false;
  const [modalState, setModalState] = React.useState<boolean>(true);

  const { control, errors, isValid, watch, onSubmit, setValue } =
    useCustomerForm();
  const emailWatch = watch("email");
  const countryWatch = watch("address.country");

  const { sepaControl, sepaIsValid, sepaErrors, sepaGetValues } = useSEPAForm();
  const [paymentMethod, setPaymentMethod] = React.useState<string>(
    EBookingPaymentMethod.STRIPE_CREDIT_CARD
  );
  const [checked, setChecked] = React.useState(false);
  const [discountCardChecked, setDiscountCardChecked] = React.useState(false);
  const [paymentMethodSelected, setPaymentMethodSelected] =
    React.useState(false);
  const [sepaChecked, setSepaChecked] = React.useState(false);
  const [addComment, setAddComment] = React.useState(false);
  const dispatch = useAppDispatch();
  const { isWidgetActive } = useWidget();

  useEffect(() => {
    if (
      location.pathname.includes("/result/error") &&
      location.search.includes("sbl_bookingRef") &&
      location.search.includes("sbl_email")
    ) {
      setModalState(false);
      const values = queryString.parse(location.search);
      setValue("email", (values as any)?.sbl_email);
    }
  }, [location, setValue]);

  const debounced = useDebouncedCallback((value: string) => {
    dispatch(
      addCommentToBookingRequest({
        customerComment: value,
      })
    );
  }, 250);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleSepaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSepaChecked(event.target.checked);
  };

  const handlePaymentChange = (paymentMethod: EBookingPaymentMethod) => {
    setPaymentMethod(paymentMethod);
    dispatch(
      updateBookingPaymentMethodRequest({
        paymentMethod: paymentMethod,
        email: emailWatch,
      })
    );
    setPaymentMethodSelected(true);
  };

  const handlePaymentSepaChange = (details: BankDetails) => {
    const bankDetails: BankDetails = {
      bic: details.bic === "" ? undefined : details.bic,
      iban: details.iban,
      accountHolder:
        details.accountHolder === "" ? undefined : details.accountHolder,
      bankName: details.bankName === "" ? undefined : details.bankName,
    };

    dispatch(
      updateBookingPaymentMethodRequest({
        paymentMethod: EBookingPaymentMethod.CUSTOM_SEPA,
        email: emailWatch,
        bankDetails,
      })
    );
  };

  const handleSubmit = React.useCallback(() => {
    dispatch(
      confirmBankTransferBookingRequest({
        email: emailWatch,
        bookingRef: bookingRef,
      })
    );
  }, [bookingRef, dispatch, emailWatch]);

  const flowParam = useMemo(
    () => `&flow=${isWidgetActive ? PaymentFlow.Widget : PaymentFlow.Website}`,
    [isWidgetActive]
  );

  const isDiscountAvailable = (booking?.beachChairBookings ?? []).some(
    (beachChairBooking) => beachChairBooking.discount
  );
  const isSubmitButtonDisabled = !(
    isValid &&
    checked &&
    (isDiscountAvailable ? discountCardChecked : true)
  );
  return (
    <Box
      style={{
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
      }}
      sx={ModalStyles.paper}
    >
      {modalState ? (
        <form
          className={classes.firsStateGrid}
          onSubmit={(e) => {
            setModalState(false);
            e.preventDefault();
            onSubmit();
          }}
        >
          <Typography component="div">
            <Box fontWeight="fontWeightBold" m={1} fontSize={25}>
              {t("common:titleCheckout")}
            </Box>
          </Typography>
          <Box sx={ModalStyles.box} border={0}>
            <div className={classes.insideCard} data-testid="inside-card">
              <div className={classes.propGrid}>
                <FormControl>
                  <Controller
                    control={control}
                    name="email"
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        required
                        className={classes.textRoundedField}
                        data-testid="email"
                        label={t("common:email")}
                        error={!!errors.email}
                        {...field}
                      />
                    )}
                  />
                </FormControl>
              </div>
              <div className={classes.propGrid}>
                <FormControl variant="outlined">
                  <Controller
                    name="salutation"
                    control={control}
                    defaultValue="MX"
                    render={({ field }) => (
                      <Select
                        MenuProps={{
                          style: { zIndex: 99999999999999 },
                        }}
                        variant="outlined"
                        className={classes.textRoundedField}
                        id="salutation"
                        data-testid="salutation"
                        placeholder={t("salutation")}
                        {...field}
                      >
                        <MenuItem value="MR">
                          {t("customers:salutations.MR")}
                        </MenuItem>
                        <MenuItem value="MS">
                          {t("customers:salutations.MS")}
                        </MenuItem>
                        <MenuItem value="MX">
                          {t("customers:salutations.MX")}
                        </MenuItem>
                        <MenuItem value="FM">
                          {t("customers:salutations.FM")}
                        </MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
              </div>
              <div className={classes.propGrid}>
                <FormControl>
                  <Controller
                    control={control}
                    name="firstName"
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        required
                        className={classes.textRoundedField}
                        data-testid="firstName"
                        label={t("common:firstname")}
                        {...field}
                      />
                    )}
                  />
                </FormControl>
              </div>
              <div className={classes.propGrid}>
                <FormControl>
                  <Controller
                    control={control}
                    name="lastName"
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        required
                        className={classes.textRoundedField}
                        data-testid="lastName"
                        label={t("common:lastname")}
                        {...field}
                      />
                    )}
                  />
                </FormControl>
              </div>
              <div className={classes.propGrid}>
                <FormControl>
                  <Controller
                    control={control}
                    name="telephone"
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        className={classes.textRoundedField}
                        data-testid="telephone"
                        label={t("common:telephone")}
                        {...field}
                      />
                    )}
                  />
                </FormControl>
              </div>
            </div>
            <div
              className={
                !matchesMd
                  ? classes.insideCardSecond
                  : classes.insideCardSecondRow
              }
            >
              {!matchesMd && <div className={classes.propGrid} />}
              <div className={classes.propGrid}>
                <FormControl>
                  <Controller
                    control={control}
                    name="address.street"
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        required
                        className={classes.textRoundedField}
                        data-testid="street"
                        label={t("common:street")}
                        {...field}
                      />
                    )}
                  />
                </FormControl>
              </div>
              <div className={classes.propGrid}>
                <FormControl>
                  <Controller
                    control={control}
                    name="address.zip"
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        required
                        className={classes.textRoundedField}
                        data-testid="zip"
                        label={t("common:zip")}
                        {...field}
                      />
                    )}
                  />
                </FormControl>
              </div>
              <div className={classes.propGrid}>
                <FormControl>
                  <Controller
                    control={control}
                    name="address.city"
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        variant="outlined"
                        required
                        className={classes.textRoundedField}
                        data-testid="city"
                        label={t("common:city")}
                        {...field}
                      />
                    )}
                  />
                </FormControl>
              </div>
              <div className={classes.propGrid}>
                <FormControl>
                  <Controller
                    control={control}
                    name="address.country"
                    defaultValue={(translatedCountries as any)["DE"]}
                    render={({ field }) => (
                      <Select
                        MenuProps={{
                          style: { zIndex: 99999999999999 },
                        }}
                        variant="outlined"
                        className={classes.textRoundedField}
                        id="country"
                        data-testid="country"
                        placeholder={(translatedCountries as any)["DE"]}
                        defaultValue={(translatedCountries as any)["DE"]}
                        {...field}
                      >
                        {countriesArray.map((country) => {
                          return (
                            <MenuItem
                              value={`${(translatedCountries as any)[country]}`}
                            >
                              {(translatedCountries as any)[country]}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    )}
                  />
                </FormControl>
              </div>
            </div>
          </Box>
          <Box
            sx={{
              width: "calc(100% - 40px)",
              paddingLeft: "20px",
              paddingRight: "20px",
            }}
          >
            {!vendorConfiguration?.hideCustomerComment &&
              (!addComment ? (
                <Link
                  sx={BookingStyles.linkTypographyBoldBlue}
                  onClick={() => {
                    setAddComment(!addComment);
                  }}
                >
                  + {t("Bemerkung zur Buchung")}
                </Link>
              ) : (
                <TextField
                  onChange={(e) => {
                    debounced(e.target.value);
                  }}
                  onFocus={(e) => {
                    e.target.value = "";
                  }}
                  multiline
                  rows={4}
                  fullWidth
                  variant="outlined"
                  defaultValue={t("common:commentPrefill")}
                  className={classes.textRoundedField}
                  data-testid="comment"
                  label={t("common:comment")}
                />
              ))}
          </Box>

          <FormControlLabel
            control={
              <Controller
                control={control}
                name="additionalData.marketing"
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    color="primary"
                    style={{ marginLeft: 20 }}
                    defaultChecked={false}
                  />
                )}
              />
            }
            label={t("common:marketing")}
            labelPlacement="end"
          />

          {isDiscountAvailable && (
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  required={isDiscountAvailable}
                  checked={discountCardChecked}
                  defaultChecked={false}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setDiscountCardChecked(event.target.checked)
                  }
                  sx={ModalStyles.checkBox}
                />
              }
              label={t("common:validDiscountCardConfirmation")}
              labelPlacement="end"
            />
          )}

          {checkTerms ? (
            <FormControlLabel
              sx={ModalStyles.termsWidth}
              control={
                <Checkbox
                  color="primary"
                  checked={checked}
                  onChange={handleChange}
                  sx={ModalStyles.checkBox}
                />
              }
              label={
                <>
                  {t("common:terms.textBegin")}
                  <Link
                    sx={BookingStyles.linkTypographyBoldBlue}
                    href="/content/terms"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("common:terms.titleTerms")}
                  </Link>
                  {t("common:terms.textMiddle")}
                  <Link
                    sx={BookingStyles.linkTypographyBoldBlue}
                    href="/content/dataprivacy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("common:terms.titlePrivacy")}
                  </Link>
                  {t("common:terms.textEnd")}
                </>
              }
              labelPlacement="end"
            />
          ) : (
            <FormControlLabel
              sx={ModalStyles.termsWidth}
              control={
                <Checkbox
                  color="primary"
                  checked={checked}
                  onChange={handleChange}
                  sx={ModalStyles.checkBox}
                />
              }
              label={
                <>
                  {t("common:termsLink.textBegin")}
                  <Link
                    sx={BookingStyles.linkTypographyBoldBlue}
                    href="/content/terms"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("common:terms.titleTerms")}
                  </Link>
                  {t("common:termsLink.textAfterTerms")}
                  <Link
                    sx={BookingStyles.linkTypographyBoldBlue}
                    href="/content/dataprivacy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("common:termsLink.titlePrivacy")}
                  </Link>
                  {t("common:termsLink.textMiddle")}
                  <Link
                    sx={BookingStyles.linkTypographyBoldBlue}
                    href={`${apiConfig.publicUrl}/api/v1/vendor/vendors/terms/${vendorId}/pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("common:termsLink.titleLink")}
                  </Link>
                  {t("common:termsLink.textEnd")}
                </>
              }
              labelPlacement="end"
            />
          )}

          <Button
            type="submit"
            disabled={isSubmitButtonDisabled}
            sx={ModalStyles.button}
          >
            {t("common:next")}
          </Button>
        </form>
      ) : (
        <form
          className={classes.formPaymentMethod}
          autoComplete="off"
          target={isWidgetActive ? "_parent" : "_self"}
          action={
            [
              EBookingPaymentMethod.STRIPE_DIRECT_DEBIT,
              EBookingPaymentMethod.STRIPE_SOFORT,
              EBookingPaymentMethod.STRIPE_CREDIT_CARD,
              EBookingPaymentMethod.STRIPE_PAYPAL,
            ].includes(paymentMethod as EBookingPaymentMethod)
              ? `${
                  apiConfig.publicUrl
                }/api/v1/payments/stripe/checkout-session?email=${emailWatch}&bookingRef=${encodeURIComponent(
                  bookingRef
                )}${flowParam}`
              : paymentMethod === EBookingPaymentMethod.PAYPAL
              ? `${
                  apiConfig.publicUrl
                }/api/v1/payments/paypal/payment?email=${emailWatch}&bookingRef=${encodeURIComponent(
                  bookingRef
                )}${flowParam}`
              : ""
          }
          onSubmit={() => {}}
          method="POST"
        >
          <Typography sx={ModalStyles.center}>
            <Box
              fontWeight="fontWeightBold"
              m={1}
              sx={ModalStyles.typographyBoldBlue}
            >
              {t("common:selectPayment")}
            </Box>
          </Typography>

          <PaymentMethodsRadioButtons
            handlePaymentChange={handlePaymentChange}
            selectedCountry={countryWatch}
          />

          {[
            EBookingPaymentMethod.STRIPE_DIRECT_DEBIT,
            EBookingPaymentMethod.STRIPE_SOFORT,
            EBookingPaymentMethod.STRIPE_CREDIT_CARD,
            EBookingPaymentMethod.STRIPE_PAYPAL,
          ].includes(paymentMethod as EBookingPaymentMethod) ? (
            <Button
              type="submit"
              sx={ModalStyles.button}
              disabled={!paymentMethodSelected}
            >
              {t("bookings:pay")}
            </Button>
          ) : (
            paymentMethod !== EBookingPaymentMethod.CUSTOM_SEPA && (
              <Button
                type="button"
                sx={ModalStyles.button}
                onClick={() => {
                  handleSubmit();
                  dispatch(rightDrawerChangeRequest({ toggle: false }));
                  onClose();
                }}
              >
                {t("bookings:pay")}
              </Button>
            )
          )}

          <Button
            type="button"
            onClick={() => {
              setModalState(true);
              setPaymentMethodSelected(false);
            }}
          >
            {t("common:back")}
          </Button>
        </form>
      )}

      {paymentMethod === EBookingPaymentMethod.CUSTOM_SEPA && (
        <Box sx={ModalStyles.sepaTitleGrid}>
          <Divider sx={{ width: "100%" }} />
          <Typography sx={ModalStyles.center}>
            <Box
              fontWeight="fontWeightBold"
              m={1}
              sx={ModalStyles.typographyBoldDarkBlueSize}
            >
              {t("common:details")}
            </Box>
          </Typography>
          <Box sx={ModalStyles.sepaGrid}>
            <FormControl>
              <Typography sx={ModalStyles.left}>
                <Box sx={ModalStyles.typographyBoldGrayLabelColorSize}>
                  {t("bookings:sepa.iban") + " *"}
                </Box>
              </Typography>
              <Controller
                control={sepaControl}
                name="iban"
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    variant="outlined"
                    required
                    sx={ModalStyles.textRoundedSepaField}
                    data-testid="iban"
                    error={!!sepaErrors.iban}
                    {...field}
                    inputProps={{
                      style: {
                        color: "#8b97a7",
                        fontSize: "12px",
                        fontWeight: "bold",
                      },
                    }}
                  />
                )}
              />
            </FormControl>
            <FormControl>
              <Typography sx={ModalStyles.left}>
                <Box sx={ModalStyles.typographyBoldGrayLabelColorSize}>
                  {t("bookings:sepa.bic")}
                </Box>
              </Typography>
              <Controller
                control={sepaControl}
                name="bic"
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    variant="outlined"
                    sx={ModalStyles.textRoundedSepaField}
                    data-testid="bic"
                    error={!!sepaErrors.bic}
                    {...field}
                    inputProps={{
                      style: {
                        color: "#8b97a7",
                        fontSize: "12px",
                        fontWeight: "bold",
                      },
                    }}
                  />
                )}
              />
            </FormControl>
            <FormControl>
              <Typography sx={ModalStyles.left}>
                <Box sx={ModalStyles.typographyBoldGrayLabelColorSize}>
                  {t("bookings:sepa.accountHolder")}
                </Box>
              </Typography>
              <Controller
                control={sepaControl}
                name="accountHolder"
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    variant="outlined"
                    sx={ModalStyles.textRoundedSepaField}
                    data-testid="accountHolder"
                    {...field}
                    inputProps={{
                      style: {
                        color: "#8b97a7",
                        fontSize: "12px",
                        fontWeight: "bold",
                      },
                    }}
                  />
                )}
              />
            </FormControl>
            <FormControl>
              <Typography sx={ModalStyles.left}>
                <Box sx={ModalStyles.typographyBoldGrayLabelColorSize}>
                  {t("bookings:sepa.bankName")}
                </Box>
              </Typography>
              <Controller
                control={sepaControl}
                name="bankName"
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    variant="outlined"
                    sx={ModalStyles.textRoundedSepaField}
                    data-testid="bankName"
                    {...field}
                    inputProps={{
                      style: {
                        color: "#8b97a7",
                        fontSize: "12px",
                        fontWeight: "bold",
                      },
                    }}
                  />
                )}
              />
            </FormControl>
          </Box>
          <FormControlLabel
            sx={ModalStyles.termsWidth}
            control={
              <Checkbox
                color="primary"
                checked={sepaChecked}
                onChange={handleSepaChange}
                style={{ marginLeft: 20 }}
              />
            }
            label={
              <Typography sx={ModalStyles.typographyBlue}>
                {t("bookings:sepaCheckboxText")}
              </Typography>
            }
            labelPlacement="end"
          />
          <Button
            disabled={sepaIsValid ? !sepaChecked : true}
            sx={ModalStyles.button}
            onClick={() => {
              handlePaymentSepaChange(sepaGetValues());
              dispatch(rightDrawerChangeRequest({ toggle: false }));
              handleSubmit();
              onClose();
            }}
          >
            {t("bookings:bookNow")}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default React.forwardRef(CheckoutModal);
