import { Box, IconButton } from "@mui/material";
import React from "react";
import { LocationAvailabilitiesBeachChairsStyle } from "../../utils/hooks/styles/locationAvailabilitiesBeachChairsStyle";
import { Close } from "@mui/icons-material";
import {
  redirectRequest,
  toggleMapRequest,
} from "../../store/reducers/mainAppReducer";
import {
  setMapLocationDefaultRequest,
  setMapStateRequest,
} from "../../store/reducers/mapReducer";
import { EMapState } from "../../utils/models/EMapState";
import { useAppDispatch } from "../../store";

const CloseButton = () => {
  const dispatch = useAppDispatch();
  return (
    <Box sx={{ display: "flex", width: "100%", justifyContent: "right" }}>
      <IconButton
        sx={LocationAvailabilitiesBeachChairsStyle.buttonClose}
        aria-label="minmax"
        onClick={() => {
          dispatch(toggleMapRequest({ toggle: true }));
          dispatch(redirectRequest({ path: "/" }));
          dispatch(setMapLocationDefaultRequest({}));
          dispatch(setMapStateRequest({ mapState: EMapState.ALL_CITIES }));
        }}
      >
        <Close />
      </IconButton>
    </Box>
  );
};

export default CloseButton;
