import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ContentStyles } from "../../utils/hooks/styles/contentStyles";
import ReactMarkdown from "react-markdown";
import MarkdownDeFile from "../../assets/markdown/terms.de.md";
import MarkdownEnFile from "../../assets/markdown/terms.en.md";
import { Box } from "@mui/material";
import { useLocation } from "react-router";
import CloseButton from "./closeButton";

const TermsContent = () => {
  const { i18n } = useTranslation(["common"]);
  const [text, setText] = useState("");
  const location = useLocation();

  useEffect(() => {
    const userLanguage = i18n.language;
    fetch(
      !userLanguage || userLanguage === "de" || userLanguage === "de-DE"
        ? MarkdownDeFile
        : MarkdownEnFile
    )
      .then((res) => res.text())
      .then((text) => setText(text));
  }, [i18n.language, i18n.languages]);

  return (
    <>
      {location.pathname === "/static/headless/terms" ? (
        <Box sx={ContentStyles.background}>
          <Box sx={ContentStyles.markDown}>
            <ReactMarkdown>{text}</ReactMarkdown>
          </Box>
        </Box>
      ) : (
        <Box sx={ContentStyles.root}>
          <Box sx={ContentStyles.content}>
            <Box sx={ContentStyles.termsText}>
              <Box sx={ContentStyles.background}>
                <CloseButton />
                <Box sx={ContentStyles.markDown}>
                  <ReactMarkdown>{text}</ReactMarkdown>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default TermsContent;
