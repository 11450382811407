import {
  Typography,
  Divider,
  Box,
  IconButton,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useAppDispatch } from "../../../store";
import {
  BookingDetailsStyles,
  BookingDetailsStylesRoot,
} from "../../../utils/hooks/styles/bookingDetailsStyle";
import { rightDrawerBookingDetailsChangeRequest } from "../../../store/reducers/mainAppReducer";
import { useSelector } from "react-redux";
import { getPublicBookingDetailsSelector } from "../../../store/selectors/bookingSelectors";
import logo from "../../../assets/SBLMarker.png";
import { EURO } from "../../../utils/convertions/euroFormater";
import { dateToProperFormat } from "../../../utils/convertions/dataConversion";

const BookingDetails = () => {
  const { t } = useTranslation(["bookings"]);
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.down("md"));
  const bookingDetails = useSelector(getPublicBookingDetailsSelector);
  const dispatch = useAppDispatch();

  return (
    <Box>
      <Box sx={{ ...BookingDetailsStylesRoot }}>
        <Box sx={{ ...BookingDetailsStyles.titleBookingDetails }}>
          <Typography
            sx={{
              ...BookingDetailsStyles.typographyBoldBlue,
              ...BookingDetailsStyles.typographyBoldBlueCenter,
            }}
          >
            {t("bookingDetailsTitle")}
          </Typography>
          <IconButton
            sx={{ ...BookingDetailsStyles.iconButton }}
            onClick={() => {
              dispatch(
                rightDrawerBookingDetailsChangeRequest({ toggle: false })
              );
            }}
          >
            <ArrowForwardIcon />
          </IconButton>
        </Box>
        <Box sx={{ ...BookingDetailsStyles.selectInfo }}>
          <Box
            component="img"
            sx={{
              height: 50,
              width: 50,
              maxHeight: 50,
              maxWidth: 50,
            }}
            alt="VendorImage"
            src={logo}
          />
          <Typography sx={{ ...BookingDetailsStyles.textAlignNormalCenter }}>
            {bookingDetails?.vendor.companyName}
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ ...BookingDetailsStyles.typographyInfo }}>
          <Typography sx={{ ...BookingDetailsStyles.textAlignCenter }}>
            {`${t("invoice")}: ${bookingDetails?.invoiceNumber}`}
          </Typography>
          <Typography sx={{ ...BookingDetailsStyles.textAlignCenter }}>
            {`${t("bookingNumber")}: ${bookingDetails?.bookingRef}`}
          </Typography>
        </Box>
        <Divider />
        <Box sx={BookingDetailsStyles.autoRows}>
          {bookingDetails?.beachChairBookings &&
            bookingDetails?.beachChairBookings.map((beachChair) => (
              <Box sx={BookingDetailsStyles.cartItem} key={`${beachChair.id}`}>
                <Box sx={BookingDetailsStyles.twoColumns}>
                  <Typography sx={BookingDetailsStyles.typographyBoldBlue}>
                    {t("common:beachChair")} {t("common:publicNumber")}:{" "}
                    {beachChair.beachChair.publicNumber}
                  </Typography>
                </Box>
                <Box sx={BookingDetailsStyles.twoColumns}>
                  <Typography sx={BookingDetailsStyles.typographyBoldGray}>
                    {t("common:model")}:
                  </Typography>
                  <Typography sx={BookingDetailsStyles.typographyGray}>
                    {t(`common:models.${beachChair.beachChair.model}`)}
                  </Typography>
                </Box>
                <Divider sx={BookingDetailsStyles.divider} />
                <Box sx={BookingDetailsStyles.twoColumns}>
                  <Typography sx={BookingDetailsStyles.typographyBoldGray}>
                    {t("common:section")}:
                  </Typography>
                  <Typography sx={BookingDetailsStyles.typographyGray}>
                    {beachChair.beachChair.section.value}
                  </Typography>
                </Box>
                <Divider sx={BookingDetailsStyles.divider} />
                <Box sx={BookingDetailsStyles.twoColumns}>
                  <Typography sx={BookingDetailsStyles.typographyBoldGray}>
                    {t("common:row")}:
                  </Typography>
                  <Typography sx={BookingDetailsStyles.typographyGray}>
                    {beachChair.beachChair.row.value}
                  </Typography>
                </Box>
                <Divider sx={BookingDetailsStyles.divider} />
                <Box sx={BookingDetailsStyles.twoColumns}>
                  <Typography sx={BookingDetailsStyles.typographyBoldGray}>
                    {t("price")}:
                  </Typography>
                  <Typography sx={BookingDetailsStyles.typographyGray}>
                    {EURO(beachChair.price || 0).format()}
                  </Typography>
                </Box>
                <Box sx={BookingDetailsStyles.twoColumns}>
                  <Typography sx={BookingDetailsStyles.typographyBoldGray}>
                    {t("bookingDate")}:
                  </Typography>
                  <Typography sx={BookingDetailsStyles.typographyGray}>
                    {dateToProperFormat(beachChair.start)} -{" "}
                    {dateToProperFormat(beachChair.end)}
                  </Typography>
                </Box>
                <Divider sx={BookingDetailsStyles.divider} />
              </Box>
            ))}
          {bookingDetails?.extras &&
            bookingDetails?.extras.map((extra) => (
              <Box
                sx={BookingDetailsStyles.cartItem}
                key={`${extra.id}-${extra.name}`}
              >
                <Box sx={BookingDetailsStyles.twoColumns}>
                  <Typography sx={BookingDetailsStyles.typographyBoldBlue}>
                    {t("common:additionalItems")}: {extra.name}
                  </Typography>
                </Box>
                <Box sx={BookingDetailsStyles.twoColumns}>
                  <Typography sx={BookingDetailsStyles.typographyBoldGray}>
                    {t("price")}:
                  </Typography>
                  <Typography sx={BookingDetailsStyles.typographyGray}>
                    {EURO(extra.amount * extra.price || 0).format()}
                  </Typography>
                </Box>
                <Box sx={BookingDetailsStyles.twoColumns}>
                  <Typography sx={BookingDetailsStyles.typographyBoldGray}>
                    {`${t("amount")}: ${extra.amount}`}
                  </Typography>
                </Box>
                <Divider sx={BookingDetailsStyles.divider} />
              </Box>
            ))}
        </Box>
        <Box sx={{ ...BookingDetailsStyles.typographyInfo }}>
          <Typography sx={{ ...BookingDetailsStyles.textAlignCenter }}>
            {`${t("totalPrice")}: ${EURO(
              bookingDetails?.totalPrice || 0
            ).format()}`}
          </Typography>
          {matchesMd && (
            <Button
              sx={{ ...BookingDetailsStyles.button }}
              onClick={() => {
                window.location.href = `strandbutler://booking/${bookingDetails?.customer.email}/${bookingDetails?.bookingRef}`;
              }}
            >
              {t("common:openApp")}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default BookingDetails;
