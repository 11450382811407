import { IRowData, ISection } from "./../../utils/models/ISection";
import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  BeachChairResponseDto,
  FiltersResponseDtoClass,
  SectionCalendarAvailabilityResponseDto,
} from "../../api-client/generated";
import {
  AvailabilityFailurePayload,
  GetBeachChairDetailedInformationRequestPayload,
  GetBeachChairDetailedInformationSuccessPayload,
  GetPublicFiltersBeachChairsRequestPayload,
  GetPublicFiltersBeachChairsSuccessPayload,
  GetPublicSectionsBeachChairsAvailabilityRequestPayload,
  GetPublicSectionsBeachChairsAvailabilitySuccessPayload,
  GetPublicSectionsBeachChairsAvailabilityTimelineRequestPayload,
  GetPublicSectionsBeachChairsAvailabilityTimelineSuccessPayload,
  SelectBeachChairModelRateRequestPayload,
  SelectBeachChairRequestPayload,
  SelectBeachChairTriggerRequestPayload,
  SetRowRequestPayload,
  SetTowIdSubSectionRequestPayload,
} from "../actionPayload/availabilityPayloads";
import { AppState } from "./rootReducer";

export interface ISelectedBeachChairModelRate {
  model: string;
  rate: string;
  discount: boolean;
}

const beachChairAdapter = createEntityAdapter<IRowData>({
  selectId: (beachChair) => beachChair.id,
});

const selectedBeachChairDetailedInformationAdapter =
  createEntityAdapter<BeachChairResponseDto>({
    selectId: (beachChair) => beachChair.id,
  });

interface IAvailability {
  pending: boolean;
  error: string;
  filters?: FiltersResponseDtoClass;
  sections: ISection[];
  sectionBeachChairs: EntityState<IRowData>;
  selectedRow?: ISection;
  selectedBeachChair?: IRowData;
  selectedBeachChairTrigger: boolean;
  selectedBeachChairDetailedInformation: EntityState<BeachChairResponseDto>;
  selectedBeachChairModelRate?: ISelectedBeachChairModelRate;
  rowIdSubSection?: number;
  timeline?: SectionCalendarAvailabilityResponseDto;
}

const initialState: IAvailability = {
  pending: true,
  error: "",
  sectionBeachChairs: beachChairAdapter.getInitialState({}),
  selectedBeachChairTrigger: false,
  selectedBeachChairDetailedInformation:
    selectedBeachChairDetailedInformationAdapter.getInitialState({}),
  sections: [],
};

const availabilitySlice = createSlice({
  name: "availability",
  initialState,
  reducers: {
    getPublicFiltersBeachChairsRequest(
      state,
      action: PayloadAction<GetPublicFiltersBeachChairsRequestPayload>
    ) {},
    getPublicFiltersBeachChairsSuccess(
      state,
      action: PayloadAction<GetPublicFiltersBeachChairsSuccessPayload>
    ) {
      state.filters = action.payload.filters;
    },
    getPublicSectionsBeachChairsAvailabilityRequest(
      state,
      action: PayloadAction<GetPublicSectionsBeachChairsAvailabilityRequestPayload>
    ) {
      state.pending = true;
      if (action.payload.hasSubsections === false) {
        state.sections = [];
      }
    },
    getPublicSectionsBeachChairsAvailabilitySuccess(
      state,
      action: PayloadAction<GetPublicSectionsBeachChairsAvailabilitySuccessPayload>
    ) {
      state.pending = false;
      state.sections = action.payload.sections;
      action.payload.sections.forEach((section) =>
        section.rowData.forEach((beachChair) => {
          beachChairAdapter.addOne(state.sectionBeachChairs, beachChair);
        })
      );
    },
    getPublicSectionsBeachChairsAvailabilityTimelineRequest(
      state,
      action: PayloadAction<GetPublicSectionsBeachChairsAvailabilityTimelineRequestPayload>
    ) {
      state.pending = true;
      if (action.payload.hasSubsections === false) {
        state.sections = [];
      }
    },
    getPublicSectionsBeachChairsAvailabilityTimelineSuccess(
      state,
      action: PayloadAction<GetPublicSectionsBeachChairsAvailabilityTimelineSuccessPayload>
    ) {
      state.pending = false;
      state.timeline = action.payload.timeline;
    },
    setRowRequest(state, action: PayloadAction<SetRowRequestPayload>) {
      state.selectedRow = action.payload.selectedRow;
    },
    selectBeachChairRequest(
      state,
      action: PayloadAction<SelectBeachChairRequestPayload>
    ) {
      state.selectedBeachChairTrigger = true;
      state.selectedBeachChair = action.payload.selectedBeachChair;
    },
    selectBeachChairTriggerRequest(
      state,
      action: PayloadAction<SelectBeachChairTriggerRequestPayload>
    ) {
      state.selectedBeachChairTrigger = action.payload.trigger;
    },
    getBeachChairDetailedInformationRequest(
      state,
      action: PayloadAction<GetBeachChairDetailedInformationRequestPayload>
    ) {},
    getBeachChairDetailedInformationSuccess(
      state,
      action: PayloadAction<GetBeachChairDetailedInformationSuccessPayload>
    ) {
      selectedBeachChairDetailedInformationAdapter.upsertOne(
        state.selectedBeachChairDetailedInformation,
        action.payload.beachChair
      );
    },
    selectBeachChairModelRateRequest(
      state,
      action: PayloadAction<SelectBeachChairModelRateRequestPayload>
    ) {
      state.selectedBeachChairModelRate = {
        discount: action.payload.discount,
        model: action.payload.model,
        rate: action.payload.rate,
      };
    },
    setRowIdSubSectionRequest(
      state,
      action: PayloadAction<SetTowIdSubSectionRequestPayload>
    ) {
      state.rowIdSubSection = action.payload.rowId;
    },
    availabilityFailure(
      state,
      action: PayloadAction<AvailabilityFailurePayload>
    ) {
      state.error = action.payload.error;
      state.pending = false;
    },
  },
});

export const { selectById: getBeachChairById } =
  beachChairAdapter.getSelectors<AppState>(
    (state) => state.availability.sectionBeachChairs
  );

export const { selectById: getSelectedBeachChairDetailedInformationById } =
  selectedBeachChairDetailedInformationAdapter.getSelectors<AppState>(
    (state) => state.availability.selectedBeachChairDetailedInformation
  );

export const {
  getPublicFiltersBeachChairsRequest,
  getPublicFiltersBeachChairsSuccess,
  getPublicSectionsBeachChairsAvailabilityRequest,
  getPublicSectionsBeachChairsAvailabilitySuccess,
  selectBeachChairRequest,
  getBeachChairDetailedInformationRequest,
  getBeachChairDetailedInformationSuccess,
  selectBeachChairModelRateRequest,
  setRowRequest,
  selectBeachChairTriggerRequest,
  setRowIdSubSectionRequest,
  getPublicSectionsBeachChairsAvailabilityTimelineRequest,
  getPublicSectionsBeachChairsAvailabilityTimelineSuccess,
  availabilityFailure,
} = availabilitySlice.actions;

export default availabilitySlice.reducer;
