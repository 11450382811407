import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { WidgetProvider } from "./contexts";
import reportWebVitals from "./reportWebVitals";
import store from "./store";
import { Provider } from "react-redux";
import { Route, Router, Switch } from "react-router";
import history from "./utils/history/history";
import "./utils/i18n/i18n";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import TagManager from "react-gtm-module";
import Headless from "./components/Headless";

const tagManagerArgs = {
  gtmId: "GTM-W9Z7FF4",
};
TagManager.initialize(tagManagerArgs);


const theme = createTheme({
  typography: {
    fontFamily: `"NunitoMedium", sans-serif`,
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <WidgetProvider>
        <Provider store={store}>
          <Router history={history}>
            <Switch>
              <Route path="/static/headless/:params" component={Headless} />
              <Route path="/" component={App} />
            </Switch>
          </Router>
        </Provider>
      </WidgetProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
