const {
    REACT_APP_OIDC_AUTHORUTY_KEYCLOAK,
    REACT_APP_OIDC_CLIENT_ID_KEYCLOAK,
    REACT_APP_OIDC_REDIRECT_URI_KEYCLOAK,
    REACT_APP_PUBLIC_URL,
    REACT_APP_API_PREFIX,
  } = process.env;
  
  const CONFIG_DATA = JSON.parse(
    // @ts-ignore
    decodeURIComponent(window["SERVER_DATA"] || "%7B%7D")
  ) as {
    REACT_APP_OIDC_AUTHORITY: string;
    REACT_APP_OIDC_CLIENT_ID: string;
    REACT_APP_OIDC_REDIRECT_URI: string;
    REACT_APP_OIDC_LOGOUT_URI: string;
    PUBLIC_URL: string;
    API_PREFIX: string;
  };
  
  export const oidcConfig = {
    authority:
      CONFIG_DATA.REACT_APP_OIDC_AUTHORITY || REACT_APP_OIDC_AUTHORUTY_KEYCLOAK,
    clientId:
      CONFIG_DATA.REACT_APP_OIDC_CLIENT_ID || REACT_APP_OIDC_CLIENT_ID_KEYCLOAK,
    redirectUri:
      CONFIG_DATA.REACT_APP_OIDC_REDIRECT_URI ||
      REACT_APP_OIDC_REDIRECT_URI_KEYCLOAK,
    logoutUri: CONFIG_DATA.REACT_APP_OIDC_LOGOUT_URI || "",
  };
  
  export const apiConfig = {
    publicUrl: CONFIG_DATA.PUBLIC_URL || REACT_APP_PUBLIC_URL,
    apiPrefix: CONFIG_DATA.API_PREFIX || REACT_APP_API_PREFIX,
  };
  