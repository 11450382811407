import { stylesConfig } from "../../styles/styles";

const globalWidth = {
  lg: `calc(${stylesConfig.leftMinComponentWidth} - 40px)`,
  xl: `calc(${stylesConfig.leftComponentWidth} - 2*${stylesConfig.marginLeft})`,
};

export const SectionStyles = {
  root: {
    display: "flow",
    gap: `calc(${stylesConfig.marginTop})`,
    gridTemplateRows: "auto auto auto auto",
    alignItems: "center",
    justifyContent: "center",
    padding: {
      xs: "10px",
      sm: "10px",
      md: "10px",
      xl: "10px",
      // md: `calc(3*${stylesConfig.marginTop}) ${stylesConfig.marginRight} calc(3*${stylesConfig.marginBottom}) ${stylesConfig.marginLeft}`
    },
    paddingBottom: {
      xs: "10px",
      sm: "10px",
      md: "10px",
      xl: "10px",
    },
    margin: {
      xs: "10px",
      sm: "10px",
      md: 0,
      xl: 0,
    },
    marginTop: {
      xs: "60px",
      sm: "60px",
      md: 0,
      xl: 0,
    },
    maxHeight: {
      // xs: "26vh",
      // sm: "26vh",
      md: `calc(100vh - ${stylesConfig.appBarHeight} - 3*${stylesConfig.marginTop} - 2*${stylesConfig.marginBottom})`,
    },
    backgroundColor: stylesConfig.whiteColor,
    borderRadius: stylesConfig.sxBorderRadius,
    boxShadow: {
      xs: stylesConfig.blueBoxShadow,
      sm: stylesConfig.blueBoxShadow,
      md: stylesConfig.boxShadow,
    },
    overflowY: "scroll",
    boxSizing: "content-box",
    "&::-webkit-scrollbar": {
      width: 3,
      height: 1,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: stylesConfig.blueColor,
      borderRadius: stylesConfig.sxBorderRadius,
    },
  } as const,
  sectionImage: {
    // width: globalWidth,
    minHeight: 200,
    borderRadius: stylesConfig.sxBorderRadius,
    border: `solid 1px ${stylesConfig.blueColor}`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  twoColumnsGrid: {
    width: globalWidth,
    display: "grid",
    gridTemplateColumns: "50% 50%",
  },
  typographyBoldBlue: {
    fontSize: 16,
    fontWeight: "bold",
    color: stylesConfig.blueColor,
  },
  maxWidth: {
    maxWidth: "400px",
    minWidth: "250px",
  },
  columnsGrid: {
    display: "grid",
    gap: 1,
    fontSize: 16,
    gridTemplateColumns: "10% 90%",
    color: stylesConfig.grayColor,
  },
  chip: {
    width: `fit-content`,
    marginRight: "3px",
    marginBottom: "3px",
  },
  buttonsSection: {
    display: "grid",
    gap: 1,
    gridTemplateColumns: "50% 50%",
  } as const,
  buttonBack: {
    fontSize: "11px",
    color: `${stylesConfig.whiteColor}`,
    borderRadius: stylesConfig.sxBorderRadius,
    height: `calc(0.5*${stylesConfig.defaultControlHeight})`,
    backgroundColor: stylesConfig.blueColor,
    boxShadow: stylesConfig.blueBoxShadow,
    "&:hover": {
      backgroundColor: stylesConfig.blueColor,
      boxShadow: stylesConfig.blueBoxShadow,
    },
  },
  additionalItemsBtnCenter: {
    display: "grid",
    justifyContent: "center",
  },
  additionalItemsBtn: {
    marginTop: "20px",
    fontSize: "11px",
    color: `${stylesConfig.whiteColor}`,
    borderRadius: stylesConfig.sxBorderRadius,
    height: `calc(0.5*${stylesConfig.defaultControlHeight})`,
    backgroundColor: stylesConfig.blueColor,
    boxShadow: stylesConfig.blueBoxShadow,
    "&:hover": {
      backgroundColor: stylesConfig.blueColor,
      boxShadow: stylesConfig.blueBoxShadow,
    },
  },
  buttonGo: {
    fontSize: "11px",
    color: `${stylesConfig.whiteColor}`,
    backgroundColor: stylesConfig.orangeColor,
    borderRadius: stylesConfig.sxBorderRadius,
    height: `calc(0.5*${stylesConfig.defaultControlHeight})`,
    boxShadow: stylesConfig.orangeBoxShadow,
    "&:hover": {
      backgroundColor: stylesConfig.orangeColor,
      boxShadow: stylesConfig.orangeBoxShadow,
    },
  },
  centered: {
    display: "grid",
    gap: "10px",
  } as const,
};
