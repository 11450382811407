export const stylesConfig = {
  whiteColor: "#FFFFFF",
  blueColor: "#1A4090",
  orangeColor: "#ED6B16",
  lightBlueColor: "rgba(26, 64, 144, 0.2)",
  lightOrangeColor: "rgba(237, 107, 22, 0.2)",
  blackTransparentColor: "rgba(111,111,111,0.2)",
  blackDarkTransparentColor: "rgba(0, 0, 0, 0.54)",
  lightGrayColor: "#EDEDED",
  grayColor: "#909091",
  grayLabelColor: "#8b97a7",
  blueBackground: "rgba(232, 236, 244, 1)",
  darkBlue: "rgba(48,64,81,255)",

  marginLeft: "1vw",
  marginTop: "1vh",
  marginRight: "1vw",
  marginBottom: "4vh",

  boxShadow: "0 0 20px #ccc",
  orangeBoxShadow: "0 2px 14px #ED6B16",
  blueBoxShadow: "0 2px 14px #1A4090",
  grayBoxShadow: "0 2px 14px #909091",
  borderRadius: 20,
  borderRadiusIcon: 5,
  sxBorderRadius: 5,

  appBarHeight: "70px",
  leftComponentWidth: "20vw",
  leftMinComponentWidth: "350px",
  rightComponentWidth: "20vw",
  rightMinComponentWidth: "350px",
  mainComponentWidth: "55vw",
  mainMinComponentWidth: "65vw",
  footerHeight: "50px",
  beachChairMobileViewHeight: "420px",
  additionalItemsMobileViewHeight: "90%",
  bookingDetailsMobileViewHeight: "95%",

  defaultControlHeight: 56,

  iconSize: 30,
  buttonIconSize: 44,
};
