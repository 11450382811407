import {
  GetCityRequestPayload,
  GetCitySuccessPayload,
  CityFailurePayload,
  SetCityRequestPayload,
} from "./../actionPayload/cityPayloads";
import { createEntityAdapter, createSlice, EntityState, PayloadAction } from "@reduxjs/toolkit";
import { CityResponseDto } from "../../api-client/generated";
import { AppState } from "./rootReducer";

const citiesAdapter = createEntityAdapter<CityResponseDto>({
  selectId: (city) => city.id,
});

interface ICityState {
  pending: boolean;
  citiesAdapter: EntityState<CityResponseDto>;
  city?: CityResponseDto;
  error: string;
}

const initialState: ICityState = {
  pending: false,
  citiesAdapter: citiesAdapter.getInitialState({}),
  city: undefined,
  error: "",
};

const citynSlice = createSlice({
  name: "city",
  initialState,
  reducers: {
    getCityRequest(state, action: PayloadAction<GetCityRequestPayload>) {
      state.pending = true;
    },
    getCitySuccess(state, action: PayloadAction<GetCitySuccessPayload>) {
      state.pending = false;
      citiesAdapter.removeAll(state.citiesAdapter);
      citiesAdapter.addMany(state.citiesAdapter, action.payload.cities);
    },
    setCityRequest(state, action: PayloadAction<SetCityRequestPayload>) {
      state.city = action.payload.city;
    },
    clearCityRequest(state) {
      state.city = undefined;
    },
    cityFailure(state, action: PayloadAction<CityFailurePayload>) {
      state.pending = false;
      state.error = action.payload.error;
    },
  },
});


export const {
  selectAll: getAllCities,
  selectById: getCityById,
} = citiesAdapter.getSelectors<AppState>((state) => state.city.citiesAdapter);

export const {
  getCityRequest,
  getCitySuccess,
  setCityRequest,
  clearCityRequest,
  cityFailure,
} = citynSlice.actions;

export default citynSlice.reducer;
