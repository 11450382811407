import { Box, Divider, Typography } from "@mui/material";
import { RowDividerStyles } from "../../../utils/hooks/styles/rowDividerStyle";

export interface IRowDividerProps {
  name: string;
}

const RowDivider = ({ name }: IRowDividerProps) => {
  return (
    <Box sx={RowDividerStyles.content}>
      <Divider sx={RowDividerStyles.divider} data-testid="first-divider" />
      <Typography sx={RowDividerStyles.boldTypography} data-testid="row-name">
        {name}
      </Typography>
      <Divider sx={RowDividerStyles.divider} data-testid="second-divider" />
    </Box>
  );
};

export default RowDivider;
