import { Marker, MarkerClusterer } from "@react-google-maps/api";
import React from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../store";
import { setGlobalCityRequest } from "../../../store/reducers/locationReducer";
import { getCitiesSelector } from "../../../store/selectors/citySelectors";
import { geoJsonGuard } from "../../../utils/functions/geoJsonGuard";
import { IGeoPoint } from "../../../utils/models/IGeoPoint";
import { IMapStateProps } from "../../../utils/models/IMapStateProps";
import { stylesConfig } from "../../../utils/styles/styles";
import logo from "./../../../assets/SBLMarker.png";
import clusterLogo from "./../../../assets/SBLMarkerCluster.png";

const AllCitiesMapState = ({ setMapBounds }: IMapStateProps) => {
  const dispatch = useAppDispatch();
  const cities = useSelector(getCitiesSelector);
  React.useEffect(() => {
    const bounds = new window.google.maps.LatLngBounds();
    cities
      .filter((city) => geoJsonGuard(city.geoJson))
      .forEach((city) => {
        bounds.extend({
          lat: (city.geoJson as IGeoPoint).coordinates[1],
          lng: (city.geoJson as IGeoPoint).coordinates[0],
        });
      });
    setMapBounds(bounds);
  }, [cities, setMapBounds]);

  return (
    <MarkerClusterer
      gridSize={40}
      averageCenter
      styles={[
        {
          url: clusterLogo,
          textColor: stylesConfig.blueColor,
          height: 50,
          width: 50,
          textSize: 25,
          fontFamily: "NunitoMedium",
        },
      ]}
    >
      {(clusterer) => (
        <>
          {cities
            .filter((city) => geoJsonGuard(city.geoJson))
            .map((city) => (
              <Marker
                key={`city-cluster-${city.id}`}
                icon={{
                  url: logo,
                  scaledSize: new google.maps.Size(50, 50),
                }}
                position={{
                  lat: (city.geoJson as IGeoPoint).coordinates[1],
                  lng: (city.geoJson as IGeoPoint).coordinates[0],
                }}
                onClick={() => {
                  dispatch(setGlobalCityRequest({ id: city.id }));
                }}
                clusterer={clusterer}
              />
            ))}
        </>
      )}
    </MarkerClusterer>
  );
};

export default AllCitiesMapState;
