import { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { PublicCreateCustomerBodyDto } from "../../../api-client/generated";
import { useAppDispatch } from "../../../store";
import { addCustomerBookingRequest } from "../../../store/reducers/bookingReducer";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

export function useCustomerForm() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["customers"]);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        firstName: yup.string().required(t("firstName") + t("isMandatory")),
        lastName: yup.string().required(t("lastName") + t("isMandatory")),
        salutation: yup.string(),
        address: yup.object({
          zip: yup.string().required(t("zip") + t("isMandatory")),
          city: yup.string().required(t("city") + t("isMandatory")),
          street: yup.string().required(t("street") + t("isMandatory")),
          country: yup.string(),
        }),
        additionalData: yup.object().shape({
          marketing: yup.string(),
          validDiscountCard: yup.boolean().optional(),
        }),
        telephone: yup.string(),
        email: yup
          .string()
          .email(t("email") + t("isMandatory"))
          .required(t("email") + t("isMandatory")),
      }),
    [t]
  );

  const {
    setValue,
    control,
    handleSubmit,
    watch,
    formState: { isValid, errors },
  } = useForm<PublicCreateCustomerBodyDto>({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const onSubmit = useCallback(
    (publicCreateCustomerBodyDto: PublicCreateCustomerBodyDto) => {
      dispatch(addCustomerBookingRequest({ publicCreateCustomerBodyDto }));
    },
    [dispatch]
  );

  return {
    setValue,
    control,
    errors,
    isValid,
    watch,
    onSubmit: handleSubmit(onSubmit),
  };
}
