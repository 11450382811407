import { getIsSubSectionsSelector } from "./../selectors/mainAppSelectors";
import { Action } from "@reduxjs/toolkit";
import { format } from "date-fns-tz";
import {
  all,
  put,
  call,
  takeLatest,
  debounce,
  select,
  take,
} from "typed-redux-saga";
import { beachChairApi, publicApi } from "../../api/api";
import { ISection } from "../../utils/models/ISection";
import {
  availabilityFailure,
  getBeachChairDetailedInformationRequest,
  getBeachChairDetailedInformationSuccess,
  getPublicFiltersBeachChairsRequest,
  getPublicFiltersBeachChairsSuccess,
  getPublicSectionsBeachChairsAvailabilityRequest,
  getPublicSectionsBeachChairsAvailabilitySuccess,
  getPublicSectionsBeachChairsAvailabilityTimelineRequest,
  getPublicSectionsBeachChairsAvailabilityTimelineSuccess,
} from "../reducers/availabilityReducer";
import { mainComponentShowChangeRequest } from "../reducers/mainAppReducer";
import dayjs from "dayjs";
import { useCreatedBookingIndicator } from "../../components/locationAvailabilitiesBeachChairs/availabilitiesBeachChair/hooks/useCreatedBookingIndicator";
import { ProgramWithOmittedUuid } from "@nessprim/planby/dist/Epg/helpers";
import { getVendorSelector } from "../selectors/vendorSelectors";

function* getPublicFiltersBeachChairstSaga(action: Action) {
  try {
    if (getPublicFiltersBeachChairsRequest.match(action)) {
      const { publicReference } = action.payload;
      const { data } = yield* call(
        [publicApi, publicApi.publicControllerGetPublicChairsFilters],
        {
          publicReference,
        }
      );
      yield* put(getPublicFiltersBeachChairsSuccess({ filters: data }));
    }
  } catch (e: any) {
    yield* put(
      availabilityFailure({
        error: e.error,
      })
    );
  }
}

function* getPublicSectionsBeachChairsAvailabilitySaga(action: Action) {
  try {
    if (getPublicSectionsBeachChairsAvailabilityRequest.match(action)) {
      const { publicReference, sectionId, start, end, rowId } = action.payload;

      const hasSubsections = yield* select(getIsSubSectionsSelector);
      const { data } = yield* call(
        [publicApi, publicApi.publicControllerGetPublicSectionsBeachChairs],
        {
          id: sectionId,
          publicReference,
          start: format(new Date(start), "yyyy-MM-dd"),
          end: format(new Date(end), "yyyy-MM-dd"),
        }
      );
      const response = data as unknown as ISection[];
      yield* put(
        getPublicSectionsBeachChairsAvailabilitySuccess({ sections: response })
      );
      if (hasSubsections === false) {
        yield* take("vendor/getVendorSuccess");
        const cfg = yield* select(getVendorSelector);
        const configuration = cfg?.configuration;
        if (configuration?.beachChairView === "grid") {
          yield* put(mainComponentShowChangeRequest({ toggle: true }));
        }
      }
      if (rowId) {
        yield* put(mainComponentShowChangeRequest({ toggle: true }));
      }
    }
  } catch (e: any) {
    yield* put(mainComponentShowChangeRequest({ toggle: true }));
    yield* put(
      availabilityFailure({
        error: e.error,
      })
    );
  }
}

export const yearChecker = (year?: string) => {
  return dayjs(year).isSame(dayjs(), "year");
};

function* getPublicSectionsBeachChairsAvailabilityTimelineSaga(action: Action) {
  try {
    if (getPublicSectionsBeachChairsAvailabilityTimelineRequest.match(action)) {
      const { publicReference, sectionId, start, end } = action.payload;
      const { data: timeline } = yield* call(
        [publicApi, publicApi.publicControllerGetPublicSectionsCalendar],
        {
          id: sectionId,
          publicReference,
          start: dayjs().isSame(dayjs(new Date(start)), "year")
            ? format(new Date(), "yyyy-MM-dd")
            : format(new Date(start), "yyyy-MM-dd"),
          end: format(
            new Date(dayjs(end).endOf("year").toISOString()),
            "yyyy-MM-dd"
          ),
          timeZone: "Europe/Berlin",
        }
      );
      yield* put(
        getPublicSectionsBeachChairsAvailabilityTimelineSuccess({
          timeline: timeline,
        })
      );
      const { setCreatedBookings, editedChannelUuid } =
        useCreatedBookingIndicator.getState();
      if (editedChannelUuid === "") {
        const egp = (timeline?.egp as any as ProgramWithOmittedUuid[]) ?? [];
        let rows: ProgramWithOmittedUuid[] = [];
        timeline?.channels.forEach((channel, index) => {
          if ((channel as any).type === "row") {
            rows.push({
              since: `${format(new Date(start), "yyyy-MM-dd")}T01:00:00`,
              till: `${format(
                new Date(dayjs(end).add(-2, "day").toISOString()),
                "yyyy-MM-dd"
              )}T23:59:59`,
              channelUuid: (channel as any).uuid,
              type: "row",
              id: `row-${(channel as any).uuid}-${index}`,
            });
          }
        });

        const egpWithRows = [...egp, ...rows];
        setCreatedBookings(egpWithRows);
      }
    }
  } catch (e: any) {
    yield* put(mainComponentShowChangeRequest({ toggle: true }));
    yield* put(
      availabilityFailure({
        error: e.error,
      })
    );
  }
}

function* getBeachChairDetailedInformationSaga(action: Action) {
  try {
    if (getBeachChairDetailedInformationRequest.match(action)) {
      const { beachChairId } = action.payload;
      const { data } = yield* call(
        [beachChairApi, beachChairApi.beachChairsControllerFindOne],
        {
          id: beachChairId,
        }
      );
      yield* put(getBeachChairDetailedInformationSuccess({ beachChair: data }));
    }
  } catch (e: any) {
    yield* put(
      availabilityFailure({
        error: e.error,
      })
    );
  }
}

function* availabilitySaga() {
  yield* all([
    takeLatest(
      getPublicFiltersBeachChairsRequest.type,
      getPublicFiltersBeachChairstSaga
    ),
    debounce(
      1000,
      getPublicSectionsBeachChairsAvailabilityRequest.type,
      getPublicSectionsBeachChairsAvailabilitySaga
    ),
    debounce(
      1000,
      getPublicSectionsBeachChairsAvailabilityTimelineRequest.type,
      getPublicSectionsBeachChairsAvailabilityTimelineSaga
    ),
    takeLatest(
      getBeachChairDetailedInformationRequest.type,
      getBeachChairDetailedInformationSaga
    ),
  ]);
}

export default availabilitySaga;
