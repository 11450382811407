import React from "react";
import {
  BookingStyles,
  useBookingStyles,
} from "../../utils/hooks/styles/bookingStyles";
import {
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  Link,
  Modal,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { getBookingSelector } from "../../store/selectors/bookingSelectors";
import { dateToProperFormat } from "../../utils/convertions/dataConversion";
import CheckoutModal from "./checkoutModal/checkoutModal";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../store";
import {
  deleteBeachChairFromBookingRequest,
  deleteExtrasFromBookingRequest,
} from "../../store/reducers/bookingReducer";
import {
  leftDrawerChangeRequest,
  rightDrawerChangeRequest,
} from "../../store/reducers/mainAppReducer";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import DeleteIcon from "@mui/icons-material/Delete";
import { EURO } from "../../utils/convertions/euroFormater";
import { badgeCount } from "../../utils/functions/badgeCount";
import { getAllExtrasSelector } from "../../store/selectors/extraSelectors";
import { getSelectedSectionSelector } from "../../store/selectors/sectionSelectors";
import { getExtrasRequest } from "../../store/reducers/extrasReducer";
import { useLocation } from "react-router-dom";
import { useCreatedBookingIndicator } from "../locationAvailabilitiesBeachChairs/availabilitiesBeachChair/hooks/useCreatedBookingIndicator";

const Booking = () => {
  const classes = useBookingStyles();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const section = useSelector(getSelectedSectionSelector);
  const items = useSelector(getAllExtrasSelector);
  const { t } = useTranslation(["bookings"]);
  const booking = useSelector(getBookingSelector);
  const [open, setOpen] = React.useState(false);
  React.useEffect(() => {
    if (
      location.pathname.includes("/result/error") &&
      location.search.includes("sbl_bookingRef") &&
      location.search.includes("sbl_email")
    ) {
      setOpen(true);
    }
  }, [location]);
  React.useEffect(() => {
    dispatch(getExtrasRequest({}));
    // eslint-disable-next-line
  }, [section]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (
      booking &&
      booking.beachChairBookings.length === 0 &&
      booking.extras.length === 0
    ) {
      dispatch(rightDrawerChangeRequest({ toggle: false }));
    }
    // eslint-disable-next-line
  }, [booking]);

  const { removeBooking } = useCreatedBookingIndicator();

  const ref = React.useRef();

  return (
    <Box sx={BookingStyles.root}>
      <Modal open={open} onClose={handleClose} style={{ zIndex: 999999999 }}>
        <CheckoutModal ref={ref} onClose={handleClose} />
      </Modal>
      <Box sx={BookingStyles.cardHeader}>
        <Badge
          badgeContent={badgeCount(booking)}
          color="primary"
          sx={BookingStyles.customBadge}
          classes={{ badge: classes.customBadge }}
        >
          <AddShoppingCartIcon sx={BookingStyles.icon} />
        </Badge>
        <Typography>{t("cart")}</Typography>
      </Box>
      <Box sx={BookingStyles.autoRows}>
        {booking?.beachChairBookings &&
          booking?.beachChairBookings.map((beachChair) => (
            <Box
              sx={BookingStyles.cartItem}
              key={`${(beachChair as any)?.beachChairId}-${beachChair.id}`}
            >
              <Box sx={BookingStyles.twoColumns}>
                <Typography sx={BookingStyles.typographyBoldBlue}>
                  {t("beachchairs:publicNumber")}:{" "}
                  {(beachChair as any).publicNumber}
                </Typography>
                <IconButton
                  sx={BookingStyles.iconButton}
                  aria-label="delete"
                  onClick={() => {
                    dispatch(
                      deleteBeachChairFromBookingRequest({
                        beachChairBookingId: beachChair.id,
                        beachChairId: (beachChair as any)?.beachChairId,
                        start: beachChair.start,
                        end: beachChair.end,
                      })
                    );
                    removeBooking(
                      `beachChair-${(beachChair as any)?.beachChairId}`
                    );
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
              <Box sx={BookingStyles.twoColumns}>
                <Typography sx={BookingStyles.typographyBoldGray}>
                  {t("price")}:
                </Typography>
                <Typography sx={BookingStyles.typographyGray}>
                  {beachChair.discount && "% "}
                  {EURO(beachChair.price || 0).format()}
                </Typography>
              </Box>
              <Box sx={BookingStyles.twoColumns}>
                <Typography sx={BookingStyles.typographyBoldGray}>
                  {t("bookingDate")}:
                </Typography>
                <Typography sx={BookingStyles.typographyGray}>
                  {dateToProperFormat(beachChair.start)} -{" "}
                  {dateToProperFormat(beachChair.end)}
                </Typography>
              </Box>
              <Divider sx={BookingStyles.divider} />
            </Box>
          ))}
        {booking?.extras &&
          booking?.extras.map((extra) => (
            <Box sx={BookingStyles.cartItem} key={`${extra.id}-${extra.name}`}>
              <Box sx={BookingStyles.twoColumns}>
                <Typography sx={BookingStyles.typographyBoldBlue}>
                  {extra.name}
                </Typography>
                <IconButton
                  sx={BookingStyles.iconButton}
                  aria-label="delete"
                  onClick={() => {
                    dispatch(
                      deleteExtrasFromBookingRequest({
                        bookingExtrasId: extra.id,
                      })
                    );
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
              <Box sx={BookingStyles.twoColumns}>
                <Typography sx={BookingStyles.typographyBoldGray}>
                  {t("price")}:
                </Typography>
                <Typography sx={BookingStyles.typographyGray}>
                  {EURO(extra.amount * extra.price || 0).format()}
                </Typography>
              </Box>
              <Box sx={BookingStyles.twoColumns}>
                <Typography sx={BookingStyles.typographyBoldGray}>
                  {`${t("amount")}: ${extra.amount}`}
                </Typography>
              </Box>
              <Divider sx={BookingStyles.divider} />
            </Box>
          ))}
      </Box>
      <Box sx={BookingStyles.cardFooter}>
        <Box sx={BookingStyles.twoColumns}>
          <Typography sx={BookingStyles.typographyBlueRight}>
            {t("totalPrice")}:
          </Typography>
          <Typography sx={BookingStyles.typographyBoldBlue}>
            {EURO(booking?.totalPrice || 0).format()}
          </Typography>
        </Box>
        <Link
          sx={BookingStyles.linkTypographyBoldBlue}
          onClick={() => {
            dispatch(rightDrawerChangeRequest({ toggle: false }));
          }}
        >
          + {t("continue")}
        </Link>
        {items && items.length > 0 && (
          <Link
            sx={BookingStyles.linkTypographyBoldBlue}
            onClick={() => {
              dispatch(rightDrawerChangeRequest({ toggle: false }));
              dispatch(leftDrawerChangeRequest({ toggle: true }));
            }}
          >
            + {t("continueExtras")}
          </Link>
        )}
        <Button sx={BookingStyles.button} onClick={handleOpen}>
          {t("confirmBooking")}
        </Button>
      </Box>
    </Box>
  );
};

export default Booking;
