import { useParams } from 'react-router';
import TermsContent from './static/terms';
import DataPrivacyContent from './static/dataprivacy';
import ImprintContent from './static/imprint';
import AboutContent from './static/about';

export default function Static() {
    const { params } = useParams<{ params: string }>();
    
    switch(params) {
    case 'terms':
        return <TermsContent />
    case 'dataprivacy':
        return <DataPrivacyContent />
    case 'imprint':
        return <ImprintContent />
    case 'about':
        return <AboutContent />
    default:
        return <div>404</div>
    }
}
