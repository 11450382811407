import { Action } from "@reduxjs/toolkit";
import { all, call, takeLatest } from "typed-redux-saga";
import { redirectRequest } from "../reducers/mainAppReducer";
import history from "../../utils/history/history";

function* redirectRequestSaga(action: Action) {
    try {
      if (redirectRequest.match(action)) {
        const { path } = action.payload;
        yield* call([history, history.push], path);
      }
    } catch (e) {
        
    }
  }
  
  function* mainAppSaga() {
    yield* all([
      takeLatest(redirectRequest.type, redirectRequestSaga)
    ]);
  }
  
  export default mainAppSaga;