import {
  ClearSectionRequestPayload,
  GetSectionByIdRequestPayload,
  GetSectionByIdSuccessPayload,
  GetSectionRequestPayload,
  GetSectionSuccessPayload,
  SectionFailurePayload,
  SetSectionRequestPayload,
} from "./../actionPayload/sectionPayloads";
import { createEntityAdapter, createSlice, EntityState, PayloadAction } from "@reduxjs/toolkit";
import { SectionResponseDto } from "../../api-client/generated";
import { AppState } from "./rootReducer";

const sectionAdapter = createEntityAdapter<SectionResponseDto>({
  selectId: (section) => section.id,
});

interface ISectionState {
  pending: boolean;
  sectionAdapter: EntityState<SectionResponseDto>;
  sections: SectionResponseDto[];
  section?: SectionResponseDto;
  error: string;
}

const initialState: ISectionState = {
  pending: false,
  sectionAdapter: sectionAdapter.getInitialState({}),
  sections: [],
  section: undefined,
  error: "",
}

const sectionnSlice = createSlice({
  name: "section",
  initialState,
  reducers: {
    getSectionRequest(
      state,
      action: PayloadAction<GetSectionRequestPayload>
    ) {
      state.pending = true;
    },
    getSectionSuccess(
      state,
      action: PayloadAction<GetSectionSuccessPayload>
    ) {
      state.pending = false;
      state.sections = action.payload.sections;
      sectionAdapter.removeAll(state.sectionAdapter);
      sectionAdapter.addMany(state.sectionAdapter, action.payload.sections);
    },
    getSectionByIdRequest(
      state,
      action: PayloadAction<GetSectionByIdRequestPayload>
    ) {
      state.pending = true;
    },
    getSectionByIdSuccess(
      state,
      action: PayloadAction<GetSectionByIdSuccessPayload>
    ) {
      state.pending = false;
      state.section = action.payload.section;
      sectionAdapter.addOne(state.sectionAdapter, action.payload.section);
    },
    setSectionRequest(
      state,
      action: PayloadAction<SetSectionRequestPayload>
    ) {
      state.section = action.payload.section;
    },
    clearSectionRequest(
      state,
      action: PayloadAction<ClearSectionRequestPayload>
    ) {
      state.section = undefined;
      state.sections = [];
    },
    sectionFailure(state, action: PayloadAction<SectionFailurePayload>) {
      state.pending = false;
      state.error = action.payload.error;
    },
  },
});

export const {
  selectAll: getAllSections,
  selectById: getSectionById,
} = sectionAdapter.getSelectors<AppState>((state) => state.section.sectionAdapter);

export const {
  getSectionRequest,
  getSectionSuccess,
  getSectionByIdRequest,
  getSectionByIdSuccess,
  setSectionRequest,clearSectionRequest,
  sectionFailure,
} = sectionnSlice.actions;

export default sectionnSlice.reducer;
