import {
  BookingDetailsResponseDto,
  BookingResponseDto,
} from "../../api-client/generated";
import { extrasCount } from "./extrasCount";

export const badgeCount = (
  booking?: BookingResponseDto | BookingDetailsResponseDto
): number => {
  let count = 0;
  if (booking) {
    count = booking.beachChairBookings.length + extrasCount(booking.extras);
  }
  return count;
};
