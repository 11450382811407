import {
    GetVendorRequestPayload,
    GetVendorSuccessPayload,
    VendorFailurePayload
} from "../actionPayload/vendorPayloads";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PublicVendorResponseDto } from "../../api-client/generated";

interface IVendorState {
    pending: boolean;
    vendor?: PublicVendorResponseDto;
    error: string;
}

const initialState: IVendorState = {
    pending: false,
    vendor: undefined,
    error: ""
}

const vendorSlice = createSlice({
    name: "vendor",
    initialState,
    reducers: {
        getVendorRequest(
            state,
            action: PayloadAction<GetVendorRequestPayload>
        ) {
            state.pending = true;
        },
        getVendorSuccess(
            state,
            action: PayloadAction<GetVendorSuccessPayload>
        ) {
            state.pending = false;
            state.vendor = action.payload.vendor;
        },
        vendorFailure(
            state,
            action: PayloadAction<VendorFailurePayload>
        ) {
            state.pending = false;
            state.error = action.payload.error;
        },
    },
});

export const {
    getVendorRequest,
    getVendorSuccess,
    vendorFailure
} = vendorSlice.actions;

export default vendorSlice.reducer;