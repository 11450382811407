import { stylesConfig } from "../../styles/styles";

const globalWidth = {
  md: `calc(${stylesConfig.leftMinComponentWidth} - 40px)`,
  xl: `calc(${stylesConfig.leftComponentWidth} - 2*${stylesConfig.marginLeft})`,
};

export const AdditionalItemsStylesRoot = {
  display: "grid",
  gap: "10px",
  width: globalWidth,
  paddingLeft: {
    xs: "10px",
    sm: "10px",
    md: "20px",
  },
  paddingRight: {
    xs: "10px",
    sm: "10px",
    md: "20px",
  },
  paddingTop: {
    xs: "10px",
    sm: "10px",
    md: `calc(3*${stylesConfig.marginTop})`,
  },
  paddingBottom: {
    xs: "30px",
    sm: "30px",
    md: `calc(3*${stylesConfig.marginTop})`,
  },
  maxHeight: {
    xs: `calc(${stylesConfig.additionalItemsMobileViewHeight} - 40px)`,
    sm: `calc(${stylesConfig.additionalItemsMobileViewHeight} - 40px)`,
    md: `calc(100vh - ${stylesConfig.appBarHeight} - 2*${stylesConfig.marginTop} - 2*${stylesConfig.marginBottom} - 140px) `,
  },
  backgroundColor: stylesConfig.whiteColor,
  borderRadius: {
    xs: `20px 20px 0 0`,
    sm: `20px 20px 0 0`,
    md: `${stylesConfig.sxBorderRadius} ${stylesConfig.sxBorderRadius} 0 0`,
  },
  marginTop: "10px",
  marginLeft: "1vw",
  marginRight: "1vw",
  overflowY: "scroll",
  boxSizing: "content-box",
  "&::-webkit-scrollbar": {
    width: 3,
    height: 1,
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: stylesConfig.blueColor,
  },
} as const;

export const AdditionalItemsStyles = {
  typographyBoldBlue: {
    fontSize: 16,
    fontWeight: "bold",
    color: stylesConfig.blueColor,
  } as const,
  typographyBoldBlueCenter: {
    textAlign: "center",
    marginLeft: "-20%",
  } as const,
  iconButton: {
    backgroundColor: stylesConfig.lightGrayColor,
    color: stylesConfig.blueColor,
    borderRadius: stylesConfig.borderRadiusIcon,
    width: stylesConfig.buttonIconSize,
    height: stylesConfig.buttonIconSize,
    justifySelf: "left",
  } as const,
  titleAdditionalItems: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "20% 80%",
    alignItems: "center",
  } as const,
  selectInfo: {
    display: "grid",
    paddingLeft: "25%",
    paddingRight: "25%",
    color: stylesConfig.grayColor,
  } as const,
  textAlignCenter: {
    fontSize: "12px",
    textAlign: "center",
  } as const,
  tabIcon: {
    height: "20px",
    width: "20px",
    "&.Mui-selected": {
      backgroundColor: stylesConfig.blueColor,
      color: stylesConfig.whiteColor,
    },
  } as const,
  boxBorder: {
    borderRadius: "16px",
    border: 2,
    padding: "8px",
    color: stylesConfig.blueColor,
    backgroundColor: stylesConfig.lightGrayColor,
  } as const,
  tab: {
    color: stylesConfig.blueColor,
    "&.Mui-selected": {
      color: stylesConfig.blueColor,
    },
    minWidth: "65px",
    fontSize: "10px",
  } as const,
  tabLabels: {
    color: stylesConfig.blueColor,
    "&.Mui-selected": {
      color: stylesConfig.whiteColor,
      backgroundColor: stylesConfig.blueColor,
    },
    minWidth: "65px",
    minHeight: "30px",
    fontSize: "10px",
    fontWeight: "bolder",
    borderRadius: "10px",
  } as const,
  selectedBoxBorder: {
    color: stylesConfig.whiteColor,
    backgroundColor: stylesConfig.blueColor,
    border: 2,
    borderColor: stylesConfig.blueColor,
    boxShadow: stylesConfig.blueBoxShadow,
  } as const,

  totalPriceBox: {
    backgroundColor: stylesConfig.lightGrayColor,
    borderRadius: "16px",
  },
  buttonAddToBooking: {
    height: `calc(${stylesConfig.defaultControlHeight}*0.5)`,
    width: "120px",
    color: `${stylesConfig.whiteColor}`,
    backgroundColor: `${stylesConfig.blueColor}`,
    borderRadius: stylesConfig.borderRadius,
    boxShadow: stylesConfig.blueBoxShadow,
    "&:hover": {
      backgroundColor: `${stylesConfig.blueColor}`,
    },
    fontSize: {
      xs: "10px",
      sm: "10px",
      md: "12px",
    },
  },
  cardFooter: {
    display: "grid",
    height: "140px",
    alignItems: "center",
    justifyContent: "center",
    justifyItems: "center",
    borderRadius: `20px`,
    borderBlockColor: stylesConfig.whiteColor,
    backgroundColor: stylesConfig.lightGrayColor,
    marginTop: "-25px",
    marginRight: "1vw",
    marginLeft: "1vw",
  },
  twoColumns: {
    display: "grid",
    gap: stylesConfig.marginRight,
    gridTemplateColumns: "auto auto",
    alignItems: "center",
  },
  typographyBlueRight: {
    fontSize: 16,
    color: stylesConfig.blueColor,
    textAlign: "right",
  } as const,
  tabsWidth: {
    maxWidth: {
      sm: "92vw",
      xs: "92vw",
      md: `calc(${stylesConfig.leftMinComponentWidth} - 40px)`,
      xl: `calc(${stylesConfig.leftComponentWidth} - 2*${stylesConfig.marginLeft})`,
    },
    minWidth: {
      sm: "92vw",
      xs: "92vw",
      md: `calc(${stylesConfig.leftMinComponentWidth} - 40px)`,
      xl: `calc(${stylesConfig.leftComponentWidth} - 2*${stylesConfig.marginLeft})`,
    },
  } as const,
  itemsBoxHeight: {
    maxHeight: {
      xs: `calc(140px)`,
      sm: `calc(240px)`,
      md: `calc(240px) `,
    },
    overflowY: "scroll",
    boxSizing: "content-box",
    "&::-webkit-scrollbar": {
      width: 3,
      height: 1,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: stylesConfig.blueColor,
    },
  } as const,
  itemToChooseBox: {
    padding: 2,
    display: "grid",
    gridTemplateColumns: "60% 40%",
  } as const,
  itemAddRemoveBox: {
    display: "grid",
    gridTemplateColumns: "33% 33% 33%",
    alignItems: "center",
    justifyItems: "center",
  } as const,
  typographyLabel: {
    fontSize: "14px",
    fontWeight: "bolder",
  },
  typographyPrice: {
    color: stylesConfig.grayLabelColor,
    fontSize: "12px",
    fontWeight: "bolder",
  },
  addRemoveIcon: {
    borderRadius: `8px`,
    boxShadow: stylesConfig.grayBoxShadow,
  } as const,
  countIndicatorPositive: {
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    borderRadius: `8px`,
    height: "24px",
    width: "24px",
    backgroundColor: stylesConfig.lightOrangeColor,
    boxShadow: stylesConfig.orangeBoxShadow,
    color: stylesConfig.orangeColor,
    fontWeight: "bolder",
  } as const,
  countIndicatorZero: {
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    borderRadius: `8px`,
    height: "24px",
    width: "24px",
    backgroundColor: stylesConfig.lightBlueColor,
    boxShadow: stylesConfig.blueBoxShadow,
    color: stylesConfig.blueColor,
    fontWeight: "bolder",
  } as const,
};
