import { Polygon } from "@react-google-maps/api";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../store";
import { getSelectedSectionSelector } from "../../../store/selectors/sectionSelectors";
import { IGeoPolygon } from "../../../utils/models/IGeoPoint";
import { stylesConfig } from "../../../utils/styles/styles";
import { setGlobalSectionRequest } from "../../../store/reducers/locationReducer";
import { IMapStateProps } from "../../../utils/models/IMapStateProps";
import React from "react";
import { SBColor } from "../../locationAvailabilitiesBeachChairs/availabilitiesBeachChair/availabilitiesBeachChairTimeline";

const SectionMapState = ({ setMapBounds }: IMapStateProps) => {
  const dispatch = useAppDispatch();
  const section = useSelector(getSelectedSectionSelector);
  const geoJson = React.useMemo(() => section?.geoJson || {}, [section]);
  const path = React.useMemo(
    () =>
      Object.keys(geoJson).length !== 0
        ? (geoJson as IGeoPolygon).coordinates[0].map((coord) => {
            return { lat: coord[1], lng: coord[0] };
          })
        : [{ lat: 0, lng: 0 }],
    [geoJson]
  );
  const reference = React.useRef<Polygon | null>();

  React.useEffect(() => {
    const bounds = new window.google.maps.LatLngBounds();
    path.forEach((point) => {
      bounds.extend({
        lat: point.lat,
        lng: point.lng,
      });
    });
    setMapBounds(bounds);
  }, [path, setMapBounds]);

  return geoJson &&
    Object.keys(geoJson).length === 0 &&
    geoJson.constructor === Object ? (
    <></>
  ) : (
    <Polygon
      ref={(ref) => {
        reference.current = ref;
      }}
      path={path}
      onClick={() => {
        if (reference.current) {
          reference.current.state.polygon?.setOptions({
            strokeColor: SBColor.white,
            fillColor: SBColor.white,
            fillOpacity: 0.2,
            strokeWeight: 4,
          });
        }
        setTimeout(() => {
          dispatch(
            setGlobalSectionRequest({
              id: section!.id,
            })
          );
        }, 200);
      }}
      options={{
        strokeColor: stylesConfig.blueColor,
        fillColor: stylesConfig.orangeColor,
        strokeOpacity: 0.78,
        strokeWeight: 1,
        fillOpacity: 0.5,
      }}
    />
  );
};

export default SectionMapState;
