import {
  GetRegionRequestPayload,
  GetRegionSuccessPayload,
  RegionFailurePayload,
  SetRegionRequestPayload,
  ClearRegionRequestPayload,
} from "./../actionPayload/regionPayloads";
import { createEntityAdapter, createSlice, EntityState, PayloadAction } from "@reduxjs/toolkit";
import { RegionResponseDto } from "../../api-client/generated";
import { AppState } from "./rootReducer";

const regionAdapter = createEntityAdapter<RegionResponseDto>({
  selectId: (region) => region.id,
});

interface IRegionState {
  pending: boolean;
  regionAdapter: EntityState<RegionResponseDto>;
  region?: RegionResponseDto;
  error: string;
}

const initialState: IRegionState = {
  pending: false,
  regionAdapter: regionAdapter.getInitialState({}),
  region: undefined,
  error: "",
};

const regionSlice = createSlice({
  name: "region",
  initialState,
  reducers: {
    getRegionRequest(state, action: PayloadAction<GetRegionRequestPayload>) {
      state.pending = true;
    },
    getRegionSuccess(state, action: PayloadAction<GetRegionSuccessPayload>) {
      state.pending = false;
      regionAdapter.removeAll(state.regionAdapter);
      regionAdapter.addMany(state.regionAdapter, action.payload.regions);
    },
    setRegionRequest(state, action: PayloadAction<SetRegionRequestPayload>) {
      state.region = action.payload.region;
    },
    clearRegionRequest(state, action: PayloadAction<ClearRegionRequestPayload>) {
      state.region = undefined;
    },
    regionFailure(state, action: PayloadAction<RegionFailurePayload>) {
      state.pending = false;
      state.error = action.payload.error;
    },
  },
});

export const {
  selectAll: getAllRegions,
  selectById: getRegionById,
} = regionAdapter.getSelectors<AppState>((state) => state.region.regionAdapter);

export const {
  getRegionRequest,
  getRegionSuccess,
  setRegionRequest,
  clearRegionRequest,
  regionFailure,
} = regionSlice.actions;

export default regionSlice.reducer;
