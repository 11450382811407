import { Marker, OverlayView, Polygon } from "@react-google-maps/api";
import React from "react";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../store";
import { setGlobalBeachRequest } from "../../../store/reducers/locationReducer";
import { setMapStateRequest } from "../../../store/reducers/mapReducer";
import { getAllBeachesSelector } from "../../../store/selectors/beachSelectors";
import { getSelectedCitySelector } from "../../../store/selectors/citySelectors";
import { getCentroid } from "../../../utils/convertions/getCentroid";
import { geoJsonGuard } from "../../../utils/functions/geoJsonGuard";
import { EMapState } from "../../../utils/models/EMapState";
import { IGeo, IGeoPoint, IGeoPolygon } from "../../../utils/models/IGeoPoint";
import { IMapStateProps } from "../../../utils/models/IMapStateProps";
import { stylesConfig } from "../../../utils/styles/styles";
import logo from "./../../../assets/SBLMarker.png";
import { Box, Typography } from "@mui/material";
import { SBColor } from "../../locationAvailabilitiesBeachChairs/availabilitiesBeachChair/availabilitiesBeachChairTimeline";

const CityMapState = ({ setMapBounds }: IMapStateProps) => {
  const dispatch = useAppDispatch();
  const city = useSelector(getSelectedCitySelector);
  const beaches = useSelector(getAllBeachesSelector);

  const cityBeaches = React.useMemo(
    () =>
      beaches
        .filter((beach) => geoJsonGuard(beach.geoJson))
        .filter((beach) => beach.cityId === (city?.id || 0)),
    [beaches, city]
  );

  React.useEffect(() => {
    const bounds = new window.google.maps.LatLngBounds();
    if (city && cityBeaches.length === 0) {
      let geoJson = city.geoJson;
      if (geoJson.hasOwnProperty("type")) {
        const geo = geoJson as IGeo;
        if (geo.type.includes("Polygon")) {
          let centroid = getCentroid(geoJson);
          bounds.extend({
            lat: centroid.geometry.coordinates[1],
            lng: centroid.geometry.coordinates[0],
          });
        } else {
          bounds.extend({
            lat: (geoJson as IGeoPoint).coordinates[1],
            lng: (geoJson as IGeoPoint).coordinates[0],
          });
          dispatch(setMapStateRequest({ mapState: EMapState.ALL_CITIES }));
        }
      }
    }
    if (city && cityBeaches.length > 1) {
      cityBeaches.forEach((beach) => {
        let geoJson = beach.geoJson;
        let centroid = getCentroid(geoJson);
        bounds.extend({
          lat: centroid.geometry.coordinates[1],
          lng: centroid.geometry.coordinates[0],
        });
      });
    } else {
      cityBeaches.forEach((beach) => {
        const path =
          Object.keys(beach.geoJson).length !== 0
            ? (beach.geoJson as IGeoPolygon).coordinates[0].map((coord) => {
              return { lat: coord[1], lng: coord[0] };
            })
            : [{ lat: 0, lng: 0 }];
        path.forEach((point) => {
          bounds.extend({
            lat: point.lat,
            lng: point.lng,
          });
        });
      });
    }

    setMapBounds(bounds);
  }, [cityBeaches, city, setMapBounds, dispatch]);

  return (
    <>
      {cityBeaches.map((beach) => {
        let geoJson = beach.geoJson;
        let centroid = getCentroid(geoJson);
        if (
          geoJson &&
          Object.keys(geoJson).length === 0 &&
          geoJson.constructor === Object
        ) {
          return <></>;
        } else {
          const path = (geoJson as IGeoPolygon).coordinates[0].map((coord) => {
            return { lat: coord[1], lng: coord[0] };
          });
          return (
            <Fragment key={`fragment-${beach.id}-${beach.regionId}`}>
              <Polygon
                path={path}
                key={`polygon-${beach.id}-${beach.regionId}`}
                onClick={() => {
                  dispatch(setGlobalBeachRequest({ id: beach.id }));
                }}
                options={{
                  strokeColor: stylesConfig.orangeColor,
                  fillColor: stylesConfig.blueColor,
                  strokeOpacity: 0.78,
                  strokeWeight: 1,
                  fillOpacity: 0.3,
                }}
              />
              <Marker
                key={`marker-${beach.id}-${beach.regionId}`}
                icon={{
                  url: logo,
                  scaledSize: new google.maps.Size(50, 50),
                }}
                animation={google.maps.Animation.BOUNCE}
                position={{
                  lat: centroid.geometry.coordinates[1],
                  lng: centroid.geometry.coordinates[0],
                }}
                onClick={() => {
                  dispatch(setGlobalBeachRequest({ id: beach.id }));
                }}
              />
              <OverlayView
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                position={{
                  lat: centroid.geometry.coordinates[1],
                  lng: centroid.geometry.coordinates[0],
                }}
              >
                <Box
                  onClick={() => {
                    dispatch(setGlobalBeachRequest({ id: beach.id }));
                  }}
                  sx={{
                    width: "fit-content",
                    cursor: "pointer",
                    background: SBColor.blue,
                    borderRadius: "25px",
                    padding: "5px",
                    justifyContent: "center",
                    marginLeft: "-50%",
                    marginRight: "50%",
                  }}
                >
                  <Typography
                    sx={{
                      whiteSpace: "nowrap",
                      color: "white",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    {beach.name}
                  </Typography>
                </Box>
              </OverlayView>
            </Fragment>
          );
        }
      })}
    </>
  );
};

export default CityMapState;
