import i18next from "i18next";
import HttpApi from "i18next-http-backend";
import backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18next
  .use(initReactI18next)
  .use(backend)
  .use(HttpApi)
  .use(LanguageDetector)
  .init(
    {
      ns: [
        "common",
        "beachchairs",
        "bookings",
        "availabilities",
        "customers",
        "error",
      ],
      defaultNS: "common",
      lng: "de",
      fallbackLng: "de",
      interpolation: {
        escapeValue: false,
      },
      backend: {
        loadPath: "/locales/{{lng}}/{{ns}}.json",
        requestOptions: {
          cache: "no-store",
        },
      },
      react: {
        useSuspense: false,
        wait: false,
      },
      // debug: true,
    },
    (err, t) => {}
  );

export default i18next;
